@charset "UTF-8";
.page-item {
  line-height: 33px;
}

.digg_pagination {
  background: white;
  cursor: default;
  /* self-clearing method: */
}

.digg_pagination a, .digg_pagination span, .digg_pagination em {
  padding: 0.2em 0.5em;
  display: block;
  float: left;
  margin-right: 1px;
}

.digg_pagination .disabled {
  color: #999999;
  border: 1px solid #dddddd;
}

.digg_pagination .current {
  font-style: normal;
  font-weight: bold;
  background: #2e6ab1;
  color: white;
  border: 1px solid #2e6ab1;
}

.digg_pagination a {
  text-decoration: none;
  color: #105cb6;
  border: 1px solid #9aafe5;
}

.digg_pagination a:hover, .digg_pagination a:focus {
  color: #000033;
  border-color: #000033;
}

.digg_pagination .page_info {
  background: #2e6ab1;
  color: white;
  padding: 0.4em 0.6em;
  width: 22em;
  margin-bottom: 0.3em;
  text-align: center;
}

.digg_pagination .page_info b {
  color: #000033;
  background: #6aa6ed;
  padding: 0.1em 0.25em;
}

.digg_pagination:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

* html .digg_pagination {
  height: 1%;
}

*:first-child + html .digg_pagination {
  overflow: hidden;
}

.time {
  vertical-align: middle;
}

:root {
  --publisher-system-font: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
  --publisher-primary-color: #0080ce;
  --publisher-menu-height: calc(1px - 1px);
  --wp-adminbar-height: calc(1px - 1px) ;
}

body {
  font: 14px/1.7 Lato, sans-serif;
  color: #5f6569;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size-adjust: auto;
}

::selection {
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  color: #fff;
  text-shadow: none;
}

.heading-typo, .site-header .site-branding .logo, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: #2d2d2d;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  line-height: 1.3;
}

h1, .h1 {
  font-size: 34px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 25px;
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 17px;
}

h6, .h6 {
  font-size: 15px;
}

.bs-injection > .vc_row, .bs-injection > .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .bs-injection > .vc_vc_row, .container, .content-wrap, .footer-instagram.boxed, .page-layout-no-sidebar .bs-vc-content .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .page-layout-no-sidebar .bs-vc-content > .vc_row, .page-layout-no-sidebar .bs-vc-content > .vc_row.vc_row-has-fill .upb-background-text.vc_row, .page-layout-no-sidebar .bs-vc-content > .vc_vc_row, .site-footer.boxed, body.boxed .main-wrap {
  max-width: var(--publisher-site-width);
  width: 100%;
}

@media only screen and (max-width: 668px) {
  .layout-1-col, .layout-2-col, .layout-3-col {
    margin-top: 20px !important;
  }
}
body.page-layout-1-col, body.page-layout-2-col {
  --publisher-site-width: var(--publisher-site-width-2-col);
  --publisher-site-width-content: var(--publisher-site-width-2-col-content-col);
  --publisher-site-width-primary: var(--publisher-site-width-2-col-primary-col);
  --publisher-site-width-secondary: 0 ;
}

@media (min-width: 768px) {
  .layout-2-col .content-column {
    width: var(--publisher-site-width-content);
  }
  .bf-2-main-column-size.bf-2-main-column-size {
    width: var(--publisher-site-width-2-col-content-col);
  }
  .layout-2-col .sidebar-column {
    width: var(--publisher-site-width-primary);
  }
  .bf-2-primary-column-size.bf-2-primary-column-size {
    width: var(--publisher-site-width-2-col-primary-col);
  }
  .layout-2-col.layout-2-col-2 .content-column {
    left: var(--publisher-site-width-primary);
  }
  .rtl .layout-2-col.layout-2-col-2 .content-column {
    left: inherit;
    right: var(--publisher-site-width-primary);
  }
  .layout-2-col.layout-2-col-2 .sidebar-column {
    right: var(--publisher-site-width-content);
  }
  .rtl .layout-2-col.layout-2-col-2 .sidebar-column {
    right: inherit;
    left: var(--publisher-site-width-content);
  }
}
body.page-layout-3-col {
  --publisher-site-width: var(--publisher-site-width-3-col);
  --publisher-site-width-content: var(--publisher-site-width-3-col-content-col);
  --publisher-site-width-primary: var(--publisher-site-width-3-col-primary-col);
  --publisher-site-width-secondary: var(--publisher-site-width-3-col-secondary-col);
  --publisher-site-width-content-col-sm: calc(var(--publisher-site-width-content) + (var(--publisher-site-width-secondary) / 2));
  --publisher-site-width-primary-col-sm: calc(100% - var(--publisher-site-width-content-col-sm));
  --publisher-site-width-primary-col-xs: calc(var(--publisher-site-width-primary) + (var(--publisher-site-width-content) / 2));
  --publisher-site-width-secondary-col-xs: calc(100% - var(--publisher-site-width-primary-col-xs)) ;
}

@media (min-width: 1000px) {
  .layout-3-col .content-column {
    width: var(--publisher-site-width-content);
  }
  .bf-3-main-column-size.bf-3-main-column-size {
    width: var(--publisher-site-width-3-col-content-col);
  }
  .layout-3-col .sidebar-column-primary {
    width: var(--publisher-site-width-primary);
  }
  .bf-3-primary-column-size.bf-3-primary-column-size {
    width: var(--publisher-site-width-3-col-primary-col);
  }
  .layout-3-col .sidebar-column-secondary {
    width: var(--publisher-site-width-secondary);
  }
  .bf-3-secondary-column-size.bf-3-secondary-column-size {
    width: var(--publisher-site-width-3-col-secondary-col);
  }
}
@media (max-width: 1000px) and (min-width: 768px) {
  .layout-3-col .content-column {
    width: var(--publisher-site-width-content-col-sm);
  }
  .layout-3-col .sidebar-column-primary {
    width: var(--publisher-site-width-primary-col-sm);
  }
  .layout-3-col .sidebar-column-secondary {
    display: none;
  }
}
@media (max-width: 767px) and (min-width: 500px) {
  .layout-3-col .sidebar-column-primary {
    width: var(--publisher-site-width-primary-col-xs);
    float: left;
  }
  .layout-3-col .sidebar-column-secondary {
    width: var(--publisher-site-width-secondary-col-xs);
    float: left;
  }
}
@media (max-width: 500px) {
  .layout-3-col .sidebar-column-secondary {
    display: none;
  }
}
@media (min-width: 1001px) {
  .layout-3-col-2 .sidebar-column-primary {
    left: var(--publisher-site-width-secondary);
  }
  .rtl .layout-3-col-2 .sidebar-column-primary {
    left: inherit;
    right: var(--publisher-site-width-secondary);
  }
  .layout-3-col-2 .sidebar-column-secondary {
    right: var(--publisher-site-width-primary);
  }
  .rtl .layout-3-col-2 .sidebar-column-secondary {
    right: inherit;
    left: var(--publisher-site-width-primary);
  }
  .layout-3-col-3 .content-column {
    left: var(--publisher-site-width-primary);
  }
  .rtl .layout-3-col-3 .content-column {
    left: inherit;
    right: var(--publisher-site-width-primary);
  }
  .layout-3-col-3 .sidebar-column-primary {
    right: var(--publisher-site-width-content);
  }
  .rtl .layout-3-col-3 .sidebar-column-primary {
    right: inherit;
    left: var(--publisher-site-width-content);
  }
  .layout-3-col-4 .content-column {
    left: var(--publisher-site-width-secondary);
  }
  .rtl .layout-3-col-4 .content-column {
    left: inherit;
    right: var(--publisher-site-width-secondary);
  }
  .layout-3-col-4 .sidebar-column-primary {
    left: var(--publisher-site-width-secondary);
  }
  .rtl .layout-3-col-4 .sidebar-column-primary {
    left: inherit;
    right: var(--publisher-site-width-secondary);
  }
  .layout-3-col-4 .sidebar-column-secondary {
    right: calc(var(--publisher-site-width-content) + var(--publisher-site-width-primary));
  }
  .rtl .layout-3-col-4 .sidebar-column-secondary {
    right: inherit;
    left: calc(var(--publisher-site-width-content) + var(--publisher-site-width-primary));
  }
  .layout-3-col-5 .content-column {
    left: calc(var(--publisher-site-width-secondary) + var(--publisher-site-width-primary));
  }
  .rtl .layout-3-col-5 .content-column {
    left: inherit;
    right: calc(var(--publisher-site-width-secondary) + var(--publisher-site-width-primary));
  }
  .layout-3-col-5 .sidebar-column-primary {
    right: var(--publisher-site-width-content);
  }
  .rtl .layout-3-col-5 .sidebar-column-primary {
    right: inherit;
    left: var(--publisher-site-width-content);
  }
  .layout-3-col-5 .sidebar-column-secondary {
    right: var(--publisher-site-width-content);
  }
  .rtl .layout-3-col-5 .sidebar-column-secondary {
    right: inherit;
    left: var(--publisher-site-width-content);
  }
  .layout-3-col-6 .content-column {
    left: calc(var(--publisher-site-width-secondary) + var(--publisher-site-width-primary));
  }
  .rtl .layout-3-col-6 .content-column {
    left: inherit;
    right: calc(var(--publisher-site-width-secondary) + var(--publisher-site-width-primary));
  }
  .layout-3-col-6 .sidebar-column-primary {
    right: calc(var(--publisher-site-width-content) - var(--publisher-site-width-secondary));
  }
  .rtl .layout-3-col-6 .sidebar-column-primary {
    right: inherit;
    left: calc(var(--publisher-site-width-content) - var(--publisher-site-width-secondary));
  }
  .layout-3-col-6 .sidebar-column-secondary {
    right: calc(var(--publisher-site-width-content) + var(--publisher-site-width-primary));
  }
  .rtl .layout-3-col-6 .sidebar-column-secondary {
    right: inherit;
    left: calc(var(--publisher-site-width-content) + var(--publisher-site-width-primary));
  }
}
@media (max-width: 1000px) and (min-width: 768px) {
  .layout-3-col-3 .content-column, .layout-3-col-5 .content-column, .layout-3-col-6 .content-column {
    left: var(--publisher-site-width-primary-col-sm);
  }
  .rtl .layout-3-col-3 .content-column, .rtl .layout-3-col-5 .content-column, .rtl .layout-3-col-6 .content-column {
    left: inherit;
    right: var(--publisher-site-width-primary-col-sm);
  }
  .layout-3-col-3 .sidebar-column-primary, .layout-3-col-5 .sidebar-column-primary, .layout-3-col-6 .sidebar-column-primary {
    right: var(--publisher-site-width-content-col-sm);
  }
  .rtl .layout-3-col-3 .sidebar-column-primary, .rtl .layout-3-col-5 .sidebar-column-primary, .rtl .layout-3-col-6 .sidebar-column-primary {
    right: inherit;
    left: var(--publisher-site-width-content-col-sm);
  }
}
code {
  padding: 4px 6px;
  font-size: 90%;
  color: inherit;
  background-color: #eaeaea;
  border-radius: 0;
}

pre {
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #dcdcdc;
  border-radius: 0;
}

a:focus {
  color: inherit;
}

a, a:focus, a:hover {
  text-decoration: none;
  -webkit-transition: all 0.07s ease;
  -moz-transition: all 0.07s ease;
  -o-transition: all 0.07s ease;
  transition: all 0.07s ease;
}

input[type=color], input[type=date], input[type=datetime-local], input[type=email], input[type=month], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week], textarea {
  height: 36px;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

textarea {
  display: block;
  width: 100%;
  height: auto;
  background-color: #fff;
}

input[type=color]:focus, input[type=date]:focus, input[type=datetime-local]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, input[type=password]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, textarea:focus {
  outline: 0;
}

.btn, button, html input[type=button], input[type=button], input[type=reset], input[type=submit] {
  border: none;
  color: #fff;
  border-radius: 0;
  outline: 0;
  height: auto;
  line-height: 1.4;
  padding: 8px 12px;
  vertical-align: middle;
  text-transform: uppercase !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  max-width: 100%;
  white-space: normal;
}

.entry-content .btn, .entry-content .btn a, .entry-content button, .entry-content html input[type=button], .entry-content input[type=button], .entry-content input[type=reset], .entry-content input[type=submit] {
  color: #fff;
}

.btn:focus, .btn:hover, button:focus, button:hover, html input[type=button]:focus, html input[type=button]:hover, input[type=button]:focus, input[type=button]:hover, input[type=reset]:focus, input[type=reset]:hover, input[type=submit]:focus, input[type=submit]:hover {
  opacity: 0.85;
}

.btn.btn-lg, .btn-group-lg > .btn {
  height: 57px;
  line-height: 57px;
  padding: 0 30px;
}

.btn.btn-xs {
  height: 47px;
  line-height: 47px;
  padding: 0 20px;
}

.btn.btn-light {
  background: #fff;
  border: 1px solid #d4d4d4;
  color: #5c5c5c;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 12px;
  height: auto;
  padding: 0 13px;
}

.btn.btn-light.hover, .btn.btn-light:hover {
  border-color: #868686 !important;
  color: #2b2b2b !important;
  background: #fff !important;
}

.btn.btn-light[disabled] {
  border-color: #eaeaea;
  color: #eaeaea;
}

ul.menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.menu a {
  text-decoration: none;
}

ul.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.menu > li {
  float: left;
}

ul.menu > li.alignright {
  float: right;
}

ul.menu li > a {
  position: relative;
}

ul.menu > li .sub-menu {
  background-color: #eee;
  position: absolute;
  top: 102%;
  top: calc(100% + 1px);
  z-index: 100;
  width: 200px;
}

ul.menu li.bs-flip-children {
  direction: rtl;
}

ul.menu li.bs-flip-children .sub-menu, ul.menu li.bs-flip-children a {
  direction: ltr;
}

ul.menu > li .sub-menu .sub-menu {
  left: 99.4%;
  top: 0 !important;
}

ul.menu li.bs-flip-children .sub-menu .sub-menu {
  left: -99.4%;
}

ul.menu li.bs-flip-children .mega-type-link .mega-links > li > a .better-custom-badge, ul.menu li.bs-flip-children .sub-menu.sub-menu .better-custom-badge {
  right: auto;
  left: 10px;
}

ul.menu li.bs-flip-children .sub-menu.sub-menu .menu-have-badge.menu-item-has-children > a > .better-custom-badge {
  right: auto;
  left: 24px;
}

ul.menu > li li:hover > .sub-menu {
  display: block;
}

ul.menu li.menu-have-icon > a > .bf-icon {
  margin-right: 6px;
  display: inline-block;
}

ul.menu li.menu-title-hide > a > .bf-icon {
  margin-right: 0;
}

ul.menu li.menu-title-hide > a {
  padding: 0 15px;
  display: inline-block;
}

ul.menu li.menu-item-has-children.menu-title-hide > a:after {
  display: none;
}

ul.menu li.menu-item-has-children > a:after {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
  margin-left: 6px;
  font-size: 13px;
}

ul.menu .sub-menu > li.menu-item-has-children > a:after {
  position: absolute;
  right: 10px;
  top: 5px;
  content: "\f105";
}

ul.menu li.bs-flip-children .sub-menu > li.menu-item-has-children > a:after {
  content: "\f104";
  left: 10px;
  right: auto;
  margin-right: 8px;
  margin-left: 0;
}

ul.menu.menu li.bs-flip-children .sub-menu a {
  text-align: right;
}

ul.menu .sub-menu .sub-menu > li.menu-item-has-children > a:after {
  content: "\f0da";
}

ul.menu li.menu-have-icon .bf-icon {
  margin-right: 10px;
}

ul.menu li.menu-have-icon.menu-title-hide .bf-icon {
  margin-right: 0;
}

ul.menu .sub-menu > li > a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

ul.menu .sub-menu > li:last-child a {
  border-bottom: none !important;
}

ul.menu .mega-menu, ul.menu .sub-menu {
  z-index: -1;
  -webkit-transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -moz-transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -ms-transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -o-transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  display: none;
  visibility: hidden;
}

ul.menu.bsm-initialized .mega-menu, ul.menu.bsm-initialized .sub-menu {
  display: block;
}

ul.menu.bsm-pure li:hover > .mega-menu, ul.menu.bsm-pure li:hover > .sub-menu {
  visibility: visible !important;
  z-index: 9999 !important;
  opacity: 1 !important;
  display: block !important;
}

ul.menu.bsm-initialized li.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.bsm-enter > .sub-menu {
  visibility: visible;
  z-index: 9999;
}

ul.menu.bsm-initialized li.bsm-leave > .mega-menu, ul.menu.bsm-initialized li.bsm-leave > .sub-menu {
  display: none;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-none > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-none > .sub-menu {
  display: none;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-none.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-none.bsm-enter > .sub-menu {
  display: block;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-fade > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-fade > .sub-menu {
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-fade.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-fade.bsm-enter > .sub-menu {
  opacity: 1;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-fade > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-fade > .sub-menu {
  -webkit-transform: translate3d(0, -10px, 0) scale3d(0.95, 0.95, 0.95);
  transform: translate3d(0, -10px, 0) scale3d(0.95, 0.95, 0.95);
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-fade.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-fade.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-bottom-in > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-bottom-in > .sub-menu {
  -webkit-transform: translate3d(0, 11px, 0);
  transform: translate3d(0, 11px, 0);
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-bottom-in.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-bottom-in.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-top-in > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-top-in > .sub-menu {
  -webkit-transform: translate3d(0, 11px, 0);
  transform: translate3d(0, 11px, 0);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-top-in.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-top-in.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation-name: menu-slide-top-in;
  animation-name: menu-slide-top-in;
}

@-webkit-keyframes menu-slide-top-in {
  0% {
    -webkit-transform: translate3d(0, -11px, 0);
    transform: translate3d(0, -11px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes menu-slide-top-in {
  0% {
    -webkit-transform: translate3d(0, -11px, 0);
    transform: translate3d(0, -11px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-left-in > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-left-in > .sub-menu {
  -webkit-transform: translate3d(11px, 0, 0);
  transform: translate3d(11px, 0, 0);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-left-in.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-left-in.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation-name: menu-slide-left-in;
  animation-name: menu-slide-left-in;
}

@-webkit-keyframes menu-slide-left-in {
  0% {
    -webkit-transform: translate3d(-11px, 0, 0);
    transform: translate3d(-11px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes menu-slide-left-in {
  0% {
    -webkit-transform: translate3d(-11px, 0, 0);
    transform: translate3d(-11px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-right-in > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-right-in > .sub-menu {
  -webkit-transform: translate3d(-11px, 0, 0);
  transform: translate3d(-11px, 0, 0);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-right-in.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-right-in.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation-name: menu-slide-right-in;
  animation-name: menu-slide-right-in;
}

@-webkit-keyframes menu-slide-right-in {
  0% {
    -webkit-transform: translate3d(11px, 0, 0);
    transform: translate3d(11px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes menu-slide-right-in {
  0% {
    -webkit-transform: translate3d(11px, 0, 0);
    transform: translate3d(11px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-tada > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-tada > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: scale3d(0.97, 0.97, 0.97);
  transform: scale3d(0.97, 0.97, 0.97);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-tada.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-tada.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-tada;
  animation-name: menu-tada;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

@-webkit-keyframes menu-tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }
  10%, 20% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97) rotate3d(0, 0, 1, -1deg);
    transform: scale3d(0.97, 0.97, 0.97) rotate3d(0, 0, 1, -1deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, 2deg);
    transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, 2deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, -1deg);
    transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
@keyframes menu-tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97) rotate3d(0, 0, 1, -1deg);
    transform: scale3d(0.97, 0.97, 0.97) rotate3d(0, 0, 1, -1deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, 2deg);
    transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, 2deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, -1deg);
    transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-bounce > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-bounce > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: translate3d(0, -8px, 0);
  transform: translate3d(0, -8px, 0);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-bounce.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-bounce.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-bounce;
  animation-name: menu-bounce;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-bounce {
  0%, 20% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    opacity: 0;
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  100%, 53%, 80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes menu-bounce {
  0%, 20% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    opacity: 0;
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  100%, 53%, 80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
  100% {
    opacity: 1;
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-shake > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-shake > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-shake.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-shake.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-shake;
  animation-name: menu-shake;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-shake {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  20%, 50%, 60%, 70%, 90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes menu-shake {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  20%, 50%, 60%, 70%, 90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-swing > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-swing > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
  transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-swing.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-swing.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-swing;
  animation-name: menu-swing;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-swing {
  0% {
    opacity: 0;
  }
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -4deg);
    transform: rotate3d(0, 0, 1, -4deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 2deg);
    transform: rotate3d(0, 0, 1, 2deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -2deg);
    transform: rotate3d(0, 0, 1, -2deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    opacity: 1;
  }
}
@keyframes menu-swing {
  0% {
    opacity: 0;
  }
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -4deg);
    transform: rotate3d(0, 0, 1, -4deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 2deg);
    transform: rotate3d(0, 0, 1, 2deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -2deg);
    transform: rotate3d(0, 0, 1, -2deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    opacity: 1;
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-wobble > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-wobble > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
  transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-wobble.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-wobble.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-wobble;
  animation-name: menu-wobble;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }
  30% {
    -webkit-transform: translate3d(7%, 0, 0) rotate3d(0, 0, 1, 1.5deg);
    transform: translate3d(7%, 0, 0) rotate3d(0, 0, 1, 1.5deg);
  }
  45% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1.5deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1.5deg);
  }
  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 0.7deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 0.7deg);
  }
  75% {
    -webkit-transform: translate3d(-1%, 0, 0) rotate3d(0, 0, 1, -0.7deg);
    transform: translate3d(-1%, 0, 0) rotate3d(0, 0, 1, -0.7deg);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes menu-wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }
  30% {
    -webkit-transform: translate3d(7%, 0, 0) rotate3d(0, 0, 1, 1.5deg);
    transform: translate3d(7%, 0, 0) rotate3d(0, 0, 1, 1.5deg);
  }
  45% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1.5deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1.5deg);
  }
  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 0.7deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 0.7deg);
  }
  75% {
    -webkit-transform: translate3d(-1%, 0, 0) rotate3d(0, 0, 1, -0.7deg);
    transform: translate3d(-1%, 0, 0) rotate3d(0, 0, 1, -0.7deg);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-x > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-x > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
  transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-x.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-x.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-flipInX;
  animation-name: menu-flipInX;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-flipInX {
  0% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, 50deg);
    transform: perspective(200px) rotate3d(1, 0, 0, 50deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, -10deg);
    transform: perspective(200px) rotate3d(1, 0, 0, -10deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, 5deg);
    transform: perspective(200px) rotate3d(1, 0, 0, 5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, -2deg);
    transform: perspective(200px) rotate3d(1, 0, 0, -2deg);
  }
  100% {
    -webkit-transform: perspective(200px);
    transform: perspective(200px);
  }
}
@keyframes menu-flipInX {
  0% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, 50deg);
    transform: perspective(200px) rotate3d(1, 0, 0, 50deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, -10deg);
    transform: perspective(200px) rotate3d(1, 0, 0, -10deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, 5deg);
    transform: perspective(200px) rotate3d(1, 0, 0, 5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, -2deg);
    transform: perspective(200px) rotate3d(1, 0, 0, -2deg);
  }
  100% {
    -webkit-transform: perspective(200px);
    transform: perspective(200px);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-y > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-y > .sub-menu {
  -webkit-animation-duration: 0.65s;
  animation-duration: 0.65s;
  opacity: 0;
  -webkit-transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
  transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-y.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-y.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-flipInY;
  animation-name: menu-flipInY;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-flipInY {
  0% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, 20deg);
    transform: perspective(200px) rotate3d(0, 1, 0, 20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, -10deg);
    transform: perspective(200px) rotate3d(0, 1, 0, -10deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, 5deg);
    transform: perspective(200px) rotate3d(0, 1, 0, 5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, -1deg);
    transform: perspective(200px) rotate3d(0, 1, 0, -1deg);
  }
  100% {
    -webkit-transform: perspective(200px);
    transform: perspective(200px);
  }
}
@keyframes menu-flipInY {
  0% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, 20deg);
    transform: perspective(200px) rotate3d(0, 1, 0, 20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, -10deg);
    transform: perspective(200px) rotate3d(0, 1, 0, -10deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, 5deg);
    transform: perspective(200px) rotate3d(0, 1, 0, 5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, -1deg);
    transform: perspective(200px) rotate3d(0, 1, 0, -1deg);
  }
  100% {
    -webkit-transform: perspective(200px);
    transform: perspective(200px);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-buzz > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-buzz > .sub-menu {
  -webkit-animation-duration: 0.65s;
  animation-duration: 0.65s;
  opacity: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-buzz.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-buzz.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-name: menu-buzz;
  animation-name: menu-buzz;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes menu-buzz {
  50% {
    -webkit-transform: translateX(1px) rotate(0.7deg);
    transform: translateX(1px) rotate(0.7deg);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(-0.7deg);
    transform: translateX(-1px) rotate(-0.7deg);
  }
}
@keyframes menu-buzz {
  50% {
    -webkit-transform: translateX(1px) rotate(0.7deg);
    transform: translateX(1px) rotate(0.7deg);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(-0.7deg);
    transform: translateX(-1px) rotate(-0.7deg);
  }
}
.main-menu-wrapper.bs-pinning-block.pinned {
  box-shadow: none;
}

.site-header .content-wrap {
  overflow: visible !important;
}

.site-header > .header-inner > .content-wrap > .container {
  position: relative;
}

.site-header .site-branding {
  margin: 0;
}

.site-header .site-branding .logo {
  margin: 0;
  line-height: 0;
  font-size: 50px;
}

.site-header .site-branding .logo.text-logo {
  font-size: 30px;
  line-height: 38px;
}

.site-header .site-branding .logo img {
  max-width: 100%;
}

.site-header .site-branding .logo .site-title {
  display: none;
}

.site-header > .header-inner > .container {
  position: relative;
}

.site-header > .header-inner .row-height {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.site-header > .header-inner .logo-col, .site-header > .header-inner .sidebar-col {
  display: table-cell;
  float: none;
  height: 100%;
  vertical-align: middle;
}

.site-header .header-inner .row {
  position: relative;
}

.site-header.full-width.stretched .content-wrap, .site-header.full-width.stretched .content-wrap .container {
  max-width: 100% !important;
}

.site-header .sidebar-col .wpb_content_element {
  margin-bottom: 0 !important;
}

.site-header .menu-action-buttons {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 35px;
  z-index: 99;
}

.header-style-1.site-header.boxed .menu-action-buttons, .header-style-2.site-header.boxed .menu-action-buttons {
  bottom: -3px;
}

.site-header .menu-action-buttons.width-1 {
  width: 40px;
}

.site-header .menu-action-buttons.width-2 {
  width: 80px;
}

.site-header .menu-action-buttons.width-3 {
  width: 120px;
}

.site-header .menu-action-buttons > div {
  min-width: 37px;
  display: inline-block;
  float: right;
  height: 100%;
  text-align: right;
  position: relative;
}

.site-header .menu-action-buttons > div.close {
  opacity: 1;
}

.site-header .main-menu-container.search-open .menu-action-buttons, .site-header .search-container.open {
  width: 100%;
}

.site-header .menu-action-buttons .search-container {
  z-index: 1;
}

.site-header .main-menu-wrapper .main-menu-container.search-open .main-menu {
  opacity: 0;
  pointer-events: none;
}

.site-header .search-container.open {
  width: 100%;
}

.site-header .off-canvas-menu-icon-container + .search-container.open {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
}

.site-header .main-menu-container.search-open .off-canvas-menu-icon-container + .search-container .search-handler {
  margin-right: 38px;
}

.site-header .search-container .search-handler, .site-header .shop-cart-container .cart-handler {
  position: absolute;
  right: 3px;
  top: 50%;
  margin-top: -15px;
  z-index: 100;
  width: 37px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  color: #434343;
  cursor: pointer;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.site-header .search-container .search-box {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 36px 0 0;
  z-index: 99;
  height: 100%;
  width: 100%;
  text-align: center;
}

.site-header .search-container .search-box .search-form .search-field {
  border: none;
  width: 100%;
  height: 45px;
  background: 0 0;
  line-height: 45px;
  font-size: 14px;
  color: #434343;
}

.site-header .search-container.open .search-box {
  display: block;
}

.site-header .search-container .search-box .search-form input[type=submit] {
  display: none;
}

.site-header .off-canvas-menu-icon-container {
  z-index: 2;
}

.site-header .shop-cart-container .cart-handler {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 17px;
  white-space: nowrap;
}

.site-header .shop-cart-container .cart-handler * {
  white-space: inherit;
}

.site-header .shop-cart-container .cart-box {
  width: 260px;
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  padding: 20px;
  display: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  text-align: left;
}

.site-header .shop-cart-container .cart-box .buttons {
  margin-bottom: 0;
}

.site-header .shop-cart-container .cart-box .buttons .checkout {
  float: right;
}

.site-header .shop-cart-container.open .cart-box {
  display: block;
}

.site-header .main-menu-container.search-open .shop-cart-container {
  display: none;
}

.site-header .shop-cart-container .cart-handler .cart-count {
  display: inline-block;
  line-height: 18px;
  min-width: 18px;
  padding: 0 4px;
  font-size: 12px;
  font-weight: bolder;
  color: #fff;
  margin-left: 1px;
  vertical-align: middle;
  border-radius: 33px;
  font-family: sans-serif;
  text-align: center;
}

.site-header .shop-cart-container .cart-box:after {
  content: "";
  height: 3px;
  position: absolute;
  right: 0;
  left: 0;
  top: -3px;
  display: inline-block;
}

.site-header .shop-cart-container a.remove {
  display: inline-block;
  float: left;
  margin-right: 4px;
  font-size: 14px;
  margin-top: 4px;
}

.site-header .menu-action-buttons > .off-canvas-menu-icon-container {
  margin-right: 6px;
}

.off-canvas-menu-icon-container {
  line-height: 42px;
}

.off-canvas-menu-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
}

.off-canvas-menu-icon .off-canvas-menu-icon-el {
  margin: 9px 0 0;
  width: 16px;
  cursor: pointer;
  height: 2px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: #313131;
  -webkit-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -moz-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -o-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
}

.off-canvas-menu-icon .off-canvas-menu-icon-el:after, .off-canvas-menu-icon .off-canvas-menu-icon-el:before {
  content: "";
  display: inline-block;
  background: #313131;
  width: 20px;
  height: 2px;
  position: absolute;
  top: -6px;
  -webkit-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -moz-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -o-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
}

.off-canvas-menu-icon .off-canvas-menu-icon-el:after {
  top: 6px;
}

.off-canvas-menu-icon:hover .off-canvas-menu-icon-el:before, .off-canvas-menu-open .off-canvas-menu-icon .off-canvas-menu-icon-el:before {
  top: -8px;
}

.off-canvas-menu-icon:hover .off-canvas-menu-icon-el:after, .off-canvas-menu-open .off-canvas-menu-icon .off-canvas-menu-icon-el:after {
  top: 8px;
}

.off-canvas-menu-icon-container.off-icon-right .off-canvas-menu-icon-el {
  text-align: right;
}

.off-canvas-menu-icon-container.off-icon-right .off-canvas-menu-icon-el:after, .off-canvas-menu-icon-container.off-icon-right .off-canvas-menu-icon-el:before {
  right: 0;
}

.off-canvas-menu-icon-container.off-icon-left .off-canvas-menu-icon-el {
  text-align: left;
}

.off-canvas-container .canvas-close {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 23px;
  right: 18px;
  cursor: pointer;
  z-index: 1;
}

.off-canvas-inner .canvas-close i, .off-canvas-inner .canvas-close:after, .off-canvas-inner .canvas-close:before {
  position: absolute;
  width: 18px;
  height: 2px;
  content: "";
  display: inline-block;
  background: #a8a8a8;
  left: 0;
  top: 7px;
  margin-top: 0;
  border-radius: 14px;
  transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
}

.off-canvas-inner .canvas-close:hover i, .off-canvas-inner .canvas-close:hover:after, .off-canvas-inner .canvas-close:hover:before {
  background: #111;
}

.off-canvas-container.skin-dark .canvas-close i, .off-canvas-container.skin-dark .canvas-close:after, .off-canvas-container.skin-dark .canvas-close:before {
  background: #7e7e7e;
}

.off-canvas-container.skin-dark .canvas-close:hover i, .off-canvas-container.skin-dark .canvas-close:hover:after, .off-canvas-container.skin-dark .canvas-close:hover:before {
  background: #fff;
}

.off-canvas-inner .canvas-close:before {
  transform: rotate(45deg);
}

.off-canvas-inner .canvas-close:after {
  transform: rotate(-45deg);
}

.off-canvas-inner .canvas-close i {
  width: 0;
  left: inherit;
  right: 50%;
  margin-right: 0;
}

.off-canvas-container.left .canvas-close.open:before, .off-canvas-container.left .canvas-close:hover:before {
  width: 10px;
  left: 0;
  top: 10px;
}

.off-canvas-container.left .canvas-close.open:after, .off-canvas-container.left .canvas-close:hover:after {
  width: 10px;
  left: 0;
  top: 4px;
}

.off-canvas-container.left .canvas-close.open:hover i, .off-canvas-container.left .canvas-close:hover i {
  width: 16px;
  visibility: visible;
  position: absolute;
  right: 50%;
  margin-right: -8px;
}

.off-canvas-container.right .canvas-close {
  left: 18px;
  right: auto;
}

.off-canvas-container.right .canvas-close i, .off-canvas-container.right .canvas-close:after, .off-canvas-container.right .canvas-close:before {
  right: 0;
  left: auto;
}

.off-canvas-container.right .canvas-close i {
  width: 0;
  right: inherit;
  left: 50%;
  margin-left: 0;
  margin-right: auto;
}

.off-canvas-container.right .canvas-close.open:before, .off-canvas-container.right .canvas-close:hover:before {
  width: 10px;
  right: 0;
  left: auto;
  top: 4px;
}

.off-canvas-container.right .canvas-close.open:after, .off-canvas-container.right .canvas-close:hover:after {
  width: 10px;
  right: 0;
  left: auto;
  top: 10px;
}

.off-canvas-container.right .canvas-close.open:hover i, .off-canvas-container.right .canvas-close:hover i {
  width: 16px;
  visibility: visible;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  right: auto;
  margin-right: auto;
}

.main-menu > li {
  margin-right: 10px;
}

.main-menu > li > a {
  line-height: 48px;
  display: inline-block;
  padding: 0 12px;
}

.main-menu .sub-menu > li > a {
  padding: 0 16px 0 10px;
  line-height: 40px;
}

.main-menu.menu > li .sub-menu {
  width: 210px;
  background-color: #fff;
  -webkit-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
}

.main-menu.menu .sub-menu li > a {
  line-height: 1.7;
  padding: 8px 16px;
}

.main-menu.menu .sub-menu > li {
  position: relative;
}

.main-menu.menu .sub-menu > li, .main-menu.menu .sub-menu > li > a {
  color: #434343;
}

.main-menu.menu .sub-menu li.current-menu-item:hover > a, .main-menu.menu .sub-menu > li:hover > a {
  color: #fff;
}

.main-menu.menu .sub-menu > li.menu-item-has-children > a:after {
  top: 7px;
}

.main-menu.menu .sub-menu > li > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.main-menu.menu .sub-menu .sub-menu > li > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.main-menu.menu > li > a:before {
  content: "";
  display: block;
  width: 20%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  visibility: hidden;
}

.main-menu.menu > li.current-menu-item > a:before, .main-menu.menu > li.current-menu-parent > a:before, .main-menu.menu > li:hover > a:before {
  width: 100%;
  visibility: visible;
}

.top-menu > li {
  line-height: 25px;
  margin-right: 15px;
}

.top-menu.menu li {
  position: relative;
}

.top-menu.menu li > a {
  color: #707070;
}

.top-menu .sub-menu > li > a {
  padding: 0 12px;
  line-height: 32px;
}

.top-menu.menu > li .sub-menu {
  width: 180px;
  background-color: #222;
}

.top-menu.menu .sub-menu > li, .top-menu.menu .sub-menu > li > a {
  color: silver;
}

.top-menu.menu .sub-menu > li.menu-item-has-children > a:after {
  top: 7px;
}

.top-menu.menu .sub-menu li > a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.02);
}

.top-menu.menu .bs-pretty-tabs-elements.sub-menu {
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 0 !important;
  border: none !important;
  left: -15px;
  top: 114% !important;
  display: block !important;
}

.top-menu.menu .display-more-items .bs-pretty-tabs-elements {
  opacity: 1;
  visibility: visible !important;
  padding: 0 !important;
  border: none !important;
}

.bspw-header-style-3 > .bs-pinning-block.pinned .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-3 > .bs-pinning-block.unpinned-no-transition .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-4 > .bs-pinning-block.pinned .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-4 > .bs-pinning-block.unpinned-no-transition .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-7 > .bs-pinning-block.pinned .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-7 > .bs-pinning-block.unpinned-no-transition .main-menu > .menu-item > a > .better-custom-badge {
  display: block;
}

.bspw-header-style-1 > .bs-pinning-block.pinned .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-2 > .bs-pinning-block.pinned .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-3 > .bs-pinning-block .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-4 > .bs-pinning-block .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-7 > .bs-pinning-block .main-menu > .menu-item > a > .better-custom-badge, .widget.widget_nav_menu .menu .better-custom-badge {
  display: none;
}

.main-menu.menu .better-custom-badge, .off-canvas-menu .menu .better-custom-badge {
  position: absolute;
  left: 11px;
  top: -16px;
  display: inline-block;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 11px;
  padding: 0 5px;
  font-weight: 600;
  letter-spacing: 1px;
  z-index: 1;
  text-transform: uppercase;
  text-align: center;
  min-width: 19px;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.main-menu.menu .better-custom-badge:after, .off-canvas-menu .menu .better-custom-badge:after {
  border: solid 4px transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  bottom: -8px;
  left: 5px;
}

.main-menu.menu > .menu-item:hover > a > .better-custom-badge {
  top: -12px;
}

.main-menu.menu .sub-menu.sub-menu .better-custom-badge, .mega-menu.mega-type-link .mega-links > li > a .better-custom-badge {
  left: auto;
  position: absolute;
  bottom: auto;
  right: 10px;
  top: 50%;
  margin-top: -8px;
}

.mega-menu.mega-type-link .mega-links > li > a .better-custom-badge {
  margin-top: -13px;
}

.main-menu.menu .sub-menu.sub-menu .menu-item.menu-badge-left > a > .better-custom-badge {
  position: inherit !important;
  margin-right: 6px;
}

.main-menu.menu .sub-menu.sub-menu .menu-have-badge.menu-item-has-children > a > .better-custom-badge {
  right: 24px;
}

.main-menu.menu .sub-menu.sub-menu .better-custom-badge:after, .mega-menu.mega-type-link .mega-links > li > a .better-custom-badge:after {
  display: none;
}

.site-header.header-style-3 .main-menu.menu > .menu-item > a > .better-custom-badge, .site-header.header-style-4 .main-menu.menu > .menu-item > a > .better-custom-badge, .site-header.header-style-7 .main-menu.menu > .menu-item > a > .better-custom-badge {
  top: 98%;
}

.site-header.header-style-3 .main-menu.menu > .menu-item:hover > a > .better-custom-badge, .site-header.header-style-4 .main-menu.menu > .menu-item:hover > a > .better-custom-badge, .site-header.header-style-7 .main-menu.menu > .menu-item:hover > a > .better-custom-badge {
  top: 98%;
  left: 20px;
}

.site-header.header-style-3 .main-menu.menu > .menu-item > a > .better-custom-badge:after, .site-header.header-style-4 .main-menu.menu > .menu-item > a > .better-custom-badge:after, .site-header.header-style-7 .main-menu.menu > .menu-item > a > .better-custom-badge:after {
  bottom: inherit;
  top: -8px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.site-header.header-style-5 .main-menu.menu > .menu-item > a > .better-custom-badge {
  top: 3px;
}

.site-header.header-style-6 .main-menu.menu > .menu-item:hover > a > .better-custom-badge, .site-header.header-style-6 .main-menu.menu > .menu-item > a > .better-custom-badge {
  top: 0;
}

.site-header.header-style-8 .main-menu.menu > .menu-item:hover > a > .better-custom-badge, .site-header.header-style-8 .main-menu.menu > .menu-item > a > .better-custom-badge {
  top: 0;
}

.site-header.header-style-3 .bs-pinning-block.pinned .main-menu.menu > .menu-item > a > .better-custom-badge, .site-header.header-style-4 .bs-pinning-block.pinned .main-menu.menu > .menu-item > a > .better-custom-badge, .site-header.header-style-7 .bs-pinning-block.pinned .main-menu.menu > .menu-item > a > .better-custom-badge {
  display: none;
}

.menu .mega-menu {
  width: 100%;
  position: absolute;
  left: 0;
  top: 102%;
  top: calc(100% + 1px);
  z-index: 100;
  background-color: #fff;
  -wbkit-box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.menu > li > .mega-menu .bsm-leave .sub-menu, .menu > li > .mega-menu .sub-menu, .menu > li > .mega-menu li.menu-item-has-children.better-anim-none > .sub-menu {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  transform: none !important;
  transition: none !important;
  animation-name: none !important;
  position: relative;
  top: auto !important;
  left: auto !important;
  background: 0 0 !important;
  width: 100%;
  box-shadow: none !important;
}

.menu > li > .mega-menu .sub-menu .sub-menu {
  padding-left: 15px;
}

.menu .mega-menu li.menu-item-has-children > a:after {
  display: none;
}

.menu > li > .mega-menu .mega-menu-links {
  background-color: #c8c8c8;
}

.mega-menu.mega-type-link {
  list-style: none;
  padding: 22px 25px;
}

.mega-menu.mega-type-link .mega-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mega-menu.mega-type-link .mega-links > li {
  float: left;
}

.mega-menu.mega-type-link .mega-links > li > a {
  color: #434343;
  font-size: 105%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.07);
  width: 100%;
  display: block;
  margin-bottom: 5px;
  padding-bottom: 10px;
}

.mega-menu.mega-type-link .mega-links .sub-menu {
  border: none;
  background: 0 0;
  box-shadow: none;
}

.mega-menu.mega-type-link-list {
  list-style: none;
  padding: 0;
}

.mega-menu.mega-type-link-list .mega-links {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.mega-menu.mega-type-link-list .mega-links > li {
  display: inline-block;
  padding: 10px 12px;
}

.mega-menu.mega-type-link-list .mega-links > li .sub-menu {
  display: none;
  position: absolute;
  top: 100% !important;
  left: 0 !important;
  background-color: #fff !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.37) !important;
  padding: 0 12px;
}

.mega-menu.mega-type-link-list .mega-links > li .sub-menu .sub-menu {
  background-color: #050505 !important;
  padding-left: 0;
}

.mega-menu.mega-type-link-list .mega-links li:hover > .sub-menu {
  display: block;
}

.mega-menu.mega-type-link-list .mega-links > li .sub-menu > li {
  position: inherit;
}

.mega-menu.mega-type-link-list .mega-links > li .sub-menu > li > a {
  border-bottom: none;
}

.mega-menu.mega-type-link-list .mega-links > li .sub-menu > li {
  display: inline-block;
}

.mega-menu.mega-type-link-list .mega-links > .menu-item {
  margin-bottom: 0;
}

.mega-grid-posts {
  padding: 20px;
}

.mega-grid-posts .post:nth-child(5), .mega-grid-posts .postli:nth-child(1) {
  clear: left;
  margin-left: -7px;
}

.mega-grid-posts .bs-listing-listing-mix-2-1, .mega-grid-posts .listing-item-grid-1 {
  margin-bottom: 0;
}

.mega-menu.tabbed-grid-posts .listing.listing-grid {
  display: inline-block;
  width: 100%;
}

.site-header .listing-item-grid-1 .title {
  line-height: 22px;
  font-size: 15px;
}

.site-header .bs-pagination {
  margin: 5px 0 0;
}

.tabbed-grid-posts {
  position: relative;
}

.tabbed-grid-posts:before {
  content: "";
  background-color: #fbfbfb;
  width: 220px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: inline-block;
  border-right: 1px solid #dedede;
}

.tabbed-grid-posts .tabs-section {
  list-style: none;
  width: 220px;
  max-width: 220px;
  text-align: left;
  position: relative;
  z-index: 1;
  float: left;
  padding: 23px 23px 0 23px;
  margin: 0;
}

.tabbed-grid-posts .tabs-section a, .tabbed-grid-posts .tabs-section a:hover {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333 !important;
  padding: 5px 17px;
  border: 1px solid transparent;
  text-transform: capitalize;
  transition: none !important;
}

.tabbed-grid-posts .tabs-section a .fa {
  margin-right: 4px;
  color: #adadad;
  -webkit-transition: all 0.07s ease;
  -moz-transition: all 0.07s ease;
  -o-transition: all 0.07s ease;
  transition: all 0.07s ease;
}

.tabbed-grid-posts .tabs-section .active a .fa {
  color: inherit;
}

.tabbed-grid-posts .tab-content {
  display: block;
  padding: 23px 23px 23px 245px;
}

.tabbed-grid-posts .listing.listing-grid-1 .listing-item-grid-1 {
  margin-bottom: 5px;
}

.tabbed-grid-posts .listing.listing-grid-1.columns-3[max-width~="450px"] .listing-item {
  width: 33.3333% !important;
  float: left !important;
}

.tabbed-grid-posts .listing.listing-grid-1.columns-3 .listing-item:nth-child(3n-2) {
  padding-right: 12px !important;
  clear: left !important;
}

.tabbed-grid-posts .listing.listing-grid-1.columns-3 .listing-item:nth-child(1) {
  clear: none !important;
}

.tabbed-grid-posts .listing.columns-3 .listing-item:nth-child(3n-1) {
  padding: 0 6px !important;
}

.tabbed-grid-posts .listing.columns-3 .listing-item:nth-child(3n+3) {
  padding-left: 12px !important;
}

.mega-type-page-builder {
  padding: 30px 25px;
}

.mega-type-page-builder .bs-listing:last-child, .mega-type-page-builder .bs-shortcode:last-child, .mega-type-page-builder .entry-content .better-studio-shortcode:last-child .mega-type-page-builder .better-studio-shortcode:last-child, .mega-type-page-builder .widget:last-child {
  margin-bottom: 0;
}

.main-menu-wrapper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.main-menu-wrapper .main-menu-container {
  position: relative;
}

.site-header.boxed .main-menu-wrapper .main-menu-container, .site-header.full-width .main-menu-wrapper {
  border-top: 1px solid #dedede;
  border-bottom: 3px solid #dedede;
  background: #fff;
}

body.boxed .site-header .main-menu-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.menu .mega-menu, ul.menu > li .sub-menu {
  top: 107%;
  top: calc(100% + 3px);
}

.site-header.header-style-5 .search-container .search-handler, .site-header.header-style-5 .shop-cart-container .cart-handler, .site-header.header-style-8 .search-container .search-handler, .site-header.header-style-8 .shop-cart-container .cart-handler {
  margin-top: -14px;
}

.site-header.header-style-6 .search-container .search-handler, .site-header.header-style-6 .shop-cart-container .cart-handler {
  margin-top: -13px;
}

.site-header.header-style-1 .header-inner {
  padding: 40px 0;
}

.site-header.header-style-1.h-a-ad .header-inner {
  padding: 30px 0;
}

.site-header.header-style-1 .site-branding {
  text-align: center;
}

.site-header.header-style-1 .main-menu.menu {
  text-align: center;
  height: 68px;
  overflow-y: hidden;
  margin-bottom: -3px;
  padding-top: 20px;
  margin-top: -20px;
}

.site-header.header-style-1.full-width .main-menu.menu .mega-menu, .site-header.header-style-1.full-width .main-menu.menu > li .sub-menu {
  top: 100%;
}

.site-header.header-style-1 .main-menu.menu > li {
  float: none;
  display: inline-block;
  margin: 0 4px;
}

.site-header.header-style-1 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-1 .search-container .search-box .search-form .search-field {
  text-align: center;
}

.site-header.header-style-1.full-width .menu-action-buttons {
  top: 20px;
}

.site-header.header-style-1 .main-menu-wrapper.menu-actions-btn-width-1 .main-menu-container {
  padding: 0 40px;
}

.site-header.header-style-1 .main-menu-wrapper.menu-actions-btn-width-2 .main-menu-container {
  padding: 0 80px;
}

.site-header.header-style-1 .main-menu-wrapper.menu-actions-btn-width-3 .main-menu-container {
  padding: 0 120px;
}

.site-header.header-style-2 .header-inner {
  padding: 20px 0;
}

.site-header.header-style-2 .main-menu.menu {
  height: 68px;
  overflow-y: hidden;
  margin-bottom: -3px;
  padding-top: 20px;
  margin-top: -20px;
}

.site-header.header-style-2.full-width .main-menu.menu .mega-menu, .site-header.header-style-2.full-width .main-menu.menu > li .sub-menu {
  top: 100%;
}

.site-header.header-style-2 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0 5px 0 0;
}

.site-header.header-style-2 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-2.full-width .menu-action-buttons {
  top: 20px;
}

.site-header.header-style-2 .main-menu-wrapper.menu-actions-btn-width-1 .main-menu-container {
  padding-right: 40px;
}

.site-header.header-style-2 .main-menu-wrapper.menu-actions-btn-width-2 .main-menu-container {
  padding-right: 80px;
}

.site-header.header-style-2 .main-menu-wrapper.menu-actions-btn-width-3 .main-menu-container {
  padding-right: 120px;
}

.site-header.header-style-3 .header-inner {
  padding: 25px 0;
}

.site-header.header-style-3 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
}

.site-header.header-style-3 .main-menu.menu {
  height: 63px;
  overflow-y: hidden;
  margin-bottom: -16px;
  padding-bottom: 15px;
}

.site-header.header-style-3 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-3.boxed .main-menu.menu .mega-menu, .site-header.header-style-3.boxed .main-menu.menu > li .sub-menu {
  top: 103%;
  top: calc(100% + 1px);
}

.site-header.header-style-3.full-width .main-menu.menu .mega-menu, .site-header.header-style-3.full-width .main-menu.menu > li .sub-menu {
  top: 76%;
  top: calc(100% - 15px);
}

.site-header.header-style-3.full-width .main-menu-wrapper.pinned .main-menu.menu .mega-menu, .site-header.header-style-3.full-width .main-menu-wrapper.pinned .main-menu.menu > li .sub-menu {
  top: 100%;
}

.site-header.boxed.header-style-3 .main-menu-wrapper .main-menu-container, .site-header.full-width.header-style-3 .main-menu-wrapper {
  border-bottom-width: 1px;
  border-top: none;
  border-bottom-color: #ececec;
}

.site-header.header-style-3 .main-menu.menu > li.current-menu-item > a:before, .site-header.header-style-3 .main-menu.menu > li.current-menu-parent > a:before, .site-header.header-style-3 .main-menu.menu > li:hover > a:before {
  height: 2px;
  bottom: 0;
}

.site-header.header-style-3 .ajax-search-results:after, .site-header.header-style-3 .shop-cart-container .cart-box:after {
  height: 2px;
  top: -2px;
}

.site-header.header-style-3.full-width .menu-action-buttons {
  bottom: 15px;
}

.site-header.header-style-3.full-width .bs-pinning-block.pinned .menu-action-buttons {
  bottom: 0;
}

.site-header.header-style-3 .main-menu-wrapper.menu-actions-btn-width-1 .main-menu-container {
  padding-right: 40px;
}

.site-header.header-style-3 .main-menu-wrapper.menu-actions-btn-width-2 .main-menu-container {
  padding-right: 80px;
}

.site-header.header-style-3 .main-menu-wrapper.menu-actions-btn-width-3 .main-menu-container {
  padding-right: 120px;
}

.site-header.header-style-3 .bs-pinning-block.pinned .main-menu.menu {
  height: 48px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.site-header.header-style-3 .bs-pinning-block.pinned .main-menu.menu {
  margin-bottom: -1px;
}

@media only screen and (max-width: 700px) {
  body.bs-show-ha .site-header.header-style-3 {
    border-bottom: none !important;
  }
}
.site-header.header-style-4 .header-inner {
  padding: 50px 0;
}

.site-header.header-style-4.h-a-ad .header-inner {
  padding: 30px 0;
}

.site-header.header-style-4 .main-menu.menu {
  height: 63px;
  overflow-y: hidden;
  margin-bottom: -16px;
  padding-bottom: 15px;
}

.site-header.header-style-4 .main-menu.menu .mega-menu, .site-header.header-style-4 .main-menu.menu > li .sub-menu {
  top: 102%;
  top: calc(100% + 1px);
}

.site-header.header-style-4.full-width .main-menu-wrapper.pinned .main-menu.menu .mega-menu, .site-header.header-style-4.full-width .main-menu-wrapper.pinned .main-menu.menu > li .sub-menu {
  top: 100%;
}

.site-header.header-style-4 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
}

.site-header.header-style-4 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.boxed.header-style-4 .main-menu-wrapper .main-menu-container, .site-header.full-width.header-style-4 .main-menu-wrapper {
  border-bottom-width: 1px;
  border-top: none;
  border-bottom-color: #ececec;
}

.site-header.header-style-4 .site-branding {
  text-align: center;
}

.site-header.header-style-4 .main-menu.menu {
  text-align: center;
}

.site-header.header-style-4 .main-menu.menu > li {
  float: none;
  display: inline-block;
}

.site-header.header-style-4 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-4 .bs-pinning-block.pinned .main-menu.menu, .site-header.header-style-4 .main-menu-wrapper .main-menu-container {
  height: 48px;
  margin-bottom: -1px;
  padding-bottom: 0;
}

.active-top-line .site-header.header-style-4.boxed .bs-pinning-block.pinned .main-menu-container {
  height: 50px;
}

.site-header.header-style-4 .search-container .search-box .search-form .search-field {
  text-align: center;
}

.site-header.header-style-4 .main-menu-wrapper.menu-actions-btn-width-1 .main-menu-container {
  padding: 0 40px;
}

.site-header.header-style-4 .main-menu-wrapper.menu-actions-btn-width-2 .main-menu-container {
  padding: 0 80px;
}

.site-header.header-style-4 .main-menu-wrapper.menu-actions-btn-width-3 .main-menu-container {
  padding: 0 120px;
}

.site-header.header-style-4.boxed .menu-action-buttons {
  bottom: 0;
}

.site-header.header-style-5 .header-inner {
  padding: 0;
  position: relative;
}

.site-header.header-style-5.boxed .header-inner, .site-header.header-style-5.full-width {
  border-bottom: 1px solid #eee;
}

.site-header.header-style-5 .main-menu.menu {
  text-align: right;
  width: 100%;
}

.site-header.header-style-5 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0;
}

.site-header.header-style-5 .main-menu.menu > li > a {
  padding: 0 15px;
}

.site-header.header-style-5 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-5 .site-branding {
  width: 23.333%;
  float: left;
}

.site-header.header-style-5 .main-menu-container {
  width: 76.666%;
  float: right;
}

.site-header.header-style-5 .main-menu > li > a {
  line-height: 78px;
}

.site-header.header-style-5 .site-branding .logo {
  line-height: 78px;
  display: table;
  width: 100%;
}

.site-header.header-style-5 .site-branding .logo, .site-header.header-style-5 .site-branding .logo img {
  max-height: 74px;
}

.site-header.header-style-5 .site-branding .logo.img-logo a {
  display: table-cell;
  vertical-align: middle;
  line-height: 74px;
  height: 74px;
  padding: 0 10px 0 0;
}

.site-header.header-style-5 .site-branding .logo.text-logo a {
  vertical-align: middle;
  padding: 5px 10px 5px 0;
}

.site-header.header-style-5 .site-branding .logo img {
  vertical-align: middle;
  margin: 0;
  max-height: 100%;
  display: table-cell;
  max-width: 100%;
  table-layout: fixed;
}

.site-header.header-style-5 .ajax-search-results:after, .site-header.header-style-5 .main-menu.menu > li.current-menu-item > a:before, .site-header.header-style-5 .main-menu.menu > li.current-menu-parent > a:before, .site-header.header-style-5 .main-menu.menu > li:hover > a:before, .site-header.header-style-5 .shop-cart-container .cart-box:after {
  height: 2px;
  bottom: -1px;
}

.site-header.header-style-5 .main-menu.menu > li.current-menu-item > a, .site-header.header-style-5 .main-menu.menu > li.current-menu-parent > a, .site-header.header-style-5 .main-menu.menu > li:hover > a {
  background: rgba(0, 0, 0, 0.05);
}

.site-header.header-style-5 .main-menu.menu > li.menu-item-has-children > a:after {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.21);
}

.site-header.header-style-5 .content-wrap > .bs-pinning-wrapper > .bs-pinning-block {
  box-shadow: none;
  background: #fff;
}

.site-header.header-style-5.full-width .content-wrap.bs-pinning-block.pinned {
  max-width: 100% !important;
}

.site-header.header-style-5.full-width .content-wrap.pinned {
  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid #eee;
}

.site-header.header-style-5 .main-menu.menu, .site-header.header-style-6 .main-menu.menu, .site-header.header-style-8 .main-menu.menu {
  height: 79px;
  overflow-y: hidden;
  margin-bottom: -1px;
}

.site-header.header-style-6 .main-menu.menu {
  height: 91px;
}

.site-header.header-style-8 .main-menu.menu {
  height: 67px;
}

.site-header.header-style-5 .main-menu.menu .mega-menu, .site-header.header-style-5 .main-menu.menu > li .sub-menu, .site-header.header-style-6 .main-menu.menu .mega-menu, .site-header.header-style-6 .main-menu.menu > li .sub-menu, .site-header.header-style-8 .main-menu.menu .mega-menu, .site-header.header-style-8 .main-menu.menu > li .sub-menu {
  top: 101%;
  top: calc(100% + 1px);
}

.site-header.header-style-5 .main-menu.menu > li .sub-menu .sub-menu, .site-header.header-style-6 .main-menu.menu > li .sub-menu .sub-menu, .site-header.header-style-8 .main-menu.menu > li .sub-menu .sub-menu {
  top: 0;
}

.site-header.header-style-5 .main-menu-container.menu-actions-btn-width-1, .site-header.header-style-6 .main-menu-container.menu-actions-btn-width-1, .site-header.header-style-8 .main-menu-container.menu-actions-btn-width-1 {
  padding-right: 40px;
}

.site-header.header-style-5 .main-menu-container.menu-actions-btn-width-2, .site-header.header-style-6 .main-menu-container.menu-actions-btn-width-2, .site-header.header-style-8 .main-menu-container.menu-actions-btn-width-2 {
  padding-right: 80px;
}

.site-header.header-style-5 .main-menu-container.menu-actions-btn-width-3, .site-header.header-style-6 .main-menu-container.menu-actions-btn-width-3, .site-header.header-style-8 .main-menu-container.menu-actions-btn-width-3 {
  padding-right: 120px;
}

.site-header.header-style-5 .main-menu-container.search-open .search-container {
  width: 66.66%;
  line-height: 78px;
  right: 0;
  left: auto;
}

.site-header.header-style-6 .main-menu-container.search-open .search-container {
  width: 66.66%;
  line-height: 90px;
  right: 0;
  left: auto;
}

.site-header.header-style-8 .main-menu-container.search-open .search-container {
  width: 75%;
  line-height: 66px;
  right: 0;
  left: auto;
}

.site-header.header-style-5 .main-menu-container.search-open .main-menu.menu, .site-header.header-style-6 .main-menu-container.search-open .main-menu.menu, .site-header.header-style-8 .main-menu-container.search-open .main-menu.menu {
  opacity: 0;
  pointer-events: none;
}

.site-header.header-style-5 .menu-action-buttons > div {
  line-height: 78px;
}

.site-header.header-style-6 .menu-action-buttons > div {
  line-height: 90px;
}

.site-header.header-style-8 .menu-action-buttons > div {
  line-height: 66px;
}

.site-header.header-style-5 .shop-cart-container .cart-box, .site-header.header-style-6 .shop-cart-container .cart-box, .site-header.header-style-8 .shop-cart-container .cart-box {
  line-height: 22px;
}

.site-header.header-style-5 .menu-action-buttons .off-canvas-menu-icon, .site-header.header-style-8 .menu-action-buttons .off-canvas-menu-icon {
  margin-top: -2px;
}

.site-header.header-style-5 .search-container .search-box .search-form .search-field {
  height: 78px;
}

.site-header.header-style-6 .search-container .search-box .search-form .search-field {
  height: 90px;
}

.site-header.header-style-8 .search-container .search-box .search-form .search-field {
  height: 66px;
}

.site-header.header-style-5 .shop-cart-container .cart-box:after, .site-header.header-style-6 .shop-cart-container .cart-box:after, .site-header.header-style-8 .shop-cart-container .cart-box:after {
  content: "";
  height: 2px;
  position: absolute;
  right: 0;
  left: 0;
  top: -1px;
  opacity: 1;
  visibility: visible;
}

.site-header.header-style-6 .header-inner {
  padding: 0;
  position: relative;
}

.site-header.header-style-6.boxed .header-inner, .site-header.header-style-6.full-width {
  border-bottom: 1px solid #eee;
}

.site-header.header-style-6 .main-menu.menu {
  float: right;
}

.site-header.header-style-6 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0;
}

.site-header.header-style-6 .main-menu.menu > li > a {
  padding: 0 15px;
}

.site-header.header-style-6 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-6 .site-branding {
  width: 23.333%;
  float: left;
  line-height: 90px;
}

.site-header.header-style-6 .main-menu-container {
  width: 76.666%;
  float: right;
}

.site-header.header-style-6 .main-menu > li > a {
  line-height: 90px;
}

.site-header.header-style-6 .site-branding .logo {
  display: table;
  width: 100%;
  line-height: 86px;
  height: 86px;
}

.site-header.header-style-6 .site-branding .logo.img-logo a {
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px 0 0;
}

.site-header.header-style-6 .site-branding .logo.text-logo a {
  vertical-align: middle;
  padding: 5px 10px 5px 0;
}

.site-header.header-style-6 .site-branding .logo img {
  vertical-align: middle;
  margin: 0;
  display: table-cell;
  max-width: 100%;
  table-layout: fixed;
}

.site-header.header-style-6 .site-branding .logo img {
  padding: 0;
  line-height: 88px;
  max-height: 88px;
}

.site-header.header-style-6 .main-menu.menu > li > a > .bf-icon {
  position: absolute;
  width: 100%;
  top: 24px;
  left: 0;
  text-align: center;
  margin-right: 0;
  font-size: 16px;
}

.site-header.header-style-6 .main-menu.menu > li > a > .bf-icon img {
  vertical-align: top;
}

.site-header.header-style-6 .main-menu.menu > li > a > .bf-icon.bf-custom-icon {
  top: 20px;
}

.site-header.header-style-6 .main-menu.menu > li.menu-have-icon > a {
  padding-top: 24px;
  line-height: 66px;
}

.site-header.header-style-6 .main-menu.menu > li.current-menu-item > a:before, .site-header.header-style-6 .main-menu.menu > li.current-menu-parent > a:before, .site-header.header-style-6 .main-menu.menu > li:hover > a:before {
  height: 2px;
  bottom: -1px;
}

.site-header.header-style-6 .main-menu.menu > li.current-menu-item > a, .site-header.header-style-6 .main-menu.menu > li.current-menu-parent > a, .site-header.header-style-6 .main-menu.menu > li:hover > a {
  background: rgba(0, 0, 0, 0.05);
}

.site-header.header-style-6 .main-menu.menu > li.menu-item-has-children > a:after {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.21);
}

.site-header.header-style-6 .content-wrap > .bs-pinning-wrapper > .bs-pinning-block {
  box-shadow: none;
  background: #fff;
}

.site-header.header-style-6.full-width .content-wrap {
  max-width: 100% !important;
}

.site-header.header-style-6.full-width .content-wrap.pinned {
  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid #eee;
}

.site-header.header-style-7 .header-inner {
  padding: 50px 0;
}

.site-header.header-style-7.h-a-ad .header-inner {
  padding: 30px 0;
}

.site-header.header-style-7 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
}

.site-header.header-style-7 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.boxed.header-style-7 .main-menu-wrapper .main-menu-container, .site-header.full-width.header-style-7 .main-menu-wrapper {
  border-bottom-width: 1px;
  border-top: none;
  border-bottom-color: #ececec;
}

.site-header.header-style-7 .site-branding {
  text-align: center;
}

.site-header.header-style-7 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-7 .main-menu.menu {
  height: 63px;
  overflow-y: hidden;
  margin-bottom: -16px;
  padding-bottom: 15px;
}

.site-header.header-style-7.boxed .main-menu.menu .mega-menu, .site-header.header-style-7.boxed .main-menu.menu > li .sub-menu {
  top: 102%;
  top: calc(100% + 1px);
}

.site-header.header-style-7.full-width .main-menu.menu .mega-menu, .site-header.header-style-7.full-width .main-menu.menu > li .sub-menu {
  top: 76%;
  top: calc(100% - 15px);
}

.site-header.header-style-7.full-width .main-menu-wrapper.pinned .main-menu.menu .mega-menu, .site-header.header-style-7.full-width .main-menu-wrapper.pinned .main-menu.menu > li .sub-menu {
  top: 100%;
}

.site-header.header-style-7.full-width .menu-action-buttons {
  bottom: 15px;
}

.site-header.header-style-7 .main-menu-wrapper.menu-actions-btn-width-1 .main-menu-container {
  padding-right: 40px;
}

.site-header.header-style-7 .main-menu-wrapper.menu-actions-btn-width-2 .main-menu-container {
  padding-right: 80px;
}

.site-header.header-style-7 .main-menu-wrapper.menu-actions-btn-width-3 .main-menu-container {
  padding-right: 120px;
}

.site-header.header-style-7 .bs-pinning-block.pinned .main-menu.menu {
  height: 48px;
  margin-bottom: -1px;
  padding-bottom: 0;
}

.site-header.header-style-7.full-width .bs-pinning-block.pinned .menu-action-buttons {
  bottom: 0;
}

.site-header.header-style-8 .header-inner {
  padding: 0;
  position: relative;
}

.site-header.header-style-8.boxed .header-inner, .site-header.header-style-8.full-width {
  border-bottom: 1px solid #eee;
}

.site-header.header-style-8 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
}

.site-header.header-style-8 .main-menu.menu > li > a {
  padding: 0 7px;
}

.site-header.header-style-8 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-8 .site-branding {
  width: 20%;
  float: left;
  padding-right: 20px;
}

.site-header.header-style-8 .main-menu-container {
  width: 80%;
  float: right;
}

.site-header.header-style-8 .main-menu > li > a {
  line-height: 66px;
}

.site-header.header-style-8 .site-branding .logo {
  line-height: 58px;
  display: table;
  width: 100%;
}

.site-header.header-style-8 .site-branding .logo.img-logo a {
  display: table-cell;
  vertical-align: middle;
  line-height: 62px;
  height: 62px;
  padding: 0 10px 0 0;
  position: relative;
  z-index: 100;
}

.site-header.header-style-8 .site-branding .logo.text-logo a {
  vertical-align: middle;
  padding: 5px 10px 5px 0;
}

.site-header.header-style-8 .site-branding .logo img {
  vertical-align: middle;
  margin: 0;
  max-height: 100%;
  display: table-cell;
  max-width: 100%;
  table-layout: fixed;
}

.site-header.header-style-8 .site-branding .logo, .site-header.header-style-8 .site-branding .logo img {
  max-height: 58px;
  padding: 0;
}

.site-header.header-style-8 .main-menu.menu > li.current-menu-item > a:before, .site-header.header-style-8 .main-menu.menu > li.current-menu-parent > a:before, .site-header.header-style-8 .main-menu.menu > li:hover > a:before {
  height: 2px;
  bottom: -1px;
}

.site-header.header-style-8 .main-menu.menu > li.menu-item-has-children > a:after {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.21);
}

.site-header.header-style-8 .content-wrap > .bs-pinning-wrapper > .bs-pinning-block {
  box-shadow: none;
  background: #fff;
}

.site-header.header-style-8.full-width .content-wrap {
  max-width: 100% !important;
}

.site-header.header-style-8.full-width .content-wrap.pinned {
  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid #eee;
}

@media only screen and (max-width: 992px) {
  .main-menu > li {
    margin-right: 10px;
  }
  .site-header.header-style-1 .main-menu.menu > li {
    margin: 0 4px;
  }
  .site-header.header-style-3 .main-menu.menu > li, .site-header.header-style-4 .main-menu.menu > li, .site-header.header-style-8 .main-menu.menu > li {
    margin: 0 7px 0 0;
  }
  .site-header.header-style-5 .main-menu.menu > li > a, .site-header.header-style-6 .main-menu.menu > li > a, .site-header.header-style-8 .main-menu.menu > li > a {
    padding: 0 7px;
  }
  .main-menu > li > a {
    padding: 0 4px;
  }
}
.rh-header {
  display: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 300px;
  position: relative;
}

.rh-header .rh-container {
  background-color: #222;
  user-select: none;
}

@media only screen and (max-width: 700px) {
  body .site-header, body.bs-hide-ha .site-header {
    display: none;
  }
  body.bs-show-ha .site-header {
    display: block;
  }
  body.bs-show-ha .site-header .bs-pinning-wrapper, body.bs-show-ha .site-header .header-inner .logo-col, body.bs-show-ha .site-header .main-menu-wrapper, body.bs-show-ha .site-header .topbar {
    display: none;
  }
  body.bs-show-ha .site-header .header-inner .sidebar-col, body.bs-show-ha .site-header > .header-inner .row-height {
    display: block;
    height: auto;
    width: 100%;
  }
  body.bs-show-ha .site-header.header-style-2 .header-inner, body.bs-show-ha .site-header.header-style-3 .header-inner {
    padding: 15px 0;
  }
  body.bs-show-ha-a .main-wrap {
    display: flex;
    flex-direction: column;
  }
  body.bs-show-ha-a .main-wrap .rh-header {
    order: 1;
  }
  body.bs-show-ha-a .main-wrap .site-header {
    order: 2;
  }
  body.bs-show-ha-a .main-wrap > * {
    order: 3;
  }
  body.bs-show-ha.bs-show-ha-a .site-header.header-style-2 .header-inner, body.bs-show-ha.bs-show-ha-a .site-header.header-style-3 .header-inner {
    padding: 15px 0 0;
  }
  .rh-header {
    display: block;
  }
}
.rh-header .menu-container {
  position: absolute;
  top: 0;
  right: 0;
}

.rh-header .menu-container .menu-handler {
  color: #fff;
  width: 54px;
  height: 54px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  float: right;
}

.rh-header .menu-container .menu-handler .lines {
  display: inline-block;
  width: 22px;
  height: 2px;
  background: #ecf0f1;
  position: relative;
  margin-top: 25.5px;
}

.rh-header .menu-container .menu-handler .lines:after, .rh-header .menu-container .menu-handler .lines:before {
  display: inline-block;
  width: 22px;
  height: 2px;
  background: #ecf0f1;
  position: absolute;
  left: 0;
  content: "";
}

.rh-header .menu-container .menu-handler .lines:before {
  top: 8px;
}

.rh-header .menu-container .menu-handler .lines:after {
  top: -8px;
}

.rh-cover .rh-c-m .resp-menu li {
  display: block;
  line-height: 46px;
  float: none;
  position: relative;
}

.rh-cover .rh-c-m .resp-menu .sub-menu a > .fa {
  min-width: 12px;
}

.rh-cover .rh-c-m .resp-menu li a > .bf-icon {
  display: inline-block;
  min-width: 22px;
  margin: 0;
}

.rh-cover .rh-c-m .resp-menu li > a {
  padding: 0 0 0 3px;
  color: #fff;
  display: block;
  position: relative;
  font-size: 14px;
  border-bottom: none;
  transition: none;
}

.rh-cover .rh-c-m .resp-menu > li > a {
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
}

.rh-cover .rh-c-m .resp-menu li.menu-item-has-children.open-sub > a {
  border-bottom-color: transparent !important;
}

.rh-cover .rh-c-m .resp-menu .children-button {
  position: absolute;
  right: 0;
  top: 11px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
  color: silver;
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  -moz-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  opacity: 0.5;
}

.rh-cover .rh-c-m .resp-menu + .resp-menu .children-button {
  top: 8px;
}

.rh-cover .rh-c-m .resp-menu li.open-sub > .children-button {
  background: rgba(0, 0, 0, 0.13);
}

.rh-cover .rh-c-m .resp-menu li li .children-button {
  top: 1px;
}

.rh-cover .rh-c-m .resp-menu .children-button:after, .rh-cover .rh-c-m .resp-menu .children-button:before {
  content: "";
  position: absolute;
  top: 11px;
  right: 6px;
  width: 11px;
  height: 2px;
  display: inline-block;
  background: #fff;
  -webkit-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  -moz-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
}

.rh-cover .rh-c-m .resp-menu .children-button:after {
  transform: rotate(90deg);
}

.rh-cover .rh-c-m .resp-menu .open-sub > .children-button {
  opacity: 0.75;
}

.rh-cover .rh-c-m .resp-menu .open-sub > .children-button:after {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
}

.rh-cover .rh-c-m .resp-menu li.menu-item-has-children > a:after {
  display: none;
}

.rh-cover .rh-c-m .resp-menu li:hover > .mega-menu, .rh-cover .rh-c-m .resp-menu li:hover > .sub-menu {
  display: none;
}

.rh-cover .rh-c-m .resp-menu .mega-menu, .rh-cover .rh-c-m .resp-menu .sub-menu {
  display: none;
  visibility: visible;
  opacity: 1 !important;
  transform: none !important;
  transition: none !important;
  animation-name: none !important;
  position: relative;
  top: inherit !important;
  left: inherit !important;
  right: auto !important;
  background-color: transparent;
  width: 100%;
  margin-top: 1px;
  z-index: 1;
}

.rh-cover .rh-c-m .resp-menu .sub-menu > li:last-child > a {
  border-bottom: 2px solid rgba(255, 255, 255, 0.04) !important;
}

.rh-cover .rh-c-m .resp-menu .sub-menu > li.open-sub:last-child > a {
  border-bottom-color: transparent !important;
}

.rh-cover .rh-c-m .resp-menu .sub-menu .sub-menu {
  margin-top: 2px;
}

.rh-cover .rh-c-m .resp-menu .sub-menu > li > a {
  font-size: 13px;
}

.rh-cover .rh-c-m .resp-menu .mega-menu, .rh-cover .rh-c-m .resp-menu .sub-menu {
  padding-left: 22px;
}

.rh-cover .rh-c-m .resp-menu li li {
  line-height: 28px;
}

.rh-cover .rh-c-m .resp-menu .sub-menu > li:last-child > a {
  padding-bottom: 8px;
  margin-bottom: 5px;
}

.rh-cover .rh-c-m .resp-menu .better-custom-badge {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 11px;
  padding: 0 5px;
  font-weight: 600;
  letter-spacing: 1px;
  z-index: 1;
  text-transform: uppercase;
  text-align: center;
  min-width: 19px;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  float: right;
  margin: 6px 6px 0 0;
  background-color: transparent !important;
  opacity: 0.6;
}

.rh-cover .rh-c-m .resp-menu li.menu-item-has-children > a > .better-custom-badge {
  margin-right: 30px;
}

.rh-cover .rh-c-m .resp-menu li li.menu-item-has-children > a > .better-custom-badge {
  margin-top: 8px;
}

.rh-cover .rh-c-m .resp-menu + .resp-menu li {
  line-height: 40px;
}

.rh-cover .rh-c-m .resp-menu + .resp-menu li > a {
  font-size: 12px;
}

.rh-cover .search-form {
  margin-top: 35px;
  position: relative;
}

.rh-cover .search-form input[type=search] {
  margin: 0;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  float: none;
  webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.rh-cover .search-form input[type=submit] {
  content: "\f002";
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 16px;
  background: 0 0 !important;
}

.rh-cover .search-form ::-webkit-input-placeholder {
  color: #fff;
}

.rh-cover .search-form ::-moz-placeholder {
  color: #fff;
}

.rh-cover .search-form :-ms-input-placeholder {
  color: #fff;
}

.rh-cover .search-form :-moz-placeholder {
  color: #fff;
}

.rh-cover .better-social-counter {
  margin: 20px 0 0 !important;
}

.rh-cover .better-social-counter.style-button .social-list {
  text-align: center;
}

.rh-cover .better-social-counter.style-button .social-item {
  float: none;
  display: inline-block;
  margin: 0 4px 4px;
}

.rh-cover .better-social-counter.style-button .item-icon {
  width: 42px;
  height: 42px;
  line-height: 38px;
  color: #fff;
  background-color: #a3a3a3;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0) !important;
  border: 3px solid rgba(255, 255, 255, 0.25);
  font-size: 16px;
}

.rh-cover .better-social-counter.style-button .social-item:hover .item-icon {
  border-color: rgba(255, 255, 255, 0.45);
}

.rh-cover.no-login-icon .better-social-counter.style-button .social-list {
  position: absolute;
  top: 9px;
  max-width: 80%;
  height: 45px;
  overflow: hidden;
  text-align: left;
}

.rh-cover .rh-c-m .resp-menu .mega-menu {
  display: none;
  visibility: visible;
  opacity: 1;
  background-color: transparent;
  position: inherit;
  left: 0;
  top: 0;
  border: none;
  box-shadow: none;
}

.rh-cover .rh-c-m .resp-menu .mega-menu .container {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link {
  padding: 0;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link .mega-links > li {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link .mega-links li a {
  padding-bottom: 0;
  margin-bottom: 0;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link .mega-links li .sub-menu {
  display: none !important;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link .mega-links li.open-sub > .sub-menu {
  display: block !important;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link-list .mega-links li {
  padding: 0;
  text-align: left;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link-list .mega-links > li .sub-menu {
  position: relative;
  top: 0 !important;
  left: 0 !important;
  background-color: transparent !important;
  text-align: left !important;
  box-shadow: none !important;
  padding: 6px 0 0 22px;
  display: none !important;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link-list .mega-links > li .sub-menu > li {
  display: block;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-grid-posts .post {
  width: 100%;
  padding: 0 20px;
  float: none;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-grid-posts .post:nth-child(2), .rh-cover .rh-c-m .resp-menu .mega-menu.mega-grid-posts .post:nth-child(3), .rh-cover .rh-c-m .resp-menu .mega-menu.mega-grid-posts .post:nth-child(4) {
  display: none;
}

.rh-cover .mega-menu.mega-type-link-list .mega-links > li:hover > a {
  color: #fff !important;
}

.rh-header .logo-container {
  padding: 0 0 0 15px;
  line-height: 54px;
  height: 54px;
}

.rh-header .logo-container.rh-img-logo {
  font-size: 0;
}

.rh-header .logo-container img {
  max-height: 80%;
  max-width: 80%;
}

.rh-header .logo-container a {
  color: #fff;
}

.rh-cover {
  position: fixed;
  top: 0;
  left: -99%;
  z-index: 9999;
  width: 100%;
  -webkit-transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
  -moz-transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
  transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
  visibility: hidden;
  overflow: auto;
  background: #191919;
  height: 101%;
  height: calc(100% + 1px);
}

body.open-rh .rh-cover {
  left: 0;
  visibility: visible;
}

body.admin-bar .rh-cover {
  top: 46px;
}

body.mce-content-body {
  background-color: #fff !important;
}

.noscroll {
  overflow: hidden;
}

@media (max-width: 767px) {
  body {
    background-color: #f3f3f3 !important;
  }
  .main-wrap {
    background-color: #fff;
    overflow: auto;
    margin: auto;
    width: 100%;
  }
}
.rh-cover.gr-1 {
  background-image: -moz-linear-gradient(45deg, #001fd3 0, #2d9dfb 100%);
  background-image: -webkit-linear-gradient(45deg, #001fd3 0, #2d9dfb 100%);
  background-image: -ms-linear-gradient(45deg, #001fd3 0, #2d9dfb 100%);
}

.rh-cover.gr-2 {
  background-image: -moz-linear-gradient(85deg, #b83917 0, #f7ae15 100%);
  background-image: -webkit-linear-gradient(85deg, #b83917 0, #f7ae15 100%);
  background-image: -ms-linear-gradient(85deg, #b83917 0, #f7ae15 100%);
}

.rh-cover.gr-3 {
  background-image: -moz-linear-gradient(85deg, #9234ef 0, #22c1c3 100%);
  background-image: -webkit-linear-gradient(85deg, #9234ef 0, #22c1c3 100%);
  background-image: -ms-linear-gradient(85deg, #9234ef 0, #22c1c3 100%);
}

.rh-cover.gr-4 {
  background-image: -moz-linear-gradient(85deg, #c23337 0, #d45f25 100%);
  background-image: -webkit-linear-gradient(85deg, #c23337 0, #d45f25 100%);
  background-image: -ms-linear-gradient(85deg, #c23337 0, #d45f25 100%);
}

.rh-cover.gr-5 {
  background-image: -moz-linear-gradient(85deg, #0a58c0 0, #00ade1 100%);
  background-image: -webkit-linear-gradient(85deg, #0a58c0 0, #00ade1 100%);
  background-image: -ms-linear-gradient(85deg, #0a58c0 0, #00ade1 100%);
}

.rh-cover.gr-6 {
  background-image: -moz-linear-gradient(85deg, #0b5c5e 0, #28ae92 100%);
  background-image: -webkit-linear-gradient(85deg, #0b5c5e 0, #28ae92 100%);
  background-image: -ms-linear-gradient(85deg, #0b5c5e 0, #28ae92 100%);
}

.rh-cover.gr-7 {
  background-image: -moz-linear-gradient(85deg, #880d2c 0, #1d70bf 100%);
  background-image: -webkit-linear-gradient(85deg, #880d2c 0, #1d70bf 100%);
  background-image: -ms-linear-gradient(85deg, #880d2c 0, #1d70bf 100%);
}

.rh-cover.gr-8 {
  background-image: -moz-linear-gradient(85deg, #191b1d 0, #525759 100%);
  background-image: -webkit-linear-gradient(85deg, #191b1d 0, #525759 100%);
  background-image: -ms-linear-gradient(85deg, #191b1d 0, #525759 100%);
}

.rh-cover.bg-img:before {
  opacity: 0.13;
}

.rh-cover.bg-img:after {
  content: "";
  display: inline-block;
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  bottom: 0;
  background: #000;
  opacity: 0.4;
  z-index: -2;
  -webkit-transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
  -moz-transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
  transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
}

.close-rh .rh-cover.bg-img:after {
  left: -100%;
}

.open-rh .rh-cover.bg-img:after {
  left: 0;
}

.rh-cover:before {
  height: 60px;
  content: "";
  width: 100%;
  position: absolute;
  background-color: #fff;
  opacity: 0.08;
  box-shadow: 0 0 32px 0 #000;
  z-index: -1;
}

.rh-cover .rh-close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  display: inline-block;
  top: 21px;
  cursor: pointer;
  z-index: 10;
}

.rh-cover .rh-close:after, .rh-cover .rh-close:before {
  content: "";
  width: 2px;
  height: 18px;
  position: absolute;
  left: 50%;
  margin-left: -1px;
  top: 50%;
  margin-top: -10px;
  opacity: 0.65;
  background: #fff;
  transform: rotate(45deg);
  border-radius: 5px;
}

.rh-cover .rh-close:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.rh-cover .rh-panel {
  padding: 27px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.rh-cover .rh-panel.rh-p-u {
  display: none;
}

.rh-cover .rh-p-h {
  line-height: 60px;
  margin: -27px -27px 20px;
  padding: 0 23px;
  font-size: 14px;
  color: #fff;
  height: 60px;
  position: relative;
}

.rh-cover .rh-p-h a {
  color: #fff;
}

.rh-cover .rh-p-h .user-login {
  padding-left: 40px;
  position: relative;
  cursor: pointer;
}

.rh-cover .rh-p-h .user-login .user-avatar {
  border: 2px solid rgba(255, 255, 255, 0.16);
  border-radius: 50%;
  overflow: hidden;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 0;
  text-align: center;
}

.rh-cover .rh-p-h .user-login .user-avatar img {
  vertical-align: top;
}

.rh-cover .rh-p-h .user-login .user-avatar .fa {
  font-size: 23px;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  width: 100%;
}

.rh-cover .rh-back-menu {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  opacity: 0.65;
  cursor: pointer;
}

.rh-cover .rh-back-menu:focus, .rh-cover .rh-back-menu:hover {
  opacity: 0.8;
}

.rh-cover .rh-back-menu:after, .rh-cover .rh-back-menu:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 2px;
  background: #fff;
  top: 10px;
  left: 6px;
  border-radius: 10px;
  -webkit-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  -moz-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
}

.rh-cover .rh-back-menu:before {
  transform: rotate(-40deg);
}

.rh-cover .rh-back-menu.abtn:before, .rh-cover .rh-back-menu:hover:before {
  transform: rotate(0);
  width: 17px;
  left: 7px;
  top: 7px;
}

.rh-cover .rh-back-menu:after {
  transform: rotate(40deg);
  top: 16px;
  left: 6px;
}

.rh-cover .rh-back-menu.abtn:after, .rh-cover .rh-back-menu:hover:after {
  transform: rotate(0);
  left: 7px;
  width: 17px;
  top: 19px;
}

.rh-cover .rh-back-menu i {
  width: 17px;
  height: 2px;
  display: inline-block;
  background: #fff;
  position: absolute;
  top: 13px;
  left: 7px;
  border-radius: 15px;
}

.rh-cover .bs-login-shortcode {
  background: 0 0;
  padding: 0 !important;
  margin: 25px 0 0 !important;
  border: none !important;
  box-shadow: none;
}

.rh-cover .bs-login .login-header, .rh-cover .bs-login .login-header .login-icon {
  color: #fff;
}

.rh-cover .bs-login .social-login-buttons li .social-login-btn.btn {
  background: rgba(255, 255, 255, 0.2) !important;
  height: 34px;
  line-height: 34px;
  border-radius: 3px;
  font-size: 13px;
  padding: 0;
  text-align: center;
}

.rh-cover .bs-login .social-login-btn.btn .bf-icon {
  width: auto;
  height: auto;
  position: inherit;
  background: 0 0;
  margin-right: 5px;
}

.rh-cover .bs-login .social-login-buttons {
  border-bottom: none;
  margin-bottom: 26px;
}

.rh-cover .bs-login .social-login-buttons .or-text:after, .rh-cover .bs-login .social-login-buttons .or-text:before {
  content: "";
  width: 100px;
  height: 1px;
  background: #fff;
  opacity: 0.1;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -0.5px;
  right: -101px;
}

.rh-cover .bs-login .social-login-buttons .or-text:after {
  left: -101px;
  right: inherit;
}

.rh-cover .bs-login .social-login-buttons .or-text {
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0.17);
  color: #fff;
  position: relative;
}

.rh-cover .bs-login input[type=password], .rh-cover .bs-login input[type=text] {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: #fff;
  box-shadow: none;
  -webkit-backface-visibility: hidden;
}

.rh-cover .bs-login input[type=password]::-webkit-input-placeholder, .rh-cover .bs-login input[type=text]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.55);
}

.rh-cover .bs-login input[type=password]::-moz-placeholder, .rh-cover .bs-login input[type=text]::-moz-placeholder {
  color: rgba(255, 255, 255, 0.55);
}

.rh-cover .bs-login input[type=password]:-ms-input-placeholder, .rh-cover .bs-login input[type=text]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.55);
}

.rh-cover .bs-login input[type=password]:-moz-placeholder, .rh-cover .bs-login input[type=text]:-moz-placeholder {
  color: rgba(255, 255, 255, 0.55);
}

.rh-cover .bs-login .remember-checkbox + .remember-label {
  color: rgba(255, 255, 255, 0.4);
}

.rh-cover .bs-login .remember-label {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAgCAYAAAAbifjMAAAAAXNSR0IArs4c6QAAAVlJREFUSA3tkzFLA0EQhXeVKzxSaSEWtmJ6wS4/w84fkdJKy/yFFFb+B8EqYC22gfRBG8FCT7Bw/d45d6zeafa8NgOPnZ197+0y7PgQwqZz7ggcgBykRAFpAe48BsckO+DWe//CujLQDCCNwJNjc2qFlcKYII20GxTz1JtjA9PkMugVawPn1j346kHx36/MDyzURE3VqIuJcTVMC01jr3HWNGZgApYgNcSVJpOBkmuwz5OSQlzTTGQgt2RxdYOZLGUQqmLXVdpOXxn+EFyCvfoyNkkvgCbxo/jEHKj5zRfooHa3hNqQdAZ2rfTK+lHmcrGi+nEC3sA4qsU3cxTuwbbOtambSL4F3lW0GLMegoeqwFqLGwZWOI/ISp+j/Tdxq4EVLyJRlTbEvxq0mLSK/zSwwzOuvwFlw1T7GXqemtjrK+snXoEpRsnzYNxpqWWjH6WJ1EtSQ1xpsk/Dn+01H7ZKAQAAAABJRU5ErkJggg==);
}

.rh-cover .bs-login .go-login-panel, .rh-cover .bs-login .go-reset-panel {
  color: rgba(255, 255, 255, 0.5);
}

.rh-cover .bs-login .go-login-panel:hover, .rh-cover .bs-login .go-reset-panel:hover {
  color: #fff;
}

.rh-cover .bs-login .bs-login-reset-panel .login-header .login-icon, .rh-cover .bs-login .remember-checkbox:checked + .remember-label {
  color: #fff;
}

.rh-cover .bs-login .bs-login-reset-panel .login-btn, .rh-cover .bs-login .login-btn {
  background: #fff !important;
  color: #000;
  -webkit-backface-visibility: hidden;
}

.rh-cover .bs-login .login-signup {
  color: rgba(255, 255, 255, 0.66);
}

.rh-cover .bs-login .login-signup a {
  color: #fff;
  text-decoration: underline;
}

.rh-cover .bs-login .user-links, .rh-cover .bs-login .user-links li {
  border-color: rgba(255, 255, 255, 0.4);
}

.rh-cover .bs-login .user-links a {
  color: #fff;
}

.rh-header.light .rh-container {
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.rh-header.light .rh-container.bs-pinning-block.pinned {
  border-bottom-color: transparent;
  -moz-box-shadow: 0 0 40px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.17);
}

.rh-header.light .logo-container a {
  color: #2c2c2c;
}

.rh-header.light .menu-container .menu-handler .lines, .rh-header.light .menu-container .menu-handler .lines:after, .rh-header.light .menu-container .menu-handler .lines:before {
  background: #2c2c2c;
}

body.bs-show-ha.bs-show-ha-b .site-header + .rh-header.light .rh-container {
  border-top: 1px solid #e5e5e5;
}

.main-menu .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  padding: 0;
  border: none;
  right: auto;
  left: auto;
}

.main-menu .bs-pretty-tabs-container {
  position: inherit;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links .sub-menu {
  position: absolute;
  box-shadow: none;
  padding: 0;
  width: 100%;
  left: 100%;
  top: 0 !important;
  background-color: #fff;
  -webkit-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
}

ul.sub-menu.bs-pretty-tabs-elements li.menu-item-mega-grid-posts > .mega-menu, ul.sub-menu.bs-pretty-tabs-elements li.menu-item-mega-grid-posts > a:after {
  display: none;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links .sub-menu .sub-menu.sub-menu, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links .sub-menu.sub-menu, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link-list .mega-links > li .sub-menu {
  top: inherit !important;
  position: absolute;
  margin-top: -39px;
  left: 100% !important;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.tabbed-grid-posts:before {
  display: none;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.tabbed-grid-posts .tab-content, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.tabbed-grid-posts .tabs-section li:first-child {
  display: none;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.tabbed-grid-posts .tabs-section {
  width: 100%;
  max-width: inherit;
  padding: 0;
  height: auto;
  float: none;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .content-wrap, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.tabbed-grid-posts .content-wrap {
  max-width: 100%;
  min-width: inherit;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links > li > a {
  color: #434343;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  width: auto;
  display: block;
  margin-bottom: 0;
  padding: 8px 16px;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link-list .mega-links > li, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link-list .mega-links > li .sub-menu > li {
  display: block;
  padding: 0;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link-list .mega-links > li > a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links > li:last-child > a, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link-list .mega-links > li:last-child > a {
  border-bottom: none;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links.link-2-column > li, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links.link-3-column > li, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links.link-4-column > li {
  width: 100%;
  float: none;
  padding: 0 !important;
  margin: 0 !important;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links > li:hover > a {
  color: #fff !important;
}

.off-canvas-overlay {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
  overflow-y: hidden;
}

.off-canvas-inner > div {
  padding: 10px 20px 14px;
  line-height: 1.6;
  color: #434343;
}

.off-canvas-container {
  visibility: hidden;
  left: -300px;
  top: 0;
  position: fixed;
  width: 300px;
  height: 100%;
  z-index: 999999;
  -webkit-transition: 0.6s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -moz-transition: 0.6s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -o-transition: 0.6s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  transition: 0.6s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
}

body.off-canvas-scroll {
  overflow-y: hidden;
}

body.off-canvas-scroll .off-canvas-container {
  overflow-y: scroll;
}

.off-canvas-container.closing {
  overflow-y: hidden !important;
}

.off-canvas-menu-open .off-canvas-container {
  width: 100%;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QA/wD/AP+gvaeTAAAA/UlEQVQ4jZXUvy4EQRwH8N9xvMI1aCWUCo/gX0h0nkWj1SiuQ4EQyYmCh/AIrnOeQI8EH8WtZEz2duem2uz+5jPf/c3sRkQE1rGNTkwxsIp9zAfW8GM8rjFbiOzgo5rXD2z6P64w04JsJQgMAh2cZtjFJAwbeE9qR1hIC44z7A7dDNnDZ1IzRK9utRwb/PUMu0VIA3ZZnCSD6nr2nVy/YLERaUnWmKRpm58iQnbvOSLepklzgK+aRHCPuRIkPbHwipsMa/4CapIM0as2oF+UbBKSPG/H2pAiDMtZT0ZYanj9Ds4z7CiM/ydFSIadJfMeA12c4NYUJ7bCDvGAlV8ryZTBSS7cPQAAAABJRU5ErkJggg==), no-drop;
}

.off-canvas-menu-open .off-canvas-container.left {
  left: 0;
  display: block;
  visibility: visible;
}

.off-canvas-container.right {
  left: inherit;
  right: -300px;
}

.off-canvas-menu-open .off-canvas-container.right {
  right: 0;
  display: block;
  visibility: visible;
}

.off-canvas-container:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 290px;
  background: #fff;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: default;
}

.off-canvas-inner {
  color: #434343;
  width: 290px;
  position: absolute;
  cursor: default;
  padding-top: 4px;
  background: #fff;
}

.off-canvas-inner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: 0 0;
  z-index: 1;
}

body.admin-bar .off-canvas-container {
  top: 32px;
}

.off-canvas-header {
  text-align: center;
  line-height: 1.5;
  padding-bottom: 10px;
}

.off-canvas-header img {
  max-width: 100%;
  height: auto;
}

.off-canvas-header .logo {
  margin-top: 20px;
}

.off-canvas-header .site-name {
  font-weight: bolder;
  font-size: 18px;
  margin-top: 15px;
}

.off-canvas-header .site-name:first-child {
  margin-bottom: 10px;
  font-size: 22px;
}

.off-canvas-header .site-name:first-child:last-child {
  margin-bottom: 0;
}

.off-canvas-header .logo + .site-description {
  margin-top: 15px;
}

.off-canvas-header .site-description:first-child:last-child {
  margin-bottom: 10px;
  margin-top: 15px;
}

.off-canvas-menu > ul > li > a {
  text-transform: none !important;
}

.off-canvas-inner > .off-canvas-menu {
  padding: 20px 0 10px;
}

.off-canvas-inner > .off-canvas-search + .off-canvas-menu {
  padding-top: 0;
}

.off-canvas-inner > .off-canvas-header + .off-canvas-menu {
  padding-top: 8px;
}

.off-canvas-inner .canvas-close + .off-canvas-search {
  margin-top: 44px;
}

.off-canvas-menu ul.menu li, .off-canvas-menu ul.menu > li {
  display: block;
  float: none;
}

.off-canvas-menu ul.menu > li {
  padding: 0 20px;
}

.off-canvas-menu li > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 10px 0 !important;
}

.off-canvas-menu li:last-child > a {
  border-bottom: none !important;
}

.off-canvas-menu li li {
  padding: 0 18px !important;
}

.off-canvas-menu li li > a {
  padding: 8px 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07) !important;
}

.off-canvas-menu a {
  color: #444;
}

.off-canvas-menu ul.menu > li > a {
  display: block !important;
}

.off-canvas-menu ul.menu > li .sub-menu {
  top: -5px;
  left: 100%;
  background: #fff;
  border: 1px solid #d7d7d7;
  padding: 7px 0;
  border-radius: 3px;
}

.off-canvas-menu ul.menu > li .sub-menu .sub-menu {
  left: 100%;
  top: -7px !important;
}

.off-canvas-menu ul.menu > li .sub-menu:after, .off-canvas-menu ul.menu > li .sub-menu:before {
  border: solid 13px transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 13px;
  left: -27px;
  border-right-color: #d7d7d7;
}

.off-canvas-menu ul.menu > li .sub-menu:after {
  border: solid 12px transparent;
  border-right-color: #fff;
  top: 14px;
  left: -24px;
}

.off-canvas-menu .fa {
  margin-right: 5px;
}

.off-canvas-menu ul {
  padding: 0;
  margin-bottom: 0;
  position: relative;
}

.off-canvas-menu li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

.off-canvas-menu ul ul {
  display: none;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  position: absolute;
  left: 100%;
  width: 100%;
  top: 0;
  border: 1px solid #f5eaea;
  padding: 0 10px;
}

.off-canvas-menu ul.menu li.menu-item-has-children > a:after {
  content: "\f105";
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -13px;
}

.off-canvas-menu ul.menu li.menu-item-has-children.bsm-enter > a:after {
  opacity: 0;
  right: -8px;
}

.off-canvas-menu .menu .better-custom-badge {
  bottom: inherit;
  top: 50%;
  right: 0;
  left: inherit;
  display: inline-block;
  margin-top: -8px;
}

.off-canvas-menu .menu .menu-item-has-children > a > .better-custom-badge {
  right: 20px;
}

.off-canvas-menu .menu .better-custom-badge:after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  bottom: 4px;
  left: -8px;
}

.off-canvas-inner > .off-canvas-search:first-child {
  padding-top: 25px;
}

.off-canvas-search form {
  position: relative;
}

.off-canvas-search input[type=text] {
  padding-right: 25px;
  padding-left: 10px;
  box-shadow: none;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  font-size: 12px;
}

.off-canvas-search .fa {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: #8f8f8f;
}

.off-canvas-inner > .off_canvas_footer {
  padding-top: 18px;
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.off-canvas-inner > .off_canvas_footer:first-child {
  padding-top: 20px;
  margin-top: 0;
  border-top: none;
}

.off-canvas-inner > .off-canvas-search + .off_canvas_footer {
  margin-top: 14px;
}

.off-canvas-inner > .off_canvas_footer .better-social-counter.style-button:first-child .social-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.off-canvas-inner > .off_canvas_footer .better-social-counter.style-button:first-child .social-item {
  float: none;
  margin: 0 3px;
  display: inline;
}

.off_canvas_footer .better-social-counter.style-button .item-icon {
  border-radius: 50%;
}

.off-canvas-container .social-share-list {
  padding: 15px 0 0;
}

.off-canvas-container .social-share-list li {
  list-style: none;
  display: inline-block;
  margin-right: 3px;
}

.off-canvas-container .social-share-list a {
  background: #424242;
  color: #fff;
  padding: 4px 7px;
  border-radius: 3px;
}

.off-canvas-container.right {
  right: -300px;
  left: auto;
}

.off-canvas-container.right .off-canvas-inner, .off-canvas-container.right:before {
  right: 0;
  left: auto;
}

.off-canvas-container.right .off-canvas-menu ul ul {
  left: auto;
  right: 100%;
  box-shadow: -3px 0 10px 0 rgba(0, 0, 0, 0.1);
}

.off-canvas-container.right .off-canvas-menu ul {
  text-align: right;
}

.off-canvas-container.right ul.menu li.menu-item-has-children > a:after {
  content: "\f104";
  right: inherit;
  left: 0;
}

.off-canvas-container.right ul.menu li.menu-item-has-children.bsm-enter > a:after {
  right: auto;
  left: -8px;
}

.off-canvas-container.right .off-canvas-menu ul.menu li.menu-item-has-children.bsm-enter > a:after {
  right: auto;
  left: -8px;
}

.off-canvas-container.right .off-canvas-menu .menu .better-custom-badge {
  right: inherit;
  left: 0;
}

.off-canvas-container.right .off-canvas-menu .menu .menu-item-has-children > a > .better-custom-badge {
  right: inherit;
  left: 20px;
}

.off-canvas-container.right .off-canvas-menu .menu .better-custom-badge:after {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  left: auto;
  right: -8px !important;
}

.off-canvas-container.right .off_canvas_footer {
  text-align: right;
}

.off-canvas-container.right .better-social-counter.style-button .social-item {
  float: right;
  margin: 0 0 6px 4px;
}

.off-canvas-container.right ul.menu li.menu-have-icon > a > .bf-icon {
  float: right;
  margin: 4px 0 0 6px;
}

.off-canvas-container.right .off-canvas-menu ul.menu > li .sub-menu:after, .off-canvas-container.right .off-canvas-menu ul.menu > li .sub-menu:before {
  left: inherit;
  right: -27px;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: FlipH;
}

.off-canvas-container.right .off-canvas-menu ul.menu > li .sub-menu:after {
  left: inherit;
  right: -24px;
}

.off-canvas-container.right ul.menu > li .sub-menu .sub-menu {
  left: inherit;
  right: 100%;
}

.skin-dark.off-canvas-container .off-canvas-inner, .skin-dark.off-canvas-container:before {
  background: #151515;
}

.skin-dark.off-canvas-container .off-canvas-search input[type=text] {
  border-color: #272727;
  background-color: #151515;
  color: #fff;
}

.skin-dark.off-canvas-container .off-canvas-inner > div, .skin-dark.off-canvas-container .off-canvas-menu a {
  color: #fff;
}

.skin-dark.off-canvas-container .off-canvas-menu ul.menu > li .sub-menu {
  background: #151515;
  border-color: #232323;
}

.skin-dark.off-canvas-container .off-canvas-menu ul.menu > li .sub-menu:after, .skin-dark.off-canvas-container .off-canvas-menu ul.menu > li .sub-menu:before {
  border-right-color: #232323;
}

.skin-dark.off-canvas-container .off-canvas-menu li > a {
  border-bottom-color: rgba(255, 255, 255, 0.05) !important;
}

.skin-dark.off-canvas-container .off-canvas-inner > .off_canvas_footer {
  border-top-color: rgba(255, 255, 255, 0.07);
}

.search-header {
  margin: 0 0 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
}

.search-header .search-form {
  margin-bottom: 15px;
}

.search-header .found-categories ul, .search-header .found-tags ul {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-header .found-categories li, .search-header .found-tags li {
  display: inline-block;
  margin: 0 10px 0 0;
}

.search-header .label {
  font-weight: 700;
  font-size: 14px;
  color: #383838;
  padding: 0 10px 0 0;
}

.search-header .clean-button {
  line-height: 18px;
}

.search-header .found-categories li .clean-button, .search-header .found-tags li .clean-button {
  margin: 0;
}

.search-header .found-categories + .found-tags {
  margin-top: 15px;
}

.search-header .found-tags .clean-button {
  line-height: 22px;
  padding: 0 6px;
}

.topbar {
  height: 35px;
  overflow: hidden;
}

.topbar.use-pretty-tabs {
  z-index: 9999;
  position: relative;
  height: auto;
  overflow: visible;
}

.topbar ul.menu > li .sub-menu {
  top: 100%;
}

.topbar .topbar-inner {
  padding: 4px 0;
}

.topbar .section-links {
  float: right;
}

.topbar.topbar-style-1 .section-menu {
  height: 26px;
}

.topbar.topbar-style-1 .section-menu {
  max-width: 80%;
}

.topbar.topbar-style-1 .section-menu:first-child:last-child {
  max-width: 100%;
}

.site-header.boxed .topbar .topbar-inner {
  border-bottom: 1px solid #efefef;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.site-header.full-width .topbar {
  border-bottom: 1px solid #efefef;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.topbar .better-studio-shortcode {
  display: inline-block;
  float: right;
  margin-bottom: 0;
}

.topbar .better-studio-shortcode .social-list {
  display: inline-block;
  float: right;
}

.topbar .better-social-counter.style-button .social-item {
  margin: 0 0 0 4px;
}

.topbar .better-social-counter.style-button .social-item .item-icon {
  color: #444;
  background-color: transparent;
  font-size: 12px;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.topbar .better-social-counter.style-button .social-item:hover .item-icon {
  color: #545454;
}

.topbar .better-social-counter.style-button .social-item {
  float: left;
  font-size: 13px;
}

.topbar .better-social-counter.style-button a:hover {
  color: #fff;
}

.topbar .topbar-date {
  color: #fff;
  display: inline-block;
  padding: 3px 10px;
  line-height: 18px;
  position: relative;
  top: -1px;
}

.topbar .better-newsticker {
  margin-bottom: 0;
}

.topbar .better-newsticker.better-newsticker {
  margin-bottom: 0 !important;
}

.topbar .section-links + .section-menu .better-newsticker .control-nav {
  right: 30px;
}

.topbar .topbar-sign-in {
  float: right;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
}

.topbar .topbar-sign-in .fa {
  margin-right: 2px;
}

.topbar .topbar-sign-in.behind-social {
  margin-right: 8px;
  position: relative;
  padding-right: 8px;
}

.topbar .topbar-sign-in.behind-social:before {
  content: "";
  width: 1px;
  top: 50%;
  margin-top: -8px;
  height: 18px;
  display: inline-block;
  background: #dadada;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  right: -2px;
}

@media only screen and (max-width: 850px) {
  .topbar .better-newsticker ul.news-list li {
    width: 65%;
  }
}
body {
  background: #fff;
  text-decoration-skip: ink;
}

body.boxed {
  background-color: #eee;
}

body.boxed .bs-vc-content > .vc_row > .bs-vc-wrapper, body.boxed .content-wrap {
  padding-left: 5px;
  padding-right: 5px;
}

body.boxed .bf-breadcrumb > .bf-breadcrumb-container {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

body.boxed .mega-menu .content-wrap {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

body.boxed .slider-container .content-wrap {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 980px) {
  body.boxed .bs-vc-content > .vc_row > .bs-vc-wrapper, body.boxed .content-wrap, body.boxed .mega-menu .content-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}
.main-wrap {
  margin: 0 auto;
}

.content-wrap, .main-wrap, main.content-container {
  position: relative;
}

body.active-top-line {
  border-top: 3px solid transparent;
}

.main-wrap .container {
  width: 100%;
}

body.full-width .main-wrap {
  width: 100%;
}

@media (min-width: 768px) {
  .boxed .main-wrap {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  .boxed .main-wrap {
    max-width: 100% !important;
  }
}
body.boxed .main-wrap {
  padding: 0;
  background-color: #fff;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.27);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.27);
}

@media only screen and (max-width: 480px) {
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .vc_row .bs-vc-wrapper > .bs-vc-column {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.content-wrap {
  margin: 0 auto;
  padding: 0;
  min-width: 300px;
}

.container {
  width: 100%;
}

.page-layout-3-col-0 .content-column {
  width: 100% !important;
}

.page-layout-3-col-0.page-layout-3-col-0 .main-section {
  margin-bottom: 0 !important;
}

.listing .col-lg-1, .listing .col-lg-10, .listing .col-lg-11, .listing .col-lg-12, .listing .col-lg-2, .listing .col-lg-3, .listing .col-lg-4, .listing .col-lg-5, .listing .col-lg-6, .listing .col-lg-7, .listing .col-lg-8, .listing .col-lg-9, .listing .col-md-1, .listing .col-md-10, .listing .col-md-11, .listing .col-md-12, .listing .col-md-2, .listing .col-md-3, .listing .col-md-4, .listing .col-md-5, .listing .col-md-6, .listing .col-md-7, .listing .col-md-8, .listing .col-md-9, .listing .col-sm-1, .listing .col-sm-10, .listing .col-sm-11, .listing .col-sm-12, .listing .col-sm-2, .listing .col-sm-3, .listing .col-sm-4, .listing .col-sm-5, .listing .col-sm-6, .listing .col-sm-7, .listing .col-sm-8, .listing .col-sm-9, .listing .col-xs-1, .listing .col-xs-10, .listing .col-xs-11, .listing .col-xs-12, .listing .col-xs-2, .listing .col-xs-3, .listing .col-xs-4, .listing .col-xs-5, .listing .col-xs-6, .listing .col-xs-7, .listing .col-xs-8, .listing .col-xs-9 {
  padding-left: 15px;
  padding-right: 15px;
}

.listing .row {
  margin-left: -15px;
  margin-right: -15px;
}

.term-badges .format-badge, .term-badges .term-badge {
  display: inline-block;
  line-height: 0;
}

.term-badges a {
  color: #8b8b8b;
  padding: 0 0 4px;
}

.term-badges .fa {
  margin-right: 2px;
}

.term-badges.floated a {
  padding: 2px 7px;
  color: #fff;
  margin-right: 3px;
  margin-bottom: 3px;
  display: inline-block;
  line-height: 1.7;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.term-badges.floated {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  line-height: 0;
  font-size: 0;
}

.term-badges.text-badges, .term-badges.text-badges .term-badge {
  line-height: 1.4;
}

.term-badges.text-badges .term-badge {
  margin-right: 10px;
}

.term-badges.text-badges .term-badge:last-child {
  margin-right: 0;
}

.term-badges.text-badges a {
  background-color: transparent !important;
  font-weight: 500;
  padding: 0;
}

.format-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  pointer-events: none;
  width: 32px;
  height: 32px;
  border: 2px solid #fff;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  -webkit-transition: 0.43s cubic-bezier(0.47, 0.13, 0.16, 0.72) 0s;
  -moz-transition: 0.43s cubic-bezier(0.47, 0.13, 0.16, 0.72) 0s;
  -o-transition: 0.43s cubic-bezier(0.47, 0.13, 0.16, 0.72) 0s;
  transition: 0.43s cubic-bezier(0.47, 0.13, 0.16, 0.72) 0s;
}

.format-icon .fa {
  line-height: 28px;
  width: 100%;
  text-align: center;
}

.format-icon.format-audio {
  padding-right: 2px;
}

.format-icon.format-video {
  padding-left: 2px;
}

.post-summary p:last-child {
  margin-bottom: 0;
}

.entry-content a.read-more, a.read-more {
  font-size: 12px;
  color: #fff;
  background: #434343;
  display: inline-block;
  padding: 3px 11px;
  text-transform: uppercase !important;
  margin-top: 13px;
}

.entry-content a.read-more, a.read-more:hover {
  color: #fff;
}

.img-holder {
  position: relative;
  width: 80px;
  display: inline-block;
  float: left;
  margin-right: 15px;
  transition: none;
}

.bs-ll-a .img-cont:before, .bs-ll-a .img-holder:before, .bs-ll-a img[data-src]:not(.b-loaded) {
  opacity: 1;
  background-color: #e9ebee;
  background-image: linear-gradient(to left, #e9ebee 0, #f4f5f6 20%, #e9ebee 40%, #e9ebee 100%);
  background-repeat: no-repeat;
  background-size: 1000px 900px;
  -webkit-animation: bs-lazy-anim 1s infinite linear forwards;
  -moz-animation: bs-lazy-anim 1s infinite linear forwards;
  animation: bs-lazy-anim 1.01s infinite linear forwards;
}

.bs-ll-d .img-cont, .bs-ll-d .img-holder {
  background-color: #f5f5f5;
}

.listing-mg-item .img-cont:before {
  content: "";
  display: inline-block;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.img-cont.b-error:before, .img-cont.b-loaded:before, .img-holder.b-error:before, .img-holder.b-loaded:before {
  background: 0 0;
  opacity: 0;
  animation: none;
}

.listing-item-blog-1 .featured .img-holder.b-error, .listing-item-blog-1 .featured .img-holder.b-loaded, .listing-item-blog-5 .featured .img-holder.b-error, .listing-item-blog-5 .featured .img-holder.b-loaded, .listing-item-tb-1 .img-holder.b-error, .listing-item-tb-1 .img-holder.b-loaded, .listing-item-tb-2 .img-holder.b-error, .listing-item-tb-2 .img-holder.b-loaded, .listing-item-tb-3 .img-holder.b-error, .listing-item-tb-3 .img-holder.b-loaded {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-position: top center !important;
}

@-webkit-keyframes bs-lazy-anim {
  from {
    background-position: -800px 0;
  }
  to {
    background-position: 400px 0;
  }
}
@keyframes bs-lazy-anim {
  from {
    background-position: -800px 0;
  }
  to {
    background-position: 400px 0;
  }
}
@-webkit-keyframes bs-lazy-anim-rtl {
  from {
    background-position: 400px 0;
  }
  to {
    background-position: -800px 0;
  }
}
@keyframes bs-lazy-anim-rtl {
  from {
    background-position: 400px 0;
  }
  to {
    background-position: -800px 0;
  }
}
.img-cont.img-cont.img-content-ni.img-content-ni, .img-holder.img-holder.img-content-ni.img-content-ni {
  background: 0 0 !important;
}

.img-holder.b-load-ready {
  opacity: 0;
}

.img-holder.b-error, .img-holder.b-loaded, body.bs-ll-d .img-holder {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  animation: none;
  background-position: top center;
}

.img-holder.b-loaded, body.bs-ll-d .img-holder {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}

.bs-pagination-cached .img-holder.b-loaded {
  transition: none;
}

.img-holder:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 70%;
}

.search-field::-ms-clear {
  display: none;
}

.modal {
  z-index: 99999;
}

.margin-sm-left {
  margin-left: 7px;
}

.margin-sm-right {
  margin-right: 7px;
}

.margin-xsm-left {
  margin-left: 3px;
}

.margin-xsm-right {
  margin-right: 3px;
}

.limit-line {
  overflow: hidden;
  white-space: nowrap;
}

.limit-dot-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.top-line {
  border-top: 1px solid #f4f4f4;
}

.bottom-line {
  border-bottom: 1px solid #f4f4f4;
}

li.bottom-line:last-child {
  border-bottom: none;
}

.no-list-style {
  list-style: none !important;
}

.no-list-style li {
  padding-right: 0 !important;
}

.no-list-style li:before {
  display: none !important;
}

.bs-padding-1-1 {
  padding-left: 5%;
  padding-right: 5%;
}

.bs-padding-0-1 {
  padding-right: 5%;
}

.bs-padding-1-0 {
  padding-left: 5%;
}

.bs-padding-2-2 {
  padding-left: 10%;
  padding-right: 10%;
}

.bs-padding-2-1 {
  padding-left: 10%;
  padding-right: 5%;
}

.bs-padding-1-2 {
  padding-left: 5%;
  padding-right: 10%;
}

.bs-padding-0-2 {
  padding-right: 10%;
}

.bs-padding-2-0 {
  padding-left: 10%;
}

.bs-padding-3-3 {
  padding-left: 15%;
  padding-right: 15%;
}

.bs-padding-0-3 {
  padding-right: 15%;
}

.bs-padding-3-0 {
  padding-left: 15%;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:active, .screen-reader-text:focus, .screen-reader-text:hover {
  background-color: #f1f1f1;
  -webmit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.search-form input[type=search] {
  float: left;
  height: 38px;
  line-height: 38px;
  margin-right: 8px;
  font-size: 12px;
  box-shadow: none;
}

.search-form input[type=submit] {
  height: 38px;
}

.post-meta {
  padding-top: 2px;
  font-style: inherit;
}

.post-meta .time {
  margin-right: 10px;
}

.post-meta .share, .post-meta .views {
  float: none;
  margin-right: 10px;
  font-style: normal;
}

.post-meta .comments, .post-meta .share, .post-meta .views {
  white-space: nowrap;
}

.post-meta .views {
  font-weight: 700;
}

.post-meta .views.rank-0, .post-meta .views.rank-default {
  font-weight: 400;
}

.post-meta .comments {
  margin-left: 10px;
  font-style: normal;
  float: right;
}

.post-meta .comments:first-child:last-child, .post-meta .rating:first-child:last-child {
  float: none;
  margin-left: 0;
  margin-right: 0;
}

.post-meta .rating + .comments {
  float: none;
  margin-left: 0;
}

.post-meta .comments .fa, .post-meta .share .bf-icon, .post-meta .views .bf-icon, .post-meta .views .fa {
  display: inline-block;
  margin-right: 3px;
}

.post-meta .post-author-avatar img {
  margin-right: 5px;
  margin-top: -2px;
}

.post-meta .post-author {
  font-weight: bolder;
  font-style: normal;
  margin-right: 8px;
  max-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
}

.post-meta .post-author:after {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f111";
  margin-left: 8px;
  font-size: 5px;
  vertical-align: middle;
  color: #adb5bd;
}

.post-meta .post-author-a:last-child .post-author {
  margin-right: 0;
}

.post-meta .post-author-a:last-child .post-author:after {
  display: none;
}

.post-meta > a:last-child, .post-meta > span:last-child {
  margin-right: 0 !important;
}

.slider-style-1-container, .slider-style-11-container, .slider-style-13-container, .slider-style-15-container, .slider-style-2-container.slider-container-1col, .slider-style-3-container, .slider-style-4-container.slider-container-1col, .slider-style-5-container, .slider-style-7-container, .slider-style-9-container {
  margin: 0 0 -20px;
  padding: 25px 0;
}

.slider-style-5-container {
  padding-bottom: 0;
}

.slider-style-16-container, .slider-style-2-container, .slider-style-4-container, .slider-style-8-container {
  margin: 0 0 25px;
}

.slider-style-15-container {
  margin: 0;
  padding-bottom: 0;
}

.slider-style-11-container .bs-slider, .slider-style-13-container .bs-slider, .slider-style-9-container .bs-slider {
  margin-bottom: 0;
}

.archive-title {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 13px;
}

.archive-title .pre-title {
  position: relative;
  margin-bottom: 17px;
}

.archive-title .pre-title span {
  background-color: #444;
  display: inline-block;
  line-height: 26px;
  z-index: 1;
  position: relative;
  padding: 0 10px;
  color: #fff;
}

.archive-title .pre-title:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 100%;
  height: 3px;
  width: 100%;
  background-color: #444;
}

.archive-title .page-heading {
  margin: 0 0 12px;
  position: relative;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
  padding: 0;
}

.archive-title.with-actions .page-heading {
  padding-right: 30px;
}

.archive-title .actions-container {
  width: 30px;
  float: right;
}

.archive-title.without-terms .actions-container {
  margin-top: 8px;
}

.archive-title .page-heading .count, .archive-title .rss-link {
  float: right;
  font-size: 20px;
}

.archive-title .rss-link {
  color: #f97410;
}

.archive-title .desc {
  margin-top: 12px;
}

.archive-title .desc p:last-child {
  margin-bottom: 0;
}

.archive-title .term-badges {
  margin: 10px 0 0;
  line-height: 0;
}

.archive-title .term-badges span.term-badge {
  margin-bottom: 6px;
}

.archive-title .term-badges span.term-badge a {
  background: #fff !important;
  border: 1px solid #d4d4d4 !important;
  border-color: #d4d4d4 !important;
  padding: 0 7px;
  display: inline-block;
  line-height: 20px;
  margin-right: 3px;
  font-size: 10px;
  float: left;
  color: #8b8b8b !important;
}

.archive-title .term-badges span.term-badge a:hover {
  color: #fff !important;
  border: 1px solid transparent !important;
}

.wpb_text_column img {
  max-width: 100%;
  height: auto;
}

.wpb_widgetised_column .wpb_wrapper > ul {
  margin: 0;
  padding: 0;
}

.bs-injection.bs-injection-1-col > .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .bs-injection.bs-injection-2-col > .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .bs-injection.bs-injection-3-col > .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .bs-injection.bs-vc-content, .bs-injection.bs-vc-content .vc_row, .bs-injection.bs-vc-content .vc_vc_row, .layout-1-col .bs-vc-content .vc_row, .layout-1-col .bs-vc-content .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .layout-1-col .bs-vc-content .vc_vc_row, .layout-3-col-0 .bs-vc-content .vc_row, .layout-3-col-0 .bs-vc-content .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .layout-3-col-0 .bs-vc-content .vc_vc_row {
  margin-left: auto;
  margin-right: auto;
}

.container, .vc_column_container {
  margin-right: auto !important;
  margin-left: auto !important;
}

.bs-vc-content > .vc_element > .vc_row[data-vc-full-width=true], .bs-vc-content > .vc_element > .vc_row[data-vc-stretch-content=true] > .bs-vc-wrapper, .bs-vc-content > .vc_row[data-vc-full-width=true], .bs-vc-content > .vc_row[data-vc-stretch-content=true] > .bs-vc-wrapper {
  width: 100%;
}

.bs-vc-content .vc_row[data-vc-full-width=true] {
  right: auto !important;
  left: auto !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.full-width .bs-vc-content .vc_row[data-vc-full-width=true] {
  max-width: inherit !important;
}

.bs-vc-content .vc_row[data-vc-stretch-content=true] {
  right: auto !important;
  left: auto !important;
  width: auto !important;
  max-width: inherit !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bs-injection.bs-vc-content .vc_row[data-vc-stretch-content=true] > .bs-vc-wrapper, .layout-3-col-0 .bs-vc-content .vc_row[data-vc-stretch-content=true] > .bs-vc-wrapper, .page-layout-1-col .bs-vc-content .vc_row[data-vc-stretch-content=true] > .bs-vc-wrapper {
  max-width: inherit !important;
}

.wpb_flexslider.flexslider .slides img {
  height: auto;
}

.bs-injection.bs-vc-content > .vc_row.vc_row-fluid.vc_row-has-fill:first-child {
  margin-top: 0 !important;
}

.vc_row.vc_row-flex > .bs-vc-wrapper {
  box-sizing: border-box;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.vc_row.vc_row-flex .bs-vc-wrapper {
  width: 100%;
}

.vc_row.vc_row-flex > .bs-vc-wrapper > .vc_column_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}

.vc_row.vc_row-flex > .bs-vc-wrapper, .vc_row.vc_row-flex > .bs-vc-wrapper > .vc_column_container {
  display: flex;
}

.vc_row.vc_row-flex > .bs-vc-wrapper > .vc_column_container > .vc_column-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.vc_row.vc_row-flex > .bs-vc-wrapper > .vc_column_container > .vc_column-inner {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 1;
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .vc_row.vc_row-flex > .bs-vc-wrapper > .vc_column_container > .vc_column-inner > * {
    min-height: 1em;
  }
}
.vc_row.vc_column-gap-1 > .bs-vc-wrapper {
  margin-left: -0.5px !important;
  margin-right: -0.5px !important;
}

.vc_row.vc_column-gap-1 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 0.5px !important;
  padding-right: 0.5px !important;
}

.vc_row.vc_column-gap-2 > .bs-vc-wrapper {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.vc_row.vc_column-gap-2 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.vc_row.vc_column-gap-3 > .bs-vc-wrapper {
  margin-left: -1.5px !important;
  margin-right: -1.5px !important;
}

.vc_row.vc_column-gap-3 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 1.5px !important;
  padding-right: 1.5px !important;
}

.vc_row.vc_column-gap-4 > .bs-vc-wrapper {
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.vc_row.vc_column-gap-4 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.vc_row.vc_column-gap-5 > .bs-vc-wrapper {
  margin-left: -2.5px !important;
  margin-right: -2.5px !important;
}

.vc_row.vc_column-gap-5 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 2.5px !important;
  padding-right: 2.5px !important;
}

.vc_row.vc_column-gap-10 > .bs-vc-wrapper {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.vc_row.vc_column-gap-10 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.vc_row.vc_column-gap-15 > .bs-vc-wrapper {
  margin-left: -7.5px !important;
  margin-right: -7.5px !important;
}

.vc_row.vc_column-gap-15 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
}

.vc_row.vc_column-gap-20 > .bs-vc-wrapper {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.vc_row.vc_column-gap-20 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.vc_row.vc_column-gap-25 > .bs-vc-wrapper {
  margin-left: -12.5px !important;
  margin-right: -12.5px !important;
}

.vc_row.vc_column-gap-25 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 12.5px !important;
  padding-right: 12.5px !important;
}

.vc_row.vc_column-gap-30 > .bs-vc-wrapper {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.vc_row.vc_column-gap-30 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.vc_row.vc_column-gap-35 > .bs-vc-wrapper {
  margin-left: -17.5px !important;
  margin-right: -17.5px !important;
}

.vc_row.vc_column-gap-35 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 17.5px !important;
  padding-right: 17.5px !important;
}

.listing-item a.vc_inline-link {
  display: none;
}

.bs-pagination-wrapper .listing + .listing {
  margin-top: 20px;
}

.post.emphasize-first-p .entry-content > p:first-child {
  font-size: 120%;
}

.listing-item .post-edit-link {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.58);
  color: #fff;
  padding: 4px 11px;
  font-size: 13px;
  display: none;
  line-height: 20px;
}

.listing-item:hover .post-edit-link {
  display: block;
}

.infinity > .listing + .listing, .more_btn > .listing + .listing {
  margin-top: 20px;
}

.mega-links > .menu-item {
  margin-bottom: 30px;
}

.listing .listing-item, .mega-links > .menu-item {
  min-height: 0;
}

.listing.columns-1 .listing-item, .mega-links.columns-1 > .menu-item {
  width: 100%;
  float: none;
}

.listing.columns-2 .listing-item, .mega-links.columns-2 > .menu-item {
  width: 50%;
  float: left;
}

.listing.columns-2 .listing-item:nth-child(odd), .mega-links.columns-2 > .menu-item:nth-child(odd) {
  padding-right: 18px;
  clear: left;
}

.listing.columns-2 .listing-item:nth-child(even), .mega-links.columns-2 > .menu-item:nth-child(even) {
  padding-left: 18px;
}

.listing.columns-3 .listing-item, .mega-links.columns-3 > .menu-item {
  width: 33.3333%;
  float: left;
}

.listing.columns-3 .listing-item:nth-child(3n-1), .mega-links.columns-3 > .menu-item:nth-child(3n-1) {
  padding-left: 9px;
  padding-right: 9px;
}

.listing.columns-3 .listing-item:nth-child(3n-2), .mega-links.columns-3 > .menu-item:nth-child(3n-2) {
  padding-right: 18px;
  clear: left;
}

.listing.columns-3 .listing-item:nth-child(3n+3), .mega-links.columns-3 > .menu-item:nth-child(3n+3) {
  padding-left: 18px;
}

.listing.columns-4 .listing-item, .mega-links.columns-4 > .menu-item {
  width: 25%;
  float: left;
}

.listing.columns-4 .listing-item:nth-child(4n-3), .mega-links.columns-4 > .menu-item:nth-child(4n-3) {
  padding-left: 0;
  padding-right: 16px;
  clear: left;
}

.listing.columns-4 .listing-item:nth-child(4n+2), .mega-links.columns-4 > .menu-item:nth-child(4n+2) {
  padding-left: 5px;
  padding-right: 11px;
}

.listing.columns-4 .listing-item:nth-child(4n+3), .mega-links.columns-4 > .menu-item:nth-child(4n+3) {
  padding-left: 10px;
  padding-right: 6px;
}

.listing.columns-4 .listing-item:nth-child(4n+4), .mega-links.columns-4 > .menu-item:nth-child(4n+4) {
  padding-right: 0;
  padding-left: 16px;
}

.listing.columns-5 .listing-item, .mega-links.columns-5 > .menu-item {
  width: 20%;
  float: left;
}

.listing.columns-5 .listing-item:nth-child(5n+1), .mega-links.columns-5 > .menu-item:nth-child(5n+1) {
  padding-left: 0;
  padding-right: 12px;
  clear: left;
}

.listing.columns-5 .listing-item:nth-child(5n+2), .mega-links.columns-5 > .menu-item:nth-child(5n+2) {
  padding-left: 4px;
  padding-right: 8px;
}

.listing.columns-5 .listing-item:nth-child(5n+3), .mega-links.columns-5 > .menu-item:nth-child(5n+3) {
  padding-left: 6px;
  padding-right: 6px;
}

.listing.columns-5 .listing-item:nth-child(5n+4), .mega-links.columns-5 > .menu-item:nth-child(5n+4) {
  padding-left: 8px;
  padding-right: 4px;
}

.listing.columns-5 .listing-item:nth-child(5n+5), .mega-links.columns-5 > .menu-item:nth-child(5n+5) {
  padding-left: 8px;
  padding-right: 0;
}

.listing.columns-5.listing-tall .listing-item:nth-child(5n+5), .mega-links.columns-5.listing-tall > .menu-item:nth-child(5n+5) {
  padding-left: 12px;
  padding-right: 0;
}

@media only screen and (max-width: 1000px) {
  .listing.columns-5 .listing-item, .mega-links.columns-5 > .menu-item {
    width: 25%;
  }
  .listing.columns-5 .listing-item:nth-child(4n-3), .mega-links.columns-5 > .menu-item:nth-child(4n-3) {
    padding-left: 0;
    padding-right: 8px;
    clear: left;
  }
  .listing.columns-5 .listing-item:nth-child(4n+2), .mega-links.columns-5 > .menu-item:nth-child(4n+2) {
    padding-left: 3px;
    padding-right: 5px;
    clear: none;
  }
  .listing.columns-5 .listing-item:nth-child(4n+3), .mega-links.columns-5 > .menu-item:nth-child(4n+3) {
    padding-left: 5px;
    padding-right: 3px;
    clear: none;
  }
  .listing.columns-5 .listing-item:nth-child(4n+4), .mega-links.columns-5 > .menu-item:nth-child(4n+4) {
    padding-right: 0;
    padding-left: 8px;
    clear: none;
  }
}
@media only screen and (max-width: 900px) {
  .listing.columns-4 .listing-item, .listing.columns-5 .listing-item, .mega-links.columns-4 > .menu-item, .mega-links.columns-5 > .menu-item {
    width: 33.3333%;
  }
  .listing.columns-4 .listing-item:nth-child(3n-1), .listing.columns-5 .listing-item:nth-child(3n-1), .mega-links.columns-4 > .menu-item:nth-child(3n-1), .mega-links.columns-5 > .menu-item:nth-child(3n-1) {
    padding-left: 6px;
    padding-right: 6px;
    clear: none;
  }
  .listing.columns-4 .listing-item:nth-child(3n-2), .listing.columns-5 .listing-item:nth-child(3n-2), .mega-links.columns-4 > .menu-item:nth-child(3n-2), .mega-links.columns-5 > .menu-item:nth-child(3n-2) {
    padding-left: 0;
    padding-right: 12px;
    clear: left;
  }
  .listing.columns-4 .listing-item:nth-child(3n+3), .listing.columns-5 .listing-item:nth-child(3n+3), .mega-links.columns-4 > .menu-item:nth-child(3n+3), .mega-links.columns-5 > .menu-item:nth-child(3n+3) {
    padding-left: 12px;
    padding-right: 0;
    clear: none;
  }
}
@media only screen and (max-width: 600px) {
  .listing.columns-3 .listing-item, .listing.columns-4 .listing-item, .listing.columns-5 .listing-item, .mega-links.columns-3 > .menu-item, .mega-links.columns-4 > .menu-item, .mega-links.columns-5 > .menu-item {
    width: 50%;
  }
  .listing.columns-3 .listing-item:nth-child(odd), .listing.columns-4 .listing-item:nth-child(odd), .listing.columns-5 .listing-item:nth-child(odd), .mega-links.columns-3 > .menu-item:nth-child(odd), .mega-links.columns-4 > .menu-item:nth-child(odd), .mega-links.columns-5 > .menu-item:nth-child(odd) {
    padding-left: 0;
    padding-right: 13px;
    clear: left;
  }
  .listing.columns-3 .listing-item:nth-child(even), .listing.columns-4 .listing-item:nth-child(even), .listing.columns-5 .listing-item:nth-child(even), .mega-links.columns-3 .listing-item:nth-child(even), .mega-links.columns-4 .listing-item:nth-child(even), .mega-links.columns-5 .listing-item:nth-child(even) {
    padding-left: 13px;
    padding-right: 0;
    clear: none;
  }
}
@media only screen and (max-width: 500px) {
  .listing.columns-2 .listing-item, .listing.columns-3 .listing-item, .listing.columns-4 .listing-item, .listing.columns-5 .listing-item, .mega-links.columns-2 > .menu-item, .mega-links.columns-3 > .menu-item, .mega-links.columns-4 > .menu-item, .mega-links.columns-5 > .menu-item {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    clear: none !important;
  }
}
.archive-title img {
  max-width: 100%;
}

.bsbt-shortcode-placeholder-p.bsbt-shortcode-placeholder-p {
  word-break: break-all;
  margin: 0;
  line-height: 24px;
  font-size: 14px;
  font-family: var(--publisher-system-font);
}

.bsbt-shortcode-placeholder-p.bsbt-shortcode-placeholder-p + .bsbt-shortcode-placeholder {
  margin-top: 5px;
}

.bsbt-shortcode-placeholder {
  text-align: center;
  background: #fff8d7;
  border: 1px solid #e5d68d;
  margin: 0 0 40px;
  padding: 15px;
  line-height: 24px;
  font-size: 13px;
  font-family: var(--publisher-system-font);
}

.bsbt-shortcode-placeholder a, .bsbt-shortcode-placeholder a:hover {
  color: #e43c36 !important;
  font-weight: bolder;
}

.bsbt-shortcode-placeholder.type-small {
  padding: 7px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.bs-shortcode-row {
  margin-bottom: 20px;
}

.bs-shortcode-row-2-column[max-width~="450px"] .bs-shortcode-col {
  width: 100% !important;
  margin-bottom: 15px;
}

.bs-shortcode-row-2-column[max-width~="450px"] .bs-shortcode-col:last-child {
  margin-bottom: 0;
}

.bs-shortcode-row-3-column[max-width~="600px"] .bs-shortcode-col {
  width: 50% !important;
  margin-bottom: 15px;
}

.bs-shortcode-row-3-column[max-width~="450px"] .bs-shortcode-col {
  width: 100% !important;
}

.bs-shortcode-row-3-column[max-width~="600px"] .bs-shortcode-col:last-child {
  margin-bottom: 0;
}

.bs-shortcode-row-4-column[max-width~="700px"] .bs-shortcode-col {
  width: 33.3333% !important;
  margin-bottom: 15px;
}

.bs-shortcode-row-4-column[max-width~="600px"] .bs-shortcode-col {
  width: 50% !important;
}

.bs-shortcode-row-4-column[max-width~="450px"] .bs-shortcode-col {
  width: 100% !important;
}

.bs-shortcode-row-4-column[max-width~="700px"] .bs-shortcode-col:last-child {
  margin-bottom: 0;
}

.bs-ll.a .fb-page, .bs-ll.a .google-plus-block {
  background-color: #e9ebee;
  background-image: linear-gradient(to left, #e9ebee 0, #f4f5f6 20%, #e9ebee 40%, #e9ebee 100%);
  background-repeat: no-repeat;
  background-size: 1000px 900px;
  -webkit-animation: bs-lazy-anim 1s infinite linear forwards;
  -moz-animation: bs-lazy-anim 1s infinite linear forwards;
  animation: bs-lazy-anim 1.01s infinite linear forwards;
  width: 100% !important;
  min-height: 130px !important;
  text-align: center;
}

.bs-ll.a .footer-widgets.light-text .fb-page, .bs-ll.a .footer-widgets.light-text .google-plus-block {
  background-color: #373737;
  background-image: linear-gradient(to left, #373737 0, #2b2b2b 20%, #373737 40%, #373737 100%);
}

.fb-page.fb_iframe_widget {
  background: 0 0;
}

.bs-text a:hover {
  text-decoration: underline;
}

.bs-text .bs-text-content p:last-child {
  margin-bottom: 0;
}

.bs-text a.btn {
  color: #fff;
}

.pagination {
  margin: 20px 0 0;
  font-size: 18px;
}

.pagination:last-child {
  margin-bottom: 0 !important;
}

.entry-content .bs-post-pagination .pages {
  border-color: #dadada !important;
  color: #333;
}

.entry-content .bs-post-pagination span {
  line-height: 28px;
  height: 28px;
  min-width: 28px;
}

.entry-content > .bs-post-pagination:first-child {
  margin: 0 0 20px;
}

.pagination.bs-links-pagination .older {
  float: left;
  width: 50%;
  text-align: left;
}

.pagination.bs-links-pagination .newer {
  float: right;
  width: 50%;
  text-align: right;
}

.pagination.bs-links-pagination a {
  display: inline-block;
  margin-right: 1px;
  line-height: 26px;
  height: 26px;
  min-width: 30px;
  text-align: center;
  padding: 0 9px;
  background-color: #fff;
  border: 1px solid #dadada;
  color: #8a8f92;
  font-size: 12px;
  text-transform: uppercase;
}

.pagination.bs-links-pagination a:hover {
  color: #fff;
  border: 1px solid transparent;
}

.pagination.bs-numbered-pagination .page-numbers, .pagination.bs-numbered-pagination .page-numbers.dots:hover, .pagination.bs-numbered-pagination .wp-pagenavi a, .pagination.bs-numbered-pagination .wp-pagenavi span, .pagination.bs-numbered-pagination span {
  display: inline-block;
  margin-right: 1px;
  line-height: 30px;
  height: 30px;
  min-width: 30px;
  text-align: center;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #dadada;
  color: #8a8f92;
  font-size: 12px;
  text-transform: uppercase;
  list-style: none;
}

.pagination.bs-numbered-pagination span {
  margin-right: 0;
}

.pagination.bs-numbered-pagination .current, .pagination.bs-numbered-pagination .page-numbers:hover, .pagination.bs-numbered-pagination .wp-pagenavi .current, .pagination.bs-numbered-pagination .wp-pagenavi a:hover, .pagination.bs-numbered-pagination > span {
  border: 1px solid;
}

.pagination.bs-numbered-pagination .next .fa {
  margin-left: 4px;
  font-size: 15px;
}

.pagination.bs-numbered-pagination .prev .fa {
  margin-right: 4px;
  font-size: 15px;
}

.pagination.bs-numbered-pagination.wc-pagination > .page-numbers {
  border: none;
  background: 0 0;
  display: block;
  padding: 0;
  margin: 0;
  text-align: inherit;
}

.pagination.bs-numbered-pagination.wc-pagination > .page-numbers > li {
  display: inline-block;
}

.post-related .listing-item .title {
  font-size: 15px;
  line-height: 1.4;
}

.post-related .include-last-mobile .listing-item:last-child {
  display: none;
}

@media only screen and (max-width: 600px) {
  .post-related .include-last-mobile .listing-item:last-child {
    display: block;
  }
}
.content-column .single-container:last-child {
  margin-bottom: 0;
}

.single-post-content .term-badges.floated a {
  max-width: auto;
}

.post-subtitle {
  padding: 0;
  margin: 0;
  color: #717171;
}

.single-post-content .post-embed iframe {
  max-width: 100%;
}

.entry-terms {
  margin-bottom: 7px;
}

.entry-terms:last-child {
  margin-bottom: -3px;
}

.entry-terms ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline;
}

.entry-terms li {
  display: inline;
}

.entry-terms span.sep {
  margin: 0 5px;
}

.entry-terms .terms-label {
  padding: 0 10px;
  display: inline-block;
  margin-right: 4px;
}

.entry-terms.post-tags .terms-label, .entry-terms.source .terms-label, .entry-terms.via .terms-label {
  line-height: 20px;
  border: 1px solid #444;
  background: #444;
  color: #fff;
  font-size: 10px;
  float: left;
  border-radius: 33px;
}

.entry-terms.post-tags a, .entry-terms.source a, .entry-terms.via a {
  background: #fff;
  border: 1px solid #d4d4d4;
  padding: 0 9px;
  display: inline-block;
  line-height: 20px;
  margin-right: 3px;
  font-size: 10px;
  float: left;
  margin-bottom: 3px;
  color: #5f5f5f;
  border-radius: 33px;
}

.entry-terms.post-tags a:hover, .entry-terms.source a:hover, .entry-terms.via a:hover {
  color: #fff;
  border: 1px solid transparent;
}

.single-container > .post-author {
  padding: 20px;
  margin: 15px 0 0;
  position: relative;
  border: 1px solid #e8e8e8;
}

.single-container > .post-author .post-author-avatar img {
  float: left;
  margin-right: 20px;
}

.single-container > .post-author .author-title {
  margin: 0 0 7px;
  font-size: 18px;
  text-transform: capitalize;
  padding-right: 50%;
}

.single-container > .post-author .author-title .title-counts {
  color: #8a8a8a;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 400;
}

.single-container > .post-author .author-title .title-counts:before {
  content: "-";
  margin: 0 8px;
  opacity: 0.4;
}

.single-container > .post-author .author-links {
  width: 50%;
  text-align: right;
  position: absolute;
  top: 15px;
  right: 15px;
}

.single-container > .post-author .author-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.single-container > .post-author .author-links li {
  margin-left: 8px;
  display: inline-block;
}

.single-container > .post-author .author-links a {
  color: #353535;
  font-size: 15px;
  width: 24px;
  display: inline-block;
  text-align: center;
}

.single-container > .post-author .author-links li.facebook a:hover {
  color: #4a639f;
}

.single-container > .post-author .author-links li.telegram a:hover {
  color: #2da4d9;
}

.single-container > .post-author .author-links li.twitter a:hover {
  color: #59c8ff;
}

.single-container > .post-author .author-links li.google-plus a:hover {
  color: #d73d32;
}

.single-container > .post-author .author-links li.instagram a:hover {
  color: #517fa4;
}

.single-container > .post-author .author-links li.flickr a:hover {
  color: #2f44db;
}

.single-container > .post-author .author-links li.behance a:hover {
  color: #3b63fc;
}

.single-container > .post-author .author-links li.soundcloud a:hover {
  color: #f50;
}

.single-container > .post-author .author-links li.delicious a:hover {
  color: #4a79ff;
}

.single-container > .post-author .author-links li.vimeo a:hover {
  color: #46a3e9;
}

.single-container > .post-author .author-links li.dribbble a:hover {
  color: #ea4c89;
}

.single-container > .post-author .author-links li.linkedin a:hover {
  color: #0266a0;
}

.single-container > .post-author .author-links li.youtube a:hover {
  color: #cc181e;
}

.single-container > .post-author .author-links li.pinterest a:hover {
  color: #cb2027;
}

.single-container > .post-author .author-links li.github a:hover {
  color: #666;
}

.single-container > .post-author .post-author-bio {
  margin-top: 6px;
  font-size: 13px;
  padding-left: 100px;
}

.single-container > .post-author .post-author-bio p:last-child {
  margin-bottom: 0;
}

.post-related {
  position: relative;
  padding: 20px;
  border: 1px solid #e8e8e8;
}

.post-related .section-heading.sh-t2.sh-s1, .post-related .section-heading.sh-t2.sh-s2 {
  margin: -21px -21px 27px;
  padding: 4px 20px 0;
}

.post-related .listing-item-grid-simple .heading-container {
  background: #f5f5f5;
}

@media only screen and (max-width: 520px) {
  .single-container > .post-author .author-title {
    padding-right: 0;
  }
  .single-container > .post-author .author-links {
    width: 100%;
    text-align: left;
    position: relative;
    top: inherit;
    right: inherit;
  }
  .single-container > .post-author .author-links li {
    margin-left: 0;
    margin-right: 4px;
  }
  .single-container > .post-author .post-author-bio {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 380px) {
  .single-container > .post-author .post-author-avatar {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  .single-container > .post-author .post-author-avatar img {
    float: none;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
  .single-container > .post-author .author-links {
    text-align: center;
  }
  .single-container > .post-author .author-links li {
    margin-left: 7px;
    margin-right: 7px;
  }
  .single-container > .post-author .post-author-bio {
    margin-top: 5px;
  }
  .single-container > .post-author .author-title {
    text-align: center;
    margin: 14px 0 10px;
    padding: 0;
  }
  .single-container > .post-author .post-author-bio {
    text-align: center;
    padding-left: 0;
  }
}
.single-page-content > .featured {
  text-align: center;
  display: block;
  margin: 0 0 20px;
  line-height: 0;
}

.single-page-content > .featured img {
  max-width: 100%;
  height: auto !important;
}

.single-page-content > .featured iframe {
  max-width: 100%;
}

.single-attachment-content .return-to {
  margin-bottom: 20px;
}

.single-attachment-content .return-to .fa {
  border: 1px solid #d4d4d4;
  border: 1px solid rgba(138, 138, 138, 0.33);
  color: #9c9c9c;
  color: rgba(138, 138, 138, 0.83);
  font-size: 12px;
  padding: 0 9px;
  display: inline-block;
  line-height: 24px;
  min-width: 26px;
  text-align: center;
  cursor: pointer;
  margin-right: 7px;
  text-decoration: none;
  -webkit-transition: all 0.07s ease;
  -moz-transition: all 0.07s ease;
  -o-transition: all 0.07s ease;
  transition: all 0.07s ease;
}

.single-attachment-content .return-to:hover .fa {
  border-color: transparent;
  color: #fff;
}

.single-attachment-content .parent-images ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.single-attachment-content .parent-images .img-holder {
  width: 100%;
  margin-bottom: 12px;
}

.single-attachment-content .parent-images .img-holder .fa {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  display: inline-block;
  font-size: 30px;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #fff;
  z-index: 2;
}

.single-attachment-content .parent-images .img-holder:hover .fa {
  opacity: 1;
}

.single-attachment-content .parent-images .img-holder:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.single-attachment-content .parent-images .img-holder:hover:after, .single-attachment-content .parent-images .listing-item-current > .img-holder .fa, .single-attachment-content .parent-images .listing-item-current > .img-holder:after {
  opacity: 1;
}

.single-attachment-content .attachment-title {
  font-size: 22px;
}

.single-attachment-content .pagination {
  margin-bottom: 23px;
}

.listing.listing-attachment-siblings.columns-5[max-width~="900px"] .listing-item {
  width: 25% !important;
  float: left !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="900px"] .listing-item:nth-child(4n-3) {
  padding-left: 0 !important;
  padding-right: 8px !important;
  clear: left !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="900px"] .listing-item:nth-child(4n+2) {
  padding-left: 3px !important;
  padding-right: 5px !important;
  clear: none !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="900px"] .listing-item:nth-child(4n+3) {
  padding-left: 5px !important;
  padding-right: 3px !important;
  clear: initial !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="900px"] .listing-item:nth-child(4n+4) {
  padding-right: 0 !important;
  padding-left: 8px !important;
  clear: none !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="560px"] .listing-item:nth-child(3n-1) {
  padding: 0 6px !important;
  clear: none !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="560px"] .listing-item:nth-child(3n-2) {
  padding-left: 0 !important;
  padding-right: 12px !important;
  clear: left !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="560px"] .listing-item:nth-child(3n+3) {
  padding-left: 12px !important;
  padding-right: 0 !important;
  clear: none !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="560px"] .listing-item {
  width: 33.3333% !important;
  float: left;
}

.listing.listing-attachment-siblings.columns-5[max-width~="450px"] .listing-item {
  float: left !important;
  width: 50% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="450px"] .listing-item:nth-child(odd) {
  padding-right: 13px !important;
  clear: left !important;
  padding-left: 0 !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="450px"] .listing-item:nth-child(even) {
  padding-left: 13px !important;
  padding-right: 0 !important;
  clear: none !important;
}

.format-video.listing-item-grid .heading-container {
  margin-top: 0;
}

.format-gallery.listing-item-grid .heading-container {
  z-index: 999;
}

.single-post-content.format-aside .entry-content, .single-post-content.format-aside .post-summary {
  position: relative;
  padding: 32px 50px 32px 50px;
  line-height: 30px;
  color: #5a4f43;
  background-color: #f2f6c1;
  border: 1px solid #e8e8e8;
  background-image: -webkit-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0, rgba(255, 255, 255, 0.1) 90%), -webkit-repeating-linear-gradient(top, transparent 0, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(239, 207, 173, 0.7) 30px);
  background-image: -moz-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0, rgba(255, 255, 255, 0.1) 90%), -moz-repeating-linear-gradient(top, transparent 0, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(239, 207, 173, 0.7) 30px);
  background-image: -o-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0, rgba(255, 255, 255, 0.1) 90%), -o-repeating-linear-gradient(top, transparent 0, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(239, 207, 173, 0.7) 30px);
  background-size: 100% 100%;
}

.single-post-content.format-aside .entry-content {
  margin-bottom: 20px;
}

.single-post-content.format-aside .entry-content blockquote:before {
  background: 0 0;
  top: 26px;
}

.single-post-content.format-quote .entry-content, .single-post-content.format-quote .post-summary {
  text-align: center;
  background-color: #f3f3f3;
  border: 1px solid rgba(0, 0, 0, 0.11);
  padding: 30px 20px;
  margin-bottom: 30px;
}

.single-post-content.format-quote .entry-content blockquote, .single-post-content.format-quote .post-summary blockquote {
  border-left: none;
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
}

.single-post-content.format-quote .entry-content blockquote:before, .single-post-content.format-quote .post-summary blockquote:before {
  top: -20px;
  background: 0 0;
  line-height: 80px;
}

.single-post-content.format-quote .entry-content blockquote {
  background: 0 0;
  padding: 20px 0;
}

.single-post-content.format-quote .entry-content blockquote cite, .single-post-content.format-quote .post-summary blockquote cite {
  font-weight: 800;
  font-style: normal;
  display: block;
  margin-top: 10px;
}

.single-post-content.format-quote .entry-content blockquote cite:before, .single-post-content.format-quote .post-summary blockquote cite:before {
  content: "— ";
}

.single-post-content.format-quote .heading-container .post-meta, .single-post-content.format-quote .heading-container .title {
  text-align: center;
}

.single-post-content.format-status .entry-content, .single-post-content.format-status .post-summary {
  text-align: center;
  padding: 20px;
  border: 2px solid #eaeaea;
  font-size: 14px;
}

.single-post-content.format-status .post-summary {
  float: left;
}

.single-post-content.format-status .entry-content {
  margin-bottom: 19px;
}

.post.format-status .post-summary p:last-child, .single-post-content.format-status .entry-content p:last-child {
  margin-bottom: 0;
}

.single-post-content.format-status .heading-container .post-meta, .single-post-content.format-status .heading-container .title {
  text-align: center;
}

.better-chat {
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.better-chat .chat-item {
  margin-bottom: 10px;
}

.better-chat .chat-item p:last-child {
  margin-bottom: 0;
}

.better-chat .chat-item .user-name {
  display: inline-block;
  position: absolute;
  top: 100%;
  right: 0;
  color: #9a9a9a;
  font-size: 12px;
  font-weight: 400;
  font-family: var(--publisher-system-font);
}

.better-chat .chat-item {
  margin: 0 0 30px;
  border-radius: 14px;
  padding: 8px 16px;
  background: #e5e5ea;
  max-width: 75%;
  clear: both;
  position: relative;
}

.better-chat .chat-item.chat-no-author {
  margin-bottom: 10px;
}

.better-chat .chat-item.chat-next-author {
  margin-bottom: 2px;
}

.better-chat .chat-item.chat-next-author:after, .better-chat .chat-item.chat-next-author:before {
  display: none;
}

.better-chat .chat-item {
  float: right;
  background-color: #dcdcdc;
  color: #373737;
  text-align: right;
}

.better-chat .chat-item::after {
  content: "";
  position: absolute;
  right: -4px;
  bottom: 0;
  width: 12px;
  height: 16px;
  border-left: 8px solid #dcdcdc;
  border-bottom-left-radius: 12px 6px;
}

.better-chat .chat-item.chat-speaker-1, .better-chat .chat-item.chat-speaker-3, .better-chat .chat-item.chat-speaker-5, .better-chat .chat-item.chat-speaker-7, .better-chat .chat-item.chat-speaker-9 {
  float: left;
  text-align: left;
}

.better-chat .chat-item.chat-speaker-1:after, .better-chat .chat-item.chat-speaker-3:after, .better-chat .chat-item.chat-speaker-5:after, .better-chat .chat-item.chat-speaker-7:after, .better-chat .chat-item.chat-speaker-9:after {
  display: none;
}

.better-chat .chat-item.chat-speaker-1 .user-name, .better-chat .chat-item.chat-speaker-3 .user-name, .better-chat .chat-item.chat-speaker-5 .user-name, .better-chat .chat-item.chat-speaker-7 .user-name, .better-chat .chat-item.chat-speaker-9 .user-name {
  left: 0;
  right: auto;
}

.better-chat .chat-item.chat-speaker-1:before, .better-chat .chat-item.chat-speaker-3:before, .better-chat .chat-item.chat-speaker-5:before, .better-chat .chat-item.chat-speaker-7:before, .better-chat .chat-item.chat-speaker-9:before {
  content: "";
  position: absolute;
  left: -4px;
  bottom: 0;
  width: 12px;
  height: 16px;
  border-right: 8px solid transparent;
  border-left: none;
  border-bottom-right-radius: 12px 6px;
}

.better-chat .chat-item a, .better-chat .chat-item a:hover {
  color: #fff !important;
}

.better-chat .chat-item.chat-speaker-2 a, .better-chat .chat-item.chat-speaker-2 a:hover {
  color: #373737 !important;
}

.better-chat .chat-item.chat-speaker-1 {
  background-color: #1289fe;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-1:before {
  border-right-color: #1289fe;
}

.better-chat .chat-item.chat-speaker-3 {
  background-color: #009e5d;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-3:before {
  border-right-color: #009e5d;
}

.better-chat .chat-item.chat-speaker-4 {
  background-color: #daa715;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-4:after {
  border-left-color: #daa715;
}

.better-chat .chat-item.chat-speaker-5 {
  background-color: #ce4309;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-5:before {
  border-right-color: #ce4309;
}

.better-chat .chat-item.chat-speaker-6 {
  background-color: #da3699;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-6:after {
  border-left-color: #da3699;
}

.better-chat .chat-item.chat-speaker-7 {
  background-color: #7655ce;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-7:before {
  border-right-color: #7655ce;
}

.better-chat .chat-item.chat-speaker-8 {
  background-color: #3b99da;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-8:after {
  border-left-color: #3b99da;
}

.better-chat .chat-item.chat-speaker-9 {
  background-color: #158a54;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-9:before {
  border-right-color: #158a54;
}

.bs-intro, .entry-content .bs-intro {
  font-size: 110%;
  font-weight: bolder;
  -webkit-font-smoothing: antialiased;
}

.entry-content {
  font-size: 14px;
  line-height: 1.6;
  color: #4a4a4a;
  padding-bottom: 20px;
}

.post .entry-content.single-post-content:last-child {
  padding-bottom: 0;
}

.entry-content.single-post-content iframe, .single-page-simple-content .entry-content iframe {
  max-width: 100%;
}

.fluid-width-video-wrapper embed, .fluid-width-video-wrapper iframe, .fluid-width-video-wrapper object {
  height: 100% !important;
}

.entry-content .wp-block-media-text {
  margin-bottom: 25px;
}

.entry-content p {
  margin-bottom: 17px;
}

.entry-content p:last-child {
  margin-bottom: 0;
}

.entry-content ol, .entry-content ul {
  margin-bottom: 17px;
}

.entry-content table {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  width: 100%;
}

.entry-content table > tbody > tr > td, .entry-content table > tbody > tr > th, .entry-content table > tfoot > tr > td, .entry-content table > tfoot > tr > th, .entry-content table > thead > tr > td, .entry-content table > thead > tr > th {
  border: 1px solid #ddd;
  padding: 7px 10px;
}

.entry-content table > thead > tr > td, .entry-content table > thead > tr > th {
  border-bottom-width: 2px;
}

.entry-content dl dt {
  font-size: 15px;
}

.entry-content dl dd {
  margin-bottom: 10px;
}

.entry-content acronym[title] {
  border-bottom: 1px dotted #999;
}

.alignright, .entry-content .alignright {
  margin: 5px 0 20px 20px;
  float: right;
}

.alignleft, .entry-content .alignleft {
  margin: 5px 20px 15px 0;
  float: left;
}

.entry-content figure.alignleft .wp-caption-text {
  text-align: left;
}

.entry-content figure.alignright .wp-caption-text {
  text-align: right;
}

.entry-content figure, .entry-content img {
  max-width: 100%;
  height: auto;
}

.entry-content img.aligncenter {
  display: block;
  margin: 15px auto 25px;
}

.entry-content figure.aligncenter {
  margin: 20px auto;
  text-align: center;
}

.entry-content figure.aligncenter img {
  display: inline-block;
}

.entry-content .gallery-caption, .entry-content .wp-caption-text, .entry-content figcaption {
  margin: 5px 0 0;
  font-style: italic;
  font-size: 13px;
  line-height: 15px;
  color: #545454;
  padding: 0;
  text-align: center;
}

@media (min-width: 750px) {
  body.full-width .wp-block-image.alignfull {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: auto;
    max-width: 1000% !important;
  }
  body.full-width .wp-block-image.alignwide {
    margin-left: calc(25% - 25vw);
    margin-right: calc(25% - 25vw);
    width: auto;
    max-width: 1000% !important;
  }
  body.boxed .wp-block-image.alignfull, body.boxed .wp-block-image.alignwide {
    margin-left: -30px;
    margin-right: -30px;
    width: auto;
    max-width: 1000% !important;
  }
}
.entry-content .g-post, .entry-content .instagram-media, .entry-content .reddit-card, .entry-content .tumblr-post, .entry-content .twitter-tweet {
  margin-left: auto !important;
  margin-right: auto !important;
}

.entry-content .gallery {
  text-align: center;
}

.entry-content .gallery:after {
  content: "";
  display: table;
  clear: both;
}

.entry-content .gallery .gallery-item {
  position: relative;
}

.entry-content .gallery .gallery-item img {
  max-width: 100% !important;
  height: auto !important;
}

.entry-content .gallery.gallery-columns-1, .entry-content .gallery.gallery-columns-2, .entry-content .gallery.gallery-columns-3, .entry-content .gallery.gallery-columns-4, .entry-content .gallery.gallery-columns-5, .entry-content .gallery.gallery-columns-6, .entry-content .gallery.gallery-columns-7, .entry-content .gallery.gallery-columns-8, .entry-content .gallery.gallery-columns-9 {
  margin: -5px -5px 15px -5px;
}

.entry-content .gallery.gallery-columns-1 .gallery-item, .entry-content .gallery.gallery-columns-2 .gallery-item, .entry-content .gallery.gallery-columns-3 .gallery-item, .entry-content .gallery.gallery-columns-4 .gallery-item, .entry-content .gallery.gallery-columns-5 .gallery-item, .entry-content .gallery.gallery-columns-6 .gallery-item, .entry-content .gallery.gallery-columns-7 .gallery-item, .entry-content .gallery.gallery-columns-8 .gallery-item, .entry-content .gallery.gallery-columns-9 .gallery-item {
  padding: 5px;
}

.entry-content .gallery.gallery-columns-2 {
  clear: both;
}

.entry-content .gallery.gallery-columns-2 .gallery-item {
  width: 50%;
  float: left;
}

.entry-content .gallery.gallery-columns-3 .gallery-item {
  width: 33.33%;
  float: left;
}

.entry-content .gallery.gallery-columns-4 .gallery-item {
  width: 25%;
  float: left;
}

.entry-content .gallery.gallery-columns-5 .gallery-item {
  width: 20%;
  float: left;
}

.entry-content .gallery.gallery-columns-6 .gallery-item {
  width: 16.666%;
  float: left;
}

.entry-content .gallery.gallery-columns-7 .gallery-item {
  width: 14.28%;
  float: left;
}

.entry-content .gallery.gallery-columns-8 .gallery-item {
  width: 12.5%;
  float: left;
}

.entry-content .gallery.gallery-columns-9 .gallery-item {
  width: 11.111%;
  float: left;
}

.entry-content .gallery.gallery-columns-2 .gallery-item:nth-child(2n+3), .entry-content .gallery.gallery-columns-3 .gallery-item:nth-child(3n+4), .entry-content .gallery.gallery-columns-4 .gallery-item:nth-child(4n+5), .entry-content .gallery.gallery-columns-5 .gallery-item:nth-child(5n+6), .entry-content .gallery.gallery-columns-6 .gallery-item:nth-child(6n+7), .entry-content .gallery.gallery-columns-7 .gallery-item:nth-child(7n+8), .entry-content .gallery.gallery-columns-8 .gallery-item:nth-child(8n+9), .entry-content .gallery.gallery-columns-9 .gallery-item:nth-child(9n+10) {
  clear: left;
}

.entry-content .terms-list {
  margin-bottom: 10px;
}

.entry-content .terms-list span.sep {
  margin: 0 5px;
}

.entry-content.single-post-content a:hover, .single-page-simple-content .entry-content a:hover {
  text-decoration: underline;
}

.entry-content hr {
  margin: 27px 0;
  border-top: 2px solid #f0f0f0;
}

.entry-content .wp-block-separator {
  border: none;
  border-bottom: 2px solid #f0f0f0;
  margin: 1.65em auto;
}

.entry-content .wp-block-separator.is-style-default {
  border: none;
  border-bottom: 2px solid #f0f0f0;
  margin: 1.65em auto;
}

.entry-content .wp-block-separator.is-style-wide {
  border-bottom-width: 1px;
}

.entry-content .wp-block-separator.is-style-dots {
  border-bottom: none;
}

.entry-content .wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
  max-width: 100px;
}

.entry-content > :last-child {
  margin-bottom: 0;
}

.entry-content > h1:first-child, .entry-content > .h1:first-child, .entry-content > h2:first-child, .entry-content > .h2:first-child, .entry-content > h3:first-child, .entry-content > .h3:first-child, .entry-content > h4:first-child, .entry-content > .h4:first-child, .entry-content > h5:first-child, .entry-content > .h5:first-child, .entry-content > h6:first-child, .entry-content > .h6:first-child {
  margin-top: 0;
}

blockquote {
  font-size: 14px;
}

blockquote p:last-child {
  margin-bottom: 0;
}

.entry-content.single-post-content blockquote, .single-page-simple-content .entry-content blockquote {
  font-size: 110%;
  background-color: #efefef;
  border-left: none;
  padding: 60px 35px 50px;
  margin: 40px 0 30px 0;
  position: relative;
  text-align: center;
  clear: both;
}

.entry-content.single-post-content blockquote:before, .single-page-simple-content .entry-content blockquote:before {
  content: "\f10e";
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -30px;
  margin-left: -40px;
  font-size: 30px;
  font-family: FontAwesome;
  color: #444;
  display: block;
  width: 80px;
  height: 80px;
  background: #fff;
  line-height: 91px;
  border-radius: 50%;
  text-align: center;
}

.entry-content.single-post-content blockquote blockquote:before, .single-page-simple-content .entry-content blockquote blockquote:before {
  display: none;
}

.entry-content.single-post-content blockquote blockquote, .single-page-simple-content .entry-content blockquote blockquote {
  background-color: transparent;
  padding: 0;
  margin: 0 0 10px;
}

.entry-content.single-post-content blockquote blockquote:last-child, .single-page-simple-content .entry-content blockquote blockquote:last-child {
  margin-bottom: 0;
}

.entry-content.single-post-content blockquote.bs-pullquote, .single-page-simple-content .entry-content blockquote.bs-pullquote {
  min-width: 250px;
  max-width: 333px;
  display: inline-block;
  padding: 20px 20px 20px;
  margin: 0 20px 20px 0;
  background-color: #f3f3f3;
  clear: none;
}

.entry-content blockquote.bs-pullquote:before {
  display: none;
}

.entry-content.single-post-content blockquote.bs-pullquote-right, .single-page-simple-content .entry-content blockquote.bs-pullquote-right {
  text-align: right;
  float: right;
  margin-left: 25px;
  border-right: 4px solid transparent;
}

.entry-content.single-post-content blockquote.bs-pullquote-left, .single-page-simple-content .entry-content blockquote.bs-pullquote-left {
  text-align: left;
  float: left;
  margin-right: 25px;
  border-left: 4px solid transparent;
}

@media only screen and (max-width: 530px) {
  .alignleft, .alignright, .entry-content .alignleft, .entry-content .alignright {
    text-align: center !important;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 100% !important;
    display: block !important;
  }
  .entry-content figure.alignright .wp-caption-text {
    text-align: center !important;
  }
}
.continue-reading-container {
  display: none;
}

@media only screen and (max-width: 700px) {
  .continue-reading-content.close {
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    max-height: 170px;
    transition: max-height 0.75s ease;
    width: 100%;
  }
  .continue-reading-content.close:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(transparent 35%, #fff);
    z-index: 99;
  }
  .continue-reading-container {
    display: block;
    text-align: center;
    position: relative;
    z-index: 300;
    bottom: 25px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px;
    letter-spacing: 1.4px;
  }
  .continue-reading-container:last-child {
    border-bottom: none;
  }
  .continue-reading-btn.btn {
    color: #fff;
    line-height: 42px;
    height: auto;
    padding: 0 42px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.37);
    border-radius: 23px;
  }
}
.comments-wrap {
  margin: 0 auto;
  position: relative;
}

.comments-template .comments-closed {
  text-align: center;
  padding: 20px 15px;
  background-color: #f5f5f5;
}

.comments-wrap ol.comment-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.comments-wrap ol.comment-list .children {
  list-style: none;
  margin: 0;
  padding-left: 10%;
}

@media only screen and (max-width: 600px) {
  .comments-wrap ol.comment-list .children {
    padding-left: 7%;
  }
}
.comments-wrap li.comment, .comments-wrap li.pingback {
  clear: both;
}

.comments-wrap li > div {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding: 15px 0;
}

.comments-wrap .comment-list > li:first-child > div {
  border-top: none;
}

.comment-list .comment-avatar {
  float: left;
  margin: 0 20px 10px 0;
}

.comment-list .comment-avatar .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.comment-list .comment-meta {
  margin: 0 0 5px;
  color: #858585;
}

.comment-list .comment-meta .comment-author {
  display: inline-block;
  padding: 4px 0 0;
  font-size: 15px;
  text-transform: capitalize !important;
  font-style: normal;
}

.comments-wrap li.pingback .comment-meta .comment-author {
  padding-top: 0;
}

.comment-list .comment-meta .comment-author .says {
  color: #9a9a9a;
}

.comment-list .comment-meta .comment-published {
  font-style: italic;
  display: inline-block;
  margin: 0;
  padding: 4px 0 0;
  float: right;
  font-size: 11px;
  color: #c2c2c2;
}

.comment-list .comment-content {
  margin: 0 0 8px;
  font-size: 13px;
  padding-left: 80px;
}

.comment-list .comment-content em.needs-approve {
  background: #f2f2f2;
  padding: 2px 5px;
  border-radius: 3px;
  margin-bottom: 7px;
  display: inline-block;
}

.comment-list .comment-footer {
  font-size: 12px;
}

.comment-list .comment-footer .comment-edit-link {
  margin-left: 15px;
}

.comment-list .comment-footer, .comment-list .comment-footer .comment-edit-link {
  color: #868686;
  float: right;
}

.comment-list .comment-footer .comment-reply-link {
  display: inline-block;
  float: right;
  color: #909090;
  text-align: center;
}

.comments-wrap .comments-nav {
  margin-top: 10px;
  position: relative;
  border-top: 1px solid #ebebeb;
  padding-top: 10px;
  padding-bottom: 5px;
}

.comments-wrap .comments-nav .pagination {
  margin-top: 0;
}

.comments-wrap .comments-nav .page-numbers {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: 400;
}

.comment-respond {
  margin: 0 auto;
  position: relative;
}

.comments-wrap + .comment-respond {
  margin-top: 20px;
}

@media only screen and (max-width: 780px) {
  .comment-respond {
    margin-bottom: 30px;
  }
}
.comment-respond .comment-reply-title:empty {
  display: none;
}

.comment-respond #cancel-comment-reply-link, .comment-respond .comment-reply-title .h-text {
  display: inline-block;
  padding: 6px 12px;
  text-transform: uppercase;
}

.comment-respond .log-in-out {
  text-align: center;
}

.form-allowed-tags {
  margin-top: -1.5rem;
  font-size: 0.9375rem;
}

.comment-respond #cancel-comment-reply-link {
  float: right;
  color: #fff !important;
}

.comments-wrap .comment-respond .comment-reply-title {
  text-align: inherit;
}

.comment-respond .wp-social-login-widget {
  text-align: center;
}

.comment-respond .wp-social-login-connect-with {
  font-size: 17px;
  font-weight: 700;
  color: #414141;
}

.comment-respond .note-after, .comment-respond .note-before {
  text-align: center;
  margin-bottom: 15px;
}

.comment-respond .note-after p:last-child, .comment-respond .note-before p:last-child {
  margin-bottom: 0;
}

.comment-respond .author-wrap {
  width: 50%;
  float: left;
  padding-right: 10px;
}

.comment-respond .email-wrap {
  width: 50%;
  float: left;
  padding-left: 10px;
}

.comment-respond .url-wrap {
  width: 100%;
}

.comment-respond .url-wrap .url {
  max-width: 100%;
  width: 100%;
}

.comment-respond .author-wrap input, .comment-respond .email-wrap input {
  width: 100%;
}

.comment-respond .author-wrap, .comment-respond .comment-wrap, .comment-respond .email-wrap, .comment-respond .url-wrap {
  margin-bottom: 15px;
}

.comment-respond .author, .comment-respond .comment, .comment-respond .email, .comment-respond .url {
  border: 1px solid #d8d8d8;
  padding: 18px 12px;
}

.comment-respond .author, .comment-respond .email, .comment-respond .url {
  padding: 8px 12px;
  height: auto;
}

.comment-respond .comment {
  height: 160px;
}

.comment-respond .comment-submit {
  background: #434343;
  font-size: 12px;
  font-weight: 400;
}

.comment-respond .form-submit {
  margin: 0;
}

.ajaxified-comments-container {
  margin: 20px 0;
  border: 1px solid #e1e2e4;
  background: #f6f7f9;
  padding: 20px 20px;
  text-align: center;
  color: #1d1d1d;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bolder;
}

.comment-ajaxified-placeholder {
  color: #696969;
  margin: 0 5px;
}

.comment-form input + label {
  margin-left: 5px;
}

.comments-template-multiple .nav-tabs {
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.comments-template-multiple .nav-tabs li {
  list-style: none;
  display: inline-block;
  margin: 0 !important;
  width: 33.333%;
  line-height: 56px;
}

.comments-template-multiple.providers-2 .nav-tabs li {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .comments-template-multiple.providers-2 .nav-tabs li, .comments-template-multiple.providers-3 .nav-tabs li {
    width: 100% !important;
  }
}
.comments-template-multiple .nav-tabs a {
  font-size: 14px;
  font-weight: 700;
  padding: 0 26px;
  display: block;
  line-height: 56px;
  height: 56px;
  border: none !important;
  border-radius: 0;
  white-space: nowrap;
  position: relative;
  transition: all 0.3s ease;
  text-align: center;
  background: #eff0f1;
  margin: 0 !important;
}

.comments-template-multiple .nav-tabs li:first-child a {
  border-radius: 4px 0 0 4px;
}

.comments-template-multiple .nav-tabs li:last-child a {
  border-radius: 0 4px 4px 0;
}

.comments-template-multiple .nav-tabs a:after {
  content: " ";
  display: inline-block;
  height: 3px;
  width: 0;
  background-color: transparent;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.3s ease;
  border-radius: 23px;
}

.comments-template-multiple .nav-tabs .active a:after {
  width: 80%;
}

.comments-template-multiple .nav-tabs a:before {
  content: " ";
  display: inline-block;
  height: 56px;
  width: 1px;
  background-color: #ddd;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
}

.comments-template-multiple .nav-tabs li:last-child a:before {
  display: none;
}

.comments-template-multiple .nav-tabs .active a {
  border: none !important;
}

.comments-template-multiple .nav-tabs .fa {
  margin-right: 6px;
  font-size: 16px;
}

.comments-template-multiple .comments-count, .comments-template-multiple .disqus-comment-count {
  background-color: #cacaca;
  color: #262626;
  border-radius: 3px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: 700;
  vertical-align: middle;
  margin-left: 10px;
  transition: all 0.3s ease;
}

.comments-template-multiple .disqus-comment-count {
  width: 30px;
  display: inline-block;
  white-space: normal;
  overflow: hidden;
  height: 20px !important;
  line-height: 20px;
}

.comments-template-multiple .active .comments-count {
  color: #fff;
}

.comments-template-multiple .comments-count:empty {
  display: none;
}

.comments-template-multiple .multi-wordpress-comment-section .comment-respond:first-child > .section-heading {
  display: none;
}

.comments-template-multiple .tab-pane .comments-template, .comments-template-multiple .tab-pane .comments-template.comment-respond {
  margin-top: 10px !important;
}

.comments-template-multiple .multi-wordpress-comment-section .comment-respond:first-child {
  margin-top: 15px !important;
}

.back-top {
  color: #fff;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  text-align: center;
  line-height: 42px;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 16px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
}

.back-top.fade-out:hover {
  opacity: 1;
}

.back-top.is-visible {
  visibility: visible;
  opacity: 0.6;
  bottom: 8px;
}

.back-top.fade-out1 {
  opacity: 0.7;
}

.back-top.fade-out2 {
  opacity: 0.8;
}

.back-top.fade-out3 {
  opacity: 0.9;
}

.back-top.fade-out4 {
  opacity: 1;
}

.back-top.dark {
  background: #383838;
  color: #fff;
}

.back-top:hover {
  opacity: 1;
}

.error404 .content-column {
  max-width: 800px !important;
  margin: 10px auto 50px;
}

.error404 .content-column .second-row {
  margin-bottom: 5px;
}

.error404 .content-column .desc-section {
  text-align: center;
}

.error404 .content-column .desc-section .title-404 {
  margin: 0 0 15px;
  color: #444;
  font-weight: bolder;
  text-align: center;
}

.error404 .content-column .text-404 {
  font-size: 130px;
  line-height: 175px;
  font-weight: 700;
  color: #444;
  text-align: center;
  margin: 0;
}

.error404 .content-column .action-links {
  margin-bottom: 10px;
  margin-top: 20px;
}

.error404 .content-column .action-links .fa {
  margin-right: 5px;
}

.error404 .content-column .top-line {
  margin-top: 15px;
  padding-top: 25px;
}

.error404 .content-column .search-form {
  position: relative;
}

.error404 .content-column .search-form label {
  width: 100%;
}

.error404 .content-column .search-form .search-field {
  width: 100%;
  border: 1px solid #cbcbcb;
  height: 34px;
  line-height: 20px;
  padding: 0 30% 0 10px;
  outline: 0;
  float: left;
  background: #fff;
}

.error404 .content-column .search-form .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  height: 34px;
  padding: 0 7%;
  line-height: 28px;
  border: none;
  text-transform: uppercase;
  color: #fff;
  margin-top: 0;
}

.error404 .content-column .search-form.have-focus .search-field {
  border-color: #9e9e9e;
}

.error404 .search-action-container {
  width: 50%;
  float: left;
  margin: 0;
}

.error404 script + .search-action-container:last-child {
  width: 100%;
  float: none;
}

@media (max-width: 768px) {
  .error404 .content-column .search-form {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.author-profile {
  margin-bottom: 29px;
}

.author-profile .post-author-avatar {
  float: left;
  margin-right: 15px;
}

.author-profile .post-author-avatar img {
  border-radius: 50%;
}

.author-profile .author-title {
  font-size: 22px;
  margin: 20px 0 0;
  text-transform: uppercase;
}

.author-profile .author-title .author-title {
  margin: 0 0 7px;
  font-size: 18px;
  text-transform: capitalize;
  padding-right: 50%;
}

.author-profile .author-title .title-counts {
  color: #8a8a8a;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 400;
}

.author-profile .author-title .title-counts:before {
  content: "-";
  margin: 0 8px;
  opacity: 0.4;
}

.author-profile .post-author-bio {
  margin: 10px auto 0 auto;
  padding-left: 115px;
}

.author-profile .post-author-bio p:last-child {
  margin-bottom: 0;
}

.author-profile .author-links {
  margin-top: 15px;
}

.author-profile .author-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.author-profile .author-links ul li {
  display: inline-block;
  background-color: #fff;
  padding: 0;
  margin: 0 10px 0 0;
  position: relative;
  z-index: 1;
  text-align: center;
}

.author-profile .author-links ul li a, .author-profile .author-links ul li > span {
  display: inline-block;
  line-height: 26px;
  height: 26px;
  width: 26px;
  background-color: #eee;
  font-size: 12px;
  color: #fff;
  vertical-align: middle;
}

.author-profile .author-links ul li.posts-count > span {
  background-color: transparent;
  color: #2d2d2d;
  border: 1px solid #d8d8d8;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  padding: 0 8px;
  text-transform: uppercase;
  line-height: 24px;
}

.author-profile .author-links ul li.facebook a {
  background-color: #4a639f;
}

.author-profile .author-links ul li.twitter a {
  background-color: #59c8ff;
}

.author-profile .author-links ul li.google-plus a {
  background-color: #d73d32;
}

.author-profile .author-links ul li.instagram a {
  background-color: #517fa4;
}

.author-profile .author-links ul li.flickr a {
  background-color: #2f44db;
}

.author-profile .author-links ul li.behance a {
  background-color: #3b63fc;
}

.author-profile .author-links ul li.soundcloud a {
  background-color: #f50;
}

.author-profile .author-links ul li.delicious a {
  background-color: #4a79ff;
}

.author-profile .author-links ul li.vimeo a {
  background-color: #46a3e9;
}

.author-profile .author-links ul li.dribbble a {
  background-color: #ea4c89;
}

.author-profile .author-links ul li.linkedin a {
  background-color: #0266a0;
}

.author-profile .author-links ul li.youtube a {
  background-color: #cc181e;
}

.author-profile .author-links ul li.pinterest a {
  background-color: #cb2027;
}

.author-profile .author-links ul li.github a {
  background-color: #666;
}

.author-profile .author-links ul li.site a {
  background-color: #666;
}

.better-gallery {
  margin: 10px 0 25px;
}

.better-gallery .gallery-title {
  color: #fff;
  padding: 10px 0 0;
  text-align: center;
  user-select: none;
}

.better-gallery p {
  margin: 0;
}

.better-gallery br {
  display: none;
}

.better-gallery .gallery-title .next, .better-gallery .gallery-title .prev {
  cursor: pointer;
  color: #373737;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.17);
  font-family: "Open Sans";
  font-size: 11px !important;
  padding: 0 11px;
  display: inline-block;
  line-height: 20px !important;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-transform: uppercase;
}

.better-gallery .gallery-title .next:hover, .better-gallery .gallery-title .prev:hover {
  color: #fff;
}

.better-gallery .gallery-title .next .fa {
  margin-left: 3px;
}

.better-gallery .gallery-title .prev .fa {
  margin-right: 3px;
}

.better-gallery .count {
  font-size: 12px;
  margin-right: 20px;
  margin-left: 20px;
  font-style: normal;
  line-height: 24px;
  display: inline-block;
  color: grey;
  vertical-align: middle;
  min-width: 46px;
}

.better-gallery .count i {
  font-style: normal;
}

.better-gallery .main-title {
  width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #373737;
  font-weight: bolder;
  text-align: center;
  font-size: 26px;
  margin-bottom: 12px;
}

.better-gallery .slide-title-wrap {
  display: block;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.better-gallery .slide-title-wrap .slide-copy, .better-gallery .slide-title-wrap .slide-title {
  padding: 4px 11px;
  background-color: rgba(20, 20, 20, 0.55);
  display: inline-block;
  font-size: 12px;
}

.better-gallery .slide-title-wrap .slide-copy {
  margin: 4px;
  font-style: italic;
}

.better-gallery .fotorama__thumb:before {
  width: 85px;
  height: 62px;
  position: relative;
  display: block;
  content: "";
  border: 2px solid rgba(255, 255, 255, 0.33);
  z-index: 8;
}

.better-gallery .fotorama__nav:before, .better-gallery .fotorama__stage:before {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.6) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.7), transparent);
  background-position: 0 0, 0 0;
  opacity: 0.4;
}

.better-gallery .fotorama__nav:after, .better-gallery .fotorama__stage:after {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.6) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.7), transparent);
  background-position: 100% 0, 100% 0;
  opacity: 0.4;
}

.better-gallery .fotorama__wrap {
  padding-top: 15px;
}

.better-gallery .count {
  font-size: 12px;
  margin-right: 20px;
  margin-left: 20px;
  font-style: normal;
  line-height: 24px;
  display: inline-block;
  color: grey;
  vertical-align: middle;
  text-transform: lowercase;
}

.better-gallery .fotorama__nav-wrap {
  margin-top: 8px;
  padding: 0 10px;
}

.better-gallery .fotorama__wrap .fotorama__html {
  height: 100%;
  text-align: center;
}

.better-gallery .fotorama__wrap .fotorama__html img {
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-box-shadow: 0 0 22px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0 0 22px rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.17);
}

.better-gallery .fotorama__wrap .fotorama__html > div {
  height: 100%;
}

.better-gallery.better-gallery-img-full .fotorama__nav__frame {
  text-align: center;
}

.better-gallery.better-gallery-img-full .fotorama__nav__frame img {
  max-width: 100% !important;
  max-height: 100% !important;
  height: auto !important;
  width: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0;
}

.no-results {
  text-align: center;
}

.no-results > .title {
  text-align: center;
  font-size: 28px;
  text-transform: uppercase;
  position: relative;
  margin: 20px 0;
}

.no-results > .title:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  height: 2px;
  width: 100%;
  background-color: #f0f0f0;
}

.no-results > .title span {
  display: inline-block;
  padding: 0 10px;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.no-results .search-form {
  margin: 20px 0 30px;
}

.no-results .search-form input[type=submit] {
  float: none;
}

.no-results .search-form input[type=search] {
  float: none;
  width: 240px;
}

.widget > .bs-shortcode:last-child {
  margin-bottom: 0;
}

.bs-shortcode.bs-heading-shortcode {
  margin-bottom: 21px;
}

@-webkit-keyframes bs-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bs-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes bs-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bs-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes bs-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bs-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes bs-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bs-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes bs-spin {
  0% {
    -webkit-transform: rotate(0);
    tranform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    tranform: rotate(360deg);
  }
}
@keyframes bs-spin {
  0% {
    -webkit-transform: rotate(0);
    tranform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    tranform: rotate(360deg);
  }
}
.bs-animate {
  -webkit-animation-duration: 0.55s;
  animation-duration: 0.55s;
  -webkit-animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
  animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bs-fadeInLeft {
  -webkit-animation-name: bs-fadeInLeft;
  animation-name: bs-fadeInLeft;
}

.bs-fadeInRight {
  -webkit-animation-name: bs-fadeInRight;
  animation-name: bs-fadeInRight;
}

.bs-fadeInDown {
  -webkit-animation-name: bs-fadeInDown;
  animation-name: bs-fadeInDown;
}

.bs-fadeInUp {
  -webkit-animation-name: bs-fadeInUp;
  animation-name: bs-fadeInUp;
}

.bs-spin {
  -webkit-animation-name: bs-spin;
  animation-name: bs-spin;
}

.pagination-animate > .bs-animate {
  -webkit-animation-duration: 0.55s;
  animation-duration: 0.55s;
  -webkit-animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
  animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
}

.bs-tab-anim.bs-tab-animated.active {
  -webkit-animation-duration: 0.55s;
  animation-duration: 0.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bs-fadeInDown;
  animation-name: bs-fadeInDown;
  -webkit-animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
  animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
}

.bs-loading, .bs-loading > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-loading {
  display: block;
  font-size: 0;
  color: #333;
}

.bs-loading > div {
  display: inline-block;
  float: none;
}

.bs-loading {
  width: 54px;
  height: 54px;
}

.bs-loading > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  -webkit-animation-name: bs-loading-keyframe;
  -moz-animation-name: bs-loading-keyframe;
  -o-animation-name: bs-loading-keyframe;
  animation-name: bs-loading-keyframe;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.bs-loading > div:nth-child(1) {
  -webkit-animation-duration: 0.65s;
  -moz-animation-duration: 0.65s;
  -o-animation-duration: 0.65s;
  animation-duration: 0.65s;
  -webkit-animation-delay: 0.03s;
  -moz-animation-delay: 0.03s;
  -o-animation-delay: 0.03s;
  animation-delay: 0.03s;
}

.bs-loading > div:nth-child(2) {
  -webkit-animation-duration: 1.02s;
  -moz-animation-duration: 1.02s;
  -o-animation-duration: 1.02s;
  animation-duration: 1.02s;
  -webkit-animation-delay: 0.09s;
  -moz-animation-delay: 0.09s;
  -o-animation-delay: 0.09s;
  animation-delay: 0.09s;
}

.bs-loading > div:nth-child(3) {
  -webkit-animation-duration: 1.06s;
  -moz-animation-duration: 1.06s;
  -o-animation-duration: 1.06s;
  animation-duration: 1.06s;
  -webkit-animation-delay: -0.69s;
  -moz-animation-delay: -0.69s;
  -o-animation-delay: -0.69s;
  animation-delay: -0.69s;
}

.bs-loading > div:nth-child(4) {
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: -0.41s;
  -moz-animation-delay: -0.41s;
  -o-animation-delay: -0.41s;
  animation-delay: -0.41s;
}

.bs-loading > div:nth-child(5) {
  -webkit-animation-duration: 1.6s;
  -moz-animation-duration: 1.6s;
  -o-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-delay: 0.04s;
  -moz-animation-delay: 0.04s;
  -o-animation-delay: 0.04s;
  animation-delay: 0.04s;
}

.bs-loading > div:nth-child(6) {
  -webkit-animation-duration: 0.84s;
  -moz-animation-duration: 0.84s;
  -o-animation-duration: 0.84s;
  animation-duration: 0.84s;
  -webkit-animation-delay: 0.07s;
  -moz-animation-delay: 0.07s;
  -o-animation-delay: 0.07s;
  animation-delay: 0.07s;
}

.bs-loading > div:nth-child(7) {
  -webkit-animation-duration: 0.68s;
  -moz-animation-duration: 0.68s;
  -o-animation-duration: 0.68s;
  animation-duration: 0.68s;
  -webkit-animation-delay: -0.66s;
  -moz-animation-delay: -0.66s;
  -o-animation-delay: -0.66s;
  animation-delay: -0.66s;
}

.bs-loading > div:nth-child(8) {
  -webkit-animation-duration: 0.93s;
  -moz-animation-duration: 0.93s;
  -o-animation-duration: 0.93s;
  animation-duration: 0.93s;
  -webkit-animation-delay: -0.76s;
  -moz-animation-delay: -0.76s;
  -o-animation-delay: -0.76s;
  animation-delay: -0.76s;
}

.bs-loading > div:nth-child(9) {
  -webkit-animation-duration: 1.24s;
  -moz-animation-duration: 1.24s;
  -o-animation-duration: 1.24s;
  animation-duration: 1.24s;
  -webkit-animation-delay: -0.76s;
  -moz-animation-delay: -0.76s;
  -o-animation-delay: -0.76s;
  animation-delay: -0.76s;
}

@-webkit-keyframes bs-loading-keyframe {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -webkit-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes bs-loading-keyframe {
  0% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -moz-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes bs-loading-keyframe {
  0% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -o-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bs-loading-keyframe {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -webkit-transform: scale(0.45);
    -moz-transform: scale(0.45);
    -o-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.deferred-loading-container {
  position: relative;
}

.bs-pagination-wrapper:focus {
  outline: 0 !important;
}

.bs-pagin-loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.bs-pagin-loading-wrapper .bs-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -27px;
  margin-left: -27px;
}

.bs-pagination-wrapper .bs-pagination-in-loading {
  opacity: 0.15;
  pointer-events: none;
}

.bs-pagination {
  margin: 15px 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.post-related .bs-pagination {
  margin-top: 0;
}

.bs-pagination-label {
  color: #9c9c9c;
  color: rgba(138, 138, 138, 0.83);
  font-size: 11px;
  margin-left: 4px;
}

.btn-bs-pagination {
  border: 1px solid #d4d4d4;
  border: 1px solid rgba(138, 138, 138, 0.33);
  color: #9c9c9c !important;
  color: rgba(138, 138, 138, 0.83) !important;
  font-size: 11px !important;
  padding: 0 6px;
  display: inline-block;
  line-height: 22px !important;
  min-width: 26px;
  text-align: center !important;
  cursor: pointer;
  text-transform: uppercase !important;
}

.btn-bs-pagination.bs-pagination-in-loading, .btn-bs-pagination.hover, .btn-bs-pagination:hover {
  color: #fff !important;
}

body .bs-pagination a.btn-bs-pagination.disabled:hover {
  border: 1px solid #d4d4d4 !important;
  border: 1px solid rgba(138, 138, 138, 0.33) !important;
  color: #9c9c9c !important;
  color: rgba(138, 138, 138, 0.83) !important;
  background-color: transparent !important;
  cursor: default;
}

.btn-bs-pagination.disabled, .btn-bs-pagination.disabled:hover {
  opacity: 0.5;
}

.btn-bs-pagination .fa {
  font-weight: 700;
}

.btn-bs-pagination.next .fa {
  margin-left: 2px;
}

.btn-bs-pagination.prev .fa {
  margin-right: 2px;
}

.bs-pagination-wrapper {
  position: relative;
}

.btn-bs-pagination .loading .fa {
  margin-right: 2px;
}

.btn-bs-pagination .loaded .fa {
  margin-left: 2px;
}

.bs-pagination.bs-ajax-pagination.infinity, .bs-pagination.bs-ajax-pagination.more_btn, .bs-pagination.bs-ajax-pagination.more_btn_infinity {
  text-align: center;
}

.bs-pagination.bs-ajax-pagination.infinity .btn-bs-pagination, .bs-pagination.bs-ajax-pagination.more_btn .btn-bs-pagination, .bs-pagination.bs-ajax-pagination.more_btn_infinity .btn-bs-pagination {
  line-height: 26px !important;
  padding: 0 7px;
}

.bs-pagination.bs-simple-pagination .btn-bs-pagination.prev .fa {
  margin: 0 0 0 2px;
}

.bs-pagination.bs-simple-pagination .btn-bs-pagination.next .fa {
  margin: 0 2px 0 0;
}

.bs-listing-listing-classic-2 .bs-pagination, .bs-listing-listing-classic-2 .bs-pagination .pagination, .bs-listing-listing-classic-3 .bs-pagination, .bs-listing-listing-classic-3 .bs-pagination .pagination, .bs-listing-listing-grid-1 .bs-pagination, .bs-listing-listing-grid-1 .bs-pagination .pagination, .bs-listing-listing-grid-2 .bs-pagination, .bs-listing-listing-grid-2 .bs-pagination .pagination, .bs-listing-listing-mix-3-2 .bs-pagination, .bs-listing-listing-mix-3-2 .bs-pagination .pagination, .bs-listing-listing-mix-4-1 .bs-pagination, .bs-listing-listing-mix-4-1 .bs-pagination .pagination, .bs-listing-listing-mix-4-2 .bs-pagination, .bs-listing-listing-mix-4-2 .bs-pagination .pagination, .bs-listing-listing-mix-4-3 .bs-pagination, .bs-listing-listing-mix-4-3 .bs-pagination .pagination, .bs-listing-listing-mix-4-4 .bs-pagination, .bs-listing-listing-mix-4-4 .bs-pagination .pagination, .bs-listing-listing-mix-4-5 .bs-pagination, .bs-listing-listing-mix-4-5 .bs-pagination .pagination, .bs-listing-listing-mix-4-6 .bs-pagination, .bs-listing-listing-mix-4-6 .bs-pagination .pagination, .bs-listing-listing-tall-1 .bs-pagination, .bs-listing-listing-tall-1 .bs-pagination .pagination, .bs-listing-listing-tall-2 .bs-pagination, .bs-listing-listing-tall-2 .bs-pagination .pagination, .bs-listing-listing-text-1 .bs-pagination, .bs-listing-listing-text-1 .bs-pagination .pagination, .bs-listing-listing-thumbnail-1 .bs-pagination, .bs-listing-listing-thumbnail-1 .bs-pagination .pagination, .bs-listing-listing-thumbnail-2 .bs-pagination, .bs-listing-listing-thumbnail-2 .bs-pagination .pagination, .bs-listing-listing-thumbnail-3 .bs-pagination, .bs-listing-listing-thumbnail-3 .bs-pagination .pagination, .bs-listing-modern-grid-listing-3 .bs-pagination, .bs-listing-modern-grid-listing-3 .bs-pagination .pagination {
  margin-top: 0;
}

.bs-slider-controls .btn-bs-pagination.next {
  margin-left: 5px;
}

.bs-slider-items-container > .bs-slider-item {
  display: none;
}

.bs-slider-items-container .bs-slider-item.bs-slider-first-item {
  display: block;
}

.bs-slider-items-container .bs-control-nav-style-2 .btn-bs-pagination.next {
  float: right;
}

.bs-slider-items-container .bs-control-nav-style-3 {
  text-align: center;
}

.bs-listing.bs-listing-single-tab .bs-slider-items-container .bs-control-nav-style-4 .btn-bs-pagination {
  line-height: 22px;
  min-width: 24px;
  padding: 0 6px;
  border-radius: 50%;
}

.bs-slider-dots {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.bs-slider-dots li {
  display: inline-block;
}

.bs-slider-dots .bts-bs-dots-btn {
  cursor: pointer;
}

.bs-slider-dots-style-1, .bs-slider-dots-style-2, .bs-slider-dots-style-3, .bs-slider-dots-style-4 {
  text-align: center;
  line-height: 27px;
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
}

.bs-control-nav + .bs-slider-dots-style-1, .bs-control-nav + .bs-slider-dots-style-2, .bs-control-nav + .bs-slider-dots-style-3, .bs-control-nav + .bs-slider-dots-style-4 {
  bottom: 0;
}

.bs-control-nav-style-4 + .bs-slider-dots-style-1, .bs-control-nav-style-4 + .bs-slider-dots-style-2, .bs-control-nav-style-4 + .bs-slider-dots-style-3, .bs-control-nav-style-4 + .bs-slider-dots-style-4 {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.bs-slider-dots-style-1 .bts-bs-dots-btn, .bs-slider-dots-style-2 .bts-bs-dots-btn, .bs-slider-dots-style-3 .bts-bs-dots-btn, .bs-slider-dots-style-4 .bts-bs-dots-btn {
  display: inline-block;
  height: 8px;
  width: 8px;
  text-indent: -9999px;
  overflow: hidden;
  background: #eee;
  background: rgba(0, 0, 0, 0.2);
  margin: 0 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  pointer-events: all;
}

.bs-slider-dots-style-1 .bts-bs-dots-btn {
  border-radius: 50%;
}

.bs-slider-dots-style-3 .bts-bs-dots-btn {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bs-slider-dots-style-4 .bts-bs-dots-btn {
  width: 12px;
  height: 4px;
}

.bs-simple-pagination.simple_next_prev .btn-bs-pagination.prev {
  float: right;
}

.bs-listing-listing-blog-1 .bs-slider-controls, .bs-listing-listing-blog-2 .bs-slider-controls, .bs-listing-listing-blog-3 .bs-slider-controls, .bs-listing-listing-blog-4 .bs-slider-controls, .bs-listing-listing-classic-1 .bs-slider-controls, .bs-listing-listing-classic-3 .bs-slider-controls, .bs-listing-listing-mix-1-1 .bs-slider-controls, .bs-listing-listing-mix-1-3 .bs-slider-controls, .bs-listing-listing-mix-1-4 .bs-slider-controls, .bs-listing-listing-mix-3-1 .bs-slider-controls, .bs-listing-listing-mix-3-3 .bs-slider-controls, .bs-listing-listing-mix-4-7 .bs-slider-controls, .bs-listing-listing-mix-4-8 .bs-slider-controls, .bs-listing-listing-text-1 .bs-slider-controls, .bs-listing-listing-text-2 .bs-slider-controls, .bs-listing-listing-text-3 .bs-slider-controls, .bs-listing-listing-text-4 .bs-slider-controls, .bs-listing-modern-grid-listing-1 .bs-slider-controls, .bs-listing-modern-grid-listing-2 .bs-slider-controls, .bs-listing-modern-grid-listing-5 .bs-slider-controls {
  height: 27px;
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-mix-1-4 .bs-slider-controls, .bs-listing-modern-grid-listing-5 .bs-slider-controls {
  margin-top: 0;
}

.bs-listing-listing-text-1 .bs-slider-controls, .bs-listing-listing-text-3 .bs-slider-controls, .bs-listing-listing-text-4 .bs-slider-controls {
  margin-top: 0;
}

.bs-listing-modern-grid-listing-1 .bs-slider-controls, .bs-listing-modern-grid-listing-2 .bs-slider-controls {
  margin-top: 10px;
}

.bs-listing-modern-grid-listing-6 .bs-slider-controls {
  height: 27px;
  margin-bottom: -10px;
  margin-top: 5px;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-mix-2-2 .bs-slider-controls {
  height: 27px;
  margin-top: 15px;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-thumbnail-1 .bs-slider-controls {
  height: 27px;
  margin-top: -5px;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-thumbnail-2 .bs-slider-controls {
  height: 27px;
  margin-top: 0;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-classic-2 .bs-slider-controls {
  height: 27px;
  margin-top: -5px;
  position: relative;
  z-index: 1;
}

.bs-listing-modern-grid-listing-4 .bs-slider-controls {
  height: 27px;
}

.bs-listing-listing-tall-1 .bs-slider-controls, .bs-listing-listing-tall-2 .bs-slider-controls {
  height: 27px;
  margin-top: 5px;
  position: relative;
  z-index: 1;
}

.bs-listing-modern-grid-listing-3 .bs-slider-controls {
  height: 27px;
  margin-top: -10px;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-grid-1 .bs-slider-controls, .bs-listing-listing-grid-2 .bs-slider-controls {
  height: 27px;
  margin-top: -7px;
  position: relative;
  z-index: 1;
}

.listing-mix-4-7 + .bs-pagination-response, .listing-mix-4-7 + .bs-pagination-response ~ .bs-pagination-response, .listing-mix-4-8 + .bs-pagination-response, .listing-mix-4-8 + .bs-pagination-response ~ .bs-pagination-response {
  margin-top: 20px;
}

.dropcap.dropcap {
  display: inline-block;
  float: left;
  margin: 0 8px -10px 0;
  font-size: 74px;
  line-height: 74px;
  height: 74px;
  text-transform: uppercase;
}

.dropcap.dropcap-square {
  color: #fff;
  padding: 0 11px;
}

.dropcap.dropcap-square-outline {
  border: 2px solid transparent;
  padding: 0 11px;
  line-height: 70px;
}

.dropcap.dropcap-circle {
  color: #fff;
  padding: 0 11px;
  border-radius: 50%;
  min-width: 74px;
  font-size: 52px;
  text-align: center;
}

.dropcap.dropcap-circle-outline {
  border: 2px solid transparent;
  padding: 0 11px;
  border-radius: 50%;
  min-width: 74px;
  font-size: 52px;
  text-align: center;
  line-height: 72px;
}

.bs-highlight {
  background-color: #ff9;
  padding: 0 3px;
}

.bs-highlight.bs-highlight-red {
  background-color: #ffb6b6;
}

.bs-tab-shortcode {
  margin-bottom: 20px;
}

.bs-tab-shortcode .nav.nav-tabs {
  border: none;
  padding: 0;
  margin: 0;
}

.bs-tab-shortcode .nav-tabs > li > a {
  background-color: #f3f4f6;
  border: 1px solid #d6d6d6 !important;
  padding: 9px 22px;
  margin-right: -1px;
  border-radius: 0;
  color: #4a4a4a !important;
  font-weight: bolder;
  position: relative;
}

.bs-tab-shortcode .nav-tabs > li > a:after {
  display: inline-block;
  content: "";
  height: 1px;
  left: 0;
  right: 0;
  bottom: -1px;
  background: 0 0;
  position: absolute;
}

.bs-tab-shortcode .nav-tabs > li.active > a, .bs-tab-shortcode .nav-tabs > li > a:focus, .bs-tab-shortcode .nav-tabs > li > a:hover {
  text-decoration: none !important;
  background-color: #fff;
}

.bs-tab-shortcode .nav-tabs > li.active > a:after {
  background-color: #fff;
}

.bs-tab-shortcode .tab-content .tab-pane {
  padding: 20px;
  border: 1px solid #d6d6d6;
}

.bs-row-shortcode {
  margin-bottom: 20px;
}

.bs-accordion-shortcode .panel {
  box-shadow: none;
  border: 1px solid #d6d6d6;
  -webkit-transition: all 0.07s ease;
  -moz-transition: all 0.07s ease;
  -o-transition: all 0.07s ease;
  transition: all 0.07s ease;
  border-radius: 0 !important;
}

.bs-accordion-shortcode .panel-heading {
  padding: 0 0 0 60px;
  position: relative;
}

.bs-accordion-shortcode .panel-heading a {
  color: #585858;
}

.bs-accordion-shortcode .panel-heading a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f067";
  margin: 0 10px 0 0;
  font-size: 14px;
  position: absolute;
  left: 0;
  width: 44px;
  line-height: 44px;
  top: 0;
  bottom: 0;
  text-align: center;
  border-right: 1px solid #d6d6d6;
}

.bs-accordion-shortcode .panel-heading.active a:before {
  content: "\f068";
}

.bs-accordion-shortcode .panel-heading *, .bs-accordion-shortcode .panel-heading .panel-title br {
  display: none;
}

.bs-accordion-shortcode .panel-heading .panel-title, .bs-accordion-shortcode .panel-heading .panel-title a {
  display: block;
}

.bs-accordion-shortcode .panel + .panel {
  margin-top: 12px !important;
}

.bs-accordion-shortcode .panel-heading .panel-title a {
  padding: 12px 0;
  font-size: 14px;
}

.bs-accordion-shortcode .panel-heading + .panel-collapse > .panel-body {
  -webkit-transition: all 0.07s ease;
  -moz-transition: all 0.07s ease;
  -o-transition: all 0.07s ease;
  transition: all 0.07s ease;
}

.bs-accordion-shortcode .panel .panel-heading a {
  color: #444 !important;
}

.bs-accordion-shortcode .panel .panel-heading a, .bs-accordion-shortcode .panel .panel-heading a:hover {
  text-decoration: none;
}

ul.bs-shortcode-list, ul.bs-shortcode-list ul {
  list-style: none !important;
  padding-left: 20px;
  overflow: hidden;
}

ul.bs-shortcode-list ul {
  padding-left: 13px;
}

ul.bs-shortcode-list li {
  position: relative;
  margin-bottom: 7px;
  padding-left: 25px;
}

ul.bs-shortcode-list li:before {
  width: 25px;
  content: "\f00c";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 6px;
}

ul.bs-shortcode-list li:empty {
  display: none;
}

ul.bs-shortcode-list.list-style-check li:before {
  content: "\f00c";
}

ul.bs-shortcode-list.list-style-star li:before {
  content: "\f005";
}

ul.bs-shortcode-list.list-style-edit li:before {
  content: "\f044";
}

ul.bs-shortcode-list.list-style-folder li:before {
  content: "\f07b";
}

ul.bs-shortcode-list.list-style-file li:before {
  content: "\f15b";
}

ul.bs-shortcode-list.list-style-heart li:before {
  content: "\f004";
}

ul.bs-shortcode-list.list-style-asterisk li:before {
  content: "\f069";
}

hr.bs-divider {
  height: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: #dbdbdb;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  margin: 30px auto 28px;
  position: relative;
  width: 90%;
}

hr.bs-divider.dashed-line {
  border-style: dashed;
  border-width: 1px;
}

hr.bs-divider.full {
  width: 100%;
}

hr.bs-divider.large {
  width: 90%;
}

hr.bs-divider.small {
  width: 70%;
}

hr.bs-divider.tiny {
  width: 50%;
}

hr.bs-divider + h1, hr.bs-divider + .h1, hr.bs-divider + h2, hr.bs-divider + .h2, hr.bs-divider + h3, hr.bs-divider + .h3 {
  margin-top: -10px;
}

hr.bs-divider + h5, hr.bs-divider + .h5 {
  margin-top: -5px;
}

h1 + hr.bs-divider, .h1 + hr.bs-divider, h2 + hr.bs-divider, .h2 + hr.bs-divider, h3 + hr.bs-divider, .h3 + hr.bs-divider, h4 + hr.bs-divider, .h4 + hr.bs-divider, h5 + hr.bs-divider, .h5 + hr.bs-divider {
  margin-top: 28px;
}

.bs-shortcode-alert.alert {
  border-radius: 0;
}

.bs-shortcode-alert.alert-simple {
  background-color: #f3f3f3;
  border-color: #e6e6e6;
}

.listing-item-blog.sticky, .listing-item-blog.sticky:first-child {
  background-color: #fffbdf;
  padding: 18px;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.vc_images_carousel:hover .vc_carousel-control .icon-next, .vc_images_carousel:hover .vc_carousel-control .icon-prev {
  background: #fff;
}

.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item {
  height: 0;
}

.vc_images_carousel .vc_carousel-control .icon-next.icon-next:before, .vc_images_carousel .vc_carousel-control .icon-prev.icon-prev:before {
  top: -11px;
  line-height: 30px;
  color: #333;
  text-shadow: none;
}

.vc_images_carousel .vc_carousel-control .icon-next.icon-next:before {
  right: -1px;
}

.vc_images_carousel .vc_carousel-control .icon-prev.icon-prev:before {
  left: -1px;
}

.wpb_gallery > .wpb_wrapper > .wpb_flexslider {
  padding: 0;
  margin: 0 0 30px;
  background: 0 0;
  border-radius: 0;
  box-shadow: none;
  zoom: 1;
  height: auto !important;
}

.wpb_flexslider .flex-direction-nav a {
  width: 30px;
  height: 30px;
}

.wpb_flexslider .flex-direction-nav a:before {
  font-family: flexslider-icon;
  font-size: 14px;
  display: inline-block;
  content: "\f001";
  text-shadow: none;
  line-height: 30px;
  height: 30px;
}

.wpb_gallery .wpb_flexslider .flex-direction-nav a {
  border: none;
  background: #fff;
  text-align: center;
  line-height: 30px;
}

.wpb_gmaps_widget .wpb_wrapper {
  padding: 0 !important;
}

.vc_btn3 {
  height: auto !important;
}

.wpcf7-form p {
  margin-bottom: 0;
  margin-top: 17px;
}

.wpcf7-form p:first-child {
  margin-top: 0;
}

.wpcf7-form label {
  display: block;
}

.archive-title.search-title.search-bgcs-title {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.better-gcs-wrapper {
  border-top: none;
  padding-top: 30px !important;
  margin-top: -30px;
}

.better-gcs-wrapper {
  padding-top: 30px !important;
}

.bs-pretty-tabs-container {
  position: relative;
  display: inline-block;
  float: right;
  text-align: center;
  z-index: 2;
}

.bs-pretty-tabs-container .bs-pretty-tabs-more {
  z-index: 3;
  position: relative;
  cursor: pointer;
}

.bs-pretty-tabs-container .bs-pretty-tabs-elements .other-link {
  display: block;
  float: none;
  width: 100%;
}

.bs-pretty-tabs-container .bs-pretty-tabs-elements .h-text {
  border: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 3px 10px;
  text-align: right;
}

.bs-pretty-tabs-container .bs-pretty-tabs-elements {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  z-index: 2;
  white-space: nowrap;
  border: 1px solid #d8d8d8;
  display: none;
  padding: 5px;
  min-width: 160px;
}

.bs-pretty-tabs .bs-pretty-tabs-more.other-link {
  pointer-events: none;
}

.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  position: relative;
  cursor: pointer;
  padding: 3px 10px;
  border: 1px solid transparent;
}

.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text {
  transition: none;
}

.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text .fa {
  margin-left: 3px;
}

.bs-pretty-tabs.bs-pretty-tabs-initialized .bs-pretty-tabs-container:hover .bs-pretty-tabs-more.other-link .h-text:after {
  display: block;
}

.bs-pretty-tabs-container.display-more-items .bs-pretty-tabs-elements {
  display: block;
}

.bs-pretty-tabs .bs-pretty-tabs-more.other-link:hover .h-text {
  background: 0 0 !important;
  color: currentColor !important;
  border-color: rgba(0, 0, 0, 0.13) !important;
}

.section-heading.multi-tab .other-link {
  opacity: 0;
  -webkit-transition: color 0.7s ease, background 0.7s ease;
  -moz-transition: color 0.7s ease, background 0.7s ease;
  -o-transition: color 0.7s ease, background 0.7s ease;
  transition: color 0.7s ease, background 0.7s ease;
  position: absolute;
}

.section-heading.multi-tab .bs-pretty-tabs-container {
  padding-left: 10px;
}

.bs-pretty-tabs.bs-pretty-tabs-initialized .other-link {
  opacity: 1;
  position: relative;
}

.bs-slider-1-item .rating-stars, .listing-mg-1-item .rating-stars, .listing-mg-2-item .rating-stars, .listing-mg-3-item .rating-stars, .listing-mg-4-item .rating-stars, .listing-mg-6-item .rating-stars {
  color: #fff;
}

.bs-slider-1-item .post-meta .rating-bar, .listing-mg-1-item .post-meta .rating-bar, .listing-mg-2-item .post-meta .rating-bar, .listing-mg-3-item .post-meta .rating-bar, .listing-mg-4-item .post-meta .rating-bar, .listing-mg-6-item .post-meta .rating-bar {
  background-color: #fff;
}

.bs-slider-item .post-meta .rating, .listing-item-tall-2 .post-meta .rating, .listing-item-text-1 .post-meta .rating, .listing-mg-3-item .post-meta .rating, .listing-mg-4-item .post-meta .rating, .listing-mg-5-item-big .post-meta .rating {
  float: none;
  vertical-align: middle;
  margin-top: 0;
  margin-right: 10px;
}

.listing-item-tall-2 .post-meta .comments, .listing-item-text-1 .post-meta .comments {
  float: none;
  margin-left: 0;
}

.search-container {
  position: relative;
}

.search-container.open.result-results-exist .search-preview {
  display: block;
}

.search-preview {
  display: none;
  border: 1px solid #dedede;
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1);
  font-weight: 400;
  color: #383838;
  line-height: 1;
  z-index: 99;
  text-align: left;
}

.search-preview [data-section-name] {
  max-height: 310px;
}

.search-preview .clean-title {
  font-size: 16px;
  margin-bottom: 15px;
}

.search-preview .listing-item-thumbnail:last-child, .search-preview ul.products {
  margin-bottom: 0;
}

.ajax-search-results {
  background: inherit;
}

.ajax-search-results .search-404 {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}

.ajax-search-results .clean-button {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-results-exist .search-preview {
  border-top: 0;
}

.ajax-search-no-product .ajax-ajax-posts-list, .two-column-results-list {
  padding: 21px;
  position: relative;
}

.two-column-results-list > div {
  width: 50%;
  float: left;
  background: inherit;
  padding-right: 3%;
}

.two-column-results-list > div:last-child {
  padding-right: 0;
}

.two-column-results-list > .ajax-posts-columns {
  width: 45%;
}

.two-column-results-list > .ajax-products-columns {
  width: 55%;
}

.ajax-search-loading {
  display: block;
  text-align: center;
  color: #a6a6a6;
}

.ajax-ajax-posts-list {
  overflow: hidden;
  background: inherit;
  display: table-cell;
}

.ajax-ajax-posts-list .ajax-search-loading, .ajax-ajax-posts-list .align-vertical-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: 0;
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  color: #afafaf;
}

.ajax-taxonomy-list .ajax-search-loading, .ajax-taxonomy-list .align-vertical-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: 0;
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  color: #afafaf;
}

.ajax-ajax-posts-list .ajax-taxonomy-list {
  margin-top: 30px;
}

.ajax-ajax-posts-list .listing-thumbnail {
  margin-bottom: 20px;
  -webkit-transform: scale3d(0.97, 0.97, 0.97);
  transform: scale3d(0.97, 0.97, 0.97);
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bs-fadeInDown;
  animation-name: bs-fadeInDown;
}

.search-preview .bs-listing-products {
  margin-bottom: 28px;
}

.ajax-taxonomy-list {
  background: #fbfbfb;
  border-left: 1px solid #e2e2e2;
  display: table-cell;
  overflow: hidden;
  width: 30%;
  padding: 25px;
}

.ajax-products-columns .add_to_cart_button {
  display: none !important;
}

.ajax-products-columns .price {
  margin-top: 19px;
}

.ajax-products-columns .star-rating + .price {
  margin-top: 0;
}

.ajax-products-columns ul.products li.product {
  margin-bottom: 1px;
}

.search-preview .post-categories {
  margin: 13px 0 0;
  padding: 0;
  list-style: none;
  -webkit-transform: scale3d(0.97, 0.97, 0.97);
  transform: scale3d(0.97, 0.97, 0.97);
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bs-fadeInDown;
  animation-name: bs-fadeInDown;
}

.search-preview .post-categories li {
  display: inline-block;
  margin: 0 0 0 5px;
}

.ajax-search-results {
  display: table-row;
}

.ajax-search-results-wrapper {
  display: table;
  background: inherit;
  width: 100%;
  height: 350px;
}

.ajax-search-results:after {
  content: " ";
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  height: 3px;
}

.ajax-categories-columns {
  background: inherit;
  margin-bottom: 30px;
}

.ajax-tags-columns {
  background: inherit;
}

.ajax-search-no-product .ajax-taxonomy-list > div {
  width: 100%;
  float: none;
  background: inherit;
  height: 152px;
  margin-bottom: 0;
  position: relative;
}

.ajax-search-no-product .ajax-taxonomy-list {
  width: 100%;
}

.ajax-search-no-product .ajax-ajax-posts-list {
  width: 53%;
}

.ajax-search-no-product.ajax-search-fullwidth .ajax-ajax-posts-list {
  width: 69%;
  position: relative;
}

.ajax-search-no-product.ajax-search-fullwidth .two-column-results-list {
  padding: 5px;
}

.ajax-search-no-product.ajax-search-fullwidth .listing-thumbnail {
  margin-bottom: 0;
}

.ajax-posts-column {
  background: inherit;
}

.search-preview .woocommerce ul.products li.product, .search-preview .woocommerce-page ul.products li.product {
  font-size: 14px;
}

.search-preview .woocommerce ul.products li.product h3, .search-preview .woocommerce ul.products li.product .h3 {
  font-size: 1em;
}

.ajax-posts-columns {
  background: inherit;
}

.clean-title {
  position: relative;
  background: inherit;
  margin-bottom: 8px;
}

.clean-title > span {
  position: relative;
  z-index: 30;
  background: inherit;
  display: inline-block;
  padding: 0 15px;
  margin-left: -10px;
}

.clean-title::after {
  position: absolute;
  z-index: 20;
  width: 100%;
  top: 50%;
  height: 1px;
  background: #e5e5e5;
  display: block;
  content: " ";
}

.clean-button {
  border-radius: 0;
  border: 1px solid #e2e2e2;
  background: #f9f9f9;
  color: #383838;
  font-size: 13px;
  font-weight: 600;
  padding: 6px 9px;
  margin: 0 1px 5px;
  display: inline-block;
}

.clean-button-light {
  background: #fff;
}

.clean-button .fa {
  margin-right: 6px;
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 100%;
  }
  .content-wrap {
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .slider-container .content-wrap {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 420px) {
  .widget {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 668px) {
  body {
    padding: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .bs-visible-md {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .bs-visible-sm {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .bs-visible-lg {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .bs-hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .bs-hidden-md {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .bs-hidden-sm, .bs-hidden-xs {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .bs-hidden-lg {
    display: none !important;
  }
}
.ajax-post-content {
  margin-top: 30px;
}

.ajax-post-content .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.ajax-post-content .single-post-title {
  margin: 10px 0 5px;
}

.ajax-post-content .single-featured.single-featured {
  margin-left: 0;
  margin-right: 0;
}

.ajax-post-content .post-meta {
  margin: 0 0 10px;
}

.ajax-post-content .comments-template.comments-template, .ajax-post-content .post-related.post-related, .ajax-post-content .single-container.single-container {
  padding-right: 0;
  padding-left: 0;
}

.ajax-post-content {
  max-width: 856px;
  margin-left: auto;
  margin-right: auto;
}

.ajax-post-content .post-header-inner .single-post-excerpt {
  margin-top: -5px;
  padding-bottom: 10px;
}

.ajax-post-content .post-header-inner .single-post-excerpt:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.ajax-post-content .post-header-inner .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.ajax-post-content .post-header-inner .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

.modal.sign-in-modal {
  text-align: center;
  padding: 0 !important;
}

.modal.sign-in-modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.sign-in-modal .modal-content {
  border: none;
  border-radius: 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.31);
}

.sign-in-modal .modal-header {
  padding: 0;
  border-bottom: 1px solid #efefef;
  line-height: 48px;
  text-align: center;
}

.sign-in-modal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 440px;
  position: relative;
  -webkit-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  -moz-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  -o-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
}

.sign-in-modal .close-modal {
  position: absolute;
  top: 32px;
  right: 37px;
  font-size: 15px;
  color: #bdbdbd;
  line-height: 15px;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  -moz-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  -o-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
}

.sign-in-modal .close-modal:hover {
  color: #333;
}

.sign-in-modal .modal-body {
  padding: 25px 15px;
}

.bs-login {
  max-width: 290px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.bs-login .bs-login-panel {
  display: block;
  width: 100%;
  -webkit-transition: all 0.44s cubic-bezier(0.8, 0.2, 0.24, 0.88);
  -moz-transition: all 0.44s cubic-bezier(0.8, 0.2, 0.24, 0.88);
  -ms-transition: all 0.44s cubic-bezier(0.8, 0.2, 0.24, 0.88);
  transition: all 0.44s cubic-bezier(0.8, 0.2, 0.24, 0.88);
}

.bs-login.inmove .bs-login-panel {
  position: absolute;
  top: 0;
}

.bs-login .bs-login-panel.bs-current-login-panel.bs-login-sign-panel {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.bs-login .bs-login-panel.bs-login-sign-panel {
  left: -44px;
  opacity: 0;
}

.bs-login .bs-login-panel.bs-login-reset-panel {
  right: -44px;
  visibility: hidden;
  position: absolute;
  top: 0;
  opacity: 0;
}

.bs-login .bs-login-panel.bs-current-login-panel.bs-login-reset-panel {
  right: 0;
  opacity: 1;
  visibility: visible;
}

.bs-login .login-header {
  text-align: center;
  color: #7a7a7a;
  font-size: 14px;
  margin-bottom: 15px;
}

.bs-login .login-header:last-child {
  margin-bottom: 0;
}

.bs-login .login-header .login-icon {
  display: block;
  margin-bottom: 10px;
  font-size: 64px;
  line-height: 80px;
}

.bs-login .bs-login-reset-panel .login-header {
  margin-bottom: 27px;
}

.bs-login .bs-login-reset-panel .login-header .login-icon {
  color: #494949;
}

.bs-login .bs-login-reset-panel .login-header p {
  margin-bottom: 4px;
}

.bs-login .login-field {
  margin-bottom: 15px;
}

.bs-login .login-field:last-child {
  margin-bottom: 0;
}

.bs-login input[type=password], .bs-login input[type=text] {
  width: 100%;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
}

.bs-login .remember-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.bs-login .remember-checkbox + .remember-label {
  padding-left: 21px;
  height: 16px;
  display: inline-block;
  line-height: 16px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
  color: #a0a0a0;
  font-weight: lighter;
}

.bs-login .remember-checkbox:checked + .remember-label {
  background-position: 0 -16px;
  color: #424242;
}

.bs-login .remember-label {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAgCAYAAAAbifjMAAAABGdBTUEAALGPC/xhBQAAAVZJREFUSA3tlTFug0AQRQcwMgoFEg2uXbnlCJyF0kdIAU1a38A14hb0Ri6ho0UUpDOFEdrMR4EQgyIIRYp4pZVgdt6fgd2ZleI4ViVJeuPpNk1j0oyhKMq7EOLM83UDWNf1436/f1FVdQZOVNe1mWXZ8Xa7EV2v1/J+v7PYsgEGrIy050YepgcGrDw0/ub5KUD0/Af8D1AYfLYXnyEwYGWugDMXRrVEBL5gwEpry3n1Nv63fpCmKbmuS3mef2375XKZ1cuSJBGWZQkmxeFwEGhpYEe7MHUeENlxHCqKoo3MTZhk+RMdZhCGodA0TZxOpz6rYWREt21blGXZroOlTqCqKsGNsk0RjhDhyGK32/W2IQyFbwIw+L7fO0PEMIz+/RGeFIDR87wegshj2vDpxiiDbmEoMhW584PAhiOMBn8KbbdbiqKIgiAg0/zhylx9tXE6f9sPPgDpK2Td6sBUpAAAAABJRU5ErkJggg==);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bs-login .go-reset-panel {
  float: right;
  color: #595959;
}

.bs-login .login-submit {
  margin-bottom: 10px;
}

.bs-login .login-btn {
  border-radius: 3px;
  height: 38px;
  text-align: center;
  width: 100%;
}

.bs-login .bs-login-reset-panel .login-btn {
  background: #575757 !important;
}

.bs-login .login-signup {
  font-size: 14px;
  color: #a7a7a7;
  text-align: center;
}

.bs-login .go-login-panel {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  color: #adadad;
  transition: all 0.5s ease;
}

.sign-in-modal .bs-login .go-login-panel {
  left: -30px;
}

.bs-login .go-login-panel:hover {
  cursor: pointer;
  position: absolute;
  color: #3f3f3f;
}

.bs-login.bs-type-profile {
  max-width: 290px;
  height: 240px;
}

.bs-login .bs-login-panel.bs-current-login-panel.bs-login-user-panel {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.bs-login .bs-login-user-panel .login-header img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.bs-login .user-links {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}

.bs-login .user-links li {
  border-bottom: 1px solid #dcdcdc;
  height: 38px;
  line-height: 38px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 14px;
}

.bs-login .user-links li:last-child {
  border-bottom: none;
}

.bs-login .user-links .fa {
  margin-right: 5px;
}

.bs-login .social-login-buttons {
  text-align: center;
  padding: 0 0 17px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  margin-bottom: 25px;
}

.bs-login .social-login-buttons .or-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -13px;
  text-align: center;
}

.bs-login .social-login-buttons .or-text {
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #e4e3e3;
  border-radius: 26px;
  font-size: 12px;
  min-width: 28px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  font-weight: bolder;
  color: #9e9e9e;
  text-transform: uppercase;
}

.bs-login .social-login-buttons ul {
  list-style: none;
  padding: 0;
  margin: 0 -4px;
  font-size: 0;
}

.bs-login .social-login-buttons li {
  display: inline-block;
  width: 50%;
  padding: 0 4px 8px;
  margin: 0;
}

.bs-login .social-login-buttons li .social-login-btn.btn {
  width: 100%;
  margin: 0;
}

.bs-login .social-login-buttons ul li:first-child, .bs-login .social-login-buttons ul.items-count-2 li {
  width: 100%;
}

.bs-login .social-login-buttons ul li:first-child .social-login-btn, .bs-login .social-login-buttons ul.items-count-2 li .social-login-btn {
  text-align: center;
}

.social-login-btn.social-login-btn-facebook, .social-login-btn.social-login-btn-facebook:focus, .social-login-btn.social-login-btn-facebook:hover {
  background-color: #49639f !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-google, .social-login-btn.social-login-btn-google:focus, .social-login-btn.social-login-btn-google:hover {
  background-color: #d73d32 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-twitter, .social-login-btn.social-login-btn-twitter:focus, .social-login-btn.social-login-btn-twitter:hover {
  background-color: #59c8ff !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-instagram, .social-login-btn.social-login-btn-instagram:focus, .social-login-btn.social-login-btn-instagram:hover {
  background-color: #517fa4 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-wordpress, .social-login-btn.social-login-btn-wordpress:focus, .social-login-btn.social-login-btn-wordpress:hover {
  background-color: #1a638d !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-yahoo, .social-login-btn.social-login-btn-yahoo:focus, .social-login-btn.social-login-btn-yahoo:hover {
  background-color: #723e98 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-linkedin, .social-login-btn.social-login-btn-linkedin:focus, .social-login-btn.social-login-btn-linkedin:hover {
  background-color: #0c5d98 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-disqus, .social-login-btn.social-login-btn-disqus:focus, .social-login-btn.social-login-btn-disqus:hover {
  background-color: #2e9fff !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-reddit, .social-login-btn.social-login-btn-reddit:focus, .social-login-btn.social-login-btn-reddit:hover {
  background-color: #7ba3bc !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-foursquare, .social-login-btn.social-login-btn-foursquare:focus, .social-login-btn.social-login-btn-foursquare:hover {
  background-color: #3492ce !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-lastfm, .social-login-btn.social-login-btn-lastfm:focus, .social-login-btn.social-login-btn-lastfm:hover {
  background-color: #e02529 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-tumblr, .social-login-btn.social-login-btn-tumblr:focus, .social-login-btn.social-login-btn-tumblr:hover {
  background-color: #354a60 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-goodreads, .social-login-btn.social-login-btn-goodreads:focus, .social-login-btn.social-login-btn-goodreads:hover {
  background-color: #7c460f !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-stackoverflow, .social-login-btn.social-login-btn-stackoverflow:focus, .social-login-btn.social-login-btn-stackoverflow:hover {
  background-color: #f36f21 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-github, .social-login-btn.social-login-btn-github:focus, .social-login-btn.social-login-btn-github:hover {
  background-color: #1b1919 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-dribbble, .social-login-btn.social-login-btn-dribbble:focus, .social-login-btn.social-login-btn-dribbble:hover {
  background-color: #ea4c89 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-500px, .social-login-btn.social-login-btn-500px:focus, .social-login-btn.social-login-btn-500px:hover {
  background-color: #231f20 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-skyrock, .social-login-btn.social-login-btn-skyrock:focus, .social-login-btn.social-login-btn-skyrock:hover {
  background-color: #5597cd !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-mixi, .social-login-btn.social-login-btn-mixi:focus, .social-login-btn.social-login-btn-mixi:hover {
  background-color: #e49100 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-steam, .social-login-btn.social-login-btn-steam:focus, .social-login-btn.social-login-btn-steam:hover {
  background-color: #303030 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-twitchtv, .social-login-btn.social-login-btn-twitchtv:focus, .social-login-btn.social-login-btn-twitchtv:hover {
  background-color: #5a399c !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-vkontakte, .social-login-btn.social-login-btn-vkontakte:focus, .social-login-btn.social-login-btn-vkontakte:hover {
  background-color: #31537d !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-mailru, .social-login-btn.social-login-btn-mailru:focus, .social-login-btn.social-login-btn-mailru:hover {
  background-color: #3881c2 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-yandex, .social-login-btn.social-login-btn-yandex:focus, .social-login-btn.social-login-btn-yandex:hover {
  background-color: #ed1c24 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-odnoklassniki, .social-login-btn.social-login-btn-odnoklassniki:focus, .social-login-btn.social-login-btn-odnoklassniki:hover {
  background-color: #e87502 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-aol, .social-login-btn.social-login-btn-aol:focus, .social-login-btn.social-login-btn-aol:hover {
  background-color: #e69827 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-live, .social-login-btn.social-login-btn-live:focus, .social-login-btn.social-login-btn-live:hover {
  background-color: #7fb0cc !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-pixelpin, .social-login-btn.social-login-btn-pixelpin:focus, .social-login-btn.social-login-btn-pixelpin:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.social-login-btn.btn {
  height: 34px;
  line-height: 34px;
  border-radius: 3px;
  text-transform: capitalize !important;
  padding: 0 14px 0 43px;
  width: auto;
  font-size: 14px;
  position: relative;
  text-align: left;
  overflow: hidden;
  margin: 0 4px 7px 0;
}

.social-login-btn.btn .bf-icon {
  vertical-align: top;
  line-height: 34px;
  margin-right: 10px;
  position: absolute;
  height: 34px;
  width: 34px;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
}

.social-login-btn.btn:hover {
  color: #fff;
}

.wp-social-login-provider-list {
  text-align: center;
}

.bs-login .g-recaptcha {
  margin-bottom: 10px;
  max-width: 100%;
}

.listing-item.better-ads-listitemad.listing-item-classic-1 {
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  border-bottom-color: rgba(0, 0, 0, 0.07);
}

.better-ads-listitemad.listing-item-text-1 {
  padding-bottom: 15px;
}

.bf-breadcrumb .bf-breadcrumb-items {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

.bf-breadcrumb .bf-breadcrumb-item {
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
}

.bf-breadcrumb .bf-breadcrumb-item:after {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  margin-left: 9px;
  margin-right: 9px;
  color: #929292;
  vertical-align: top;
}

.bf-breadcrumb .bf-breadcrumb-item.bf-breadcrumb-end:after, .bf-breadcrumb .bf-breadcrumb-item:last-child:after {
  display: none;
}

.bf-breadcrumb.hide_current .bf-breadcrumb-item.bf-breadcrumb-end {
  opacity: 0;
  visibility: hidden;
}

.bf-breadcrumb .bf-breadcrumb-item span {
  vertical-align: top;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bf-breadcrumb .bf-breadcrumb-item a, .bf-breadcrumb .bf-breadcrumb-item span {
  color: #9e9e9e;
  display: inline-block;
}

.bf-breadcrumb.bc-align-center {
  text-align: center;
}

.bf-breadcrumb.bc-light-color .bf-breadcrumb-item a, .bf-breadcrumb.bc-light-color .bf-breadcrumb-item span, .bf-breadcrumb.bc-light-color .bf-breadcrumb-item:after {
  color: #fff;
}

.bf-breadcrumb.bc-light-color .bf-breadcrumb-item.bf-breadcrumb-end {
  opacity: 0.8;
}

.bf-breadcrumb.bc-hide-last .bf-breadcrumb-item.bf-breadcrumb-end {
  display: none;
}

.bf-breadcrumb.bc-top-style {
  padding: 7px 0 0;
}

@media only screen and (max-width: 680px) {
  body.single .bf-breadcrumb .bf-breadcrumb-item:last-child {
    display: none;
  }
}
body.single .content-column > .bs-newsletter-pack:last-child {
  margin-bottom: 0 !important;
}

.bsnp-empty-note {
  margin-bottom: 30px;
}

.bs-light-scheme, .bs-light-scheme .listing-item-blog-1 > .item-inner > .title, .bs-light-scheme .listing-item-blog-2 > .item-inner > .title, .bs-light-scheme .listing-item-blog-3 > .item-inner > .title, .bs-light-scheme .listing-item-blog-5 > .item-inner > .title, .bs-light-scheme .listing-item-classic-1 .title, .bs-light-scheme .listing-item-classic-2 .title, .bs-light-scheme .listing-item-grid-1 .title, .bs-light-scheme .listing-item-grid-2 .title, .bs-light-scheme .listing-item-tb-1 .title, .bs-light-scheme .listing-item-tb-2 .title, .bs-light-scheme .listing-item-tb-3 .title, .bs-light-scheme .listing-item-text-1 .title, .bs-light-scheme .listing-item-text-2 .title, .bs-light-scheme .listing-item-text-3 .title, .bs-light-scheme .listing-item-text-4 .title, .bs-light-scheme .post-meta, .bs-light-scheme .post-meta a, .bs-light-scheme .post-summary, .bs-light-scheme .section-heading .other-link .h-text, .bs-light-scheme .title, .bs-light-scheme a {
  color: #fff;
}

.bs-light-scheme .post-meta > a, .bs-light-scheme .post-meta > span {
  opacity: 0.6;
}

.bs-light-scheme .post-summary {
  opacity: 0.9;
}

.bs-light-scheme .listing-blog-4 .listing-item-blog.has-post-thumbnail, .bs-light-scheme .listing-classic-1.columns-2 .listing-item-classic-1:last-child .listing-inner, .bs-light-scheme .listing-classic-1.columns-3 .listing-item-classic-1:last-child .listing-inner, .bs-light-scheme .listing-item-classic-1 .listing-inner, .bs-light-scheme .listing-item-grid-2 .post-summary {
  border-bottom-color: rgba(255, 255, 255, 0.129);
}

.bs-light-scheme .listing-item-classic-2 .listing-inner {
  border-color: rgba(255, 255, 255, 0.129);
}

.bs-light-scheme .pagination.bs-numbered-pagination .page-numbers, .bs-light-scheme .pagination.bs-numbered-pagination .page-numbers.dots:hover, .bs-light-scheme .pagination.bs-numbered-pagination .wp-pagenavi a, .bs-light-scheme .pagination.bs-numbered-pagination .wp-pagenavi span, .bs-light-scheme .pagination.bs-numbered-pagination span {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.1);
}

.bs-light-scheme.better-social-counter.style-clean .social-item, .bs-light-scheme.better-social-counter.style-modern .social-item, .bs-light-scheme.better-social-counter.style-style-10 .social-item, .bs-light-scheme.better-social-counter.style-style-6 .social-item, .bs-light-scheme.better-social-counter.style-style-7 .social-item, .bs-light-scheme.better-social-counter.style-style-8 .social-item, .bs-light-scheme.better-social-counter.style-style-9 .social-item {
  border-color: rgba(255, 255, 255, 0.0705882);
}

.bs-light-scheme.better-social-counter.style-style-10 .item-count:before, .bs-light-scheme.better-social-counter.style-style-10 .item-join:before, .bs-light-scheme.better-social-counter.style-style-9 .item-count:before, .bs-light-scheme.better-social-counter.style-style-9 .item-join:before {
  background-color: rgba(255, 255, 255, 0.0705882);
}

.bs-light-scheme.bs-popular-categories .bs-popular-term-item .term-count, .bs-light-scheme.bs-popular-categories .bs-popular-term-item a {
  color: #fff;
}

.bs-light-scheme .section-heading.sh-t2.sh-s2 .h-text, .bs-light-scheme .section-heading.sh-t2.sh-s3 .h-text, .bs-light-scheme .section-heading.sh-t2.sh-s4 .h-text {
  color: #fff;
}

.bs-light-scheme .section-heading.sh-t1.sh-s1:after {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.bs-light-scheme .section-heading.sh-t1.sh-s2:after {
  opacity: 0.15;
}

.bs-light-scheme .section-heading.sh-t1.sh-s3:after, .bs-light-scheme .section-heading.sh-t1.sh-s6:after, .bs-light-scheme .section-heading.sh-t1.sh-s7:after, .bs-light-scheme .section-heading.sh-t4.sh-s3:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAEAQMAAACTPww9AAAABlBMVEUAAAD///+l2Z/dAAAAAXRSTlMAQObYZgAAABBJREFUCNdjEGBQYHBgaAAAAlgA8X2AAy8AAAAASUVORK5CYII=) repeat;
  opacity: 0.3;
}

.bs-light-scheme .section-heading.sh-t1 > .h-text:last-child:first-child, .bs-light-scheme .section-heading.sh-t1 > .main-link > .h-text, .bs-light-scheme .section-heading.sh-t1 > a:last-child:first-child > .h-text {
  color: #fff !important;
}

.bs-light-scheme .section-heading.sh-t1.sh-s5:after, .bs-light-scheme .section-heading.sh-t3.sh-s1:after {
  opacity: 0.15;
}

.section-heading.sh-t1.sh-s8 .h-text {
  padding: 4px 17px 4px 21px;
}

.section-heading.sh-t1.sh-s8 .other-link .h-text {
  padding: 4px 4px 4px 17px !important;
}

.section-heading.sh-t1.sh-s8:first-child:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjQ4NUVFODNGNzc1MTFFN0I4NTdCMTRBMzQ3RTIwNTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjQ4NUVFODRGNzc1MTFFN0I4NTdCMTRBMzQ3RTIwNTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNDg1RUU4MUY3NzUxMUU3Qjg1N0IxNEEzNDdFMjA1NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNDg1RUU4MkY3NzUxMUU3Qjg1N0IxNEEzNDdFMjA1NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pk86A7EAAAASSURBVHjaYmBgYLBgIAgAAgwAB88AOccVbLsAAAAASUVORK5CYII=) repeat;
  opacity: 1;
  height: 10px;
  margin-top: -6px;
  background-color: transparent !important;
}

.section-heading.sh-t1.sh-s8 .main-link .h-text:before, .section-heading.sh-t1.sh-s8 .main-link.h-text:before, .section-heading.sh-t1.sh-s8 > .h-text:first-child:last-child:before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 14px solid rgba(0, 0, 0, 0.06);
  top: 50%;
  margin-top: -9px;
}

.bs-light-scheme .section-heading.sh-t2.sh-s2 {
  background: rgba(255, 255, 255, 0.06);
}

.bs-light-scheme .section-heading.sh-t3.sh-s3:before {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .section-heading.multi-tab.sh-t4 .main-link.active .h-text, .bs-light-scheme .section-heading.multi-tab.sh-t4 .main-link:hover .h-text, .bs-light-scheme .section-heading.sh-t3.sh-s4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s4 > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s4 > a.active > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s4 > a > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s7.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s7 > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s7 > a.active > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s7 > a > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t4 .main-link.active .h-text, .bs-light-scheme .section-heading.sh-t4 .main-link:first-child:last-child .h-text, .bs-light-scheme .section-heading.sh-t4 a.active .h-text, .bs-light-scheme .section-heading.sh-t4 a:hover .h-text, .bs-light-scheme .section-heading.sh-t4 > .h-text, .bs-light-scheme .section-heading.sh-t6 > .h-text:last-child:first-child, .bs-light-scheme .section-heading.sh-t6 > .main-link > .h-text, .bs-light-scheme .section-heading.sh-t6 > a:last-child:first-child > .h-text {
  color: #fff !important;
}

.bs-light-scheme .section-heading.sh-t3.sh-s9.section-heading.sh-t3.sh-s9:before {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .section-heading.sh-t3.sh-s9.section-heading.sh-t3.sh-s9:after {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .section-heading.sh-t3.sh-s5 {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.bs-light-scheme .section-heading.sh-t3.sh-s8 > .h-text:last-child:first-child, .bs-light-scheme .section-heading.sh-t3.sh-s8 > .main-link > .h-text, .bs-light-scheme .section-heading.sh-t3.sh-s8 > a:last-child:first-child > .h-text {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.bs-light-scheme .section-heading.sh-t3.sh-s8:after {
  opacity: 0.15;
  background: #fff;
}

.bs-light-scheme .section-heading.sh-t4.sh-s1:after, .bs-light-scheme .section-heading.sh-t4.sh-s3:after, .bs-light-scheme .section-heading.sh-t6.sh-s6:before, .bs-light-scheme .section-heading.sh-t6.sh-s7:before {
  background: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .section-heading.multi-tab.sh-t4 .bs-pretty-tabs-container .bs-pretty-tabs-elements, .bs-light-scheme .section-heading.multi-tab.sh-t4 .h-text, .bs-light-scheme .section-heading.multi-tab.sh-t4 .main-link .h-text, .bs-light-scheme .section-heading.multi-tab.sh-t4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text {
  border-color: rgba(255, 255, 255, 0.15);
}

.bs-light-scheme .section-heading.sh-t5:before {
  background: rgba(255, 255, 255, 0.1);
}

.bs-light-scheme .section-heading.sh-t6.sh-s9:before {
  background: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .section-heading.sh-t6.sh-s13.section-heading.sh-t6.sh-s13 .h-text {
  color: #000 !important;
}

.bs-light-scheme .section-heading.sh-t7.sh-s1 .h-text {
  color: #fff !important;
}

.bs-light-scheme .section-heading.sh-t7:before {
  background: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .listing-item-user .user-display-name {
  color: #fff;
}

.bs-light-scheme.bs-about .about-icons-list .about-icon-item a {
  color: #fff;
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18);
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18);
}

.bs-light-scheme.bs-popular-categories .bs-popular-term-item {
  border-bottom-color: rgba(226, 226, 226, 0.15);
}

.bs-light-scheme.bs-popular-categories .bs-popular-term-item .term-count {
  background: rgba(255, 255, 255, 0.08);
}

.bs-light-scheme.bs-subscribe-newsletter .powered-by {
  color: #fff;
}

@media print {
  body {
    background: #fff none !important;
    color: #000;
  }
  .back-top, .bs-irp, .bs-pinning-wrapper, .comment-list .comment-footer, .comment-respond, .footer-instagram, .main-menu-wrapper, .more-stories, .next-prev-post, .post-related, .post-tp-4-header .move-to-content, .post-tp-6-header.wfi, .post-tp-8-header, .sidebar-column.sidebar-column-primary, .sidebar-column.sidebar-column-secoundary, .single-container > .post-author .author-links ul, .single-post-share, .single-post-share .share-handler-wrap, .site-footer .footer-social-icons, .site-footer .footer-widgets, body.single .bf-breadcrumb .bf-breadcrumb-item.bf-breadcrumb-end, body.single .bf-breadcrumb .bf-breadcrumb-item:last-child {
    display: none !important;
  }
  .single-post-share .post-share-btn.post-share-btn-comments, .single-post-share .post-share-btn.post-share-btn-views {
    float: none;
  }
  a[href]:after {
    content: none !important;
  }
  .term-badges a {
    max-width: inherit;
  }
  .bs-sks {
    display: none;
  }
  body.active-top-line {
    border-top: none;
  }
  .comment-list .comment-meta .comment-published {
    float: none;
    margin: 0 15px;
  }
  .post-template-6.wfi .post-header-inner, .post-template-7.wfi .post-header-inner, .post-tp-11-header.wfi .post-header-inner, .post-tp-2-header.wfi .post-header-inner, .post-tp-3-header.wfi .post-header-inner, .post-tp-4-header.wfi .post-header-inner, .post-tp-5-header.wfi .post-header-inner, .post-tp-7-header.wfi .post-header-inner {
    height: auto;
    padding: 0;
  }
  .post-template-6.wfi .post-header-inner, .post-tp-11-header.wfi .post-header-title, .post-tp-2-header.wfi .post-header-title, .post-tp-3-header.wfi .post-header-title, .post-tp-4-header .term-badges.floated, .post-tp-4-header.wfi .post-header-title, .post-tp-5-header .term-badges.floated, .post-tp-5-header.wfi .post-header-title, .post-tp-7-header.wfi .post-header-title {
    position: relative !important;
    top: inherit !important;
    left: inherit !important;
    right: inherit !important;
    bottom: inherit !important;
    margin-top: 20px !important;
    opacity: 1 !important;
    transform: none !important;
  }
  .post-template-6.wfi .post-header-inner, .post-template-7.wfi .post-header-inner, .post-tp-4-header .term-badges.floated {
    margin-bottom: 15px;
  }
  .post-tp-7-header.wfi .post-header-title {
    padding: 0;
  }
}
.bs-wrap-gdpr-law {
  display: block;
  color: #212121;
  font-size: 14px;
}

.bs-gdpr-law {
  padding: 8px 15px;
  color: inherit;
  font-size: inherit;
  display: block;
  text-align: center;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  background: #f5f5f5;
}

.bs-gdpr-law p {
  font-size: inherit;
  color: inherit;
  margin: 0;
  display: inline-block;
}

.bs-gdpr-law .bs-gdpr-accept, .bs-gdpr-law .bs-gdpr-more {
  display: inline-block;
  padding: 0;
  line-height: 1;
  font-size: inherit;
  color: inherit;
  margin-left: 10px;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.bs-gdpr-law .bs-gdpr-accept {
  background: #212121;
  color: #fff;
  padding: 6px 15px;
}

.bs-gdpr-law .bs-gdpr-accept:hover, .bs-gdpr-law .bs-gdpr-more:hover {
  opacity: 0.8;
}

.bs-gdpr-law, .bs-wrap-gdpr-law .bs-gdpr-show {
  position: fixed;
  z-index: 19999;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}

.bs-wrap-gdpr-law .bs-gdpr-show {
  display: inline-block;
  width: auto;
  right: 100px;
  left: auto;
  padding: 6px 15px;
  font-size: inherit;
  color: inherit;
  background: #f5f5f5;
  line-height: 1;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}

.bs-wrap-gdpr-law-close {
  z-index: 0;
}

.bs-wrap-gdpr-law .bs-gdpr-show, .bs-wrap-gdpr-law-close .bs-gdpr-law {
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
}

.bs-wrap-gdpr-law-close .bs-gdpr-show {
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

@media screen and (max-width: 768px) {
  .bs-wrap-gdpr-law .bs-gdpr-show {
    display: none !important;
  }
}
.sidebar-column-primary {
  z-index: 1000;
}

.bs-sks {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999;
  pointer-events: none;
}

.bs-sks.bs-sks-type-1 {
  bottom: 0;
}

.bs-sks.bs-sks-type-2 {
  left: calc((100vw - var(--publisher-site-width)) / 2 * -1);
  max-width: initial !important;
  display: inline-table;
  width: calc(100vw - 9px) !important;
  height: 100%;
}

.bs-sksin1, .bs-sksin3 {
  display: inline-table;
  height: 100%;
  width: 100%;
}

.bs-sks.bs-sks-s1 .bs-sksin2 {
  --sks-top: calc(1px - 1px);
  --sks-top-gap: var(--wp-adminbar-height);
  position: sticky;
  top: calc(var(--sks-top) + var(--sks-top-gap));
  transition: top 0.2s ease;
}

body.menu-pined .bs-sks.bs-sks-s1 .bs-sksin2 {
  --sks-top-gap: calc(var(--publisher-menu-height) + var(--wp-adminbar-height)) ;
}

.bs-sks .bs-sks-path {
  overflow: hidden;
}

.bs-sks .bs-sks-path-boxed {
  max-width: var(--publisher-site-width);
  margin-left: auto;
  margin-right: auto;
}

.bs-sks .bs-sksitem > * {
  pointer-events: all;
}

.bs-sks .bs-sksiteml {
  float: left;
  transform: translateX(-100%);
}

.bs-sks.bs-sks-type-2 .bs-sksiteml {
  transform: translateX(calc(-100% + 5px));
}

.bs-sks .bs-sksitemr {
  float: right;
  transform: translateX(100%);
}

.bs-sks.bs-sks-type-2 .bs-sksitemr {
  transform: translateX(calc(100% + 5px));
}

.bs-sks.bs-sks-sticky .bs-sksin2 {
  --sks-top: 0px;
  --sks-top-gap: 0px;
  position: sticky;
  top: calc(var(--sks-top) + var(--sks-top-gap));
  transition: top 0.2s ease;
}

body.admin-bar .bs-sks.bs-sks-sticky .bs-sksin2, body.full-width .bs-sks.bs-sks-sticky.bs-sks-pafter-header .bs-sksin2 {
  --sks-top: 30px ;
}

body.full-width.admin-bar .bs-sks.bs-sks-sticky.bs-sks-pafter-header .bs-sksin2 {
  --sks-top: 60px ;
}

body.full-menu-pined .bs-sks.bs-sks-sticky .bs-sksin2 {
  --sks-top-gap: 30px ;
}

.listing.scolumns-1 .listing-item {
  width: 100%;
  float: none;
}

.listing.scolumns-2 .listing-item {
  width: 50%;
  float: left;
}

.listing.scolumns-2 .listing-item:nth-child(odd) {
  padding-right: 12px;
  clear: left;
}

.listing.scolumns-2 .listing-item:nth-child(even) {
  padding-left: 12px;
}

.listing.scolumns-3 .listing-item {
  width: 33.3333%;
  float: left;
}

.listing.scolumns-3 .listing-item:nth-child(3n-1) {
  padding: 0 6px;
}

.listing.scolumns-3 .listing-item:nth-child(3n-2) {
  padding-right: 12px;
  clear: left;
}

.listing.scolumns-3 .listing-item:nth-child(3n+3) {
  padding-left: 12px;
}

.listing.scolumns-4 .listing-item {
  width: 25%;
  float: left;
}

.listing.scolumns-4 .listing-item:nth-child(4n-3) {
  padding-left: 0;
  padding-right: 14px;
  clear: left;
}

.listing.scolumns-4 .listing-item:nth-child(4n+2) {
  padding-left: 5px;
  padding-right: 9px;
}

.listing.scolumns-4 .listing-item:nth-child(4n+3) {
  padding-left: 9px;
  padding-right: 5px;
}

.listing.scolumns-4 .listing-item:nth-child(4n+4) {
  padding-right: 0;
  padding-left: 14px;
  clear: none;
}

.listing.scolumns-5 .listing-item {
  width: 20%;
  float: left;
}

.listing.scolumns-5 .listing-item:nth-child(5n+1) {
  padding-left: 0;
  padding-right: 12px;
  clear: left;
}

.listing.scolumns-5 .listing-item:nth-child(5n+2) {
  padding-left: 4px;
  padding-right: 8px;
}

.listing.scolumns-5 .listing-item:nth-child(5n+3) {
  padding-left: 6px;
  padding-right: 6px;
}

.listing.scolumns-5 .listing-item:nth-child(5n+4) {
  padding-left: 8px;
  padding-right: 4px;
}

.listing.scolumns-5 .listing-item:nth-child(5n+5) {
  padding-left: 12px;
  padding-right: 0;
}

@media only screen and (max-width: 1000px) {
  .listing.scolumns-5 .listing-item {
    width: 25%;
    float: left;
  }
  .listing.scolumns-5 .listing-item:nth-child(4n-3) {
    padding-left: 0;
    padding-right: 14px;
    clear: left;
  }
  .listing.scolumns-5 .listing-item:nth-child(4n+2) {
    padding-left: 5px;
    padding-right: 9px;
    clear: none;
  }
  .listing.scolumns-5 .listing-item:nth-child(4n+3) {
    padding-left: 9px;
    padding-right: 5px;
  }
  .listing.scolumns-5 .listing-item:nth-child(4n+4) {
    padding-right: 0;
    padding-left: 14px;
    clear: none !important;
  }
}
@media only screen and (max-width: 900px) {
  .listing.scolumns-4 .listing-item, .listing.scolumns-5 .listing-item {
    width: 33.3333%;
    float: left;
  }
  .listing.scolumns-4 .listing-item:nth-child(3n-1), .listing.scolumns-5 .listing-item:nth-child(3n-1) {
    padding: 0 6px;
    clear: none;
  }
  .listing.scolumns-4 .listing-item:nth-child(3n-2), .listing.scolumns-5 .listing-item:nth-child(3n-2) {
    padding-right: 12px;
    padding-left: 0;
    clear: left;
  }
  .listing.scolumns-4 .listing-item:nth-child(3n+3), .listing.scolumns-5 .listing-item:nth-child(3n+3) {
    padding-left: 12px;
    padding-right: 0;
    clear: none;
  }
}
@media only screen and (max-width: 600px) {
  .listing.scolumns-3 .listing-item, .listing.scolumns-4 .listing-item, .listing.scolumns-5 .listing-item {
    width: 50%;
    float: left;
  }
  .listing.scolumns-3 .listing-item:nth-child(odd), .listing.scolumns-4 .listing-item:nth-child(odd), .listing.scolumns-5 .listing-item:nth-child(odd) {
    padding-left: 0;
    padding-right: 12px;
    clear: left;
  }
  .listing.scolumns-3 .listing-item:nth-child(even), .listing.scolumns-4 .listing-item:nth-child(even), .listing.scolumns-5 .listing-item:nth-child(even) {
    padding-right: 0;
    padding-left: 12px;
    clear: none;
  }
}
@media only screen and (max-width: 320px) {
  .listing.scolumns-2 .listing-item, .listing.scolumns-3 .listing-item, .listing.scolumns-4 .listing-item, .listing.scolumns-5 .listing-item {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    clear: none !important;
  }
}
.post-count-badge {
  pointer-events: none;
  display: inline-block;
}

.post-count-badge.pcb-t1 {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px;
  font-size: 12px;
  text-align: center;
  font-weight: bolder;
  box-shadow: 0 0 0 2px #fff;
}

.post-count-badge.pcb-t1.pcb-s1 {
  color: #fff;
  background: #2a2a2a;
}

.post-count-badge.pcb-t1.pcb-s2 {
  color: #000;
  background: rgba(0, 0, 0, 0.06);
  box-shadow: none;
}

.post-count-badge.pcb-t2 {
  line-height: 1;
  font-size: 90px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.045);
  font-family: arial;
}

.post-count-badge.pcb-t2.pcb-s2 {
  font-size: 42px;
}

.listing-item-classic .post-subtitle {
  margin-bottom: 7px;
}

.listing-item-classic-1 {
  margin-bottom: 22px;
}

.listing-classic-1.columns-2 .listing-item-classic-1:last-child .listing-inner, .listing-classic-1.columns-3 .listing-item-classic-1:last-child .listing-inner, .listing-item-classic-1 .listing-inner {
  padding-bottom: 22px;
  border-bottom: 1px solid #f1f1f1;
}

.listing-item-classic-1.has-not-post-thumbnail .listing-inner {
  padding-bottom: 28px;
}

.listing-item-classic-1:last-child {
  margin-bottom: 0;
}

.listing-item-classic-1:last-child .listing-inner {
  padding-bottom: 0;
  border-bottom: none;
}

.listing-item-classic-1 .featured {
  position: relative;
  line-height: 0;
  margin-bottom: 17px;
}

.listing-item-classic-1 .featured iframe {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.listing-item-classic-1 .featured .img-holder {
  width: 100%;
  margin: 0;
  float: none;
}

.listing-item-classic-1 .featured .img-holder:before {
  padding-top: 50%;
}

.listing-item-classic-1 .title {
  margin: 0 0 6px;
}

.listing-item-classic-1 .title a {
  color: inherit;
}

.listing-item-classic-1 .post-meta {
  margin: 0 0 9px;
}

.listing-item-classic.bsw-1 .title, .listing-item-classic.bsw-2 .title, .listing-item-classic.bsw-3 .title, .listing-item-classic.bsw-4 .title {
  line-height: 1.3;
}

.listing-item-classic.bsw-1 .title .post-title, .listing-item-classic.bsw-2 .title .post-title, .listing-item-classic.bsw-3 .title .post-title, .listing-item-classic.bsw-4 .title .post-title {
  font-size: 88%;
}

@media only screen and (max-width: 400px) {
  .listing-item-classic .title {
    line-height: 1.3 !important;
  }
  .listing-item-classic .title .post-title {
    font-size: 88% !important;
  }
}
.listing.columns-1 > .listing-item-classic-2:last-child, .listing.columns-3 > .listing-item-classic-2:last-child {
  margin-bottom: 25px;
}

.listing-item-classic-2, .listing.columns-2 > .listing-item-classic-2:last-child {
  margin-bottom: 30px;
}

.listing .listing-classic-2 > .listing-item-classic-2:last-child, .listing-item-classic-2:last-child {
  margin-bottom: 0;
}

.listing-item-classic-2 .listing-inner {
  padding: 0 20px 20px 20px;
  border: 1px solid #e6e6e6;
}

.listing-item-classic-2.has-not-post-thumbnail .listing-inner {
  padding-top: 20px;
}

.listing-item-classic-2 .featured {
  position: relative;
  line-height: 0;
  margin: -1px -21px 17px;
}

.listing-item-classic-2 .featured iframe {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.listing-item-classic-2 .featured .img-holder {
  width: 100%;
  margin: 0;
  float: none;
}

.listing-item-classic-2 .featured .img-holder:before {
  padding-top: 56%;
}

.listing-item-classic-2 .title {
  margin: 0 0 6px;
}

.listing-item-classic-2 .title a {
  color: inherit;
}

.listing-item-classic-2 .post-meta {
  margin: 0 0 9px;
}

.listing-classic-3.columns-2 .listing-item-classic-3:last-child, .listing-classic-3.columns-3 .listing-item-classic-3:last-child, .listing-item-classic-3 {
  margin-bottom: 25px;
}

.listing-item-classic-3 .listing-inner {
  padding: 0 25px 25px;
  border-bottom: 1px solid #f1f1f1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-left: 4%;
  margin-right: 4%;
  position: relative;
  z-index: 1;
  margin-top: -15px;
}

.listing-item-classic-3:last-child, .listing-item-classic-3:last-child .listing-inner {
  margin-bottom: 0;
  border-bottom: none;
}

.listing-classic-3.columns-2 .listing-item-classic-3:last-child .listing-inner, .listing-classic-3.columns-3 .listing-item-classic-3:last-child .listing-inner {
  border-bottom: 1px solid #f1f1f1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.listing-item-classic-3 .featured {
  position: relative;
  line-height: 0;
}

.listing-item-classic-3 .featured iframe {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.listing-item-classic-3 .featured .img-holder {
  width: 100%;
  margin: 0;
  float: none;
}

.listing-item-classic-3 .featured .img-holder:before {
  padding-top: 55%;
}

.listing-item-classic-3 .title {
  margin: 40px 0 6px;
}

.listing-item-classic-3 .featured .title {
  margin: 0 4%;
  background: #fff;
  position: absolute;
  bottom: -2px;
  padding: 26px 25px 25px;
  width: 92%;
}

.listing-item-classic-3 .title a {
  color: inherit;
}

.listing-item-classic-3 .title h2, .listing-item-classic-3 .title .h2 {
  margin: 0;
}

.listing-item-classic-3 .post-meta {
  margin: 0 0 9px;
}

.listing-item-classic-3.has-not-post-thumbnail .listing-inner {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.listing-item-classic-3.has-not-post-thumbnail .title {
  margin-top: 20px;
}

.listing-item-classic-3.bsw-1 .featured .img-holder:before, .listing-item-classic-3.bsw-2 .featured .img-holder:before, .listing-item-classic-3.bsw-3 .featured .img-holder:before, .listing-item-classic-3.bsw-4 .featured .img-holder:before {
  padding-top: 65%;
}

.listing-item-classic-3.bsw-1 .featured .title, .listing-item-classic-3.bsw-2 .featured .title, .listing-item-classic-3.bsw-3 .featured .title {
  margin: 15px 0 10px;
  padding: 0;
  background: #fff;
  position: relative;
}

.listing-item-classic-3.bsw-1 .listing-inner, .listing-item-classic-3.bsw-2 .listing-inner, .listing-item-classic-3.bsw-3 .listing-inner {
  padding: 0 0 15px;
  margin: 0;
}

@media only screen and (max-width: 380px) {
  .listing-item-classic-3 .featured .img-holder:before {
    padding-top: 65% !important;
  }
  .listing-item-classic-3 .featured .title {
    margin: 15px 0 10px !important;
    padding: 0 !important;
    background: #fff !important;
    position: relative !important;
    bottom: inherit !important;
  }
  .listing-item-classic-3 .listing-inner {
    padding: 0 0 15px !important;
    margin: 0 !important;
  }
}
.listing-item-grid-1 {
  margin-bottom: 22px;
}

.listing-item-grid-1 .featured {
  position: relative;
  line-height: 0;
  margin-bottom: 13px;
}

.listing-item-grid-1 .featured iframe {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.listing-item-grid-1 .featured .img-holder {
  width: 100%;
  margin: 0;
  float: none;
  display: block;
}

.listing-item-grid-1 .featured .img-holder:before {
  padding-top: 58%;
}

.listing-item-grid-1:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.listing-item-grid-1 .title {
  margin: 0 0 6px;
}

.listing-item-grid-1 .title a {
  color: inherit;
  text-transform: inherit;
}

.listing-item-grid-1 .post-meta {
  margin: 0 0 7px;
}

.listing-item-grid-1 .post-summary {
  padding-bottom: 22px;
  border-bottom: 1px solid #f1f1f1;
  border-bottom-color: rgba(0, 0, 0, 0.07);
}

.listing-item-grid-1 .post-subtitle {
  margin-bottom: 6px;
}

.listing-grid.columns-1 .listing-item-grid-1:last-child .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing-item-grid.simple-grid .featured {
  margin-bottom: 7px;
}

.listing-item-grid.simple-grid .featured .img-holder:before {
  padding-top: 66%;
}

.listing-item-grid-2 {
  margin-bottom: 22px;
}

.listing-item-grid-2 .featured {
  position: relative;
  line-height: 0;
  margin-bottom: 13px;
}

.listing-item-grid-2 .featured iframe {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.listing-item-grid-2 .featured .img-holder {
  width: 100%;
  margin: 0;
  float: none;
  display: block;
}

.listing-item-grid-2 .featured .img-holder:before {
  padding-top: 58%;
}

.listing-item-grid-2:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.listing-item-grid-2 .title {
  margin: 0 0 6px;
}

.listing-item-grid-2 .title a {
  color: inherit;
  text-transform: inherit;
}

.listing-item-grid-2 .post-meta {
  margin: 0 0 15px;
}

.listing-item-grid-2 .post-summary {
  padding-bottom: 22px;
  border-bottom: 1px solid #f1f1f1;
}

.listing-item-grid-2 .post-subtitle {
  margin-bottom: 6px;
}

.listing-item-blog .format-icon {
  right: 15px;
  top: 15px;
  width: 28px;
  height: 28px;
  line-height: 25px;
  font-size: 13px;
}

.listing-item-blog .format-icon.format-audio {
  padding-right: 3px;
}

.listing-item-blog .format-icon.format-video {
  padding-left: 3px;
}

.listing-item-blog .format-icon .fa {
  line-height: 25px;
}

.listing-item-blog .featured:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.listing-item-blog-1, .listing-item-blog-5 {
  margin-bottom: 25px;
}

.listing-item-blog-1:last-child, .listing-item-blog-5:last-child {
  margin-bottom: 0;
}

.listing-item-blog-1 .featured, .listing-item-blog-5 .featured {
  position: relative;
  float: left;
  margin-right: 26px;
  line-height: 0;
}

.listing-item-blog-1 .featured .img-holder, .listing-item-blog-5 .featured .img-holder {
  width: 210px;
  margin: 0;
}

.listing-item-blog-1 .featured .img-holder.img-holder:before, .listing-item-blog-5 .featured .img-holder.img-holder:before {
  padding-top: 64%;
}

.listing-item-blog-1 > .item-inner > .title, .listing-item-blog-5 > .item-inner > .title {
  margin: 0 0 6px;
}

.listing-item-blog-1 > .item-inner > .title a, .listing-item-blog-5 > .item-inner > .title a {
  color: inherit;
}

.listing-item-blog-1 .post-summary, .listing-item-blog-5 .post-summary {
  margin-top: 6px;
}

.listing-item-blog-1.has-post-thumbnail .post-summary {
  padding-left: 237px;
}

.listing-item-blog-5.has-post-thumbnail .post-summary {
  padding-left: 326px;
}

.listing-item-blog-5 .post-summary p + .read-more {
  margin-top: 0;
}

.listing-item-blog-5 .featured .img-holder {
  width: 300px;
  margin: 0;
}

.listing-item-blog.has-not-post-thumbnail:first-child {
  padding-top: 0;
  border-top: none;
}

.listing-item-blog + .listing-item-blog.has-not-post-thumbnail, .listing-item-blog.has-not-post-thumbnail + .listing-item-blog {
  padding-top: 18px;
  border-top: 1px solid #f1f3f5;
}

.listing-item-blog > .item-inner > .post-subtitle {
  margin-bottom: 5px;
}

.listing-item-blog > .item-inner > .post-meta + .post-subtitle {
  margin-top: 5px;
}

.listing-item-blog-5.bsw-1 .img-holder, .listing-item-blog-5.bsw-2 .img-holder, .listing-item-blog-5.bsw-3 .img-holder, .listing-item-blog-5.bsw-4 .img-holder, .listing-item-blog-5.bsw-5 .img-holder {
  width: 220px;
}

.listing-item-blog-5.has-post-thumbnail.bsw-1 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-2 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-3 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-4 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-5 .post-summary {
  padding-left: 243px;
}

.listing-item-blog-1.bsw-1 .featured, .listing-item-blog-1.bsw-2 .featured, .listing-item-blog-1.bsw-3 .featured, .listing-item-blog-1.bsw-4 .featured, .listing-item-blog-2.bsw-1 .featured, .listing-item-blog-2.bsw-2 .featured, .listing-item-blog-2.bsw-3 .featured, .listing-item-blog-2.bsw-4 .featured, .listing-item-blog-5.bsw-1 .featured, .listing-item-blog-5.bsw-2 .featured, .listing-item-blog-5.bsw-3 .featured, .listing-item-blog-5.bsw-4 .featured {
  float: none;
  text-align: center;
  margin: 0 0 10px 0;
  display: inline-block;
}

.listing-item-blog-5.bsw-1 .featured, .listing-item-blog-5.bsw-1 .img-holder, .listing-item-blog-5.bsw-2 .featured, .listing-item-blog-5.bsw-2 .img-holder, .listing-item-blog-5.bsw-3 .featured, .listing-item-blog-5.bsw-3 .img-holder, .listing-item-blog-5.bsw-4 .featured, .listing-item-blog-5.bsw-4 .img-holder {
  width: 100%;
}

.listing-item-blog-2.has-post-thumbnail.bsw-1 .post-summary, .listing-item-blog-2.has-post-thumbnail.bsw-2 .post-summary, .listing-item-blog-2.has-post-thumbnail.bsw-3 .post-summary, .listing-item-blog-2.has-post-thumbnail.bsw-4 .post-summary {
  padding-right: 0;
}

.listing-item-blog-1.has-post-thumbnail.bsw-1 .post-summary, .listing-item-blog-1.has-post-thumbnail.bsw-2 .post-summary, .listing-item-blog-1.has-post-thumbnail.bsw-3 .post-summary, .listing-item-blog-1.has-post-thumbnail.bsw-4 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-1 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-2 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-3 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-4 .post-summary {
  padding-left: 0;
}

@media only screen and (max-width: 830px) {
  .listing-item-blog-1.has-post-thumbnail .post-summary, .listing-item-blog-5.has-post-thumbnail .post-summary {
    padding-left: 0 !important;
  }
  .listing-item-blog-1 .featured, .listing-item-blog-2 .featured, .listing-item-blog-5 .featured {
    float: none !important;
    text-align: center !important;
    margin: 0 0 10px 0 !important;
    display: inline-block !important;
  }
  .listing-item-blog-5 .featured, .listing-item-blog-5 .img-holder {
    width: 100% !important;
  }
}
.listing-item-blog-2 {
  margin-bottom: 25px;
}

.listing-item-blog-2:last-child {
  margin-bottom: 0;
}

.listing-item-blog-2 .featured {
  position: relative;
  float: right;
  margin-left: 23px;
  line-height: 0;
}

.listing-item-blog-2 .featured .img-holder {
  width: 210px;
  margin: 0;
}

.listing-item-blog-2 .featured .img-holder.img-holder:before {
  padding-top: 64%;
}

.listing-item-blog-2 > .item-inner > .title {
  margin: 0 0 6px;
}

.listing-item-blog-2 > .item-inner > .title a {
  color: inherit;
}

.listing-item-blog-2 .post-summary {
  margin-top: 6px;
}

.listing-item-blog-2.has-post-thumbnail .post-summary {
  padding-right: 233px;
}

@media only screen and (max-width: 420px) {
  .listing-item-blog-2.has-post-thumbnail .post-summary {
    padding-right: 0 !important;
  }
  .listing-item-blog-2 .featured {
    float: none;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
.listing-item-blog-3 {
  margin-bottom: 25px;
}

.listing-item-blog-3:last-child {
  margin-bottom: 0;
}

.listing-item-blog-3 .featured {
  position: relative;
  float: left;
  margin-right: 23px;
  line-height: 0;
}

.listing-item-blog-3 .featured .img-holder {
  width: 210px;
  margin: 0;
}

.listing-item-blog-3.has-post-thumbnail .post-summary {
  padding-left: 233px;
}

.listing-item-blog-3 .featured .img-holder.img-holder:before {
  padding-top: 64%;
}

.listing-item-blog-3 > .item-inner > .title {
  margin: 7px 0 3px;
}

.listing-item-blog-3 > .item-inner > .post-meta {
  margin-bottom: 10px;
}

.listing-item-blog-3 > .item-inner > .title a {
  color: inherit;
}

.listing-item-blog-3.bsw-1 .featured, .listing-item-blog-3.bsw-2 .featured, .listing-item-blog-3.bsw-3 .featured, .listing-item-blog-3.bsw-4 .featured {
  float: none;
  text-align: center;
  margin: 0 0 10px 0;
}

.listing-item-blog-3.bsw-1 .featured, .listing-item-blog-3.bsw-2 .featured, .listing-item-blog-3.bsw-3 .featured, .listing-item-blog-3.bsw-4 .featured {
  float: none;
  text-align: center;
  margin: 0 0 10px 0;
  display: inline-block;
}

.listing-item-blog-3.has-post-thumbnail.bsw-1 .post-summary, .listing-item-blog-3.has-post-thumbnail.bsw-2 .post-summary, .listing-item-blog-3.has-post-thumbnail.bsw-3 .post-summary, .listing-item-blog-3.has-post-thumbnail.bsw-4 .post-summary {
  padding-left: 0;
}

@media only screen and (max-width: 420px) {
  .listing-item-blog-3 .featured {
    float: none !important;
    text-align: center !important;
    margin: 0 0 10px 0 !important;
  }
  .listing-item-blog-3 .featured {
    float: none !important;
    text-align: center !important;
    margin: 0 0 10px 0 !important;
    display: inline-block !important;
  }
  .listing-item-blog-3.has-post-thumbnail .post-summary {
    padding-left: 0 !important;
  }
}
.listing-blog-4 .listing-item-blog.has-post-thumbnail {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}

.listing-blog-4 .listing-item-blog.has-post-thumbnail:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.listing-item-blog-5 .post-subtitle {
  margin-bottom: 7px;
}

.listing-item-tall .format-icon {
  right: 15px;
  top: 15px;
  width: 28px;
  height: 28px;
  line-height: 25px;
  font-size: 12px;
}

.listing-item-tall .format-icon .fa {
  line-height: 25px;
}

.listing-item-tall:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.listing-item-tall .post-subtitle {
  margin-bottom: 5px;
}

.listing-item-tall .featured + .post-subtitle {
  margin: 8px 0 -8px;
}

.listing-item-tall .post-meta + .post-subtitle {
  margin-top: 8px;
}

.listing-item-tall-1 {
  margin-bottom: 23px;
}

.listing-item-tall-1:last-child {
  margin-bottom: 0;
}

.listing-item-tall-1 .featured {
  position: relative;
}

.bs-ll-a .listing-item-tall-1 .featured .img-holder.b-loaded, .bs-ll-d .listing-item-tall-1 .featured .img-holder, .listing-item-tall-1 .featured .img-holder {
  width: 100%;
  margin: 0;
  background-position: top center;
}

.bs-ll-a .listing-item-tall-1 .featured .img-holder {
  background-position: center center;
}

.listing-item-tall-1 .featured .img-holder.img-holder:before {
  padding-top: 134%;
}

.listing-item-tall-1 > .title {
  margin: 15px 0 9px;
}

.listing-item-tall-1 > .title a {
  color: inherit;
}

.listing-item-tall-1 .post-summary {
  margin-top: 10px;
}

.listing-item-tall-2 {
  margin-bottom: 23px;
}

.bs-slider-item .listing-item-tall-2 {
  margin-bottom: 0 !important;
}

.listing-item-tall-2:last-child {
  margin-bottom: 0;
}

.listing-item-tall-2 .featured {
  position: relative;
}

.bs-ll-a .listing-item-tall-2 .featured .img-holder.b-loaded, .bs-ll-d .listing-item-tall-2 .featured .img-holder, .listing-item-tall-2 .featured .img-holder {
  width: 100%;
  margin: 0;
  background-position: top center;
}

.bs-ll-a .listing-item-tall-2 .featured .img-holder {
  background-position: center center;
}

.listing-item-tall-2 .featured .img-holder.img-holder:before {
  padding-top: 134%;
}

.listing-item-tall-2 .term-badges.floated {
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: none;
}

.listing-item-tall-2 .term-badges.floated a {
  pointer-events: all;
}

.listing-item-tall-2 > .title {
  margin: 15px 0 9px;
}

.listing-item-tall-2 > .title a {
  color: inherit;
}

.listing-item-tall-2 .post-meta {
  text-align: center;
}

.listing-item-tall-2 .post-summary {
  text-align: center;
  margin-top: 10px;
}

.listing-item-tall-2 .post-subtitle {
  text-align: center !important;
}

.listing-item-tb-1 {
  margin-bottom: 22px;
  position: relative;
}

.listing-item-tb-1:last-child, .listing-mix-3-1 .listing-tb-1 > .listing-item-tb-1:last-child {
  margin-bottom: 0;
}

.listing-item-tb-1 .title {
  margin: 0;
}

.listing-item-tb-1 .title a {
  color: inherit;
}

.listing-item-tb-1 .featured {
  margin-right: 13px;
  float: left;
  position: relative;
  line-height: 0;
}

.listing-item-tb-1 .img-holder {
  width: 86px;
  margin-right: 0;
  float: none;
}

.listing-item-tb-1 .img-holder:before {
  padding-top: 74%;
}

.listing-item-tb-1 .featured.featured-type-author-avatar .img-holder:before {
  padding-top: 100%;
}

.listing-item-tb-1 .post-subtitle {
  margin-top: 4px;
}

.listing-item-tb-1 .featured + .post-subtitle {
  margin: 0;
}

.bs-listing.bs-listing-listing-thumbnail-1 .bs-pagination {
  margin-top: 15px;
}

.listing-item-tb-1.has-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 1;
}

.listing-item-tb-1.has-not-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  margin-bottom: 5px;
}

.listing-item-tb-2 {
  margin-bottom: 20px;
  position: relative;
}

.listing-item-tb-2 .term-badges.floated {
  left: 0;
}

.listing-item-tb-2 .item-inner {
  position: relative;
}

.listing-item-tb-2 .title {
  margin: 0;
}

.listing-item-tb-2 .title a {
  color: inherit;
}

.listing-item-tb-2 .post-meta {
  margin-top: 5px;
}

.listing-item-tb-2 .post-summary {
  margin-top: 5px;
}

.listing-item-tb-2 .featured {
  margin-bottom: 10px;
  line-height: 0;
  position: relative;
}

.listing-item-tb-2 .format-icon {
  right: 15px;
  top: 15px;
  width: 28px;
  height: 28px;
  line-height: 24px;
  font-size: 12px;
}

.listing-item-tb-2 .format-icon .fa {
  line-height: 24px;
}

.listing-item-tb-2:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform: scale(1.5);
}

.listing-item-tb-2 .img-holder {
  width: 100%;
  margin-right: 0;
  float: none;
  display: block;
}

.listing-item-tb-2 .img-holder:before {
  padding-top: 70%;
}

.listing-item-tb-2 .featured.featured-type-author-avatar .img-holder:before {
  padding-top: 100%;
}

.listing-item-tb-2 .post-subtitle {
  margin-bottom: 5px;
}

.listing-item-tb-2 .title + .post-subtitle {
  margin: 5px 0 0;
}

.listing-item-tb-2.has-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  position: absolute;
  bottom: -14px;
  left: 6px;
  z-index: 1;
}

.listing-item-tb-2.has-post-thumbnail.has-counter-badge .featured {
  margin-bottom: 22px;
}

.listing-item-tb-2.has-not-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  margin-bottom: 10px;
}

.listing-item-tb-3 {
  margin-bottom: 20px;
  position: relative;
}

.listing-item-tb-3:last-child {
  margin-bottom: 0;
}

.listing-tb-3 > .listing-item-tb-3:last-child {
  margin-bottom: 20px;
}

.listing-item-tb-3 .title {
  margin: 0;
}

.listing-item-tb-3 .title a {
  color: inherit;
}

.listing-item-tb-3 .featured {
  margin-left: 15px;
  float: right;
  position: relative;
  line-height: 0;
}

.listing-item-tb-3 .img-holder {
  width: 86px;
  margin-right: 0;
  float: none;
}

.listing-item-tb-3 .img-holder:before {
  padding-top: 74%;
}

.listing-item-tb-3 .featured.featured-type-author-avatar .img-holder:before {
  padding-top: 100%;
}

.listing-item-tb-3 .post-subtitle {
  margin-top: 4px;
}

.listing-item-tb-3 .featured + .post-subtitle {
  margin: 0;
}

.listing-item-tb-3.has-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}

.listing-item-tb-3.has-not-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  margin-bottom: 5px;
}

.listing-item-text-1 {
  position: relative;
  margin-bottom: 15px;
}

.listing-item-text-1:last-child {
  margin-bottom: 0;
}

.listing-item-text-1 .item-inner {
  padding-bottom: 15px;
  border-bottom: 1px dashed #eaeaea;
  border-bottom-color: rgba(0, 0, 0, 0.08);
}

.listing-item-text-1 .term-badges.floated {
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-align: center;
  margin-bottom: 12px;
}

.listing-item-text-1 .term-badges.floated .term-badge a {
  color: #fff !important;
  margin: 0 2px;
}

.listing-item-text-1 .title {
  margin: 0;
}

.listing-item-text-1.no-meta .title {
  margin-bottom: 10px;
}

.listing-item-text-1 .title a {
  color: inherit;
}

.listing-item-text-1 .post-meta {
  padding-top: 6px;
  text-align: center;
}

.listing-item-text-1 .post-summary {
  padding-top: 4px;
  text-align: center;
}

.listing-text-1.listing.columns-1 .listing-item:last-child {
  margin-bottom: 0;
}

.listing-text-1.listing.columns-1 .listing-item:last-child .item-inner {
  border-bottom: none;
}

.listing-item-text-1 .post-subtitle {
  text-align: center !important;
  margin: 5px 0 0;
}

.listing-item-text-1 .post-subtitle + .post-meta {
  padding-top: 5px;
}

.listing-item-text-1 .post-subtitle + .title {
  margin-top: 6px;
}

.listing-item-text-1 .post-subtitle:first-child {
  margin-top: 0;
}

.listing-item-text-1 .post-count-badge.pcb-t2.pcb-s1 {
  position: absolute;
  top: 0;
  left: 0;
}

.listing-item-text-2 {
  position: relative;
  margin-bottom: 15px;
}

.listing-item-text-2:last-child {
  margin-bottom: 0;
}

.listing-item-text-2 .item-inner {
  border-left: 3px solid rgba(0, 0, 0, 0.11);
  -webkit-transition: 0.7s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -moz-transition: 0.7s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -o-transition: 0.7s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  padding: 6px 0 6px 14px;
}

.listing-item-text-2 .term-badges.floated {
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-align: center;
  margin-bottom: 10px;
}

.listing-item-text-2 .term-badges.floated .term-badge a {
  background: #434343 !important;
  color: #fff !important;
  margin: 0;
}

.listing-item-text-2 .title {
  margin: 0;
}

.listing-item-text-2 .title a {
  color: inherit;
}

.listing-item-text-2 .post-meta, .listing-item-text-2 .post-meta .comments, .listing-item-text-2 .post-meta .post-author {
  font-size: 11px;
}

.listing-text-2.listing.columns-1 .listing-item:last-child {
  margin-bottom: 0;
}

.listing-text-2.listing.columns-1 .listing-item:last-child .item-inner {
  border-bottom: none;
}

.listing-item-text-2 .post-subtitle {
  margin-top: 4px;
}

.listing-item-text-2 .post-subtitle:first-child {
  margin-top: 0;
}

.listing-item-text-2 .post-subtitle + .title {
  margin-top: 4px;
}

.listing-item-text-2 .post-summary {
  padding-top: 6px;
}

.listing-item-text-2 .post-count-badge.pcb-t2.pcb-s2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.listing-item-text-3 {
  margin-bottom: 13px;
}

.listing-item-text-3 .item-inner {
  padding-bottom: 13px !important;
  border-bottom: 1px solid #f0f0f0;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  position: relative;
}

.listing-item-text-3:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.listing-item-text-3 .title {
  margin: 0;
}

.listing-item-text-3 .title a {
  color: inherit;
}

.listing-item-text-3 .post-meta {
  margin-top: 2px;
}

.listing-item-text-3 .post-summary {
  margin-top: 4px;
}

.content-column > .listing-text-3 .listing-item-text-3 {
  margin-bottom: 17px;
  padding-bottom: 17px !important;
}

.listing-item-text-3 .post-subtitle {
  margin-top: 4px;
}

.listing-item-text-3 .post-subtitle:first-child {
  margin-top: 0;
}

.listing-item-text-3 .post-subtitle + .title {
  margin-top: 4px;
}

.listing-item-text-3.has-counter-badge .item-inner {
  padding-left: 43px;
}

.listing-item-text-3.has-counter-badge .post-count-badge.pcb-t1.pcb-s2 {
  position: absolute;
  top: 3px;
  left: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
}

.listing-item-text-4 {
  margin-bottom: 13px;
}

.listing-item-text-4 .item-inner {
  padding-bottom: 13px !important;
  border-bottom: 1px solid #f0f0f0;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  position: relative;
}

.listing-item-text-4:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.listing-text-4.columns-1 .listing-item-text-4:last-child {
  margin-bottom: 0;
}

.listing-text-4.columns-1 .listing-item-text-4:last-child .item-inner {
  border-bottom: none;
  padding-bottom: 0;
}

.listing-item-text-4 .title {
  margin: 0;
}

.listing-item-text-4 .title a {
  color: inherit;
}

.listing-item-text-4 .post-meta {
  margin-top: 2px;
}

.listing-item-text-4 .post-summary {
  margin-top: 4px;
}

.listing-item-text-4 .term-badges.text-badges {
  margin-bottom: 6px;
}

.content-column > .listing-text-4 .listing-item-text-4 {
  margin-bottom: 17px;
  padding-bottom: 17px !important;
}

.listing-item-text-4 .post-subtitle {
  margin-top: 4px;
}

.listing-item-text-4 .post-subtitle:first-child {
  margin-top: 0;
}

.listing-item-text-4 .post-subtitle + .title {
  margin-top: 4px;
}

.listing-item-text-4.has-counter-badge .item-inner {
  padding-left: 43px;
}

.listing-item-text-4.has-counter-badge .post-count-badge.pcb-t1.pcb-s2 {
  position: absolute;
  top: 5px;
  left: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
}

.bs-listing p:empty {
  display: none;
}

.listing-mix-1-1 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing-mix-1-1 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing-mix-1-1 .listing-item-tb-1 {
  margin-bottom: 27px;
}

.listing-mix-1-1 .listing-item-tb-1:last-child {
  margin-bottom: 0;
}

.listing-mix-1-1 .column-1 {
  width: 50%;
  float: left;
  padding-right: 18px;
}

.listing-mix-1-1 .column-2 {
  width: 50%;
  float: left;
  padding-left: 18px;
}

.listing.listing-mix-1-1.l-1-col .column-1, .listing.listing-mix-1-1.l-1-col .column-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing.listing-mix-1-1.l-1-col .column-1 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 450px) {
  .listing.listing-mix-1-1 .column-1, .listing.listing-mix-1-1 .column-2 {
    width: 100%;
    padding: 0;
    float: none;
  }
  .listing.listing-mix-1-1 .column-1 {
    margin-bottom: 24px;
  }
}
.listing.listing-mix-1-2 .listing-item-grid-1 {
  margin-bottom: 30px;
}

.listing.listing-mix-1-2 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing.listing-mix-1-2 .listing-item-tb-1 {
  margin-bottom: 7px;
}

.listing.listing-mix-1-2 .column-1 {
  width: 50%;
  float: left;
  padding-right: 18px;
}

.listing.listing-mix-1-2 .column-2 {
  width: 50%;
  float: left;
  padding-left: 18px;
}

.bs-listing-listing-mix-1-2 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.listing.listing-mix-1-2.l-1-col .column-1, .listing.listing-mix-1-2.l-1-col .column-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing.listing-mix-1-2.l-1-col .column-1 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 450px) {
  .listing.listing-mix-1-2 .column-1, .listing.listing-mix-1-2 .column-2 {
    width: 100% !important;
    padding: 0 !important;
    float: none !important;
  }
  .listing.listing-mix-1-2 .column-1 {
    margin-bottom: 20px;
  }
}
.listing.listing-mix-1-4 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing.listing-mix-1-4 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing.listing-mix-1-4 .column-1 {
  width: 70%;
  float: left;
  padding-right: 18px;
}

.listing.listing-mix-1-4 .column-2 {
  width: 30%;
  float: left;
  padding-left: 18px;
}

.listing.listing-mix-1-4.l-1-col .column-1, .listing.listing-mix-1-4.l-1-col .column-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing.listing-mix-1-4.l-1-col .listing-item-grid-1 {
  margin-bottom: 20px;
}

.listing.listing-mix-1-4.l-1-col .listing-item-tb-2 {
  width: 50%;
  float: left;
}

.listing.listing-mix-1-4.l-1-col .listing-item-tb-2:nth-child(odd) {
  padding-right: 13px;
  clear: left;
}

.listing.listing-mix-1-4.l-1-col .listing-item-tb-2:nth-child(even) {
  padding-left: 13px;
}

@media only screen and (max-width: 450px) {
  .listing.listing-mix-1-4 .column-1, .listing.listing-mix-1-4 .column-2 {
    width: 100% !important;
    padding: 0 !important;
    float: none !important;
  }
  .listing.listing-mix-1-4 .listing-item-grid-1 {
    margin-bottom: 20px;
  }
  .listing.listing-mix-1-4 .listing-item-tb-2 {
    width: 50%;
    float: left;
  }
  .listing.listing-mix-1-4 .listing-item-tb-2:nth-child(odd) {
    padding-right: 13px;
    clear: left;
  }
  .listing.listing-mix-1-4 .listing-item-tb-2:nth-child(even) {
    padding-left: 13px;
  }
}
.listing-mix-2-1 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.bs-listing-listing-mix-2-1 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.listing-mix-2-1.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  float: none;
  padding: 0 !important;
}

@media only screen and (max-width: 450px) {
  .listing-mix-2-1 .listing.columns-2 .listing-item {
    width: 100%;
    float: none;
    padding: 0 !important;
  }
}
.listing-mix-2-2 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing-mix-2-2.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  float: none;
  padding: 0 !important;
}

@media only screen and (max-width: 450px) {
  .listing-mix-2-2 .listing.columns-2 .listing-item {
    width: 100%;
    float: none;
    padding: 0 !important;
  }
}
.listing.listing-mix-3-1 .row-1 + .row-2 {
  margin-top: 23px;
}

.listing.listing-mix-3-1 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing.listing-mix-3-2 .row-1:last-child {
  margin-bottom: 20px;
}

.listing.listing-mix-3-2 .row-1 + .row-2 {
  margin-top: 23px;
}

.listing.listing-mix-3-2 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing.listing-mix-3-2 .listing-item-tb-2 {
  margin-bottom: 25px;
}

.infinity > .listing-mix-3-2 + .listing-mix-3-2, .more_btn > .listing-mix-3-2 + .listing-mix-3-2 {
  margin-top: 0;
}

.listing.listing-mix-3-3 .row-1 + .row-2 {
  margin-top: 23px;
}

.listing.listing-mix-3-3 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing.listing-mix-3-3 .listing-item-text-2 {
  margin-bottom: 25px;
}

.listing-mix-3-3.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  float: none;
  padding: 0 !important;
}

@media only screen and (max-width: 450px) {
  .listing-mix-3-3 .listing.columns-2 .listing-item {
    width: 100%;
    float: none;
    padding: 0 !important;
  }
}
.listing.listing-mix-3-4 .row-1 + .row-2 {
  margin-top: 23px;
}

.listing.listing-mix-3-4 .listing-item-tb-1:last-child, .listing.listing-mix-3-4 .listing-mg-3-item {
  margin-bottom: 0;
}

.listing.listing-mix-3-4 .listing-item-tb-1 {
  margin-bottom: 25px;
}

.listing-mix-6 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing-mix-6 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing-mix-6 .listing-item-text:last-child {
  margin-bottom: 0;
}

.listing-mix-6 .column-1 {
  width: 50%;
  float: left;
  padding-right: 18px;
}

.listing-mix-6 .column-2 {
  width: 50%;
  float: left;
  padding-left: 18px;
}

.listing.listing-mix-6.l-1-col .column-1, .listing.listing-mix-6.l-1-col .column-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing.listing-mix-6.l-1-col .column-1 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 450px) {
  .listing.listing-mix-6 .column-1, .listing.listing-mix-6 .column-2 {
    width: 100%;
    padding: 0;
    float: none;
  }
  .listing.listing-mix-6 .column-1 {
    margin-bottom: 20px;
  }
}
.listing-mix-4-1 .listing-classic-3 {
  margin-bottom: 15px;
}

.bs-pagination-wrapper .listing-mix-4-1 + .listing-mix-4-1 {
  margin-top: 0;
}

.listing.listing-mix-4-1.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-1 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-2 .listing-classic-3 {
  margin-bottom: 15px;
}

.bs-listing-listing-mix-4-2 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.listing.listing-mix-4-2.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-2 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-3 .listing-classic-2 {
  margin-bottom: 25px;
}

.bs-pagination-wrapper .listing-mix-4-3 + .listing-mix-4-3 {
  margin-top: 0;
}

.listing.listing-mix-4-3.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-3 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-4 .listing-classic-2 {
  margin-bottom: 25px;
  margin-top: 0 !important;
}

.bs-pagination-wrapper .listing-mix-4-4 + .listing-mix-4-4 {
  margin-top: 0;
}

.listing.listing-mix-4-4.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-4 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-5 .listing-classic-2.columns-2 .listing-item-classic-2 {
  margin-bottom: 30px !important;
}

.listing-mix-4-5 .listing-classic-2 {
  margin-bottom: 30px;
}

.listing-mix-4-5 .listing-classic-2:last-child {
  margin-bottom: 0;
}

.bs-listing-listing-mix-4-5 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.listing.listing-mix-4-5.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-5 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-6 .listing-classic-2.columns-2 .listing-item-classic-2 {
  margin-bottom: 30px !important;
}

.listing-mix-4-6 .listing-classic-2 {
  margin-bottom: 30px;
}

.listing-mix-4-6 .listing-classic-2.columns-2 {
  margin-bottom: 0;
}

.bs-listing-listing-mix-4-6 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.listing.listing-mix-4-6.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-6 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-7 .listing-classic-3 {
  margin-bottom: 0;
}

.bs-listing-listing-mix-4-7 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.bs-listing-listing-mix-4-7 .bs-pagination-wrapper .listing-mix-4-7 + .listing-mix-4-7.listing-mix-4-7 {
  margin-top: 20px;
}

.listing-mix-4-8 .listing-classic-2 {
  margin-bottom: 25px;
}

.listing-mg-item {
  position: relative;
  overflow: hidden;
}

.listing-mg-item.has-not-post-thumbnail .img-cont {
  background-color: #e4e4e4;
}

.listing-mg-1-item .item-content, .listing-mg-item .content-container, .listing-mg-item .term-badges.floated, .listing-modern-grid .bs-slider-item .item-content {
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.listing-mg-1-item .img-cont {
  -webkit-backface-visibility: hidden;
}

.listing-mg-item .post-meta .views.views.views {
  color: #fff !important;
}

.listing-mg-item.listing-mg-5-item .post-meta.post-meta .views.views.views {
  padding: 0;
  background: 0 0;
}

.listing-mg-item .post-meta .views {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 1px 6px;
  vertical-align: middle;
}

.listing-modern-grid.slider-overlay-simple .listing-mg-item .content-container {
  padding: 22px 20px 20px;
  background: rgba(0, 0, 0, 0.42);
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.listing-modern-grid.slider-overlay-simple-gr .listing-mg-item .img-cont:after {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.65)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  top: auto;
  height: 75%;
}

.listing-modern-grid.slider-overlay-colored .listing-item-1 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-17 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-9 .img-cont:after {
  background: #dc4225;
  background: -moz-linear-gradient(45deg, #292484 0, #dc4225 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #292484), color-stop(100%, #dc4225));
  background: -webkit-linear-gradient(45deg, #292484 0, #dc4225 100%);
  background: -o-linear-gradient(45deg, #292484 0, #dc4225 100%);
  background: -ms-linear-gradient(45deg, #292484 0, #dc4225 100%);
  background: linear-gradient(45deg, #292484 0, #dc4225 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-10 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-18 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-2 .img-cont:after {
  background: #26d0ce;
  background: -moz-linear-gradient(45deg, #1a2980 0, #26d0ce 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #1a2980), color-stop(100%, #26d0ce));
  background: -webkit-linear-gradient(45deg, #1a2980 0, #26d0ce 100%);
  background: -o-linear-gradient(45deg, #1a2980 0, #26d0ce 100%);
  background: -ms-linear-gradient(45deg, #1a2980 0, #26d0ce 100%);
  background: linear-gradient(45deg, #1a2980 0, #26d0ce 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-11 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-19 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-3 .img-cont:after {
  background: #1d976c;
  background: -moz-linear-gradient(45deg, #1d976c 0, #3cf93d 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #1d976c), color-stop(100%, #3cf93d));
  background: -webkit-linear-gradient(45deg, #1d976c 0, #3cf93d 100%);
  background: -o-linear-gradient(45deg, #1d976c 0, #3cf93d 100%);
  background: -ms-linear-gradient(45deg, #1d976c 0, #3cf93d 100%);
  background: linear-gradient(45deg, #1d976c 0, #3cf93d 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-12 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-20 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-4 .img-cont:after {
  background: #dc4225;
  background: -moz-linear-gradient(45deg, #e0be00 0, #dc4225 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #e0be00), color-stop(100%, #dc4225));
  background: -webkit-linear-gradient(45deg, #e0be00 0, #dc4225 100%);
  background: -o-linear-gradient(45deg, #e0be00 0, #dc4225 100%);
  background: -ms-linear-gradient(45deg, #e0be00 0, #dc4225 100%);
  background: linear-gradient(45deg, #e0be00 0, #dc4225 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-13 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-21 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-5 .img-cont:after {
  background: #ff4f39;
  background: -moz-linear-gradient(45deg, #ff4f39 0, #dd0098 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #ff4f39), color-stop(100%, #dd0098));
  background: -webkit-linear-gradient(45deg, #ff4f39 0, #dd0098 100%);
  background: -o-linear-gradient(45deg, #ff4f39 0, #dd0098 100%);
  background: -ms-linear-gradient(45deg, #ff4f39 0, #dd0098 100%);
  background: linear-gradient(45deg, #ff4f39 0, #dd0098 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-14 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-22 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-6 .img-cont:after {
  background: #3895ff;
  background: -moz-linear-gradient(45deg, #3895ff 0, #2add28 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #3895ff), color-stop(100%, #2add28));
  background: -webkit-linear-gradient(45deg, #3895ff 0, #2add28 100%);
  background: -o-linear-gradient(45deg, #3895ff 0, #2add28 100%);
  background: -ms-linear-gradient(45deg, #3895ff 0, #2add28 100%);
  background: linear-gradient(45deg, #3895ff 0, #2add28 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-15 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-23 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-7 .img-cont:after {
  background: #7741d5;
  background: -moz-linear-gradient(45deg, #7741d5 0, #c56200 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #7741d5), color-stop(100%, #c56200));
  background: -webkit-linear-gradient(45deg, #7741d5 0, #c56200 100%);
  background: -o-linear-gradient(45deg, #7741d5 0, #c56200 100%);
  background: -ms-linear-gradient(45deg, #7741d5 0, #c56200 100%);
  background: linear-gradient(45deg, #7741d5 0, #c56200 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-16 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-24 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-8 .img-cont:after {
  background: #06c67b;
  background: -moz-linear-gradient(45deg, #06c67b 0, #076fc5 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #06c67b), color-stop(100%, #076fc5));
  background: -webkit-linear-gradient(45deg, #06c67b 0, #076fc5 100%);
  background: -o-linear-gradient(45deg, #06c67b 0, #076fc5 100%);
  background: -ms-linear-gradient(45deg, #06c67b 0, #076fc5 100%);
  background: linear-gradient(45deg, #06c67b 0, #076fc5 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-mg-item .img-cont:after {
  opacity: 0.7;
}

.listing-modern-grid.slider-overlay-colored .listing-mg-item:hover .img-cont:after {
  opacity: 0.53;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-mg-item .img-cont:after {
  background-size: 300% 300%;
  -webkit-animation: mg-gr-anim 5.5s ease-in infinite;
  -moz-animation: mg-gr-anim 5.5s ease-in infinite;
  -o-animation: mg-gr-anim 5.5s ease-in infinite;
  animation: mg-gr-anim 5.5s ease-in infinite;
  opacity: 0.5;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-mg-item:hover .img-cont:after {
  opacity: 0.7;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-1 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-17 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-9 .img-cont:after {
  background: linear-gradient(135deg, #292484, #dc4225);
  background-size: 500% 500%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-10 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-18 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-2 .img-cont:after {
  background: linear-gradient(135deg, #1a2980, #26d0ce);
  background-size: 500% 500%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-11 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-19 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-3 .img-cont:after {
  background: linear-gradient(135deg, #1d976c, #3cf93d);
  background-size: 500% 500%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-12 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-20 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-4 .img-cont:after {
  background: linear-gradient(135deg, #e0be00, #dc4225);
  background-size: 500% 500%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-13 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-21 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-5 .img-cont:after {
  background: linear-gradient(135deg, #ff4f39, #dd0098);
  background-size: 1000% 1000%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-14 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-22 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-6 .img-cont:after {
  background: linear-gradient(135deg, #06c67b, #076fc5);
  background-size: 1000% 1000%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-15 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-23 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-7 .img-cont:after {
  background: linear-gradient(135deg, #7741d5, #c56200);
  background-size: 1000% 1000%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-16 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-24 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-8 .img-cont:after {
  background: linear-gradient(135deg, #3895ff, #2add28);
  background-size: 1000% 1000%;
}

@-webkit-keyframes mg-gr-anim {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@-moz-keyframes mg-gr-anim {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@-o-keyframes mg-gr-anim {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes mg-gr-anim {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}
.listing-mg-item.listing-mg-1-item.listing-mg-1-item:hover .title a, .listing-mg-item.listing-mg-2-item.listing-mg-2-item:hover .title a, .listing-mg-item.listing-mg-3-item.listing-mg-3-item:hover .title a, .listing-mg-item.listing-mg-4-item.listing-mg-4-item:hover .title a, .listing-mg-item.listing-mg-6-item.listing-mg-6-item:hover .title a {
  color: #fff !important;
}

.listing-modern-grid-1.l-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-1.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-1.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-1.l-5 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-5 .listing-mg-item:hover .format-icon, .listing-modern-grid-3.l-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-3.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-3.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-3.l-5 .listing-mg-item:hover .format-icon, .listing-modern-grid-4.l-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-4.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-4.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-4.l-5 .listing-mg-item:hover .format-icon, .listing-modern-grid-6.l-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-6.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-6.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-6.l-5 .listing-mg-item:hover .format-icon {
  right: 20px;
  top: 20px;
  margin-top: 0;
  margin-right: 0;
  transform: none;
}

.listing-mg-item:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.listing-item-3.listing-mg-1-item:hover .format-icon, .listing-item-4.listing-mg-1-item:hover .format-icon {
  margin-top: -30px;
}

.listing-item-2.listing-mg-2-item:hover .format-icon, .listing-item-3.listing-mg-2-item:hover .format-icon, .listing-item-4.listing-mg-2-item:hover .format-icon, .listing-item-5.listing-mg-2-item:hover .format-icon {
  margin-top: -25px;
}

.listing-mg-3-item:hover .format-icon {
  top: 27px;
  margin-top: 0;
}

.infinity > .listing-modern-grid-3 + .listing-modern-grid-3, .more_btn > .listing-modern-grid-3 + .listing-modern-grid-3 {
  margin-top: 0;
}

body.bs-ll-a .listing-mg-type-1 .img-cont.img-cont {
  background-size: inherit !important;
  transition: none;
  transition: all 0.35s;
}

body.bs-ll-a .listing-mg-type-1 .img-cont.img-cont.b-error, body.bs-ll-a .listing-mg-type-1 .img-cont.img-cont.b-loaded, body.bs-ll-d .listing-mg-type-1 .img-cont.img-cont {
  background-size: cover !important;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation: none;
  animation: none;
}

.listing-mg-item .post-subtitle {
  -webkit-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  -moz-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.listing-mg-item .post-subtitle, .listing-mg-item .post-subtitle a {
  color: #fff !important;
}

.listing-mg-item .post-subtitle {
  margin-top: 10px;
}

.listing-mg-item .post-subtitle + .post-meta {
  margin-top: 5px;
}

.listing-mg-item .post-subtitle:first-child {
  margin: 0 0 7px;
}

@media only screen and (max-width: 480px) {
  .listing-modern-grid-1 .listing-mg-item .format-icon, .listing-modern-grid-1 .listing-mg-item:hover .format-icon, .listing-modern-grid-2 .listing-mg-item .format-icon, .listing-modern-grid-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-3 .listing-mg-item .format-icon, .listing-modern-grid-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-4 .listing-mg-item .format-icon, .listing-modern-grid-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-6 .listing-mg-item .format-icon, .listing-modern-grid-6 .listing-mg-item:hover .format-icon {
    right: 20px;
    top: 20px;
    margin-top: 0;
    margin-right: 0;
    transform: none;
  }
}
.listing-mg-type-1:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 68.17%;
}

.listing-mg-type-1 .item-content, .listing-modern-grid .bs-slider-item .listing-mg-type-1 .item-content {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.listing-mg-type-1 .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: -1px;
  left: -2px;
  right: -2px;
  bottom: -1px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  -moz-transform-origin: 0 0;
}

.listing-mg-type-1 .img-cont.b-error, .listing-mg-type-1 .img-cont.b-loaded, body.bs-ll-d .listing-mg-type-1 .img-cont {
  background-size: cover !important;
}

.listing-mg-type-1 .img-cont:after {
  -webkit-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-mg-type-1:hover .img-cont {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.listing-mg-type-1 .content-container {
  position: absolute;
  bottom: 15px;
  left: 20px;
  right: 20px;
  padding: 0;
  max-height: 75%;
  overflow: hidden;
  pointer-events: none;
}

.listing-mg-type-1 .content-container a {
  pointer-events: all;
}

.listing-mg-type-1 .title {
  margin: 0;
  -webkit-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  -moz-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
}

.listing-item.listing-mg-type-1 .title.title a.post-url.post-url, .listing-item.listing-mg-type-1 .title.title a.post-url.post-url:hover {
  color: inherit !important;
}

.listing-mg-type-1 .post-meta {
  margin-top: 3px;
}

.listing-mg-type-1 .post-meta .post-author {
  color: #fff;
}

.listing-mg-type-1 .post-meta .comments {
  color: #fff;
  float: right;
  margin-right: 0;
  margin-left: 15px;
}

.listing-mg-type-1 .post-meta .rating + .comments {
  float: none;
  margin-left: 0;
  vertical-align: middle;
}

.listing-mg-type-1 .post-meta .post-author:after, .listing-mg-type-1 .post-meta .time {
  color: rgba(255, 255, 255, 0.57);
}

.listing-mg-1-item.listing-item-2 .title, .listing-mg-1-item.listing-item-3 .title, .listing-mg-1-item.listing-item-4 .title, .listing-mg-1-item.listing-item-5 .title {
  font-size: 72%;
}

.listing-mg-1-item.listing-item-2 .title {
  font-size: 80%;
}

.listing-modern-grid-1 .mg-col-1 {
  width: 56%;
  float: left;
  padding-right: 1px;
  overflow: hidden;
}

.listing-modern-grid-1 .mg-col-2 {
  width: 44%;
  float: left;
  padding-left: 1px;
  overflow: hidden;
}

.listing-modern-grid-1 .mg-row-1 {
  margin: 0 0 2px;
  overflow: hidden;
}

.listing-mg-1-item.listing-item-2:before {
  padding-top: 47.29%;
}

.listing-modern-grid-1 .item-3-cont, .listing-modern-grid-1 .item-4-cont {
  width: 50%;
  float: left;
  overflow: hidden;
}

.listing-modern-grid-1 .item-3-cont {
  padding-right: 1px;
}

.listing-modern-grid-1 .item-4-cont {
  padding-left: 1px;
}

.listing-mg-1-item.listing-item-3:before, .listing-mg-1-item.listing-item-4:before {
  padding-top: 78.54%;
}

.listing-modern-grid-1.l-1 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1.l-1 .listing-mg-1-item.listing-item-4:before {
  padding-top: 78.43%;
}

.listing-modern-grid-1.l-2 .listing-mg-1-item:before {
  padding-top: 89%;
}

.listing-modern-grid-1.l-2 .listing-mg-1-item.listing-item-2:before {
  padding-top: 63.74%;
}

.listing-modern-grid-1.l-2 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1.l-2 .listing-mg-1-item.listing-item-4:before {
  padding-top: 98.58%;
}

.listing-modern-grid-1.l-2 .listing-item-1 .title, .listing-modern-grid-1.l-3 .listing-item-1 .title, .listing-modern-grid-1.l-4 .listing-item-1 .title, .listing-modern-grid-1.l-5 .listing-item-1 .title {
  font-size: 80%;
}

.listing-modern-grid-1.l-2 .listing-item-2 .title {
  font-size: 72%;
}

.listing-modern-grid-1.l-2 .listing-item-3 .title, .listing-modern-grid-1.l-2 .listing-item-4 .title, .listing-modern-grid-1.l-3 .listing-item-3 .title, .listing-modern-grid-1.l-3 .listing-item-4 .title, .listing-modern-grid-1.l-4 .listing-item-3 .title, .listing-modern-grid-1.l-4 .listing-item-4 .title, .listing-modern-grid-1.l-5 .listing-item-3 .title, .listing-modern-grid-1.l-5 .listing-item-4 .title {
  font-size: 62%;
}

.listing-modern-grid-1.l-2 .listing-item-3 .term-badges, .listing-modern-grid-1.l-2 .listing-item-4 .term-badges, .listing-modern-grid-1.l-3 .listing-item-4 .term-badges, .listing-modern-grid-1.l-4 .listing-item-4 .term-badges, .listing-modern-grid-1.l-5 .listing-item-4 .term-badges {
  display: none;
}

.listing-modern-grid-1.l-2 .listing-mg-item .content-container, .listing-modern-grid-1.l-3 .listing-mg-item .content-container, .listing-modern-grid-1.l-4 .listing-mg-item .content-container, .listing-modern-grid-1.l-5 .listing-mg-item .content-container {
  left: 15px;
  right: 15px;
  bottom: 10px;
  max-height: 50%;
}

.listing-modern-grid-1.l-3 .listing-mg-item .format-icon, .listing-modern-grid-1.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-1.l-4 .listing-mg-item .format-icon, .listing-modern-grid-1.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-1.l-5 .listing-mg-item .format-icon, .listing-modern-grid-1.l-5 .listing-mg-item:hover .format-icon {
  right: 12px;
  top: 12px;
}

.listing-modern-grid-1.l-3 .mg-col-1, .listing-modern-grid-1.l-5 .mg-col-1 {
  width: 100%;
  padding: 0;
}

.listing-modern-grid-1.l-3 .mg-col-2, .listing-modern-grid-1.l-5 .mg-col-2 {
  width: 100%;
  padding: 2px 0 0 0;
}

.listing-modern-grid-1.l-3 .mg-row-1, .listing-modern-grid-1.l-5 .mg-row-1 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing-modern-grid-1.l-3 .mg-row-2, .listing-modern-grid-1.l-5 .mg-row-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing-modern-grid-1.l-3 .listing-item-1:before, .listing-modern-grid-1.l-3 .listing-mg-1-item.listing-item-2:before, .listing-modern-grid-1.l-4 .listing-item-1:before, .listing-modern-grid-1.l-4 .listing-mg-1-item.listing-item-2:before, .listing-modern-grid-1.l-5 .listing-item-1:before, .listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-2:before {
  padding-top: 46%;
}

.listing-modern-grid-1.l-3 .listing-item-2 .title, .listing-modern-grid-1.l-4 .listing-item-2 .title, .listing-modern-grid-1.l-5 .listing-item-2 .title {
  font-size: 72%;
}

.listing-modern-grid-1.l-3 .listing-item-2 .post-meta, .listing-modern-grid-1.l-3 .listing-item-3 .term-badges.floated, .listing-modern-grid-1.l-3 .listing-item-4 .term-badges.floated {
  display: none;
}

.listing-modern-grid-1.l-4 .listing-mg-1-item.listing-item-1:before {
  padding-top: 50%;
}

.listing-modern-grid-1.l-4 .listing-mg-1-item.listing-item-2 .term-badges.floated {
  display: none;
}

.listing-modern-grid-1.l-4 .listing-mg-1-item.listing-item-2:before {
  padding-top: 38%;
}

.listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-1:before {
  padding-top: 57%;
}

.listing-modern-grid-1.l-5 .listing-item-2 .post-meta, .listing-modern-grid-1.l-5 .listing-item-3 .term-badges.floated, .listing-modern-grid-1.l-5 .listing-item-4 .term-badges.floated, .listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-1 .post-meta, .listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-2 .term-badges.floated {
  display: none;
}

.listing-modern-grid-1.l-5 .listing-item-2 .title {
  font-size: 64%;
}

.listing-modern-grid-1.l-5 .listing-mg-item .content-container {
  left: 12px;
  right: 12px;
  bottom: 12px;
}

.listing-modern-grid-1.l-5 .listing-mg-item.listing-item-2 .content-container {
  max-height: 40%;
}

.slider-overlay-simple.listing-modern-grid-1.l-5 .listing-mg-item.listing-item-2 .content-container {
  max-height: 60%;
  padding: 12px 12px 10px;
}

.listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-4:before {
  padding-top: 88%;
}

.listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-2:before {
  padding-top: 50.5%;
}

@media only screen and (max-width: 980px) {
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1 .listing-mg-1-item.listing-item-4:before {
    padding-top: 78.43%;
  }
}
@media only screen and (max-width: 780px) {
  .listing-modern-grid-1 .listing-mg-1-item:before {
    padding-top: 89%;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-2:before {
    padding-top: 63.74%;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1 .listing-mg-1-item.listing-item-4:before {
    padding-top: 98.58%;
  }
  .listing-modern-grid-1 .listing-item-1 .title {
    font-size: 80%;
  }
  .listing-modern-grid-1 .listing-item-2 .title {
    font-size: 72%;
  }
  .listing-modern-grid-1 .listing-item-3 .title, .listing-modern-grid-1 .listing-item-4 .title {
    font-size: 62%;
  }
  .listing-modern-grid-1 .listing-item-3 .term-badges, .listing-modern-grid-1 .listing-item-4 .term-badges {
    display: none;
  }
  .listing-modern-grid-1 .listing-mg-item .content-container {
    left: 15px;
    right: 15px;
    bottom: 10px;
    max-height: 50%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-1 .listing-mg-item .format-icon, .listing-modern-grid-1 .listing-mg-item:hover .format-icon {
    right: 12px;
    top: 12px;
  }
  .listing-modern-grid-1 .mg-col-1 {
    width: 100%;
    padding: 0;
  }
  .listing-modern-grid-1 .mg-col-2 {
    width: 100%;
    padding: 2px 0 0 0;
  }
  .listing-modern-grid-1 .mg-row-1 {
    width: 100%;
    padding: 0;
    float: none;
  }
  .listing-modern-grid-1 .mg-row-2 {
    width: 100%;
    padding: 0;
    float: none;
  }
  .listing-modern-grid-1 .listing-item-1:before, .listing-modern-grid-1 .listing-mg-1-item.listing-item-2:before {
    padding-top: 46%;
  }
  .listing-modern-grid-1 .listing-item-2 .title {
    font-size: 72%;
  }
  .listing-modern-grid-1 .listing-item-2 .post-meta, .listing-modern-grid-1 .listing-item-3 .term-badges.floated, .listing-modern-grid-1 .listing-item-4 .term-badges.floated {
    display: none;
  }
}
@media only screen and (max-width: 560px) {
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-1:before {
    padding-top: 50%;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-2 .term-badges.floated {
    display: none;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-2:before {
    padding-top: 38%;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-2:before {
    padding-top: 50.5%;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-1:before {
    padding-top: 57%;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-1 .post-meta {
    display: none;
  }
  .listing-modern-grid-1 .listing-item-2 .title {
    font-size: 64%;
  }
  .listing-modern-grid-1 .listing-mg-item .content-container {
    left: 12px;
    right: 12px;
    bottom: 12px;
  }
  .listing-modern-grid-1 .listing-mg-item.listing-item-2 .content-container {
    max-height: 40%;
  }
  .slider-overlay-simple.listing-modern-grid-1 .listing-mg-item.listing-item-2 .content-container {
    max-height: 60%;
    padding: 12px 12px 10px;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1 .listing-mg-1-item.listing-item-4:before {
    padding-top: 88%;
  }
}
.listing-mg-2-item.listing-item-2 .title, .listing-mg-2-item.listing-item-3 .title, .listing-mg-2-item.listing-item-4 .title, .listing-mg-2-item.listing-item-5 .title {
  font-size: 72%;
}

.listing-mg-2-item:before {
  padding-top: 77.8%;
}

.listing-mg-2-item.listing-item-1:before {
  padding-top: 69.05%;
}

.listing-modern-grid-2 .mg-col-1 {
  width: 53%;
  float: left;
  padding-right: 1px;
}

.listing-modern-grid-2 .mg-col-2 {
  width: 47%;
  float: left;
  padding-left: 1px;
}

.listing-modern-grid-2 .item-2-cont, .listing-modern-grid-2 .item-3-cont, .listing-modern-grid-2 .item-4-cont, .listing-modern-grid-2 .item-5-cont {
  width: 50%;
  float: left;
}

.listing-modern-grid-2 .item-2-cont, .listing-modern-grid-2 .item-4-cont {
  padding-right: 1px;
}

.listing-modern-grid-2 .item-3-cont, .listing-modern-grid-2 .item-5-cont {
  padding-left: 1px;
}

.listing-modern-grid-2 .mg-row-1 {
  margin-bottom: 2px;
}

.listing-modern-grid-2.l-1 .listing-mg-item .content-container, .listing-modern-grid-2.l-2 .listing-mg-item .content-container, .listing-modern-grid-2.l-3 .listing-mg-item .content-container, .listing-modern-grid-2.l-4 .listing-mg-item .content-container, .listing-modern-grid-2.l-5 .listing-mg-item .content-container {
  left: 15px;
  right: 15px;
  bottom: 12px;
  max-height: 50%;
}

.listing-modern-grid-2.l-2 .mg-col-1, .listing-modern-grid-2.l-2 .mg-col-2, .listing-modern-grid-2.l-3 .mg-col-1, .listing-modern-grid-2.l-3 .mg-col-2, .listing-modern-grid-2.l-4 .mg-col-1, .listing-modern-grid-2.l-4 .mg-col-2, .listing-modern-grid-2.l-5 .mg-col-1, .listing-modern-grid-2.l-5 .mg-col-2 {
  width: 50%;
}

.listing-modern-grid-2.l-2 .listing-mg-item:before, .listing-modern-grid-2.l-3 .listing-mg-item:before, .listing-modern-grid-2.l-4 .listing-mg-item:before, .listing-modern-grid-2.l-5 .listing-mg-item:before {
  padding-top: 92.97%;
}

.listing-modern-grid-2.l-2 .listing-item-1:before, .listing-modern-grid-2.l-3 .listing-item-1:before, .listing-modern-grid-2.l-4 .listing-item-1:before, .listing-modern-grid-2.l-5 .listing-item-1:before {
  padding-top: 93%;
}

.listing-modern-grid-2.l-2 .listing-mg-item .title, .listing-modern-grid-2.l-3 .listing-mg-item .title, .listing-modern-grid-2.l-4 .listing-mg-item .title, .listing-modern-grid-2.l-5 .listing-mg-item .title {
  font-size: 64%;
}

.listing-modern-grid-2.l-2 .listing-item-1 .title, .listing-modern-grid-2.l-3 .listing-item-1 .title, .listing-modern-grid-2.l-4 .listing-item-1 .title, .listing-modern-grid-2.l-5 .listing-item-1 .title {
  font-size: 86%;
}

.listing-modern-grid-2.l-2 .listing-item-2 .term-badges, .listing-modern-grid-2.l-2 .listing-item-3 .term-badges, .listing-modern-grid-2.l-2 .listing-item-4 .term-badges, .listing-modern-grid-2.l-2 .listing-item-5 .term-badges, .listing-modern-grid-2.l-3 .listing-item-2 .term-badges, .listing-modern-grid-2.l-3 .listing-item-3 .term-badges, .listing-modern-grid-2.l-3 .listing-item-4 .term-badges, .listing-modern-grid-2.l-3 .listing-item-5 .term-badges, .listing-modern-grid-2.l-4 .listing-item-2 .term-badges, .listing-modern-grid-2.l-4 .listing-item-3 .term-badges, .listing-modern-grid-2.l-4 .listing-item-4 .term-badges, .listing-modern-grid-2.l-4 .listing-item-5 .term-badges, .listing-modern-grid-2.l-5 .listing-item-2 .term-badges, .listing-modern-grid-2.l-5 .listing-item-3 .term-badges, .listing-modern-grid-2.l-5 .listing-item-4 .term-badges, .listing-modern-grid-2.l-5 .listing-item-5 .term-badges {
  display: none;
}

.listing-modern-grid-2.l-3 .listing-mg-item .post-meta, .listing-modern-grid-2.l-4 .listing-mg-item .post-meta, .listing-modern-grid-2.l-5 .listing-mg-item .post-meta {
  display: none !important;
}

.listing-modern-grid-2.l-3 .listing-mg-item.listing-item-1 .post-meta, .listing-modern-grid-2.l-4 .listing-mg-item.listing-item-1 .post-meta, .listing-modern-grid-2.l-5 .listing-mg-item.listing-item-1 .post-meta {
  display: block;
}

.listing-modern-grid-2.l-3 .listing-mg-item .format-icon, .listing-modern-grid-2.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-4 .listing-mg-item .format-icon, .listing-modern-grid-2.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-5 .listing-mg-item .format-icon, .listing-modern-grid-2.l-5 .listing-mg-item:hover .format-icon {
  right: 12px;
  top: 12px;
}

.listing-modern-grid-2.l-3 .listing-mg-item:before, .listing-modern-grid-2.l-4 .listing-mg-item:before, .listing-modern-grid-2.l-5 .listing-mg-item:before {
  padding-top: 60%;
}

.listing-modern-grid-2.l-3 .listing-item-1:before, .listing-modern-grid-2.l-4 .listing-item-1:before, .listing-modern-grid-2.l-5 .listing-item-1:before {
  padding-top: 45%;
}

.listing-modern-grid-2.l-3 .mg-col-1, .listing-modern-grid-2.l-4 .mg-col-1, .listing-modern-grid-2.l-5 .mg-col-1 {
  width: 100%;
  padding: 0;
  margin-bottom: 2px;
}

.listing-modern-grid-2.l-3 .mg-col-2, .listing-modern-grid-2.l-4 .mg-col-2, .listing-modern-grid-2.l-5 .mg-col-2 {
  width: 100%;
  padding: 0;
}

.listing-modern-grid-2.l-4 .listing-mg-item .format-icon, .listing-modern-grid-2.l-5 .listing-mg-item .format-icon {
  display: none;
}

.listing-modern-grid-2.l-4 .listing-mg-item-1 .format-icon, .listing-modern-grid-2.l-5 .listing-mg-item-1 .format-icon {
  display: block;
}

.listing-modern-grid-2.l-4 .listing-mg-item:before, .listing-modern-grid-2.l-5 .listing-mg-item:before {
  padding-top: 80%;
}

.listing-modern-grid-2.l-4 .listing-item-1:before, .listing-modern-grid-2.l-5 .listing-item-1:before {
  padding-top: 60%;
}

.slider-overlay-simple.listing-modern-grid-2.l-5 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-2.l-5 .listing-mg-item.listing-item-3 .content-container, .slider-overlay-simple.listing-modern-grid-2.l-5 .listing-mg-item.listing-item-4 .content-container, .slider-overlay-simple.listing-modern-grid-2.l-5 .listing-mg-item.listing-item-5 .content-container {
  max-height: 64%;
  padding: 12px;
}

@media only screen and (max-width: 900px) {
  .listing-modern-grid-2 .listing-mg-item .content-container {
    left: 15px;
    right: 15px;
    bottom: 12px;
    max-height: 50%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-2 .mg-col-1, .listing-modern-grid-2 .mg-col-2 {
    width: 50%;
  }
  .listing-modern-grid-2 .listing-mg-item:before {
    padding-top: 92.97%;
  }
  .listing-modern-grid-2 .listing-item-1:before {
    padding-top: 93%;
  }
  .listing-modern-grid-2 .listing-mg-item .title {
    font-size: 66%;
  }
  .listing-modern-grid-2 .listing-item-1 .title {
    font-size: 86%;
  }
  .listing-modern-grid-2 .listing-item-2 .term-badges, .listing-modern-grid-2 .listing-item-3 .term-badges, .listing-modern-grid-2 .listing-item-4 .term-badges, .listing-modern-grid-2 .listing-item-5 .term-badges {
    display: none;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-2 .listing-mg-item .post-meta {
    display: none;
  }
  .listing-modern-grid-2 .listing-mg-item.listing-item-1 .post-meta {
    display: block;
  }
  .listing-modern-grid-2 .listing-mg-item .format-icon, .listing-modern-grid-2 .listing-mg-item:hover .format-icon {
    right: 12px;
    top: 12px;
  }
  .listing-modern-grid-2 .listing-mg-item:before {
    padding-top: 60%;
  }
  .listing-modern-grid-2 .listing-item-1:before {
    padding-top: 45%;
  }
  .listing-modern-grid-2 .mg-col-1 {
    width: 100%;
    padding: 0;
    margin-bottom: 2px;
  }
  .listing-modern-grid-2 .mg-col-2 {
    width: 100%;
    padding: 0;
  }
}
@media only screen and (max-width: 560px) {
  .listing-modern-grid-2 .listing-mg-item:before {
    padding-top: 80%;
  }
  .listing-modern-grid-2 .listing-item-1:before {
    padding-top: 60%;
  }
}
@media only screen and (max-width: 480px) {
  .slider-overlay-simple.listing-modern-grid-2 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-2 .listing-mg-item.listing-item-3 .content-container, .slider-overlay-simple.listing-modern-grid-2 .listing-mg-item.listing-item-4 .content-container, .slider-overlay-simple.listing-modern-grid-2 .listing-mg-item.listing-item-5 .content-container {
    max-height: 64%;
    padding: 12px;
  }
}
.bs-shortcode.bs-modern-grid-listing-3 {
  margin-bottom: 10px;
}

.listing-mg-3-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.listing-mg-3-item .item-content {
  position: relative;
  overflow: hidden;
}

.listing-mg-3-item .item-content:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 65%;
}

.listing-mg-3-item .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: -1px;
  left: -2px;
  right: -2px;
  bottom: -1px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  -moz-transform-origin: 0 0;
}

.listing-mg-3-item .img-cont.b-error, .listing-mg-3-item .img-cont.b-loaded, body.bs-ll-d .listing-mg-3-item .img-cont {
  background-size: cover !important;
  animation: none;
}

.listing-mg-3-item .img-cont:after {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-mg-3-item:hover .img-cont {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
}

.listing-mg-3-item .content-container {
  position: absolute !important;
  bottom: 20px;
  left: 20px;
  right: 20px;
  padding: 0;
  overflow: hidden;
  pointer-events: none;
}

.listing-mg-3-item .content-container a {
  pointer-events: all;
}

.listing-mg-3-item .title {
  margin: 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  text-align: center !important;
}

.listing-mg-3-item .title a {
  color: #fff;
}

.listing-mg-3-item .post-meta {
  margin-top: 12px;
  text-align: center !important;
  font-size: 11px;
}

.listing-mg-3-item .post-meta .comments, .listing-mg-3-item .post-meta .post-author {
  color: #fff;
  font-size: 11px;
}

.listing-mg-3-item .post-meta .comments {
  float: none;
  margin-left: 0;
}

.listing-mg-3-item .post-meta .post-author:after, .listing-mg-3-item .post-meta .time {
  color: rgba(255, 255, 255, 0.57);
}

.listing-mg-3-item .term-badges.floated {
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
  text-align: center;
  margin-bottom: 15px;
}

.listing-mg-3-item .term-badges.floated .term-badge a {
  margin: 0 2px;
}

.listing-mg-3-item.bsw-1 .title, .listing-mg-3-item.bsw-2 .title {
  font-size: 82%;
}

.listing-mg-3-item.bsw-1 .post-meta, .listing-mg-3-item.bsw-2 .post-meta {
  display: none;
}

.listing-mg-3-item.bsw-1 .item-content:before, .listing-mg-3-item.bsw-2 .item-content:before {
  padding-top: 65% !important;
}

.listing-mg-3-item.bsw-1 .format-icon, .listing-mg-3-item.bsw-2 .format-icon {
  display: none !important;
}

.listing-mg-3-item.bsw-1 .title a, .listing-mg-3-item.bsw-2 .title a {
  display: inline-block;
}

.listing-mg-3-item.bsw-100 .title, .listing-mg-3-item.bsw-150 .title, .listing-mg-3-item.bsw-200 .title, .listing-mg-3-item.bsw-250 .title, .listing-mg-3-item.bsw-300 .title {
  font-size: 82%;
}

.listing-mg-3-item.bsw-100 .post-meta, .listing-mg-3-item.bsw-150 .post-meta, .listing-mg-3-item.bsw-200 .post-meta, .listing-mg-3-item.bsw-250 .post-meta, .listing-mg-3-item.bsw-300 .post-meta {
  display: none;
}

.listing-mg-3-item.bsw-400 .item-content:before {
  padding-top: 88% !important;
}

.listing-mg-3-item.bsw-100 .item-content:before, .listing-mg-3-item.bsw-150 .item-content:before, .listing-mg-3-item.bsw-200 .item-content:before, .listing-mg-3-item.bsw-250 .item-content:before, .listing-mg-3-item.bsw-300 .item-content:before {
  padding-top: 68% !important;
}

.listing-mg-3-item.bsw-100 .format-icon, .listing-mg-3-item.bsw-150 .format-icon, .listing-mg-3-item.bsw-200 .format-icon, .listing-mg-3-item.bsw-250 .format-icon {
  display: none !important;
}

.listing-mg-3-item.bsw-100 .title a, .listing-mg-3-item.bsw-150 .title a, .listing-mg-3-item.bsw-200 .title a, .listing-mg-3-item.bsw-250 .title a, .listing-mg-3-item.bsw-300 .title a {
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .listing-mg-3-item .title {
    font-size: 82%;
  }
  .listing-mg-3-item .post-meta {
    display: none;
  }
  .listing-mg-3-item .item-content:before {
    padding-top: 65% !important;
  }
  .listing-mg-3-item .format-icon {
    display: none !important;
  }
  .listing-mg-3-item .title a {
    display: inline-block;
  }
}
.bs-shortcode.bs-modern-grid-listing-4 {
  margin-bottom: 10px;
}

.listing-mg-4-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 6px;
}

.listing-mg-4-item .item-content {
  position: relative;
  overflow: hidden;
}

.listing-mg-4-item .item-content:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 135%;
}

.listing-mg-4-item .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  -moz-transform-origin: 0 0;
}

.listing-mg-4-item .img-cont.b-error, .listing-mg-4-item .img-cont.b-loaded, body.bs-ll-d .listing-mg-4-item .img-cont {
  background-size: cover !important;
  animation: none;
}

.listing-mg-4-item .img-cont:after {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-mg-4-item:hover .img-cont {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.listing-mg-4-item .content-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px 20px;
  overflow: hidden;
  pointer-events: none;
}

.listing-mg-4-item .content-container a {
  pointer-events: all;
}

.listing-mg-4-item .title {
  margin: 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  text-align: center !important;
}

.listing-mg-4-item .title a {
  color: #fff;
}

.listing-mg-4-item .post-subtitle {
  text-align: center !important;
}

.listing-mg-4-item .post-meta {
  margin-top: 12px;
  text-align: center !important;
  font-size: 11px;
}

.listing-mg-4-item .post-meta .comments, .listing-mg-4-item .post-meta .post-author {
  color: #fff;
}

.listing-mg-4-item .post-meta .comments {
  float: none;
  margin-left: 0;
}

.listing-mg-4-item .post-meta .post-author:after, .listing-mg-4-item .post-meta .time {
  color: rgba(255, 255, 255, 0.57);
}

.listing-mg-4-item .term-badges.floated {
  position: absolute;
  right: auto;
  left: 0;
  top: 26px;
  bottom: auto;
  text-align: center;
  margin-bottom: 0;
  width: 100%;
  pointer-events: none;
}

.listing-mg-4-item .term-badges.floated .term-badge a {
  margin: 0 2px;
  pointer-events: all;
}

.listing-modern-grid-4.listing.columns-2 .listing-item {
  width: 50%;
}

.listing-modern-grid-4.listing.columns-2 .listing-item:nth-child(odd) {
  padding-left: 0;
  padding-right: 4px;
  clear: left;
}

.listing-modern-grid-4.listing.columns-2 .listing-item:nth-child(even) {
  padding-left: 4px;
  padding-right: 0;
  clear: none;
}

.listing-modern-grid-4.listing.columns-3 .listing-item:nth-child(3n-1) {
  padding: 0 2px;
}

.listing-modern-grid-4.listing.columns-3 .listing-item:nth-child(3n-2) {
  padding-right: 4px;
  clear: left;
}

.listing-modern-grid-4.listing.columns-3 .listing-item:nth-child(3n+3) {
  padding-left: 4px;
}

.listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(4n-3) {
  padding-left: 0;
  padding-right: 3px;
  clear: left;
}

.listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(4n+2) {
  padding-left: 1px;
  padding-right: 2px;
}

.listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(4n+3) {
  padding-left: 2px;
  padding-right: 1px;
}

.listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(4n+4) {
  padding-right: 0;
  padding-left: 3px;
}

.listing-modern-grid-4.bsw-6 .listing-mg-4-item .item-content:before {
  padding-top: 115%;
}

.listing-mg-4-item.bsw-100 .post-meta, .listing-mg-4-item.bsw-150 .post-meta, .listing-mg-4-item.bsw-200 .post-meta, .listing-mg-4-item.bsw-250 .post-meta {
  display: none;
}

.listing-mg-4-item.bsw-100 .item-content:before, .listing-mg-4-item.bsw-150 .item-content:before, .listing-mg-4-item.bsw-200 .item-content:before, .listing-mg-4-item.bsw-250 .item-content:before {
  padding-top: 125% !important;
}

.listing-mg-4-item.bsw-100 .format-icon, .listing-mg-4-item.bsw-150 .format-icon, .listing-mg-4-item.bsw-200 .format-icon, .listing-mg-4-item.bsw-250 .format-icon {
  display: none !important;
}

.listing-mg-4-item.bsw-100 .title a, .listing-mg-4-item.bsw-150 .title a, .listing-mg-4-item.bsw-200 .title a, .listing-mg-4-item.bsw-250 .title a {
  font-size: 82%;
  display: inline-block;
}

@media only screen and (max-width: 900px) {
  .listing-modern-grid-4.listing.columns-4 .listing-item {
    width: 33.3333%;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n-1) {
    padding: 0 2px;
    clear: none !important;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n-2) {
    padding-left: 0;
    padding-right: 4px;
    clear: left !important;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n+3) {
    padding-left: 4px;
    padding-right: 0;
    clear: none !important;
  }
}
@media only screen and (max-width: 680px) {
  .listing-modern-grid-4.listing.columns-4 .listing-item {
    width: 33.333%;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n-1) {
    padding: 0 2px;
    clear: none !important;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n-2) {
    padding-right: 4px;
    padding-left: 0;
    clear: left !important;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n+3) {
    padding-left: 4px;
    padding-right: 0;
    clear: none !important;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-4.listing.columns-3 .listing-item, .listing-modern-grid-4.listing.columns-4 .listing-item {
    width: 50%;
  }
  .listing-modern-grid-4.listing.columns-3 .listing-item:nth-child(odd), .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(odd) {
    padding-left: 0;
    padding-right: 4px;
    clear: left !important;
  }
  .listing-modern-grid-4.listing.columns-3 .listing-item:nth-child(even), .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(even) {
    padding-left: 4px;
    padding-right: 0;
    clear: none !important;
  }
}
@media only screen and (max-width: 300px) {
  .listing-modern-grid-4 .listing-mg-4-item .post-meta {
    display: none;
  }
}
@media only screen and (max-width: 380px) {
  .listing-mg-4-item .item-content:before {
    padding-top: 115% !important;
  }
  .listing-mg-4-item .format-icon {
    display: none !important;
  }
  .listing-mg-4-item .title a {
    font-size: 82%;
    display: inline-block;
  }
}
.listing-modern-grid-5 .mg-col {
  position: relative;
  float: left;
}

.listing-modern-grid-5 .mg-col-1 {
  width: 55%;
  left: 22.5%;
  padding: 0 10px;
}

.listing-modern-grid-5 .mg-col-2 {
  width: 22.5%;
  left: -55%;
  padding: 0 20px 0 0;
}

.listing-modern-grid-5 .mg-col-3 {
  width: 22.5%;
  padding: 0 0 0 20px;
}

.listing-modern-grid-5.l-2 .mg-col-1 {
  width: 100%;
  left: auto;
  margin: 0 0 20px;
  padding: 0;
}

.listing-modern-grid-5.l-2 .mg-col-2, .listing-modern-grid-5.l-2 .mg-col-3 {
  width: 50%;
  left: auto;
  padding: 0 40px;
}

.listing-modern-grid-5.bsw-2 .mg-col-1, .listing-modern-grid-5.bsw-3 .mg-col-1, .listing-modern-grid-5.bsw-4 .mg-col-1, .listing-modern-grid-5.bsw-5 .mg-col-1, .listing-modern-grid-5.bsw-6 .mg-col-1 {
  margin-bottom: 10px;
}

.listing-modern-grid-5.bsw-2 .mg-col-2, .listing-modern-grid-5.bsw-2 .mg-col-3, .listing-modern-grid-5.bsw-3 .mg-col-2, .listing-modern-grid-5.bsw-3 .mg-col-3, .listing-modern-grid-5.bsw-4 .mg-col-2, .listing-modern-grid-5.bsw-4 .mg-col-3, .listing-modern-grid-5.bsw-5 .mg-col-2, .listing-modern-grid-5.bsw-5 .mg-col-3, .listing-modern-grid-5.bsw-6 .mg-col-2, .listing-modern-grid-5.bsw-6 .mg-col-3 {
  padding: 0 20px;
}

.listing-modern-grid-5.bsw-2 .listing-mg-5-item-small .title, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-small .title, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-small .title, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-small .title, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-small .title {
  padding: 0;
  line-height: 1.3;
}

.listing-modern-grid-5.bsw-2 .listing-mg-5-item-small .title a, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-small .title a, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-small .title a, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-small .title a, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-small .title a {
  font-size: 90%;
}

.listing-modern-grid-5.bsw-1 .mg-col-3, .listing-modern-grid-5.bsw-2 .mg-col-3, .listing-modern-grid-5.bsw-3 .mg-col-3, .listing-modern-grid-5.bsw-4 .mg-col-3, .listing-modern-grid-5.bsw-5 .mg-col-3 {
  padding: 0 0 0 10px;
}

.listing-modern-grid-5.bsw-1 .mg-col-2, .listing-modern-grid-5.bsw-2 .mg-col-2, .listing-modern-grid-5.bsw-3 .mg-col-2, .listing-modern-grid-5.bsw-4 .mg-col-2, .listing-modern-grid-5.bsw-5 .mg-col-2 {
  padding: 0 10px 0 0;
}

.listing-modern-grid-5 .term-badges.floated {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  pointer-events: none;
  z-index: 1;
  line-height: 0;
  text-align: center;
}

.listing-modern-grid-5 .term-badges.floated a {
  pointer-events: all;
}

.listing-mg-5-item .item-content {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.listing-mg-5-item .item-content:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 70.29%;
}

.listing-mg-5-item .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  -moz-transform-origin: 0 0;
}

.listing-mg-5-item .img-cont.b-error, .listing-mg-5-item .img-cont.b-loaded, body.bs-ll-d .listing-mg-5-item .img-cont {
  background-size: cover !important;
  animation: none;
}

.listing-mg-5-item .title a {
  color: inherit;
}

.listing-mg-5-item .title {
  line-height: 1.4;
}

.listing-mg-5-item .content-container {
  padding: 0 !important;
  background: 0 0 !important;
}

.listing-mg-5-item-big .img-cont {
  top: -1px;
  left: -2px;
  right: -2px;
  bottom: -1px;
}

.listing-mg-5-item-big .item-content:before {
  padding-top: 66.29%;
}

.listing-mg-5-item-big:hover .img-cont {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1) rotate(0.02deg);
}

.listing-mg-5-item-big .content-container {
  position: relative;
  padding: 20px !important;
  overflow: hidden;
  pointer-events: none;
  margin: -80px 35px 0;
  z-index: 1;
  background: #fff !important;
  min-height: 100px;
}

.listing-mg-5-item-big .title {
  margin: 0 0 6px;
}

.listing-mg-5-item-big .post-subtitle {
  color: #2d2d2d !important;
  text-shadow: none;
}

.listing-mg-5-item-big .post-meta {
  text-align: center;
}

.listing-mg-5-item-big .post-meta .comments {
  float: none;
  margin-left: 0;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-big .content-container, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-big .content-container, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-big .content-container, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-big .content-container, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-big .content-container {
  padding: 20px 15px !important;
  overflow: hidden;
  pointer-events: none;
  margin: -80px 18px 0;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-7 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-8 .listing-mg-5-item-big .title {
  line-height: 1;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-7 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-8 .listing-mg-5-item-big .title a {
  font-size: 71%;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-7 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-8 .listing-mg-5-item-big .item-content:before {
  padding-top: 54%;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-big .item-content:before {
  padding-top: 62%;
}

.listing-mg-5-item-small .item-content:before {
  padding-top: 68%;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-1 .listing-mg-5-item-small .term-badges.floated, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-small .term-badges.floated, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-small .term-badges.floated, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-small .term-badges.floated, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-small .term-badges.floated, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-small .term-badges.floated {
  display: none;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-small .item-content:before, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-small .item-content:before, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-small .item-content:before, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-small .item-content:before, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-small .item-content:before, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-small .item-content:before {
  padding-top: 70%;
}

.listing-mg-5-item-small {
  margin-bottom: 16px;
}

.mg-col .listing-mg-5-item-small:last-child {
  margin-bottom: 0;
}

.listing-mg-5-item-small .title {
  padding: 0 13px;
  margin: 10px 0 0;
  min-height: 38px;
}

@media only screen and (max-width: 780px) {
  .listing-modern-grid-5 .mg-col-1 {
    width: 100%;
    left: auto;
    margin: 0 0 20px;
    padding: 0;
  }
  .listing-modern-grid-5 .mg-col-2, .listing-modern-grid-5 .mg-col-3 {
    width: 50%;
    left: auto;
    padding: 0 40px;
  }
  .listing-modern-grid-5 .listing-mg-5-item-big .title {
    line-height: 1;
  }
  .listing-modern-grid-5 .listing-mg-5-item-big .title a {
    font-size: 71%;
  }
  .listing-modern-grid-5 .listing-mg-5-item-big .item-content:before {
    padding-top: 54%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-5 .listing-mg-5-item-small .format-icon {
    display: none;
  }
  .listing-modern-grid-5 .mg-col-1 {
    margin-bottom: 10px;
  }
  .listing-modern-grid-5 .mg-col-2, .listing-modern-grid-5 .mg-col-3 {
    padding: 0 20px;
  }
  .listing-modern-grid-5 .listing-mg-5-item-small .title {
    padding: 0;
    line-height: 1.3;
  }
  .listing-modern-grid-5 .listing-mg-5-item-small .title a {
    font-size: 90%;
  }
}
@media only screen and (max-width: 560px) {
  .listing-modern-grid-5 .listing-mg-5-item-big .item-content:before {
    padding-top: 62%;
  }
  .listing-modern-grid-5 .listing-mg-5-item-small .term-badges.floated {
    display: none;
  }
  .listing-modern-grid-5 .listing-mg-5-item-small .item-content:before {
    padding-top: 70%;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-5 .mg-col-3 {
    padding: 0 0 0 10px !important;
  }
  .listing-modern-grid-5 .mg-col-2 {
    padding: 0 10px 0 0 !important;
  }
}
@media only screen and (max-width: 450px) {
  .listing-modern-grid-5 .listing-mg-5-item-big .content-container {
    padding: 20px 15px !important;
    overflow: hidden;
    pointer-events: none;
    margin: -80px 18px 0;
  }
}
.listing-mg-6-item {
  position: relative;
  overflow: hidden;
}

.listing-mg-6-item + .listing-mg-6-item {
  margin-top: 2px;
}

.listing-mg-6-item:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 64%;
}

.listing-mg-6-item .item-content, .listing-modern-grid .bs-slider-item .item-content {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-mg-6-item .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  -moz-transform-origin: 0 0;
}

.listing-mg-6-item .img-cont.b-error, .listing-mg-6-item .img-cont.b-loaded, body.bs-ll-d .listing-mg-6-item .img-cont {
  background-size: cover !important;
}

.listing-mg-6-item .img-cont:after {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-mg-6-item:hover .img-cont {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.listing-mg-6-item .content-container {
  position: absolute;
  bottom: 15px;
  left: 20px;
  right: 20px;
  padding: 0;
  max-height: 75%;
  overflow: hidden;
  pointer-events: none;
}

.listing-mg-6-item .content-container a {
  pointer-events: all;
}

.listing-mg-6-item .title {
  margin: 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
}

.listing-mg-6-item .title a {
  color: inherit;
}

.listing-mg-6-item .post-meta {
  margin-top: 3px;
}

.listing-mg-6-item .post-meta .post-author {
  color: #fff;
}

.listing-mg-6-item .post-meta .comments {
  color: #fff;
  float: right;
  margin-right: 0;
  margin-left: 15px;
}

.listing-mg-6-item .post-meta .rating + .comments {
  float: none;
  margin-left: 0;
}

.listing-mg-6-item .post-meta .post-author:after, .listing-mg-6-item .post-meta .time {
  color: rgba(255, 255, 255, 0.57);
}

.listing-modern-grid-6 .mg-col-1 {
  width: 50%;
  float: left;
  padding-right: 1px;
}

.listing-modern-grid-6 .mg-col-2 {
  width: 50%;
  float: left;
  padding-left: 1px;
}

.infinity > .listing-modern-grid-6 + .listing-modern-grid-6, .more_btn > .listing-modern-grid-6 + .listing-modern-grid-6 {
  margin-top: 4px;
}

.listing-modern-grid-6.bsw-1 .content-container, .listing-modern-grid-6.bsw-2 .content-container, .listing-modern-grid-6.bsw-3 .content-container, .listing-modern-grid-6.bsw-4 .content-container, .listing-modern-grid-6.bsw-5 .content-container, .listing-modern-grid-6.bsw-6 .content-container, .listing-modern-grid-6.bsw-7 .content-container, .listing-modern-grid-6.bsw-8 .content-container {
  max-height: 50%;
}

.listing-modern-grid-6.bsw-1 .listing-item .title, .listing-modern-grid-6.bsw-2 .listing-item .title, .listing-modern-grid-6.bsw-3 .listing-item .title, .listing-modern-grid-6.bsw-4 .listing-item .title, .listing-modern-grid-6.bsw-5 .listing-item .title, .listing-modern-grid-6.bsw-6 .listing-item .title, .listing-modern-grid-6.bsw-7 .listing-item .title, .listing-modern-grid-6.bsw-8 .listing-item .title {
  line-height: 1;
}

.listing-modern-grid-6.bsw-1 .listing-item .title a, .listing-modern-grid-6.bsw-2 .listing-item .title a, .listing-modern-grid-6.bsw-3 .listing-item .title a, .listing-modern-grid-6.bsw-4 .listing-item .title a, .listing-modern-grid-6.bsw-5 .listing-item .title a, .listing-modern-grid-6.bsw-6 .listing-item .title a, .listing-modern-grid-6.bsw-7 .listing-item .title a, .listing-modern-grid-6.bsw-8 .listing-item .title a {
  font-size: 80%;
}

.listing-modern-grid-6.bsw-1 .mg-col-1, .listing-modern-grid-6.bsw-2 .mg-col-1, .listing-modern-grid-6.bsw-3 .mg-col-1, .listing-modern-grid-6.bsw-4 .mg-col-1, .listing-modern-grid-6.bsw-5 .mg-col-1 {
  width: 100%;
  padding: 0;
}

.listing-modern-grid-6.bsw-1 .mg-col-2, .listing-modern-grid-6.bsw-2 .mg-col-2, .listing-modern-grid-6.bsw-3 .mg-col-2, .listing-modern-grid-6.bsw-4 .mg-col-2, .listing-modern-grid-6.bsw-5 .mg-col-2 {
  width: 100%;
  padding: 4px 0 0 0;
}

.listing-modern-grid-6.bsw-1 .format-icon, .listing-modern-grid-6.bsw-2 .format-icon, .listing-modern-grid-6.bsw-3 .format-icon, .listing-modern-grid-6.bsw-4 .format-icon, .listing-modern-grid-6.bsw-5 .format-icon {
  display: none;
}

.listing-modern-grid-6.bsw-1 .listing-mg-6-item:before, .listing-modern-grid-6.bsw-2 .listing-mg-6-item:before, .listing-modern-grid-6.bsw-3 .listing-mg-6-item:before, .listing-modern-grid-6.bsw-4 .listing-mg-6-item:before, .listing-modern-grid-6.bsw-5 .listing-mg-6-item:before {
  padding-top: 60%;
}

.slider-overlay-simple.listing-modern-grid-6.bsw-1 .listing-mg-item .content-container, .slider-overlay-simple.listing-modern-grid-6.bsw-2 .listing-mg-item .content-container, .slider-overlay-simple.listing-modern-grid-6.bsw-3 .listing-mg-item .content-container, .slider-overlay-simple.listing-modern-grid-6.bsw-4 .listing-mg-item .content-container, .slider-overlay-simple.listing-modern-grid-6.bsw-5 .listing-mg-item .content-container {
  max-height: 60%;
  padding: 18px 15px 18px;
}

@media only screen and (max-width: 780px) {
  .listing-modern-grid-6 .content-container {
    max-height: 50%;
  }
  .listing-modern-grid-6 .listing-item .title {
    line-height: 1;
  }
  .listing-modern-grid-6 .listing-item .title a {
    font-size: 80%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-6 .listing-mg-6-item .post-meta {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-6 .format-icon {
    display: none;
  }
  .listing-modern-grid-6 .mg-col-1 {
    width: 100%;
    padding: 0;
  }
  .listing-modern-grid-6 .mg-col-2 {
    width: 100%;
    padding: 4px 0 0 0;
  }
  .listing-modern-grid-6 .listing-mg-6-item:before {
    padding-top: 60%;
  }
  .slider-overlay-simple.listing-modern-grid-6 .listing-mg-item .content-container {
    max-height: 60%;
    padding: 18px 15px 18px;
  }
}
.listing-mg-7-item:before {
  padding-top: 60%;
}

.listing-modern-grid-7 .mg-row-1 {
  padding-bottom: 2px;
}

.listing-modern-grid-7 .mg-row-1 .mg-col-1 {
  width: 50%;
  float: left;
  padding-right: 1px;
}

.listing-modern-grid-7 .mg-row-1 .mg-col-2 {
  width: 50%;
  float: left;
  padding-left: 1px;
}

.listing-modern-grid-7 .mg-row-2 .mg-col {
  width: 33.333%;
  float: left;
}

.listing-modern-grid-7 .mg-row-2 .mg-col-1 {
  padding-right: 1.5px;
}

.listing-modern-grid-7 .mg-row-2 .mg-col-2 {
  padding-left: 0.75px;
  padding-right: 0.75px;
}

.listing-modern-grid-7 .mg-row-2 .mg-col-3 {
  padding-left: 1.5px;
}

.listing-modern-grid-7 .mg-row-2 .listing-mg-7-item {
  width: 100%;
}

.listing-modern-grid-7 .mg-row-2 .listing-mg-7-item:before {
  padding-top: 60%;
}

.listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .post-subtitle, .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .title {
  font-size: 79%;
}

.listing-modern-grid-7.bsw-10 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-2 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-3 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-4 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-5 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-6 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-7 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-8 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-9 .mg-row-1 .listing-mg-7-item .title {
  font-size: 92%;
}

.listing-modern-grid-7.bsw-10 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-2 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-3 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-4 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-5 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-6 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-7 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-8 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-9 .mg-row-2 .listing-mg-7-item .title {
  font-size: 72%;
}

.listing-modern-grid-7.bsw-2 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-2 .listing-mg-7-item.listing-mg-item:hover .format-icon, .listing-modern-grid-7.bsw-3 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-3 .listing-mg-7-item.listing-mg-item:hover .format-icon, .listing-modern-grid-7.bsw-4 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-4 .listing-mg-7-item.listing-mg-item:hover .format-icon, .listing-modern-grid-7.bsw-5 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-5 .listing-mg-7-item.listing-mg-item:hover .format-icon, .listing-modern-grid-7.bsw-6 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-6 .listing-mg-7-item.listing-mg-item:hover .format-icon, .listing-modern-grid-7.bsw-7 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-7 .listing-mg-7-item.listing-mg-item:hover .format-icon {
  right: 20px !important;
  top: 20px !important;
  transform: none;
  margin: 0;
}

.listing-modern-grid-7.bsw-1 .mg-col, .listing-modern-grid-7.bsw-1 .mg-row, .listing-modern-grid-7.bsw-2 .mg-col, .listing-modern-grid-7.bsw-2 .mg-row, .listing-modern-grid-7.bsw-3 .mg-col, .listing-modern-grid-7.bsw-3 .mg-row, .listing-modern-grid-7.bsw-4 .mg-col, .listing-modern-grid-7.bsw-4 .mg-row, .listing-modern-grid-7.bsw-5 .mg-col, .listing-modern-grid-7.bsw-5 .mg-row, .listing-modern-grid-7.bsw-6 .mg-col, .listing-modern-grid-7.bsw-6 .mg-row, .listing-modern-grid-7.bsw-7 .mg-col, .listing-modern-grid-7.bsw-7 .mg-row {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  float: none !important;
}

.listing-modern-grid-7.bsw-1 .listing-item, .listing-modern-grid-7.bsw-2 .listing-item, .listing-modern-grid-7.bsw-3 .listing-item, .listing-modern-grid-7.bsw-4 .listing-item, .listing-modern-grid-7.bsw-5 .listing-item, .listing-modern-grid-7.bsw-6 .listing-item, .listing-modern-grid-7.bsw-7 .listing-item {
  margin-bottom: 2px;
}

.listing-modern-grid-7.bsw-4 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-5 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-6 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-7 .listing-mg-7-item:before {
  padding-top: 50% !important;
}

.listing-modern-grid-7.bsw-1 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-2 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-3 .listing-mg-7-item:before {
  padding-top: 72% !important;
}

.listing-modern-grid-7.bsw-1 .listing-mg-7-item .format-icon, .listing-modern-grid-7.bsw-2 .listing-mg-7-item .format-icon, .listing-modern-grid-7.bsw-3 .listing-mg-7-item .format-icon {
  display: none;
}

.listing-modern-grid-7.bsw-1 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-2 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-3 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-4 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-5 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-6 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-7 .listing-mg-7-item:before {
  padding-top: 24% !important;
}

.listing-modern-grid-7.bsw-1 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-2 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-3 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-4 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-5 .listing-mg-7-item:before {
  padding-top: 40% !important;
}

.listing-modern-grid-7.bsw-2 .listing-mg-7-item .post-meta, .listing-modern-grid-7.bsw-3 .listing-mg-7-item .post-meta, .listing-modern-grid-7.bsw-4 .listing-mg-7-item .post-meta, .listing-modern-grid-7.bsw-5 .listing-mg-7-item .post-meta {
  display: none;
}

.listing-modern-grid-7.bsw-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-3 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-4 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-5 .listing-mg-7-item .title {
  font-size: 62% !important;
}

.listing-modern-grid-7.bsw-2 .listing-mg-7-item .post-subtitle, .listing-modern-grid-7.bsw-3 .listing-mg-7-item .post-subtitle, .listing-modern-grid-7.bsw-4 .listing-mg-7-item .post-subtitle, .listing-modern-grid-7.bsw-5 .listing-mg-7-item .post-subtitle {
  font-size: 60% !important;
}

.listing-modern-grid-7.bsw-2 .listing-mg-7-item .content-container, .listing-modern-grid-7.bsw-3 .listing-mg-7-item .content-container, .listing-modern-grid-7.bsw-4 .listing-mg-7-item .content-container, .listing-modern-grid-7.bsw-5 .listing-mg-7-item .content-container {
  max-height: 43% !important;
}

@media only screen and (max-width: 980px) {
  .listing-modern-grid-7 .mg-row-1 .listing-mg-7-item .title {
    font-size: 92%;
  }
  .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .title {
    font-size: 72%;
  }
}
@media only screen and (max-width: 780px) {
  .listing-modern-grid-7 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7 .listing-mg-7-item.listing-mg-item:hover .format-icon {
    right: 20px !important;
    top: 20px !important;
    transform: none;
    margin: 0;
  }
  .listing-modern-grid-7 .listing-mg-7-item.listing-mg-7-item:before {
    padding-top: 70% !important;
  }
  .listing-modern-grid-7 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .title {
    font-size: 78% !important;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-7 .listing-item {
    margin-bottom: 2px;
  }
  .listing-modern-grid-7 .mg-col {
    width: 100% !important;
    float: none !important;
    padding: 0 !important;
  }
  .listing-modern-grid-7 .mg-row {
    margin: 0 !important;
    padding: 0 !important;
  }
  .listing-modern-grid-7 .post-meta {
    display: none;
  }
  .listing-modern-grid-7 .term-badges.floated {
    display: block;
  }
  .listing-modern-grid-7 .listing-mg-7-item.listing-mg-7-item:before {
    padding-top: 40% !important;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-7 .mg-row-1 .listing-mg-7-item .title {
    font-size: 82%;
  }
  .listing-modern-grid-7 .mg-row-1 .listing-mg-7-item.listing-item-2 .title, .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .title {
    font-size: 62%;
  }
  .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .post-subtitle {
    font-size: 60%;
  }
  .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .content-container {
    max-height: 43%;
  }
}
.listing-mg-8-item.listing-item-2 .title, .listing-mg-8-item.listing-item-3 .title, .listing-mg-8-item.listing-item-4 .title, .listing-mg-8-item.listing-item-5 .post-subtitle, .listing-mg-8-item.listing-item-5 .title {
  font-size: 72%;
}

.listing-mg-8-item.listing-item-2 .title {
  font-size: 80%;
}

.listing-modern-grid-8 .mg-col-1 {
  width: 46%;
  float: left;
  padding-right: 1px;
  overflow: hidden;
}

.listing-modern-grid-8 .mg-col-2 {
  width: 32%;
  float: left;
  padding-left: 1px;
  padding-right: 1px;
  overflow: hidden;
}

.listing-modern-grid-8 .mg-col-3 {
  width: 22%;
  float: left;
  padding-left: 1px;
  overflow: hidden;
}

.listing-modern-grid-8 .mg-row-1 {
  margin: 0 0 2px;
  overflow: hidden;
}

.listing-modern-grid-8 .item-3-cont, .listing-modern-grid-8 .item-4-cont {
  width: 50%;
  float: left;
  overflow: hidden;
}

.listing-modern-grid-8 .item-3-cont {
  padding-right: 1px;
}

.listing-modern-grid-8 .item-4-cont {
  padding-left: 1px;
}

.listing-mg-8-item.listing-item-1:before {
  padding-top: 71.779%;
}

.listing-mg-8-item.listing-item-2:before {
  padding-top: 53.005%;
}

.listing-mg-8-item.listing-item-3:before, .listing-mg-8-item.listing-item-4:before {
  padding-top: 100.55%;
}

.listing-mg-8-item.listing-item-5:before {
  padding-top: 150.394%;
}

.listing-mg-8-item.listing-mg-item .format-icon, .listing-mg-8-item.listing-mg-item:hover .format-icon {
  right: 20px !important;
  top: 20px !important;
  transform: none;
  margin: 0;
}

.listing-modern-grid-8.l-1 .mg-col-3, .listing-modern-grid-8.l-2 .mg-col-3, .listing-modern-grid-8.l-3 .mg-col-3, .listing-modern-grid-8.l-4 .mg-col-3 {
  width: 100%;
  padding: 2px 0 0;
}

.listing-modern-grid-8.l-1 .mg-col-1, .listing-modern-grid-8.l-2 .mg-col-1, .listing-modern-grid-8.l-3 .mg-col-1, .listing-modern-grid-8.l-4 .mg-col-1 {
  width: 56%;
}

.listing-modern-grid-8.l-1 .mg-col-2, .listing-modern-grid-8.l-2 .mg-col-2, .listing-modern-grid-8.l-3 .mg-col-2, .listing-modern-grid-8.l-4 .mg-col-2 {
  width: 44%;
  padding: 0 0 0 1px;
}

.listing-modern-grid-8.l-1 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2:before {
  padding-top: 47.29%;
}

.listing-modern-grid-8.l-1 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-1 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-4:before {
  padding-top: 87.68%;
}

.listing-modern-grid-8.l-1 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-5:before {
  padding-top: 18%;
}

.listing-modern-grid-8.l-2 .listing-mg-8-item:before, .listing-modern-grid-8.l-3 .listing-mg-8-item:before, .listing-modern-grid-8.l-4 .listing-mg-8-item:before {
  padding-top: 89%;
}

.listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-5:before {
  padding-top: 18%;
}

.listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2:before {
  padding-top: 65.5%;
}

.listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-4:before {
  padding-top: 95.055%;
}

.listing-modern-grid-8.l-2 .listing-item-1 .title, .listing-modern-grid-8.l-3 .listing-item-1 .title, .listing-modern-grid-8.l-4 .listing-item-1 .title {
  font-size: 80%;
}

.listing-modern-grid-8.l-2 .listing-item-2 .title, .listing-modern-grid-8.l-2 .listing-item-3 .title, .listing-modern-grid-8.l-2 .listing-item-4 .title, .listing-modern-grid-8.l-3 .listing-item-2 .title, .listing-modern-grid-8.l-3 .listing-item-3 .title, .listing-modern-grid-8.l-3 .listing-item-4 .title, .listing-modern-grid-8.l-4 .listing-item-2 .title, .listing-modern-grid-8.l-4 .listing-item-3 .title, .listing-modern-grid-8.l-4 .listing-item-4 .title {
  font-size: 62%;
}

.listing-modern-grid-8.l-2 .listing-item-3 .term-badges, .listing-modern-grid-8.l-2 .listing-item-4 .term-badges, .listing-modern-grid-8.l-3 .listing-item-3 .term-badges, .listing-modern-grid-8.l-3 .listing-item-4 .term-badges, .listing-modern-grid-8.l-4 .listing-item-3 .term-badges, .listing-modern-grid-8.l-4 .listing-item-4 .term-badges {
  display: none;
}

.listing-modern-grid-8.l-2 .listing-mg-item .content-container, .listing-modern-grid-8.l-3 .listing-mg-item .content-container, .listing-modern-grid-8.l-4 .listing-mg-item .content-container {
  left: 15px;
  right: 15px;
  bottom: 10px;
  max-height: 50%;
}

.listing-modern-grid-8.l-3 .mg-col-1, .listing-modern-grid-8.l-4 .mg-col-1 {
  width: 100%;
  padding: 0;
}

.listing-modern-grid-8.l-3 .mg-col-2, .listing-modern-grid-8.l-4 .mg-col-2 {
  width: 100%;
  padding: 2px 0 0 0;
}

.listing-modern-grid-8.l-3 .mg-row-2, .listing-modern-grid-8.l-4 .mg-row-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing-modern-grid-8.l-3 .listing-item-1:before, .listing-modern-grid-8.l-4 .listing-item-1:before {
  padding-top: 46%;
}

.listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-3:before {
  padding-top: 65.5%;
}

.listing-modern-grid-8.l-3 .listing-item-2 .title, .listing-modern-grid-8.l-4 .listing-item-2 .title {
  font-size: 72%;
}

.listing-modern-grid-8.l-3 .listing-item-2 .post-meta, .listing-modern-grid-8.l-3 .mg-col-2 .post-meta, .listing-modern-grid-8.l-3 .mg-col-2 .term-badges.floated, .listing-modern-grid-8.l-3 .mg-col-3 .post-meta, .listing-modern-grid-8.l-3 .mg-col-3 .term-badges.floated, .listing-modern-grid-8.l-4 .listing-item-2 .post-meta, .listing-modern-grid-8.l-4 .mg-col-2 .post-meta, .listing-modern-grid-8.l-4 .mg-col-2 .term-badges.floated, .listing-modern-grid-8.l-4 .mg-col-3 .post-meta, .listing-modern-grid-8.l-4 .mg-col-3 .term-badges.floated {
  display: none;
}

.listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-1:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-1:before {
  padding-top: 50%;
}

.listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-2 .term-badges.floated, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2 .term-badges.floated {
  display: none;
}

.listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-5:before {
  padding-top: 36%;
}

.listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-4:before {
  padding-top: 79.5%;
}

.listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2:before {
  padding-top: 50.5%;
}

.listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-1:before {
  padding-top: 55%;
}

.listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-1 .post-meta {
  display: none;
}

.listing-modern-grid-8.l-4 .listing-mg-item .content-container {
  left: 12px;
  right: 12px;
  bottom: 12px;
}

.listing-modern-grid-8.l-4 .listing-mg-item.listing-item-2 .content-container, .listing-modern-grid-8.l-4 .listing-mg-item.listing-item-3 .content-container {
  max-height: 40%;
}

.slider-overlay-simple.listing-modern-grid-8.l-4 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-8.l-4 .listing-mg-item.listing-item-3 .content-container {
  max-height: 60%;
  padding: 12px 12px 10px;
}

.listing-mg-8-item.listing-mg-item.l-4 .format-icon, .listing-mg-8-item.listing-mg-item.l-4:hover .format-icon {
  right: 10px !important;
  top: 10px !important;
}

@media only screen and (max-width: 980px) {
  .listing-modern-grid-8 .mg-col-3 {
    width: 100%;
    padding: 2px 0 0;
  }
  .listing-modern-grid-8 .mg-col-1 {
    width: 56%;
  }
  .listing-modern-grid-8 .mg-col-2 {
    width: 44%;
    padding: 0 0 0 1px;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2:before {
    padding-top: 47.29%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8 .listing-mg-8-item.listing-item-4:before {
    padding-top: 87.68%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-5:before {
    padding-top: 18%;
  }
}
@media only screen and (max-width: 780px) {
  .listing-modern-grid-8 .listing-mg-8-item:before {
    padding-top: 89%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-5:before {
    padding-top: 18%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2:before {
    padding-top: 65.5%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8 .listing-mg-8-item.listing-item-4:before {
    padding-top: 95.055%;
  }
  .listing-modern-grid-8 .listing-item-1 .title {
    font-size: 80%;
  }
  .listing-modern-grid-8 .listing-item-2 .title, .listing-modern-grid-8 .listing-item-3 .title {
    font-size: 72%;
  }
  .listing-modern-grid-8 .listing-item-3 .term-badges, .listing-modern-grid-8 .listing-item-4 .term-badges {
    display: none;
  }
  .listing-modern-grid-8 .listing-mg-item .content-container {
    left: 15px;
    right: 15px;
    bottom: 10px;
    max-height: 50%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-8 .mg-col-1 {
    width: 100% !important;
    padding: 0 !important;
  }
  .listing-modern-grid-8 .mg-col-2 {
    width: 100% !important;
    padding: 2px 0 0 0 !important;
  }
  .listing-modern-grid-8 .mg-row-2 {
    width: 100% !important;
    padding: 0 !important;
    float: none !important;
  }
  .listing-modern-grid-8 .listing-item-1:before {
    padding-top: 46% !important;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8 .listing-mg-8-item.listing-item-3:before {
    padding-top: 65.5% !important;
  }
  .listing-modern-grid-8 .listing-item-2 .title, .listing-modern-grid-8 .listing-item-3 .title {
    font-size: 72% !important;
  }
  .listing-modern-grid-8 .listing-item-2 .post-meta, .listing-modern-grid-8 .mg-col-2 .post-meta, .listing-modern-grid-8 .mg-col-2 .term-badges.floated, .listing-modern-grid-8 .mg-col-3 .post-meta, .listing-modern-grid-8 .mg-col-3 .term-badges.floated {
    display: none !important;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-1:before {
    padding-top: 50% !important;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2 .term-badges.floated {
    display: none !important;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8 .listing-mg-8-item.listing-item-5:before {
    padding-top: 36% !important;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8 .listing-mg-8-item.listing-item-4:before {
    padding-top: 79.5% !important;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2:before {
    padding-top: 50.5%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-1:before {
    padding-top: 55%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-1 .post-meta {
    display: none;
  }
  .listing-modern-grid-8 .listing-item-2 .title, .listing-modern-grid-8 .listing-item-3 .title {
    font-size: 64%;
  }
  .listing-modern-grid-8 .listing-mg-item .content-container {
    left: 12px;
    right: 12px;
    bottom: 12px;
  }
  .listing-modern-grid-8 .listing-mg-item.listing-item-2 .content-container, .listing-modern-grid-8 .listing-mg-item.listing-item-3 .content-container {
    max-height: 40%;
  }
  .slider-overlay-simple.listing-modern-grid-8 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-8 .listing-mg-item.listing-item-3 .content-container {
    max-height: 60%;
    padding: 12px 12px 10px;
  }
  .listing-mg-8-item.listing-mg-item .format-icon, .listing-mg-8-item.listing-mg-item:hover .format-icon {
    right: 10px !important;
    top: 10px !important;
  }
}
.listing-mg-9-item.listing-item-2 .title, .listing-mg-9-item.listing-item-3 .title, .listing-mg-9-item.listing-item-4 .title, .listing-mg-9-item.listing-item-5 .title, .listing-mg-9-item.listing-item-6 .title, .listing-mg-9-item.listing-item-7 .title {
  font-size: 72%;
}

.listing-mg-9-item.listing-mg-item .format-icon, .listing-mg-9-item.listing-mg-item:hover .format-icon {
  right: 20px !important;
  top: 20px !important;
  transform: none;
  margin: 0;
}

.listing-mg-9-item:before {
  padding-top: 93.76%;
}

.listing-modern-grid-9 .mg-col-3 .listing-mg-9-item:before {
  padding-top: 93.97%;
}

.listing-mg-9-item.listing-item-1:before {
  padding-top: 74.545%;
}

.listing-modern-grid-9 .mg-col-1 {
  width: 45.6%;
  float: left;
  padding-right: 1px;
}

.listing-modern-grid-9 .mg-col-2 {
  width: 36.35%;
  float: left;
  padding-left: 1px;
  padding-right: 1px;
}

.listing-modern-grid-9 .mg-col-3 {
  width: 18.05%;
  float: left;
  padding-left: 1px;
}

.listing-modern-grid-9 .item-2-cont, .listing-modern-grid-9 .item-3-cont, .listing-modern-grid-9 .item-4-cont, .listing-modern-grid-9 .item-5-cont {
  width: 50%;
  float: left;
}

.listing-modern-grid-9 .item-2-cont, .listing-modern-grid-9 .item-4-cont {
  padding-right: 1px;
}

.listing-modern-grid-9 .item-3-cont, .listing-modern-grid-9 .item-5-cont {
  padding-left: 1px;
}

.listing-modern-grid-9 .mg-row-1 {
  margin-bottom: 2px;
}

.listing-modern-grid-9.l-1 .mg-col-1, .listing-modern-grid-9.l-2 .mg-col-1, .listing-modern-grid-9.l-3 .mg-col-1, .listing-modern-grid-9.l-4 .mg-col-1, .listing-modern-grid-9.l-5 .mg-col-1 {
  width: 53%;
}

.listing-modern-grid-9.l-1 .mg-col-2, .listing-modern-grid-9.l-2 .mg-col-2, .listing-modern-grid-9.l-3 .mg-col-2, .listing-modern-grid-9.l-4 .mg-col-2, .listing-modern-grid-9.l-5 .mg-col-2 {
  width: 47%;
}

.listing-modern-grid-9.l-1 .listing-mg-9-item:before, .listing-modern-grid-9.l-2 .listing-mg-9-item:before, .listing-modern-grid-9.l-3 .listing-mg-9-item:before, .listing-modern-grid-9.l-4 .listing-mg-9-item:before, .listing-modern-grid-9.l-5 .listing-mg-9-item:before {
  padding-top: 86.97%;
}

.listing-modern-grid-9.l-1 .listing-item-1:before, .listing-modern-grid-9.l-2 .listing-item-1:before, .listing-modern-grid-9.l-3 .listing-item-1:before, .listing-modern-grid-9.l-4 .listing-item-1:before, .listing-modern-grid-9.l-5 .listing-item-1:before {
  padding-top: 77%;
}

.listing-modern-grid-9.l-1 .listing-mg-item .content-container, .listing-modern-grid-9.l-2 .listing-mg-item .content-container, .listing-modern-grid-9.l-3 .listing-mg-item .content-container, .listing-modern-grid-9.l-4 .listing-mg-item .content-container, .listing-modern-grid-9.l-5 .listing-mg-item .content-container {
  left: 15px;
  right: 15px;
  bottom: 12px;
  max-height: 50%;
}

.listing-modern-grid-9.l-1 .mg-col-3 .mg-row, .listing-modern-grid-9.l-2 .mg-col-3 .mg-row, .listing-modern-grid-9.l-3 .mg-col-3 .mg-row, .listing-modern-grid-9.l-4 .mg-col-3 .mg-row, .listing-modern-grid-9.l-5 .mg-col-3 .mg-row {
  width: 50%;
  float: left;
}

.listing-modern-grid-9.l-1 .mg-col-3, .listing-modern-grid-9.l-2 .mg-col-3, .listing-modern-grid-9.l-3 .mg-col-3, .listing-modern-grid-9.l-4 .mg-col-3, .listing-modern-grid-9.l-5 .mg-col-3 {
  width: 100%;
  padding: 2px 0 0;
}

.listing-modern-grid-9.l-1 .mg-col-3 .mg-row.mg-row-1, .listing-modern-grid-9.l-2 .mg-col-3 .mg-row.mg-row-1, .listing-modern-grid-9.l-3 .mg-col-3 .mg-row.mg-row-1, .listing-modern-grid-9.l-4 .mg-col-3 .mg-row.mg-row-1, .listing-modern-grid-9.l-5 .mg-col-3 .mg-row.mg-row-1 {
  padding-right: 1px;
}

.listing-modern-grid-9.l-1 .mg-col-3 .mg-row.mg-row-2, .listing-modern-grid-9.l-2 .mg-col-3 .mg-row.mg-row-2, .listing-modern-grid-9.l-3 .mg-col-3 .mg-row.mg-row-2, .listing-modern-grid-9.l-4 .mg-col-3 .mg-row.mg-row-2, .listing-modern-grid-9.l-5 .mg-col-3 .mg-row.mg-row-2 {
  padding-left: 1px;
}

.listing-modern-grid-9.l-2 .mg-col-1, .listing-modern-grid-9.l-2 .mg-col-2, .listing-modern-grid-9.l-3 .mg-col-1, .listing-modern-grid-9.l-3 .mg-col-2, .listing-modern-grid-9.l-4 .mg-col-1, .listing-modern-grid-9.l-4 .mg-col-2, .listing-modern-grid-9.l-5 .mg-col-1, .listing-modern-grid-9.l-5 .mg-col-2 {
  width: 50%;
}

.listing-modern-grid-9.l-2 .listing-mg-item:before, .listing-modern-grid-9.l-3 .listing-mg-item:before, .listing-modern-grid-9.l-4 .listing-mg-item:before, .listing-modern-grid-9.l-5 .listing-mg-item:before {
  padding-top: 93%;
}

.listing-modern-grid-9.l-2 .listing-item-1:before, .listing-modern-grid-9.l-3 .listing-item-1:before, .listing-modern-grid-9.l-4 .listing-item-1:before, .listing-modern-grid-9.l-5 .listing-item-1:before {
  padding-top: 92.876%;
}

.listing-modern-grid-9.l-2 .mg-col-3 .listing-item:before {
  padding-top: 37%;
}

.listing-modern-grid-9.l-2 .listing-mg-item .title, .listing-modern-grid-9.l-3 .listing-mg-item .title, .listing-modern-grid-9.l-4 .listing-mg-item .title, .listing-modern-grid-9.l-5 .listing-mg-item .title {
  font-size: 66%;
}

.listing-modern-grid-9.l-2 .listing-item-1 .title, .listing-modern-grid-9.l-3 .listing-item-1 .title, .listing-modern-grid-9.l-4 .listing-item-1 .title, .listing-modern-grid-9.l-5 .listing-item-1 .title {
  font-size: 86%;
}

.listing-modern-grid-9.l-2 .mg-col-2 .term-badges, .listing-modern-grid-9.l-2 .mg-col-3 .term-badges, .listing-modern-grid-9.l-3 .mg-col-2 .term-badges, .listing-modern-grid-9.l-3 .mg-col-3 .term-badges, .listing-modern-grid-9.l-4 .mg-col-2 .term-badges, .listing-modern-grid-9.l-4 .mg-col-3 .term-badges, .listing-modern-grid-9.l-5 .mg-col-2 .term-badges, .listing-modern-grid-9.l-5 .mg-col-3 .term-badges {
  display: none;
}

.listing-modern-grid-9.l-3 .listing-mg-item .post-meta, .listing-modern-grid-9.l-4 .listing-mg-item .post-meta, .listing-modern-grid-9.l-5 .listing-mg-item .post-meta {
  display: none !important;
}

.listing-modern-grid-9.l-3 .listing-mg-item .format-icon, .listing-modern-grid-9.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-9.l-4 .listing-mg-item .format-icon, .listing-modern-grid-9.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-9.l-5 .listing-mg-item .format-icon, .listing-modern-grid-9.l-5 .listing-mg-item:hover .format-icon {
  right: 12px !important;
  top: 12px !important;
}

.listing-modern-grid-9.l-3.l-3 .listing-mg-item:before, .listing-modern-grid-9.l-4 .listing-mg-item:before, .listing-modern-grid-9.l-5 .listing-mg-item:before {
  padding-top: 60%;
}

.listing-modern-grid-9.l-3 .listing-item-1:before, .listing-modern-grid-9.l-4 .listing-item-1:before, .listing-modern-grid-9.l-5 .listing-item-1:before {
  padding-top: 45%;
}

.listing-modern-grid-9.l-3 .mg-col-1, .listing-modern-grid-9.l-4 .mg-col-1, .listing-modern-grid-9.l-5 .mg-col-1 {
  width: 100% !important;
  padding: 0 !important;
  margin-bottom: 2px !important;
}

.listing-modern-grid-9.l-3 .mg-col-2, .listing-modern-grid-9.l-4 .mg-col-2, .listing-modern-grid-9.l-5 .mg-col-2 {
  width: 100% !important;
  padding: 0 !important;
}

.listing-modern-grid-9.l-4 .listing-mg-item:before, .listing-modern-grid-9.l-4 .mg-col-3 .listing-item:before, .listing-modern-grid-9.l-5 .listing-mg-item:before, .listing-modern-grid-9.l-5 .mg-col-3 .listing-item:before {
  padding-top: 80%;
}

.listing-modern-grid-9.l-4 .listing-item-1:before, .listing-modern-grid-9.l-5 .listing-item-1:before {
  padding-top: 55%;
}

.slider-overlay-simple.listing-modern-grid-9.l-5 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-9.l-5 .listing-mg-item.listing-item-3 .content-container, .slider-overlay-simple.listing-modern-grid-9.l-5 .listing-mg-item.listing-item-4 .content-container, .slider-overlay-simple.listing-modern-grid-9.l-5 .listing-mg-item.listing-item-5 .content-container {
  max-height: 64%;
  padding: 12px !important;
}

@media only screen and (max-width: 980px) {
  .listing-modern-grid-9 .mg-col-1 {
    width: 53%;
  }
  .listing-modern-grid-9 .mg-col-2 {
    width: 47%;
  }
  .listing-modern-grid-9 .listing-mg-9-item:before {
    padding-top: 86.97%;
  }
  .listing-modern-grid-9 .listing-item-1:before {
    padding-top: 77%;
  }
  .listing-modern-grid-9 .listing-mg-item .content-container {
    left: 15px;
    right: 15px;
    bottom: 12px;
    max-height: 50%;
  }
  .listing-modern-grid-9 .mg-col-3 .mg-row {
    width: 50% !important;
    float: left !important;
  }
  .listing-modern-grid-9 .mg-col-3 {
    width: 100%;
    padding: 2px 0 0;
  }
  .listing-modern-grid-9 .mg-col-3 .mg-row.mg-row-1 {
    padding-right: 1px;
  }
  .listing-modern-grid-9 .mg-col-3 .mg-row.mg-row-2 {
    padding-left: 1px;
  }
  .listing-modern-grid-9 .mg-col-3 .listing-item:before {
    padding-top: 37%;
  }
}
@media only screen and (max-width: 780px) {
  .listing-modern-grid-9 .mg-col-1, .listing-modern-grid-9 .mg-col-2 {
    width: 50% !important;
  }
  .listing-modern-grid-9 .listing-mg-item:before {
    padding-top: 93%;
  }
  .listing-modern-grid-9 .listing-item-1:before {
    padding-top: 92.876%;
  }
  .listing-modern-grid-9 .listing-mg-item .title {
    font-size: 66%;
  }
  .listing-modern-grid-9 .listing-item-1 .title {
    font-size: 86%;
  }
  .listing-modern-grid-9 .mg-col-2 .term-badges, .listing-modern-grid-9 .mg-col-3 .term-badges {
    display: none;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-9 .listing-mg-item .post-meta {
    display: none;
  }
  .listing-modern-grid-9 .listing-mg-item .format-icon, .listing-modern-grid-9 .listing-mg-item:hover .format-icon {
    right: 12px;
    top: 12px;
  }
  .listing-modern-grid-9 .listing-mg-item:before, .listing-modern-grid-9 .mg-col-3 .listing-item:before {
    padding-top: 60%;
  }
  .listing-modern-grid-9 .listing-item-1:before {
    padding-top: 45%;
  }
  .listing-modern-grid-9 .mg-col-1 {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 2px !important;
  }
  .listing-modern-grid-9 .mg-col-2 {
    width: 100% !important;
    padding: 0 !important;
  }
}
@media only screen and (max-width: 560px) {
  .listing-modern-grid-9 .listing-mg-item:before, .listing-modern-grid-9 .mg-col-3 .listing-item:before {
    padding-top: 80% !important;
  }
  .listing-modern-grid-9 .listing-item-1:before {
    padding-top: 55% !important;
  }
}
@media only screen and (max-width: 480px) {
  .slider-overlay-simple.listing-modern-grid-9 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-9 .listing-mg-item.listing-item-3 .content-container, .slider-overlay-simple.listing-modern-grid-9 .listing-mg-item.listing-item-4 .content-container, .slider-overlay-simple.listing-modern-grid-9 .listing-mg-item.listing-item-5 .content-container {
    max-height: 64%;
    padding: 12px;
  }
}
.listing-mg-10-item:before {
  padding-top: 60%;
}

.listing-modern-grid-10 .mg-row-1 {
  padding-bottom: 4px;
}

.listing-modern-grid-10 .mg-row-1 .mg-col-1 {
  width: 50%;
  float: left;
  padding-right: 2px;
}

.listing-modern-grid-10 .mg-row-1 .mg-col-2 {
  width: 50%;
  float: left;
  padding-left: 2px;
}

.listing-modern-grid-10 .mg-row-2 .mg-col {
  width: 25%;
  float: left;
}

.listing-modern-grid-10 .mg-row-2 .mg-col-1 {
  padding-right: 3px;
}

.listing-modern-grid-10 .mg-row-2 .mg-col-2 {
  padding-left: 1px;
  padding-right: 2px;
}

.listing-modern-grid-10 .mg-row-2 .mg-col-3 {
  padding-left: 2px;
  padding-right: 1px;
}

.listing-modern-grid-10 .mg-row-2 .mg-col-4 {
  padding-left: 3px;
}

.listing-modern-grid-10 .mg-row-2 .listing-mg-10-item {
  width: 100%;
}

.listing-modern-grid-10 .mg-row-2 .listing-mg-10-item:before {
  padding-top: 64%;
}

.listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .post-subtitle, .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .title {
  font-size: 75%;
}

.listing-modern-grid-10 .mg-row-2 .listing-mg-item:hover .format-icon {
  margin-top: -46px;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.listing-modern-grid-10.l-1 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10.l-1 .listing-mg-10-item.listing-mg-item:hover .format-icon {
  right: 20px !important;
  top: 20px !important;
  transform: none;
  margin: 0;
}

.listing-modern-grid-10.l-1 .mg-row-2 .mg-col, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col {
  width: 50%;
}

.listing-modern-grid-10.l-1 .mg-row-2 .mg-col-1, .listing-modern-grid-10.l-1 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-1, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-1, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-1, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-3 {
  padding-right: 2px;
  padding-left: 0;
}

.listing-modern-grid-10.l-1 .mg-row-2 .mg-col-2, .listing-modern-grid-10.l-1 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-2, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-2, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-2, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-4 {
  padding-left: 2px;
  padding-right: 0;
}

.listing-modern-grid-10.l-1 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-1 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-4 {
  padding-top: 4px;
}

.listing-modern-grid-10.l-1 .mg-row-1 .listing-mg-10-item .title, .listing-modern-grid-10.l-2 .mg-row-1 .listing-mg-10-item .title, .listing-modern-grid-10.l-3 .mg-row-1 .listing-mg-10-item .title, .listing-modern-grid-10.l-4 .mg-row-1 .listing-mg-10-item .title {
  font-size: 92%;
}

.listing-modern-grid-10.l-1 .mg-row-2 .listing-mg-10-item .title, .listing-modern-grid-10.l-2 .mg-row-2 .listing-mg-10-item .title, .listing-modern-grid-10.l-3 .mg-row-2 .listing-mg-10-item .title, .listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item .title {
  font-size: 72%;
}

.listing-modern-grid-10.l-2 .mg-row-1 .mg-col, .listing-modern-grid-10.l-3 .mg-row-1 .mg-col, .listing-modern-grid-10.l-4 .mg-row-1 .mg-col {
  width: 100%;
  padding: 0;
  float: none;
}

.listing-modern-grid-10.l-2 .mg-row-1 .mg-col-1, .listing-modern-grid-10.l-3 .mg-row-1 .mg-col-1, .listing-modern-grid-10.l-4 .mg-row-1 .mg-col-1 {
  padding-bottom: 4px;
}

.listing-modern-grid-10.l-2 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10.l-2 .listing-mg-10-item.listing-mg-item:hover .format-icon, .listing-modern-grid-10.l-3 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10.l-3 .listing-mg-10-item.listing-mg-item:hover .format-icon, .listing-modern-grid-10.l-4 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10.l-4 .listing-mg-10-item.listing-mg-item:hover .format-icon {
  right: 20px !important;
  top: 20px !important;
  transform: none;
  margin: 0;
}

.listing-modern-grid-10.l-2 .mg-row-1 .listing-mg-10-item:before, .listing-modern-grid-10.l-3 .mg-row-1 .listing-mg-10-item:before, .listing-modern-grid-10.l-4 .mg-row-1 .listing-mg-10-item:before {
  padding-top: 36%;
}

.listing-modern-grid-10.l-2 .mg-row-2 .listing-mg-10-item:before, .listing-modern-grid-10.l-3 .mg-row-2 .listing-mg-10-item:before, .listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item:before {
  padding-top: 70%;
}

.listing-modern-grid-10.l-3 .listing-item .post-meta, .listing-modern-grid-10.l-4 .listing-item .post-meta {
  display: none;
}

.listing-modern-grid-10.l-3 .mg-row-2 .mg-col, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col {
  width: 50%;
}

.listing-modern-grid-10.l-3 .mg-row-2 .mg-col-1, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-1 {
  padding-right: 2px;
}

.listing-modern-grid-10.l-3 .mg-row-2 .mg-col-2, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-2 {
  padding-left: 2px;
  padding-right: 0;
}

.listing-modern-grid-10.l-3 .mg-row-2 .listing-mg-10-item .format-icon, .listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item .format-icon {
  display: none;
}

.listing-modern-grid-10.l-4 .mg-row-1 .listing-mg-10-item:before {
  padding-top: 52%;
}

.listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item:before {
  padding-top: 76%;
}

.listing-modern-grid-10.l-4 .mg-row-1 .listing-mg-10-item .title {
  font-size: 82%;
}

.listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item .title {
  font-size: 62%;
}

.listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item .content-container {
  max-height: 43%;
}

@media only screen and (max-width: 980px) {
  .listing-modern-grid-10 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10 .listing-mg-10-item.listing-mg-item:hover .format-icon {
    right: 20px !important;
    top: 20px !important;
    transform: none;
    margin: 0;
  }
  .listing-modern-grid-10 .mg-row-1 .listing-mg-10-item .title {
    font-size: 92%;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .title {
    font-size: 72%;
  }
}
@media only screen and (max-width: 780px) {
  .listing-modern-grid-10 .mg-row-2 .mg-col {
    width: 50% !important;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col-1, .listing-modern-grid-10 .mg-row-2 .mg-col-3 {
    padding-right: 2px !important;
    padding-left: 0 !important;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col-2, .listing-modern-grid-10 .mg-row-2 .mg-col-4 {
    padding-left: 2px !important;
    padding-right: 0 !important;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col-3, .listing-modern-grid-10 .mg-row-2 .mg-col-4 {
    padding-top: 4px !important;
  }
  .listing-modern-grid-10 .mg-row-1 .mg-col {
    width: 100% !important;
    padding: 0 !important;
    float: none !important;
  }
  .listing-modern-grid-10 .mg-row-1 .mg-col-1 {
    padding-bottom: 4px !important;
  }
  .listing-modern-grid-10 .mg-row-1 .listing-mg-10-item:before {
    padding-top: 30%;
  }
  .listing-modern-grid-10 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10 .listing-mg-10-item.listing-mg-item:hover .format-icon {
    right: 20px !important;
    top: 20px !important;
    transform: none;
    margin: 0;
  }
  .listing-modern-grid-10 .listing-mg-10-item:before {
    padding-top: 70%;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item:before {
    padding-top: 50%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-10 .listing-item .post-meta {
    display: none;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col {
    width: 50% !important;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col-1 {
    padding-right: 2px !important;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col-2 {
    padding-left: 2px !important;
    padding-right: 0 !important;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .format-icon {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-10 .mg-row-1 .listing-mg-10-item:before {
    padding-top: 52% !important;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item:before {
    padding-top: 76% !important;
  }
  .listing-modern-grid-10 .mg-row-1 .listing-mg-10-item .title {
    font-size: 82%;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .title {
    font-size: 62%;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .content-container {
    max-height: 43%;
  }
}
.listing-item-user {
  position: relative;
}

.listing-item-user .user-avatar img {
  border-radius: 50%;
  display: inline-block;
}

.listing-item-user .btn-light {
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #3c3c3c !important;
  vertical-align: top;
  line-height: 24px;
  font-size: 10px;
  padding: 0 8px;
  margin: 0 10px 0 0;
}

.listing-item-user .btn-light.btn-light:focus, .listing-item-user .btn-light.btn-light:hover {
  color: #3c3c3c !important;
  text-decoration: none !important;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.listing-item-user .user-social-icons {
  display: inline;
  padding: 0;
  margin: 0;
  vertical-align: top;
}

.listing-item-user .user-social-icons .social-item {
  display: inline-block;
  padding: 0;
  margin: 0 5px 7px 0;
}

.listing-item-user .user-social-icons .social-item:last-child {
  margin-right: 0;
}

.listing-item-user .user-social-icons a {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  padding: 0 4px;
  text-align: center;
  display: inline-block;
  min-width: 27px;
  font-size: 12px;
  line-height: 24px;
  color: #444;
  vertical-align: top;
}

.listing-item-user .user-social-icons .social-item a, .listing-item-user .user-social-icons .social-item a .fa {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.listing-item-user .user-social-icons .github:hover a:hover {
  color: #666;
}

.listing-item-user .user-social-icons .pinterest a:hover {
  color: #cb2027;
}

.listing-item-user .user-social-icons .youtube a:hover {
  color: #cc181e;
}

.listing-item-user .user-social-icons .linkedin a:hover {
  color: #0266a0;
}

.listing-item-user .user-social-icons .dribbble a:hover {
  color: #ea4c89;
}

.listing-item-user .user-social-icons .vimeo a:hover {
  color: #46a3e9;
}

.listing-item-user .user-social-icons .delicious a:hover {
  color: #4a79ff;
}

.listing-item-user .user-social-icons .soundcloud a:hover {
  color: #f50;
}

.listing-item-user .user-social-icons .behance a:hover {
  color: #3b63fc;
}

.listing-item-user .user-social-icons .flickr a:hover {
  color: #2f44db;
}

.listing-item-user .user-social-icons .instagram a:hover {
  color: #517fa4;
}

.listing-item-user .user-social-icons .google-plus a:hover {
  color: #d73d32;
}

.listing-item-user .user-social-icons .google-plus a:hover {
  color: #d73d32;
}

.listing-item-user .user-social-icons .google-plus a:hover {
  color: #d73d32;
}

.listing-item-user .user-social-icons .google-plus a:hover {
  color: #d73d32;
}

.listing-item-user .user-social-icons .twitter a:hover {
  color: #59c8ff;
}

.listing-item-user .user-social-icons .facebook a:hover {
  color: #4a639f;
}

.listing-item-user .user-avatar {
  float: left;
  margin-right: 15px;
  margin-bottom: 5px;
  position: relative;
}

.listing-item-user .user-display-name {
  padding: 0;
  color: #444;
  font-size: 18px;
  margin: 0 0 8px;
}

.listing-item-user .biography {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
}

.listing-item-user .biography p {
  margin: 0 0 10px 0;
}

.listing-item-user .user-badge {
  position: absolute;
  left: -11px;
  top: 35px;
  background: #0077d5;
  color: #fff;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  line-height: 23px;
  overflow: hidden;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  border: 3px solid #fff;
}

.listing-item-user .user-badge {
  left: -12px;
  top: 50%;
  width: 26px;
  height: 26px;
  line-height: 24px;
  font-size: 12px;
  border-width: 2px;
  margin-top: -13px;
}

.listing-user.columns-1 .listing-item-user {
  padding-bottom: 0 !important;
}

.listing-user .listing-item-user {
  padding-bottom: 0 !important;
}

.bs-listing.bs-listing-user-listing-1 .bs-slider-dots:first-child:last-child, .bs-listing.bs-listing-user-listing-2 .bs-slider-dots:first-child:last-child, .bs-listing.bs-listing-user-listing-3 .bs-slider-dots:first-child:last-child, .bs-listing.bs-listing-user-listing-4 .bs-slider-dots:first-child:last-child {
  position: relative;
  left: auto;
  right: auto;
}

.listing-item-user.type-1 .user-social-icons .social-item a:hover {
  background: currentColor;
  border-color: currentColor;
}

.listing-item-user.type-1 .user-social-icons .social-item a:hover .fa {
  color: #fff;
}

.listing-item-user.type-1 {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 13px;
  position: relative;
}

.listing.columns-1 .listing-item-user.type-1:last-child {
  border-width: 0;
}

.listing-item-user.type-1.style-2 .user-avatar {
  float: left;
  margin: 0;
}

.listing-item-user.type-1.style-2 .user-meta {
  padding-left: 120px;
}

.listing-item-user.type-1.style-2 .user-avatar {
  margin-right: 20px;
  margin-bottom: 10px;
}

.listing-item-user.type-1.style-2 .user-display-name {
  font-size: 20px;
}

.listing-item-user.type-2 .bs-user-item {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.069);
  margin-right: 25px;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  position: relative;
  margin-bottom: 16px;
}

.listing-item-user.type-2 .bs-user-item:hover {
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.1);
}

.listing-item-user.type-2 .bs-user-item:last-child {
  margin-right: 0;
}

.listing-item-user.type-2 .user-avatar {
  float: none;
  margin: 0;
}

.listing-item-user.type-2 .user-social-icons {
  overflow: hidden;
  max-height: 22px;
  display: block;
  margin: 0 0 14px 0;
}

.listing-item-user.type-2 .btn-light {
  border-color: rgba(0, 0, 0, 0.18);
  color: #7d7d80 !important;
  line-height: 22px;
  padding: 0 12px;
  margin-bottom: 0;
}

.listing-item-user.type-2 .user-display-name {
  margin: 12px 0 8px;
}

.listing-item-user.type-2 .biography {
  font-size: 12px;
  line-height: 18px;
}

.listing-item-user.type-2 .biography p:last-child {
  margin: 0;
}

.listing-item-user.type-2 .user-badge {
  top: 8px;
  left: -5px;
}

.listing-item-user.type-2 {
  padding-bottom: 20px;
}

.listing-item-user.type-2 .user-social-icons a {
  border: transparent;
}

.listing-item-user.type-2 .user-social-icons .social-item {
  margin: 0 0 5px;
}

.listing-item-user.type-2 .user-social-icons a {
  min-width: 24px;
}

.listing-item-user.type-2.style-1 {
  text-align: center;
}

.listing-item-user.type-2.style-1 .btn-light {
  border-radius: 12px;
}

.listing-item-user.type-2.style-2 .user-badge {
  left: -12px;
  top: 8px;
}

.more-stories {
  display: none;
  position: fixed;
  right: -330px;
  bottom: 70px;
  z-index: 9999;
  border: 1px solid #ddd;
  -webkit-box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  background: #fff;
  width: 325px;
  max-width: 60%;
}

.more-stories:before {
  content: "";
  display: inline-block;
  height: 4px;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -4px;
}

.more-stories.right {
  border-right-width: 0;
}

.more-stories.left {
  -webkit-box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-left-width: 0;
  right: auto;
  left: -330px;
}

.more-stories.more-stories-thumbnail-2 {
  width: 355px;
  right: -360px;
}

.more-stories.more-stories-thumbnail-2.left {
  width: 355px;
  right: auto;
  left: -360px;
}

.more-stories-title {
  text-align: center;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #313131;
  border-bottom: 1px solid #f0f0f0;
}

.more-stories .listing {
  padding-top: 15px;
}

.more-stories .bs-pagination:last-child {
  margin-bottom: 10px;
}

.more-stories-close {
  float: left;
  color: #cecece;
}

.more-stories.left .more-stories-close {
  float: right;
}

.more-stories .listing-item {
  margin-bottom: 8px;
}

.more-stories .listing-item:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 680px) {
  .more-stories {
    display: none !important;
  }
}
.bs-irp {
  position: relative;
  width: 320px;
  margin: 10px 0 15px 0;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  padding-bottom: 20px;
}

.bs-irp .bs-irp-heading {
  font-size: 15px;
  margin-bottom: 16px;
  position: relative;
  padding: 8px 0 0;
  text-transform: uppercase;
}

.bs-irp .bs-irp-heading:before {
  content: "";
  display: inline-block;
  width: 70px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

.bs-irp br {
  display: none;
}

.bs-irp p:empty {
  display: none;
}

.bs-irp p {
  margin-bottom: 0 !important;
}

.bs-irp a:hover {
  text-decoration: none !important;
}

.bs-irp.right {
  float: right;
  margin-left: 30px;
}

.bs-irp.left {
  float: left;
  margin-right: 30px;
}

.bs-irp.center {
  float: none;
  margin-right: auto;
  margin-left: auto;
}

.bs-irp .listing-text .listing-item:last-child .item-inner {
  border-bottom-width: 0;
  padding-bottom: 0;
}

.bs-irp .listing .listing-item:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.bs-irp.bs-irp-thumbnail-2 {
  padding-bottom: 0;
}

.bs-irp.bs-irp-text-1-full, .bs-irp.bs-irp-text-2-full, .bs-irp.bs-irp-text-3-full, .bs-irp.bs-irp-text-4-full, .bs-irp.bs-irp-thumbnail-1-full, .bs-irp.bs-irp-thumbnail-2-full, .bs-irp.bs-irp-thumbnail-3-full {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  float: none;
  padding-bottom: 0;
  clear: both;
  border-top-width: 1px;
  padding-top: 3px;
}

.bs-irp.bs-irp-text-1-full .bs-irp-heading:before, .bs-irp.bs-irp-text-2-full .bs-irp-heading:before, .bs-irp.bs-irp-text-3-full .bs-irp-heading:before, .bs-irp.bs-irp-text-4-full .bs-irp-heading:before, .bs-irp.bs-irp-thumbnail-1-full .bs-irp-heading:before, .bs-irp.bs-irp-thumbnail-2-full .bs-irp-heading:before, .bs-irp.bs-irp-thumbnail-3-full .bs-irp-heading:before {
  display: none;
}

.bs-irp.bs-irp-text-1-full .listing-item-text-1, .bs-irp.bs-irp-text-1-full .listing-item-text-1:last-child, .bs-irp.bs-irp-text-2-full .listing-item-text-2, .bs-irp.bs-irp-text-2-full .listing-item-text-2:last-child, .bs-irp.bs-irp-text-3-full .listing-item-text-3, .bs-irp.bs-irp-text-3-full .listing-item-text-3:last-child, .bs-irp.bs-irp-text-4-full .listing-item-text-4, .bs-irp.bs-irp-text-4-full .listing-item-text-4:last-child {
  border-bottom: 0;
  margin-bottom: 17px !important;
  padding-bottom: 0 !important;
}

.bs-irp.bs-irp-text-1-full .listing-item-text-1 .item-inner {
  padding-bottom: 0 !important;
  border: none;
}

.bs-irp.bs-irp-thumbnail-2 .bs-pagination {
  padding-bottom: 18px;
}

.bs-irp.bs-irp-text-1-full .bs-pagination, .bs-irp.bs-irp-text-2-full .bs-pagination, .bs-irp.bs-irp-text-3-full .bs-pagination, .bs-irp.bs-irp-text-4-full .bs-pagination, .bs-irp.bs-irp-thumbnail-1-full .bs-pagination, .bs-irp.bs-irp-thumbnail-2-full .bs-pagination, .bs-irp.bs-irp-thumbnail-3-full .bs-pagination {
  padding-bottom: 18px;
  padding-top: 0;
  margin-top: 0;
}

.bs-irp .post-meta p {
  display: inline;
}

@media only screen and (max-width: 530px) {
  .bs-irp.left, .bs-irp.right {
    float: none;
    width: 100%;
    max-width: 100%;
    margin: 21px 0 21px 0;
  }
}
.bs-listing-slider-1 > .bs-slider, .bs-listing-slider-2 > .bs-slider, .bs-listing-slider-3 > .bs-slider {
  margin-bottom: 0;
}

.bs-listing-slider-1, .bs-listing-slider-2, .bs-listing-slider-3 {
  margin-bottom: 22px;
}

.bs-shortcode.bs-slider .better-direction-nav a {
  text-indent: -9999px;
}

.bs-shortcode.bs-slider .better-direction-nav a:before {
  line-height: 38px;
  font-size: 14px;
  display: inline-block;
  content: "\f053";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-indent: 0;
  width: auto;
  height: auto;
}

.bs-shortcode.bs-slider .better-direction-nav a.better-next:before {
  content: "\f054";
  right: -3px;
}

.bs-slider-item {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.bs-slider-item > .item-content {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.bs-slider-item > .item-content:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 45%;
}

.bs-slider-item .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bs-slider-item .img-cont.b-error, .bs-slider-item .img-cont.b-loaded, body.bs-ll-d .bs-slider-item .img-cont {
  background-size: cover !important;
  animation: none;
}

.bs-slider .bs-slider-item:nth-child(1) {
  float: left;
  display: block;
  width: 100%;
}

.bs-slider-item.has-not-post-thumbnail .img-cont {
  background-color: #e2e2e2;
}

.bs-slider-item .post-subtitle {
  text-align: center !important;
}

.bs-slider-1-item > .item-content:before {
  padding-top: 45%;
}

.bs-slider-1-item .img-cont:after {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.55)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 160px;
}

.bs-slider-1-item:hover .img-cont {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
}

.bs-slider-1-item .content-container {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  position: absolute;
  bottom: -80px;
  left: 30px;
  right: 30px;
  padding: 0 30px 30px;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
}

.bs-slider-1-item.slider-content-shown .content-container {
  bottom: 0;
  opacity: 1;
}

.bs-slider-1-item .content-container a {
  pointer-events: all;
}

.bs-slider-1-item .title {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  text-align: center !important;
  max-width: 70%;
  margin: 0 auto;
}

.bs-slider-1-item .title a {
  color: #fff;
}

.bs-slider-1-item .post-meta {
  margin-top: 12px;
  text-align: center !important;
  font-size: 11px;
}

.bs-slider-1-item .post-meta .comments, .bs-slider-1-item .post-meta .post-author {
  color: #fff;
  font-size: 11px;
}

.bs-slider-1-item .post-meta .comments {
  float: none;
  margin-left: 0;
}

.bs-slider-1-item .post-meta .post-author:after, .bs-slider-1-item .post-meta .time {
  color: rgba(255, 255, 255, 0.57);
}

.bs-slider-1-item .term-badges.floated {
  position: absolute;
  right: auto;
  left: 0;
  top: 30px;
  bottom: auto;
  width: 100%;
  text-align: center;
  pointer-events: none;
}

.bs-slider-1 .better-direction-nav .better-next, .bs-slider-1 .better-direction-nav .better-prev {
  opacity: 1;
  transform: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: 0 0;
  border: 2px solid #fff;
  text-align: center;
  line-height: 40px;
  color: #fff;
  text-shadow: none;
  margin: -36px 0 0;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.bs-slider-1 .better-direction-nav .better-prev {
  left: 20px;
}

.bs-slider-1 .better-direction-nav .better-next {
  right: 20px;
}

.bs-slider-1-item:hover .format-icon {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.bs-slider-1-item .post-subtitle {
  margin-top: 7px;
  color: #fff !important;
}

.bs-slider-1-item .post-subtitle:first-child {
  margin: 0 0 7px;
}

.bs-slider-1.l-1 .bs-slider-1-item > .item-content:before, .bs-slider-1.l-2 .bs-slider-1-item > .item-content:before {
  padding-top: 60%;
}

.bs-slider-1.l-2 .bs-slider-1-item > .item-content:before {
  padding-top: 110%;
}

.bs-slider-1.l-2 .bs-slider-1-item .post-meta {
  display: none;
}

.bs-slider-1.l-2 .bs-slider-1-item .title a {
  font-size: 75%;
  line-height: 170%;
  display: inline-block;
}

.bs-slider-1.l-2 .bs-slider-1-item .content-container {
  left: 0;
  right: 0;
  padding: 0 20px 20px;
}

.bs-slider-1.l-2 .better-direction-nav .better-prev {
  left: -20px;
  opacity: 0;
}

.bs-slider-1.l-2:hover .better-direction-nav .better-prev {
  left: 10px;
  opacity: 1;
}

.bs-slider-1.l-2 .better-direction-nav .better-next {
  right: -20px;
  opacity: 0;
}

.bs-slider-1.l-2:hover .better-direction-nav .better-next {
  right: 10px;
  opacity: 1;
}

@media only screen and (max-width: 780px) {
  .bs-slider-1 .bs-slider-1-item > .item-content:before {
    padding-top: 60%;
  }
}
@media only screen and (max-width: 370px) {
  .bs-slider-1 .bs-slider-1-item .post-meta {
    display: none;
  }
  .bs-slider-1 .bs-slider-1-item > .item-content:before {
    padding-top: 110% !important;
  }
  .bs-slider-1 .bs-slider-1-item .title a {
    font-size: 75%;
    line-height: 170%;
    display: inline-block;
  }
  .bs-slider-1 .bs-slider-1-item .content-container {
    left: 0;
    right: 0;
    padding: 0 20px 20px;
  }
  .bs-slider-1 .better-direction-nav .better-prev {
    left: -20px;
    opacity: 0;
  }
  .bs-slider-1:hover .better-direction-nav .better-prev {
    left: 10px;
    opacity: 1;
  }
  .bs-slider-1 .better-direction-nav .better-next {
    right: -20px;
    opacity: 0;
  }
  .bs-slider-1:hover .better-direction-nav .better-next {
    right: 10px;
    opacity: 1;
  }
}
.bs-slider-2-item > .item-content:before {
  padding-top: 45%;
}

.bs-slider-2-item:hover .img-cont {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
}

.bs-slider-2-item .content-container {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  position: absolute;
  left: 50%;
  bottom: 10px;
  padding: 30px;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  width: 460px;
  margin: 0 0 0 -230px;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.bs-slider-2-item.slider-content-shown .content-container {
  bottom: 40px;
  opacity: 1;
}

.bs-slider-2-item .content-container a {
  pointer-events: all;
}

.bs-slider-2-item .title {
  margin: 10px 0 0;
  line-height: 1.4;
  text-align: center !important;
}

.bs-slider-2-item .title a {
  color: #212121;
}

.bs-slider-2-item .post-meta {
  margin-top: 8px;
  text-align: center !important;
  font-size: 12px;
}

.bs-slider-2-item .post-meta .comments, .bs-slider-2-item .post-meta .post-author {
  color: #212121;
  font-size: 12px;
}

.bs-slider-2-item .post-meta .comments {
  float: none;
  margin-left: 0;
}

.bs-slider-2-item .post-meta .post-author:after, .bs-slider-2-item .post-meta .time {
  color: #212121;
}

.bs-slider-2-item .content-container a.read-more {
  border: 1px solid transparent;
  background: 0 0;
  color: #fff;
  font-size: 12px;
  padding: 6px 20px;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  line-height: 20px;
  margin-top: 15px;
}

.bs-slider-2-item .content-container a.read-more:hover {
  color: #fff;
}

.bs-slider-2-item .term-badges.floated {
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.bs-slider-2-item .term-badges.floated .term-badge a {
  background: #212121 !important;
  color: #fff !important;
  margin: 0;
}

.bs-slider-2-item:hover .format-icon {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.bs-slider-2 .better-direction-nav .better-next, .bs-slider-2 .better-direction-nav .better-prev {
  transform: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: 0 0;
  border: 2px solid #fff;
  text-align: center;
  line-height: 40px;
  color: #fff;
  text-shadow: none;
  margin: -36px 0 0;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.bs-slider-2-item .post-subtitle {
  margin-top: 7px;
}

.bs-slider-2-item .post-subtitle:first-child, .bs-slider-2-item .term-badges + .post-subtitle {
  margin: 0 0 -4px;
}

.bs-slider-2.l-1 .bs-slider-2-item > .item-content:before, .bs-slider-2.l-2 .bs-slider-2-item > .item-content:before, .bs-slider-2.l-3 .bs-slider-2-item > .item-content:before {
  padding-top: 70%;
}

.bs-slider-2.l-1 .bs-slider-2-item .title a, .bs-slider-2.l-2 .bs-slider-2-item .title a, .bs-slider-2.l-3 .bs-slider-2-item .title a {
  font-size: 85%;
}

.bs-slider-2.l-2 .bs-slider-2-item > .item-content:before, .bs-slider-2.l-3 .bs-slider-2-item > .item-content:before {
  padding-top: 120%;
}

.bs-slider-2.l-2 .bs-slider-2-item .post-meta, .bs-slider-2.l-3 .bs-slider-2-item .post-meta {
  display: none;
}

.bs-slider-2.l-2 .bs-slider-2-item .title a, .bs-slider-2.l-3 .bs-slider-2-item .title a {
  font-size: 75%;
  line-height: 170%;
  display: inline-block;
}

.bs-slider-2.l-2 .bs-slider-2-item .content-container, .bs-slider-2.l-3 .bs-slider-2-item .content-container {
  left: 7.5%;
  bottom: 7.5%;
  right: 7.5%;
  padding: 20px;
  width: 85%;
  transform: none;
  margin: 0;
}

.bs-slider-2.l-3 .better-direction-nav .better-next, .bs-slider-2.l-3 .better-direction-nav .better-prev {
  display: none;
}

@media only screen and (max-width: 780px) {
  .bs-slider-2-item > .item-content:before {
    padding-top: 70%;
  }
  .bs-slider-2-item .title a {
    font-size: 85%;
  }
}
@media only screen and (max-width: 520px) {
  .bs-slider-2-item > .item-content:before {
    padding-top: 120% !important;
  }
  .bs-slider-2-item .post-meta {
    display: none;
  }
  .bs-slider-2-item .title a {
    font-size: 75%;
    line-height: 170%;
    display: inline-block;
  }
  .bs-slider-2-item .content-container {
    left: 7.5%;
    bottom: 7.5%;
    right: 7.5%;
    padding: 20px;
    width: 85%;
    transform: none;
    margin: 0;
  }
}
@media only screen and (max-width: 370px) {
  .bs-slider-2 .better-direction-nav .better-next, .bs-slider-2 .better-direction-nav .better-prev {
    display: none;
  }
}
.bs-slider-3-item > .item-content:before {
  padding-top: 45%;
}

.bs-slider-3-item:hover .img-cont {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
}

.bs-slider-3-item .content-container {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  position: absolute;
  left: 50%;
  top: 45%;
  padding: 30px;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  width: 460px;
  margin: -120px 0 0 -230px;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.bs-slider-3-item .content-container a.read-more {
  border: 1px solid transparent;
  background: 0 0;
  color: #fff;
  font-size: 12px;
  padding: 6px 20px;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  line-height: 20px;
  margin-top: 15px;
}

.bs-slider-3-item .content-container a.read-more:hover {
  color: #fff;
}

.bs-slider-3-item.slider-content-shown .content-container {
  opacity: 1;
  top: 50%;
}

.bs-slider-3-item .content-container a {
  pointer-events: all;
}

.bs-slider-3-item .title {
  margin: 10px 0 0;
  line-height: 1.4;
  text-align: center !important;
}

.bs-slider-3-item .title a {
  color: #212121;
}

.bs-slider-3-item .post-meta {
  margin-top: 8px;
  text-align: center !important;
  font-size: 12px;
}

.bs-slider-3-item .post-meta .comments, .bs-slider-3-item .post-meta .post-author {
  color: #212121;
  font-size: 12px;
}

.bs-slider-3-item .post-meta .comments {
  float: none;
  margin-left: 0;
}

.bs-slider-3-item .post-meta .post-author:after, .bs-slider-3-item .post-meta .time {
  color: #212121;
}

.bs-slider-3-item .term-badges.floated {
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.bs-slider-3-item .term-badges.floated .term-badge a {
  background: #212121 !important;
  color: #fff !important;
  margin: 0;
}

.bs-slider-3 .better-direction-nav .better-next, .bs-slider-3 .better-direction-nav .better-prev {
  transform: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: 0 0;
  border: 2px solid #fff;
  text-align: center;
  line-height: 40px;
  color: #fff;
  text-shadow: none;
  margin: -36px 0 0;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.bs-slider-3 .better-direction-nav .better-prev {
  left: 20px;
}

.bs-slider-3 .better-direction-nav .better-next {
  right: 20px;
}

.bs-slider-3-item:hover .format-icon {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.bs-slider-3-item .post-subtitle {
  margin-top: 7px;
}

.bs-slider-3-item .post-subtitle:first-child, .bs-slider-3-item .term-badges + .post-subtitle {
  margin: 0 0 -4px;
}

.bs-slider-3.l-1 .bs-slider-3-item > .item-content:before, .bs-slider-3.l-2 .bs-slider-3-item > .item-content:before, .bs-slider-3.l-3 .bs-slider-3-item > .item-content:before {
  padding-top: 60%;
}

.bs-slider-3.l-1 .bs-slider-3-item .title a, .bs-slider-3.l-2 .bs-slider-3-item .title a, .bs-slider-3.l-3 .bs-slider-3-item .title a {
  font-size: 90%;
}

.bs-slider-3.l-1 .bs-slider-3-item > .item-content:before, .bs-slider-3.l-2 .bs-slider-3-item > .item-content:before, .bs-slider-3.l-3 .bs-slider-3-item > .item-content:before {
  padding-top: 60%;
}

.bs-slider-3.l-2 .bs-slider-3-item > .item-content:before, .bs-slider-3.l-3 .bs-slider-3-item > .item-content:before {
  padding-top: 110% !important;
}

.bs-slider-3.l-2 .bs-slider-3-item .post-meta, .bs-slider-3.l-3 .bs-slider-3-item .post-meta {
  display: none;
}

.bs-slider-3.l-2 .bs-slider-3-item .title a, .bs-slider-3.l-3 .bs-slider-3-item .title a {
  font-size: 75%;
  line-height: 150%;
  display: inline-block;
}

.bs-slider-3.l-3 .bs-slider-3-item .content-container {
  left: 7.5%;
  bottom: 7.5%;
  right: 7.5%;
  top: auto;
  padding: 20px;
  width: 85%;
  transform: none;
  margin: 0;
}

.bs-slider-3.l-3 .bs-slider-3-item.slider-content-shown .content-container {
  bottom: 25px;
}

.bs-slider-3.l-3 .better-direction-nav .better-next, .bs-slider-3.l-3 .better-direction-nav .better-prev {
  display: none;
}

@media only screen and (max-width: 780px) {
  .bs-slider-3 .bs-slider-3-item > .item-content:before {
    padding-top: 60%;
  }
  .bs-slider-3 .bs-slider-3-item .title a {
    font-size: 90%;
  }
  .bs-slider-3 .bs-slider-3-item > .item-content:before {
    padding-top: 60%;
  }
}
@media only screen and (max-width: 520px) {
  .bs-slider-3 .bs-slider-3-item > .item-content:before {
    padding-top: 110% !important;
  }
  .bs-slider-3 .bs-slider-3-item .post-meta {
    display: none;
  }
  .bs-slider-3 .bs-slider-3-item .title a {
    font-size: 75%;
    line-height: 150%;
    display: inline-block;
  }
  .bs-slider-3 .bs-slider-3-item .content-container {
    left: 7.5%;
    bottom: 7.5%;
    right: 7.5%;
    top: auto;
    padding: 20px;
    width: 85%;
    transform: none;
    margin: 0;
  }
}
@media only screen and (max-width: 380px) {
  .bs-slider-3 .bs-slider-3-item.slider-content-shown .content-container {
    bottom: 25px;
  }
  .bs-slider-3 .better-direction-nav .better-next, .bs-slider-3 .better-direction-nav .better-prev {
    display: none;
  }
}
.bs-push-noti {
  position: relative;
  padding: 10px;
  font-weight: 700;
  overflow: hidden;
  text-align: center;
}

.bs-push-noti-bg {
  display: none;
}

.bs-push-noti .bs-push-noti-button {
  text-transform: capitalize !important;
  line-height: 30px;
  font-weight: bolder;
}

.bs-push-noti .bs-push-noti-wrapper-icon {
  color: #fff;
}

.bs-push-noti.bspn-t1.bspn-t1 {
  text-align: left;
}

.bs-push-noti.bspn-t1 .bs-push-noti-inner {
  padding: 15px 10px 20px 124px;
  border: 1px solid #e8e8e8;
  position: relative;
  overflow: hidden;
}

.bs-push-noti.bspn-t1 p {
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 15px;
}

.bs-push-noti.bspn-t1 .bs-push-noti-wrapper-icon {
  padding: 10px;
  background: #bbb;
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  width: 111px;
  text-align: center;
}

.bs-push-noti.bspn-t1 .bs-push-noti-wrapper-icon:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 37px;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 5px 5px 5px 0;
  top: 50%;
  left: 50%;
  margin-left: -26px;
  margin-top: -23px;
}

.bs-push-noti.bspn-t1 .bs-push-noti-wrapper-icon:before {
  content: "";
  position: absolute;
  border-top: 0 solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 0 solid transparent;
  border-left: 14px solid rgba(255, 255, 255, 0.25);
  z-index: 2;
  left: 50%;
  margin-left: -26px;
  bottom: 50%;
  margin-bottom: -24px;
}

.bs-push-noti.bspn-t1 .bs-push-noti-wrapper-icon .notification-icon {
  position: absolute;
  top: 50%;
  font-size: 17px;
  margin-top: -13px;
  margin-left: -8.5px;
  color: #fff;
  z-index: 1;
}

.bs-push-noti.bspn-t1 .bs-push-noti-bg {
  display: block;
  position: absolute;
  top: -50%;
  right: -3%;
  font-size: 7em;
  transform: rotate(30deg);
  opacity: 0.09;
}

.bs-push-noti.post-bottom {
  margin-bottom: 0 !important;
}

.widget .bs-push-noti.bspn-t1 .bs-push-noti-wrapper-icon {
  position: absolute;
  left: -16px;
  right: -16px;
  top: -1px;
  display: block;
  height: 140px;
  width: auto;
}

.widget .bs-push-noti.bspn-t1 .bs-push-noti-inner {
  padding-left: 15px;
  padding-top: 150px;
}

.bs-push-noti.bspn-t2 {
  padding: 0;
}

.bs-push-noti.bspn-t2 .bs-push-noti-inner {
  background: #fdfdfd;
  text-align: center;
  margin-top: 35px;
  padding: 10px 20px 20px;
  border: 1px solid #e8e8e8;
}

.bs-push-noti.bspn-t2 .bs-push-noti-wrapper-icon {
  background: #ccc;
  border-radius: 50%;
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: -40px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  z-index: 9;
}

.bs-push-noti.bspn-t2 .notification-icon {
  line-height: 60px;
}

.bs-push-noti.bspn-t2 .bs-push-noti-message {
  margin-top: -25px;
  margin-bottom: 20px;
}

.bs-push-noti.bspn-t2 p {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.bs-push-noti.bspn-t2 .bs-push-noti-button:before {
  font: normal normal normal 14px/1 bs-icons;
  content: "끡";
  margin-right: 5px;
  vertical-align: middle;
}

.bs-push-noti.bspn-t2 .bs-push-noti-button {
  text-transform: capitalize !important;
  line-height: 20px;
  font-weight: bolder;
}

.bs-box-inner {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.bs-box-inner:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 70%;
}

.bs-box .box-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.bs-box .box-image {
  background-repeat: no-repeat !important;
  background-position: top center !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bs-box-1 .bs-box-inner {
  background: #ccc;
}

.bs-box-1 .box-text {
  position: absolute;
  background: #fff;
  color: #333;
  font-size: 16px;
  text-align: center;
  padding: 20px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  opacity: 1;
  pointer-events: none;
}

.bs-box-1 .box-image:before {
  border: 1px solid #fff;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1, 1);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.bs-box-1:hover .box-image:before {
  bottom: 15px;
  left: 15px;
  opacity: 1;
  right: 15px;
  top: 15px;
}

.bs-box-1 .box-pre-title {
  font-size: 13px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.bs-box-1 .box-title {
  font-size: 22px;
  margin: 0;
}

.bs-box-2 .box-image:after {
  border: 1px solid #fff;
  content: "";
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  bottom: 15px;
  left: 15px;
  opacity: 1;
  right: 15px;
  top: 15px;
}

.bs-box-2 .box-text {
  position: absolute;
  top: 50%;
  pointer-events: none;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  -webkit-backface-visibility: hidden;
  text-align: center;
}

.bs-box-2 .box-image:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.bs-box-2:hover .box-image:before {
  background: rgba(0, 0, 0, 0.3);
}

.bs-box-2 .box-title {
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
  padding: 5px 10px;
  line-height: 1.3;
  background: #fff;
  color: #353535;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: inline-block;
}

.bs-box-3 .box-text {
  position: absolute;
  font-size: 16px;
  padding: 0;
  bottom: 25px;
  left: 25px;
  right: 25px;
  opacity: 1;
  pointer-events: none;
}

.bs-box-3 .box-image:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.bs-box-3:hover .box-image:before {
  background: rgba(0, 0, 0, 0.35);
}

.bs-box-3 .box-sub-title {
  font-size: 13px;
  margin-bottom: 5px;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
}

.bs-box-3 .box-title {
  font-size: 17px;
  margin: 0 0 10px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
}

.bs-box-3 .bf-icon {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
  font-size: 24px;
  display: block;
  margin-bottom: 15px;
}

.bs-box-3.box-no-bg .bf-icon, .bs-box-3.box-no-bg .box-sub-title, .bs-box-3.box-no-bg .box-title {
  color: #333;
  text-shadow: none;
}

.bs-box-3.box-no-bg {
  background: #eee;
}

.bs-box-3.box-no-bg .box-image:before {
  display: none;
}

.bs-box-3.box-text-left .bf-icon, .bs-box-3.box-text-left .box-sub-title, .bs-box-3.box-text-left .box-title {
  text-align: left !important;
}

.bs-box-3.box-text-right .bf-icon, .bs-box-3.box-text-right .box-sub-title, .bs-box-3.box-text-right .box-title {
  text-align: right !important;
}

.bs-box-3.box-text-center .bf-icon, .bs-box-3.box-text-center .box-sub-title, .bs-box-3.box-text-center .box-title {
  text-align: center !important;
}

.bs-box-4 .bs-box-inner:before {
  padding-top: 120%;
}

.bs-box-4 .box-text {
  position: absolute;
  font-size: 16px;
  padding: 0;
  bottom: 25px;
  left: 25px;
  right: 25px;
  opacity: 1;
  pointer-events: none;
}

.bs-box-4 .box-image:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.bs-box-4:hover .box-image:before {
  background: rgba(0, 0, 0, 0.35);
}

.bs-box-4 .box-sub-title {
  font-size: 13px;
  margin-bottom: 5px;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
}

.bs-box-4 .box-title {
  font-size: 17px;
  margin: 0 0 10px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
}

.bs-box-4 .bf-icon {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
  font-size: 24px;
  display: block;
  margin-bottom: 15px;
}

.bs-box-4.box-no-bg .bf-icon, .bs-box-4.box-no-bg .box-sub-title, .bs-box-4.box-no-bg .box-title {
  color: #333;
  text-shadow: none;
}

.bs-box-4.box-no-bg {
  background: #eee;
}

.bs-box-4.box-no-bg .box-image:before {
  display: none;
}

.bs-box-4.box-text-left .bf-icon, .bs-box-4.box-text-left .box-sub-title, .bs-box-4.box-text-left .box-title {
  text-align: left !important;
}

.bs-box-4.box-text-right .bf-icon, .bs-box-4.box-text-right .box-sub-title, .bs-box-4.box-text-right .box-title {
  text-align: right !important;
}

.bs-box-4.box-text-center .bf-icon, .bs-box-4.box-text-center .box-sub-title, .bs-box-4.box-text-center .box-title {
  text-align: center !important;
}

.listing-widget, .widget .listing-widget {
  padding: 0;
  margin: 0;
  list-style: none;
}

.listing-widget .listing-item img.wp-post-image {
  max-width: 80px;
  float: left;
  margin-right: 15px;
}

.listing-widget .listing-item {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.listing-widget .listing-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.listing-widget .img-holder {
  width: 80px;
}

.listing-widget .img-holder:before {
  padding-top: 70%;
}

.listing-widget .listing-item .post-meta {
  margin-top: 2px;
}

@media only screen and (max-width: 678px) {
  .footer-widgets > .content-wrap > .container > .row > :last-child {
    margin-bottom: 0 !important;
  }
}
.listing-widget.listing-widget-simple .listing-item .title {
  font-size: 14px;
  margin: 0;
}

.listing-widget.listing-widget-simple .listing-item .title a {
  line-height: 1.4;
}

.listing-widget.listing-widget-simple .listing-item .post-meta {
  margin-top: 5px;
  margin-bottom: 8px;
}

.listing-widget.listing-widget-thumbnail .listing-item .title {
  font-size: 14px;
  margin: 0;
  line-height: 1.2;
}

.separator-line {
  margin: 15px auto;
  width: 70%;
  overflow: hidden;
  text-align: center;
  position: relative;
  height: 20px;
  color: #949494;
}

.separator-line .sep-icon {
  padding: 0 25px;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  position: relative;
  z-index: 1;
  color: rgba(0, 0, 0, 0.35);
}

.separator-line .sep-icon:after {
  content: "";
  height: 2px;
  width: 440%;
  top: 50%;
  left: -440%;
  position: absolute;
  margin-top: -1px;
  background-color: #dadada;
  background-color: rgba(0, 0, 0, 0.15);
  display: inline-block;
}

.separator-line .sep-icon:before {
  content: "";
  height: 2px;
  width: 440%;
  top: 50%;
  right: -440%;
  position: absolute;
  margin-top: -1px;
  background-color: #dadada;
  background-color: rgba(0, 0, 0, 0.15);
  display: inline-block;
}

.sidebar > .widget:last-child, .widget > .bs-listing {
  margin-bottom: 0;
}

.widget ol, .widget ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.widget li {
  margin-bottom: 7px;
}

.widget li:last-child {
  margin-bottom: 0;
}

.widget li > ul {
  margin-top: 10px;
}

.widget img {
  max-width: 100%;
  height: auto;
}

.widget.widget_text img {
  margin: 10px 0 15px 0;
}

.widget select {
  width: 100%;
}

.sidebar-column .widget > .bs-listing:last-child {
  margin-bottom: 0;
}

.widget > .better-weather {
  margin-bottom: 0;
}

.widget.widget_archive ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.widget.widget_archive ul li a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f187";
  margin-right: 10px;
}

.widget.widget_archive ul li a {
  font-size: 14px;
}

.widget.widget_archive ul li {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.widget.widget_archive ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.widget.widget_archive .post-count {
  float: right;
  color: #444;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  background: #eee;
  text-align: center;
}

.widget.widget_calendar {
  font-size: 13px;
}

.widget.widget_calendar table {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.widget.widget_calendar table td, .widget.widget_calendar table th {
  text-align: center;
  padding: 5px 0;
}

.widget.widget_calendar table td.active-day {
  background-color: #444;
  background-color: rgba(0, 0, 0, 0.71);
}

.widget.widget_calendar table td.active-day:hover {
  background-color: #444;
  background-color: rgba(0, 0, 0, 0.91);
}

.widget.widget_calendar table td, .widget.widget_calendar table td:hover {
  background-color: #dcdcdc;
  background-color: rgba(0, 0, 0, 0.08);
  color: #888;
  color: rgba(0, 0, 0, 0.51);
}

.widget.widget_calendar table td.pad, .widget.widget_calendar table td.pad:hover {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.03);
}

.widget.widget_calendar table td a {
  color: #fff;
}

.widget.widget_calendar table caption {
  padding: 20px 5px;
  text-align: center;
  background-color: #444;
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 18px;
  color: #fff;
  margin-bottom: 2px;
}

.widget.widget_calendar table tfoot td, .widget.widget_calendar table th {
  background-color: #444;
  background-color: rgba(0, 0, 0, 0.71);
  color: #fff;
}

.widget.widget_calendar table tfoot td:hover {
  background-color: #dedede;
}

.widget.widget_categories ul {
  list-style: none;
  padding-left: 0;
}

.widget.widget_categories ul:last-child {
  margin-bottom: 0;
}

.widget.widget_categories ul li {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.widget.widget_categories ul li a {
  text-transform: capitalize;
  font-size: 14px;
}

.widget.widget_categories ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget.widget_categories ul li a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f07c";
  margin-right: 10px;
}

.widget.widget_categories .children a:before {
  content: "\f115";
}

.widget.widget_categories .post-count {
  float: right;
  color: #444;
  min-width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  padding: 0 4px;
  background: #eee;
  background: rgba(0, 0, 0, 0.08);
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget.widget_categories ul.children {
  border-top: 1px solid #f5f5f5;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 5px;
  padding-top: 5px;
  padding-left: 25px;
}

.widget.widget_categories ul.children li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget.widget_nav_menu .bf-icon {
  margin-left: 5px;
  margin-right: 7px;
}

.widget.widget_nav_menu ul.menu li.menu-have-icon.menu-title-hide > a > .bf-icon {
  margin-right: 5px;
}

.widget.widget_nav_menu ul.menu {
  font-size: 16px;
}

.widget.widget_nav_menu ul, .widget.widget_nav_menu ul ul {
  list-style: none;
  margin-top: 0;
  padding: 0;
}

.widget.widget_nav_menu ul.menu > li .sub-menu {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: auto;
  height: auto;
  background: 0 0;
}

.widget.widget_nav_menu ul.menu > li .sub-menu .sub-menu {
  left: 0;
}

.widget.widget_nav_menu ul.menu li {
  display: block;
  float: none;
  margin-bottom: 2px;
  padding: 0;
}

.widget.widget_nav_menu ul.menu li:before {
  content: "";
  display: none;
}

.widget.widget_nav_menu ul.menu li.menu-title-hide span.hidden {
  display: inline !important;
  visibility: visible !important;
}

.widget.widget_nav_menu ul.menu li a {
  line-height: 24px;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #dedede;
  display: block;
  position: relative;
  width: 100%;
}

.widget.widget_nav_menu ul.menu li a .description {
  display: none;
}

.widget.widget_nav_menu ul.menu li > a:hover {
  color: #fff !important;
  border-color: transparent !important;
}

.widget.widget_nav_menu ul.menu li a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0da";
  margin-left: 7px;
  margin-right: 7px;
}

.widget.widget_nav_menu ul.menu ul li a:before {
  content: "\f105";
}

.widget.widget_nav_menu ul.menu li .sub-menu li.menu-have-icon > a:before, .widget.widget_nav_menu ul.menu li.menu-have-icon a:before {
  display: none;
}

.widget.widget_nav_menu ul.menu ul li:first-child {
  margin-top: 2px;
}

.widget.widget_nav_menu ul.menu ul li {
  margin-left: 15px;
}

.widget.widget_nav_menu ul.menu ul ul li {
  margin-left: 15px;
}

.widget.widget_nav_menu ul.menu .sub-menu > li:last-child a {
  border-bottom: 1px solid #dedede !important;
}

.widget.widget_nav_menu ul.menu .sub-menu > li:last-child a:hover {
  border-bottom-color: transparent !important;
}

.widget.widget_nav_menu ul.menu li .sub-menu > li > a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0da";
}

.widget.widget_nav_menu .menu .better-custom-badge {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  color: #fff !important;
  font-family: "Open Sans";
  font-size: 10px;
  padding: 0 5px;
  margin-left: 13px;
  font-weight: 700;
  z-index: 1;
  text-transform: uppercase;
  text-align: center;
  min-width: 19px;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 8px;
}

.widget.widget_nav_menu .menu .better-custom-badge:after {
  border: solid 4px transparent;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: -8px;
  margin-top: -4px;
}

.ltr .widget.widget_nav_menu .menu .better-custom-badge:after {
  border-left-color: transparent !important;
}

.widget.widget_nav_menu ul.menu li.current-menu-item > a {
  border-color: transparent;
  color: #fff;
}

.widget.widget_nav_menu ul.menu > li > .mega-menu {
  display: none;
}

.widget.widget_nav_menu ul.menu li.menu-item-has-children > a:after {
  display: none;
}

.widget.widget_nav_menu ul.menu .mega-menu, .widget.widget_nav_menu ul.menu .sub-menu {
  display: block;
  visibility: visible;
  opacity: 1 !important;
  transform: none !important;
  transition: none !important;
  animation-name: none !important;
  position: relative;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  background: 0 0 !important;
  width: 100%;
}

.widget.widget_pages ul {
  list-style: none;
  padding-left: 0;
}

.widget.widget_pages ul li {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.widget.widget_pages ul li a {
  font-size: 13px;
}

.widget.widget_pages ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget.widget_pages ul li a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f15b";
  margin-right: 10px;
}

.widget.widget_recent_comments a {
  border-bottom: 1px solid #e7e7e7;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.widget.widget_recent_comments ul {
  list-style: none;
  padding-left: 0;
}

.widget.widget_recent_comments ul li {
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.widget.widget_recent_comments ul li a {
  font-size: 13px;
}

.widget.widget_recent_comments ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget.widget_recent_comments ul li:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f075";
  margin-right: 10px;
}

.widget.widget_recent_entries ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.widget.widget_recent_entries ul li {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.widget.widget_recent_entries ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget.widget_recent_entries li a {
  font-size: 14px;
}

.widget.widget_recent_entries li .post-date {
  font-style: italic;
  display: block;
  font-size: 12px;
}

.widget.widget_rss li .rsswidget {
  font-size: 14px;
}

.widget.widget_rss li .rss-date {
  display: block;
  font-style: italic;
}

.widget.widget_rss li .rssSummary {
  font-size: 13px;
}

.search-header .search-form, .widget.widget_display_search form[role=search], .widget.widget_product_search .woocommerce-product-search, .widget.widget_search .search-form {
  position: relative;
}

.search-header .search-form .search-field, .widget.widget_display_search form[role=search] input#bbp_search, .widget.widget_product_search .search-field, .widget.widget_search .search-form input[type=search] {
  width: 100%;
  padding-right: 20%;
  margin: 0;
  line-height: 36px;
  height: 36px;
}

.search-header .search-form .search-submit, .widget.widget_display_search form[role=search] input#bbp_search_submit, .widget.widget_product_search input[type=submit], .widget.widget_search .search-form .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 36px;
  height: 36px;
  padding: 0 16px;
}

.widget.widget_tag_cloud .tagcloud a {
  display: inline-block;
  line-height: 16px;
  color: #444;
  background-color: #fff;
  border: 1px solid #dedede;
  margin: 0 6px 6px 0;
  padding: 5px 7px;
  font-size: 12px !important;
  font-weight: 400;
}

.widget.widget_tag_cloud .tagcloud a:hover {
  color: #fff;
  border-color: transparent;
}

.bs-social-share .social-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bs-social-share span.social-item {
  float: left;
  margin-right: 6px;
  padding: 0;
}

.bs-social-share span.social-item a {
  font-family: "Open Sans", sans-serif;
  border-radius: 2px;
}

.bs-social-share span.social-item.bbm, .bs-social-share span.social-item.line, .bs-social-share span.social-item.whatsapp {
  display: none;
}

.widget_bs-theme-social-share .bs-social-share span.social-item {
  float: none;
  display: inline-block;
}

@media only screen and (max-width: 780px) {
  .bs-social-share span.social-item.bbm, .bs-social-share span.social-item.line, .bs-social-share span.social-item.whatsapp {
    display: block;
  }
}
.bs-social-share.style-button span.social-item a {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  padding: 0 13px;
  background-color: #8d8d8d;
  display: block;
  color: #fff !important;
  margin-bottom: 7px;
}

.bs-social-share.style-button span.social-item a:hover {
  background-color: #7c7c7c;
}

.bs-social-share.style-button span.social-item a span {
  margin-left: 7px;
}

.bs-social-share.style-button span.social-item a span:last-child:first-child {
  margin-right: 0;
  margin-left: 0;
}

.bs-social-share.style-button.no-title-style span.social-item a {
  width: 40px;
  text-align: center;
  padding: 0;
}

.bs-social-share.style-button.no-title-style span.social-item a .fa {
  margin-right: 0;
}

.bs-social-share.style-button.colored span.social-item.facebook a {
  background-color: #4a639f;
}

.bs-social-share.style-button.colored span.social-item.facebook a:hover {
  background-color: #5271b3;
}

.bs-social-share.style-button.colored span.social-item.twitter a {
  background-color: #59c8ff;
}

.bs-social-share.style-button.colored span.social-item.twitter a:hover {
  background-color: #58d1ff;
}

.bs-social-share.style-button.colored span.social-item.google_plus a {
  background-color: #d73d32;
}

.bs-social-share.style-button.colored span.social-item.google_plus a:hover {
  background-color: #e64035;
}

.bs-social-share.style-button.colored span.social-item.pinterest a {
  background-color: #cb2027;
}

.bs-social-share.style-button.colored span.social-item.pinterest a:hover {
  background-color: #e32229;
}

.bs-social-share.style-button.colored span.social-item.linkedin a {
  background-color: #0266a0;
}

.bs-social-share.style-button.colored span.social-item.linkedin a:hover {
  background-color: #027ec1;
}

.bs-social-share.style-button.colored span.social-item.tumblr a {
  background-color: #3e5a70;
}

.bs-social-share.style-button.colored span.social-item.tumblr a:hover {
  background-color: #426283;
}

.bs-social-share.style-button.colored span.social-item.email a {
  background-color: #0c151c;
}

.bs-social-share.style-button.colored span.social-item.email a:hover {
  background-color: #1a2a39;
}

.bs-social-share.style-button.colored span.social-item.telegram a {
  background-color: #179cde;
}

.bs-social-share.style-button.colored span.social-item.telegram a:hover {
  background-color: #0f8dde;
}

.bs-social-share.style-button.colored span.social-item.whatsapp a {
  background-color: #00e676;
}

.bs-social-share.style-button.colored span.social-item.whatsapp a:hover {
  background-color: #00ca65;
}

.bs-social-share.style-button.colored span.social-item.reddit a {
  background-color: #ff4500;
}

.bs-social-share.style-button.colored span.social-item.reddit a:hover {
  background-color: #dc3c01;
}

.bs-social-share.style-button.colored span.social-item.stumbleupon a {
  background-color: #ee4813;
}

.bs-social-share.style-button.colored span.social-item.stumbleupon a:hover {
  background-color: #ce3d0f;
}

.bs-social-share.style-button.colored span.social-item.vk a {
  background-color: #4c75a3;
}

.bs-social-share.style-button.colored span.social-item.vk a:hover {
  background-color: #3e5f84;
}

.bs-social-share.style-button.colored span.social-item.digg a {
  background-color: #000;
}

.bs-social-share.style-button.colored span.social-item.digg a:hover {
  background-color: #2f2f2f;
}

.bs-social-share.style-button.colored span.social-item.line a {
  background-color: #00b900;
}

.bs-social-share.style-button.colored span.social-item.line a:hover {
  background-color: #00b900;
}

.bs-social-share.style-button.colored span.social-item.line .fa.fa-line-it {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAxlBMVEX///8AuwAAtwAAvQD9//1O0kx23Xaa5Zly3HEryij7/vvw+/Ds+uzZ9tnR9NGM4YyK4IpJ0Ug6zDgdxhn3/ffl+OTX9te/77657bix67Gp6amk6KOC34Fr2mph1mBd1VxCzkBB0D8DwQD0/fTo+eff9t/K8srD8MO77rqu6q6d5ZyR45GH4IZ+331u2m1U1FI1yzIwyi0vyiwiyB4NxQMAvwDt+u3e9t7M8sy37LaT4pKP4Y963Xk+zTwmyCIYxBITwwsAtABqI5GFAAABAklEQVQY022RV5KDMBBEm5FENsE4kW3WOYf15nj/S60KKFym9v3paWY+uqH8S62poda1/Rbq+1uSn1j1gVJ2188osV4zRpUmtn1CAx+dpIe0Ee7ofJICUjSg5QMCiR7arOWRFXg8RC9aagPEQy3dd2B94acD09BhT/THSV/sg8vBATIcbZjHHSxfNVgq1LNQB0ACow+zq5pTX5fz1+QsPqRWcX0B7xa+J1JjsWG7i5E74D7IBZbudrSYh3YvdELXm8EpQMEMLXhGUOjw0NIaSa1QPr2zsQwLZYCr2wLXGNV5kzL2hn0Oia3L160GYkEEYD5uaqigXxeWVzSlNWxG9ajkD+0mEhtxMt1EAAAAAElFTkSuQmCC);
  display: inline-block;
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-top: -6px;
  margin-left: -5px;
}

.bs-social-share.style-button.colored span.social-item.bbm a {
  background-color: #1f1f1f;
}

.bs-social-share.style-button.colored span.social-item.bbm a:hover {
  background-color: #3c3c3c;
}

.bs-social-share.style-button.colored span.social-item.bbm .fa.fa-bbm {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAkFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+WABnwAAAAL3RSTlMABezkG/XZhyYiCgOqO/Hb0sO+t7SejnluXVdAMh0SDuHe1UtHLSncyK6Zk4JyadjoMwwAAADLSURBVBjTtZBZrsIwDEVvmjRDWzrTuWXmjcD+d0cSWUjwicT5OfJV7MjGR0k45/kV6K2z34XSDb9ZZIz1zXGkeOWrGputc3qiuP+SMv/TOOdS7pMjWKeU6hEzxmLAm1mL0HVhEUJEACLr5TEUVRAE+wNwsd62DK1LQ6ROBVD6VxOGpmn+R/gqwexHBgaE7d2tNUZrXo+AnqZTBJy1Fu5HY8xsPe/CMP1e4YWBhhJtVdXKgE7QPV2mZFBFUf507OkyWQTamThwSzbgPe52ABZlYOfWsgAAAABJRU5ErkJggg==);
  display: inline-block;
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-top: -6px;
  margin-left: -3px;
}

.bs-social-share.style-button.colored span.social-item.viber a {
  background-color: #5d54a4;
}

.bs-social-share.style-button.colored span.social-item.viber .fa.fa-viber {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAtFBMVEVmXaxlW6taTqVeVKhjWadcUKb///9iWKZhVqnu7fZWS6P8/P5pX65iWKphWKSQisR9dLlrYq9YTaO2sdhyabNTR6Hw7/fm5fKsp9Kjnc6gmsyclsqIgb+BeLt5cLZ1bLNuZLD5+fzq6fTAu927t9uoo9CWkMf39vvRzufOy+Wwq9SalMlwZrFcUaP08/nf3e7IxOHFweCxrNSNhsKEfb3i4fDLx+O4s9mzrtZOQp/W1OnV0+nSxjEiAAABXUlEQVQY0z2Qh5LjIAxABQoEAz737tiOW3rPtrv//6/D8e6+GTTMk0bSCGYEo5RR85DAL8i8Mvvs+2BwamQ/CRbuk/XZMpzX8e2J9mxXCX8f3bABr3oEscpf9cJ7U478kghuSCU97njJjKYFz78gW9EwS4sG/WbzTxAgeLWA9fzS4GPYp0dBO1Uh2JC8S6/l/C7LILvntn/gDgNxtAIfA74pmFd8pEMoS1VQwDpOKdabFgRq55C5crXOvzXQT+5EgJKYbV5ahNbeRNjGz+joaEpYqQ4UbJ10FIB5F/Wx5dvKpuM0kuDuzbYBsOm5YYey5y4C0Ny0JcazcqfWRaTjCzFlomkt17c1ERKfbqRvfGTzqSwVeEJorb3V3uLpdCoChFbX1o2GJGnPavP3gGKSBoZD9dh2t+4+VoIuCJn1ArOgq6OT7/un5WICXvE0Xmt/sZzV0gDLiT8mM3+++Q/56h8tpySg5AAAAABJRU5ErkJggg==);
  display: inline-block;
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-top: -4px;
  margin-left: -5px;
}

.bs-social-share.style-outline-button span.social-item a {
  height: 32px;
  line-height: 28px;
  font-size: 13px;
  padding: 0 13px;
  display: block;
  color: #5e5e5e;
  border: 2px solid #a1a1a1;
  margin-bottom: 7px;
}

.bs-social-share.style-outline-button span.social-item a .fa {
  margin-right: 5px;
}

.bs-social-share.style-outline-button.no-title-style span.social-item a {
  width: 40px;
  text-align: center;
}

.bs-social-share.style-outline-button.no-title-style span.social-item a .fa {
  margin-right: 0;
}

.bs-social-share.style-outline-button.colored span.social-item.facebook a {
  border-color: #4a639f;
  color: #4a639f;
}

.bs-social-share.style-outline-button.colored span.social-item.facebook a:hover {
  border-color: #5271b3;
  color: #5271b3;
}

.bs-social-share.style-outline-button.colored span.social-item.twitter a {
  border-color: #59c8ff;
  color: #59c8ff;
}

.bs-social-share.style-outline-button.colored span.social-item.twitter a:hover {
  border-color: #58d1ff;
  color: #58d1ff;
}

.bs-social-share.style-outline-button.colored span.social-item.google_plus a {
  border-color: #d73d32;
  color: #d73d32;
}

.bs-social-share.style-outline-button.colored span.social-item.google_plus a:hover {
  border-color: #e64035;
  color: #e64035;
}

.bs-social-share.style-outline-button.colored span.social-item.pinterest a {
  border-color: #cb2027;
  color: #cb2027;
}

.bs-social-share.style-outline-button.colored span.social-item.pinterest a:hover {
  border-color: #e32229;
  color: #e32229;
}

.bs-social-share.style-outline-button.colored span.social-item.linkedin a {
  border-color: #0266a0;
  color: #0266a0;
}

.bs-social-share.style-outline-button.colored span.social-item.linkedin a:hover {
  border-color: #027ec1;
  color: #027ec1;
}

.bs-social-share.style-outline-button.colored span.social-item.tumblr a {
  border-color: #3e5a70;
  color: #3e5a70;
}

.bs-social-share.style-outline-button.colored span.social-item.tumblr a:hover {
  border-color: #426283;
  color: #426283;
}

.bs-social-share.style-outline-button.colored span.social-item.email a {
  border-color: #0c151c;
  color: #0c151c;
}

.bs-social-share.style-outline-button.colored span.social-item.email a:hover {
  border-color: #1a2a39;
  color: #1a2a39;
}

.bs-social-share.style-outline-button.colored span.social-item.telegram a {
  border-color: #179cde;
  color: #179cde;
}

.bs-social-share.style-outline-button.colored span.social-item.telegram a:hover {
  border-color: #179cde;
  color: #179cde;
}

.bs-social-share.style-outline-button.colored span.social-item.whatsapp a {
  border-color: #00e676;
  color: #00e676;
}

.bs-social-share.style-outline-button.colored span.social-item.whatsapp a:hover {
  border-color: #00ca65;
  color: #00ca65;
}

.bs-about .about-title {
  text-align: center;
  margin: 0 0 15px;
}

.bs-about .about-text {
  text-align: center;
}

.bs-about .about-link {
  text-align: center;
  margin-top: 10px;
}

.bs-about .about-link a {
  font-weight: 700;
  font-size: 14px;
}

.bs-about .about-icons-list {
  text-align: center;
  margin: 17px 0 0;
  padding: 0;
}

.bs-about .about-icons-list .about-icon-item {
  display: inline-block;
  margin: 0 5px 5px 0;
}

.bs-about .about-icons-list .about-icon-item a {
  width: 28px;
  height: 28px;
  display: inline-block;
  color: #333;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.22);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.22);
  text-align: center;
  line-height: 28px;
  font-size: 16px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bs-about .about-icons-list .about-icon-item a:hover {
  color: #fff;
  box-shadow: none;
}

.bs-about .about-icons-list .about-icon-item a .bsfi, .bs-about .about-icons-list .about-icon-item a .fa {
  vertical-align: top;
  line-height: 28px;
}

.bs-about .about-icons-list .about-icon-item.facebook a:hover {
  background-color: #4a639f;
}

.bs-about .about-icons-list .about-icon-item.twitter a:hover {
  background-color: #59c8ff;
}

.bs-about .about-icons-list .about-icon-item.google-plus a:hover {
  background-color: #d73d32;
}

.bs-about .about-icons-list .about-icon-item.instagram a:hover {
  background: #f77638;
  background: -webkit-radial-gradient(circle farthest-corner at 35% 90%, #fec564, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(circle farthest-corner at 0 140%, #fec564, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, rgba(0, 0, 0, 0)), -webkit-linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, rgba(0, 0, 0, 0) 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, rgba(0, 0, 0, 0)), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.bs-about .about-icons-list .about-icon-item.youtube a:hover {
  background-color: #cc181e;
}

.bs-about .about-icons-list .about-icon-item.youtube a:hover {
  background-color: #cc181e;
}

.bs-about .about-icons-list .about-icon-item.dribbble a:hover {
  background-color: #ea4c89;
}

.bs-about .about-icons-list .about-icon-item.vimeo a:hover {
  background-color: #46a3e9;
}

.bs-about .about-icons-list .about-icon-item.behance a:hover {
  background-color: #3b63fc;
}

.bs-about .about-icons-list .about-icon-item.pinterest a:hover {
  background-color: #cb2027;
}

.bs-about .about-icons-list .about-icon-item.github a:hover {
  background-color: #4c4c4c;
}

.bs-about .about-icons-list .about-icon-item.email a:hover {
  background-color: #333;
}

.bs-about .about-icons-list .about-icon-item.telegram a:hover {
  background-color: #179cde;
}

.bs-about .about-icons-list .about-icon-item.vk a:hover {
  background-color: #4e729a;
}

.bs-popular-categories .bs-popular-terms-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-column-secondary .bs-popular-categories .bs-popular-terms-list, .vc_col-sm-2 .bs-popular-categories .bs-popular-terms-list {
  padding: 0;
}

.bs-popular-categories .bs-popular-term-item {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  font-size: 13px;
  line-height: 24px;
}

.bs-popular-categories .bs-popular-term-item .term-count, .bs-popular-categories .bs-popular-term-item a {
  color: #333;
}

.bs-popular-categories .bs-popular-term-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.bs-popular-categories .bs-popular-term-item .term-count {
  float: right;
  display: inline-block;
  background: rgba(0, 0, 0, 0.08);
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 0 6px;
}

.bs-popular-categories .bs-popular-term-item:hover .term-count {
  color: #fff;
}

.bs-popular-categories .bs-popular-term-item:hover a {
  color: inherit;
}

.widget.widget_bs-subscribe-newsletter {
  background: #efefef;
  padding: 27px 23px !important;
}

.bs-subscribe-newsletter .subscribe-image {
  margin-bottom: 15px;
  text-align: center;
  max-width: 312px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
}

.bs-subscribe-newsletter .subscribe-message {
  text-align: center;
  max-width: 312px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.bs-subscribe-newsletter .subscribe-message p:last-child {
  margin-bottom: 0;
}

.bs-subscribe-newsletter form {
  width: 100%;
  position: relative;
  max-width: 312px;
  margin-left: auto;
  margin-right: auto;
}

.bs-subscribe-newsletter .newsletter-email {
  color: #5f6569;
  width: 100%;
  border-color: #cbd1d8;
  height: 34px;
  line-height: 34px;
  border-radius: 33px;
  padding: 0 12px;
  font-size: 12px;
}

.bs-subscribe-newsletter .newsletter-subscribe {
  margin-top: 0;
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
  height: 34px;
  line-height: 34px;
  border-radius: 0 33px 33px 0;
  text-transform: capitalize !important;
  cursor: pointer;
  font-size: 13px;
  padding: 0 13px;
}

.bs-subscribe-newsletter .newsletter-subscribe .fa {
  margin-right: 3px;
}

.bs-subscribe-newsletter .powered-by {
  font-size: 12px;
  margin: 5px auto 0;
  max-width: 311px;
  color: #b7b7b7;
  text-align: center;
  opacity: 0.6;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  vertical-align: middle;
}

.bs-subscribe-newsletter .powered-by:hover {
  opacity: 1;
}

.bs-flickr .bs-flickr-photo-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.bs-flickr .bs-flickr-photo-list.columns-2 {
  margin: 0;
}

.bs-flickr .bs-flickr-photo-list.columns-2 li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
  text-align: center;
}

.bs-flickr .bs-flickr-photo-list.columns-2 li img {
  width: 100%;
}

.bs-flickr .bs-flickr-photo-list.columns-2 li:nth-child(odd) {
  padding-right: 5px;
}

.bs-flickr .bs-flickr-photo-list.columns-2 li:nth-child(even) {
  padding-left: 5px;
}

.bs-flickr .bs-flickr-photo-list.columns-2 li:nth-child(n+3) {
  clear: right;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li {
  float: left;
  width: 33.333%;
  margin-bottom: 8px;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(1) {
  width: 66.66666%;
  padding-right: 3px !important;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(1) img {
  width: 100%;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(2) {
  padding: 0 0 0 6px !important;
  margin-bottom: 10px;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(3) {
  padding: 0 0 0 6px !important;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li img {
  max-width: 100%;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(3n-1) {
  padding: 0 3px;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(3n-2) {
  padding-right: 6px;
  clear: left;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(3n+3) {
  padding-left: 6px;
}

.bs-flickr .bs-flickr-photo-list.list-photos .bs-flickr-photo {
  float: left;
  width: 12.5%;
}

.bs-flickr .bs-flickr-photo-list.list-photos .bs-flickr-photo img {
  max-width: 100%;
}

.bs-flickr .bs-flickr-photo-list.list-photos {
  margin: 0;
  padding: 0;
}

.bs-flickr-photo .img-holder {
  width: 100%;
}

.bs-flickr-photo .img-holder:before {
  padding-top: 100%;
}

.bs-dribbble .bs-dribbble-shot-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.bs-dribbble .bs-dribbble-shot-list li:before {
  display: none;
}

.bs-dribbble .bs-dribbble-shot-list li {
  padding: 0;
  margin-bottom: 10px;
  text-align: center;
}

.bs-dribbble .bs-dribbble-shot-list.columns-2 li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
}

.bs-dribbble .bs-dribbble-shot-list.columns-2 li:nth-child(odd) {
  padding-right: 5px;
}

.bs-dribbble .bs-dribbble-shot-list.columns-2 li:nth-child(even) {
  padding-left: 5px;
}

.bs-dribbble .bs-dribbble-shot-list.columns-2 li:nth-child(n+3) {
  clear: right;
}

.bs-dribbble .bs-dribbble-shot-list.columns-3 li {
  float: left;
  width: 33.333%;
}

.bs-dribbble .bs-dribbble-shot-list.columns-3 li:nth-child(3n-1) {
  padding: 0 3px;
}

.bs-dribbble .bs-dribbble-shot-list.columns-3 li:nth-child(3n-2) {
  padding-right: 6px;
  clear: left;
}

.bs-dribbble .bs-dribbble-shot-list.columns-3 li:nth-child(3n+3) {
  padding-left: 6px;
}

.dribbble-shot .img-holder {
  width: 100%;
}

.bs-google-plus > div {
  margin-right: auto !important;
  margin-left: auto !important;
  display: block !important;
}

.bs-embed .bs-embed-item iframe {
  max-width: 100%;
}

.bs-embed .bs-embed-item {
  margin-bottom: 15px;
}

.bs-embed .bs-embed-item:last-child {
  margin-bottom: 0;
}

.bs-instagram .bs-instagram-photo-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.bs-instagram-photo {
  position: relative;
  z-index: 1;
  width: 100%;
}

.bs-instagram-photo a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  float: none;
  margin: 0;
}

.bs-instagram-photo a, .bs-instagram-photo a:hover {
  color: #fff;
}

.bs-instagram-photo a:before {
  padding-top: 100%;
}

.bs-instagram-photo .img-holder {
  background-color: #e6e6e6;
}

.bs-instagram-photo .img-holder.b-loaded, body.bs-ll-d .bs-instagram-photo .img-holder {
  background-size: cover;
  background-color: transparent;
}

.bs-instagram-photo a .the-hover:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.35);
  display: block;
  -webkit-transition: all 0.45s ease;
  -moz-transition: all 0.45s ease;
  -o-transition: all 0.45s ease;
  transition: all 0.45s ease;
  opacity: 0;
}

.bs-instagram-photo a .the-hover:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f16d";
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  left: 50%;
  margin-left: -20px;
  font-size: 30px;
  opacity: 0;
  -webkit-transition: all 0.45s ease;
  -moz-transition: all 0.45s ease;
  -o-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

.bs-instagram-photo:hover a .the-hover:after, .bs-instagram-photo:hover a .the-hover:before {
  opacity: 1;
}

.bs-instagram .bs-instagram-photo-list li:before {
  display: none;
}

.bs-dribbble .bs-instagram-photo-list li {
  padding: 0;
  margin-bottom: 8px;
  text-align: center;
}

.bs-dribbble .bs-instagram-photo-list li br {
  display: none;
}

.bs-instagram .bs-instagram-photo-list .bs-instagram-photo img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.bs-instagram .bs-instagram-photo-list.columns-2 li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
  max-height: 155px;
  overflow: hidden;
}

.bs-instagram .bs-instagram-photo-list.columns-2 li:nth-child(odd) {
  padding-right: 5px;
}

.bs-instagram .bs-instagram-photo-list.columns-2 li:nth-child(even) {
  padding-left: 5px;
}

.bs-instagram .bs-instagram-photo-list.columns-2 li:nth-child(n+3) {
  clear: right;
}

.bs-instagram .bs-instagram-photo-list.columns-2-1 li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
  max-height: 155px;
  overflow: hidden;
}

.bs-instagram .bs-instagram-photo-list.columns-2-1 li:first-child {
  width: 100%;
  max-height: inherit;
  float: none;
  padding: 0 !important;
}

.bs-instagram .bs-instagram-photo-list.columns-2-1 li:nth-child(even) {
  padding-right: 5px;
}

.bs-instagram .bs-instagram-photo-list.columns-2-1 li:nth-child(odd) {
  padding-left: 5px;
}

.bs-instagram .bs-instagram-photo-list.columns-2-1 li:nth-child(n+3) {
  clear: right;
}

.bs-instagram .bs-instagram-photo-list.columns-3 li {
  float: left;
  width: 33.333%;
  overflow: hidden;
  margin-bottom: 10px;
}

.bs-instagram .bs-instagram-photo-list.columns-3 li:nth-child(3n-1) {
  padding: 0 3px;
}

.bs-instagram .bs-instagram-photo-list.columns-3 li:nth-child(3n-2) {
  padding-right: 6px;
  clear: left;
}

.bs-instagram .bs-instagram-photo-list.columns-3 li:nth-child(3n+3) {
  padding-left: 6px;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li {
  float: left;
  width: 33.333%;
  margin-bottom: 8px;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(1) {
  width: 66.66666%;
  padding-right: 3px !important;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(1) img {
  width: 100%;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(2) {
  padding: 0 0 0 6px !important;
  margin-bottom: 10px;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(3) {
  padding: 0 0 0 6px !important;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li img {
  max-width: 100%;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(3n-1) {
  padding: 0 3px;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(3n-2) {
  padding-right: 6px;
  clear: left;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(3n+3) {
  padding-left: 6px;
}

.bs-instagram .bs-instagram-photo-list.list-photos .bs-instagram-photo {
  float: left;
  width: 12.5%;
}

.bs-instagram .bs-instagram-photo-list.list-photos .bs-instagram-photo img {
  max-width: 100%;
}

.bs-instagram .bs-instagram-photo-list.list-photos .bs-instagram-photo-list {
  margin: 0;
  padding: 0;
}

.bs-instagram .better-direction-nav .better-next, .bs-instagram .better-direction-nav .better-prev {
  margin-top: -26px;
}

.better-newsticker {
  min-height: 24px;
  max-height: 24px;
  overflow: hidden;
  position: relative;
  margin: 0 0 20px 0;
}

.better-newsticker .heading {
  display: inline-block;
  width: 90px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 24px;
  font-size: 12px;
  padding: 0;
  text-transform: uppercase !important;
  margin: 0;
  color: #fff;
  text-align: center;
  border-radius: 3px;
}

.better-newsticker .heading:after {
  content: "";
  display: inline-block;
  border-top: 6px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 6px solid transparent;
  position: absolute;
  right: -7px;
  top: 50%;
  margin-top: -6px;
}

.better-newsticker ul.news-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.better-newsticker ul.news-list li {
  margin: 0;
  padding: 0 15px 0 0;
  height: 24px;
  line-height: 24px;
  font-size: 13px;
  width: 65%;
  -webkit-transition: all 0.45s ease;
  -moz-transition: all 0.45s ease;
  -o-transition: all 0.45s ease;
  transition: all 0.45s ease;
  z-index: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.better-newsticker ul.news-list li.active {
  margin: 0;
  z-index: 1;
  opacity: 1;
  left: 110px;
}

.better-newsticker ul.news-list li a {
  color: #696969;
}

.better-newsticker ul.news-list li a:hover {
  color: #0080ce;
}

.better-newsticker .control-nav {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  line-height: 24px;
  direction: ltr;
}

.better-newsticker .control-nav span {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border: 1px solid #eaeaea;
  color: #ccc;
  background: #fff;
  -webkit-transition: all 0.45s ease;
  -moz-transition: all 0.45s ease;
  -o-transition: all 0.45s ease;
  transition: all 0.45s ease;
  cursor: pointer;
  vertical-align: top;
  border-radius: 3px;
}

.better-newsticker .control-nav span:hover {
  border-color: transparent;
  color: #fff;
}

.better-newsticker .control-nav span.prev {
  margin-right: 6px;
}

.newsticker-container {
  margin: 0 0 20px;
}

.layout-1-col .newsticker-container {
  padding-right: 0;
  padding-left: 0;
}

.better-newsticker.header-ticker {
  margin: 0;
}

.wpb_wrapper .better-newsticker {
  margin-left: 20px;
  margin-right: 20px;
}

.entry-content > .vc_row:first-child > .wpb_column > .vc_column-inner > .wpb_wrapper > .better-newsticker {
  margin-top: -5px;
}

.better-newsticker .control-nav span .fa {
  line-height: 22px;
  position: relative;
}

.better-social-counter.style-big-button .social-item, .better-social-counter.style-box .item-icon, .better-social-counter.style-button .item-icon, .better-social-counter.style-clean .item-icon, .better-social-counter.style-modern .item-icon, .better-social-counter.style-style-6 .item-icon {
  background-color: #434343;
}

.better-social-counter.style-big-button .item-icon, .better-social-counter.style-box .item-count, .better-social-counter.style-box .item-title {
  background-color: #313131;
}

.widget_better-social-counter .better-social-counter.style-button .social-list {
  text-align: center;
}

.widget_better-social-counter .better-social-counter.style-button .social-item {
  display: inline-block;
  float: none;
}

.widget_better-social-counter .better-social-counter.style-style-6 .social-item {
  border-bottom: none;
}

.site-footer .better-studio-shortcode, .widget .better-studio-shortcode {
  margin-bottom: 0;
}

.section-heading {
  margin: 0 0 15px;
  font-size: 16px;
  line-height: 20px;
  position: relative;
}

.section-heading p {
  margin-bottom: 0 !important;
  display: inline-block;
}

.section-heading .h-text .bf-icon {
  margin-right: 3px;
  vertical-align: top;
  line-height: inherit;
}

.section-heading .h-text, .section-heading:after {
  display: inline-block;
  -webkit-transition: 0.35s cubic-bezier(0.39, 0.58, 0.57, 1) 0s;
  -moz-transition: 0.35s cubic-bezier(0.39, 0.58, 0.57, 1) 0s;
  -o-transition: 0.35s cubic-bezier(0.39, 0.58, 0.57, 1) 0s;
  transition: 0.35s cubic-bezier(0.39, 0.58, 0.57, 1) 0s;
}

.section-heading:after {
  background: rgba(0, 0, 0, 0.08);
  content: "";
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.section-heading .h-text {
  position: relative;
  z-index: 1;
}

.section-heading .other-link {
  float: right;
  outline: 0;
}

.section-heading .other-link .h-text {
  margin-left: 0;
  color: #484848;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 82%;
  padding: 4px 0 4px 14px;
}

.section-heading.sh-t1:after {
  top: 50%;
  left: 0;
  height: 5px;
  margin-top: -2.5px;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
}

.section-heading.sh-t1 .h-text {
  display: inline-block;
  padding: 4px 17px 4px 0;
  color: #444;
  background: #fff;
}

.section-heading.sh-t1 .other-link .h-text {
  padding: 4px 4px 4px 17px !important;
  background: #fff;
}

.section-heading.sh-t1 .bs-pretty-tabs-container {
  background: #fff;
}

.section-heading.multi-tab.sh-t1 .bs-pretty-tabs-container {
  padding-left: 0;
}

.section-heading.sh-t1 .bs-pretty-tabs .bs-pretty-tabs-container:hover .bs-pretty-tabs-more.other-link .h-text {
  border: 1px solid #d8d8d8;
  border-bottom: none;
}

.section-heading.sh-t1 .bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;
  display: none;
}

.section-heading.sh-t1.sh-s2:after {
  opacity: 0.15;
}

.section-heading.sh-t1.sh-s3:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFNzg0NTFBNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFNzg0NTFCNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUU3ODQ1MTg2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUU3ODQ1MTk2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz461u9NAAAAEUlEQVR42mJgQAX/yeAABBgAgcID/UDOgL8AAAAASUVORK5CYII=) repeat;
  opacity: 0.2;
  height: 8px;
  margin-top: -4px;
  background-color: transparent !important;
}

.section-heading.sh-t1.sh-s4:after {
  display: none;
}

.section-heading.sh-t1.sh-s5 .h-text {
  line-height: 22px;
}

.section-heading.sh-t1.sh-s5 {
  padding-bottom: 7px;
}

.section-heading.sh-t1.sh-s5:after {
  top: inherit;
  left: 0;
  height: 1px;
  margin-top: -0.5px;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  bottom: 0;
}

.section-heading.sh-t1.sh-s5 > .h-text:last-child:first-child:after, .section-heading.sh-t1.sh-s5 > .main-link > .h-text:after, .section-heading.sh-t1.sh-s5 > a:last-child:first-child > .h-text:after {
  display: inline-block;
  margin-left: 16px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  font-weight: 900;
}

.section-heading.sh-t1.sh-s5 > .h-text:last-child:first-child, .section-heading.sh-t1.sh-s5 > .main-link:last-child:first-child > .h-text, .section-heading.sh-t1.sh-s5 > a:last-child:first-child > .h-text {
  padding-right: 15px;
  color: #444 !important;
}

.section-heading.sh-t1.sh-s6:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFNzg0NTFBNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFNzg0NTFCNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUU3ODQ1MTg2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUU3ODQ1MTk2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz461u9NAAAAEUlEQVR42mJgQAX/yeAABBgAgcID/UDOgL8AAAAASUVORK5CYII=) repeat;
  opacity: 0.2;
  height: 8px;
  background-color: transparent !important;
}

.section-heading.sh-t1.sh-s6 {
  text-align: center;
}

.section-heading.sh-t1.sh-s6.multi-tab {
  text-align: inherit;
}

.section-heading.sh-t1.sh-s6 > .h-text:last-child:first-child, .section-heading.sh-t1.sh-s6 > .main-link:last-child:first-child > .h-text, .section-heading.sh-t1.sh-s6 > a:last-child:first-child > .h-text {
  padding: 4px 17px;
}

.section-heading.sh-t1.sh-s7:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFNzg0NTFBNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFNzg0NTFCNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUU3ODQ1MTg2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUU3ODQ1MTk2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz461u9NAAAAEUlEQVR42mJgQAX/yeAABBgAgcID/UDOgL8AAAAASUVORK5CYII=) repeat;
  opacity: 0.2;
  height: 8px;
  background-color: transparent !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: initial;
  margin-top: 0;
}

.section-heading.sh-t1.sh-s7 {
  text-align: center;
  padding-bottom: 26px;
}

.section-heading.sh-t1.sh-s7.multi-tab {
  text-align: inherit;
}

.section-heading.sh-t1.sh-s7 > .h-text:last-child:first-child, .section-heading.sh-t1.sh-s7 > .main-link:last-child:first-child > .h-text, .section-heading.sh-t1.sh-s7 > a:last-child:first-child > .h-text {
  padding: 4px 0;
}

.section-heading.sh-t2 {
  line-height: 20px;
  padding: 4px 0 0;
}

.section-heading.sh-t2:after {
  top: 0;
  height: 4px;
}

.section-heading.sh-t2:before {
  content: "";
  display: inline-block;
  height: 1px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #ededed;
  background: rgba(0, 0, 0, 0.07);
}

.section-heading.sh-t2 .h-text {
  padding: 4px 0;
  line-height: 34px;
}

.section-heading.sh-t2 .other-link .h-text {
  padding: 4px 0 4px 12px;
  text-transform: capitalize;
}

.section-heading.sh-t2 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  border-color: #e6e6e6;
}

.section-heading.sh-t2.sh-t2 .bs-pretty-tabs-elements .h-text {
  line-height: 24px;
  padding: 2px 3px;
}

.section-heading.sh-t2.sh-s2 {
  background: #f5f5f5;
  background: rgba(0, 0, 0, 0.04);
  padding: 4px 15px 0;
}

.section-heading.sh-t2.sh-s2 .bs-pretty-tabs-container {
  margin-right: -15px;
}

.section-heading.sh-t2.sh-s2 .h-text {
  line-height: 38px;
  color: #444;
}

.section-heading.sh-t2.sh-s3 {
  background: #f5f5f5;
  background: rgba(0, 0, 0, 0.04);
  padding: 0 15px 0 20px;
}

.section-heading.sh-t2.sh-s4 {
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 15px 0 20px;
}

.section-heading.sh-t2.sh-s3 .bs-pretty-tabs-container, .section-heading.sh-t2.sh-s4 .bs-pretty-tabs-container {
  margin-right: -15px;
}

.section-heading.sh-t2.sh-s3 .h-text, .section-heading.sh-t2.sh-s4 .h-text {
  line-height: 34px;
  color: #444;
}

.section-heading.sh-t2.sh-s4 .h-text {
  line-height: 32px;
}

.section-heading.sh-t2.sh-s3:after {
  top: 0;
  width: 5px;
  bottom: 0;
  height: 100%;
}

.section-heading.sh-t2.sh-s4:after {
  top: -1px;
  bottom: -1px;
  left: -1px;
  height: auto;
  width: 5px;
}

.section-heading.sh-t2.sh-s3:before, .section-heading.sh-t2.sh-s4:before {
  display: none;
}

.section-heading.sh-t3 {
  line-height: 20px;
  padding: 0 0 4px;
}

.section-heading.sh-t3:after {
  top: inherit;
  bottom: 0;
  height: 4px;
  background: #2d2d2d;
}

.section-heading.sh-t3:before {
  display: none;
}

.section-heading.sh-t3.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t3 > .h-text, .section-heading.sh-t3 > a > .h-text {
  padding: 4px 0;
  line-height: 26px;
  border: none;
}

.bs-light-scheme .section-heading.sh-t3 > .h-text {
  color: #fff;
}

.bs-light-scheme .section-heading.sh-t3:after {
  background-color: #fff !important;
}

.section-heading.sh-t3 > .other-link .h-text {
  padding: 4px 0 4px 17px;
}

.section-heading.sh-t3 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  border-color: rgba(0, 0, 0, 0.09);
}

.section-heading.sh-t3.sh-s2 {
  padding: 0 15px;
}

.section-heading.sh-t3.sh-s2:after {
  height: 100%;
  opacity: 0.08;
}

.section-heading.sh-t3.sh-s2.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t3.sh-s2 > .h-text, .section-heading.sh-t3.sh-s2 > a > .h-text {
  line-height: 36px;
}

.section-heading.sh-t3.sh-s3 {
  padding: 0 15px;
}

.section-heading.sh-t3.sh-s3:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #444;
}

.section-heading.sh-t3.sh-s3.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text, .section-heading.sh-t3.sh-s3 > .h-text.h-text, .section-heading.sh-t3.sh-s3 > a.active > .h-text.h-text, .section-heading.sh-t3.sh-s3 > a > .h-text.h-text {
  line-height: 34px;
  color: #fff !important;
}

.section-heading.sh-t3.sh-s3:after {
  height: 3px;
  background: #444;
}

.section-heading.sh-t3.sh-s3 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  background: #444;
  right: -15px;
}

.section-heading.sh-t3.sh-s3 .other-link .h-text {
  color: #fff;
}

.section-heading.sh-t3.sh-s3 > .h-text:last-child:first-child, .section-heading.sh-t3.sh-s3 > .main-link > .h-text, .section-heading.sh-t3.sh-s3 > a:last-child:first-child > .h-text {
  text-transform: uppercase;
}

.section-heading.sh-t3.sh-s4 {
  padding: 0 15px;
}

.section-heading.sh-t3.sh-s4:after {
  height: 100%;
}

.section-heading.sh-t3.sh-s4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text, .section-heading.sh-t3.sh-s4 > .h-text.h-text.h-text, .section-heading.sh-t3.sh-s4 > a.active > .h-text.h-text.h-text, .section-heading.sh-t3.sh-s4 > a > .h-text.h-text.h-text {
  line-height: 34px;
  border: none;
}

.section-heading.sh-t3.sh-s4.sh-t3.sh-s4 .h-text, .section-heading.sh-t3.sh-s4.sh-t3.sh-s4 .main-link:first-child:last-child .main-term-1171.h-text, .section-heading.sh-t3.sh-s4.sh-t3.sh-s4 a.active, .section-heading.sh-t3.sh-s4.sh-t3.sh-s4 > .other-link .h-text {
  color: #fff !important;
}

.section-heading.sh-t3.sh-s4 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  right: -15px;
}

.section-heading.sh-t3.sh-s5 {
  border: 1px solid rgba(0, 0, 1, 0.07);
  padding: 2px 15px 0 15px;
}

.section-heading.sh-t3.sh-s5:before {
  display: none;
}

.section-heading.sh-t3.sh-s5:after {
  top: -1px;
  height: 3px;
  left: -1px;
  right: -1px;
  width: auto;
}

.section-heading.sh-t3.sh-s5.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text {
  padding-right: 0;
}

.section-heading.sh-t3.sh-s5.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t3.sh-s5 > .h-text, .section-heading.sh-t3.sh-s5 > a > .h-text {
  line-height: 36px;
  border: none;
}

.section-heading.sh-t3.sh-s6 {
  padding: 0 15px 0 15px;
}

.section-heading.sh-t3.sh-s6:before {
  display: none;
}

.section-heading.sh-t3.sh-s6:after {
  height: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFNzg0NTFBNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFNzg0NTFCNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUU3ODQ1MTg2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUU3ODQ1MTk2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz461u9NAAAAEUlEQVR42mJgQAX/yeAABBgAgcID/UDOgL8AAAAASUVORK5CYII=) !important;
  opacity: 0.13;
}

.section-heading.sh-t3.sh-s6.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t3.sh-s6 > .h-text, .section-heading.sh-t3.sh-s6 > a > .h-text {
  line-height: 36px;
  border: none;
}

.section-heading.sh-t3.sh-s7 {
  padding: 0 15px;
  text-align: center;
}

.section-heading.sh-t3.sh-s7.multi-tab {
  text-align: inherit;
}

.section-heading.sh-t3.sh-s7:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 50%;
  height: 0;
  width: 0;
  margin-left: -10.5px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #444;
}

.section-heading.sh-t3.sh-s7.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text.h-text, .section-heading.sh-t3.sh-s7 > .h-text.h-text.h-text, .section-heading.sh-t3.sh-s7 > a.active > .h-text.h-text.h-text, .section-heading.sh-t3.sh-s7 > a > .h-text.h-text.h-text {
  line-height: 32px;
  color: #fff !important;
}

.section-heading.sh-t3.sh-s7:after {
  height: 100%;
  background: #444;
}

.section-heading.sh-t3.sh-s7 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  background: #444;
  right: -15px;
}

.section-heading.sh-t3.sh-s7 .other-link .h-text {
  color: #fff;
}

.section-heading.sh-t3.sh-s8 {
  padding: 0;
  text-align: center;
}

.section-heading.sh-t3.sh-s8.multi-tab {
  text-align: inherit;
}

.section-heading.sh-t3.sh-s8 > .h-text:last-child:first-child, .section-heading.sh-t3.sh-s8 > .main-link > .h-text, .section-heading.sh-t3.sh-s8 > a:last-child:first-child > .h-text {
  text-transform: uppercase;
  border: 2px solid #444;
  padding: 0 15px;
}

.section-heading.sh-t3.sh-s8 > .h-text:last-child:first-child:after, .section-heading.sh-t3.sh-s8 > .h-text:last-child:first-child:before, .section-heading.sh-t3.sh-s8 > .main-link > .h-text:after, .section-heading.sh-t3.sh-s8 > .main-link > .h-text:before, .section-heading.sh-t3.sh-s8 > a:last-child:first-child > .h-text:after, .section-heading.sh-t3.sh-s8 > a:last-child:first-child > .h-text:before {
  display: inline-block;
  content: "";
  height: 100%;
  width: 17px;
  position: absolute;
  left: -19px;
  top: 0;
  background: #fff;
}

.section-heading.sh-t3.sh-s8 > .h-text:last-child:first-child:after, .section-heading.sh-t3.sh-s8 > .main-link > .h-text:after, .section-heading.sh-t3.sh-s8 > a:last-child:first-child > .h-text:after {
  left: inherit;
  right: -19px;
}

.section-heading.sh-t3.sh-s8.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t3.sh-s8 > .h-text, .section-heading.sh-t3.sh-s8 > a > .h-text {
  line-height: 34px;
  border: none;
  background: #fff;
}

.section-heading.sh-t3.sh-s8:after {
  opacity: 0.13;
  height: 2px;
  margin-top: -1px;
  top: 50%;
  display: block;
}

.section-heading.sh-t3.sh-s8.multi-tab .bs-pretty-tabs-container {
  background: #fff;
}

.section-heading.sh-t3.sh-s9 {
  text-align: center;
  padding: 0 16px 4px;
}

.section-heading.sh-t3.sh-s9:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
}

.section-heading.sh-t3.sh-s9:after {
  height: 3px;
}

.section-heading.sh-t3.sh-s9.section-heading.sh-t3.sh-s9 .h-text {
  color: #fff !important;
  padding: 5px 0;
}

.section-heading.sh-t3.sh-s9.bs-pretty-tabs {
  text-align: inherit !important;
}

.section-heading.multi-tab.sh-t4 .main-link.active .h-text, .section-heading.sh-t4 .main-link:first-child:last-child .h-text, .section-heading.sh-t4 > .h-text {
  color: #fff;
}

.section-heading.sh-t4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t4 > .h-text, .section-heading.sh-t4 > a > .h-text {
  line-height: 32px;
}

.section-heading.multi-tab.sh-t4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t4 .h-text {
  padding: 0 12px;
}

.section-heading.multi-tab.sh-t4 .h-text, .section-heading.multi-tab.sh-t4 .main-link .h-text {
  border: 1px solid #e2e2e2;
  border-color: rgba(0, 0, 0, 0.15);
  margin-right: 12px;
}

.section-heading.multi-tab.sh-t4 > .h-text, .section-heading.multi-tab.sh-t4 > a > .h-text {
  background: #fff;
}

.section-heading.multi-tab.sh-t4 .other-link .h-text {
  margin-right: 0;
  margin-left: 12px;
}

.section-heading.multi-tab.sh-t4 .main-link:hover .h-text, .section-heading.sh-t4 .main-link.active .h-text, .section-heading.sh-t4 a.active .h-text, .section-heading.sh-t4 a:hover .h-text {
  border-color: transparent !important;
  color: #fff;
}

.section-heading.multi-tab.sh-t4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text {
  border-color: #e2e2e2;
  border-color: rgba(0, 0, 0, 0.15);
  background: #fff;
}

.section-heading.sh-t4 .bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text:after {
  height: 50%;
  right: 0;
  left: initial;
  top: 90%;
  pointer-events: all;
}

.section-heading.sh-t4 .bs-pretty-tabs-container .bs-pretty-tabs-elements .other-link .h-text {
  margin: 0 0 1px;
  border: none;
  padding: 1px 10px;
}

.section-heading.sh-t4 .bs-pretty-tabs-container .bs-pretty-tabs-elements .other-link .h-text:after {
  display: none;
}

.section-heading.multi-tab.sh-t4 .bs-pretty-tabs-container {
  padding-left: 0;
}

.section-heading.multi-tab.sh-t4 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  top: 120%;
  border-color: #e2e2e2;
  border-color: rgba(0, 0, 0, 0.15);
}

.section-heading.multi-tab.sh-t4.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text:before {
  content: "";
  position: absolute;
  height: 21%;
  display: inline-block;
  background: 0 0;
  right: -1px;
  left: -1px;
  top: 103%;
  top: calc(100% + 1px);
  pointer-events: visible;
}

.section-heading.multi-tab.sh-t4.bs-pretty-tabs .bs-pretty-tabs-more.other-link:hover .h-text {
  background: #fff !important;
}

.section-heading.sh-t4.sh-s1:after, .section-heading.sh-t4.sh-s3:after {
  height: 100%;
  top: 0;
  margin-top: 0;
  background: #f3f3f3;
  background: rgba(0, 0, 0, 0.04);
}

.section-heading.sh-t4.sh-s1 .h-text:after, .section-heading.sh-t4.sh-s3 .h-text:after {
  content: "";
  position: absolute;
  height: 110%;
  width: 12px;
  display: inline-block;
  background: #fff;
  right: -13px;
  top: -1px;
}

.section-heading.sh-t4.sh-s1 .other-link .h-text:after, .section-heading.sh-t4.sh-s3 .other-link .h-text:after {
  right: initial;
  left: -13px;
}

.section-heading.sh-t4.sh-s2:after {
  display: none;
}

.section-heading.sh-t4.sh-s3:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFNzg0NTFBNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFNzg0NTFCNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUU3ODQ1MTg2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUU3ODQ1MTk2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz461u9NAAAAEUlEQVR42mJgQAX/yeAABBgAgcID/UDOgL8AAAAASUVORK5CYII=);
  opacity: 0.13;
}

.section-heading.sh-t4.sh-s4:after {
  height: 13px;
  top: 9px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjQ4NUVFODNGNzc1MTFFN0I4NTdCMTRBMzQ3RTIwNTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjQ4NUVFODRGNzc1MTFFN0I4NTdCMTRBMzQ3RTIwNTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNDg1RUU4MUY3NzUxMUU3Qjg1N0IxNEEzNDdFMjA1NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNDg1RUU4MkY3NzUxMUU3Qjg1N0IxNEEzNDdFMjA1NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pk86A7EAAAASSURBVHjaYmBgYLBgIAgAAgwAB88AOccVbLsAAAAASUVORK5CYII=) repeat;
  opacity: 1;
}

.section-heading.sh-t4.sh-s4 .h-text:before {
  content: "";
  position: absolute;
  display: inline-block;
  right: -11px;
  top: 0;
  width: 25px;
  height: 35px;
  transform: skew(29deg);
  z-index: -1;
}

.section-heading.sh-t4.sh-s4 .h-text:after {
  content: "";
  position: absolute;
  height: 110%;
  display: inline-block;
  background: #fff;
  top: -1px;
  width: 42px;
  right: -42px;
  z-index: -2;
}

.site-footer .light-text .section-heading.sh-t4.sh-s4 .h-text:after, .site-footer .light-text .section-heading.sh-t4.sh-s4:after {
  display: none;
}

.section-heading.sh-t4.sh-s4 .h-text {
  line-height: 34px;
  height: 35px;
  padding-top: 2px;
}

.section-heading.sh-t4.sh-s4 .main-link .h-text {
  border: none !important;
}

.section-heading.sh-t4.sh-s4 .other-link.other-link .h-text {
  background-color: #fff !important;
  border: none !important;
  margin-top: -3px;
  color: #000 !important;
  margin-left: 0;
}

.section-heading.sh-t4.sh-s4 .other-link.other-link .h-text:after, .section-heading.sh-t4.sh-s4 .other-link.other-link .h-text:before {
  display: none;
}

.section-heading.sh-t4.sh-s4 .main-link:hover .h-text, .section-heading.sh-t4.sh-s4.section-heading.sh-t4.sh-s4 .main-link .h-text {
  color: #fff !important;
}

.section-heading.sh-t4.sh-s5:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjkyNTA0MDQyNDg2MTExRThBMUYzQkY4RTIxQ0VCMDZDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjkyNTA0MDQzNDg2MTExRThBMUYzQkY4RTIxQ0VCMDZDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTI1MDQwNDA0ODYxMTFFOEExRjNCRjhFMjFDRUIwNkMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTI1MDQwNDE0ODYxMTFFOEExRjNCRjhFMjFDRUIwNkMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6f3F9TAAAAhklEQVR42myPSw7DIAxEJyp36BoQWy7ASRAC5WAIDpVVJBb87kEbFhVtY8kL+4014y2lJDnn+K2cM7TW2MYY8g4aYxBCAC7B2m8olVLyPM85k/Wy1gprLWKMEELM3UfQWoNzbsI1E1nh5ckY+8pDeu/Y931CSunfN4/jOJ7e+1tYSsFLgAEAJ2RFZ6uSCs8AAAAASUVORK5CYII=) repeat;
  opacity: 0.7;
}

.have_bg.bs-light-scheme .section-heading.sh-t4.sh-s5:after {
  filter: invert(100%) brightness(12.7);
  opacity: 0.6;
  background-color: transparent;
}

.bs-light-scheme .section-heading.sh-t4.sh-s5 > .h-text {
  color: #000 !important;
}

.section-heading.sh-t4.sh-s5:after {
  height: 100%;
}

.section-heading.sh-t4.sh-s5 .h-text:before {
  content: "";
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  z-index: -1;
  border-left: 0 solid transparent;
  border-right: 17px solid transparent;
  border-top: 16px solid #fff;
}

.section-heading.sh-t4.sh-s5 .h-text:after {
  content: "";
  position: absolute;
  height: 100%;
  display: inline-block;
  background: #fff;
  top: 0;
  width: 14px;
  right: -14px;
  z-index: -2;
}

.site-footer .light-text .section-heading.sh-t4.sh-s5 .h-text:after, .site-footer .light-text .section-heading.sh-t4.sh-s5:after {
  display: none;
}

.section-heading.sh-t4.sh-s5 .h-text {
  line-height: 40px;
  height: 42px;
  padding-top: 2px;
  padding-right: 17px;
  padding-left: 17px;
}

.section-heading.sh-t4.sh-s5 .main-link .h-text {
  border: none !important;
}

.section-heading.sh-t4.sh-s5 .other-link.other-link .h-text {
  background-color: #fff !important;
  border: none !important;
  color: #000 !important;
  margin-left: 0;
}

.section-heading.sh-t4.sh-s5 .other-link.other-link .h-text:after, .section-heading.sh-t4.sh-s5 .other-link.other-link .h-text:before {
  display: none;
}

.section-heading.sh-t4.sh-s5 .main-link:hover .h-text, .section-heading.sh-t4.sh-s5.section-heading.sh-t4.sh-s5 .main-link .h-text {
  color: #fff !important;
}

.section-heading.sh-t4.sh-s6 {
  background-color: #2d2f38;
}

.bs-light-scheme .section-heading.sh-t4.sh-s6 {
  background-color: rgba(255, 255, 255, 0.07);
}

.section-heading.sh-t4.sh-s6 .h-text {
  line-height: 38px;
  color: #fff !important;
  border: none !important;
}

.section-heading.sh-t4.sh-s6 .h-text:before {
  content: "";
  position: absolute;
  right: -20px;
  top: 0;
  z-index: -1;
  border-left: 0 solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 38px solid transparent;
}

.section-heading.sh-t4.sh-s6 .other-link span {
  background-color: transparent !important;
  border: none;
  color: #fff;
  font-size: 11px;
}

.section-heading.sh-t4.sh-s6 .other-link span:before {
  display: none;
}

.section-heading.sh-t4.sh-s6 .other-link.active .h-text {
  background-color: transparent !important;
  border: none;
  color: #fff;
}

.section-heading.multi-tab.sh-t4.sh-s6 .main-link .h-text:hover {
  color: #fff !important;
}

.section-heading.sh-t4.sh-s6 .h-text:after, .section-heading.sh-t4.sh-s6:after {
  display: none;
}

.section-heading.sh-t5 {
  padding: 0 0 3px;
}

.section-heading.sh-t5:after {
  display: none;
}

.section-heading.sh-t5:before {
  content: "";
  display: inline-block;
  height: 3px;
  position: absolute;
  top: inherit;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ededed;
  background: rgba(0, 0, 0, 0.07);
}

.section-heading.sh-t5 > .h-text:last-child:first-child, .section-heading.sh-t5 > .main-link > .h-text, .section-heading.sh-t5 > a:last-child:first-child > .h-text {
  position: relative;
}

.section-heading.sh-t5 > .h-text:last-child:first-child:before, .section-heading.sh-t5 > .main-link > .h-text:before, .section-heading.sh-t5 > a:last-child:first-child > .h-text:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  bottom: -3px;
  background: #000;
}

.section-heading.sh-t5 .h-text {
  padding: 0;
  line-height: 36px;
}

.section-heading.sh-t5 .other-link .h-text, .section-heading.sh-t5.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  padding: 0 0 0 17px;
  text-transform: capitalize;
}

.section-heading.sh-t5 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  border-color: #e6e6e6;
  padding: 8px 12px;
}

.section-heading.sh-t5 .bs-pretty-tabs-elements .other-link .h-text {
  line-height: 30px;
  padding: 0;
}

.section-heading.sh-t5.sh-s2 {
  padding: 3px 0 0;
}

.section-heading.sh-t5.sh-s2 > .h-text:last-child:first-child:before, .section-heading.sh-t5.sh-s2 > .main-link > .h-text:before, .section-heading.sh-t5.sh-s2 > a:last-child:first-child > .h-text:before {
  bottom: inherit;
  top: -3px;
}

.section-heading.sh-t5.sh-s2:before {
  top: 0;
  bottom: inherit;
}

.section-heading.sh-t5.sh-2 .h-text {
  line-height: 40px;
}

.section-heading.sh-t6 {
  padding: 0;
}

.section-heading.sh-t6:after {
  display: none;
}

.section-heading.sh-t6:before {
  content: "";
  display: inline-block;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.section-heading.sh-t6 > .h-text:last-child:first-child, .section-heading.sh-t6 > .main-link > .h-text, .section-heading.sh-t6 > a:last-child:first-child > .h-text {
  position: relative;
  padding: 0 11px;
  color: #fff;
}

.section-heading.sh-t6.sh-s13:before {
  top: auto;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.section-heading.sh-t6 .h-text {
  padding: 0;
  line-height: 32px;
}

.section-heading.sh-t6 .other-link .h-text, .section-heading.sh-t6.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  padding: 2px 0 0 17px;
  text-transform: capitalize;
}

.section-heading.sh-t6 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  border-color: #e6e6e6;
  padding: 8px 12px;
}

.section-heading.sh-t6 .bs-pretty-tabs-elements .other-link .h-text {
  line-height: 30px;
  padding: 0;
}

.section-heading.sh-t6 > .other-link {
  margin-top: 2px;
}

.section-heading.sh-t6 .bs-pretty-tabs-elements .other-link {
  margin-top: 0;
}

.section-heading.multi-tab.sh-t6 .bs-pretty-tabs-container {
  padding-left: 0;
}

.section-heading.sh-t6.sh-s2:before {
  top: 50%;
  height: 3px;
  margin-top: -1.5px;
}

.section-heading.sh-t6.sh-s2 > .other-link {
  margin-top: 0;
}

.section-heading.sh-t6.sh-s2 .other-link .h-text, .section-heading.sh-t6.sh-s2.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  padding: 0 0 0 17px;
  background: #fff;
}

.section-heading.sh-t6.sh-s3:before {
  top: inherit;
  bottom: 0;
  height: 3px;
  margin-top: 0;
}

.section-heading.sh-t6.sh-s3 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s3 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s3 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s3 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s3 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s3 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 30px solid #000;
  border-right: 20px solid transparent;
  z-index: 1;
  right: -20px;
  background-color: transparent !important;
}

.section-heading.sh-t6.sh-s3 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s3 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s3 > a:last-child:first-child > .h-text:before {
  background: #fff;
}

.section-heading.sh-t6.sh-s3 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s3 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s3 > a:last-child:first-child > .h-text:after {
  border-bottom-color: rgba(255, 255, 255, 0.43);
  z-index: 2;
  -webkir-box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
}

.section-heading.sh-t6.sh-s3 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s3 > .main-link > .h-text, .section-heading.sh-t6.sh-s3 > a:last-child:first-child > .h-text {
  padding: 0 15px;
}

.section-heading.sh-t6.sh-s3 > .other-link {
  margin-top: 0;
}

.section-heading.sh-t6.bs-pretty-tabs.sh-s3 .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t6.sh-s3 .other-link .h-text {
  padding: 0 0 0 17px;
}

.section-heading.sh-t6.sh-s3 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  top: 96%;
  border-top-color: transparent;
}

.section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s4 > .main-link > .h-text, .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text {
  position: relative;
  padding: 0 24px 0 12px;
}

.section-heading.sh-t6.sh-s4:before {
  display: none;
}

.section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s4 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
  border-left: 2px solid #fff;
  border-right: 3px solid rgba(255, 255, 255, 0.7);
}

.section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after {
  right: 5px;
  width: 2px;
  border-left: 3px solid #fff;
  border-right: 5px solid rgba(255, 255, 255, 0.45);
}

.section-heading.sh-t6.sh-s5:before {
  display: none;
}

.section-heading.sh-t6.sh-s5 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s5 > .main-link > .h-text, .section-heading.sh-t6.sh-s5 > a:last-child:first-child > .h-text {
  padding: 0 22px 0 25px;
  border-radius: 0 0 12px;
}

.section-heading.sh-t6.sh-s5 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s5 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s5 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s5 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s5 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s5 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 20px solid #fff;
  border-right: 15px solid transparent;
}

.section-heading.sh-t6.sh-s5 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s5 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s5 > a:last-child:first-child > .h-text:after {
  border-right: none;
  border-top: none;
  border-bottom: 20px solid rgba(0, 0, 0, 0.3);
  border-left: 15px solid transparent;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
}

.section-heading.sh-t6.sh-s6, .section-heading.sh-t6.sh-s7 {
  padding: 0 25px 0 0;
}

.section-heading.sh-t6.sh-s6:before, .section-heading.sh-t6.sh-s7:before {
  height: 100%;
  width: auto;
  left: 5px;
  right: 5px;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  background: rgba(0, 0, 0, 0.05) !important;
}

.section-heading.sh-t6.sh-s7:before {
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
}

.section-heading.sh-t6.sh-s6 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s6 > .main-link > .h-text, .section-heading.sh-t6.sh-s6 > a:last-child:first-child > .h-text, .section-heading.sh-t6.sh-s7 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s7 > .main-link > .h-text, .section-heading.sh-t6.sh-s7 > a:last-child:first-child > .h-text {
  padding: 0 26px 0 10px;
}

.section-heading.sh-t6.sh-s6 .h-text, .section-heading.sh-t6.sh-s7 .h-text {
  line-height: 34px;
}

.section-heading.sh-t6.bs-pretty-tabs.sh-s6 .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t6.bs-pretty-tabs.sh-s7 .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t6.sh-s6 .other-link .h-text, .section-heading.sh-t6.sh-s7 .other-link .h-text {
  padding: 0 0 0 17px;
  border: none;
}

.section-heading.sh-t6.sh-s6 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s6 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s6 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s7 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s7 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s7 > a:last-child:first-child > .h-text:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: -8px;
  width: 16px;
  height: 100%;
  z-index: 1;
  background: #fff;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
}

.section-heading.sh-t6.sh-s7 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s7 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s7 > a:last-child:first-child > .h-text:after {
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
}

.section-heading.sh-t6.sh-s7 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s7 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s7 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 44px solid #fff;
  border-right: 16px solid transparent;
}

.section-heading.sh-t6.sh-s6 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s6 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s6 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -7px;
  width: 15px;
  height: 100%;
  z-index: 1;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  border: none;
}

.section-heading.sh-t6.sh-s6 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s6 > .main-link > .h-text, .section-heading.sh-t6.sh-s6 > a:last-child:first-child > .h-text {
  margin-left: 11px;
}

.section-heading.sh-t6.sh-s7 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s7 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s7 > a:last-child:first-child > .h-text:before {
  border-top: none;
  border-bottom: 44px solid #fff;
}

.section-heading.sh-t6.sh-s6 > .other-link, .section-heading.sh-t6.sh-s7 > .other-link {
  margin-top: 0;
}

.section-heading.sh-t6.sh-s6 .bs-pretty-tabs-container .bs-pretty-tabs-elements, .section-heading.sh-t6.sh-s7 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  right: -13px;
}

.section-heading.sh-t6.sh-s8:before {
  display: none;
}

.section-heading.sh-t6.sh-s8 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s8 > .main-link > .h-text, .section-heading.sh-t6.sh-s8 > a:last-child:first-child > .h-text {
  padding: 0 25px 0 12px;
}

.section-heading.sh-t6.sh-s8 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s8 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s8 > a:last-child:first-child > .h-text:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 16px solid transparent;
  border-right: 16px solid #fff;
  border-bottom: 16px solid transparent;
}

.section-heading.sh-t6.sh-s8 .other-link .h-text, .section-heading.sh-t6.sh-s8.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  padding-top: 0;
}

.section-heading.sh-t6.sh-s8 > .other-link {
  margin-top: 0;
}

.section-heading.sh-t6.sh-s10, .section-heading.sh-t6.sh-s12, .section-heading.sh-t6.sh-s9 {
  padding: 0 15px 7px 0;
}

.section-heading.sh-t6.sh-s10 .h-text, .section-heading.sh-t6.sh-s12 .h-text, .section-heading.sh-t6.sh-s9 .h-text {
  line-height: 34px;
}

.section-heading.sh-t6.sh-s10:before, .section-heading.sh-t6.sh-s9:before {
  height: auto;
  top: 7px;
  border-radius: 3px;
  bottom: 0;
}

.section-heading.sh-t6.sh-s12:before {
  display: none;
}

.section-heading.sh-t6.sh-s9:before {
  background: rgba(0, 0, 0, 0.05) !important;
}

.section-heading.sh-t6.sh-s10:before {
  opacity: 0.13;
  background: #a2a2a2;
}

.section-heading.sh-t6.sh-s10 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s10 > .main-link > .h-text, .section-heading.sh-t6.sh-s10 > a:last-child:first-child > .h-text, .section-heading.sh-t6.sh-s9 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s9 > .main-link > .h-text, .section-heading.sh-t6.sh-s9 > a:last-child:first-child > .h-text {
  border-radius: 3px 3px 3px 0;
}

.section-heading.sh-t6.sh-s10 > .other-link, .section-heading.sh-t6.sh-s9 > .other-link {
  margin-top: 6px;
}

.section-heading.sh-t6.sh-s10.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t6.sh-s9.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  border: none;
  padding-top: 0;
  line-height: 33px;
  margin-top: 8px;
}

.section-heading.sh-t6.sh-s10.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  border: none;
  padding-top: 0;
  line-height: 33px;
}

.section-heading.sh-t6.sh-s10 > .other-link, .section-heading.sh-t6.sh-s9 > .other-link {
  margin-top: 6px;
}

.section-heading.sh-t6.sh-s10 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s10 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s10 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s10 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s10 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s10 > a:last-child:first-child > .h-text:before, .section-heading.sh-t6.sh-s12 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s12 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s12 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s12 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s12 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s12 > a:last-child:first-child > .h-text:before, .section-heading.sh-t6.sh-s9 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s9 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s9 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s9 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s9 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s9 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 10px solid rgba(0, 0, 0, 0.18);
  border-right: 10px solid transparent;
}

.section-heading.sh-t6.sh-s10 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s10 > .main-link > .h-text, .section-heading.sh-t6.sh-s10 > a:last-child:first-child > .h-text, .section-heading.sh-t6.sh-s12 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s12 > .main-link > .h-text, .section-heading.sh-t6.sh-s12 > a:last-child:first-child > .h-text, .section-heading.sh-t6.sh-s9 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s9 > .main-link > .h-text, .section-heading.sh-t6.sh-s9 > a:last-child:first-child > .h-text {
  padding: 0 19px;
}

.section-heading.sh-t6.sh-s12 .other-link .h-text {
  padding-top: 0;
}

.section-heading.sh-t6.sh-s12 > .other-link {
  margin-top: 0;
}

.section-heading.sh-t6.sh-s11 {
  text-align: center;
}

.section-heading.sh-t6.sh-s11.multi-tab {
  text-align: inherit;
}

.section-heading.sh-t6.sh-s11:before {
  display: none;
}

.section-heading.sh-t6.sh-s11 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s11 > .main-link > .h-text, .section-heading.sh-t6.sh-s11 > a:last-child:first-child > .h-text {
  background: 0 0 !important;
  padding: 0 62px;
  text-align: center;
  line-height: 31px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  text-transform: inherit;
}

.section-heading.sh-t6.sh-s11 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s11 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s11 > a:last-child:first-child > .h-text:before {
  left: 47px;
  right: 47px;
  height: 100%;
  position: absolute;
  content: "";
  display: inline-block;
  z-index: -1;
  border-radius: 10px;
}

.section-heading.sh-t6.sh-s11 .sh-svg-l {
  position: absolute;
  left: 0;
  z-index: -1;
  top: -1px;
}

.section-heading.sh-t6.sh-s11 .sh-svg-r {
  position: absolute;
  right: 0;
  top: -1px;
}

.section-heading.sh-t6.sh-s12 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s12 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s12 > a:last-child:first-child > .h-text:before {
  background-color: transparent !important;
}

.section-heading.sh-t7 {
  padding: 0;
}

.section-heading.sh-t7:after {
  display: none;
}

.section-heading.sh-t7:before {
  content: "";
  display: inline-block;
  height: 3px;
  position: absolute;
  top: 50%;
  margin-top: -1.5px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ededed;
  background: rgba(0, 0, 0, 0.07);
}

.section-heading.sh-t7 > .h-text:last-child:first-child, .section-heading.sh-t7 > .main-link > .h-text, .section-heading.sh-t7 > a:last-child:first-child > .h-text {
  position: relative;
  padding: 0 15px 0 24px;
  background: #fff;
}

.section-heading.sh-t7 > .h-text:last-child:first-child:before, .section-heading.sh-t7 > .main-link > .h-text:before, .section-heading.sh-t7 > a:last-child:first-child > .h-text:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 15px;
  width: 15px;
  background: #000;
  top: 50%;
  margin-top: -7.5px;
}

.section-heading.sh-t7 .h-text {
  padding: 0;
}

.section-heading.sh-t7 .other-link .h-text, .section-heading.sh-t7.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  padding: 0 0 0 17px;
  text-transform: capitalize;
  background: #fff;
}

.section-heading.sh-t7 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  border-color: #e6e6e6;
  padding: 8px 12px;
}

.section-heading.sh-t7 .bs-pretty-tabs-elements .other-link .h-text {
  line-height: 30px;
  padding: 0;
}

.footer-widgets.light-text .section-heading.sh-t1 .main-link:first-child:last-child .h-text, .footer-widgets.light-text .section-heading.sh-t1 a.active .h-text, .footer-widgets.light-text .section-heading.sh-t1 a:hover .h-text, .footer-widgets.light-text .section-heading.sh-t1 > .h-text {
  color: #fff;
}

.footer-widgets.light-text .section-heading.sh-t1:after {
  background: rgba(255, 255, 255, 0.1);
}

.footer-widgets.light-text .section-heading.sh-t1.sh-s2:after {
  opacity: 0.45;
}

.footer-widgets.light-text .section-heading.sh-t1.sh-s5 > .h-text:first-child:last-child:after, .footer-widgets.light-text .section-heading.sh-t1.sh-s5 > .h-text:last-child:first-child, .footer-widgets.light-text .section-heading.sh-t1.sh-s5 > .main-link > .h-text, .footer-widgets.light-text .section-heading.sh-t1.sh-s5 > .main-link > .h-text:after, .footer-widgets.light-text .section-heading.sh-t1.sh-s5 > a:first-child:last-child > .h-text:after, .footer-widgets.light-text .section-heading.sh-t1.sh-s5 > a:last-child:first-child > .h-text {
  color: #fff !important;
}

.footer-widgets.light-text .section-heading.sh-t2:before {
  background: rgba(255, 255, 255, 0.14);
}

.footer-widgets.light-text .section-heading.sh-t2:after {
  background-color: #f9f9f9;
}

.footer-widgets.light-text .section-heading.sh-t2 .main-link:first-child:last-child .h-text, .footer-widgets.light-text .section-heading.sh-t2 a.active, .footer-widgets.light-text .section-heading.sh-t2 > .h-text {
  color: #fff !important;
}

.footer-widgets.light-text .listing-item-text-2 .item-inner {
  border-color: rgba(255, 255, 255, 0.11);
}

.footer-widgets.light-text .section-heading.sh-t2.sh-s2 {
  background: rgba(255, 255, 255, 0.04);
}

.footer-widgets.light-text .section-heading.sh-t2.sh-s3 {
  background: rgba(255, 255, 255, 0.04);
}

.footer-widgets.light-text .section-heading.sh-t2.sh-s4 {
  border: 1px solid rgba(255, 255, 255, 0.06);
}

.footer-widgets.light-text .section-heading.sh-t3:after {
  background: #fff;
}

.footer-widgets.light-text .section-heading.sh-t3 {
  color: #fff;
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text, .footer-widgets.light-text .section-heading.sh-t3.sh-s4 > .h-text.h-text.h-text, .footer-widgets.light-text .section-heading.sh-t3.sh-s4 > a.active > .h-text.h-text.h-text, .footer-widgets.light-text .section-heading.sh-t3.sh-s4 > a > .h-text.h-text.h-text {
  color: #000 !important;
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s5 {
  border: 1px solid rgba(255, 255, 255, 0.07);
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s6 {
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s7:after {
  background: #444;
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s7:before {
  border-top: 10px solid #444;
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s9:before {
  background-color: rgba(255, 255, 255, 0.07) !important;
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s9:after {
  background-color: rgba(255, 255, 255, 0.07) !important;
}

.footer-widgets.light-text .section-heading.sh-t4.sh-s1:after, .footer-widgets.light-text .section-heading.sh-t4.sh-s3:after {
  background: rgba(255, 255, 255, 0.05);
}

.footer-widgets.light-text .section-heading.sh-t4.sh-s6 {
  background-color: rgba(255, 255, 255, 0.07);
}

.footer-widgets.light-text .section-heading.sh-t4.sh-s5:after {
  display: block;
  filter: invert(100%) brightness(12.7);
  opacity: 0.6;
  background-color: transparent;
}

.footer-widgets.light-text .section-heading.sh-t5:before {
  background: rgba(255, 255, 255, 0.06);
}

.footer-widgets.light-text .section-heading.sh-t5 > .h-text:last-child:first-child:before, .footer-widgets.light-text .section-heading.sh-t5 > .main-link > .h-text:before, .footer-widgets.light-text .section-heading.sh-t5 > a:last-child:first-child > .h-text:before {
  background: #fff;
}

.footer-widgets.light-text .section-heading.sh-t5 {
  color: #fff;
}

.footer-widgets .section-heading.sh-t4.sh-s5 .h-text:after {
  display: block !important;
}

.ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after {
  border-right-color: rgba(0, 0, 0, 0.2);
}

.rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after {
  border-left-color: rgba(0, 0, 0, 0.2);
}

.ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:before, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:before, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:before {
  border-right-color: rgba(0, 0, 0, 0.3);
}

.rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:before, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:before, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:before {
  border-left-color: rgba(0, 0, 0, 0.3);
}

.footer-widgets.light-text .section-heading.sh-t6.sh-s9:before {
  background: rgba(255, 255, 255, 0.05) !important;
}

.site-footer .footer-widgets.light-text .section-heading.sh-t7.sh-s1 .h-text {
  color: #fff !important;
}

.footer-widgets.light-text .section-heading.sh-t7:before {
  background: rgba(255, 255, 255, 0.1);
}

.single-featured-video {
  max-width: 100% !important;
}

.post-meta.single-post-meta, .post-meta.single-post-meta a {
  color: #3a3a3a;
}

.post-meta.single-post-meta .comments, .post-meta.single-post-meta .post-author-avatar, .post-meta.single-post-meta .time, .post-meta.single-post-meta .views {
  float: none;
  margin: 0 12px 0 0;
  white-space: nowrap;
  line-height: 26px;
  display: inline-block;
}

.post-meta.single-post-meta > a + span:before, .post-meta.single-post-meta > span + a:before, .post-meta.single-post-meta > span + span:before {
  content: " ";
  display: inline-block;
  width: 9px;
  margin-right: 12px;
  height: 1px;
  background: rgba(0, 0, 0, 0.23);
  vertical-align: middle;
}

.post-meta.single-post-meta b {
  color: #4d4d4d;
  margin-left: 2px;
}

.post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  border-radius: 50%;
  margin: 0;
}

.post-meta.single-post-meta .post-author-a.post-author-avatar .avatar + .post-author-name {
  margin-left: 7px;
}

.post-meta.single-post-meta .post-author-name {
  text-transform: capitalize !important;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.post-meta.single-post-meta .post-author-a, .post-meta.single-post-meta .time {
  color: rgba(0, 0, 0, 0.44);
}

.post-meta.single-post-meta .post-author-a:hover {
  color: #b7b7b7 !important;
}

.single-post-title {
  line-height: 1.3;
}

.single-featured {
  text-align: center;
  display: block;
  margin: 0 0 20px;
  line-height: 0;
  position: relative;
}

.single-featured img {
  max-width: 100%;
  height: auto !important;
}

.single-featured figure {
  line-height: 2;
  text-align: center;
}

.single-featured iframe {
  max-width: 100%;
}

.image-credit a, .image-credit a:hover {
  color: #fff !important;
}

.single-featured .image-credit {
  position: absolute;
  right: 0;
  font-size: 10px;
  display: inline-block;
  padding: 0 8px;
  bottom: -18px;
  color: #adadad;
  background: 0 0;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
}

.post.format-standard .single-featured .image-credit {
  line-height: 20px;
  background: rgba(0, 0, 0, 0.26);
  bottom: 0;
  color: #fff;
}

.single-post-excerpt {
  color: #6d6d6d;
}

.single-post-excerpt p:last-child {
  margin-bottom: 0;
}

.single-post-excerpt.post-excerpt-bc {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e2e2e2;
}

.single-post-share {
  list-style: none;
  padding: 0;
  font-size: 0;
}

.single-post-share.top-share {
  margin-bottom: 10px;
}

.single-post-share .number {
  font-size: 13px;
  font-weight: 700;
  margin: 0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  display: inline-block;
}

.single-post-share .post-share-btn {
  background: #fff;
  border: 1px solid #e2e2e2;
  line-height: 30px;
  display: inline-block;
  padding: 0 5px;
  border-radius: 30px;
  font-size: 12px;
  color: #4d4d4d;
  margin-right: 8px;
  margin-bottom: 9px;
  vertical-align: top;
}

.single-post-share .post-share-btn .bf-icon, .single-post-share .post-share-btn .number, .single-post-share .post-share-btn .text {
  line-height: 30px;
  vertical-align: top;
}

.single-post-share .post-share-btn .text {
  font-size: 12px;
}

.single-post-share .post-share-btn .bf-icon {
  font-size: 15px;
  margin: 0 3px;
}

.single-post-share .share-handler {
  float: left;
  position: relative;
}

.single-post-share .post-share-btn.post-share-btn-comments, .single-post-share .post-share-btn.post-share-btn-views {
  float: right;
  border-radius: 0;
}

.single-post-share .post-share-btn.post-share-btn-views {
  font-weight: 700;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.single-post-share .post-share-btn.post-share-btn-views.rank-0, .single-post-share .post-share-btn.post-share-btn-views.rank-default {
  font-weight: 400;
}

.share-handler-wrap {
  overflow: hidden;
  height: 33px;
}

.post-share.style-2 .share-handler-wrap {
  height: 38px;
}

.post-share.style-5 .share-handler-wrap, .post-share.style-8 .share-handler-wrap {
  height: 47px;
}

.post-share.style-6 .share-handler-wrap, .post-share.style-7 .share-handler-wrap {
  height: 70px;
}

.share-handler-wrap.bs-pretty-tabs-initialized {
  height: auto !important;
}

.post-share .post-share-btn-group {
  overflow: hidden;
  float: right;
  margin-bottom: 10px;
}

.post-share .post-share-btn-group .post-share-btn {
  border-radius: 0;
  margin: 0;
  padding: 0 10px;
  border-left-width: 0;
  border-right-width: 0;
  position: relative;
}

.post-share .post-share-btn-group .post-share-btn:before {
  display: inline-block;
  content: " ";
  width: 1px;
  height: 66%;
  background: #e2e2e2;
  position: absolute;
  left: 0;
  top: 17%;
}

.post-share .post-share-btn-group .post-share-btn:first-child {
  border-radius: 0 3px 3px 0;
  border-right-width: 1px;
}

.post-share .post-share-btn-group .post-share-btn:last-child {
  border-radius: 3px 0 0 3px;
  border-left-width: 1px;
}

.post-share .post-share-btn-group .post-share-btn:last-child:before {
  display: none;
}

.post-share .bs-pretty-tabs-container {
  float: left;
}

.post-share .bs-pretty-tabs-elements {
  display: none !important;
}

.single-post-share ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.single-post-share li {
  margin: 0 6px 6px;
  display: inline-block;
  text-align: center;
  list-style: none;
}

.single-post-share li li {
  display: none;
}

.single-post-share .social-item {
  margin: 0 6px 4px 0;
  float: left;
}

.single-post-share .social-item a {
  line-height: 30px;
  min-width: 30px;
  display: inline-block;
  padding: 0 0;
  border-radius: 30px;
  font-size: 14px;
  color: #fff;
  overflow: hidden;
  text-align: center;
}

.single-post-share .social-item.has-count a {
  padding: 0 10px;
}

.single-post-share .social-item a .bf-icon, .single-post-share .social-item a .item-title, .single-post-share .social-item a .number {
  line-height: 30px;
  vertical-align: top;
  color: #fff;
  display: inline-block;
}

.single-post-share .social-item a .item-title, .single-post-share .social-item a .number {
  margin-left: 6px;
}

.single-post-share .social-item a .bf-icon {
  font-size: 16px;
}

.single-post-share .social-item.whatsapp .bf-icon {
  font-size: 16px;
  font-weight: 700;
}

.single-post-share .number:empty {
  display: none;
}

.single-post-share .social-item.facebook a {
  background-color: #2d5f9a;
  color: #133d6d;
}

.single-post-share .social-item.facebook a:hover {
  background-color: #1b4a82;
}

.single-post-share .social-item.twitter a {
  background-color: #53c7ff;
  color: #2e86b1;
}

.single-post-share .social-item.twitter a:hover {
  background-color: #369ed0;
}

.single-post-share .social-item.google_plus a {
  background-color: #d93b2b;
  color: #9a2317;
}

.single-post-share .social-item.google_plus a:hover {
  background-color: #b7291a;
}

.single-post-share .social-item.pinterest a {
  background-color: #a41719;
  color: #670d0e;
}

.single-post-share .social-item.pinterest a:hover {
  background-color: #8c0a0c;
}

.single-post-share .social-item.linkedin a {
  background-color: #005182;
  color: #013c61;
}

.single-post-share .social-item.linkedin a:hover {
  background-color: #003b5f;
}

.single-post-share .social-item.email a, .single-post-share .social-item.print a {
  background-color: #444;
  color: #313131;
}

.single-post-share .social-item.email a:hover, .single-post-share .social-item.print a:hover {
  background-color: #2f2f2f;
}

.single-post-share .social-item.tumblr a {
  background-color: #3e5a70;
  color: #24384a;
}

.single-post-share .social-item.tumblr a:hover {
  background-color: #426283;
}

.single-post-share .social-item.telegram a {
  background-color: #179cde;
  color: #0a629a;
}

.single-post-share .social-item.telegram a:hover {
  background-color: #0f8dde;
}

.single-post-share .social-item.reddit a {
  background-color: #ff4500;
  color: #bb3200;
}

.single-post-share .social-item.reddit a:hover {
  background-color: #dc3c01;
}

.single-post-share .social-item.stumbleupon a {
  background-color: #ee4813;
  color: #982d0b;
}

.single-post-share .social-item.stumbleupon a:hover {
  background-color: #ce3d0f;
}

.single-post-share .social-item.vk a {
  background-color: #4c75a3;
  color: #223448;
}

.single-post-share .social-item.vk a:hover {
  background-color: #3e5f84;
}

.single-post-share .social-item.digg a {
  background-color: #000;
  color: #525252;
}

.single-post-share .social-item.digg a:hover {
  background-color: #2f2f2f;
}

.single-post-share .social-item.bs-custom-link a {
  background-color: #000;
  color: #525252;
}

.single-post-share .social-item.bs-custom-link a:hover {
  background-color: #2f2f2f;
}

.single-post-share .social-item.ok-ru a {
  background-color: #ee8208;
  color: #cb6e04;
}

.single-post-share .social-item.ok-ru a:hover {
  background-color: #d36c08;
}

.single-post-share .social-item.whatsapp a {
  background-color: #00e676;
  color: #02a252;
}

.single-post-share .social-item.whatsapp a:hover {
  background-color: #00c462;
}

.single-post-share .social-item.line a {
  background-color: #00b900;
  color: #018c01;
}

.single-post-share .social-item.line a:hover {
  background-color: #009a00;
}

.single-post-share .social-item.line .bf-icon {
  font-size: 20px;
}

.single-post-share .social-item.bbm .bf-icon:before, .single-post-share .social-item.line .bf-icon:before, .single-post-share .social-item.viber .bf-icon:before {
  vertical-align: top;
}

.single-post-share .social-item.viber a {
  background-color: #5d54a4;
  color: #1f1d3c;
}

.single-post-share .social-item.viber:hover a {
  background-color: #494589;
}

.single-post-share .social-item.viber .bf-icon {
  font-size: 18px;
}

.single-post-share .social-item.bbm a {
  background-color: #1f1f1f;
  color: #505050;
}

.single-post-share .social-item.bbm a:hover {
  background-color: #3c3c3c;
}

.single-post-share .social-item.facebook-messenger a {
  background-color: #448aff;
  color: #2464ff;
}

.single-post-share .social-item.facebook-messenger a:hover {
  background-color: #448aff;
}

.single-post-share .social-item.bbm .bf-icon {
  font-size: 18px;
}

.single-post-share .social-item.telegram .bf-icon {
  margin-left: -1px;
}

.single-post-share .social-item.bbm .bsfi-bbm, .single-post-share .social-item.digg .bf-icon, .single-post-share .social-item.line .bsfi-line, .single-post-share .social-item.viber .bsfi-viber {
  font-size: 18px;
}

.single-post-share .social-item .bf-icon {
  vertical-align: middle;
}

.single-post-share .social-item.bbm, .single-post-share .social-item.line, .single-post-share .social-item.viber, .single-post-share .social-item.whatsapp {
  display: none;
}

@media only screen and (max-width: 780px) {
  .single-post-share .social-item.bbm, .single-post-share .social-item.line, .single-post-share .social-item.viber, .single-post-share .social-item.whatsapp {
    display: inline-block;
  }
}
@media only screen and (max-width: 540px) {
  .post-share .post-share-btn-group {
    float: left;
  }
  .share-handler-wrap {
    clear: both;
  }
}
@media only screen and (max-width: 420px) {
  .single-post-share .social-share-list + .post-share-btn {
    float: left;
    clear: left;
    margin-left: 0;
    margin-top: 5px;
  }
  .single-post-share .post-share-btn {
    margin-bottom: 4px !important;
  }
}
.post-share.style-3 .share-handler, .post-share.style-4 .share-handler, .post-share.style-5 .share-handler, .post-share.style-8 .share-handler {
  margin: 0 12px 12px 0;
}

.post-share .share-handler:after, .post-share .share-handler:before {
  border: solid 6px transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: -12px;
  border-left-color: #d7d7d7;
  margin-top: -6px;
}

.post-share .share-handler:after {
  border: solid 5px transparent;
  border-left-color: #fff;
  right: -10px;
  margin-top: -5px;
}

.post-share.style-1 .share-handler:after, .post-share.style-1 .share-handler:before, .post-share.style-2 .share-handler:after, .post-share.style-2 .share-handler:before {
  display: none;
}

.post-share .post-share-btn, .post-share .social-item a {
  border-radius: 3px;
  padding: 0 5px;
}

.post-share.style-3 .social-item a, .post-share.style-6 .social-item a, .post-share.style-7 .social-item a {
  -moz-box-shadow: 0 3px 0;
  -webkit-box-shadow: 0 3px 0;
  box-shadow: 0 3px 0;
}

.post-share.style-6 .bf-icon, .post-share.style-6 .item-title, .post-share.style-6 .number, .post-share.style-6 .text, .post-share.style-7 .bf-icon, .post-share.style-7 .item-title, .post-share.style-7 .number, .post-share.style-7 .text {
  font-size: 15px;
  display: block;
  font-weight: 400;
}

.post-share.style-5 .post-share-btn .bf-icon, .post-share.style-5 .post-share-btn .number, .post-share.style-5 .post-share-btn .text, .post-share.style-8 .post-share-btn .bf-icon, .post-share.style-8 .post-share-btn .number, .post-share.style-8 .post-share-btn .text {
  line-height: 43px;
}

.post-share.style-5 .bf-icon {
  font-size: 22px !important;
}

.post-share.style-5 .number {
  font-size: 18px;
}

.post-share.style-5 .social-item .item-title, .post-share.style-8 .social-item .item-title {
  font-size: 16px;
}

.post-share.style-5 .social-item a .bf-icon, .post-share.style-5 .social-item a .number, .post-share.style-8 .social-item a .bf-icon, .post-share.style-8 .social-item a .number {
  line-height: 44px;
  font-weight: 400;
}

.post-share.style-5 .social-item, .post-share.style-8 .social-item {
  margin: 0 6px 6px 0;
}

.post-share.style-5 .bs-pretty-tabs-more, .post-share.style-8 .bs-pretty-tabs-more {
  padding: 0 6px !important;
  transform: initial;
  top: 6px;
  min-width: 30px !important;
}

.post-share.style-5 .bs-pretty-tabs-more .bf-icon {
  line-height: 30px;
  font-size: 18px !important;
}

.post-share.style-10 .social-item, .post-share.style-9 .social-item {
  border: 1px solid #e2e2e2;
  border-radius: 3px;
}

.post-share.style-10 .post-share-btn, .post-share.style-11 .post-share-btn, .post-share.style-9 .post-share-btn {
  line-height: 31px;
}

.post-share.style-10 .share-handler, .post-share.style-9 .share-handler {
  padding: 0 10px;
  margin-right: 15px;
}

.post-share.style-10 .social-item a .item-title, .post-share.style-10 .social-item a .number {
  color: inherit;
}

.post-share.style-10 .social-item.has-count.has-title .number {
  border-left: none;
  padding-left: 0;
}

.post-share.style-10 .social-item.has-count.has-title .item-title {
  border-right: none;
}

.post-share.style-10 .post-share-btn, .post-share.style-11 .post-share-btn, .post-share.style-9 .post-share-btn {
  padding: 0 6px;
  line-height: 32px;
  border-radius: 3px;
  min-width: 32px;
  height: 32px;
}

.post-share.style-10 .post-share-btn-group .post-share-btn:first-child, .post-share.style-11 .post-share-btn-group .post-share-btn:first-child, .post-share.style-9 .post-share-btn-group .post-share-btn:first-child {
  border-radius: 0 3px 3px 0;
}

.post-share.style-10 .post-share-btn-group .post-share-btn:last-child, .post-share.style-11 .post-share-btn-group .post-share-btn:last-child, .post-share.style-9 .post-share-btn-group .post-share-btn:last-child {
  border-radius: 3px 0 0 3px;
}

.post-share.style-10 .social-item a, .post-share.style-9 .social-item a {
  background: #fff !important;
  color: #4d4d4d;
}

.post-share.style-10 .social-item.facebook a, .post-share.style-11 .social-item.facebook a, .post-share.style-9 .social-item.facebook a {
  color: #2d5f9a;
}

.post-share.style-10 .social-item.facebook .icon, .post-share.style-11 .social-item.facebook .icon {
  background-color: #2d5f9a;
  border-color: #2d5f9a;
}

.post-share.style-10 .social-item.facebook a:hover, .post-share.style-11 .social-item.facebook a:hover, .post-share.style-9 .social-item.facebook a:hover {
  color: #1b4a82;
}

.post-share.style-10 .social-item.facebook a:hover .icon, .post-share.style-11 .social-item.facebook a:hover .icon {
  background-color: #1b4a82;
  border-color: #1b4a82;
}

.post-share.style-10 .social-item.twitter a, .post-share.style-11 .social-item.twitter a, .post-share.style-9 .social-item.twitter a {
  color: #53c7ff;
}

.post-share.style-10 .social-item.twitter .icon, .post-share.style-11 .social-item.twitter .icon {
  background-color: #53c7ff;
  border-color: #53c7ff;
}

.post-share.style-10 .social-item.twitter a:hover, .post-share.style-11 .social-item.twitter a:hover, .post-share.style-9 .social-item.twitter a:hover {
  color: #369ed0;
}

.post-share.style-10 .social-item.google_plus a, .post-share.style-11 .social-item.google_plus a, .post-share.style-9 .social-item.google_plus a {
  color: #d93b2b;
}

.post-share.style-10 .social-item.google_plus .icon, .post-share.style-11 .social-item.google_plus .icon {
  background-color: #d93b2b;
  border-color: #d93b2b;
}

.post-share.style-10 .social-item.google_plus a:hover, .post-share.style-11 .social-item.google_plus a:hover, .post-share.style-9 .social-item.google_plus a:hover {
  color: #b7291a;
}

.post-share.style-10 .social-item.pinterest a, .post-share.style-11 .social-item.pinterest a, .post-share.style-9 .social-item.pinterest a {
  color: #a41719;
}

.post-share.style-10 .social-item.pinterest .icon, .post-share.style-11 .social-item.pinterest .icon {
  background-color: #a41719;
  border-color: #a41719;
}

.post-share.style-10 .social-item.pinterest a:hover, .post-share.style-11 .social-item.pinterest a:hover, .post-share.style-9 .social-item.pinterest a:hover {
  color: #8c0a0c;
}

.post-share.style-10 .social-item.linkedin a, .post-share.style-11 .social-item.linkedin a, .post-share.style-9 .social-item.linkedin a {
  color: #005182;
}

.post-share.style-10 .social-item.linkedin .icon, .post-share.style-11 .social-item.linkedin .icon {
  background-color: #005182;
  border-color: #005182;
}

.post-share.style-10 .social-item.linkedin a:hover, .post-share.style-11 .social-item.linkedin a:hover, .post-share.style-9 .social-item.linkedin a:hover {
  color: #003b5f;
}

.post-share.style-10 .social-item.email a, .post-share.style-10 .social-item.print a, .post-share.style-11 .social-item.email a, .post-share.style-11 .social-item.print a, .post-share.style-9 .social-item.email a, .post-share.style-9 .social-item.print a {
  color: #444;
}

.post-share.style-10 .social-item.email .icon, .post-share.style-10 .social-item.print .icon, .post-share.style-11 .social-item.email .icon, .post-share.style-11 .social-item.print .icon {
  background-color: #444;
  border-color: #444;
}

.post-share.style-10 .social-item.email a:hover, .post-share.style-10 .social-item.print a:hover, .post-share.style-11 .social-item.email a:hover, .post-share.style-11 .social-item.print a:hover, .post-share.style-9 .social-item.email a:hover, .post-share.style-9 .social-item.print a:hover {
  color: #2f2f2f;
}

.post-share.style-10 .social-item.tumblr a, .post-share.style-11 .social-item.tumblr a, .post-share.style-9 .social-item.tumblr a {
  color: #3e5a70;
}

.post-share.style-10 .social-item.tumblr .icon, .post-share.style-11 .social-item.tumblr .icon {
  background-color: #3e5a70;
  border-color: #3e5a70;
}

.post-share.style-10 .social-item.tumblr a:hover, .post-share.style-11 .social-item.tumblr a:hover, .post-share.style-9 .social-item.tumblr a:hover {
  color: #426283;
}

.post-share.style-10 .social-item.telegram a, .post-share.style-11 .social-item.telegram a, .post-share.style-9 .social-item.telegram a {
  color: #179cde;
}

.post-share.style-10 .social-item.telegram .icon, .post-share.style-11 .social-item.telegram .icon {
  background-color: #179cde;
  border-color: #179cde;
}

.post-share.style-10 .social-item.telegram a:hover, .post-share.style-11 .social-item.telegram a:hover, .post-share.style-9 .social-item.telegram a:hover {
  color: #0f8dde;
}

.post-share.style-10 .social-item.reddit a, .post-share.style-11 .social-item.reddit a, .post-share.style-9 .social-item.reddit a {
  color: #ff4500;
}

.post-share.style-10 .social-item.reddit .icon, .post-share.style-11 .social-item.reddit .icon {
  background-color: #ff4500;
  border-color: #ff4500;
}

.post-share.style-10 .social-item.reddit a:hover, .post-share.style-11 .social-item.reddit a:hover, .post-share.style-9 .social-item.reddit a:hover {
  color: #dc3c01;
}

.post-share.style-10 .social-item.stumbleupon a, .post-share.style-11 .social-item.stumbleupon a, .post-share.style-9 .social-item.stumbleupon a {
  color: #ee4813;
}

.post-share.style-10 .social-item.stumbleupon .icon, .post-share.style-11 .social-item.stumbleupon .icon {
  background-color: #ee4813;
  border-color: #ee4813;
}

.post-share.style-10 .social-item.stumbleupon a:hover, .post-share.style-11 .social-item.stumbleupon a:hover, .post-share.style-9 .social-item.stumbleupon a:hover {
  color: #ce3d0f;
}

.post-share.style-10 .social-item.vk a, .post-share.style-11 .social-item.vk a, .post-share.style-9 .social-item.vk a {
  color: #4c75a3;
}

.post-share.style-10 .social-item.vk .icon, .post-share.style-11 .social-item.vk .icon {
  background-color: #4c75a3;
  border-color: #4c75a3;
}

.post-share.style-10 .social-item.vk a:hover, .post-share.style-11 .social-item.vk a:hover, .post-share.style-9 .social-item.vk a:hover {
  color: #3e5f84;
}

.post-share.style-10 .social-item.digg a, .post-share.style-11 .social-item.digg a, .post-share.style-9 .social-item.digg a {
  color: #000;
}

.post-share.style-10 .social-item.digg .icon, .post-share.style-11 .social-item.digg .icon {
  background-color: #000;
  border-color: #000;
}

.post-share.style-10 .social-item.bs-custom-link a, .post-share.style-11 .social-item.bs-custom-link a, .post-share.style-9 .social-item.bs-custom-link a {
  color: #000;
}

.post-share.style-10 .social-item.bs-custom-link .icon, .post-share.style-11 .social-item.bs-custom-link .icon {
  background-color: #000;
  border-color: #000;
}

.post-share.style-10 .social-item.ok-ru a, .post-share.style-11 .social-item.ok-ru a, .post-share.style-9 .social-item.ok-ru a {
  color: #ee8208;
}

.post-share.style-10 .social-item.ok-ru .icon, .post-share.style-11 .social-item.ok-ru .icon {
  background-color: #ee8208;
  border-color: #ee8208;
}

.post-share.style-10 .social-item.digg a:hover, .post-share.style-11 .social-item.digg a:hover, .post-share.style-9 .social-item.digg a:hover {
  color: #2f2f2f;
}

.post-share.style-10 .social-item.bs-custom-link a:hover, .post-share.style-11 .social-item.bs-custom-link a:hover, .post-share.style-9 .social-item.bs-custom-link a:hover {
  color: #2f2f2f;
}

.post-share.style-10 .social-item.whatsapp a, .post-share.style-11 .social-item.whatsapp a, .post-share.style-9 .social-item.whatsapp a {
  color: #00e676;
}

.post-share.style-10 .social-item.whatsapp .icon, .post-share.style-11 .social-item.whatsapp .icon {
  background-color: #00e676;
  border-color: #00e676;
}

.post-share.style-10 .social-item.whatsapp a:hover, .post-share.style-11 .social-item.whatsapp a:hover, .post-share.style-9 .social-item.whatsapp a:hover {
  color: #00c462;
}

.post-share.style-10 .social-item.line a, .post-share.style-11 .social-item.line a, .post-share.style-9 .social-item.line a {
  color: #00b900;
}

.post-share.style-10 .social-item.line .icon, .post-share.style-11 .social-item.line .icon {
  background-color: #00b900;
  border-color: #00b900;
}

.post-share.style-10 .social-item.line a:hover, .post-share.style-11 .social-item.line a:hover, .post-share.style-9 .social-item.line a:hover {
  color: #009a00;
}

.post-share.style-10 .social-item.print a, .post-share.style-11 .social-item.print a, .post-share.style-9 .social-item.print a {
  color: #444;
}

.post-share.style-10 .social-item.viber a .icon, .post-share.style-11 .social-item.viber a .icon {
  background-color: #5d54a4 !important;
  border-color: #5d54a4 !important;
}

.post-share.style-10 .social-item.viber:hover a .icon, .post-share.style-11 .social-item.viber:hover a .icon {
  background-color: #494589 !important;
  border-color: #494589 !important;
}

.post-share.style-10 .social-item.viber .icon, .post-share.style-11 .social-item.viber .icon {
  font-size: 18px;
}

.post-share.style-10 .social-item.bbm a, .post-share.style-11 .social-item.bbm a, .post-share.style-9 .social-item.bbm a {
  color: #1f1f1f;
}

.post-share.style-10 .social-item.bbm .icon, .post-share.style-11 .social-item.bbm .icon {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
}

.post-share.style-10 .social-item.bbm a:hover, .post-share.style-11 .social-item.bbm a:hover, .post-share.style-9 .social-item.bbm a:hover {
  color: #3c3c3c;
}

.post-share.style-12 .post-share-btn, .post-share.style-12 a, .post-share.style-12 span, .post-share.style-13 .post-share-btn, .post-share.style-13 a, .post-share.style-13 span, .style-23.entry-terms .terms-label, .style-23.entry-terms a, .style-24.entry-terms .terms-label, .style-24.entry-terms a, .style-25.entry-terms .terms-label, .style-25.entry-terms a, .style-26.entry-terms .terms-label, .style-26.entry-terms a {
  border-radius: 0 !important;
}

.post-share.style-13 .post-share-btn, .post-share.style-13 .social-item a {
  padding: 0 10px;
  min-width: 44px;
  min-height: 44px;
  line-height: 44px !important;
}

.post-share.style-13 .post-share-btn > *, .post-share.style-13 .social-item .item-title, .post-share.style-13 .social-item i {
  line-height: 44px !important;
}

.post-share.style-13 .social-item .icon i {
  font-size: 22px;
}

.post-share.style-13 .social-item .item-title {
  font-size: 19px;
}

.post-share.style-13 .bs-pretty-tabs-more, .post-share.style-13 .post-share-btn {
  min-height: 42px;
  line-height: 42px !important;
}

.post-share.style-13 .bs-pretty-tabs-more i, .post-share.style-13 .post-share-btn > * {
  line-height: 42px !important;
}

.post-share .item-title {
  font-weight: 400;
  padding: 0;
}

.post-share .item-title, .post-share .number {
  font-size: 12px;
}

.post-share .post-share-btn-group .item-title, .post-share .post-share-btn-group .number {
  padding: 0;
}

.post-share.style-1 .social-item.has-title a {
  padding: 0 11px;
}

.post-share.style-1 .post-share-btn-group .post-share-btn:first-child, .post-share.style-2 .post-share-btn-group .post-share-btn:first-child {
  border-radius: 0 30px 30px 0;
}

.post-share.style-1 .post-share-btn-group .post-share-btn:last-child, .post-share.style-2 .post-share-btn-group .post-share-btn:last-child {
  border-radius: 30px 0 0 30px;
}

.post-share.style-1 .post-share-btn-group .post-share-btn:last-child:first-child, .post-share.style-2 .post-share-btn-group .post-share-btn:last-child:first-child {
  border-radius: 30px;
}

.post-share.style-1 .bs-pretty-tabs-more, .post-share.style-1 .share-handler, .post-share.style-1 .social-item a, .post-share.style-2 .bs-pretty-tabs-more, .post-share.style-2 .share-handler, .post-share.style-2 .social-item a {
  border-radius: 30px;
}

.post-share.style-1 .bs-pretty-tabs-more {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
}

.post-share.style-2 .bs-pretty-tabs-more {
  min-width: 38px;
  height: 38px;
  line-height: 38px;
}

.post-share .bs-pretty-tabs-more {
  color: #afafaf;
}

.post-share .bs-pretty-tabs-more:hover {
  color: #383838;
}

.single-post-share.top-share.style-1 {
  margin-bottom: 7px;
}

.single-post-share.bottom-share.style-1 {
  margin-bottom: -8px;
}

.single-post-share.top-share.style-2 {
  margin-bottom: 9px;
}

.single-post-share.bottom-share.style-2 {
  margin-bottom: -6px;
}

.post-share.style-1 .share-handler, .post-share.style-2 .share-handler {
  padding: 0 10px;
}

.post-share.style-2 .post-share-btn .bf-icon {
  font-size: 20px;
}

.post-share.style-2 .item-title, .post-share.style-2 .number, .post-share.style-2 .text {
  font-size: 14px;
}

.post-share.style-2 .number {
  margin: 0;
}

.post-share.style-2 .bf-icon, .post-share.style-2 .item-title, .post-share.style-2 .number, .post-share.style-2 .text {
  line-height: 38px !important;
  height: 38px;
}

.post-share.style-2 .item-title {
  margin-left: 10px;
  margin-right: 5px;
  font-weight: 400;
}

.post-share.style-2 .social-item a {
  padding: 0 8px;
  min-width: 38px;
  line-height: 38px;
}

.post-share.style-2 .social-item.has-count a, .post-share.style-2 .social-item.has-title a {
  padding: 0 15px;
}

.post-share.style-2 .social-item {
  margin: 0 6px 6px 0;
}

.post-share.style-2 .bs-pretty-tabs-more .bf-icon, .post-share.style-2 .post-share-btn-group .bf-icon {
  font-size: 15px;
}

.post-share.style-2 .social-item a .item-title + .number {
  margin-left: 0;
}

.single-post-share.top-share.style-3 {
  margin-bottom: 7px;
}

.single-post-share.bottom-share.style-3 {
  margin-bottom: -8px;
}

.post-share.style-3 .bf-icon, .post-share.style-3 .item-title, .post-share.style-3 .number, .post-share.style-3 .text {
  line-height: 32px !important;
}

.post-share.style-3 .post-share-btn, .post-share.style-3 .social-item a {
  line-height: 32px;
  height: 32px;
  min-width: 40px;
}

.post-share.style-3 .post-share-btn {
  line-height: 35px;
  height: 35px;
  padding: 0 8px;
}

.post-share.style-3 .post-share-btn .bf-icon, .post-share.style-3 .post-share-btn .number, .post-share.style-3 .post-share-btn .text {
  line-height: 34px !important;
}

.post-share.style-3 .social-item a {
  padding: 0 8px;
}

.post-share.style-3 .social-item.has-count a, .post-share.style-3 .social-item.has-title a {
  padding: 0 12px;
}

.single-post-share.style-3 .social-item.line .bsfi-line {
  font-size: 20px;
}

.single-post-share.style-3 .social-item.viber .bsfi-viber {
  font-size: 17px;
}

.single-post-share .social-item {
  margin-bottom: 8px;
}

.single-post-share.top-share.style-4 {
  margin-bottom: 7px;
}

.single-post-share.bottom-share.style-4 {
  margin-bottom: -8px;
}

.post-share.style-4 .share-handler.post-share-btn {
  padding: 0 8px 0 5px;
}

.post-share.style-4 .social-item a {
  padding: 0;
}

.post-share.style-4 .social-item .number {
  padding-right: 10px;
  margin-left: 10px;
}

.post-share.style-4 .social-item .item-title {
  padding-right: 9px;
  margin-left: 8px;
}

.post-share.style-4 .social-item .item-title + .number {
  margin-left: -2px;
}

.post-share.style-4 .social-item .bf-icon {
  display: inline-block;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  padding: 0 6px;
  min-width: 30px;
}

.post-share.style-4 .social-item .icon:last-child:first-child .bf-icon {
  background: 0 0;
}

.post-share.style-4 .share-handler .text {
  margin-left: 2px;
  font-size: 13px;
}

.post-share.style-4 .bs-pretty-tabs-more.post-share-btn {
  height: 30px;
}

.single-post-share.top-share.style-5 {
  margin-bottom: 9px;
}

.single-post-share.bottom-share.style-5 {
  margin-bottom: -6px;
}

.post-share.style-5 .post-share-btn, .post-share.style-5 .social-item a {
  padding: 0 10px;
  min-width: 44px;
}

.post-share.style-5 .social-item.has-count a, .post-share.style-5 .social-item.has-title a {
  padding: 0 12px;
}

.post-share.style-5 .social-item a .item-title, .post-share.style-5 .social-item a .number {
  margin: 0 5px 0 10px;
  line-height: 44px;
}

.post-share.style-5 .social-item a .item-title + .number {
  margin: 0 0 0 2px;
}

.post-share.style-5 .share-handler .text {
  font-size: 16px;
}

.post-share.style-5 .post-share-btn-group .bf-icon, .post-share.style-5 .post-share-btn-group .number {
  font-size: 15px !important;
}

.single-post-share.top-share.style-6, .single-post-share.top-share.style-7 {
  margin-bottom: 10px;
}

.single-post-share.bottom-share.style-6, .single-post-share.bottom-share.style-7 {
  margin-bottom: -5px;
}

.post-share.style-6 .social-item, .post-share.style-7 .social-item {
  text-align: center;
  margin: 0 10px 12px 0;
}

.post-share.style-6 .social-item a, .post-share.style-7 .social-item a {
  padding: 0 12px 0;
  line-height: 32px;
  height: 61px;
  min-width: 76px;
}

.post-share.style-6 .post-share-btn .bf-icon, .post-share.style-6 .post-share-btn .item-title, .post-share.style-6 .post-share-btn .number, .post-share.style-6 .social-item .item-title, .post-share.style-6 .social-item .number, .post-share.style-7 .post-share-btn .bf-icon, .post-share.style-7 .post-share-btn .item-title, .post-share.style-7 .post-share-btn .number, .post-share.style-7 .social-item .item-title, .post-share.style-7 .social-item .number {
  line-height: 20px;
  margin: 0 3px;
  font-size: 13px;
}

.post-share.style-6 .post-share-btn .bf-icon, .post-share.style-7 .post-share-btn .bf-icon {
  line-height: 26px;
  margin-top: 7px;
}

.post-share.style-6 .post-share-btn.bs-pretty-tabs-more .bf-icon, .post-share.style-7 .post-share-btn.bs-pretty-tabs-more .bf-icon {
  margin: 0;
}

.post-share.style-6 .social-item .bf-icon, .post-share.style-7 .social-item .bf-icon {
  font-size: 20px;
  display: block;
  line-height: 32px;
  margin-top: 6px;
}

.post-share.style-6 .social-item .icon:first-child:last-child .bf-icon, .post-share.style-7 .social-item .icon:first-child:last-child .bf-icon {
  line-height: 64px;
  margin: 0;
}

.post-share.style-6 .share-handler .bf-icon, .post-share.style-7 .share-handler .bf-icon {
  font-size: 20px;
  line-height: 28px;
  margin-top: 6px;
}

.post-share.style-6 .share-handler .number, .post-share.style-7 .share-handler .number {
  line-height: 26px;
  font-size: 15px;
}

.post-share.style-6 .bs-pretty-tabs-more, .post-share.style-7 .bs-pretty-tabs-more {
  margin-top: 18px;
  min-width: 28px;
}

.post-share.style-6 .share-handler, .post-share.style-7 .share-handler {
  text-align: center;
  padding: 0 14px;
  margin: 0 15px 11px 0;
  min-width: 84px;
  height: 64px;
}

.post-share.style-6 .share-handler .text, .post-share.style-7 .share-handler .text {
  font-weight: 700;
  line-height: 20px;
}

.post-share.style-6 .post-share-btn-group .post-share-btn, .post-share.style-7 .post-share-btn-group .post-share-btn {
  padding: 0 16px;
  text-align: center;
  height: 64px;
}

.post-share.style-7 .social-item a {
  border-radius: 0;
}

.post-share.style-7 .social-item.first a {
  border-radius: 3px 0 0 3px;
}

.post-share.style-7 .social-item.last a, .post-share.style-7 .social-item:last-child a {
  border-radius: 0 3px 3px 0;
}

.post-share.style-7 .social-item {
  margin: 0 0 12px 0;
}

.post-share.style-7 .bs-pretty-tabs-container {
  margin-left: 10px;
}

.single-post-share.top-share.style-8 {
  margin-bottom: 9px;
}

.single-post-share.bottom-share.style-8 {
  margin-bottom: -6px;
}

.post-share.style-8 .social-item .bf-icon {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 90px;
  margin-top: 8px;
  line-height: 30px !important;
  font-size: 16px;
  width: 30px;
  height: 30px;
}

.post-share.style-8 .social-item a {
  padding: 0 10px;
  line-height: 45px;
  min-width: 56px;
}

.post-share.style-8 .share-handler .text, .post-share.style-8 .social-item a .item-title, .post-share.style-8 .social-item a .number {
  margin: 0 6px 0;
  font-size: 14px;
  padding-right: 0;
  line-height: 45px;
}

.post-share.style-8 .social-item .item-title + .number {
  margin: 0 2px 0 -2px;
}

.post-share.style-8 .share-handler .text {
  padding-left: 3px;
  margin: 0;
  line-height: 44px;
  display: inline-block;
  vertical-align: top;
}

.post-share.style-8 .social-item .number {
  text-indent: 2px;
}

.post-share.style-8 .post-share-btn {
  padding: 0 10px;
}

.post-share.style-8 .share-handler {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 12px;
  height: 45px;
}

.post-share.style-8 .number {
  font-size: 14px;
}

.post-share.style-8 .bs-pretty-tabs-more .bf-icon {
  line-height: 30px;
  font-size: 16px !important;
}

.post-share.style-9 .social-item.has-count a, .post-share.style-9 .social-item.has-title a {
  padding: 0 10px;
}

.post-share.style-9 .social-item a {
  padding: 0 5px;
}

.post-share.style-9 .social-item .number {
  margin-left: 5px !important;
}

.post-share.style-9 .share-handler {
  margin-right: 12px;
  border-radius: 3px;
}

.post-share.style-9 .social-item a .bf-icon, .post-share.style-9 .social-item a .item-title, .post-share.style-9 .social-item a .number {
  color: inherit !important;
}

.post-share.style-9 .bs-pretty-tabs-more.post-share-btn {
  padding: 0 6px;
  width: 32px;
  line-height: 32px;
  height: 32px;
  border-radius: 3px;
}

.post-share.style-9 .social-item a .item-title, .post-share.style-9 .social-item a .number {
  margin-left: 8px;
}

.single-post-share.top-share.style-10, .single-post-share.top-share.style-11 {
  margin-bottom: 7px;
}

.single-post-share.bottom-share.style-10, .single-post-share.bottom-share.style-11 {
  margin-bottom: -8px;
}

.post-share.style-10 .social-item a, .post-share.style-11 .social-item a {
  padding: 0 !important;
  height: 32px;
}

.post-share.style-10 .social-item .icon, .post-share.style-10 .social-item a > span, .post-share.style-11 .social-item .icon, .post-share.style-11 .social-item a > span {
  display: inline-block;
  border: 1px solid #e2e2e2;
  border-left-width: 0;
  margin: 0 !important;
  padding: 0 8px;
  text-align: center;
}

.post-share.style-10 .social-item {
  border: none;
  padding: 0 !important;
}

.post-share.style-10 .social-item .icon, .post-share.style-11 .social-item .icon {
  color: #fff;
  display: inline-block;
  border-color: transparent;
  position: relative;
  height: inherit;
  min-width: 32px;
}

.post-share.style-10 .social-item .icon:after, .post-share.style-10 .social-item .icon:before {
  width: 0;
  height: 50%;
  border: 7px solid transparent;
  border-top-width: 0;
  border-right: 7px solid #fff;
  content: " ";
  position: absolute;
  top: 0;
  right: -1px;
  box-shadow: 7px -15px 0 #e2e2e2;
  display: none;
}

.post-share.style-10 .social-item .icon:before {
  bottom: 0;
  top: auto;
  border-top-width: 7px;
  border-bottom-width: 0;
  border-right: 7px solid #fff;
  box-shadow: 7px 15px 0 #e2e2e2;
}

.post-share.style-10 .social-item.has-count .icon:after, .post-share.style-10 .social-item.has-count .icon:before, .post-share.style-10 .social-item.has-title .icon:after, .post-share.style-10 .social-item.has-title .icon:before {
  display: inline-block;
}

.post-share.style-10 .social-item.has-count .icon, .post-share.style-10 .social-item.has-title .icon {
  padding: 0 16px 0 11px;
  min-width: 33px;
}

.post-share.style-10 .social-item .item-title, .post-share.style-10 .social-item .number {
  height: 32px;
}

.post-share.style-11 .social-item .item-title, .post-share.style-11 .social-item .number {
  background: #fff;
  margin: 1px !important;
  height: 30px;
  border-radius: 0 3px 3px 0;
  color: inherit;
  border: none;
  line-height: 30px;
  min-width: 30px;
}

.post-share.style-11 .social-item a .bf-icon {
  line-height: 30px;
}

.post-share.style-11 .social-item .icon, .post-share.style-11 .social-item a > span {
  padding: 0 8px;
}

.post-share.style-11 .post-share-btn {
  padding: 0 6px;
}

.post-share.style-11 .share-handler.post-share-btn {
  margin-right: 14px;
}

.post-share.style-11 .social-item.has-count.has-title .item-title {
  margin: 1px -1px 0 0 !important;
  border-radius: 0 !important;
}

.post-share.style-11 .social-item.has-count.has-title .number {
  padding-left: 0;
  margin-left: -4px !important;
}

.next-prev-post {
  padding: 25px 0 0;
  margin: 30px 0 0;
  position: relative;
  border-top: 1px solid #e8e8e8;
}

.bs-newsletter-pack + .next-prev-post, .post-author + .next-prev-post {
  border-top: none;
  padding: 0 20px;
}

.next-prev-post .prev-post {
  width: 50%;
  float: left;
  padding-right: 15px;
  text-align: left;
}

.next-prev-post .prev-post .pre-title .fa {
  margin-right: 10px;
}

.next-prev-post .next-post {
  width: 50%;
  float: right;
  padding-left: 15px;
  text-align: right;
}

.next-prev-post .next-post .pre-title .fa {
  margin-left: 10px;
}

.next-prev-post .pre-title {
  text-transform: uppercase;
  font-size: 13px;
  color: #8a8a8a;
  margin-bottom: 6px;
}

.next-prev-post .title {
  font-size: 15px;
  margin: 0;
  line-height: 1.4;
}

.post-tp-1-header .post-meta-wrap {
  margin: 15px 0 20px;
  padding: 0;
}

.post-tp-1-header .post-meta-wrap .post-meta {
  padding: 0;
}

.post-tp-1-header .post-meta-wrap .term-badges + .post-meta .post-author-a:last-child .post-author:after {
  display: none;
}

.post-tp-1-header .term-badges {
  float: right;
  margin: 0;
  display: inline;
  line-height: 18px;
}

.post-tp-1-header .term-badges:first-child:last-child {
  float: none;
}

.post-tp-1-header .term-badges a {
  background: #444;
  padding: 0 6px;
  display: inline-block;
  line-height: 18px;
  font-size: 10px;
  color: #fff;
  margin: 0 0 0 4px;
}

.post-tp-1-header .term-badges .term-badge:first-child a {
  margin: 0;
}

.post-tp-1-header .single-post-title {
  margin: 0 0 15px;
}

.post-tp-1-header .single-post-title + .post-subtitle {
  margin-top: -5px;
}

.post-tp-1-header .single-post-excerpt {
  margin-top: -8px;
  padding-bottom: 10px;
}

.post-tp-1-header .post-subtitle + .single-post-excerpt {
  padding: 10px 0;
  margin: 0;
}

.post-tp-1-header .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

@media only screen and (max-width: 420px) {
  .post-tp-1-header .term-badges {
    float: none;
    margin-bottom: 10px;
    display: block;
  }
  .post-tp-1-header .post-meta-wrap {
    margin: 10px 0 12px;
    padding: 0;
  }
}
.post-tp-2-header {
  position: relative;
  margin: 0 0 25px;
}

.post-tp-2-header.wfi {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
}

.post-tp-2-header.wfi:before {
  background: rgba(0, 0, 0, 0.35);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.post-tp-2-header .post-header-inner {
  padding: 30px 0 0;
  position: relative;
}

.post-tp-2-header.wfi .post-header-inner {
  padding: 30px 30px;
  height: 560px;
  overflow: hidden;
}

.post-tp-2-header.wfi .post-header-title {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}

.post-tp-2-header.nfi .post-header-title {
  margin-top: 20px;
}

.post-tp-2-header.wfi .single-post-title {
  color: #fff;
}

.post-tp-2-header.nfi .single-post-title {
  margin: 10px 0;
}

.post-tp-2-header .single-post-title + .post-subtitle {
  margin: 0 0 15px;
}

.post-tp-2-header.wfi .single-post-title + .post-subtitle {
  color: #fff;
}

.post-tp-2-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 10px;
}

.post-tp-2-header.wfi .post-meta, .post-tp-2-header.wfi .post-meta .post-author-a:hover, .post-tp-2-header.wfi .post-meta .views, .post-tp-2-header.wfi .post-meta a, .post-tp-2-header.wfi .post-meta a:hover, .post-tp-2-header.wfi .post-meta b {
  color: #fff !important;
}

.post-tp-2-header.wfi .post-meta.single-post-meta .post-author-a, .post-tp-2-header.wfi .post-meta.single-post-meta .post-author-a:hover, .post-tp-2-header.wfi .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-2-header.wfi .post-meta.single-post-meta > a + span:before, .post-tp-2-header.wfi .post-meta.single-post-meta > span + a:before, .post-tp-2-header.wfi .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-2-header.wfi .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-2-header .image-credit {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 9px;
}

.post-tp-2-header.wfi .image-credit {
  color: rgba(255, 255, 255, 0.7);
}

.post-tp-2-header.wfi .image-credit:hover {
  color: #fff;
}

.post-template-2 .post-embed {
  margin-bottom: 20px;
}

.post-tp-2-header .bf-breadcrumb {
  position: absolute;
  left: 0;
  top: 15px;
}

.post-tp-2-header .single-post-excerpt {
  padding-bottom: 12px;
}

.post-tp-2-header.wfi .single-post-excerpt {
  color: #fff;
}

.post-tp-2-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

.post-tp-2-header.nfi .bf-breadcrumb .bf-breadcrumb-item.bf-breadcrumb-end {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .post-tp-2-header.wfi .post-header-inner {
    height: 450px;
  }
}
@media only screen and (max-width: 680px) {
  .post-tp-2-header.wfi .post-header-inner {
    height: 420px;
  }
}
.post-tp-3-header {
  position: relative;
  margin-bottom: 25px;
}

.post-tp-3-header.wfi {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
}

.post-tp-3-header.wfi:before {
  background: rgba(0, 0, 0, 0.35);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.post-tp-3-header.wfi .post-header-inner {
  padding: 30px 30px;
  height: 560px;
  position: relative;
  overflow: hidden;
}

.post-tp-3-header.wfi .post-header-title {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
}

.post-tp-3-header.wfi .single-post-title {
  color: #fff;
}

.post-tp-3-header.nfi .single-post-title {
  margin: 10px 0 12px;
}

.post-tp-3-header .single-post-title + .post-subtitle {
  margin: 0 0 15px;
}

.post-tp-3-header.wfi .single-post-title + .post-subtitle {
  color: #fff;
}

.post-tp-3-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 10px;
}

.post-tp-3-header.wfi .post-meta, .post-tp-3-header.wfi .post-meta .post-author-a:hover, .post-tp-3-header.wfi .post-meta .views, .post-tp-3-header.wfi .post-meta a, .post-tp-3-header.wfi .post-meta a:hover, .post-tp-3-header.wfi .post-meta b {
  color: #fff !important;
}

.post-tp-3-header.wfi .post-meta.single-post-meta .post-author-a, .post-tp-3-header.wfi .post-meta.single-post-meta .post-author-a:hover, .post-tp-3-header.wfi .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-3-header.wfi .post-meta.single-post-meta > a + span:before, .post-tp-3-header.wfi .post-meta.single-post-meta > span + a:before, .post-tp-3-header.wfi .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-3-header.wfi .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-3-header .image-credit {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 9px;
}

.post-tp-3-header.wfi .image-credit {
  color: rgba(255, 255, 255, 0.7);
}

.post-tp-3-header.wfi .image-credit:hover {
  color: #fff;
}

.post-template-3 .post-embed {
  margin-bottom: 20px;
}

.post-tp-3-header .single-post-excerpt {
  color: #fff;
  padding-bottom: 12px;
}

.post-tp-3-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

.post-tp-3-header.nfi .bf-breadcrumb .bf-breadcrumb-item.bf-breadcrumb-end {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .post-tp-3-header.wfi .post-header-inner {
    height: 450px;
  }
}
@media only screen and (max-width: 780px) {
  .post-tp-3-header.wfi .post-header-inner {
    padding: 20px;
  }
  .post-tp-3-header.wfi .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  .post-tp-3-header .image-credit {
    bottom: 5px;
  }
  .post-tp-3-header .bf-breadcrumb {
    margin-top: 0;
  }
}
@media only screen and (max-width: 680px) {
  .post-tp-3-header.wfi .post-header-inner {
    height: 420px;
  }
}
.post-tp-4-header {
  position: relative;
  margin: 0 0 25px;
}

.post-tp-4-header.wfi {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
}

.post-tp-4-header.wfi:before {
  background: rgba(0, 0, 0, 0.25);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.post-tp-4-header.nfi .post-header-inner {
  padding: 18px 10px 10px;
  position: relative;
}

.post-tp-4-header.wfi .post-header-inner {
  padding: 18px 30px 30px;
  height: 580px;
  position: relative;
  overflow: hidden;
}

.post-tp-4-header.wfi .post-header-title {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.39);
}

.post-tp-4-header.nfi .post-header-title {
  margin-top: 60px;
  position: relative;
  opacity: 1 !important;
}

.post-tp-4-header .single-post-title {
  text-align: center;
}

.post-tp-4-header.wfi .single-post-title {
  color: #fff;
}

.post-tp-4-header .single-post-title + .post-subtitle {
  margin: 0 0 15px;
  text-align: center;
}

.post-tp-4-header.wfi .single-post-title + .post-subtitle {
  color: #fff;
}

.post-tp-4-header .term-badges.floated {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: auto;
  text-align: center;
}

.post-tp-4-header.wfi .term-badges.floated .term-badge a, .post-tp-4-header.wfi .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-4-header .post-meta a, .post-tp-4-header.wfi .post-meta, .post-tp-4-header.wfi .post-meta .post-author-a:hover, .post-tp-4-header.wfi .post-meta .views, .post-tp-4-header.wfi .post-meta a:hover, .post-tp-4-header.wfi .post-meta b {
  color: #fff !important;
}

.post-tp-4-header.wfi .post-meta.single-post-meta .post-author-a, .post-tp-4-header.wfi .post-meta.single-post-meta .post-author-a:hover, .post-tp-4-header.wfi .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-4-header.wfi .post-meta.single-post-meta > a + span:before, .post-tp-4-header.wfi .post-meta.single-post-meta > span + a:before, .post-tp-4-header.wfi .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-4-header.wfi .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-4-header .post-meta {
  text-align: center;
}

.post-tp-4-header .image-credit {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  padding: 0 8px;
}

.post-tp-4-header .move-to-content {
  display: block;
  text-align: center;
  font-size: 36px;
}

.post-tp-4-header.wfi .move-to-content {
  color: #fff;
}

.post-tp-4-header .move-to-content .fa {
  cursor: pointer;
}

.post-template-4 .post-embed {
  margin-bottom: 20px;
}

.post-tp-4-header.show-cat-badges.show-breadcrumb .term-badges.floated {
  top: 55px;
}

.post-tp-4-header .single-post-excerpt {
  color: #fff;
  padding-bottom: 12px;
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.post-tp-4-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

.post-tp-4-header .bf-breadcrumb.bc-light-color .bf-breadcrumb-item.bf-breadcrumb-end {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .post-tp-4-header.wfi .post-header-inner {
    height: 500px;
  }
}
@media only screen and (max-width: 680px) {
  .post-tp-4-header.wfi .post-header-inner {
    height: 430px;
  }
}
.post-tp-5-header {
  position: relative;
  margin-bottom: 25px;
}

.post-tp-5-header.wfi {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
}

.post-tp-5-header.wfi:before {
  background: rgba(0, 0, 0, 0.25);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.post-tp-5-header.nfi .post-header-inner {
  padding-top: 30px;
  position: relative;
  opacity: 1 !important;
  margin-top: 5px;
}

.post-tp-5-header.wfi .post-header-inner {
  padding: 20px 30px 30px;
  height: 580px;
  position: relative;
  overflow: hidden;
}

.post-tp-5-header.wfi .post-header-title {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.39);
}

.post-tp-5-header.nfi .post-header-title {
  opacity: 1 !important;
  position: relative;
}

.post-tp-5-header .single-post-title {
  text-align: center;
  padding: 0 25px;
}

.post-tp-5-header.nfi .single-post-title {
  margin: 12px 0;
}

.post-tp-5-header.wfi .single-post-title {
  color: #fff;
  text-align: center;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.39);
  padding: 0 25px;
}

.post-tp-5-header .single-post-title + .post-subtitle {
  margin: 0 0 15px;
}

.post-tp-5-header.wfi .single-post-title + .post-subtitle {
  color: #fff;
  text-align: center;
}

.post-tp-5-header .term-badges.floated {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  text-align: center;
}

.post-tp-5-header.nfi .term-badges.floated {
  top: 0;
}

.post-tp-5-header.wfi .term-badges.floated .term-badge a, .post-tp-5-header.wfi .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-5-header.wfi .post-meta, .post-tp-5-header.wfi .post-meta .post-author-a:hover, .post-tp-5-header.wfi .post-meta .views, .post-tp-5-header.wfi .post-meta a, .post-tp-5-header.wfi .post-meta a:hover, .post-tp-5-header.wfi .post-meta b {
  color: #fff !important;
}

.post-tp-5-header.wfi .post-meta.single-post-meta .post-author-a, .post-tp-5-header.wfi .post-meta.single-post-meta .post-author-a:hover, .post-tp-5-header.wfi .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-5-header.wfi .post-meta.single-post-meta > a + span:before, .post-tp-5-header.wfi .post-meta.single-post-meta > span + a:before, .post-tp-5-header.wfi .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-5-header.wfi .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-5-header .post-meta {
  text-align: center;
}

.post-tp-5-header .image-credit {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  padding: 0 8px;
}

.post-tp-5-header .move-to-content {
  display: block;
  text-align: center;
  font-size: 36px;
}

.post-tp-5-header.wfi .move-to-content {
  color: #fff;
}

.post-tp-5-header .move-to-content .fa {
  cursor: pointer;
}

.post-template-5 .post-embed {
  margin-bottom: 20px;
}

.post-tp-5-header .single-post-excerpt {
  padding-bottom: 12px;
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.post-tp-5-header.wfi .single-post-excerpt {
  color: #fff;
}

.post-tp-5-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

@media only screen and (max-width: 1080px) {
  .post-tp-5-header.wfi .post-header-inner {
    height: 450px;
  }
}
@media only screen and (max-width: 780px) {
  .post-tp-5-header.wfi .post-header-inner {
    padding: 20px 20px;
  }
  .post-tp-5-header.wfi .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}
.post-tp-6-header {
  position: relative;
  margin-bottom: 25px;
}

.post-tp-6-header.wfi {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
  height: 550px;
}

.post-template-6 .post-header-inner {
  position: relative;
  overflow: hidden;
  padding: 10px 0 20px;
}

.post-template-6.wfi .post-header-inner {
  padding: 20px 20px 20px 0;
  background: #fff;
  margin-top: -150px;
}

.layout-2-col.layout-left-sidebar.post-template-6.wfi .post-header-inner, .layout-3-col.layout-3-col-3.post-template-6.wfi .post-header-inner, .layout-3-col.layout-3-col-4.post-template-6.wfi .post-header-inner, .layout-3-col.layout-3-col-5.post-template-6.wfi .post-header-inner, .layout-3-col.layout-3-col-6.post-template-6.wfi .post-header-inner {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.post-template-6.post-template-6-no-term.wfi .post-header-inner {
  padding-top: 33px;
  padding-bottom: 30px;
}

.post-template-6.post-template-6-no-meta.wfi .post-header-inner {
  padding-top: 25px;
  padding-bottom: 20px;
}

.post-template-6.post-template-6-no-meta.post-template-6-no-term.wfi .post-header-inner {
  padding-top: 35px;
  padding-bottom: 20px;
  margin-top: -120px;
}

.post-template-6 .single-post-title {
  margin: 0 0 15px;
}

.post-template-6 .single-post-title + .post-subtitle {
  margin: -5px 0 15px;
}

.post-template-6 .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 11px;
}

.post-tp-6-header.wfi .term-badges.floated .term-badge a, .post-tp-6-header.wfi .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-6-header.wfi .post-meta, .post-tp-6-header.wfi .post-meta .post-author, .post-tp-6-header.wfi .post-meta a {
  color: #fff;
}

.post-tp-6-header .post-meta {
  text-align: center;
}

.post-tp-6-header .image-credit {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  padding: 0 8px;
}

.post-tp-6-header.wfi .image-credit:hover {
  background: rgba(0, 0, 0, 0.35);
}

.post-template-6 .post-embed {
  margin-bottom: 20px;
}

.post-template-6 .post-header-inner .single-post-excerpt {
  margin-top: -8px;
  padding-bottom: 10px;
}

.post-template-6 .post-header-inner .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-template-6 .post-header-inner .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

@media only screen and (max-width: 1080px) {
  .post-tp-6-header.wfi {
    height: 450px;
  }
}
@media only screen and (max-width: 780px) {
  .post-tp-6-header.wfi {
    height: 350px;
  }
  .post-template-6.wfi .post-header-inner {
    margin-top: 0;
    padding-top: 0;
  }
  .post-tp-6-header.wfi .post-header-inner {
    padding: 20px 20px;
  }
  .post-tp-6-header.wfi .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}
.post-tp-7-header {
  position: relative;
  margin: 0;
}

.post-tp-7-header.wfi {
  background-color: #565656;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.post-tp-7-header.wfi:before {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.55)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 380px;
}

.post-tp-7-header.wfi .post-header-inner {
  padding: 30px 30px 0;
  height: 580px;
  position: relative;
}

.post-tp-7-header.nfi .post-header-inner {
  margin: 5px 0 0;
  position: relative;
}

.post-tp-7-header.wfi .post-header-title {
  position: absolute;
  bottom: 0;
  left: -25px;
  right: -25px;
  background: #fff;
  padding: 25px 25px 0;
}

.post-tp-7-header .post-header-title .single-post-title {
  margin-top: 0;
}

.post-tp-7-header .single-post-title + .post-subtitle {
  margin: -2px 0 13px;
}

.post-tp-7-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 15px;
}

.post-tp-7-header .image-credit {
  position: absolute;
  right: 0;
  top: -21px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  padding: 0 8px;
  overflow: hidden;
  white-space: nowrap;
}

.post-tp-7-header.wfi .image-credit {
  color: #fff;
}

.post-tp-7-header.nfi .image-credit {
  display: none;
}

.post-template-7 .post-embed {
  margin-bottom: 20px;
}

.post-tp-7-header .bf-breadcrumb {
  margin-bottom: 10px;
}

.post-tp-7-header .single-post-excerpt {
  padding-bottom: 10px;
}

.post-tp-7-header .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-tp-7-header .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

@media only screen and (max-width: 1366px) {
  .post-tp-7-header.wfi .post-header-inner {
    height: 500px;
  }
}
@media only screen and (max-width: 1080px) {
  .post-tp-7-header.wfi .post-header-inner {
    height: 400px;
  }
}
@media only screen and (max-width: 980px) {
  .post-tp-7-header .post-header-title {
    left: 0;
    right: 0;
  }
}
.post-tp-8-header {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  background-color: #565656;
  height: 350px;
  float: left;
  width: 40%;
  margin: 0 30px 25px 0;
}

.post-template-8 .post-header-inner {
  padding: 0;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
}

.post-template-8 .single-post-title {
  margin: 0 0 13px;
}

.post-template-8 .single-post-title + .post-subtitle {
  margin: -7px 0 15px;
}

.post-template-8 .single-post-title:last-child {
  margin-bottom: 0;
}

.post-template-8 .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 15px;
}

.post-tp-8-header .term-badges.floated .term-badge a, .post-tp-8-header .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-8-header .post-meta, .post-tp-8-header .post-meta .post-author, .post-tp-8-header .post-meta a {
  color: #fff;
}

.post-tp-8-header .post-meta {
  text-align: center;
}

.post-tp-8-header .image-credit {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 8px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  font-size: 10px;
  color: #fff;
}

.post-template-8 .single-post-excerpt {
  margin-top: -5px;
  padding-bottom: 10px;
}

.post-template-8 .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-template-8 .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

@media only screen and (max-width: 780px) {
  .post-tp-8-header {
    width: 100%;
    margin-right: 0;
  }
  .post-template-8 .post-header-inner {
    margin-top: 0;
    padding-top: 0;
  }
  .post-tp-8-header .post-header-inner {
    padding: 20px 20px;
  }
  .post-tp-8-header .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}
.post-template-9 .post-header-inner {
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.post-template-9 .single-post-title {
  margin: 0 0 12px;
}

.post-template-9 .single-post-title + .post-subtitle {
  margin: -7px 0 15px;
}

.post-template-9 .single-post-title:last-child {
  margin-bottom: 0;
}

.post-template-9 .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 15px;
}

.post-tp-9-header .term-badges.floated .term-badge a, .post-tp-9-header .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-9-header .post-meta, .post-tp-9-header .post-meta .post-author, .post-tp-9-header .post-meta a {
  color: #fff;
}

.post-template-9 .post-header-inner .single-post-excerpt {
  margin-top: -5px;
  padding-bottom: 10px;
}

.post-template-9 .post-header-inner .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-template-9 .post-header-inner .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

.post-tp-10-header {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  background-color: #565656;
  height: 350px;
  float: left;
  width: 40%;
  margin: 0 30px 25px 0;
}

.post-template-10 .post-header-inner {
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.post-template-10 .single-featured:empty {
  display: none;
}

.post-template-10 .single-post-title {
  margin: 0 0 13px;
}

.post-template-10 .single-post-title + .post-subtitle {
  margin: -5px 0 13px;
}

.post-template-10 .single-post-title:last-child {
  margin-bottom: 0;
}

.post-template-10 .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 15px;
}

.post-tp-10-header .term-badges.floated .term-badge a, .post-tp-10-header .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-10-header .post-meta, .post-tp-10-header .post-meta .post-author, .post-tp-10-header .post-meta a {
  color: #fff;
}

.post-tp-10-header .post-meta {
  text-align: center;
}

.post-template-10 .post-header-inner .single-post-excerpt {
  margin-top: -5px;
  padding-bottom: 10px;
}

.post-template-10 .post-header-inner .single-post-excerpt:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.post-template-10 .post-header-inner .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-template-10 .post-header-inner .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

@media only screen and (max-width: 780px) {
  .post-tp-10-header {
    width: 100%;
    margin-right: 0;
  }
  .post-template-10 .post-header-inner {
    margin-top: 0;
    padding-top: 0;
  }
  .post-tp-10-header .post-header-inner {
    padding: 20px;
  }
  .post-tp-10-header .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  .post-tp-10-header .image-credit {
    bottom: 5px;
  }
}
.post-tp-11-header {
  position: relative;
  margin-bottom: 15px;
}

.post-tp-11-header.wfi {
  margin-bottom: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
}

.post-tp-11-header.wfi:before {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.55)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 380px;
}

.post-tp-11-header.wfi .post-header-inner {
  height: 470px;
  position: relative;
  overflow: hidden;
}

.post-tp-11-header.wfi .post-header-title {
  position: absolute;
  bottom: 25px;
  left: 25px;
  right: 25px;
}

.post-tp-11-header .single-post-title {
  margin: 0 0 10px;
}

.post-tp-11-header.wfi .single-post-title {
  color: #fff;
}

.post-tp-11-header .single-post-title + .post-subtitle {
  margin: 0 0 13px;
}

.post-tp-11-header.wfi .single-post-title + .post-subtitle {
  color: #fff;
}

.post-tp-11-header .single-post-title:last-child {
  margin-bottom: 0;
}

.post-tp-11-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 15px;
}

.post-tp-11-header.wfi .post-meta, .post-tp-11-header.wfi .post-meta .post-author-a:hover, .post-tp-11-header.wfi .post-meta .views, .post-tp-11-header.wfi .post-meta a, .post-tp-11-header.wfi .post-meta a:hover, .post-tp-11-header.wfi .post-meta b {
  color: #fff !important;
}

.post-tp-11-header.wfi .post-meta.single-post-meta .post-author-a, .post-tp-11-header.wfi .post-meta.single-post-meta .post-author-a:hover, .post-tp-11-header.wfi .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-11-header.wfi .post-meta.single-post-meta > a + span:before, .post-tp-11-header.wfi .post-meta.single-post-meta > span + a:before, .post-tp-11-header.wfi .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-11-header.wfi .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-11-header .image-credit {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 9px;
  overflow: hidden;
  white-space: nowrap;
}

.post-tp-11-header.wfi .image-credit {
  color: rgba(255, 255, 255, 0.7);
}

.post-tp-11-header.wfi .image-credit:hover {
  color: #fff;
}

.post-template-11 .post-embed {
  margin-bottom: 20px;
}

.post-template-11 .bf-breadcrumb {
  margin: -7px 0 9px;
}

.post-tp-11-header .single-post-excerpt {
  color: #fff;
  padding-bottom: 12px;
}

.post-tp-11-header .single-post-excerpt:last-child {
  padding-bottom: 0 !important;
}

.post-tp-11-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

@media only screen and (max-width: 1080px) {
  .post-tp-11-header.wfi .post-header-inner {
    height: 450px;
  }
}
@media only screen and (max-width: 780px) {
  .post-tp-11-header.wfi .post-header-inner {
    height: 380px;
  }
  .post-tp-11-header.wfi .post-header-inner {
    padding: 20px 20px;
  }
  .post-tp-11-header.wfi .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  .post-tp-11-header .image-credit {
    bottom: 5px;
  }
}
.post-tp-12-header {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  background-color: #3c3c3c;
  margin: 0 0 25px;
}

.post-tp-12-header .single-featured {
  margin: 20px auto 0;
  max-width: 900px;
  text-align: center;
}

.post-tp-12-header .single-featured > * {
  margin-left: auto;
  margin-right: auto;
}

.post-tp-12-header .post-header-inner {
  padding: 40px 25px 60px;
  position: relative;
}

.post-tp-12-header .single-post-title {
  color: #fff;
  text-align: center;
  margin: 0 0 10px;
}

.post-tp-12-header .single-post-title + .post-subtitle {
  margin: 0 0 13px;
  color: #c7c7c7;
  text-align: center;
}

.post-tp-12-header .single-post-title:last-child {
  margin-bottom: 30px;
}

.post-tp-12-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  text-align: center;
  margin-bottom: 20px;
}

.post-tp-12-header .term-badges.floated .term-badge a, .post-tp-12-header .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-12-header .post-meta, .post-tp-12-header .post-meta .post-author-a:hover, .post-tp-12-header .post-meta .views, .post-tp-12-header .post-meta a, .post-tp-12-header .post-meta a:hover, .post-tp-12-header .post-meta b {
  color: #fff !important;
}

.post-tp-12-header .post-meta.single-post-meta .post-author-a, .post-tp-12-header .post-meta.single-post-meta .post-author-a:hover, .post-tp-12-header .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-12-header .post-meta.single-post-meta > a + span:before, .post-tp-12-header .post-meta.single-post-meta > span + a:before, .post-tp-12-header .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-12-header .single-post-share {
  margin: 25px 0 -20px !important;
  text-align: center;
}

.post-tp-12-header .single-post-share li {
  margin: 0 3px;
}

.post-tp-12-header .single-post-share .share-handler-wrap {
  max-width: 260px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.post-tp-12-header .single-post-share .post-share-btn {
  background: #3c3c3c;
  border: 1px solid #5d5d5d;
  color: #e8e8e8;
}

.post-tp-12-header .single-post-share .social-item.email a {
  background-color: #6b6b6b;
}

.post-tp-12-header .post-share .share-handler:after, .post-tp-12-header .post-share .share-handler:before {
  border-left-color: #5d5d5d;
}

.post-tp-12-header .post-share .share-handler:after {
  border-left-color: #3c3c3c;
}

.post-tp-12-header .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-12-header .post-meta {
  text-align: center;
}

.post-tp-12-header .image-credit {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 10px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 9px;
  text-align: center;
}

.post-tp-12-header .image-credit:hover {
  color: #fff;
}

.post-tp-12-header .bf-breadcrumb {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 10px;
  opacity: 0.6;
}

.post-tp-12-header .bf-breadcrumb .bf-breadcrumb-item {
  font-size: 11px;
}

.post-tp-12-header .single-post-excerpt {
  color: #fff;
  text-align: center;
}

@media only screen and (max-width: 780px) {
  .post-tp-12-header .post-header-inner {
    padding-left: 0;
    padding-right: 0;
  }
}
.post-template-13 .single-container {
  padding-right: 40px;
  padding-left: 40px;
}

.post-tp-13-header {
  padding: 0;
}

.post-tp-13-header .post-meta-wrap {
  margin: 15px 0;
  padding: 0;
  text-align: center;
}

.post-tp-13-header .post-meta-wrap .post-meta {
  padding: 0;
}

.post-tp-13-header .single-featured {
  margin: 0 -40px 20px;
}

.post-tp-13-header .term-badges {
  position: relative;
  text-align: center;
  top: auto;
  left: auto;
  right: auto;
  margin-bottom: 15px;
}

.post-tp-13-header .single-post-title {
  margin: 0 0 15px;
  text-align: center;
}

.post-tp-13-header .single-post-title + .post-subtitle {
  margin: -1px 0 15px;
  text-align: center;
}

.post-tp-13-header .single-post-title:first-child {
  margin-top: 15px;
}

.post-tp-13-header .single-post-title + .post-share, .post-tp-13-header .single-post-title + .single-featured {
  margin-top: 25px;
}

.post-tp-13-header .single-post-share {
  margin: 15px 0 10px;
  text-align: center;
}

.post-tp-13-header .single-post-share .share-handler-wrap {
  display: inline-block;
}

.post-template-13 .comments-template, body.single .post-template-13 .content-column > .bs-newsletter-pack {
  margin-right: 40px;
  margin-left: 40px;
}

.post-template-13 .ajax-post-content .comments-template.comments-template {
  margin-right: 0;
  margin-left: 0;
}

.post-template-13 .post-related {
  margin-right: 40px;
  margin-left: 40px;
}

.post-template-13 .post-embed {
  margin-bottom: 20px;
}

.post-tp-13-header .bf-breadcrumb {
  margin: -5px 0 12px;
}

.post-tp-13-header .single-post-excerpt {
  text-align: center;
  padding: 0 0 20px;
}

.post-tp-13-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

.post-tp-13-header .single-post-excerpt + .post-meta-wrap {
  margin-top: -7px;
}

@media only screen and (max-width: 780px) {
  .post-template-13 .single-container {
    padding-right: 0;
    padding-left: 0;
  }
  .post-tp-13-header .single-featured {
    margin: 0 0 20px;
  }
  .post-template-13 .comments-template, .post-template-13 .post-related {
    margin-right: 0;
    margin-left: 0;
  }
}
.post-tp-14-header {
  position: relative;
  margin-bottom: 25px;
}

.post-template-14 .post-header-inner {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0 0 25px;
}

.post-template-14 .single-post-title {
  margin: 0 0 15px;
}

.post-template-14 .single-post-title + .post-subtitle {
  margin: -5px 0 15px;
}

.post-template-14 .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 11px;
}

.post-tp-14-header .image-credit {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  padding: 0 8px;
}

.post-tp-14-header.wfi .image-credit:hover {
  background: rgba(0, 0, 0, 0.35);
}

.post-template-14 .post-embed {
  margin-bottom: 20px;
}

.post-template-14 .post-header-inner .single-post-excerpt {
  margin-top: -8px;
  padding-bottom: 10px;
}

.post-template-14 .post-header-inner .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-template-14 .post-header-inner .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

.post-tp-15-header {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  background-color: #3c3c3c;
  margin: 0 0 25px;
}

.post-tp-15-header .single-post-share {
  margin: 25px 0 -20px !important;
  text-align: center;
}

.post-tp-15-header .single-post-share li {
  margin: 0 3px;
  float: none;
  display: inline-block;
}

.post-tp-15-header .single-featured {
  margin: 20px auto 0;
  max-width: 900px;
  text-align: center;
}

.post-tp-15-header .single-featured > * {
  margin-left: auto;
  margin-right: auto;
}

.post-tp-15-header .post-header-inner {
  padding: 40px 25px 50px;
  position: relative;
}

.post-tp-15-header .single-post-title {
  color: #fff;
  text-align: center;
  margin: 0 0 10px;
}

.post-tp-15-header .single-post-title + .post-subtitle {
  margin: 0 0 13px;
  color: #c7c7c7;
  text-align: center;
}

.post-tp-15-header .single-post-title:last-child {
  margin-bottom: 30px;
}

.post-tp-15-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  text-align: center;
  margin-bottom: 20px;
}

.post-tp-15-header .term-badges.floated .term-badge a, .post-tp-15-header .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-15-header .post-meta, .post-tp-15-header .post-meta .post-author-a:hover, .post-tp-15-header .post-meta .views, .post-tp-15-header .post-meta a, .post-tp-15-header .post-meta a:hover, .post-tp-15-header .post-meta b {
  color: #fff !important;
}

.post-tp-15-header .post-meta.single-post-meta .post-author-a, .post-tp-15-header .post-meta.single-post-meta .post-author-a:hover, .post-tp-15-header .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-15-header .post-meta.single-post-meta > a + span:before, .post-tp-15-header .post-meta.single-post-meta > span + a:before, .post-tp-15-header .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-15-header .single-post-share .share-handler-wrap {
  display: inline-block;
}

.post-tp-15-header .single-post-share .post-share-btn {
  background: #3c3c3c;
  border: 1px solid #5d5d5d;
  color: #e8e8e8;
}

.post-tp-15-header .single-post-share .social-item.email a {
  background-color: #6b6b6b;
}

.post-tp-15-header .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-15-header .post-meta {
  text-align: center;
}

.post-tp-15-header .image-credit {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 10px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 9px;
  text-align: center;
}

.post-tp-15-header .image-credit:hover {
  color: #fff;
}

.post-tp-15-header .bf-breadcrumb {
  margin: -15px 0 15px;
}

.post-tp-15-header .single-post-excerpt {
  color: #fff;
  text-align: center;
}

.post-tp-15-header .post-share .share-handler:after, .post-tp-15-header .post-share .share-handler:before {
  border-left-color: #5d5d5d;
}

.post-tp-15-header .post-share .share-handler:after {
  border-left-color: #3c3c3c;
}

.site-footer {
  background-color: #434343;
}

.site-footer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
}

.footer-instagram.boxed, .site-footer.boxed {
  margin-left: auto;
  margin-right: auto;
}

.footer-instagram.full-width.stretched .content-wrap, .site-footer.full-width.stretched .container, .site-footer.full-width.stretched .content-wrap {
  max-width: 100% !important;
}

.footer-widgets {
  padding: 45px 0 35px;
}

.footer-widgets .widget .btn-bs-pagination:hover {
  color: #fff !important;
}

.footer-widgets.light-text .widget.widget_nav_menu ul.menu li a {
  background-color: rgba(0, 0, 0, 0.08);
}

.footer-widgets.light-text .bs-popular-categories .bs-popular-term-item .term-count, .footer-widgets.light-text .widget.widget_archive .post-count, .footer-widgets.light-text .widget.widget_calendar table caption, .footer-widgets.light-text .widget.widget_calendar table td.active-day, .footer-widgets.light-text .widget.widget_calendar table tfoot td, .footer-widgets.light-text .widget.widget_calendar table th, .footer-widgets.light-text .widget.widget_categories .post-count, .footer-widgets.light-text .widget.widget_tag_cloud .tagcloud a {
  background: rgba(255, 255, 255, 0.26);
  color: #fff;
}

.footer-widgets.light-text .bs-popular-categories .bs-popular-term-item, .footer-widgets.light-text .widget.widget_categories ul li, .footer-widgets.light-text .widget.widget_categories ul.children, .footer-widgets.light-text .widget.widget_nav_menu ul.menu .sub-menu > li:last-child a, .footer-widgets.light-text .widget.widget_nav_menu ul.menu li a, .footer-widgets.light-text .widget.widget_pages ul li, .footer-widgets.light-text .widget.widget_tag_cloud .tagcloud a {
  border-color: rgba(255, 255, 255, 0.04);
}

.footer-widgets.light-text .widget.widget_nav_menu ul.menu .sub-menu > li:last-child a {
  border-color: rgba(255, 255, 255, 0.06) !important;
}

.footer-widgets.light-text .widget.widget_tag_cloud .tagcloud a:hover {
  color: rgba(255, 255, 255, 0.71) !important;
}

.footer-widgets.light-text .widget, .footer-widgets.light-text .widget.widget_calendar table td, .footer-widgets.light-text .widget.widget_calendar table td:hover {
  color: rgba(255, 255, 255, 0.71);
}

.footer-widgets.light-text .post-meta .post-author, .footer-widgets.light-text .widget a, .footer-widgets.light-text .widget.widget_categories .post-count {
  color: #fff;
}

.footer-widgets.light-text .widget .bs-about .about-icons-list .about-icon-item a {
  -webkit-box-shadow: inset 0 0 0 1px rgba(228, 228, 228, 0.21);
  -moz-box-shadow: inset 0 0 0 1px rgba(228, 228, 228, 0.21);
  box-shadow: inset 0 0 0 1px rgba(228, 228, 228, 0.21);
  color: #fff;
}

.footer-widgets.light-text .widget .better-social-counter.style-clean .item-count, .footer-widgets.light-text .widget .bs-about .about-icons-list .about-icon-item a:hover {
  color: #fff !important;
}

.footer-widgets.light-text .better-control-nav li a, .footer-widgets.light-text .widget.widget_bs-subscribe-newsletter {
  background: rgba(0, 0, 0, 0.15);
}

.footer-widgets.dark-text .widget.widget_nav_menu ul.menu li a {
  background-color: rgba(0, 0, 0, 0.08);
}

.footer-widgets.dark-text .bs-popular-categories .bs-popular-term-item .term-count, .footer-widgets.dark-text .widget.widget_calendar table caption, .footer-widgets.dark-text .widget.widget_calendar table td.active-day, .footer-widgets.dark-text .widget.widget_calendar table tfoot td, .footer-widgets.dark-text .widget.widget_calendar table th, .footer-widgets.dark-text .widget.widget_categories .post-count {
  background: rgba(0, 0, 0, 0.15);
}

.footer-widgets.dark-text .bs-popular-categories .bs-popular-term-item, .footer-widgets.dark-text .widget.widget_categories ul li, .footer-widgets.dark-text .widget.widget_categories ul.children, .footer-widgets.dark-text .widget.widget_pages ul li {
  border-color: rgba(0, 0, 0, 0.11);
}

.footer-widgets.dark-text .widget, .footer-widgets.dark-text .widget.widget_calendar table td, .footer-widgets.dark-text .widget.widget_calendar table td:hover {
  color: rgba(0, 0, 0, 0.69);
}

.footer-widgets.dark-text .post-meta .post-author {
  color: rgba(0, 0, 0, 0.6);
}

.footer-widgets.dark-text .widget .bs-about .about-icons-list .about-icon-item a {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.46);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.46);
  color: rgba(0, 0, 0, 0.41);
}

.footer-widgets.dark-text .widget .bs-about .about-icons-list .about-icon-item a:hover, .footer-widgets.dark-text .widget.widget_categories .post-count {
  color: #fff !important;
}

.footer-widgets.dark-text .better-control-nav li a, .footer-widgets.dark-text .widget.widget_bs-subscribe-newsletter {
  background: rgba(0, 0, 0, 0.15);
}

.footer-widgets.dark-text .listing-item-text-1 .item-inner {
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

.site-footer .footer-social-icons {
  padding: 20px 0;
  background: #292929;
}

.site-footer .footer-social-icons .social-item {
  margin-bottom: 0;
}

.footer-social-icons .better-social-counter.style-big-button .social-list {
  text-align: center;
  font-size: 0;
}

.footer-social-icons .better-social-counter.style-big-button .social-item {
  display: inline-block;
  float: none !important;
  text-align: left;
}

.site-footer .copy-footer {
  padding: 22px 0 22px;
  background: #353535;
}

.site-footer .copy-footer .container {
  font-size: 12px;
}

.site-footer .copy-1, .site-footer .copy-2 {
  color: #b6b6b6;
  color: rgba(255, 255, 255, 0.57);
}

.site-footer .copy-2 {
  text-align: right;
}

.site-footer .copy-1 a, .site-footer .copy-2 a {
  color: #f3f3f3;
}

@media only screen and (max-width: 780px) {
  .site-footer .copy-1, .site-footer .copy-2 {
    text-align: center !important;
  }
  .site-footer .copy-1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .site-footer ul.menu.footer-menu {
    text-align: center;
  }
  .site-footer ul.menu.footer-menu > li {
    float: none;
    margin: 0 7px;
    display: inline-block;
  }
}
.copy-footer .content-wrap {
  overflow-x: hidden;
}

.footer-menu-container {
  border-bottom: 2px solid #646464;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding: 10px 0;
  margin-bottom: 10px;
  position: relative;
}

ul.menu.footer-menu > li {
  margin-right: 20px;
}

ul.menu.footer-menu li > a {
  color: inherit;
  text-transform: inherit;
}

ul.menu.footer-menu > li.menu-item-has-children > a:after {
  display: none;
}

.site-footer .copy-footer .footer-menu-wrapper .footer-menu-container:before {
  content: "";
  position: absolute;
  width: 200vw;
  height: 100%;
  right: -100vw;
  padding-left: 200px;
  box-sizing: unset;
  top: 0;
}

.site-footer.full-width .copy-footer {
  max-width: 100%;
  overflow: hidden;
}

.site-footer.full-width .copy-footer .content-wrap {
  overflow-x: visible;
}

.site-footer.full-width.stretched .copy-footer .content-wrap {
  overflow-x: hidden;
  overflow-y: hidden;
}

.main-wrap .site-footer.full-width .copy-footer .content-wrap {
  overflow-x: hidden;
  overflow-y: hidden;
}

.footer-instagram .footer-instagram-label {
  margin: 25px 0;
  text-align: center;
  font-size: 18px;
}

.footer-instagram .bs-instagram-photo img {
  max-width: 100%;
}

.footer-instagram-1 {
  padding: 0 2px 2px;
}

.footer-instagram-1 .bs-instagram-col {
  float: left;
  width: 16.666666667%;
  overflow: hidden;
}

.footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-1 .bs-instagram-photo:nth-child(1) {
  max-width: 100%;
}

.footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-1 .bs-instagram-photo:nth-child(2), .footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-1 .bs-instagram-photo:nth-child(3) {
  max-width: 50%;
  float: left;
}

.footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-2 .bs-instagram-photo:nth-child(3) {
  max-width: 100%;
  clear: left;
}

.footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-2 .bs-instagram-photo:nth-child(1), .footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-2 .bs-instagram-photo:nth-child(2) {
  max-width: 50%;
  float: left;
}

.footer-instagram-1 .bs-instagram-col .bs-instagram-photo {
  border: 1px solid transparent;
}

@media only screen and (max-width: 1280px) {
  .footer-instagram-1 .bs-instagram-col {
    width: 20%;
  }
  .footer-instagram-1 .bs-instagram-col.bs-instagram-col-6 {
    display: none;
  }
}
@media only screen and (max-width: 980px) {
  .footer-instagram-1 .bs-instagram-col {
    width: 25%;
  }
  .footer-instagram-1 .bs-instagram-col.bs-instagram-col-5 {
    display: none;
  }
}
@media only screen and (max-width: 780px) {
  .footer-instagram-1 .bs-instagram-col {
    width: 33.33333%;
  }
  .footer-instagram-1 .bs-instagram-col.bs-instagram-col-4 {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .footer-instagram-1 .bs-instagram-col {
    width: 50%;
  }
  .footer-instagram-1 .bs-instagram-col.bs-instagram-col-3 {
    display: none;
  }
}
.footer-instagram-2 {
  margin: 0;
}

.footer-instagram-2 .bs-instagram-photo {
  width: 12.5%;
  float: left;
}

@media only screen and (max-width: 1280px) {
  .footer-instagram-2 .bs-instagram-photo {
    width: 14.285714286%;
  }
  .footer-instagram-2 .bs-instagram-photo-7 {
    display: none;
  }
}
@media only screen and (max-width: 1100px) {
  .footer-instagram-2 .bs-instagram-photo {
    width: 16.666666667%;
  }
  .footer-instagram-2 .bs-instagram-photo-6 {
    display: none;
  }
}
@media only screen and (max-width: 980px) {
  .footer-instagram-2 .bs-instagram-photo {
    width: 20%;
  }
  .footer-instagram-2 .bs-instagram-photo-5 {
    display: none;
  }
}
@media only screen and (max-width: 780px) {
  .footer-instagram-2 .bs-instagram-photo {
    width: 25%;
  }
  .footer-instagram-2 .bs-instagram-photo-4 {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .footer-instagram-2 .bs-instagram-photo {
    width: 33.333%;
  }
  .footer-instagram-2 .bs-instagram-photo-3 {
    display: none;
  }
}
.footer-instagram-3 {
  margin: 0 0 30px;
}

.footer-instagram-3 .bs-instagram-photo {
  width: 200px;
  margin: 0 20px;
  float: left;
}

.footer-instagram-3 .simply-scroll-container {
  position: relative;
}

.footer-instagram-3 .simply-scroll-clip {
  position: relative;
  overflow: hidden;
}

.footer-instagram-3 .simply-scroll-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-instagram-3 .simply-scroll-list li {
  padding: 0;
  margin: 0 15px;
  list-style: none;
}

.footer-instagram-3 .custom .simply-scroll-clip {
  width: 100%;
  height: 210px;
}

@media (min-width: 768px) {
  .active-sticky-sidebar .row.main-section, .active-sticky-sidebar .row > .bs-vc-wrapper {
    display: flex;
  }
}
:root {
  --publisher-spacing: 20;
  --publisher-spacing-half: calc(var(--publisher-spacing) / 2);
  --publisher-spacing-80-percent: calc(var(--publisher-spacing) * 0.83);
  --publisher-spacing-60-percent: calc(var(--publisher-spacing) * 0.66) ;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .container, .vc_column_container.vc_column_container, .vc_row .vc_column_container > .vc_column-inner, .vc_row.vc_column-gap-1, .vc_row.vc_column-gap-10, .vc_row.vc_column-gap-15, .vc_row.vc_column-gap-2, .vc_row.vc_column-gap-20, .vc_row.vc_column-gap-25, .vc_row.vc_column-gap-3, .vc_row.vc_column-gap-30, .vc_row.vc_column-gap-35, .vc_row.vc_column-gap-4, .vc_row.vc_column-gap-5 {
  padding-left: calc(var(--publisher-spacing-half) * 1px);
  padding-right: calc(var(--publisher-spacing-half) * 1px);
}

.bs-vc-content .vc_row.vc_row-no-padding[data-vc-stretch-content=true], .row, .vc_row.wpb_row {
  margin-left: calc(var(--publisher-spacing-half) * -1px);
  margin-right: calc(var(--publisher-spacing-half) * -1px);
}

.vc_row.vc_inner {
  margin-left: calc(var(--publisher-spacing-half) * -1px) !important;
  margin-right: calc(var(--publisher-spacing-half) * -1px) !important;
}

.bs-vc-wrapper > :not(.bs-vc-column), .sidebar > * {
  margin-bottom: calc(var(--publisher-spacing) * 1px);
}

.better-studio-shortcode, .bs-listing, .bs-shortcode, .bsac, .bsp-wrapper, .content-column > div:last-child, .entry-content .better-studio-shortcode, .order-customer-detail, .order-detail-wrap, .single-container, .slider-style-16-container, .slider-style-18-container, .slider-style-2-container, .slider-style-23-container, .slider-style-4-container, .slider-style-8-container, .vc_row .vc_column-inner .wpb_content_element, .wc-account-content-wrap, .widget {
  margin-bottom: calc(var(--publisher-spacing) * 1px);
}

.archive-title {
  margin-bottom: calc(var(--publisher-spacing-60-percent) * 1px);
}

.layout-1-col, .layout-2-col, .layout-3-col {
  margin-top: calc(var(--publisher-spacing-80-percent) * 1px);
}

.layout-1-col.layout-bc-before, .layout-2-col.layout-bc-before, .layout-3-col.layout-bc-before {
  margin-top: calc(var(--publisher-spacing-half) * 1px);
}

.bs-listing.bs-listing-products .bs-pagination, .bs-listing.bs-listing-products .bs-slider-controls, .bs-vc-content > .vc_row.vc_row-fluid.vc_row-has-fill:first-child {
  margin-top: calc(var(--publisher-spacing-80-percent) * -1px) !important;
}

.vc_col-has-fill > .bs-vc-wrapper, .vc_row-has-fill + .vc_row-full-width + .vc_row > .bs-vc-wrapper > .bs-vc-column > .bs-vc-wrapper, .vc_row-has-fill + .vc_row > .bs-vc-wrapper > .bs-vc-column > .bs-vc-wrapper, .vc_row-has-fill + .vc_row > .wpb_column > .bs-vc-wrapper, .vc_row-has-fill > .bs-vc-wrapper > .vc_column_container > .bs-vc-wrapper, .vc_row-has-fill > .wpb_column > .bs-vc-wrapper {
  padding-top: calc(var(--publisher-spacing-80-percent) * 1px) !important;
}

#bbpress-forums #bbp-search-form, .main-section, .vc_col-has-fill .wpb_wrapper > .bs-listing:last-child, .vc_col-has-fill .wpb_wrapper > .bsp-wrapper:last-child, .vc_col-has-fill .wpb_wrapper > :last-child, .vc_row-has-fill .wpb_wrapper > .bs-listing:last-child, .vc_row-has-fill .wpb_wrapper > .bsp-wrapper:last-child, .vc_row-has-fill .wpb_wrapper > :last-child {
  margin-bottom: calc(var(--publisher-spacing-half) * 1px);
}

.bs-listing-modern-grid-listing-3.bs-listing {
  margin-bottom: calc(var(--publisher-spacing-half) * 1px) !important;
}

.vc_row-has-fill .wpb_wrapper > .bs-listing-modern-grid-listing-3.bs-listing:last-child {
  margin-bottom: calc(var(--publisher-spacing-60-percent) * 1px) !important;
}

.bsac.bsac-post-before-author, .comment-respond.comments-template, .comments-template, .post-related, .post-related + .ajax-post-content, .post-related + .comments-template, .post-related + .single-container, .single-container > .bs-newsletter-pack, .single-container > .post-author, .up-sells.products, .woocommerce .cart-collaterals .cart_totals, .woocommerce .cart-collaterals .cross-sells, .woocommerce + .bs-shortcode, .woocommerce + .woocommerce, .woocommerce-checkout-review-order-wrap, .woocommerce-page div.product .related.products, .woocommerce-page div.product .woocommerce-tabs, body.single .content-column > .bs-newsletter-pack {
  margin-top: calc(var(--publisher-spacing) * 1px);
}

.better-gcs-wrapper {
  margin-top: calc(var(--publisher-spacing) * -1px);
}

.slider-container + .bs-sks, .slider-style-1-container, .slider-style-11-container, .slider-style-13-container, .slider-style-15-container, .slider-style-17-container, .slider-style-19-container, .slider-style-2-container.slider-container-1col, .slider-style-20-container, .slider-style-21-container, .slider-style-22-container, .slider-style-3-container, .slider-style-4-container.slider-container-1col, .slider-style-5-container, .slider-style-7-container, .slider-style-9-container {
  padding-top: calc(var(--publisher-spacing-80-percent) * 1px);
  padding-bottom: calc(var(--publisher-spacing) * 1px);
  margin-bottom: calc(var(--publisher-spacing-80-percent) * -1px);
}

.slider-container.slider-bc-before + .bs-sks, .slider-style-1-container.slider-bc-before, .slider-style-11-container.slider-bc-before, .slider-style-13-container.slider-bc-before, .slider-style-15-container.slider-bc-before, .slider-style-17-container.slider-bc-before, .slider-style-19-container.slider-bc-before, .slider-style-20-container.slider-bc-before, .slider-style-21-container.slider-bc-before, .slider-style-22-container.slider-bc-before, .slider-style-23-container.slider-bc-before, .slider-style-3-container.slider-bc-before, .slider-style-5-container.slider-bc-before, .slider-style-7-container.slider-bc-before, .slider-style-9-container.slider-bc-before {
  padding-top: calc(var(--publisher-spacing-half) * 1px);
  padding-bottom: calc(var(--publisher-spacing-half) * 1px);
  margin-bottom: calc(var(--publisher-spacing-half) * 1px);
}

.section-heading {
  margin-bottom: calc(var(--publisher-spacing-60-percent) * 1px);
}

@media only screen and (max-width: 678px) {
  .footer-widgets > .content-wrap > .container > .row > * {
    margin-bottom: calc(var(--publisher-spacing-80-percent) * 1px);
  }
}
.bs-injection.bs-injection-before_footer .bs-injection.bs-injection-after_footer {
  margin-top: calc(var(--publisher-spacing-80-percent) * 1px);
}

.bs-injection.bs-injection-before_header .bs-injection.bs-injection-after_header {
  padding-top: calc(var(--publisher-spacing-80-percent) * 1px);
}

.single-container > .bs-push-noti.post-bottom, .single-container > .bs-push-noti.post-bottom + .post-author {
  margin-top: calc(var(--publisher-spacing) * 1px);
}

.bs-push-noti.post-top {
  margin-bottom: calc(var(--publisher-spacing) * 1px) !important;
}

@font-face {
  font-display: swap;
  font-family: "Poppins-Local";
  src: url(/fonts/Poppins.eot);
  src: url("/fonts/Poppins.eot?#iefix") format("embedded-opentype");
  src: url(/fonts/Poppins.woff) format("woff"), url(/fonts/Poppins.tff) format("truetype"), url("/fonts/Poppins.svg#Poppins-Local") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: "Poppins-SmiBold Local";
  src: url(/fonts/Poppins-SemiBold.eot);
  src: url("/fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype");
  src: url(/fonts/Poppins-SemiBold.woff) format("woff"), url(/fonts/Poppins-SemiBold.ttf) format("truetype"), url("/wieviel-geld-hat-don-mclean-vermoegen-von-don-mclean-2023-biografie-geld-lebenslauf-hier//fonts/Poppins-SemiBold.svg#Poppins-SmiBold Local") format("svg");
  font-weight: 400;
  font-style: normal;
}
.post-template-14 .single-post-title {
  margin: 0 0 15px;
}

.post-template-14 .single-post-title {
  margin: 0 0 15px;
}

.single-post-title {
  font-family: "Poppins-SmiBold Local";
  font-weight: 400;
  text-transform: none;
}

.img-hover img {
  -webkit-transition: all 0.3s ease; /* Safari and Chrome */
  -moz-transition: all 0.3s ease; /* Firefox */
  -o-transition: all 0.3s ease; /* IE 9 */
  -ms-transition: all 0.3s ease; /* Opera */
  transition: all 0.3s ease;
  position: relative;
}

.img-hover img:hover {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.2); /* Safari and Chrome */
  -moz-transform: scale(1.2); /* Firefox */
  -ms-transform: scale(1.2); /* IE 9 */
  -o-transform: translatZ(0) scale(1.2); /* Opera */
  transform: translatZ(0) scale(1.2);
}

.img-hover:hover:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 30px;
  height: 30px;
  border: 1px solid #000;
}

.grayscale {
  -webkit-filter: brightness(1.1) grayscale(100%) contrast(90%);
  -moz-filter: brightness(1.1) grayscale(100%) contrast(90%);
  filter: brightness(1.1) grayscale(100%);
}

.bf-breadcrumb .bf-breadcrumb-item {
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
}

.bf-breadcrumb-item {
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
  margin: 2px;
}

.bf-breadcrumb .bf-breadcrumb-items {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

.bf-breadcrumb-items {
  padding: 0;
  margin: 2px;
  list-style: none;
  font-size: 0;
}

.img-cont.b-error:before, .img-cont.b-loaded:before, .img-holder.b-error:before, .img-holder.b-loaded:before {
  background: 0 0;
  opacity: 0;
  animation: none;
}

.listing-item-blog-1 .featured .img-holder.b-error, .listing-item-blog-1 .featured .img-holder.b-loaded, .listing-item-blog-5 .featured .img-holder.b-error, .listing-item-blog-5 .featured .img-holder.b-loaded, .listing-item-tb-1 .img-holder.b-error, .listing-item-tb-1 .img-holder.b-loaded, .listing-item-tb-2 .img-holder.b-error, .listing-item-tb-2 .img-holder.b-loaded, .listing-item-tb-3 .img-holder.b-error, .listing-item-tb-3 .img-holder.b-loaded {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-position: top center !important;
}

:root {
  --publisher-system-font: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen-Sans","Ubuntu","Cantarell","Helvetica Neue",sans-serif;
  --publisher-primary-color: #0080ce;
  --publisher-menu-height: calc(1px - 1px);
  --wp-adminbar-height: calc(1px - 1px) ;
}

body {
  font: 14px/1.7 Lato, sans-serif;
  color: #5f6569;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  font-size-adjust: auto;
}

::selection {
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  color: #fff;
  text-shadow: none;
}

.heading-typo, .site-header .site-branding .logo, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: #2d2d2d;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  line-height: 1.3;
}

h1, .h1 {
  font-size: 34px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 25px;
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 17px;
}

h6, .h6 {
  font-size: 15px;
}

.bs-injection > .vc_row, .bs-injection > .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .bs-injection > .vc_vc_row, .container, .content-wrap, .footer-instagram.boxed, .page-layout-no-sidebar .bs-vc-content .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .page-layout-no-sidebar .bs-vc-content > .vc_row, .page-layout-no-sidebar .bs-vc-content > .vc_row.vc_row-has-fill .upb-background-text.vc_row, .page-layout-no-sidebar .bs-vc-content > .vc_vc_row, .site-footer.boxed, body.boxed .main-wrap {
  max-width: var(--publisher-site-width);
  width: 100%;
}

@media only screen and (max-width: 668px) {
  .layout-1-col, .layout-2-col, .layout-3-col {
    margin-top: 20px !important;
  }
}
body.page-layout-1-col, body.page-layout-2-col {
  --publisher-site-width: var(--publisher-site-width-2-col);
  --publisher-site-width-content: var(--publisher-site-width-2-col-content-col);
  --publisher-site-width-primary: var(--publisher-site-width-2-col-primary-col);
  --publisher-site-width-secondary: 0 ;
}

@media (min-width: 768px) {
  .layout-2-col .content-column {
    width: var(--publisher-site-width-content);
  }
  .bf-2-main-column-size.bf-2-main-column-size {
    width: var(--publisher-site-width-2-col-content-col);
  }
  .layout-2-col .sidebar-column {
    width: var(--publisher-site-width-primary);
  }
  .bf-2-primary-column-size.bf-2-primary-column-size {
    width: var(--publisher-site-width-2-col-primary-col);
  }
  .layout-2-col.layout-2-col-2 .content-column {
    left: var(--publisher-site-width-primary);
  }
  .rtl .layout-2-col.layout-2-col-2 .content-column {
    left: inherit;
    right: var(--publisher-site-width-primary);
  }
  .layout-2-col.layout-2-col-2 .sidebar-column {
    right: var(--publisher-site-width-content);
  }
  .rtl .layout-2-col.layout-2-col-2 .sidebar-column {
    right: inherit;
    left: var(--publisher-site-width-content);
  }
}
body.page-layout-3-col {
  --publisher-site-width: var(--publisher-site-width-3-col);
  --publisher-site-width-content: var(--publisher-site-width-3-col-content-col);
  --publisher-site-width-primary: var(--publisher-site-width-3-col-primary-col);
  --publisher-site-width-secondary: var(--publisher-site-width-3-col-secondary-col);
  --publisher-site-width-content-col-sm: calc(var(--publisher-site-width-content) + (var(--publisher-site-width-secondary) / 2));
  --publisher-site-width-primary-col-sm: calc(100% - var(--publisher-site-width-content-col-sm));
  --publisher-site-width-primary-col-xs: calc(var(--publisher-site-width-primary) + (var(--publisher-site-width-content) / 2));
  --publisher-site-width-secondary-col-xs: calc(100% - var(--publisher-site-width-primary-col-xs)) ;
}

@media (min-width: 1000px) {
  .layout-3-col .content-column {
    width: var(--publisher-site-width-content);
  }
  .bf-3-main-column-size.bf-3-main-column-size {
    width: var(--publisher-site-width-3-col-content-col);
  }
  .layout-3-col .sidebar-column-primary {
    width: var(--publisher-site-width-primary);
  }
  .bf-3-primary-column-size.bf-3-primary-column-size {
    width: var(--publisher-site-width-3-col-primary-col);
  }
  .layout-3-col .sidebar-column-secondary {
    width: var(--publisher-site-width-secondary);
  }
  .bf-3-secondary-column-size.bf-3-secondary-column-size {
    width: var(--publisher-site-width-3-col-secondary-col);
  }
}
@media (max-width: 1000px) and (min-width: 768px) {
  .layout-3-col .content-column {
    width: var(--publisher-site-width-content-col-sm);
  }
  .layout-3-col .sidebar-column-primary {
    width: var(--publisher-site-width-primary-col-sm);
  }
  .layout-3-col .sidebar-column-secondary {
    display: none;
  }
}
@media (max-width: 767px) and (min-width: 500px) {
  .layout-3-col .sidebar-column-primary {
    width: var(--publisher-site-width-primary-col-xs);
    float: left;
  }
  .layout-3-col .sidebar-column-secondary {
    width: var(--publisher-site-width-secondary-col-xs);
    float: left;
  }
}
@media (max-width: 500px) {
  .layout-3-col .sidebar-column-secondary {
    display: none;
  }
}
@media (min-width: 1001px) {
  .layout-3-col-2 .sidebar-column-primary {
    left: var(--publisher-site-width-secondary);
  }
  .rtl .layout-3-col-2 .sidebar-column-primary {
    left: inherit;
    right: var(--publisher-site-width-secondary);
  }
  .layout-3-col-2 .sidebar-column-secondary {
    right: var(--publisher-site-width-primary);
  }
  .rtl .layout-3-col-2 .sidebar-column-secondary {
    right: inherit;
    left: var(--publisher-site-width-primary);
  }
  .layout-3-col-3 .content-column {
    left: var(--publisher-site-width-primary);
  }
  .rtl .layout-3-col-3 .content-column {
    left: inherit;
    right: var(--publisher-site-width-primary);
  }
  .layout-3-col-3 .sidebar-column-primary {
    right: var(--publisher-site-width-content);
  }
  .rtl .layout-3-col-3 .sidebar-column-primary {
    right: inherit;
    left: var(--publisher-site-width-content);
  }
  .layout-3-col-4 .content-column {
    left: var(--publisher-site-width-secondary);
  }
  .rtl .layout-3-col-4 .content-column {
    left: inherit;
    right: var(--publisher-site-width-secondary);
  }
  .layout-3-col-4 .sidebar-column-primary {
    left: var(--publisher-site-width-secondary);
  }
  .rtl .layout-3-col-4 .sidebar-column-primary {
    left: inherit;
    right: var(--publisher-site-width-secondary);
  }
  .layout-3-col-4 .sidebar-column-secondary {
    right: calc(var(--publisher-site-width-content) + var(--publisher-site-width-primary));
  }
  .rtl .layout-3-col-4 .sidebar-column-secondary {
    right: inherit;
    left: calc(var(--publisher-site-width-content) + var(--publisher-site-width-primary));
  }
  .layout-3-col-5 .content-column {
    left: calc(var(--publisher-site-width-secondary) + var(--publisher-site-width-primary));
  }
  .rtl .layout-3-col-5 .content-column {
    left: inherit;
    right: calc(var(--publisher-site-width-secondary) + var(--publisher-site-width-primary));
  }
  .layout-3-col-5 .sidebar-column-primary {
    right: var(--publisher-site-width-content);
  }
  .rtl .layout-3-col-5 .sidebar-column-primary {
    right: inherit;
    left: var(--publisher-site-width-content);
  }
  .layout-3-col-5 .sidebar-column-secondary {
    right: var(--publisher-site-width-content);
  }
  .rtl .layout-3-col-5 .sidebar-column-secondary {
    right: inherit;
    left: var(--publisher-site-width-content);
  }
  .layout-3-col-6 .content-column {
    left: calc(var(--publisher-site-width-secondary) + var(--publisher-site-width-primary));
  }
  .rtl .layout-3-col-6 .content-column {
    left: inherit;
    right: calc(var(--publisher-site-width-secondary) + var(--publisher-site-width-primary));
  }
  .layout-3-col-6 .sidebar-column-primary {
    right: calc(var(--publisher-site-width-content) - var(--publisher-site-width-secondary));
  }
  .rtl .layout-3-col-6 .sidebar-column-primary {
    right: inherit;
    left: calc(var(--publisher-site-width-content) - var(--publisher-site-width-secondary));
  }
  .layout-3-col-6 .sidebar-column-secondary {
    right: calc(var(--publisher-site-width-content) + var(--publisher-site-width-primary));
  }
  .rtl .layout-3-col-6 .sidebar-column-secondary {
    right: inherit;
    left: calc(var(--publisher-site-width-content) + var(--publisher-site-width-primary));
  }
}
@media (max-width: 1000px) and (min-width: 768px) {
  .layout-3-col-3 .content-column, .layout-3-col-5 .content-column, .layout-3-col-6 .content-column {
    left: var(--publisher-site-width-primary-col-sm);
  }
  .rtl .layout-3-col-3 .content-column, .rtl .layout-3-col-5 .content-column, .rtl .layout-3-col-6 .content-column {
    left: inherit;
    right: var(--publisher-site-width-primary-col-sm);
  }
  .layout-3-col-3 .sidebar-column-primary, .layout-3-col-5 .sidebar-column-primary, .layout-3-col-6 .sidebar-column-primary {
    right: var(--publisher-site-width-content-col-sm);
  }
  .rtl .layout-3-col-3 .sidebar-column-primary, .rtl .layout-3-col-5 .sidebar-column-primary, .rtl .layout-3-col-6 .sidebar-column-primary {
    right: inherit;
    left: var(--publisher-site-width-content-col-sm);
  }
}
code {
  padding: 4px 6px;
  font-size: 90%;
  color: inherit;
  background-color: #eaeaea;
  border-radius: 0;
}

pre {
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #dcdcdc;
  border-radius: 0;
}

a:focus {
  color: inherit;
}

a, a:focus, a:hover {
  text-decoration: none;
  -webkit-transition: all 0.07s ease;
  -moz-transition: all 0.07s ease;
  -o-transition: all 0.07s ease;
  transition: all 0.07s ease;
}

input[type=color], input[type=date], input[type=datetime-local], input[type=email], input[type=month], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week], textarea {
  height: 36px;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

textarea {
  display: block;
  width: 100%;
  height: auto;
  background-color: #fff;
}

input[type=color]:focus, input[type=date]:focus, input[type=datetime-local]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, input[type=password]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, textarea:focus {
  outline: 0;
}

.btn, button, html input[type=button], input[type=button], input[type=reset], input[type=submit] {
  border: none;
  color: #fff;
  border-radius: 0;
  outline: 0;
  height: auto;
  line-height: 1.4;
  padding: 8px 12px;
  vertical-align: middle;
  text-transform: uppercase !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  max-width: 100%;
  white-space: normal;
}

.entry-content .btn, .entry-content .btn a, .entry-content button, .entry-content html input[type=button], .entry-content input[type=button], .entry-content input[type=reset], .entry-content input[type=submit] {
  color: #fff;
}

.btn:focus, .btn:hover, button:focus, button:hover, html input[type=button]:focus, html input[type=button]:hover, input[type=button]:focus, input[type=button]:hover, input[type=reset]:focus, input[type=reset]:hover, input[type=submit]:focus, input[type=submit]:hover {
  opacity: 0.85;
}

.btn.btn-lg, .btn-group-lg > .btn {
  height: 57px;
  line-height: 57px;
  padding: 0 30px;
}

.btn.btn-xs {
  height: 47px;
  line-height: 47px;
  padding: 0 20px;
}

.btn.btn-light {
  background: #fff;
  border: 1px solid #d4d4d4;
  color: #5c5c5c;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 12px;
  height: auto;
  padding: 0 13px;
}

.btn.btn-light.hover, .btn.btn-light:hover {
  border-color: #868686 !important;
  color: #2b2b2b !important;
  background: #fff !important;
}

.btn.btn-light[disabled] {
  border-color: #eaeaea;
  color: #eaeaea;
}

ul.menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.menu a {
  text-decoration: none;
}

ul.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.menu > li {
  float: left;
}

ul.menu > li.alignright {
  float: right;
}

ul.menu li > a {
  position: relative;
}

ul.menu > li .sub-menu {
  background-color: #eee;
  position: absolute;
  top: 102%;
  top: calc(100% + 1px);
  z-index: 100;
  width: 200px;
}

ul.menu li.bs-flip-children {
  direction: rtl;
}

ul.menu li.bs-flip-children .sub-menu, ul.menu li.bs-flip-children a {
  direction: ltr;
}

ul.menu > li .sub-menu .sub-menu {
  left: 99.4%;
  top: 0 !important;
}

ul.menu li.bs-flip-children .sub-menu .sub-menu {
  left: -99.4%;
}

ul.menu li.bs-flip-children .mega-type-link .mega-links > li > a .better-custom-badge, ul.menu li.bs-flip-children .sub-menu.sub-menu .better-custom-badge {
  right: auto;
  left: 10px;
}

ul.menu li.bs-flip-children .sub-menu.sub-menu .menu-have-badge.menu-item-has-children > a > .better-custom-badge {
  right: auto;
  left: 24px;
}

ul.menu > li li:hover > .sub-menu {
  display: block;
}

ul.menu li.menu-have-icon > a > .bf-icon {
  margin-right: 6px;
  display: inline-block;
}

ul.menu li.menu-title-hide > a > .bf-icon {
  margin-right: 0;
}

ul.menu li.menu-title-hide > a {
  padding: 0 15px;
  display: inline-block;
}

ul.menu li.menu-item-has-children.menu-title-hide > a:after {
  display: none;
}

ul.menu li.menu-item-has-children > a:after {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
  margin-left: 6px;
  font-size: 13px;
}

ul.menu .sub-menu > li.menu-item-has-children > a:after {
  position: absolute;
  right: 10px;
  top: 5px;
  content: "\f105";
}

ul.menu li.bs-flip-children .sub-menu > li.menu-item-has-children > a:after {
  content: "\f104";
  left: 10px;
  right: auto;
  margin-right: 8px;
  margin-left: 0;
}

ul.menu.menu li.bs-flip-children .sub-menu a {
  text-align: right;
}

ul.menu .sub-menu .sub-menu > li.menu-item-has-children > a:after {
  content: "\f0da";
}

ul.menu li.menu-have-icon .bf-icon {
  margin-right: 10px;
}

ul.menu li.menu-have-icon.menu-title-hide .bf-icon {
  margin-right: 0;
}

ul.menu .sub-menu > li > a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

ul.menu .sub-menu > li:last-child a {
  border-bottom: none !important;
}

ul.menu .mega-menu, ul.menu .sub-menu {
  z-index: -1;
  -webkit-transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -moz-transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -ms-transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -o-transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  display: none;
  visibility: hidden;
}

ul.menu.bsm-initialized .mega-menu, ul.menu.bsm-initialized .sub-menu {
  display: block;
}

ul.menu.bsm-pure li:hover > .mega-menu, ul.menu.bsm-pure li:hover > .sub-menu {
  visibility: visible !important;
  z-index: 9999 !important;
  opacity: 1 !important;
  display: block !important;
}

ul.menu.bsm-initialized li.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.bsm-enter > .sub-menu {
  visibility: visible;
  z-index: 9999;
}

ul.menu.bsm-initialized li.bsm-leave > .mega-menu, ul.menu.bsm-initialized li.bsm-leave > .sub-menu {
  display: none;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-none > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-none > .sub-menu {
  display: none;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-none.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-none.bsm-enter > .sub-menu {
  display: block;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-fade > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-fade > .sub-menu {
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-fade.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-fade.bsm-enter > .sub-menu {
  opacity: 1;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-fade > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-fade > .sub-menu {
  -webkit-transform: translate3d(0, -10px, 0) scale3d(0.95, 0.95, 0.95);
  transform: translate3d(0, -10px, 0) scale3d(0.95, 0.95, 0.95);
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-fade.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-fade.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-bottom-in > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-bottom-in > .sub-menu {
  -webkit-transform: translate3d(0, 11px, 0);
  transform: translate3d(0, 11px, 0);
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-bottom-in.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-bottom-in.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-top-in > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-top-in > .sub-menu {
  -webkit-transform: translate3d(0, 11px, 0);
  transform: translate3d(0, 11px, 0);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-top-in.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-top-in.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation-name: menu-slide-top-in;
  animation-name: menu-slide-top-in;
}

@-webkit-keyframes menu-slide-top-in {
  0% {
    -webkit-transform: translate3d(0, -11px, 0);
    transform: translate3d(0, -11px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes menu-slide-top-in {
  0% {
    -webkit-transform: translate3d(0, -11px, 0);
    transform: translate3d(0, -11px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-left-in > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-left-in > .sub-menu {
  -webkit-transform: translate3d(11px, 0, 0);
  transform: translate3d(11px, 0, 0);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-left-in.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-left-in.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation-name: menu-slide-left-in;
  animation-name: menu-slide-left-in;
}

@-webkit-keyframes menu-slide-left-in {
  0% {
    -webkit-transform: translate3d(-11px, 0, 0);
    transform: translate3d(-11px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes menu-slide-left-in {
  0% {
    -webkit-transform: translate3d(-11px, 0, 0);
    transform: translate3d(-11px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-right-in > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-right-in > .sub-menu {
  -webkit-transform: translate3d(-11px, 0, 0);
  transform: translate3d(-11px, 0, 0);
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  opacity: 0;
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-right-in.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-slide-right-in.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation-name: menu-slide-right-in;
  animation-name: menu-slide-right-in;
}

@-webkit-keyframes menu-slide-right-in {
  0% {
    -webkit-transform: translate3d(11px, 0, 0);
    transform: translate3d(11px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes menu-slide-right-in {
  0% {
    -webkit-transform: translate3d(11px, 0, 0);
    transform: translate3d(11px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-tada > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-tada > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: scale3d(0.97, 0.97, 0.97);
  transform: scale3d(0.97, 0.97, 0.97);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-tada.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-tada.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-tada;
  animation-name: menu-tada;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

@-webkit-keyframes menu-tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }
  10%, 20% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97) rotate3d(0, 0, 1, -1deg);
    transform: scale3d(0.97, 0.97, 0.97) rotate3d(0, 0, 1, -1deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, 2deg);
    transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, 2deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, -1deg);
    transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
@keyframes menu-tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97) rotate3d(0, 0, 1, -1deg);
    transform: scale3d(0.97, 0.97, 0.97) rotate3d(0, 0, 1, -1deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, 2deg);
    transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, 2deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, -1deg);
    transform: scale3d(1.03, 1.03, 1.03) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-bounce > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-bounce > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: translate3d(0, -8px, 0);
  transform: translate3d(0, -8px, 0);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-bounce.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-bounce.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-bounce;
  animation-name: menu-bounce;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-bounce {
  0%, 20% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    opacity: 0;
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  100%, 53%, 80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes menu-bounce {
  0%, 20% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    opacity: 0;
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  100%, 53%, 80% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
  100% {
    opacity: 1;
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-shake > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-shake > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-shake.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-shake.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-shake;
  animation-name: menu-shake;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-shake {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  20%, 50%, 60%, 70%, 90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes menu-shake {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  20%, 50%, 60%, 70%, 90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-swing > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-swing > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
  transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-swing.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-swing.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-swing;
  animation-name: menu-swing;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-swing {
  0% {
    opacity: 0;
  }
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -4deg);
    transform: rotate3d(0, 0, 1, -4deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 2deg);
    transform: rotate3d(0, 0, 1, 2deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -2deg);
    transform: rotate3d(0, 0, 1, -2deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    opacity: 1;
  }
}
@keyframes menu-swing {
  0% {
    opacity: 0;
  }
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -4deg);
    transform: rotate3d(0, 0, 1, -4deg);
  }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 2deg);
    transform: rotate3d(0, 0, 1, 2deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -2deg);
    transform: rotate3d(0, 0, 1, -2deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    opacity: 1;
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-wobble > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-wobble > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
  transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-wobble.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-wobble.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-wobble;
  animation-name: menu-wobble;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }
  30% {
    -webkit-transform: translate3d(7%, 0, 0) rotate3d(0, 0, 1, 1.5deg);
    transform: translate3d(7%, 0, 0) rotate3d(0, 0, 1, 1.5deg);
  }
  45% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1.5deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1.5deg);
  }
  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 0.7deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 0.7deg);
  }
  75% {
    -webkit-transform: translate3d(-1%, 0, 0) rotate3d(0, 0, 1, -0.7deg);
    transform: translate3d(-1%, 0, 0) rotate3d(0, 0, 1, -0.7deg);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes menu-wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-9%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }
  30% {
    -webkit-transform: translate3d(7%, 0, 0) rotate3d(0, 0, 1, 1.5deg);
    transform: translate3d(7%, 0, 0) rotate3d(0, 0, 1, 1.5deg);
  }
  45% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1.5deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1.5deg);
  }
  60% {
    -webkit-transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 0.7deg);
    transform: translate3d(3%, 0, 0) rotate3d(0, 0, 1, 0.7deg);
  }
  75% {
    -webkit-transform: translate3d(-1%, 0, 0) rotate3d(0, 0, 1, -0.7deg);
    transform: translate3d(-1%, 0, 0) rotate3d(0, 0, 1, -0.7deg);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-x > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-x > .sub-menu {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
  transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-x.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-x.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-flipInX;
  animation-name: menu-flipInX;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-flipInX {
  0% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, 50deg);
    transform: perspective(200px) rotate3d(1, 0, 0, 50deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, -10deg);
    transform: perspective(200px) rotate3d(1, 0, 0, -10deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, 5deg);
    transform: perspective(200px) rotate3d(1, 0, 0, 5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, -2deg);
    transform: perspective(200px) rotate3d(1, 0, 0, -2deg);
  }
  100% {
    -webkit-transform: perspective(200px);
    transform: perspective(200px);
  }
}
@keyframes menu-flipInX {
  0% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, 50deg);
    transform: perspective(200px) rotate3d(1, 0, 0, 50deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, -10deg);
    transform: perspective(200px) rotate3d(1, 0, 0, -10deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, 5deg);
    transform: perspective(200px) rotate3d(1, 0, 0, 5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(200px) rotate3d(1, 0, 0, -2deg);
    transform: perspective(200px) rotate3d(1, 0, 0, -2deg);
  }
  100% {
    -webkit-transform: perspective(200px);
    transform: perspective(200px);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-y > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-y > .sub-menu {
  -webkit-animation-duration: 0.65s;
  animation-duration: 0.65s;
  opacity: 0;
  -webkit-transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
  transform: translate3d(0, -3px, 0) scale3d(0.95, 0.95, 0.95);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-y.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-filip-in-y.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-animation-name: menu-flipInY;
  animation-name: menu-flipInY;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes menu-flipInY {
  0% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, 20deg);
    transform: perspective(200px) rotate3d(0, 1, 0, 20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, -10deg);
    transform: perspective(200px) rotate3d(0, 1, 0, -10deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, 5deg);
    transform: perspective(200px) rotate3d(0, 1, 0, 5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, -1deg);
    transform: perspective(200px) rotate3d(0, 1, 0, -1deg);
  }
  100% {
    -webkit-transform: perspective(200px);
    transform: perspective(200px);
  }
}
@keyframes menu-flipInY {
  0% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, 20deg);
    transform: perspective(200px) rotate3d(0, 1, 0, 20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, -10deg);
    transform: perspective(200px) rotate3d(0, 1, 0, -10deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, 5deg);
    transform: perspective(200px) rotate3d(0, 1, 0, 5deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(200px) rotate3d(0, 1, 0, -1deg);
    transform: perspective(200px) rotate3d(0, 1, 0, -1deg);
  }
  100% {
    -webkit-transform: perspective(200px);
    transform: perspective(200px);
  }
}
ul.menu.bsm-initialized li.menu-item-has-children.better-anim-buzz > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-buzz > .sub-menu {
  -webkit-animation-duration: 0.65s;
  animation-duration: 0.65s;
  opacity: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

ul.menu.bsm-initialized li.menu-item-has-children.better-anim-buzz.bsm-enter > .mega-menu, ul.menu.bsm-initialized li.menu-item-has-children.better-anim-buzz.bsm-enter > .sub-menu {
  opacity: 1;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-name: menu-buzz;
  animation-name: menu-buzz;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes menu-buzz {
  50% {
    -webkit-transform: translateX(1px) rotate(0.7deg);
    transform: translateX(1px) rotate(0.7deg);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(-0.7deg);
    transform: translateX(-1px) rotate(-0.7deg);
  }
}
@keyframes menu-buzz {
  50% {
    -webkit-transform: translateX(1px) rotate(0.7deg);
    transform: translateX(1px) rotate(0.7deg);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(-0.7deg);
    transform: translateX(-1px) rotate(-0.7deg);
  }
}
.main-menu-wrapper.bs-pinning-block.pinned {
  box-shadow: none;
}

.site-header .content-wrap {
  overflow: visible !important;
}

.site-header > .header-inner > .content-wrap > .container {
  position: relative;
}

.site-header .site-branding {
  margin: 0;
}

.site-header .site-branding .logo {
  margin: 0;
  line-height: 0;
  font-size: 50px;
}

.site-header .site-branding .logo.text-logo {
  font-size: 30px;
  line-height: 38px;
}

.site-header .site-branding .logo img {
  max-width: 100%;
}

.site-header .site-branding .logo .site-title {
  display: none;
}

.site-header > .header-inner > .container {
  position: relative;
}

.site-header > .header-inner .row-height {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.site-header > .header-inner .logo-col, .site-header > .header-inner .sidebar-col {
  display: table-cell;
  float: none;
  height: 100%;
  vertical-align: middle;
}

.site-header .header-inner .row {
  position: relative;
}

.site-header.full-width.stretched .content-wrap, .site-header.full-width.stretched .content-wrap .container {
  max-width: 100% !important;
}

.site-header .sidebar-col .wpb_content_element {
  margin-bottom: 0 !important;
}

.site-header .menu-action-buttons {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 35px;
  z-index: 99;
}

.header-style-1.site-header.boxed .menu-action-buttons, .header-style-2.site-header.boxed .menu-action-buttons {
  bottom: -3px;
}

.site-header .menu-action-buttons.width-1 {
  width: 40px;
}

.site-header .menu-action-buttons.width-2 {
  width: 80px;
}

.site-header .menu-action-buttons.width-3 {
  width: 120px;
}

.site-header .menu-action-buttons > div {
  min-width: 37px;
  display: inline-block;
  float: right;
  height: 100%;
  text-align: right;
  position: relative;
}

.site-header .menu-action-buttons > div.close {
  opacity: 1;
}

.site-header .main-menu-container.search-open .menu-action-buttons, .site-header .search-container.open {
  width: 100%;
}

.site-header .menu-action-buttons .search-container {
  z-index: 1;
}

.site-header .main-menu-wrapper .main-menu-container.search-open .main-menu {
  opacity: 0;
  pointer-events: none;
}

.site-header .search-container.open {
  width: 100%;
}

.site-header .off-canvas-menu-icon-container + .search-container.open {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
}

.site-header .main-menu-container.search-open .off-canvas-menu-icon-container + .search-container .search-handler {
  margin-right: 38px;
}

.site-header .search-container .search-handler, .site-header .shop-cart-container .cart-handler {
  position: absolute;
  right: 3px;
  top: 50%;
  margin-top: -15px;
  z-index: 100;
  width: 37px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  color: #434343;
  cursor: pointer;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.site-header .search-container .search-box {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 36px 0 0;
  z-index: 99;
  height: 100%;
  width: 100%;
  text-align: center;
}

.site-header .search-container .search-box .search-form .search-field {
  border: none;
  width: 100%;
  height: 45px;
  background: 0 0;
  line-height: 45px;
  font-size: 14px;
  color: #434343;
}

.site-header .search-container.open .search-box {
  display: block;
}

.site-header .search-container .search-box .search-form input[type=submit] {
  display: none;
}

.site-header .off-canvas-menu-icon-container {
  z-index: 2;
}

.site-header .shop-cart-container .cart-handler {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 17px;
  white-space: nowrap;
}

.site-header .shop-cart-container .cart-handler * {
  white-space: inherit;
}

.site-header .shop-cart-container .cart-box {
  width: 260px;
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  padding: 20px;
  display: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  text-align: left;
}

.site-header .shop-cart-container .cart-box .buttons {
  margin-bottom: 0;
}

.site-header .shop-cart-container .cart-box .buttons .checkout {
  float: right;
}

.site-header .shop-cart-container.open .cart-box {
  display: block;
}

.site-header .main-menu-container.search-open .shop-cart-container {
  display: none;
}

.site-header .shop-cart-container .cart-handler .cart-count {
  display: inline-block;
  line-height: 18px;
  min-width: 18px;
  padding: 0 4px;
  font-size: 12px;
  font-weight: bolder;
  color: #fff;
  margin-left: 1px;
  vertical-align: middle;
  border-radius: 33px;
  font-family: sans-serif;
  text-align: center;
}

.site-header .shop-cart-container .cart-box:after {
  content: "";
  height: 3px;
  position: absolute;
  right: 0;
  left: 0;
  top: -3px;
  display: inline-block;
}

.site-header .shop-cart-container a.remove {
  display: inline-block;
  float: left;
  margin-right: 4px;
  font-size: 14px;
  margin-top: 4px;
}

.site-header .menu-action-buttons > .off-canvas-menu-icon-container {
  margin-right: 6px;
}

.off-canvas-menu-icon-container {
  line-height: 42px;
}

.off-canvas-menu-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
}

.off-canvas-menu-icon .off-canvas-menu-icon-el {
  margin: 9px 0 0;
  width: 16px;
  cursor: pointer;
  height: 2px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: #313131;
  -webkit-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -moz-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -o-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
}

.off-canvas-menu-icon .off-canvas-menu-icon-el:after, .off-canvas-menu-icon .off-canvas-menu-icon-el:before {
  content: "";
  display: inline-block;
  background: #313131;
  width: 20px;
  height: 2px;
  position: absolute;
  top: -6px;
  -webkit-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -moz-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -o-transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
}

.off-canvas-menu-icon .off-canvas-menu-icon-el:after {
  top: 6px;
}

.off-canvas-menu-icon:hover .off-canvas-menu-icon-el:before, .off-canvas-menu-open .off-canvas-menu-icon .off-canvas-menu-icon-el:before {
  top: -8px;
}

.off-canvas-menu-icon:hover .off-canvas-menu-icon-el:after, .off-canvas-menu-open .off-canvas-menu-icon .off-canvas-menu-icon-el:after {
  top: 8px;
}

.off-canvas-menu-icon-container.off-icon-right .off-canvas-menu-icon-el {
  text-align: right;
}

.off-canvas-menu-icon-container.off-icon-right .off-canvas-menu-icon-el:after, .off-canvas-menu-icon-container.off-icon-right .off-canvas-menu-icon-el:before {
  right: 0;
}

.off-canvas-menu-icon-container.off-icon-left .off-canvas-menu-icon-el {
  text-align: left;
}

.off-canvas-container .canvas-close {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 23px;
  right: 18px;
  cursor: pointer;
  z-index: 1;
}

.off-canvas-inner .canvas-close i, .off-canvas-inner .canvas-close:after, .off-canvas-inner .canvas-close:before {
  position: absolute;
  width: 18px;
  height: 2px;
  content: "";
  display: inline-block;
  background: #a8a8a8;
  left: 0;
  top: 7px;
  margin-top: 0;
  border-radius: 14px;
  transition: 0.38s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
}

.off-canvas-inner .canvas-close:hover i, .off-canvas-inner .canvas-close:hover:after, .off-canvas-inner .canvas-close:hover:before {
  background: #111;
}

.off-canvas-container.skin-dark .canvas-close i, .off-canvas-container.skin-dark .canvas-close:after, .off-canvas-container.skin-dark .canvas-close:before {
  background: #7e7e7e;
}

.off-canvas-container.skin-dark .canvas-close:hover i, .off-canvas-container.skin-dark .canvas-close:hover:after, .off-canvas-container.skin-dark .canvas-close:hover:before {
  background: #fff;
}

.off-canvas-inner .canvas-close:before {
  transform: rotate(45deg);
}

.off-canvas-inner .canvas-close:after {
  transform: rotate(-45deg);
}

.off-canvas-inner .canvas-close i {
  width: 0;
  left: inherit;
  right: 50%;
  margin-right: 0;
}

.off-canvas-container.left .canvas-close.open:before, .off-canvas-container.left .canvas-close:hover:before {
  width: 10px;
  left: 0;
  top: 10px;
}

.off-canvas-container.left .canvas-close.open:after, .off-canvas-container.left .canvas-close:hover:after {
  width: 10px;
  left: 0;
  top: 4px;
}

.off-canvas-container.left .canvas-close.open:hover i, .off-canvas-container.left .canvas-close:hover i {
  width: 16px;
  visibility: visible;
  position: absolute;
  right: 50%;
  margin-right: -8px;
}

.off-canvas-container.right .canvas-close {
  left: 18px;
  right: auto;
}

.off-canvas-container.right .canvas-close i, .off-canvas-container.right .canvas-close:after, .off-canvas-container.right .canvas-close:before {
  right: 0;
  left: auto;
}

.off-canvas-container.right .canvas-close i {
  width: 0;
  right: inherit;
  left: 50%;
  margin-left: 0;
  margin-right: auto;
}

.off-canvas-container.right .canvas-close.open:before, .off-canvas-container.right .canvas-close:hover:before {
  width: 10px;
  right: 0;
  left: auto;
  top: 4px;
}

.off-canvas-container.right .canvas-close.open:after, .off-canvas-container.right .canvas-close:hover:after {
  width: 10px;
  right: 0;
  left: auto;
  top: 10px;
}

.off-canvas-container.right .canvas-close.open:hover i, .off-canvas-container.right .canvas-close:hover i {
  width: 16px;
  visibility: visible;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  right: auto;
  margin-right: auto;
}

.main-menu > li {
  margin-right: 10px;
}

.main-menu > li > a {
  line-height: 48px;
  display: inline-block;
  padding: 0 12px;
}

.main-menu .sub-menu > li > a {
  padding: 0 16px 0 10px;
  line-height: 40px;
}

.main-menu.menu > li .sub-menu {
  width: 210px;
  background-color: #fff;
  -webkit-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
}

.main-menu.menu .sub-menu li > a {
  line-height: 1.7;
  padding: 8px 16px;
}

.main-menu.menu .sub-menu > li {
  position: relative;
}

.main-menu.menu .sub-menu > li, .main-menu.menu .sub-menu > li > a {
  color: #434343;
}

.main-menu.menu .sub-menu li.current-menu-item:hover > a, .main-menu.menu .sub-menu > li:hover > a {
  color: #fff;
}

.main-menu.menu .sub-menu > li.menu-item-has-children > a:after {
  top: 7px;
}

.main-menu.menu .sub-menu > li > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.main-menu.menu .sub-menu .sub-menu > li > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.main-menu.menu > li > a:before {
  content: "";
  display: block;
  width: 20%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  visibility: hidden;
}

.main-menu.menu > li.current-menu-item > a:before, .main-menu.menu > li.current-menu-parent > a:before, .main-menu.menu > li:hover > a:before {
  width: 100%;
  visibility: visible;
}

.top-menu > li {
  line-height: 25px;
  margin-right: 15px;
}

.top-menu.menu li {
  position: relative;
}

.top-menu.menu li > a {
  color: #707070;
}

.top-menu .sub-menu > li > a {
  padding: 0 12px;
  line-height: 32px;
}

.top-menu.menu > li .sub-menu {
  width: 180px;
  background-color: #222;
}

.top-menu.menu .sub-menu > li, .top-menu.menu .sub-menu > li > a {
  color: silver;
}

.top-menu.menu .sub-menu > li.menu-item-has-children > a:after {
  top: 7px;
}

.top-menu.menu .sub-menu li > a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.02);
}

.top-menu.menu .bs-pretty-tabs-elements.sub-menu {
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 0 !important;
  border: none !important;
  left: -15px;
  top: 114% !important;
  display: block !important;
}

.top-menu.menu .display-more-items .bs-pretty-tabs-elements {
  opacity: 1;
  visibility: visible !important;
  padding: 0 !important;
  border: none !important;
}

.bspw-header-style-3 > .bs-pinning-block.pinned .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-3 > .bs-pinning-block.unpinned-no-transition .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-4 > .bs-pinning-block.pinned .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-4 > .bs-pinning-block.unpinned-no-transition .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-7 > .bs-pinning-block.pinned .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-7 > .bs-pinning-block.unpinned-no-transition .main-menu > .menu-item > a > .better-custom-badge {
  display: block;
}

.bspw-header-style-1 > .bs-pinning-block.pinned .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-2 > .bs-pinning-block.pinned .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-3 > .bs-pinning-block .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-4 > .bs-pinning-block .main-menu > .menu-item > a > .better-custom-badge, .bspw-header-style-7 > .bs-pinning-block .main-menu > .menu-item > a > .better-custom-badge, .widget.widget_nav_menu .menu .better-custom-badge {
  display: none;
}

.main-menu.menu .better-custom-badge, .off-canvas-menu .menu .better-custom-badge {
  position: absolute;
  left: 11px;
  top: -16px;
  display: inline-block;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 11px;
  padding: 0 5px;
  font-weight: 600;
  letter-spacing: 1px;
  z-index: 1;
  text-transform: uppercase;
  text-align: center;
  min-width: 19px;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.main-menu.menu .better-custom-badge:after, .off-canvas-menu .menu .better-custom-badge:after {
  border: solid 4px transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  bottom: -8px;
  left: 5px;
}

.main-menu.menu > .menu-item:hover > a > .better-custom-badge {
  top: -12px;
}

.main-menu.menu .sub-menu.sub-menu .better-custom-badge, .mega-menu.mega-type-link .mega-links > li > a .better-custom-badge {
  left: auto;
  position: absolute;
  bottom: auto;
  right: 10px;
  top: 50%;
  margin-top: -8px;
}

.mega-menu.mega-type-link .mega-links > li > a .better-custom-badge {
  margin-top: -13px;
}

.main-menu.menu .sub-menu.sub-menu .menu-item.menu-badge-left > a > .better-custom-badge {
  position: inherit !important;
  margin-right: 6px;
}

.main-menu.menu .sub-menu.sub-menu .menu-have-badge.menu-item-has-children > a > .better-custom-badge {
  right: 24px;
}

.main-menu.menu .sub-menu.sub-menu .better-custom-badge:after, .mega-menu.mega-type-link .mega-links > li > a .better-custom-badge:after {
  display: none;
}

.site-header.header-style-3 .main-menu.menu > .menu-item > a > .better-custom-badge, .site-header.header-style-4 .main-menu.menu > .menu-item > a > .better-custom-badge, .site-header.header-style-7 .main-menu.menu > .menu-item > a > .better-custom-badge {
  top: 98%;
}

.site-header.header-style-3 .main-menu.menu > .menu-item:hover > a > .better-custom-badge, .site-header.header-style-4 .main-menu.menu > .menu-item:hover > a > .better-custom-badge, .site-header.header-style-7 .main-menu.menu > .menu-item:hover > a > .better-custom-badge {
  top: 98%;
  left: 20px;
}

.site-header.header-style-3 .main-menu.menu > .menu-item > a > .better-custom-badge:after, .site-header.header-style-4 .main-menu.menu > .menu-item > a > .better-custom-badge:after, .site-header.header-style-7 .main-menu.menu > .menu-item > a > .better-custom-badge:after {
  bottom: inherit;
  top: -8px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.site-header.header-style-5 .main-menu.menu > .menu-item > a > .better-custom-badge {
  top: 3px;
}

.site-header.header-style-6 .main-menu.menu > .menu-item:hover > a > .better-custom-badge, .site-header.header-style-6 .main-menu.menu > .menu-item > a > .better-custom-badge {
  top: 0;
}

.site-header.header-style-8 .main-menu.menu > .menu-item:hover > a > .better-custom-badge, .site-header.header-style-8 .main-menu.menu > .menu-item > a > .better-custom-badge {
  top: 0;
}

.site-header.header-style-3 .bs-pinning-block.pinned .main-menu.menu > .menu-item > a > .better-custom-badge, .site-header.header-style-4 .bs-pinning-block.pinned .main-menu.menu > .menu-item > a > .better-custom-badge, .site-header.header-style-7 .bs-pinning-block.pinned .main-menu.menu > .menu-item > a > .better-custom-badge {
  display: none;
}

.menu .mega-menu {
  width: 100%;
  position: absolute;
  left: 0;
  top: 102%;
  top: calc(100% + 1px);
  z-index: 100;
  background-color: #fff;
  -wbkit-box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.menu > li > .mega-menu .bsm-leave .sub-menu, .menu > li > .mega-menu .sub-menu, .menu > li > .mega-menu li.menu-item-has-children.better-anim-none > .sub-menu {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  transform: none !important;
  transition: none !important;
  animation-name: none !important;
  position: relative;
  top: auto !important;
  left: auto !important;
  background: 0 0 !important;
  width: 100%;
  box-shadow: none !important;
}

.menu > li > .mega-menu .sub-menu .sub-menu {
  padding-left: 15px;
}

.menu .mega-menu li.menu-item-has-children > a:after {
  display: none;
}

.menu > li > .mega-menu .mega-menu-links {
  background-color: #c8c8c8;
}

.mega-menu.mega-type-link {
  list-style: none;
  padding: 22px 25px;
}

.mega-menu.mega-type-link .mega-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mega-menu.mega-type-link .mega-links > li {
  float: left;
}

.mega-menu.mega-type-link .mega-links > li > a {
  color: #434343;
  font-size: 105%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.07);
  width: 100%;
  display: block;
  margin-bottom: 5px;
  padding-bottom: 10px;
}

.mega-menu.mega-type-link .mega-links .sub-menu {
  border: none;
  background: 0 0;
  box-shadow: none;
}

.mega-menu.mega-type-link-list {
  list-style: none;
  padding: 0;
}

.mega-menu.mega-type-link-list .mega-links {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.mega-menu.mega-type-link-list .mega-links > li {
  display: inline-block;
  padding: 10px 12px;
}

.mega-menu.mega-type-link-list .mega-links > li .sub-menu {
  display: none;
  position: absolute;
  top: 100% !important;
  left: 0 !important;
  background-color: #fff !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.37) !important;
  padding: 0 12px;
}

.mega-menu.mega-type-link-list .mega-links > li .sub-menu .sub-menu {
  background-color: #050505 !important;
  padding-left: 0;
}

.mega-menu.mega-type-link-list .mega-links li:hover > .sub-menu {
  display: block;
}

.mega-menu.mega-type-link-list .mega-links > li .sub-menu > li {
  position: inherit;
}

.mega-menu.mega-type-link-list .mega-links > li .sub-menu > li > a {
  border-bottom: none;
}

.mega-menu.mega-type-link-list .mega-links > li .sub-menu > li {
  display: inline-block;
}

.mega-menu.mega-type-link-list .mega-links > .menu-item {
  margin-bottom: 0;
}

.mega-grid-posts {
  padding: 20px;
}

.mega-grid-posts .post:nth-child(5), .mega-grid-posts .postli:nth-child(1) {
  clear: left;
  margin-left: -7px;
}

.mega-grid-posts .bs-listing-listing-mix-2-1, .mega-grid-posts .listing-item-grid-1 {
  margin-bottom: 0;
}

.mega-menu.tabbed-grid-posts .listing.listing-grid {
  display: inline-block;
  width: 100%;
}

.site-header .listing-item-grid-1 .title {
  line-height: 22px;
  font-size: 15px;
}

.site-header .bs-pagination {
  margin: 5px 0 0;
}

.tabbed-grid-posts {
  position: relative;
}

.tabbed-grid-posts:before {
  content: "";
  background-color: #fbfbfb;
  width: 220px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: inline-block;
  border-right: 1px solid #dedede;
}

.tabbed-grid-posts .tabs-section {
  list-style: none;
  width: 220px;
  max-width: 220px;
  text-align: left;
  position: relative;
  z-index: 1;
  float: left;
  padding: 23px 23px 0 23px;
  margin: 0;
}

.tabbed-grid-posts .tabs-section a, .tabbed-grid-posts .tabs-section a:hover {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333 !important;
  padding: 5px 17px;
  border: 1px solid transparent;
  text-transform: capitalize;
  transition: none !important;
}

.tabbed-grid-posts .tabs-section a .fa {
  margin-right: 4px;
  color: #adadad;
  -webkit-transition: all 0.07s ease;
  -moz-transition: all 0.07s ease;
  -o-transition: all 0.07s ease;
  transition: all 0.07s ease;
}

.tabbed-grid-posts .tabs-section .active a .fa {
  color: inherit;
}

.tabbed-grid-posts .tab-content {
  display: block;
  padding: 23px 23px 23px 245px;
}

.tabbed-grid-posts .listing.listing-grid-1 .listing-item-grid-1 {
  margin-bottom: 5px;
}

.tabbed-grid-posts .listing.listing-grid-1.columns-3[max-width~="450px"] .listing-item {
  width: 33.3333% !important;
  float: left !important;
}

.tabbed-grid-posts .listing.listing-grid-1.columns-3 .listing-item:nth-child(3n-2) {
  padding-right: 12px !important;
  clear: left !important;
}

.tabbed-grid-posts .listing.listing-grid-1.columns-3 .listing-item:nth-child(1) {
  clear: none !important;
}

.tabbed-grid-posts .listing.columns-3 .listing-item:nth-child(3n-1) {
  padding: 0 6px !important;
}

.tabbed-grid-posts .listing.columns-3 .listing-item:nth-child(3n+3) {
  padding-left: 12px !important;
}

.mega-type-page-builder {
  padding: 30px 25px;
}

.mega-type-page-builder .bs-listing:last-child, .mega-type-page-builder .bs-shortcode:last-child, .mega-type-page-builder .entry-content .better-studio-shortcode:last-child .mega-type-page-builder .better-studio-shortcode:last-child, .mega-type-page-builder .widget:last-child {
  margin-bottom: 0;
}

.main-menu-wrapper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.main-menu-wrapper .main-menu-container {
  position: relative;
}

.site-header.boxed .main-menu-wrapper .main-menu-container, .site-header.full-width .main-menu-wrapper {
  border-top: 1px solid #dedede;
  border-bottom: 3px solid #dedede;
  background: #fff;
}

body.boxed .site-header .main-menu-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.menu .mega-menu, ul.menu > li .sub-menu {
  top: 107%;
  top: calc(100% + 3px);
}

.site-header.header-style-5 .search-container .search-handler, .site-header.header-style-5 .shop-cart-container .cart-handler, .site-header.header-style-8 .search-container .search-handler, .site-header.header-style-8 .shop-cart-container .cart-handler {
  margin-top: -14px;
}

.site-header.header-style-6 .search-container .search-handler, .site-header.header-style-6 .shop-cart-container .cart-handler {
  margin-top: -13px;
}

.site-header.header-style-1 .header-inner {
  padding: 40px 0;
}

.site-header.header-style-1.h-a-ad .header-inner {
  padding: 30px 0;
}

.site-header.header-style-1 .site-branding {
  text-align: center;
}

.site-header.header-style-1 .main-menu.menu {
  text-align: center;
  height: 68px;
  overflow-y: hidden;
  margin-bottom: -3px;
  padding-top: 20px;
  margin-top: -20px;
}

.site-header.header-style-1.full-width .main-menu.menu .mega-menu, .site-header.header-style-1.full-width .main-menu.menu > li .sub-menu {
  top: 100%;
}

.site-header.header-style-1 .main-menu.menu > li {
  float: none;
  display: inline-block;
  margin: 0 4px;
}

.site-header.header-style-1 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-1 .search-container .search-box .search-form .search-field {
  text-align: center;
}

.site-header.header-style-1.full-width .menu-action-buttons {
  top: 20px;
}

.site-header.header-style-1 .main-menu-wrapper.menu-actions-btn-width-1 .main-menu-container {
  padding: 0 40px;
}

.site-header.header-style-1 .main-menu-wrapper.menu-actions-btn-width-2 .main-menu-container {
  padding: 0 80px;
}

.site-header.header-style-1 .main-menu-wrapper.menu-actions-btn-width-3 .main-menu-container {
  padding: 0 120px;
}

.site-header.header-style-2 .header-inner {
  padding: 20px 0;
}

.site-header.header-style-2 .main-menu.menu {
  height: 68px;
  overflow-y: hidden;
  margin-bottom: -3px;
  padding-top: 20px;
  margin-top: -20px;
}

.site-header.header-style-2.full-width .main-menu.menu .mega-menu, .site-header.header-style-2.full-width .main-menu.menu > li .sub-menu {
  top: 100%;
}

.site-header.header-style-2 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0 5px 0 0;
}

.site-header.header-style-2 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-2.full-width .menu-action-buttons {
  top: 20px;
}

.site-header.header-style-2 .main-menu-wrapper.menu-actions-btn-width-1 .main-menu-container {
  padding-right: 40px;
}

.site-header.header-style-2 .main-menu-wrapper.menu-actions-btn-width-2 .main-menu-container {
  padding-right: 80px;
}

.site-header.header-style-2 .main-menu-wrapper.menu-actions-btn-width-3 .main-menu-container {
  padding-right: 120px;
}

.site-header.header-style-3 .header-inner {
  padding: 25px 0;
}

.site-header.header-style-3 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
}

.site-header.header-style-3 .main-menu.menu {
  height: 63px;
  overflow-y: hidden;
  margin-bottom: -16px;
  padding-bottom: 15px;
}

.site-header.header-style-3 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-3.boxed .main-menu.menu .mega-menu, .site-header.header-style-3.boxed .main-menu.menu > li .sub-menu {
  top: 103%;
  top: calc(100% + 1px);
}

.site-header.header-style-3.full-width .main-menu.menu .mega-menu, .site-header.header-style-3.full-width .main-menu.menu > li .sub-menu {
  top: 76%;
  top: calc(100% - 15px);
}

.site-header.header-style-3.full-width .main-menu-wrapper.pinned .main-menu.menu .mega-menu, .site-header.header-style-3.full-width .main-menu-wrapper.pinned .main-menu.menu > li .sub-menu {
  top: 100%;
}

.site-header.boxed.header-style-3 .main-menu-wrapper .main-menu-container, .site-header.full-width.header-style-3 .main-menu-wrapper {
  border-bottom-width: 1px;
  border-top: none;
  border-bottom-color: #ececec;
}

.site-header.header-style-3 .main-menu.menu > li.current-menu-item > a:before, .site-header.header-style-3 .main-menu.menu > li.current-menu-parent > a:before, .site-header.header-style-3 .main-menu.menu > li:hover > a:before {
  height: 2px;
  bottom: 0;
}

.site-header.header-style-3 .ajax-search-results:after, .site-header.header-style-3 .shop-cart-container .cart-box:after {
  height: 2px;
  top: -2px;
}

.site-header.header-style-3.full-width .menu-action-buttons {
  bottom: 15px;
}

.site-header.header-style-3.full-width .bs-pinning-block.pinned .menu-action-buttons {
  bottom: 0;
}

.site-header.header-style-3 .main-menu-wrapper.menu-actions-btn-width-1 .main-menu-container {
  padding-right: 40px;
}

.site-header.header-style-3 .main-menu-wrapper.menu-actions-btn-width-2 .main-menu-container {
  padding-right: 80px;
}

.site-header.header-style-3 .main-menu-wrapper.menu-actions-btn-width-3 .main-menu-container {
  padding-right: 120px;
}

.site-header.header-style-3 .bs-pinning-block.pinned .main-menu.menu {
  height: 48px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.site-header.header-style-3 .bs-pinning-block.pinned .main-menu.menu {
  margin-bottom: -1px;
}

@media only screen and (max-width: 700px) {
  body.bs-show-ha .site-header.header-style-3 {
    border-bottom: none !important;
  }
}
.site-header.header-style-4 .header-inner {
  padding: 50px 0;
}

.site-header.header-style-4.h-a-ad .header-inner {
  padding: 30px 0;
}

.site-header.header-style-4 .main-menu.menu {
  height: 63px;
  overflow-y: hidden;
  margin-bottom: -16px;
  padding-bottom: 15px;
}

.site-header.header-style-4 .main-menu.menu .mega-menu, .site-header.header-style-4 .main-menu.menu > li .sub-menu {
  top: 102%;
  top: calc(100% + 1px);
}

.site-header.header-style-4.full-width .main-menu-wrapper.pinned .main-menu.menu .mega-menu, .site-header.header-style-4.full-width .main-menu-wrapper.pinned .main-menu.menu > li .sub-menu {
  top: 100%;
}

.site-header.header-style-4 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
}

.site-header.header-style-4 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.boxed.header-style-4 .main-menu-wrapper .main-menu-container, .site-header.full-width.header-style-4 .main-menu-wrapper {
  border-bottom-width: 1px;
  border-top: none;
  border-bottom-color: #ececec;
}

.site-header.header-style-4 .site-branding {
  text-align: center;
}

.site-header.header-style-4 .main-menu.menu {
  text-align: center;
}

.site-header.header-style-4 .main-menu.menu > li {
  float: none;
  display: inline-block;
}

.site-header.header-style-4 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-4 .bs-pinning-block.pinned .main-menu.menu, .site-header.header-style-4 .main-menu-wrapper .main-menu-container {
  height: 48px;
  margin-bottom: -1px;
  padding-bottom: 0;
}

.active-top-line .site-header.header-style-4.boxed .bs-pinning-block.pinned .main-menu-container {
  height: 50px;
}

.site-header.header-style-4 .search-container .search-box .search-form .search-field {
  text-align: center;
}

.site-header.header-style-4 .main-menu-wrapper.menu-actions-btn-width-1 .main-menu-container {
  padding: 0 40px;
}

.site-header.header-style-4 .main-menu-wrapper.menu-actions-btn-width-2 .main-menu-container {
  padding: 0 80px;
}

.site-header.header-style-4 .main-menu-wrapper.menu-actions-btn-width-3 .main-menu-container {
  padding: 0 120px;
}

.site-header.header-style-4.boxed .menu-action-buttons {
  bottom: 0;
}

.site-header.header-style-5 .header-inner {
  padding: 0;
  position: relative;
}

.site-header.header-style-5.boxed .header-inner, .site-header.header-style-5.full-width {
  border-bottom: 1px solid #eee;
}

.site-header.header-style-5 .main-menu.menu {
  text-align: right;
  width: 100%;
}

.site-header.header-style-5 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0;
}

.site-header.header-style-5 .main-menu.menu > li > a {
  padding: 0 15px;
}

.site-header.header-style-5 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-5 .site-branding {
  width: 23.333%;
  float: left;
}

.site-header.header-style-5 .main-menu-container {
  width: 76.666%;
  float: right;
}

.site-header.header-style-5 .main-menu > li > a {
  line-height: 78px;
}

.site-header.header-style-5 .site-branding .logo {
  line-height: 78px;
  display: table;
  width: 100%;
}

.site-header.header-style-5 .site-branding .logo, .site-header.header-style-5 .site-branding .logo img {
  max-height: 74px;
}

.site-header.header-style-5 .site-branding .logo.img-logo a {
  display: table-cell;
  vertical-align: middle;
  line-height: 74px;
  height: 74px;
  padding: 0 10px 0 0;
}

.site-header.header-style-5 .site-branding .logo.text-logo a {
  vertical-align: middle;
  padding: 5px 10px 5px 0;
}

.site-header.header-style-5 .site-branding .logo img {
  vertical-align: middle;
  margin: 0;
  max-height: 100%;
  display: table-cell;
  max-width: 100%;
  table-layout: fixed;
}

.site-header.header-style-5 .ajax-search-results:after, .site-header.header-style-5 .main-menu.menu > li.current-menu-item > a:before, .site-header.header-style-5 .main-menu.menu > li.current-menu-parent > a:before, .site-header.header-style-5 .main-menu.menu > li:hover > a:before, .site-header.header-style-5 .shop-cart-container .cart-box:after {
  height: 2px;
  bottom: -1px;
}

.site-header.header-style-5 .main-menu.menu > li.current-menu-item > a, .site-header.header-style-5 .main-menu.menu > li.current-menu-parent > a, .site-header.header-style-5 .main-menu.menu > li:hover > a {
  background: rgba(0, 0, 0, 0.05);
}

.site-header.header-style-5 .main-menu.menu > li.menu-item-has-children > a:after {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.21);
}

.site-header.header-style-5 .content-wrap > .bs-pinning-wrapper > .bs-pinning-block {
  box-shadow: none;
  background: #fff;
}

.site-header.header-style-5.full-width .content-wrap.bs-pinning-block.pinned {
  max-width: 100% !important;
}

.site-header.header-style-5.full-width .content-wrap.pinned {
  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid #eee;
}

.site-header.header-style-5 .main-menu.menu, .site-header.header-style-6 .main-menu.menu, .site-header.header-style-8 .main-menu.menu {
  height: 79px;
  overflow-y: hidden;
  margin-bottom: -1px;
}

.site-header.header-style-6 .main-menu.menu {
  height: 91px;
}

.site-header.header-style-8 .main-menu.menu {
  height: 67px;
}

.site-header.header-style-5 .main-menu.menu .mega-menu, .site-header.header-style-5 .main-menu.menu > li .sub-menu, .site-header.header-style-6 .main-menu.menu .mega-menu, .site-header.header-style-6 .main-menu.menu > li .sub-menu, .site-header.header-style-8 .main-menu.menu .mega-menu, .site-header.header-style-8 .main-menu.menu > li .sub-menu {
  top: 101%;
  top: calc(100% + 1px);
}

.site-header.header-style-5 .main-menu.menu > li .sub-menu .sub-menu, .site-header.header-style-6 .main-menu.menu > li .sub-menu .sub-menu, .site-header.header-style-8 .main-menu.menu > li .sub-menu .sub-menu {
  top: 0;
}

.site-header.header-style-5 .main-menu-container.menu-actions-btn-width-1, .site-header.header-style-6 .main-menu-container.menu-actions-btn-width-1, .site-header.header-style-8 .main-menu-container.menu-actions-btn-width-1 {
  padding-right: 40px;
}

.site-header.header-style-5 .main-menu-container.menu-actions-btn-width-2, .site-header.header-style-6 .main-menu-container.menu-actions-btn-width-2, .site-header.header-style-8 .main-menu-container.menu-actions-btn-width-2 {
  padding-right: 80px;
}

.site-header.header-style-5 .main-menu-container.menu-actions-btn-width-3, .site-header.header-style-6 .main-menu-container.menu-actions-btn-width-3, .site-header.header-style-8 .main-menu-container.menu-actions-btn-width-3 {
  padding-right: 120px;
}

.site-header.header-style-5 .main-menu-container.search-open .search-container {
  width: 66.66%;
  line-height: 78px;
  right: 0;
  left: auto;
}

.site-header.header-style-6 .main-menu-container.search-open .search-container {
  width: 66.66%;
  line-height: 90px;
  right: 0;
  left: auto;
}

.site-header.header-style-8 .main-menu-container.search-open .search-container {
  width: 75%;
  line-height: 66px;
  right: 0;
  left: auto;
}

.site-header.header-style-5 .main-menu-container.search-open .main-menu.menu, .site-header.header-style-6 .main-menu-container.search-open .main-menu.menu, .site-header.header-style-8 .main-menu-container.search-open .main-menu.menu {
  opacity: 0;
  pointer-events: none;
}

.site-header.header-style-5 .menu-action-buttons > div {
  line-height: 78px;
}

.site-header.header-style-6 .menu-action-buttons > div {
  line-height: 90px;
}

.site-header.header-style-8 .menu-action-buttons > div {
  line-height: 66px;
}

.site-header.header-style-5 .shop-cart-container .cart-box, .site-header.header-style-6 .shop-cart-container .cart-box, .site-header.header-style-8 .shop-cart-container .cart-box {
  line-height: 22px;
}

.site-header.header-style-5 .menu-action-buttons .off-canvas-menu-icon, .site-header.header-style-8 .menu-action-buttons .off-canvas-menu-icon {
  margin-top: -2px;
}

.site-header.header-style-5 .search-container .search-box .search-form .search-field {
  height: 78px;
}

.site-header.header-style-6 .search-container .search-box .search-form .search-field {
  height: 90px;
}

.site-header.header-style-8 .search-container .search-box .search-form .search-field {
  height: 66px;
}

.site-header.header-style-5 .shop-cart-container .cart-box:after, .site-header.header-style-6 .shop-cart-container .cart-box:after, .site-header.header-style-8 .shop-cart-container .cart-box:after {
  content: "";
  height: 2px;
  position: absolute;
  right: 0;
  left: 0;
  top: -1px;
  opacity: 1;
  visibility: visible;
}

.site-header.header-style-6 .header-inner {
  padding: 0;
  position: relative;
}

.site-header.header-style-6.boxed .header-inner, .site-header.header-style-6.full-width {
  border-bottom: 1px solid #eee;
}

.site-header.header-style-6 .main-menu.menu {
  float: right;
}

.site-header.header-style-6 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0;
}

.site-header.header-style-6 .main-menu.menu > li > a {
  padding: 0 15px;
}

.site-header.header-style-6 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-6 .site-branding {
  width: 23.333%;
  float: left;
  line-height: 90px;
}

.site-header.header-style-6 .main-menu-container {
  width: 76.666%;
  float: right;
}

.site-header.header-style-6 .main-menu > li > a {
  line-height: 90px;
}

.site-header.header-style-6 .site-branding .logo {
  display: table;
  width: 100%;
  line-height: 86px;
  height: 86px;
}

.site-header.header-style-6 .site-branding .logo.img-logo a {
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px 0 0;
}

.site-header.header-style-6 .site-branding .logo.text-logo a {
  vertical-align: middle;
  padding: 5px 10px 5px 0;
}

.site-header.header-style-6 .site-branding .logo img {
  vertical-align: middle;
  margin: 0;
  display: table-cell;
  max-width: 100%;
  table-layout: fixed;
}

.site-header.header-style-6 .site-branding .logo img {
  padding: 0;
  line-height: 88px;
  max-height: 88px;
}

.site-header.header-style-6 .main-menu.menu > li > a > .bf-icon {
  position: absolute;
  width: 100%;
  top: 24px;
  left: 0;
  text-align: center;
  margin-right: 0;
  font-size: 16px;
}

.site-header.header-style-6 .main-menu.menu > li > a > .bf-icon img {
  vertical-align: top;
}

.site-header.header-style-6 .main-menu.menu > li > a > .bf-icon.bf-custom-icon {
  top: 20px;
}

.site-header.header-style-6 .main-menu.menu > li.menu-have-icon > a {
  padding-top: 24px;
  line-height: 66px;
}

.site-header.header-style-6 .main-menu.menu > li.current-menu-item > a:before, .site-header.header-style-6 .main-menu.menu > li.current-menu-parent > a:before, .site-header.header-style-6 .main-menu.menu > li:hover > a:before {
  height: 2px;
  bottom: -1px;
}

.site-header.header-style-6 .main-menu.menu > li.current-menu-item > a, .site-header.header-style-6 .main-menu.menu > li.current-menu-parent > a, .site-header.header-style-6 .main-menu.menu > li:hover > a {
  background: rgba(0, 0, 0, 0.05);
}

.site-header.header-style-6 .main-menu.menu > li.menu-item-has-children > a:after {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.21);
}

.site-header.header-style-6 .content-wrap > .bs-pinning-wrapper > .bs-pinning-block {
  box-shadow: none;
  background: #fff;
}

.site-header.header-style-6.full-width .content-wrap {
  max-width: 100% !important;
}

.site-header.header-style-6.full-width .content-wrap.pinned {
  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid #eee;
}

.site-header.header-style-7 .header-inner {
  padding: 50px 0;
}

.site-header.header-style-7.h-a-ad .header-inner {
  padding: 30px 0;
}

.site-header.header-style-7 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
}

.site-header.header-style-7 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.boxed.header-style-7 .main-menu-wrapper .main-menu-container, .site-header.full-width.header-style-7 .main-menu-wrapper {
  border-bottom-width: 1px;
  border-top: none;
  border-bottom-color: #ececec;
}

.site-header.header-style-7 .site-branding {
  text-align: center;
}

.site-header.header-style-7 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-7 .main-menu.menu {
  height: 63px;
  overflow-y: hidden;
  margin-bottom: -16px;
  padding-bottom: 15px;
}

.site-header.header-style-7.boxed .main-menu.menu .mega-menu, .site-header.header-style-7.boxed .main-menu.menu > li .sub-menu {
  top: 102%;
  top: calc(100% + 1px);
}

.site-header.header-style-7.full-width .main-menu.menu .mega-menu, .site-header.header-style-7.full-width .main-menu.menu > li .sub-menu {
  top: 76%;
  top: calc(100% - 15px);
}

.site-header.header-style-7.full-width .main-menu-wrapper.pinned .main-menu.menu .mega-menu, .site-header.header-style-7.full-width .main-menu-wrapper.pinned .main-menu.menu > li .sub-menu {
  top: 100%;
}

.site-header.header-style-7.full-width .menu-action-buttons {
  bottom: 15px;
}

.site-header.header-style-7 .main-menu-wrapper.menu-actions-btn-width-1 .main-menu-container {
  padding-right: 40px;
}

.site-header.header-style-7 .main-menu-wrapper.menu-actions-btn-width-2 .main-menu-container {
  padding-right: 80px;
}

.site-header.header-style-7 .main-menu-wrapper.menu-actions-btn-width-3 .main-menu-container {
  padding-right: 120px;
}

.site-header.header-style-7 .bs-pinning-block.pinned .main-menu.menu {
  height: 48px;
  margin-bottom: -1px;
  padding-bottom: 0;
}

.site-header.header-style-7.full-width .bs-pinning-block.pinned .menu-action-buttons {
  bottom: 0;
}

.site-header.header-style-8 .header-inner {
  padding: 0;
  position: relative;
}

.site-header.header-style-8.boxed .header-inner, .site-header.header-style-8.full-width {
  border-bottom: 1px solid #eee;
}

.site-header.header-style-8 .main-menu.menu > li {
  float: left;
  display: inline-block;
  margin: 0 10px 0 0;
}

.site-header.header-style-8 .main-menu.menu > li > a {
  padding: 0 7px;
}

.site-header.header-style-8 .main-menu.menu .sub-menu {
  text-align: left;
}

.site-header.header-style-8 .site-branding {
  width: 20%;
  float: left;
  padding-right: 20px;
}

.site-header.header-style-8 .main-menu-container {
  width: 80%;
  float: right;
}

.site-header.header-style-8 .main-menu > li > a {
  line-height: 66px;
}

.site-header.header-style-8 .site-branding .logo {
  line-height: 58px;
  display: table;
  width: 100%;
}

.site-header.header-style-8 .site-branding .logo.img-logo a {
  display: table-cell;
  vertical-align: middle;
  line-height: 62px;
  height: 62px;
  padding: 0 10px 0 0;
  position: relative;
  z-index: 100;
}

.site-header.header-style-8 .site-branding .logo.text-logo a {
  vertical-align: middle;
  padding: 5px 10px 5px 0;
}

.site-header.header-style-8 .site-branding .logo img {
  vertical-align: middle;
  margin: 0;
  max-height: 100%;
  display: table-cell;
  max-width: 100%;
  table-layout: fixed;
}

.site-header.header-style-8 .site-branding .logo, .site-header.header-style-8 .site-branding .logo img {
  max-height: 58px;
  padding: 0;
}

.site-header.header-style-8 .main-menu.menu > li.current-menu-item > a:before, .site-header.header-style-8 .main-menu.menu > li.current-menu-parent > a:before, .site-header.header-style-8 .main-menu.menu > li:hover > a:before {
  height: 2px;
  bottom: -1px;
}

.site-header.header-style-8 .main-menu.menu > li.menu-item-has-children > a:after {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  text-align: center;
  bottom: 0;
  color: rgba(0, 0, 0, 0.21);
}

.site-header.header-style-8 .content-wrap > .bs-pinning-wrapper > .bs-pinning-block {
  box-shadow: none;
  background: #fff;
}

.site-header.header-style-8.full-width .content-wrap {
  max-width: 100% !important;
}

.site-header.header-style-8.full-width .content-wrap.pinned {
  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid #eee;
}

@media only screen and (max-width: 992px) {
  .main-menu > li {
    margin-right: 10px;
  }
  .site-header.header-style-1 .main-menu.menu > li {
    margin: 0 4px;
  }
  .site-header.header-style-3 .main-menu.menu > li, .site-header.header-style-4 .main-menu.menu > li, .site-header.header-style-8 .main-menu.menu > li {
    margin: 0 7px 0 0;
  }
  .site-header.header-style-5 .main-menu.menu > li > a, .site-header.header-style-6 .main-menu.menu > li > a, .site-header.header-style-8 .main-menu.menu > li > a {
    padding: 0 7px;
  }
  .main-menu > li > a {
    padding: 0 4px;
  }
}
.rh-header {
  display: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 300px;
  position: relative;
}

.rh-header .rh-container {
  background-color: #222;
  user-select: none;
}

@media only screen and (max-width: 700px) {
  body .site-header, body.bs-hide-ha .site-header {
    display: none;
  }
  body.bs-show-ha .site-header {
    display: block;
  }
  body.bs-show-ha .site-header .bs-pinning-wrapper, body.bs-show-ha .site-header .header-inner .logo-col, body.bs-show-ha .site-header .main-menu-wrapper, body.bs-show-ha .site-header .topbar {
    display: none;
  }
  body.bs-show-ha .site-header .header-inner .sidebar-col, body.bs-show-ha .site-header > .header-inner .row-height {
    display: block;
    height: auto;
    width: 100%;
  }
  body.bs-show-ha .site-header.header-style-2 .header-inner, body.bs-show-ha .site-header.header-style-3 .header-inner {
    padding: 15px 0;
  }
  body.bs-show-ha-a .main-wrap {
    display: flex;
    flex-direction: column;
  }
  body.bs-show-ha-a .main-wrap .rh-header {
    order: 1;
  }
  body.bs-show-ha-a .main-wrap .site-header {
    order: 2;
  }
  body.bs-show-ha-a .main-wrap > * {
    order: 3;
  }
  body.bs-show-ha.bs-show-ha-a .site-header.header-style-2 .header-inner, body.bs-show-ha.bs-show-ha-a .site-header.header-style-3 .header-inner {
    padding: 15px 0 0;
  }
  .rh-header {
    display: block;
  }
}
.rh-header .menu-container {
  position: absolute;
  top: 0;
  right: 0;
}

.rh-header .menu-container .menu-handler {
  color: #fff;
  width: 54px;
  height: 54px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  float: right;
}

.rh-header .menu-container .menu-handler .lines {
  display: inline-block;
  width: 22px;
  height: 2px;
  background: #ecf0f1;
  position: relative;
  margin-top: 25.5px;
}

.rh-header .menu-container .menu-handler .lines:after, .rh-header .menu-container .menu-handler .lines:before {
  display: inline-block;
  width: 22px;
  height: 2px;
  background: #ecf0f1;
  position: absolute;
  left: 0;
  content: "";
}

.rh-header .menu-container .menu-handler .lines:before {
  top: 8px;
}

.rh-header .menu-container .menu-handler .lines:after {
  top: -8px;
}

.rh-cover .rh-c-m .resp-menu li {
  display: block;
  line-height: 46px;
  float: none;
  position: relative;
}

.rh-cover .rh-c-m .resp-menu .sub-menu a > .fa {
  min-width: 12px;
}

.rh-cover .rh-c-m .resp-menu li a > .bf-icon {
  display: inline-block;
  min-width: 22px;
  margin: 0;
}

.rh-cover .rh-c-m .resp-menu li > a {
  padding: 0 0 0 3px;
  color: #fff;
  display: block;
  position: relative;
  font-size: 14px;
  border-bottom: none;
  transition: none;
}

.rh-cover .rh-c-m .resp-menu > li > a {
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
}

.rh-cover .rh-c-m .resp-menu li.menu-item-has-children.open-sub > a {
  border-bottom-color: transparent !important;
}

.rh-cover .rh-c-m .resp-menu .children-button {
  position: absolute;
  right: 0;
  top: 11px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
  color: silver;
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  -moz-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  opacity: 0.5;
}

.rh-cover .rh-c-m .resp-menu + .resp-menu .children-button {
  top: 8px;
}

.rh-cover .rh-c-m .resp-menu li.open-sub > .children-button {
  background: rgba(0, 0, 0, 0.13);
}

.rh-cover .rh-c-m .resp-menu li li .children-button {
  top: 1px;
}

.rh-cover .rh-c-m .resp-menu .children-button:after, .rh-cover .rh-c-m .resp-menu .children-button:before {
  content: "";
  position: absolute;
  top: 11px;
  right: 6px;
  width: 11px;
  height: 2px;
  display: inline-block;
  background: #fff;
  -webkit-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  -moz-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
}

.rh-cover .rh-c-m .resp-menu .children-button:after {
  transform: rotate(90deg);
}

.rh-cover .rh-c-m .resp-menu .open-sub > .children-button {
  opacity: 0.75;
}

.rh-cover .rh-c-m .resp-menu .open-sub > .children-button:after {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
}

.rh-cover .rh-c-m .resp-menu li.menu-item-has-children > a:after {
  display: none;
}

.rh-cover .rh-c-m .resp-menu li:hover > .mega-menu, .rh-cover .rh-c-m .resp-menu li:hover > .sub-menu {
  display: none;
}

.rh-cover .rh-c-m .resp-menu .mega-menu, .rh-cover .rh-c-m .resp-menu .sub-menu {
  display: none;
  visibility: visible;
  opacity: 1 !important;
  transform: none !important;
  transition: none !important;
  animation-name: none !important;
  position: relative;
  top: inherit !important;
  left: inherit !important;
  right: auto !important;
  background-color: transparent;
  width: 100%;
  margin-top: 1px;
  z-index: 1;
}

.rh-cover .rh-c-m .resp-menu .sub-menu > li:last-child > a {
  border-bottom: 2px solid rgba(255, 255, 255, 0.04) !important;
}

.rh-cover .rh-c-m .resp-menu .sub-menu > li.open-sub:last-child > a {
  border-bottom-color: transparent !important;
}

.rh-cover .rh-c-m .resp-menu .sub-menu .sub-menu {
  margin-top: 2px;
}

.rh-cover .rh-c-m .resp-menu .sub-menu > li > a {
  font-size: 13px;
}

.rh-cover .rh-c-m .resp-menu .mega-menu, .rh-cover .rh-c-m .resp-menu .sub-menu {
  padding-left: 22px;
}

.rh-cover .rh-c-m .resp-menu li li {
  line-height: 28px;
}

.rh-cover .rh-c-m .resp-menu .sub-menu > li:last-child > a {
  padding-bottom: 8px;
  margin-bottom: 5px;
}

.rh-cover .rh-c-m .resp-menu .better-custom-badge {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 11px;
  padding: 0 5px;
  font-weight: 600;
  letter-spacing: 1px;
  z-index: 1;
  text-transform: uppercase;
  text-align: center;
  min-width: 19px;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  float: right;
  margin: 6px 6px 0 0;
  background-color: transparent !important;
  opacity: 0.6;
}

.rh-cover .rh-c-m .resp-menu li.menu-item-has-children > a > .better-custom-badge {
  margin-right: 30px;
}

.rh-cover .rh-c-m .resp-menu li li.menu-item-has-children > a > .better-custom-badge {
  margin-top: 8px;
}

.rh-cover .rh-c-m .resp-menu + .resp-menu li {
  line-height: 40px;
}

.rh-cover .rh-c-m .resp-menu + .resp-menu li > a {
  font-size: 12px;
}

.rh-cover .search-form {
  margin-top: 35px;
  position: relative;
}

.rh-cover .search-form input[type=search] {
  margin: 0;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  float: none;
  webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.rh-cover .search-form input[type=submit] {
  content: "\f002";
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 16px;
  background: 0 0 !important;
}

.rh-cover .search-form ::-webkit-input-placeholder {
  color: #fff;
}

.rh-cover .search-form ::-moz-placeholder {
  color: #fff;
}

.rh-cover .search-form :-ms-input-placeholder {
  color: #fff;
}

.rh-cover .search-form :-moz-placeholder {
  color: #fff;
}

.rh-cover .better-social-counter {
  margin: 20px 0 0 !important;
}

.rh-cover .better-social-counter.style-button .social-list {
  text-align: center;
}

.rh-cover .better-social-counter.style-button .social-item {
  float: none;
  display: inline-block;
  margin: 0 4px 4px;
}

.rh-cover .better-social-counter.style-button .item-icon {
  width: 42px;
  height: 42px;
  line-height: 38px;
  color: #fff;
  background-color: #a3a3a3;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0) !important;
  border: 3px solid rgba(255, 255, 255, 0.25);
  font-size: 16px;
}

.rh-cover .better-social-counter.style-button .social-item:hover .item-icon {
  border-color: rgba(255, 255, 255, 0.45);
}

.rh-cover.no-login-icon .better-social-counter.style-button .social-list {
  position: absolute;
  top: 9px;
  max-width: 80%;
  height: 45px;
  overflow: hidden;
  text-align: left;
}

.rh-cover .rh-c-m .resp-menu .mega-menu {
  display: none;
  visibility: visible;
  opacity: 1;
  background-color: transparent;
  position: inherit;
  left: 0;
  top: 0;
  border: none;
  box-shadow: none;
}

.rh-cover .rh-c-m .resp-menu .mega-menu .container {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link {
  padding: 0;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link .mega-links > li {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link .mega-links li a {
  padding-bottom: 0;
  margin-bottom: 0;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link .mega-links li .sub-menu {
  display: none !important;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link .mega-links li.open-sub > .sub-menu {
  display: block !important;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link-list .mega-links li {
  padding: 0;
  text-align: left;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link-list .mega-links > li .sub-menu {
  position: relative;
  top: 0 !important;
  left: 0 !important;
  background-color: transparent !important;
  text-align: left !important;
  box-shadow: none !important;
  padding: 6px 0 0 22px;
  display: none !important;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-type-link-list .mega-links > li .sub-menu > li {
  display: block;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-grid-posts .post {
  width: 100%;
  padding: 0 20px;
  float: none;
}

.rh-cover .rh-c-m .resp-menu .mega-menu.mega-grid-posts .post:nth-child(2), .rh-cover .rh-c-m .resp-menu .mega-menu.mega-grid-posts .post:nth-child(3), .rh-cover .rh-c-m .resp-menu .mega-menu.mega-grid-posts .post:nth-child(4) {
  display: none;
}

.rh-cover .mega-menu.mega-type-link-list .mega-links > li:hover > a {
  color: #fff !important;
}

.rh-header .logo-container {
  padding: 0 0 0 15px;
  line-height: 54px;
  height: 54px;
}

.rh-header .logo-container.rh-img-logo {
  font-size: 0;
}

.rh-header .logo-container img {
  max-height: 80%;
  max-width: 80%;
}

.rh-header .logo-container a {
  color: #fff;
}

.rh-cover {
  position: fixed;
  top: 0;
  left: -99%;
  z-index: 9999;
  width: 100%;
  -webkit-transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
  -moz-transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
  transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
  visibility: hidden;
  overflow: auto;
  background: #191919;
  height: 101%;
  height: calc(100% + 1px);
}

body.open-rh .rh-cover {
  left: 0;
  visibility: visible;
}

body.admin-bar .rh-cover {
  top: 46px;
}

body.mce-content-body {
  background-color: #fff !important;
}

.noscroll {
  overflow: hidden;
}

@media (max-width: 767px) {
  body {
    background-color: #f3f3f3 !important;
  }
  .main-wrap {
    background-color: #fff;
    overflow: auto;
    margin: auto;
    width: 100%;
  }
}
.rh-cover.gr-1 {
  background-image: -moz-linear-gradient(45deg, #001fd3 0, #2d9dfb 100%);
  background-image: -webkit-linear-gradient(45deg, #001fd3 0, #2d9dfb 100%);
  background-image: -ms-linear-gradient(45deg, #001fd3 0, #2d9dfb 100%);
}

.rh-cover.gr-2 {
  background-image: -moz-linear-gradient(85deg, #b83917 0, #f7ae15 100%);
  background-image: -webkit-linear-gradient(85deg, #b83917 0, #f7ae15 100%);
  background-image: -ms-linear-gradient(85deg, #b83917 0, #f7ae15 100%);
}

.rh-cover.gr-3 {
  background-image: -moz-linear-gradient(85deg, #9234ef 0, #22c1c3 100%);
  background-image: -webkit-linear-gradient(85deg, #9234ef 0, #22c1c3 100%);
  background-image: -ms-linear-gradient(85deg, #9234ef 0, #22c1c3 100%);
}

.rh-cover.gr-4 {
  background-image: -moz-linear-gradient(85deg, #c23337 0, #d45f25 100%);
  background-image: -webkit-linear-gradient(85deg, #c23337 0, #d45f25 100%);
  background-image: -ms-linear-gradient(85deg, #c23337 0, #d45f25 100%);
}

.rh-cover.gr-5 {
  background-image: -moz-linear-gradient(85deg, #0a58c0 0, #00ade1 100%);
  background-image: -webkit-linear-gradient(85deg, #0a58c0 0, #00ade1 100%);
  background-image: -ms-linear-gradient(85deg, #0a58c0 0, #00ade1 100%);
}

.rh-cover.gr-6 {
  background-image: -moz-linear-gradient(85deg, #0b5c5e 0, #28ae92 100%);
  background-image: -webkit-linear-gradient(85deg, #0b5c5e 0, #28ae92 100%);
  background-image: -ms-linear-gradient(85deg, #0b5c5e 0, #28ae92 100%);
}

.rh-cover.gr-7 {
  background-image: -moz-linear-gradient(85deg, #880d2c 0, #1d70bf 100%);
  background-image: -webkit-linear-gradient(85deg, #880d2c 0, #1d70bf 100%);
  background-image: -ms-linear-gradient(85deg, #880d2c 0, #1d70bf 100%);
}

.rh-cover.gr-8 {
  background-image: -moz-linear-gradient(85deg, #191b1d 0, #525759 100%);
  background-image: -webkit-linear-gradient(85deg, #191b1d 0, #525759 100%);
  background-image: -ms-linear-gradient(85deg, #191b1d 0, #525759 100%);
}

.rh-cover.bg-img:before {
  opacity: 0.13;
}

.rh-cover.bg-img:after {
  content: "";
  display: inline-block;
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  bottom: 0;
  background: #000;
  opacity: 0.4;
  z-index: -2;
  -webkit-transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
  -moz-transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
  transition: 0.54s cubic-bezier(0.65, 0.33, 0.35, 0.84);
}

.close-rh .rh-cover.bg-img:after {
  left: -100%;
}

.open-rh .rh-cover.bg-img:after {
  left: 0;
}

.rh-cover:before {
  height: 60px;
  content: "";
  width: 100%;
  position: absolute;
  background-color: #fff;
  opacity: 0.08;
  box-shadow: 0 0 32px 0 #000;
  z-index: -1;
}

.rh-cover .rh-close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  display: inline-block;
  top: 21px;
  cursor: pointer;
  z-index: 10;
}

.rh-cover .rh-close:after, .rh-cover .rh-close:before {
  content: "";
  width: 2px;
  height: 18px;
  position: absolute;
  left: 50%;
  margin-left: -1px;
  top: 50%;
  margin-top: -10px;
  opacity: 0.65;
  background: #fff;
  transform: rotate(45deg);
  border-radius: 5px;
}

.rh-cover .rh-close:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.rh-cover .rh-panel {
  padding: 27px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.rh-cover .rh-panel.rh-p-u {
  display: none;
}

.rh-cover .rh-p-h {
  line-height: 60px;
  margin: -27px -27px 20px;
  padding: 0 23px;
  font-size: 14px;
  color: #fff;
  height: 60px;
  position: relative;
}

.rh-cover .rh-p-h a {
  color: #fff;
}

.rh-cover .rh-p-h .user-login {
  padding-left: 40px;
  position: relative;
  cursor: pointer;
}

.rh-cover .rh-p-h .user-login .user-avatar {
  border: 2px solid rgba(255, 255, 255, 0.16);
  border-radius: 50%;
  overflow: hidden;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 0;
  text-align: center;
}

.rh-cover .rh-p-h .user-login .user-avatar img {
  vertical-align: top;
}

.rh-cover .rh-p-h .user-login .user-avatar .fa {
  font-size: 23px;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 26px;
  width: 100%;
}

.rh-cover .rh-back-menu {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  opacity: 0.65;
  cursor: pointer;
}

.rh-cover .rh-back-menu:focus, .rh-cover .rh-back-menu:hover {
  opacity: 0.8;
}

.rh-cover .rh-back-menu:after, .rh-cover .rh-back-menu:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 2px;
  background: #fff;
  top: 10px;
  left: 6px;
  border-radius: 10px;
  -webkit-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  -moz-transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
  transition: 0.55s cubic-bezier(0.37, -0.04, 0.2, 0.99);
}

.rh-cover .rh-back-menu:before {
  transform: rotate(-40deg);
}

.rh-cover .rh-back-menu.abtn:before, .rh-cover .rh-back-menu:hover:before {
  transform: rotate(0);
  width: 17px;
  left: 7px;
  top: 7px;
}

.rh-cover .rh-back-menu:after {
  transform: rotate(40deg);
  top: 16px;
  left: 6px;
}

.rh-cover .rh-back-menu.abtn:after, .rh-cover .rh-back-menu:hover:after {
  transform: rotate(0);
  left: 7px;
  width: 17px;
  top: 19px;
}

.rh-cover .rh-back-menu i {
  width: 17px;
  height: 2px;
  display: inline-block;
  background: #fff;
  position: absolute;
  top: 13px;
  left: 7px;
  border-radius: 15px;
}

.rh-cover .bs-login-shortcode {
  background: 0 0;
  padding: 0 !important;
  margin: 25px 0 0 !important;
  border: none !important;
  box-shadow: none;
}

.rh-cover .bs-login .login-header, .rh-cover .bs-login .login-header .login-icon {
  color: #fff;
}

.rh-cover .bs-login .social-login-buttons li .social-login-btn.btn {
  background: rgba(255, 255, 255, 0.2) !important;
  height: 34px;
  line-height: 34px;
  border-radius: 3px;
  font-size: 13px;
  padding: 0;
  text-align: center;
}

.rh-cover .bs-login .social-login-btn.btn .bf-icon {
  width: auto;
  height: auto;
  position: inherit;
  background: 0 0;
  margin-right: 5px;
}

.rh-cover .bs-login .social-login-buttons {
  border-bottom: none;
  margin-bottom: 26px;
}

.rh-cover .bs-login .social-login-buttons .or-text:after, .rh-cover .bs-login .social-login-buttons .or-text:before {
  content: "";
  width: 100px;
  height: 1px;
  background: #fff;
  opacity: 0.1;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -0.5px;
  right: -101px;
}

.rh-cover .bs-login .social-login-buttons .or-text:after {
  left: -101px;
  right: inherit;
}

.rh-cover .bs-login .social-login-buttons .or-text {
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0.17);
  color: #fff;
  position: relative;
}

.rh-cover .bs-login input[type=password], .rh-cover .bs-login input[type=text] {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: #fff;
  box-shadow: none;
  -webkit-backface-visibility: hidden;
}

.rh-cover .bs-login input[type=password]::-webkit-input-placeholder, .rh-cover .bs-login input[type=text]::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.55);
}

.rh-cover .bs-login input[type=password]::-moz-placeholder, .rh-cover .bs-login input[type=text]::-moz-placeholder {
  color: rgba(255, 255, 255, 0.55);
}

.rh-cover .bs-login input[type=password]:-ms-input-placeholder, .rh-cover .bs-login input[type=text]:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.55);
}

.rh-cover .bs-login input[type=password]:-moz-placeholder, .rh-cover .bs-login input[type=text]:-moz-placeholder {
  color: rgba(255, 255, 255, 0.55);
}

.rh-cover .bs-login .remember-checkbox + .remember-label {
  color: rgba(255, 255, 255, 0.4);
}

.rh-cover .bs-login .remember-label {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAgCAYAAAAbifjMAAAAAXNSR0IArs4c6QAAAVlJREFUSA3tkzFLA0EQhXeVKzxSaSEWtmJ6wS4/w84fkdJKy/yFFFb+B8EqYC22gfRBG8FCT7Bw/d45d6zeafa8NgOPnZ197+0y7PgQwqZz7ggcgBykRAFpAe48BsckO+DWe//CujLQDCCNwJNjc2qFlcKYII20GxTz1JtjA9PkMugVawPn1j346kHx36/MDyzURE3VqIuJcTVMC01jr3HWNGZgApYgNcSVJpOBkmuwz5OSQlzTTGQgt2RxdYOZLGUQqmLXVdpOXxn+EFyCvfoyNkkvgCbxo/jEHKj5zRfooHa3hNqQdAZ2rfTK+lHmcrGi+nEC3sA4qsU3cxTuwbbOtambSL4F3lW0GLMegoeqwFqLGwZWOI/ISp+j/Tdxq4EVLyJRlTbEvxq0mLSK/zSwwzOuvwFlw1T7GXqemtjrK+snXoEpRsnzYNxpqWWjH6WJ1EtSQ1xpsk/Dn+01H7ZKAQAAAABJRU5ErkJggg==);
}

.rh-cover .bs-login .go-login-panel, .rh-cover .bs-login .go-reset-panel {
  color: rgba(255, 255, 255, 0.5);
}

.rh-cover .bs-login .go-login-panel:hover, .rh-cover .bs-login .go-reset-panel:hover {
  color: #fff;
}

.rh-cover .bs-login .bs-login-reset-panel .login-header .login-icon, .rh-cover .bs-login .remember-checkbox:checked + .remember-label {
  color: #fff;
}

.rh-cover .bs-login .bs-login-reset-panel .login-btn, .rh-cover .bs-login .login-btn {
  background: #fff !important;
  color: #000;
  -webkit-backface-visibility: hidden;
}

.rh-cover .bs-login .login-signup {
  color: rgba(255, 255, 255, 0.66);
}

.rh-cover .bs-login .login-signup a {
  color: #fff;
  text-decoration: underline;
}

.rh-cover .bs-login .user-links, .rh-cover .bs-login .user-links li {
  border-color: rgba(255, 255, 255, 0.4);
}

.rh-cover .bs-login .user-links a {
  color: #fff;
}

.rh-header.light .rh-container {
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.rh-header.light .rh-container.bs-pinning-block.pinned {
  border-bottom-color: transparent;
  -moz-box-shadow: 0 0 40px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.17);
}

.rh-header.light .logo-container a {
  color: #2c2c2c;
}

.rh-header.light .menu-container .menu-handler .lines, .rh-header.light .menu-container .menu-handler .lines:after, .rh-header.light .menu-container .menu-handler .lines:before {
  background: #2c2c2c;
}

body.bs-show-ha.bs-show-ha-b .site-header + .rh-header.light .rh-container {
  border-top: 1px solid #e5e5e5;
}

.main-menu .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  padding: 0;
  border: none;
  right: auto;
  left: auto;
}

.main-menu .bs-pretty-tabs-container {
  position: inherit;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links .sub-menu {
  position: absolute;
  box-shadow: none;
  padding: 0;
  width: 100%;
  left: 100%;
  top: 0 !important;
  background-color: #fff;
  -webkit-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.15);
}

ul.sub-menu.bs-pretty-tabs-elements li.menu-item-mega-grid-posts > .mega-menu, ul.sub-menu.bs-pretty-tabs-elements li.menu-item-mega-grid-posts > a:after {
  display: none;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links .sub-menu .sub-menu.sub-menu, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links .sub-menu.sub-menu, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link-list .mega-links > li .sub-menu {
  top: inherit !important;
  position: absolute;
  margin-top: -39px;
  left: 100% !important;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.tabbed-grid-posts:before {
  display: none;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.tabbed-grid-posts .tab-content, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.tabbed-grid-posts .tabs-section li:first-child {
  display: none;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.tabbed-grid-posts .tabs-section {
  width: 100%;
  max-width: inherit;
  padding: 0;
  height: auto;
  float: none;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .content-wrap, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.tabbed-grid-posts .content-wrap {
  max-width: 100%;
  min-width: inherit;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links > li > a {
  color: #434343;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  width: auto;
  display: block;
  margin-bottom: 0;
  padding: 8px 16px;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link-list .mega-links > li, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link-list .mega-links > li .sub-menu > li {
  display: block;
  padding: 0;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link-list .mega-links > li > a {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links > li:last-child > a, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link-list .mega-links > li:last-child > a {
  border-bottom: none;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links.link-2-column > li, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links.link-3-column > li, ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links.link-4-column > li {
  width: 100%;
  float: none;
  padding: 0 !important;
  margin: 0 !important;
}

ul.sub-menu.bs-pretty-tabs-elements .mega-menu.mega-type-link .mega-links > li:hover > a {
  color: #fff !important;
}

.off-canvas-overlay {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
  overflow-y: hidden;
}

.off-canvas-inner > div {
  padding: 10px 20px 14px;
  line-height: 1.6;
  color: #434343;
}

.off-canvas-container {
  visibility: hidden;
  left: -300px;
  top: 0;
  position: fixed;
  width: 300px;
  height: 100%;
  z-index: 999999;
  -webkit-transition: 0.6s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -moz-transition: 0.6s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -o-transition: 0.6s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  transition: 0.6s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
}

body.off-canvas-scroll {
  overflow-y: hidden;
}

body.off-canvas-scroll .off-canvas-container {
  overflow-y: scroll;
}

.off-canvas-container.closing {
  overflow-y: hidden !important;
}

.off-canvas-menu-open .off-canvas-container {
  width: 100%;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QA/wD/AP+gvaeTAAAA/UlEQVQ4jZXUvy4EQRwH8N9xvMI1aCWUCo/gX0h0nkWj1SiuQ4EQyYmCh/AIrnOeQI8EH8WtZEz2duem2uz+5jPf/c3sRkQE1rGNTkwxsIp9zAfW8GM8rjFbiOzgo5rXD2z6P64w04JsJQgMAh2cZtjFJAwbeE9qR1hIC44z7A7dDNnDZ1IzRK9utRwb/PUMu0VIA3ZZnCSD6nr2nVy/YLERaUnWmKRpm58iQnbvOSLepklzgK+aRHCPuRIkPbHwipsMa/4CapIM0as2oF+UbBKSPG/H2pAiDMtZT0ZYanj9Ds4z7CiM/ydFSIadJfMeA12c4NYUJ7bCDvGAlV8ryZTBSS7cPQAAAABJRU5ErkJggg==), no-drop;
}

.off-canvas-menu-open .off-canvas-container.left {
  left: 0;
  display: block;
  visibility: visible;
}

.off-canvas-container.right {
  left: inherit;
  right: -300px;
}

.off-canvas-menu-open .off-canvas-container.right {
  right: 0;
  display: block;
  visibility: visible;
}

.off-canvas-container:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 290px;
  background: #fff;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: default;
}

.off-canvas-inner {
  color: #434343;
  width: 290px;
  position: absolute;
  cursor: default;
  padding-top: 4px;
  background: #fff;
}

.off-canvas-inner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: 0 0;
  z-index: 1;
}

body.admin-bar .off-canvas-container {
  top: 32px;
}

.off-canvas-header {
  text-align: center;
  line-height: 1.5;
  padding-bottom: 10px;
}

.off-canvas-header img {
  max-width: 100%;
  height: auto;
}

.off-canvas-header .logo {
  margin-top: 20px;
}

.off-canvas-header .site-name {
  font-weight: bolder;
  font-size: 18px;
  margin-top: 15px;
}

.off-canvas-header .site-name:first-child {
  margin-bottom: 10px;
  font-size: 22px;
}

.off-canvas-header .site-name:first-child:last-child {
  margin-bottom: 0;
}

.off-canvas-header .logo + .site-description {
  margin-top: 15px;
}

.off-canvas-header .site-description:first-child:last-child {
  margin-bottom: 10px;
  margin-top: 15px;
}

.off-canvas-menu > ul > li > a {
  text-transform: none !important;
}

.off-canvas-inner > .off-canvas-menu {
  padding: 20px 0 10px;
}

.off-canvas-inner > .off-canvas-search + .off-canvas-menu {
  padding-top: 0;
}

.off-canvas-inner > .off-canvas-header + .off-canvas-menu {
  padding-top: 8px;
}

.off-canvas-inner .canvas-close + .off-canvas-search {
  margin-top: 44px;
}

.off-canvas-menu ul.menu li, .off-canvas-menu ul.menu > li {
  display: block;
  float: none;
}

.off-canvas-menu ul.menu > li {
  padding: 0 20px;
}

.off-canvas-menu li > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 10px 0 !important;
}

.off-canvas-menu li:last-child > a {
  border-bottom: none !important;
}

.off-canvas-menu li li {
  padding: 0 18px !important;
}

.off-canvas-menu li li > a {
  padding: 8px 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07) !important;
}

.off-canvas-menu a {
  color: #444;
}

.off-canvas-menu ul.menu > li > a {
  display: block !important;
}

.off-canvas-menu ul.menu > li .sub-menu {
  top: -5px;
  left: 100%;
  background: #fff;
  border: 1px solid #d7d7d7;
  padding: 7px 0;
  border-radius: 3px;
}

.off-canvas-menu ul.menu > li .sub-menu .sub-menu {
  left: 100%;
  top: -7px !important;
}

.off-canvas-menu ul.menu > li .sub-menu:after, .off-canvas-menu ul.menu > li .sub-menu:before {
  border: solid 13px transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 13px;
  left: -27px;
  border-right-color: #d7d7d7;
}

.off-canvas-menu ul.menu > li .sub-menu:after {
  border: solid 12px transparent;
  border-right-color: #fff;
  top: 14px;
  left: -24px;
}

.off-canvas-menu .fa {
  margin-right: 5px;
}

.off-canvas-menu ul {
  padding: 0;
  margin-bottom: 0;
  position: relative;
}

.off-canvas-menu li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

.off-canvas-menu ul ul {
  display: none;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  position: absolute;
  left: 100%;
  width: 100%;
  top: 0;
  border: 1px solid #f5eaea;
  padding: 0 10px;
}

.off-canvas-menu ul.menu li.menu-item-has-children > a:after {
  content: "\f105";
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -13px;
}

.off-canvas-menu ul.menu li.menu-item-has-children.bsm-enter > a:after {
  opacity: 0;
  right: -8px;
}

.off-canvas-menu .menu .better-custom-badge {
  bottom: inherit;
  top: 50%;
  right: 0;
  left: inherit;
  display: inline-block;
  margin-top: -8px;
}

.off-canvas-menu .menu .menu-item-has-children > a > .better-custom-badge {
  right: 20px;
}

.off-canvas-menu .menu .better-custom-badge:after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  bottom: 4px;
  left: -8px;
}

.off-canvas-inner > .off-canvas-search:first-child {
  padding-top: 25px;
}

.off-canvas-search form {
  position: relative;
}

.off-canvas-search input[type=text] {
  padding-right: 25px;
  padding-left: 10px;
  box-shadow: none;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  font-size: 12px;
}

.off-canvas-search .fa {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: #8f8f8f;
}

.off-canvas-inner > .off_canvas_footer {
  padding-top: 18px;
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.off-canvas-inner > .off_canvas_footer:first-child {
  padding-top: 20px;
  margin-top: 0;
  border-top: none;
}

.off-canvas-inner > .off-canvas-search + .off_canvas_footer {
  margin-top: 14px;
}

.off-canvas-inner > .off_canvas_footer .better-social-counter.style-button:first-child .social-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.off-canvas-inner > .off_canvas_footer .better-social-counter.style-button:first-child .social-item {
  float: none;
  margin: 0 3px;
  display: inline;
}

.off_canvas_footer .better-social-counter.style-button .item-icon {
  border-radius: 50%;
}

.off-canvas-container .social-share-list {
  padding: 15px 0 0;
}

.off-canvas-container .social-share-list li {
  list-style: none;
  display: inline-block;
  margin-right: 3px;
}

.off-canvas-container .social-share-list a {
  background: #424242;
  color: #fff;
  padding: 4px 7px;
  border-radius: 3px;
}

.off-canvas-container.right {
  right: -300px;
  left: auto;
}

.off-canvas-container.right .off-canvas-inner, .off-canvas-container.right:before {
  right: 0;
  left: auto;
}

.off-canvas-container.right .off-canvas-menu ul ul {
  left: auto;
  right: 100%;
  box-shadow: -3px 0 10px 0 rgba(0, 0, 0, 0.1);
}

.off-canvas-container.right .off-canvas-menu ul {
  text-align: right;
}

.off-canvas-container.right ul.menu li.menu-item-has-children > a:after {
  content: "\f104";
  right: inherit;
  left: 0;
}

.off-canvas-container.right ul.menu li.menu-item-has-children.bsm-enter > a:after {
  right: auto;
  left: -8px;
}

.off-canvas-container.right .off-canvas-menu ul.menu li.menu-item-has-children.bsm-enter > a:after {
  right: auto;
  left: -8px;
}

.off-canvas-container.right .off-canvas-menu .menu .better-custom-badge {
  right: inherit;
  left: 0;
}

.off-canvas-container.right .off-canvas-menu .menu .menu-item-has-children > a > .better-custom-badge {
  right: inherit;
  left: 20px;
}

.off-canvas-container.right .off-canvas-menu .menu .better-custom-badge:after {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  left: auto;
  right: -8px !important;
}

.off-canvas-container.right .off_canvas_footer {
  text-align: right;
}

.off-canvas-container.right .better-social-counter.style-button .social-item {
  float: right;
  margin: 0 0 6px 4px;
}

.off-canvas-container.right ul.menu li.menu-have-icon > a > .bf-icon {
  float: right;
  margin: 4px 0 0 6px;
}

.off-canvas-container.right .off-canvas-menu ul.menu > li .sub-menu:after, .off-canvas-container.right .off-canvas-menu ul.menu > li .sub-menu:before {
  left: inherit;
  right: -27px;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: FlipH;
}

.off-canvas-container.right .off-canvas-menu ul.menu > li .sub-menu:after {
  left: inherit;
  right: -24px;
}

.off-canvas-container.right ul.menu > li .sub-menu .sub-menu {
  left: inherit;
  right: 100%;
}

.skin-dark.off-canvas-container .off-canvas-inner, .skin-dark.off-canvas-container:before {
  background: #151515;
}

.skin-dark.off-canvas-container .off-canvas-search input[type=text] {
  border-color: #272727;
  background-color: #151515;
  color: #fff;
}

.skin-dark.off-canvas-container .off-canvas-inner > div, .skin-dark.off-canvas-container .off-canvas-menu a {
  color: #fff;
}

.skin-dark.off-canvas-container .off-canvas-menu ul.menu > li .sub-menu {
  background: #151515;
  border-color: #232323;
}

.skin-dark.off-canvas-container .off-canvas-menu ul.menu > li .sub-menu:after, .skin-dark.off-canvas-container .off-canvas-menu ul.menu > li .sub-menu:before {
  border-right-color: #232323;
}

.skin-dark.off-canvas-container .off-canvas-menu li > a {
  border-bottom-color: rgba(255, 255, 255, 0.05) !important;
}

.skin-dark.off-canvas-container .off-canvas-inner > .off_canvas_footer {
  border-top-color: rgba(255, 255, 255, 0.07);
}

.search-header {
  margin: 0 0 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
}

.search-header .search-form {
  margin-bottom: 15px;
}

.search-header .found-categories ul, .search-header .found-tags ul {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-header .found-categories li, .search-header .found-tags li {
  display: inline-block;
  margin: 0 10px 0 0;
}

.search-header .label {
  font-weight: 700;
  font-size: 14px;
  color: #383838;
  padding: 0 10px 0 0;
}

.search-header .clean-button {
  line-height: 18px;
}

.search-header .found-categories li .clean-button, .search-header .found-tags li .clean-button {
  margin: 0;
}

.search-header .found-categories + .found-tags {
  margin-top: 15px;
}

.search-header .found-tags .clean-button {
  line-height: 22px;
  padding: 0 6px;
}

.topbar {
  height: 35px;
  overflow: hidden;
}

.topbar.use-pretty-tabs {
  z-index: 9999;
  position: relative;
  height: auto;
  overflow: visible;
}

.topbar ul.menu > li .sub-menu {
  top: 100%;
}

.topbar .topbar-inner {
  padding: 4px 0;
}

.topbar .section-links {
  float: right;
}

.topbar.topbar-style-1 .section-menu {
  height: 26px;
}

.topbar.topbar-style-1 .section-menu {
  max-width: 80%;
}

.topbar.topbar-style-1 .section-menu:first-child:last-child {
  max-width: 100%;
}

.site-header.boxed .topbar .topbar-inner {
  border-bottom: 1px solid #efefef;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.site-header.full-width .topbar {
  border-bottom: 1px solid #efefef;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.topbar .better-studio-shortcode {
  display: inline-block;
  float: right;
  margin-bottom: 0;
}

.topbar .better-studio-shortcode .social-list {
  display: inline-block;
  float: right;
}

.topbar .better-social-counter.style-button .social-item {
  margin: 0 0 0 4px;
}

.topbar .better-social-counter.style-button .social-item .item-icon {
  color: #444;
  background-color: transparent;
  font-size: 12px;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.topbar .better-social-counter.style-button .social-item:hover .item-icon {
  color: #545454;
}

.topbar .better-social-counter.style-button .social-item {
  float: left;
  font-size: 13px;
}

.topbar .better-social-counter.style-button a:hover {
  color: #fff;
}

.topbar .topbar-date {
  color: #fff;
  display: inline-block;
  padding: 3px 10px;
  line-height: 18px;
  position: relative;
  top: -1px;
}

.topbar .better-newsticker {
  margin-bottom: 0;
}

.topbar .better-newsticker.better-newsticker {
  margin-bottom: 0 !important;
}

.topbar .section-links + .section-menu .better-newsticker .control-nav {
  right: 30px;
}

.topbar .topbar-sign-in {
  float: right;
  cursor: pointer;
  height: 24px;
  line-height: 24px;
}

.topbar .topbar-sign-in .fa {
  margin-right: 2px;
}

.topbar .topbar-sign-in.behind-social {
  margin-right: 8px;
  position: relative;
  padding-right: 8px;
}

.topbar .topbar-sign-in.behind-social:before {
  content: "";
  width: 1px;
  top: 50%;
  margin-top: -8px;
  height: 18px;
  display: inline-block;
  background: #dadada;
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  right: -2px;
}

@media only screen and (max-width: 850px) {
  .topbar .better-newsticker ul.news-list li {
    width: 65%;
  }
}
body {
  background: #fff;
  text-decoration-skip: ink;
}

body.boxed {
  background-color: #eee;
}

body.boxed .bs-vc-content > .vc_row > .bs-vc-wrapper, body.boxed .content-wrap {
  padding-left: 5px;
  padding-right: 5px;
}

body.boxed .bf-breadcrumb > .bf-breadcrumb-container {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

body.boxed .mega-menu .content-wrap {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

body.boxed .slider-container .content-wrap {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 980px) {
  body.boxed .bs-vc-content > .vc_row > .bs-vc-wrapper, body.boxed .content-wrap, body.boxed .mega-menu .content-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}
.main-wrap {
  margin: 0 auto;
}

.content-wrap, .main-wrap, main.content-container {
  position: relative;
}

body.active-top-line {
  border-top: 3px solid transparent;
}

.main-wrap .container {
  width: 100%;
}

body.full-width .main-wrap {
  width: 100%;
}

@media (min-width: 768px) {
  .boxed .main-wrap {
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  .boxed .main-wrap {
    max-width: 100% !important;
  }
}
body.boxed .main-wrap {
  padding: 0;
  background-color: #fff;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.27);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.27);
}

@media only screen and (max-width: 480px) {
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .vc_row .bs-vc-wrapper > .bs-vc-column {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.content-wrap {
  margin: 0 auto;
  padding: 0;
  min-width: 300px;
}

.container {
  width: 100%;
}

.page-layout-3-col-0 .content-column {
  width: 100% !important;
}

.page-layout-3-col-0.page-layout-3-col-0 .main-section {
  margin-bottom: 0 !important;
}

.listing .col-lg-1, .listing .col-lg-10, .listing .col-lg-11, .listing .col-lg-12, .listing .col-lg-2, .listing .col-lg-3, .listing .col-lg-4, .listing .col-lg-5, .listing .col-lg-6, .listing .col-lg-7, .listing .col-lg-8, .listing .col-lg-9, .listing .col-md-1, .listing .col-md-10, .listing .col-md-11, .listing .col-md-12, .listing .col-md-2, .listing .col-md-3, .listing .col-md-4, .listing .col-md-5, .listing .col-md-6, .listing .col-md-7, .listing .col-md-8, .listing .col-md-9, .listing .col-sm-1, .listing .col-sm-10, .listing .col-sm-11, .listing .col-sm-12, .listing .col-sm-2, .listing .col-sm-3, .listing .col-sm-4, .listing .col-sm-5, .listing .col-sm-6, .listing .col-sm-7, .listing .col-sm-8, .listing .col-sm-9, .listing .col-xs-1, .listing .col-xs-10, .listing .col-xs-11, .listing .col-xs-12, .listing .col-xs-2, .listing .col-xs-3, .listing .col-xs-4, .listing .col-xs-5, .listing .col-xs-6, .listing .col-xs-7, .listing .col-xs-8, .listing .col-xs-9 {
  padding-left: 15px;
  padding-right: 15px;
}

.listing .row {
  margin-left: -15px;
  margin-right: -15px;
}

.term-badges .format-badge, .term-badges .term-badge {
  display: inline-block;
  line-height: 0;
}

.term-badges a {
  color: #8b8b8b;
  padding: 0 0 4px;
}

.term-badges .fa {
  margin-right: 2px;
}

.term-badges.floated a {
  padding: 2px 7px;
  color: #fff;
  margin-right: 3px;
  margin-bottom: 3px;
  display: inline-block;
  line-height: 1.7;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.term-badges.floated {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  line-height: 0;
  font-size: 0;
}

.term-badges.text-badges, .term-badges.text-badges .term-badge {
  line-height: 1.4;
}

.term-badges.text-badges .term-badge {
  margin-right: 10px;
}

.term-badges.text-badges .term-badge:last-child {
  margin-right: 0;
}

.term-badges.text-badges a {
  background-color: transparent !important;
  font-weight: 500;
  padding: 0;
}

.format-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  pointer-events: none;
  width: 32px;
  height: 32px;
  border: 2px solid #fff;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  -webkit-transition: 0.43s cubic-bezier(0.47, 0.13, 0.16, 0.72) 0s;
  -moz-transition: 0.43s cubic-bezier(0.47, 0.13, 0.16, 0.72) 0s;
  -o-transition: 0.43s cubic-bezier(0.47, 0.13, 0.16, 0.72) 0s;
  transition: 0.43s cubic-bezier(0.47, 0.13, 0.16, 0.72) 0s;
}

.format-icon .fa {
  line-height: 28px;
  width: 100%;
  text-align: center;
}

.format-icon.format-audio {
  padding-right: 2px;
}

.format-icon.format-video {
  padding-left: 2px;
}

.post-summary p:last-child {
  margin-bottom: 0;
}

.entry-content a.read-more, a.read-more {
  font-size: 12px;
  color: #fff;
  background: #434343;
  display: inline-block;
  padding: 3px 11px;
  text-transform: uppercase !important;
  margin-top: 13px;
}

.entry-content a.read-more, a.read-more:hover {
  color: #fff;
}

.img-holder {
  position: relative;
  width: 80px;
  display: inline-block;
  float: left;
  margin-right: 15px;
  transition: none;
}

.bs-ll-a .img-cont:before, .bs-ll-a .img-holder:before, .bs-ll-a img[data-src]:not(.b-loaded) {
  opacity: 1;
  background-color: #e9ebee;
  background-image: linear-gradient(to left, #e9ebee 0, #f4f5f6 20%, #e9ebee 40%, #e9ebee 100%);
  background-repeat: no-repeat;
  background-size: 1000px 900px;
  -webkit-animation: bs-lazy-anim 1s infinite linear forwards;
  -moz-animation: bs-lazy-anim 1s infinite linear forwards;
  animation: bs-lazy-anim 1.01s infinite linear forwards;
}

.bs-ll-d .img-cont, .bs-ll-d .img-holder {
  background-color: #f5f5f5;
}

.listing-mg-item .img-cont:before {
  content: "";
  display: inline-block;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.img-cont.b-error:before, .img-cont.b-loaded:before, .img-holder.b-error:before, .img-holder.b-loaded:before {
  background: 0 0;
  opacity: 0;
  animation: none;
}

.listing-item-blog-1 .featured .img-holder.b-error, .listing-item-blog-1 .featured .img-holder.b-loaded, .listing-item-blog-5 .featured .img-holder.b-error, .listing-item-blog-5 .featured .img-holder.b-loaded, .listing-item-tb-1 .img-holder.b-error, .listing-item-tb-1 .img-holder.b-loaded, .listing-item-tb-2 .img-holder.b-error, .listing-item-tb-2 .img-holder.b-loaded, .listing-item-tb-3 .img-holder.b-error, .listing-item-tb-3 .img-holder.b-loaded {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-position: top center !important;
}

@-webkit-keyframes bs-lazy-anim {
  from {
    background-position: -800px 0;
  }
  to {
    background-position: 400px 0;
  }
}
@keyframes bs-lazy-anim {
  from {
    background-position: -800px 0;
  }
  to {
    background-position: 400px 0;
  }
}
@-webkit-keyframes bs-lazy-anim-rtl {
  from {
    background-position: 400px 0;
  }
  to {
    background-position: -800px 0;
  }
}
@keyframes bs-lazy-anim-rtl {
  from {
    background-position: 400px 0;
  }
  to {
    background-position: -800px 0;
  }
}
.img-cont.img-cont.img-content-ni.img-content-ni, .img-holder.img-holder.img-content-ni.img-content-ni {
  background: 0 0 !important;
}

.img-holder.b-load-ready {
  opacity: 0;
}

.img-holder.b-error, .img-holder.b-loaded, body.bs-ll-d .img-holder {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  animation: none;
  background-position: top center;
}

.img-holder.b-loaded, body.bs-ll-d .img-holder {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}

.bs-pagination-cached .img-holder.b-loaded {
  transition: none;
}

.img-holder:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 70%;
}

.search-field::-ms-clear {
  display: none;
}

.modal {
  z-index: 99999;
}

.margin-sm-left {
  margin-left: 7px;
}

.margin-sm-right {
  margin-right: 7px;
}

.margin-xsm-left {
  margin-left: 3px;
}

.margin-xsm-right {
  margin-right: 3px;
}

.limit-line {
  overflow: hidden;
  white-space: nowrap;
}

.limit-dot-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.top-line {
  border-top: 1px solid #f4f4f4;
}

.bottom-line {
  border-bottom: 1px solid #f4f4f4;
}

li.bottom-line:last-child {
  border-bottom: none;
}

.no-list-style {
  list-style: none !important;
}

.no-list-style li {
  padding-right: 0 !important;
}

.no-list-style li:before {
  display: none !important;
}

.bs-padding-1-1 {
  padding-left: 5%;
  padding-right: 5%;
}

.bs-padding-0-1 {
  padding-right: 5%;
}

.bs-padding-1-0 {
  padding-left: 5%;
}

.bs-padding-2-2 {
  padding-left: 10%;
  padding-right: 10%;
}

.bs-padding-2-1 {
  padding-left: 10%;
  padding-right: 5%;
}

.bs-padding-1-2 {
  padding-left: 5%;
  padding-right: 10%;
}

.bs-padding-0-2 {
  padding-right: 10%;
}

.bs-padding-2-0 {
  padding-left: 10%;
}

.bs-padding-3-3 {
  padding-left: 15%;
  padding-right: 15%;
}

.bs-padding-0-3 {
  padding-right: 15%;
}

.bs-padding-3-0 {
  padding-left: 15%;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:active, .screen-reader-text:focus, .screen-reader-text:hover {
  background-color: #f1f1f1;
  -webmit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.search-form input[type=search] {
  float: left;
  height: 38px;
  line-height: 38px;
  margin-right: 8px;
  font-size: 12px;
  box-shadow: none;
}

.search-form input[type=submit] {
  height: 38px;
}

.post-meta {
  padding-top: 2px;
  font-style: inherit;
}

.post-meta .time {
  margin-right: 10px;
}

.post-meta .share, .post-meta .views {
  float: none;
  margin-right: 10px;
  font-style: normal;
}

.post-meta .comments, .post-meta .share, .post-meta .views {
  white-space: nowrap;
}

.post-meta .views {
  font-weight: 700;
}

.post-meta .views.rank-0, .post-meta .views.rank-default {
  font-weight: 400;
}

.post-meta .comments {
  margin-left: 10px;
  font-style: normal;
  float: right;
}

.post-meta .comments:first-child:last-child, .post-meta .rating:first-child:last-child {
  float: none;
  margin-left: 0;
  margin-right: 0;
}

.post-meta .rating + .comments {
  float: none;
  margin-left: 0;
}

.post-meta .comments .fa, .post-meta .share .bf-icon, .post-meta .views .bf-icon, .post-meta .views .fa {
  display: inline-block;
  margin-right: 3px;
}

.post-meta .post-author-avatar img {
  margin-right: 5px;
  margin-top: -2px;
}

.post-meta .post-author {
  font-weight: bolder;
  font-style: normal;
  margin-right: 8px;
  max-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
}

.post-meta .post-author:after {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f111";
  margin-left: 8px;
  font-size: 5px;
  vertical-align: middle;
  color: #adb5bd;
}

.post-meta .post-author-a:last-child .post-author {
  margin-right: 0;
}

.post-meta .post-author-a:last-child .post-author:after {
  display: none;
}

.post-meta > a:last-child, .post-meta > span:last-child {
  margin-right: 0 !important;
}

.slider-style-1-container, .slider-style-11-container, .slider-style-13-container, .slider-style-15-container, .slider-style-2-container.slider-container-1col, .slider-style-3-container, .slider-style-4-container.slider-container-1col, .slider-style-5-container, .slider-style-7-container, .slider-style-9-container {
  margin: 0 0 -20px;
  padding: 25px 0;
}

.slider-style-5-container {
  padding-bottom: 0;
}

.slider-style-16-container, .slider-style-2-container, .slider-style-4-container, .slider-style-8-container {
  margin: 0 0 25px;
}

.slider-style-15-container {
  margin: 0;
  padding-bottom: 0;
}

.slider-style-11-container .bs-slider, .slider-style-13-container .bs-slider, .slider-style-9-container .bs-slider {
  margin-bottom: 0;
}

.archive-title {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 13px;
}

.archive-title .pre-title {
  position: relative;
  margin-bottom: 17px;
}

.archive-title .pre-title span {
  background-color: #444;
  display: inline-block;
  line-height: 26px;
  z-index: 1;
  position: relative;
  padding: 0 10px;
  color: #fff;
}

.archive-title .pre-title:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 100%;
  height: 3px;
  width: 100%;
  background-color: #444;
}

.archive-title .page-heading {
  margin: 0 0 12px;
  position: relative;
  -webkit-pointer-events: none;
  -moz-pointer-events: none;
  pointer-events: none;
  padding: 0;
}

.archive-title.with-actions .page-heading {
  padding-right: 30px;
}

.archive-title .actions-container {
  width: 30px;
  float: right;
}

.archive-title.without-terms .actions-container {
  margin-top: 8px;
}

.archive-title .page-heading .count, .archive-title .rss-link {
  float: right;
  font-size: 20px;
}

.archive-title .rss-link {
  color: #f97410;
}

.archive-title .desc {
  margin-top: 12px;
}

.archive-title .desc p:last-child {
  margin-bottom: 0;
}

.archive-title .term-badges {
  margin: 10px 0 0;
  line-height: 0;
}

.archive-title .term-badges span.term-badge {
  margin-bottom: 6px;
}

.archive-title .term-badges span.term-badge a {
  background: #fff !important;
  border: 1px solid #d4d4d4 !important;
  border-color: #d4d4d4 !important;
  padding: 0 7px;
  display: inline-block;
  line-height: 20px;
  margin-right: 3px;
  font-size: 10px;
  float: left;
  color: #8b8b8b !important;
}

.archive-title .term-badges span.term-badge a:hover {
  color: #fff !important;
  border: 1px solid transparent !important;
}

.wpb_text_column img {
  max-width: 100%;
  height: auto;
}

.wpb_widgetised_column .wpb_wrapper > ul {
  margin: 0;
  padding: 0;
}

.bs-injection.bs-injection-1-col > .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .bs-injection.bs-injection-2-col > .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .bs-injection.bs-injection-3-col > .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .bs-injection.bs-vc-content, .bs-injection.bs-vc-content .vc_row, .bs-injection.bs-vc-content .vc_vc_row, .layout-1-col .bs-vc-content .vc_row, .layout-1-col .bs-vc-content .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .layout-1-col .bs-vc-content .vc_vc_row, .layout-3-col-0 .bs-vc-content .vc_row, .layout-3-col-0 .bs-vc-content .vc_row[data-vc-full-width=true] > .bs-vc-wrapper, .layout-3-col-0 .bs-vc-content .vc_vc_row {
  margin-left: auto;
  margin-right: auto;
}

.container, .vc_column_container {
  margin-right: auto !important;
  margin-left: auto !important;
}

.bs-vc-content > .vc_element > .vc_row[data-vc-full-width=true], .bs-vc-content > .vc_element > .vc_row[data-vc-stretch-content=true] > .bs-vc-wrapper, .bs-vc-content > .vc_row[data-vc-full-width=true], .bs-vc-content > .vc_row[data-vc-stretch-content=true] > .bs-vc-wrapper {
  width: 100%;
}

.bs-vc-content .vc_row[data-vc-full-width=true] {
  right: auto !important;
  left: auto !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.full-width .bs-vc-content .vc_row[data-vc-full-width=true] {
  max-width: inherit !important;
}

.bs-vc-content .vc_row[data-vc-stretch-content=true] {
  right: auto !important;
  left: auto !important;
  width: auto !important;
  max-width: inherit !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bs-injection.bs-vc-content .vc_row[data-vc-stretch-content=true] > .bs-vc-wrapper, .layout-3-col-0 .bs-vc-content .vc_row[data-vc-stretch-content=true] > .bs-vc-wrapper, .page-layout-1-col .bs-vc-content .vc_row[data-vc-stretch-content=true] > .bs-vc-wrapper {
  max-width: inherit !important;
}

.wpb_flexslider.flexslider .slides img {
  height: auto;
}

.bs-injection.bs-vc-content > .vc_row.vc_row-fluid.vc_row-has-fill:first-child {
  margin-top: 0 !important;
}

.vc_row.vc_row-flex > .bs-vc-wrapper {
  box-sizing: border-box;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.vc_row.vc_row-flex .bs-vc-wrapper {
  width: 100%;
}

.vc_row.vc_row-flex > .bs-vc-wrapper > .vc_column_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
}

.vc_row.vc_row-flex > .bs-vc-wrapper, .vc_row.vc_row-flex > .bs-vc-wrapper > .vc_column_container {
  display: flex;
}

.vc_row.vc_row-flex > .bs-vc-wrapper > .vc_column_container > .vc_column-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.vc_row.vc_row-flex > .bs-vc-wrapper > .vc_column_container > .vc_column-inner {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 1;
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .vc_row.vc_row-flex > .bs-vc-wrapper > .vc_column_container > .vc_column-inner > * {
    min-height: 1em;
  }
}
.vc_row.vc_column-gap-1 > .bs-vc-wrapper {
  margin-left: -0.5px !important;
  margin-right: -0.5px !important;
}

.vc_row.vc_column-gap-1 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 0.5px !important;
  padding-right: 0.5px !important;
}

.vc_row.vc_column-gap-2 > .bs-vc-wrapper {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.vc_row.vc_column-gap-2 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.vc_row.vc_column-gap-3 > .bs-vc-wrapper {
  margin-left: -1.5px !important;
  margin-right: -1.5px !important;
}

.vc_row.vc_column-gap-3 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 1.5px !important;
  padding-right: 1.5px !important;
}

.vc_row.vc_column-gap-4 > .bs-vc-wrapper {
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.vc_row.vc_column-gap-4 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.vc_row.vc_column-gap-5 > .bs-vc-wrapper {
  margin-left: -2.5px !important;
  margin-right: -2.5px !important;
}

.vc_row.vc_column-gap-5 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 2.5px !important;
  padding-right: 2.5px !important;
}

.vc_row.vc_column-gap-10 > .bs-vc-wrapper {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.vc_row.vc_column-gap-10 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.vc_row.vc_column-gap-15 > .bs-vc-wrapper {
  margin-left: -7.5px !important;
  margin-right: -7.5px !important;
}

.vc_row.vc_column-gap-15 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
}

.vc_row.vc_column-gap-20 > .bs-vc-wrapper {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.vc_row.vc_column-gap-20 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.vc_row.vc_column-gap-25 > .bs-vc-wrapper {
  margin-left: -12.5px !important;
  margin-right: -12.5px !important;
}

.vc_row.vc_column-gap-25 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 12.5px !important;
  padding-right: 12.5px !important;
}

.vc_row.vc_column-gap-30 > .bs-vc-wrapper {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.vc_row.vc_column-gap-30 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.vc_row.vc_column-gap-35 > .bs-vc-wrapper {
  margin-left: -17.5px !important;
  margin-right: -17.5px !important;
}

.vc_row.vc_column-gap-35 > .bs-vc-wrapper > .bs-vc-column {
  padding-left: 17.5px !important;
  padding-right: 17.5px !important;
}

.listing-item a.vc_inline-link {
  display: none;
}

.bs-pagination-wrapper .listing + .listing {
  margin-top: 20px;
}

.post.emphasize-first-p .entry-content > p:first-child {
  font-size: 120%;
}

.listing-item .post-edit-link {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.58);
  color: #fff;
  padding: 4px 11px;
  font-size: 13px;
  display: none;
  line-height: 20px;
}

.listing-item:hover .post-edit-link {
  display: block;
}

.infinity > .listing + .listing, .more_btn > .listing + .listing {
  margin-top: 20px;
}

.mega-links > .menu-item {
  margin-bottom: 30px;
}

.listing .listing-item, .mega-links > .menu-item {
  min-height: 0;
}

.listing.columns-1 .listing-item, .mega-links.columns-1 > .menu-item {
  width: 100%;
  float: none;
}

.listing.columns-2 .listing-item, .mega-links.columns-2 > .menu-item {
  width: 50%;
  float: left;
}

.listing.columns-2 .listing-item:nth-child(odd), .mega-links.columns-2 > .menu-item:nth-child(odd) {
  padding-right: 18px;
  clear: left;
}

.listing.columns-2 .listing-item:nth-child(even), .mega-links.columns-2 > .menu-item:nth-child(even) {
  padding-left: 18px;
}

.listing.columns-3 .listing-item, .mega-links.columns-3 > .menu-item {
  width: 33.3333%;
  float: left;
}

.listing.columns-3 .listing-item:nth-child(3n-1), .mega-links.columns-3 > .menu-item:nth-child(3n-1) {
  padding-left: 9px;
  padding-right: 9px;
}

.listing.columns-3 .listing-item:nth-child(3n-2), .mega-links.columns-3 > .menu-item:nth-child(3n-2) {
  padding-right: 18px;
  clear: left;
}

.listing.columns-3 .listing-item:nth-child(3n+3), .mega-links.columns-3 > .menu-item:nth-child(3n+3) {
  padding-left: 18px;
}

.listing.columns-4 .listing-item, .mega-links.columns-4 > .menu-item {
  width: 25%;
  float: left;
}

.listing.columns-4 .listing-item:nth-child(4n-3), .mega-links.columns-4 > .menu-item:nth-child(4n-3) {
  padding-left: 0;
  padding-right: 16px;
  clear: left;
}

.listing.columns-4 .listing-item:nth-child(4n+2), .mega-links.columns-4 > .menu-item:nth-child(4n+2) {
  padding-left: 5px;
  padding-right: 11px;
}

.listing.columns-4 .listing-item:nth-child(4n+3), .mega-links.columns-4 > .menu-item:nth-child(4n+3) {
  padding-left: 10px;
  padding-right: 6px;
}

.listing.columns-4 .listing-item:nth-child(4n+4), .mega-links.columns-4 > .menu-item:nth-child(4n+4) {
  padding-right: 0;
  padding-left: 16px;
}

.listing.columns-5 .listing-item, .mega-links.columns-5 > .menu-item {
  width: 20%;
  float: left;
}

.listing.columns-5 .listing-item:nth-child(5n+1), .mega-links.columns-5 > .menu-item:nth-child(5n+1) {
  padding-left: 0;
  padding-right: 12px;
  clear: left;
}

.listing.columns-5 .listing-item:nth-child(5n+2), .mega-links.columns-5 > .menu-item:nth-child(5n+2) {
  padding-left: 4px;
  padding-right: 8px;
}

.listing.columns-5 .listing-item:nth-child(5n+3), .mega-links.columns-5 > .menu-item:nth-child(5n+3) {
  padding-left: 6px;
  padding-right: 6px;
}

.listing.columns-5 .listing-item:nth-child(5n+4), .mega-links.columns-5 > .menu-item:nth-child(5n+4) {
  padding-left: 8px;
  padding-right: 4px;
}

.listing.columns-5 .listing-item:nth-child(5n+5), .mega-links.columns-5 > .menu-item:nth-child(5n+5) {
  padding-left: 8px;
  padding-right: 0;
}

.listing.columns-5.listing-tall .listing-item:nth-child(5n+5), .mega-links.columns-5.listing-tall > .menu-item:nth-child(5n+5) {
  padding-left: 12px;
  padding-right: 0;
}

@media only screen and (max-width: 1000px) {
  .listing.columns-5 .listing-item, .mega-links.columns-5 > .menu-item {
    width: 25%;
  }
  .listing.columns-5 .listing-item:nth-child(4n-3), .mega-links.columns-5 > .menu-item:nth-child(4n-3) {
    padding-left: 0;
    padding-right: 8px;
    clear: left;
  }
  .listing.columns-5 .listing-item:nth-child(4n+2), .mega-links.columns-5 > .menu-item:nth-child(4n+2) {
    padding-left: 3px;
    padding-right: 5px;
    clear: none;
  }
  .listing.columns-5 .listing-item:nth-child(4n+3), .mega-links.columns-5 > .menu-item:nth-child(4n+3) {
    padding-left: 5px;
    padding-right: 3px;
    clear: none;
  }
  .listing.columns-5 .listing-item:nth-child(4n+4), .mega-links.columns-5 > .menu-item:nth-child(4n+4) {
    padding-right: 0;
    padding-left: 8px;
    clear: none;
  }
}
@media only screen and (max-width: 900px) {
  .listing.columns-4 .listing-item, .listing.columns-5 .listing-item, .mega-links.columns-4 > .menu-item, .mega-links.columns-5 > .menu-item {
    width: 33.3333%;
  }
  .listing.columns-4 .listing-item:nth-child(3n-1), .listing.columns-5 .listing-item:nth-child(3n-1), .mega-links.columns-4 > .menu-item:nth-child(3n-1), .mega-links.columns-5 > .menu-item:nth-child(3n-1) {
    padding-left: 6px;
    padding-right: 6px;
    clear: none;
  }
  .listing.columns-4 .listing-item:nth-child(3n-2), .listing.columns-5 .listing-item:nth-child(3n-2), .mega-links.columns-4 > .menu-item:nth-child(3n-2), .mega-links.columns-5 > .menu-item:nth-child(3n-2) {
    padding-left: 0;
    padding-right: 12px;
    clear: left;
  }
  .listing.columns-4 .listing-item:nth-child(3n+3), .listing.columns-5 .listing-item:nth-child(3n+3), .mega-links.columns-4 > .menu-item:nth-child(3n+3), .mega-links.columns-5 > .menu-item:nth-child(3n+3) {
    padding-left: 12px;
    padding-right: 0;
    clear: none;
  }
}
@media only screen and (max-width: 600px) {
  .listing.columns-3 .listing-item, .listing.columns-4 .listing-item, .listing.columns-5 .listing-item, .mega-links.columns-3 > .menu-item, .mega-links.columns-4 > .menu-item, .mega-links.columns-5 > .menu-item {
    width: 50%;
  }
  .listing.columns-3 .listing-item:nth-child(odd), .listing.columns-4 .listing-item:nth-child(odd), .listing.columns-5 .listing-item:nth-child(odd), .mega-links.columns-3 > .menu-item:nth-child(odd), .mega-links.columns-4 > .menu-item:nth-child(odd), .mega-links.columns-5 > .menu-item:nth-child(odd) {
    padding-left: 0;
    padding-right: 13px;
    clear: left;
  }
  .listing.columns-3 .listing-item:nth-child(even), .listing.columns-4 .listing-item:nth-child(even), .listing.columns-5 .listing-item:nth-child(even), .mega-links.columns-3 .listing-item:nth-child(even), .mega-links.columns-4 .listing-item:nth-child(even), .mega-links.columns-5 .listing-item:nth-child(even) {
    padding-left: 13px;
    padding-right: 0;
    clear: none;
  }
}
@media only screen and (max-width: 500px) {
  .listing.columns-2 .listing-item, .listing.columns-3 .listing-item, .listing.columns-4 .listing-item, .listing.columns-5 .listing-item, .mega-links.columns-2 > .menu-item, .mega-links.columns-3 > .menu-item, .mega-links.columns-4 > .menu-item, .mega-links.columns-5 > .menu-item {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    clear: none !important;
  }
}
.archive-title img {
  max-width: 100%;
}

.bsbt-shortcode-placeholder-p.bsbt-shortcode-placeholder-p {
  word-break: break-all;
  margin: 0;
  line-height: 24px;
  font-size: 14px;
  font-family: var(--publisher-system-font);
}

.bsbt-shortcode-placeholder-p.bsbt-shortcode-placeholder-p + .bsbt-shortcode-placeholder {
  margin-top: 5px;
}

.bsbt-shortcode-placeholder {
  text-align: center;
  background: #fff8d7;
  border: 1px solid #e5d68d;
  margin: 0 0 40px;
  padding: 15px;
  line-height: 24px;
  font-size: 13px;
  font-family: var(--publisher-system-font);
}

.bsbt-shortcode-placeholder a, .bsbt-shortcode-placeholder a:hover {
  color: #e43c36 !important;
  font-weight: bolder;
}

.bsbt-shortcode-placeholder.type-small {
  padding: 7px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.bs-shortcode-row {
  margin-bottom: 20px;
}

.bs-shortcode-row-2-column[max-width~="450px"] .bs-shortcode-col {
  width: 100% !important;
  margin-bottom: 15px;
}

.bs-shortcode-row-2-column[max-width~="450px"] .bs-shortcode-col:last-child {
  margin-bottom: 0;
}

.bs-shortcode-row-3-column[max-width~="600px"] .bs-shortcode-col {
  width: 50% !important;
  margin-bottom: 15px;
}

.bs-shortcode-row-3-column[max-width~="450px"] .bs-shortcode-col {
  width: 100% !important;
}

.bs-shortcode-row-3-column[max-width~="600px"] .bs-shortcode-col:last-child {
  margin-bottom: 0;
}

.bs-shortcode-row-4-column[max-width~="700px"] .bs-shortcode-col {
  width: 33.3333% !important;
  margin-bottom: 15px;
}

.bs-shortcode-row-4-column[max-width~="600px"] .bs-shortcode-col {
  width: 50% !important;
}

.bs-shortcode-row-4-column[max-width~="450px"] .bs-shortcode-col {
  width: 100% !important;
}

.bs-shortcode-row-4-column[max-width~="700px"] .bs-shortcode-col:last-child {
  margin-bottom: 0;
}

.bs-ll.a .fb-page, .bs-ll.a .google-plus-block {
  background-color: #e9ebee;
  background-image: linear-gradient(to left, #e9ebee 0, #f4f5f6 20%, #e9ebee 40%, #e9ebee 100%);
  background-repeat: no-repeat;
  background-size: 1000px 900px;
  -webkit-animation: bs-lazy-anim 1s infinite linear forwards;
  -moz-animation: bs-lazy-anim 1s infinite linear forwards;
  animation: bs-lazy-anim 1.01s infinite linear forwards;
  width: 100% !important;
  min-height: 130px !important;
  text-align: center;
}

.bs-ll.a .footer-widgets.light-text .fb-page, .bs-ll.a .footer-widgets.light-text .google-plus-block {
  background-color: #373737;
  background-image: linear-gradient(to left, #373737 0, #2b2b2b 20%, #373737 40%, #373737 100%);
}

.fb-page.fb_iframe_widget {
  background: 0 0;
}

.bs-text a:hover {
  text-decoration: underline;
}

.bs-text .bs-text-content p:last-child {
  margin-bottom: 0;
}

.bs-text a.btn {
  color: #fff;
}

.pagination {
  margin: 20px 0 0;
  font-size: 18px;
}

.pagination:last-child {
  margin-bottom: 0 !important;
}

.entry-content .bs-post-pagination .pages {
  border-color: #dadada !important;
  color: #333;
}

.entry-content .bs-post-pagination span {
  line-height: 28px;
  height: 28px;
  min-width: 28px;
}

.entry-content > .bs-post-pagination:first-child {
  margin: 0 0 20px;
}

.pagination.bs-links-pagination .older {
  float: left;
  width: 50%;
  text-align: left;
}

.pagination.bs-links-pagination .newer {
  float: right;
  width: 50%;
  text-align: right;
}

.pagination.bs-links-pagination a {
  display: inline-block;
  margin-right: 1px;
  line-height: 26px;
  height: 26px;
  min-width: 30px;
  text-align: center;
  padding: 0 9px;
  background-color: #fff;
  border: 1px solid #dadada;
  color: #8a8f92;
  font-size: 12px;
  text-transform: uppercase;
}

.pagination.bs-links-pagination a:hover {
  color: #fff;
  border: 1px solid transparent;
}

.pagination.bs-numbered-pagination .page-numbers, .pagination.bs-numbered-pagination .page-numbers.dots:hover, .pagination.bs-numbered-pagination .wp-pagenavi a, .pagination.bs-numbered-pagination .wp-pagenavi span, .pagination.bs-numbered-pagination span {
  display: inline-block;
  margin-right: 1px;
  line-height: 30px;
  height: 30px;
  min-width: 30px;
  text-align: center;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #dadada;
  color: #8a8f92;
  font-size: 12px;
  text-transform: uppercase;
  list-style: none;
}

.pagination.bs-numbered-pagination span {
  margin-right: 0;
}

.pagination.bs-numbered-pagination .current, .pagination.bs-numbered-pagination .page-numbers:hover, .pagination.bs-numbered-pagination .wp-pagenavi .current, .pagination.bs-numbered-pagination .wp-pagenavi a:hover, .pagination.bs-numbered-pagination > span {
  border: 1px solid;
}

.pagination.bs-numbered-pagination .next .fa {
  margin-left: 4px;
  font-size: 15px;
}

.pagination.bs-numbered-pagination .prev .fa {
  margin-right: 4px;
  font-size: 15px;
}

.pagination.bs-numbered-pagination.wc-pagination > .page-numbers {
  border: none;
  background: 0 0;
  display: block;
  padding: 0;
  margin: 0;
  text-align: inherit;
}

.pagination.bs-numbered-pagination.wc-pagination > .page-numbers > li {
  display: inline-block;
}

.post-related .listing-item .title {
  font-size: 15px;
  line-height: 1.4;
}

.post-related .include-last-mobile .listing-item:last-child {
  display: none;
}

@media only screen and (max-width: 600px) {
  .post-related .include-last-mobile .listing-item:last-child {
    display: block;
  }
}
.content-column .single-container:last-child {
  margin-bottom: 0;
}

.single-post-content .term-badges.floated a {
  max-width: auto;
}

.post-subtitle {
  padding: 0;
  margin: 0;
  color: #717171;
}

.single-post-content .post-embed iframe {
  max-width: 100%;
}

.entry-terms {
  margin-bottom: 7px;
}

.entry-terms:last-child {
  margin-bottom: -3px;
}

.entry-terms ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline;
}

.entry-terms li {
  display: inline;
}

.entry-terms span.sep {
  margin: 0 5px;
}

.entry-terms .terms-label {
  padding: 0 10px;
  display: inline-block;
  margin-right: 4px;
}

.entry-terms.post-tags .terms-label, .entry-terms.source .terms-label, .entry-terms.via .terms-label {
  line-height: 20px;
  border: 1px solid #444;
  background: #444;
  color: #fff;
  font-size: 10px;
  float: left;
  border-radius: 33px;
}

.entry-terms.post-tags a, .entry-terms.source a, .entry-terms.via a {
  background: #fff;
  border: 1px solid #d4d4d4;
  padding: 0 9px;
  display: inline-block;
  line-height: 20px;
  margin-right: 3px;
  font-size: 10px;
  float: left;
  margin-bottom: 3px;
  color: #5f5f5f;
  border-radius: 33px;
}

.entry-terms.post-tags a:hover, .entry-terms.source a:hover, .entry-terms.via a:hover {
  color: #fff;
  border: 1px solid transparent;
}

.single-container > .post-author {
  padding: 20px;
  margin: 15px 0 0;
  position: relative;
  border: 1px solid #e8e8e8;
}

.single-container > .post-author .post-author-avatar img {
  float: left;
  margin-right: 20px;
}

.single-container > .post-author .author-title {
  margin: 0 0 7px;
  font-size: 18px;
  text-transform: capitalize;
  padding-right: 50%;
}

.single-container > .post-author .author-title .title-counts {
  color: #8a8a8a;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 400;
}

.single-container > .post-author .author-title .title-counts:before {
  content: "-";
  margin: 0 8px;
  opacity: 0.4;
}

.single-container > .post-author .author-links {
  width: 50%;
  text-align: right;
  position: absolute;
  top: 15px;
  right: 15px;
}

.single-container > .post-author .author-links ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.single-container > .post-author .author-links li {
  margin-left: 8px;
  display: inline-block;
}

.single-container > .post-author .author-links a {
  color: #353535;
  font-size: 15px;
  width: 24px;
  display: inline-block;
  text-align: center;
}

.single-container > .post-author .author-links li.facebook a:hover {
  color: #4a639f;
}

.single-container > .post-author .author-links li.telegram a:hover {
  color: #2da4d9;
}

.single-container > .post-author .author-links li.twitter a:hover {
  color: #59c8ff;
}

.single-container > .post-author .author-links li.google-plus a:hover {
  color: #d73d32;
}

.single-container > .post-author .author-links li.instagram a:hover {
  color: #517fa4;
}

.single-container > .post-author .author-links li.flickr a:hover {
  color: #2f44db;
}

.single-container > .post-author .author-links li.behance a:hover {
  color: #3b63fc;
}

.single-container > .post-author .author-links li.soundcloud a:hover {
  color: #f50;
}

.single-container > .post-author .author-links li.delicious a:hover {
  color: #4a79ff;
}

.single-container > .post-author .author-links li.vimeo a:hover {
  color: #46a3e9;
}

.single-container > .post-author .author-links li.dribbble a:hover {
  color: #ea4c89;
}

.single-container > .post-author .author-links li.linkedin a:hover {
  color: #0266a0;
}

.single-container > .post-author .author-links li.youtube a:hover {
  color: #cc181e;
}

.single-container > .post-author .author-links li.pinterest a:hover {
  color: #cb2027;
}

.single-container > .post-author .author-links li.github a:hover {
  color: #666;
}

.single-container > .post-author .post-author-bio {
  margin-top: 6px;
  font-size: 13px;
  padding-left: 100px;
}

.single-container > .post-author .post-author-bio p:last-child {
  margin-bottom: 0;
}

.post-related {
  position: relative;
  padding: 20px;
  border: 1px solid #e8e8e8;
}

.post-related .section-heading.sh-t2.sh-s1, .post-related .section-heading.sh-t2.sh-s2 {
  margin: -21px -21px 27px;
  padding: 4px 20px 0;
}

.post-related .listing-item-grid-simple .heading-container {
  background: #f5f5f5;
}

@media only screen and (max-width: 520px) {
  .single-container > .post-author .author-title {
    padding-right: 0;
  }
  .single-container > .post-author .author-links {
    width: 100%;
    text-align: left;
    position: relative;
    top: inherit;
    right: inherit;
  }
  .single-container > .post-author .author-links li {
    margin-left: 0;
    margin-right: 4px;
  }
  .single-container > .post-author .post-author-bio {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 380px) {
  .single-container > .post-author .post-author-avatar {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  .single-container > .post-author .post-author-avatar img {
    float: none;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
  }
  .single-container > .post-author .author-links {
    text-align: center;
  }
  .single-container > .post-author .author-links li {
    margin-left: 7px;
    margin-right: 7px;
  }
  .single-container > .post-author .post-author-bio {
    margin-top: 5px;
  }
  .single-container > .post-author .author-title {
    text-align: center;
    margin: 14px 0 10px;
    padding: 0;
  }
  .single-container > .post-author .post-author-bio {
    text-align: center;
    padding-left: 0;
  }
}
.single-page-content > .featured {
  text-align: center;
  display: block;
  margin: 0 0 20px;
  line-height: 0;
}

.single-page-content > .featured img {
  max-width: 100%;
  height: auto !important;
}

.single-page-content > .featured iframe {
  max-width: 100%;
}

.single-attachment-content .return-to {
  margin-bottom: 20px;
}

.single-attachment-content .return-to .fa {
  border: 1px solid #d4d4d4;
  border: 1px solid rgba(138, 138, 138, 0.33);
  color: #9c9c9c;
  color: rgba(138, 138, 138, 0.83);
  font-size: 12px;
  padding: 0 9px;
  display: inline-block;
  line-height: 24px;
  min-width: 26px;
  text-align: center;
  cursor: pointer;
  margin-right: 7px;
  text-decoration: none;
  -webkit-transition: all 0.07s ease;
  -moz-transition: all 0.07s ease;
  -o-transition: all 0.07s ease;
  transition: all 0.07s ease;
}

.single-attachment-content .return-to:hover .fa {
  border-color: transparent;
  color: #fff;
}

.single-attachment-content .parent-images ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.single-attachment-content .parent-images .img-holder {
  width: 100%;
  margin-bottom: 12px;
}

.single-attachment-content .parent-images .img-holder .fa {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  display: inline-block;
  font-size: 30px;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #fff;
  z-index: 2;
}

.single-attachment-content .parent-images .img-holder:hover .fa {
  opacity: 1;
}

.single-attachment-content .parent-images .img-holder:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.single-attachment-content .parent-images .img-holder:hover:after, .single-attachment-content .parent-images .listing-item-current > .img-holder .fa, .single-attachment-content .parent-images .listing-item-current > .img-holder:after {
  opacity: 1;
}

.single-attachment-content .attachment-title {
  font-size: 22px;
}

.single-attachment-content .pagination {
  margin-bottom: 23px;
}

.listing.listing-attachment-siblings.columns-5[max-width~="900px"] .listing-item {
  width: 25% !important;
  float: left !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="900px"] .listing-item:nth-child(4n-3) {
  padding-left: 0 !important;
  padding-right: 8px !important;
  clear: left !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="900px"] .listing-item:nth-child(4n+2) {
  padding-left: 3px !important;
  padding-right: 5px !important;
  clear: none !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="900px"] .listing-item:nth-child(4n+3) {
  padding-left: 5px !important;
  padding-right: 3px !important;
  clear: initial !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="900px"] .listing-item:nth-child(4n+4) {
  padding-right: 0 !important;
  padding-left: 8px !important;
  clear: none !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="560px"] .listing-item:nth-child(3n-1) {
  padding: 0 6px !important;
  clear: none !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="560px"] .listing-item:nth-child(3n-2) {
  padding-left: 0 !important;
  padding-right: 12px !important;
  clear: left !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="560px"] .listing-item:nth-child(3n+3) {
  padding-left: 12px !important;
  padding-right: 0 !important;
  clear: none !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="560px"] .listing-item {
  width: 33.3333% !important;
  float: left;
}

.listing.listing-attachment-siblings.columns-5[max-width~="450px"] .listing-item {
  float: left !important;
  width: 50% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="450px"] .listing-item:nth-child(odd) {
  padding-right: 13px !important;
  clear: left !important;
  padding-left: 0 !important;
}

.listing.listing-attachment-siblings.columns-5[max-width~="450px"] .listing-item:nth-child(even) {
  padding-left: 13px !important;
  padding-right: 0 !important;
  clear: none !important;
}

.format-video.listing-item-grid .heading-container {
  margin-top: 0;
}

.format-gallery.listing-item-grid .heading-container {
  z-index: 999;
}

.single-post-content.format-aside .entry-content, .single-post-content.format-aside .post-summary {
  position: relative;
  padding: 32px 50px 32px 50px;
  line-height: 30px;
  color: #5a4f43;
  background-color: #f2f6c1;
  border: 1px solid #e8e8e8;
  background-image: -webkit-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0, rgba(255, 255, 255, 0.1) 90%), -webkit-repeating-linear-gradient(top, transparent 0, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(239, 207, 173, 0.7) 30px);
  background-image: -moz-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0, rgba(255, 255, 255, 0.1) 90%), -moz-repeating-linear-gradient(top, transparent 0, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(239, 207, 173, 0.7) 30px);
  background-image: -o-radial-gradient(center, cover, rgba(255, 255, 255, 0.7) 0, rgba(255, 255, 255, 0.1) 90%), -o-repeating-linear-gradient(top, transparent 0, transparent 29px, rgba(239, 207, 173, 0.7) 29px, rgba(239, 207, 173, 0.7) 30px);
  background-size: 100% 100%;
}

.single-post-content.format-aside .entry-content {
  margin-bottom: 20px;
}

.single-post-content.format-aside .entry-content blockquote:before {
  background: 0 0;
  top: 26px;
}

.single-post-content.format-quote .entry-content, .single-post-content.format-quote .post-summary {
  text-align: center;
  background-color: #f3f3f3;
  border: 1px solid rgba(0, 0, 0, 0.11);
  padding: 30px 20px;
  margin-bottom: 30px;
}

.single-post-content.format-quote .entry-content blockquote, .single-post-content.format-quote .post-summary blockquote {
  border-left: none;
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
}

.single-post-content.format-quote .entry-content blockquote:before, .single-post-content.format-quote .post-summary blockquote:before {
  top: -20px;
  background: 0 0;
  line-height: 80px;
}

.single-post-content.format-quote .entry-content blockquote {
  background: 0 0;
  padding: 20px 0;
}

.single-post-content.format-quote .entry-content blockquote cite, .single-post-content.format-quote .post-summary blockquote cite {
  font-weight: 800;
  font-style: normal;
  display: block;
  margin-top: 10px;
}

.single-post-content.format-quote .entry-content blockquote cite:before, .single-post-content.format-quote .post-summary blockquote cite:before {
  content: "— ";
}

.single-post-content.format-quote .heading-container .post-meta, .single-post-content.format-quote .heading-container .title {
  text-align: center;
}

.single-post-content.format-status .entry-content, .single-post-content.format-status .post-summary {
  text-align: center;
  padding: 20px;
  border: 2px solid #eaeaea;
  font-size: 14px;
}

.single-post-content.format-status .post-summary {
  float: left;
}

.single-post-content.format-status .entry-content {
  margin-bottom: 19px;
}

.post.format-status .post-summary p:last-child, .single-post-content.format-status .entry-content p:last-child {
  margin-bottom: 0;
}

.single-post-content.format-status .heading-container .post-meta, .single-post-content.format-status .heading-container .title {
  text-align: center;
}

.better-chat {
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.better-chat .chat-item {
  margin-bottom: 10px;
}

.better-chat .chat-item p:last-child {
  margin-bottom: 0;
}

.better-chat .chat-item .user-name {
  display: inline-block;
  position: absolute;
  top: 100%;
  right: 0;
  color: #9a9a9a;
  font-size: 12px;
  font-weight: 400;
  font-family: var(--publisher-system-font);
}

.better-chat .chat-item {
  margin: 0 0 30px;
  border-radius: 14px;
  padding: 8px 16px;
  background: #e5e5ea;
  max-width: 75%;
  clear: both;
  position: relative;
}

.better-chat .chat-item.chat-no-author {
  margin-bottom: 10px;
}

.better-chat .chat-item.chat-next-author {
  margin-bottom: 2px;
}

.better-chat .chat-item.chat-next-author:after, .better-chat .chat-item.chat-next-author:before {
  display: none;
}

.better-chat .chat-item {
  float: right;
  background-color: #dcdcdc;
  color: #373737;
  text-align: right;
}

.better-chat .chat-item::after {
  content: "";
  position: absolute;
  right: -4px;
  bottom: 0;
  width: 12px;
  height: 16px;
  border-left: 8px solid #dcdcdc;
  border-bottom-left-radius: 12px 6px;
}

.better-chat .chat-item.chat-speaker-1, .better-chat .chat-item.chat-speaker-3, .better-chat .chat-item.chat-speaker-5, .better-chat .chat-item.chat-speaker-7, .better-chat .chat-item.chat-speaker-9 {
  float: left;
  text-align: left;
}

.better-chat .chat-item.chat-speaker-1:after, .better-chat .chat-item.chat-speaker-3:after, .better-chat .chat-item.chat-speaker-5:after, .better-chat .chat-item.chat-speaker-7:after, .better-chat .chat-item.chat-speaker-9:after {
  display: none;
}

.better-chat .chat-item.chat-speaker-1 .user-name, .better-chat .chat-item.chat-speaker-3 .user-name, .better-chat .chat-item.chat-speaker-5 .user-name, .better-chat .chat-item.chat-speaker-7 .user-name, .better-chat .chat-item.chat-speaker-9 .user-name {
  left: 0;
  right: auto;
}

.better-chat .chat-item.chat-speaker-1:before, .better-chat .chat-item.chat-speaker-3:before, .better-chat .chat-item.chat-speaker-5:before, .better-chat .chat-item.chat-speaker-7:before, .better-chat .chat-item.chat-speaker-9:before {
  content: "";
  position: absolute;
  left: -4px;
  bottom: 0;
  width: 12px;
  height: 16px;
  border-right: 8px solid transparent;
  border-left: none;
  border-bottom-right-radius: 12px 6px;
}

.better-chat .chat-item a, .better-chat .chat-item a:hover {
  color: #fff !important;
}

.better-chat .chat-item.chat-speaker-2 a, .better-chat .chat-item.chat-speaker-2 a:hover {
  color: #373737 !important;
}

.better-chat .chat-item.chat-speaker-1 {
  background-color: #1289fe;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-1:before {
  border-right-color: #1289fe;
}

.better-chat .chat-item.chat-speaker-3 {
  background-color: #009e5d;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-3:before {
  border-right-color: #009e5d;
}

.better-chat .chat-item.chat-speaker-4 {
  background-color: #daa715;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-4:after {
  border-left-color: #daa715;
}

.better-chat .chat-item.chat-speaker-5 {
  background-color: #ce4309;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-5:before {
  border-right-color: #ce4309;
}

.better-chat .chat-item.chat-speaker-6 {
  background-color: #da3699;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-6:after {
  border-left-color: #da3699;
}

.better-chat .chat-item.chat-speaker-7 {
  background-color: #7655ce;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-7:before {
  border-right-color: #7655ce;
}

.better-chat .chat-item.chat-speaker-8 {
  background-color: #3b99da;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-8:after {
  border-left-color: #3b99da;
}

.better-chat .chat-item.chat-speaker-9 {
  background-color: #158a54;
  color: #fff;
}

.better-chat .chat-item.chat-speaker-9:before {
  border-right-color: #158a54;
}

.bs-intro, .entry-content .bs-intro {
  font-size: 110%;
  font-weight: bolder;
  -webkit-font-smoothing: antialiased;
}

.entry-content {
  font-size: 14px;
  line-height: 1.6;
  color: #4a4a4a;
  padding-bottom: 20px;
}

.post .entry-content.single-post-content:last-child {
  padding-bottom: 0;
}

.entry-content.single-post-content iframe, .single-page-simple-content .entry-content iframe {
  max-width: 100%;
}

.fluid-width-video-wrapper embed, .fluid-width-video-wrapper iframe, .fluid-width-video-wrapper object {
  height: 100% !important;
}

.entry-content .wp-block-media-text {
  margin-bottom: 25px;
}

.entry-content p {
  margin-bottom: 17px;
}

.entry-content p:last-child {
  margin-bottom: 0;
}

.entry-content ol, .entry-content ul {
  margin-bottom: 17px;
}

.entry-content table {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  width: 100%;
}

.entry-content table > tbody > tr > td, .entry-content table > tbody > tr > th, .entry-content table > tfoot > tr > td, .entry-content table > tfoot > tr > th, .entry-content table > thead > tr > td, .entry-content table > thead > tr > th {
  border: 1px solid #ddd;
  padding: 7px 10px;
}

.entry-content table > thead > tr > td, .entry-content table > thead > tr > th {
  border-bottom-width: 2px;
}

.entry-content dl dt {
  font-size: 15px;
}

.entry-content dl dd {
  margin-bottom: 10px;
}

.entry-content acronym[title] {
  border-bottom: 1px dotted #999;
}

.alignright, .entry-content .alignright {
  margin: 5px 0 20px 20px;
  float: right;
}

.alignleft, .entry-content .alignleft {
  margin: 5px 20px 15px 0;
  float: left;
}

.entry-content figure.alignleft .wp-caption-text {
  text-align: left;
}

.entry-content figure.alignright .wp-caption-text {
  text-align: right;
}

.entry-content figure, .entry-content img {
  max-width: 100%;
  height: auto;
}

.entry-content img.aligncenter {
  display: block;
  margin: 15px auto 25px;
}

.entry-content figure.aligncenter {
  margin: 20px auto;
  text-align: center;
}

.entry-content figure.aligncenter img {
  display: inline-block;
}

.entry-content .gallery-caption, .entry-content .wp-caption-text, .entry-content figcaption {
  margin: 5px 0 0;
  font-style: italic;
  font-size: 13px;
  line-height: 15px;
  color: #545454;
  padding: 0;
  text-align: center;
}

@media (min-width: 750px) {
  body.full-width .wp-block-image.alignfull {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: auto;
    max-width: 1000% !important;
  }
  body.full-width .wp-block-image.alignwide {
    margin-left: calc(25% - 25vw);
    margin-right: calc(25% - 25vw);
    width: auto;
    max-width: 1000% !important;
  }
  body.boxed .wp-block-image.alignfull, body.boxed .wp-block-image.alignwide {
    margin-left: -30px;
    margin-right: -30px;
    width: auto;
    max-width: 1000% !important;
  }
}
.entry-content .g-post, .entry-content .instagram-media, .entry-content .reddit-card, .entry-content .tumblr-post, .entry-content .twitter-tweet {
  margin-left: auto !important;
  margin-right: auto !important;
}

.entry-content .gallery {
  text-align: center;
}

.entry-content .gallery:after {
  content: "";
  display: table;
  clear: both;
}

.entry-content .gallery .gallery-item {
  position: relative;
}

.entry-content .gallery .gallery-item img {
  max-width: 100% !important;
  height: auto !important;
}

.entry-content .gallery.gallery-columns-1, .entry-content .gallery.gallery-columns-2, .entry-content .gallery.gallery-columns-3, .entry-content .gallery.gallery-columns-4, .entry-content .gallery.gallery-columns-5, .entry-content .gallery.gallery-columns-6, .entry-content .gallery.gallery-columns-7, .entry-content .gallery.gallery-columns-8, .entry-content .gallery.gallery-columns-9 {
  margin: -5px -5px 15px -5px;
}

.entry-content .gallery.gallery-columns-1 .gallery-item, .entry-content .gallery.gallery-columns-2 .gallery-item, .entry-content .gallery.gallery-columns-3 .gallery-item, .entry-content .gallery.gallery-columns-4 .gallery-item, .entry-content .gallery.gallery-columns-5 .gallery-item, .entry-content .gallery.gallery-columns-6 .gallery-item, .entry-content .gallery.gallery-columns-7 .gallery-item, .entry-content .gallery.gallery-columns-8 .gallery-item, .entry-content .gallery.gallery-columns-9 .gallery-item {
  padding: 5px;
}

.entry-content .gallery.gallery-columns-2 {
  clear: both;
}

.entry-content .gallery.gallery-columns-2 .gallery-item {
  width: 50%;
  float: left;
}

.entry-content .gallery.gallery-columns-3 .gallery-item {
  width: 33.33%;
  float: left;
}

.entry-content .gallery.gallery-columns-4 .gallery-item {
  width: 25%;
  float: left;
}

.entry-content .gallery.gallery-columns-5 .gallery-item {
  width: 20%;
  float: left;
}

.entry-content .gallery.gallery-columns-6 .gallery-item {
  width: 16.666%;
  float: left;
}

.entry-content .gallery.gallery-columns-7 .gallery-item {
  width: 14.28%;
  float: left;
}

.entry-content .gallery.gallery-columns-8 .gallery-item {
  width: 12.5%;
  float: left;
}

.entry-content .gallery.gallery-columns-9 .gallery-item {
  width: 11.111%;
  float: left;
}

.entry-content .gallery.gallery-columns-2 .gallery-item:nth-child(2n+3), .entry-content .gallery.gallery-columns-3 .gallery-item:nth-child(3n+4), .entry-content .gallery.gallery-columns-4 .gallery-item:nth-child(4n+5), .entry-content .gallery.gallery-columns-5 .gallery-item:nth-child(5n+6), .entry-content .gallery.gallery-columns-6 .gallery-item:nth-child(6n+7), .entry-content .gallery.gallery-columns-7 .gallery-item:nth-child(7n+8), .entry-content .gallery.gallery-columns-8 .gallery-item:nth-child(8n+9), .entry-content .gallery.gallery-columns-9 .gallery-item:nth-child(9n+10) {
  clear: left;
}

.entry-content .terms-list {
  margin-bottom: 10px;
}

.entry-content .terms-list span.sep {
  margin: 0 5px;
}

.entry-content.single-post-content a:hover, .single-page-simple-content .entry-content a:hover {
  text-decoration: underline;
}

.entry-content hr {
  margin: 27px 0;
  border-top: 2px solid #f0f0f0;
}

.entry-content .wp-block-separator {
  border: none;
  border-bottom: 2px solid #f0f0f0;
  margin: 1.65em auto;
}

.entry-content .wp-block-separator.is-style-default {
  border: none;
  border-bottom: 2px solid #f0f0f0;
  margin: 1.65em auto;
}

.entry-content .wp-block-separator.is-style-wide {
  border-bottom-width: 1px;
}

.entry-content .wp-block-separator.is-style-dots {
  border-bottom: none;
}

.entry-content .wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
  max-width: 100px;
}

.entry-content > :last-child {
  margin-bottom: 0;
}

.entry-content > h1:first-child, .entry-content > .h1:first-child, .entry-content > h2:first-child, .entry-content > .h2:first-child, .entry-content > h3:first-child, .entry-content > .h3:first-child, .entry-content > h4:first-child, .entry-content > .h4:first-child, .entry-content > h5:first-child, .entry-content > .h5:first-child, .entry-content > h6:first-child, .entry-content > .h6:first-child {
  margin-top: 0;
}

blockquote {
  font-size: 14px;
}

blockquote p:last-child {
  margin-bottom: 0;
}

.entry-content.single-post-content blockquote, .single-page-simple-content .entry-content blockquote {
  font-size: 110%;
  background-color: #efefef;
  border-left: none;
  padding: 60px 35px 50px;
  margin: 40px 0 30px 0;
  position: relative;
  text-align: center;
  clear: both;
}

.entry-content.single-post-content blockquote:before, .single-page-simple-content .entry-content blockquote:before {
  content: "\f10e";
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -30px;
  margin-left: -40px;
  font-size: 30px;
  font-family: FontAwesome;
  color: #444;
  display: block;
  width: 80px;
  height: 80px;
  background: #fff;
  line-height: 91px;
  border-radius: 50%;
  text-align: center;
}

.entry-content.single-post-content blockquote blockquote:before, .single-page-simple-content .entry-content blockquote blockquote:before {
  display: none;
}

.entry-content.single-post-content blockquote blockquote, .single-page-simple-content .entry-content blockquote blockquote {
  background-color: transparent;
  padding: 0;
  margin: 0 0 10px;
}

.entry-content.single-post-content blockquote blockquote:last-child, .single-page-simple-content .entry-content blockquote blockquote:last-child {
  margin-bottom: 0;
}

.entry-content.single-post-content blockquote.bs-pullquote, .single-page-simple-content .entry-content blockquote.bs-pullquote {
  min-width: 250px;
  max-width: 333px;
  display: inline-block;
  padding: 20px 20px 20px;
  margin: 0 20px 20px 0;
  background-color: #f3f3f3;
  clear: none;
}

.entry-content blockquote.bs-pullquote:before {
  display: none;
}

.entry-content.single-post-content blockquote.bs-pullquote-right, .single-page-simple-content .entry-content blockquote.bs-pullquote-right {
  text-align: right;
  float: right;
  margin-left: 25px;
  border-right: 4px solid transparent;
}

.entry-content.single-post-content blockquote.bs-pullquote-left, .single-page-simple-content .entry-content blockquote.bs-pullquote-left {
  text-align: left;
  float: left;
  margin-right: 25px;
  border-left: 4px solid transparent;
}

@media only screen and (max-width: 530px) {
  .alignleft, .alignright, .entry-content .alignleft, .entry-content .alignright {
    text-align: center !important;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 100% !important;
    display: block !important;
  }
  .entry-content figure.alignright .wp-caption-text {
    text-align: center !important;
  }
}
.continue-reading-container {
  display: none;
}

@media only screen and (max-width: 700px) {
  .continue-reading-content.close {
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    max-height: 170px;
    transition: max-height 0.75s ease;
    width: 100%;
  }
  .continue-reading-content.close:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(transparent 35%, #fff);
    z-index: 99;
  }
  .continue-reading-container {
    display: block;
    text-align: center;
    position: relative;
    z-index: 300;
    bottom: 25px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px;
    letter-spacing: 1.4px;
  }
  .continue-reading-container:last-child {
    border-bottom: none;
  }
  .continue-reading-btn.btn {
    color: #fff;
    line-height: 42px;
    height: auto;
    padding: 0 42px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.37);
    border-radius: 23px;
  }
}
.comments-wrap {
  margin: 0 auto;
  position: relative;
}

.comments-template .comments-closed {
  text-align: center;
  padding: 20px 15px;
  background-color: #f5f5f5;
}

.comments-wrap ol.comment-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.comments-wrap ol.comment-list .children {
  list-style: none;
  margin: 0;
  padding-left: 10%;
}

@media only screen and (max-width: 600px) {
  .comments-wrap ol.comment-list .children {
    padding-left: 7%;
  }
}
.comments-wrap li.comment, .comments-wrap li.pingback {
  clear: both;
}

.comments-wrap li > div {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding: 15px 0;
}

.comments-wrap .comment-list > li:first-child > div {
  border-top: none;
}

.comment-list .comment-avatar {
  float: left;
  margin: 0 20px 10px 0;
}

.comment-list .comment-avatar .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.comment-list .comment-meta {
  margin: 0 0 5px;
  color: #858585;
}

.comment-list .comment-meta .comment-author {
  display: inline-block;
  padding: 4px 0 0;
  font-size: 15px;
  text-transform: capitalize !important;
  font-style: normal;
}

.comments-wrap li.pingback .comment-meta .comment-author {
  padding-top: 0;
}

.comment-list .comment-meta .comment-author .says {
  color: #9a9a9a;
}

.comment-list .comment-meta .comment-published {
  font-style: italic;
  display: inline-block;
  margin: 0;
  padding: 4px 0 0;
  float: right;
  font-size: 11px;
  color: #c2c2c2;
}

.comment-list .comment-content {
  margin: 0 0 8px;
  font-size: 13px;
  padding-left: 80px;
}

.comment-list .comment-content em.needs-approve {
  background: #f2f2f2;
  padding: 2px 5px;
  border-radius: 3px;
  margin-bottom: 7px;
  display: inline-block;
}

.comment-list .comment-footer {
  font-size: 12px;
}

.comment-list .comment-footer .comment-edit-link {
  margin-left: 15px;
}

.comment-list .comment-footer, .comment-list .comment-footer .comment-edit-link {
  color: #868686;
  float: right;
}

.comment-list .comment-footer .comment-reply-link {
  display: inline-block;
  float: right;
  color: #909090;
  text-align: center;
}

.comments-wrap .comments-nav {
  margin-top: 10px;
  position: relative;
  border-top: 1px solid #ebebeb;
  padding-top: 10px;
  padding-bottom: 5px;
}

.comments-wrap .comments-nav .pagination {
  margin-top: 0;
}

.comments-wrap .comments-nav .page-numbers {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: 400;
}

.comment-respond {
  margin: 0 auto;
  position: relative;
}

.comments-wrap + .comment-respond {
  margin-top: 20px;
}

@media only screen and (max-width: 780px) {
  .comment-respond {
    margin-bottom: 30px;
  }
}
.comment-respond .comment-reply-title:empty {
  display: none;
}

.comment-respond #cancel-comment-reply-link, .comment-respond .comment-reply-title .h-text {
  display: inline-block;
  padding: 6px 12px;
  text-transform: uppercase;
}

.comment-respond .log-in-out {
  text-align: center;
}

.form-allowed-tags {
  margin-top: -1.5rem;
  font-size: 0.9375rem;
}

.comment-respond #cancel-comment-reply-link {
  float: right;
  color: #fff !important;
}

.comments-wrap .comment-respond .comment-reply-title {
  text-align: inherit;
}

.comment-respond .wp-social-login-widget {
  text-align: center;
}

.comment-respond .wp-social-login-connect-with {
  font-size: 17px;
  font-weight: 700;
  color: #414141;
}

.comment-respond .note-after, .comment-respond .note-before {
  text-align: center;
  margin-bottom: 15px;
}

.comment-respond .note-after p:last-child, .comment-respond .note-before p:last-child {
  margin-bottom: 0;
}

.comment-respond .author-wrap {
  width: 50%;
  float: left;
  padding-right: 10px;
}

.comment-respond .email-wrap {
  width: 50%;
  float: left;
  padding-left: 10px;
}

.comment-respond .url-wrap {
  width: 100%;
}

.comment-respond .url-wrap .url {
  max-width: 100%;
  width: 100%;
}

.comment-respond .author-wrap input, .comment-respond .email-wrap input {
  width: 100%;
}

.comment-respond .author-wrap, .comment-respond .comment-wrap, .comment-respond .email-wrap, .comment-respond .url-wrap {
  margin-bottom: 15px;
}

.comment-respond .author, .comment-respond .comment, .comment-respond .email, .comment-respond .url {
  border: 1px solid #d8d8d8;
  padding: 18px 12px;
}

.comment-respond .author, .comment-respond .email, .comment-respond .url {
  padding: 8px 12px;
  height: auto;
}

.comment-respond .comment {
  height: 160px;
}

.comment-respond .comment-submit {
  background: #434343;
  font-size: 12px;
  font-weight: 400;
}

.comment-respond .form-submit {
  margin: 0;
}

.ajaxified-comments-container {
  margin: 20px 0;
  border: 1px solid #e1e2e4;
  background: #f6f7f9;
  padding: 20px 20px;
  text-align: center;
  color: #1d1d1d;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bolder;
}

.comment-ajaxified-placeholder {
  color: #696969;
  margin: 0 5px;
}

.comment-form input + label {
  margin-left: 5px;
}

.comments-template-multiple .nav-tabs {
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.comments-template-multiple .nav-tabs li {
  list-style: none;
  display: inline-block;
  margin: 0 !important;
  width: 33.333%;
  line-height: 56px;
}

.comments-template-multiple.providers-2 .nav-tabs li {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .comments-template-multiple.providers-2 .nav-tabs li, .comments-template-multiple.providers-3 .nav-tabs li {
    width: 100% !important;
  }
}
.comments-template-multiple .nav-tabs a {
  font-size: 14px;
  font-weight: 700;
  padding: 0 26px;
  display: block;
  line-height: 56px;
  height: 56px;
  border: none !important;
  border-radius: 0;
  white-space: nowrap;
  position: relative;
  transition: all 0.3s ease;
  text-align: center;
  background: #eff0f1;
  margin: 0 !important;
}

.comments-template-multiple .nav-tabs li:first-child a {
  border-radius: 4px 0 0 4px;
}

.comments-template-multiple .nav-tabs li:last-child a {
  border-radius: 0 4px 4px 0;
}

.comments-template-multiple .nav-tabs a:after {
  content: " ";
  display: inline-block;
  height: 3px;
  width: 0;
  background-color: transparent;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.3s ease;
  border-radius: 23px;
}

.comments-template-multiple .nav-tabs .active a:after {
  width: 80%;
}

.comments-template-multiple .nav-tabs a:before {
  content: " ";
  display: inline-block;
  height: 56px;
  width: 1px;
  background-color: #ddd;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
}

.comments-template-multiple .nav-tabs li:last-child a:before {
  display: none;
}

.comments-template-multiple .nav-tabs .active a {
  border: none !important;
}

.comments-template-multiple .nav-tabs .fa {
  margin-right: 6px;
  font-size: 16px;
}

.comments-template-multiple .comments-count, .comments-template-multiple .disqus-comment-count {
  background-color: #cacaca;
  color: #262626;
  border-radius: 3px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: 700;
  vertical-align: middle;
  margin-left: 10px;
  transition: all 0.3s ease;
}

.comments-template-multiple .disqus-comment-count {
  width: 30px;
  display: inline-block;
  white-space: normal;
  overflow: hidden;
  height: 20px !important;
  line-height: 20px;
}

.comments-template-multiple .active .comments-count {
  color: #fff;
}

.comments-template-multiple .comments-count:empty {
  display: none;
}

.comments-template-multiple .multi-wordpress-comment-section .comment-respond:first-child > .section-heading {
  display: none;
}

.comments-template-multiple .tab-pane .comments-template, .comments-template-multiple .tab-pane .comments-template.comment-respond {
  margin-top: 10px !important;
}

.comments-template-multiple .multi-wordpress-comment-section .comment-respond:first-child {
  margin-top: 15px !important;
}

.back-top {
  color: #fff;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  text-align: center;
  line-height: 42px;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 16px;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
}

.back-top.fade-out:hover {
  opacity: 1;
}

.back-top.is-visible {
  visibility: visible;
  opacity: 0.6;
  bottom: 8px;
}

.back-top.fade-out1 {
  opacity: 0.7;
}

.back-top.fade-out2 {
  opacity: 0.8;
}

.back-top.fade-out3 {
  opacity: 0.9;
}

.back-top.fade-out4 {
  opacity: 1;
}

.back-top.dark {
  background: #383838;
  color: #fff;
}

.back-top:hover {
  opacity: 1;
}

.error404 .content-column {
  max-width: 800px !important;
  margin: 10px auto 50px;
}

.error404 .content-column .second-row {
  margin-bottom: 5px;
}

.error404 .content-column .desc-section {
  text-align: center;
}

.error404 .content-column .desc-section .title-404 {
  margin: 0 0 15px;
  color: #444;
  font-weight: bolder;
  text-align: center;
}

.error404 .content-column .text-404 {
  font-size: 130px;
  line-height: 175px;
  font-weight: 700;
  color: #444;
  text-align: center;
  margin: 0;
}

.error404 .content-column .action-links {
  margin-bottom: 10px;
  margin-top: 20px;
}

.error404 .content-column .action-links .fa {
  margin-right: 5px;
}

.error404 .content-column .top-line {
  margin-top: 15px;
  padding-top: 25px;
}

.error404 .content-column .search-form {
  position: relative;
}

.error404 .content-column .search-form label {
  width: 100%;
}

.error404 .content-column .search-form .search-field {
  width: 100%;
  border: 1px solid #cbcbcb;
  height: 34px;
  line-height: 20px;
  padding: 0 30% 0 10px;
  outline: 0;
  float: left;
  background: #fff;
}

.error404 .content-column .search-form .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  height: 34px;
  padding: 0 7%;
  line-height: 28px;
  border: none;
  text-transform: uppercase;
  color: #fff;
  margin-top: 0;
}

.error404 .content-column .search-form.have-focus .search-field {
  border-color: #9e9e9e;
}

.error404 .search-action-container {
  width: 50%;
  float: left;
  margin: 0;
}

.error404 script + .search-action-container:last-child {
  width: 100%;
  float: none;
}

@media (max-width: 768px) {
  .error404 .content-column .search-form {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.author-profile {
  margin-bottom: 29px;
}

.author-profile .post-author-avatar {
  float: left;
  margin-right: 15px;
}

.author-profile .post-author-avatar img {
  border-radius: 50%;
}

.author-profile .author-title {
  font-size: 22px;
  margin: 20px 0 0;
  text-transform: uppercase;
}

.author-profile .author-title .author-title {
  margin: 0 0 7px;
  font-size: 18px;
  text-transform: capitalize;
  padding-right: 50%;
}

.author-profile .author-title .title-counts {
  color: #8a8a8a;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 400;
}

.author-profile .author-title .title-counts:before {
  content: "-";
  margin: 0 8px;
  opacity: 0.4;
}

.author-profile .post-author-bio {
  margin: 10px auto 0 auto;
  padding-left: 115px;
}

.author-profile .post-author-bio p:last-child {
  margin-bottom: 0;
}

.author-profile .author-links {
  margin-top: 15px;
}

.author-profile .author-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.author-profile .author-links ul li {
  display: inline-block;
  background-color: #fff;
  padding: 0;
  margin: 0 10px 0 0;
  position: relative;
  z-index: 1;
  text-align: center;
}

.author-profile .author-links ul li a, .author-profile .author-links ul li > span {
  display: inline-block;
  line-height: 26px;
  height: 26px;
  width: 26px;
  background-color: #eee;
  font-size: 12px;
  color: #fff;
  vertical-align: middle;
}

.author-profile .author-links ul li.posts-count > span {
  background-color: transparent;
  color: #2d2d2d;
  border: 1px solid #d8d8d8;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  padding: 0 8px;
  text-transform: uppercase;
  line-height: 24px;
}

.author-profile .author-links ul li.facebook a {
  background-color: #4a639f;
}

.author-profile .author-links ul li.twitter a {
  background-color: #59c8ff;
}

.author-profile .author-links ul li.google-plus a {
  background-color: #d73d32;
}

.author-profile .author-links ul li.instagram a {
  background-color: #517fa4;
}

.author-profile .author-links ul li.flickr a {
  background-color: #2f44db;
}

.author-profile .author-links ul li.behance a {
  background-color: #3b63fc;
}

.author-profile .author-links ul li.soundcloud a {
  background-color: #f50;
}

.author-profile .author-links ul li.delicious a {
  background-color: #4a79ff;
}

.author-profile .author-links ul li.vimeo a {
  background-color: #46a3e9;
}

.author-profile .author-links ul li.dribbble a {
  background-color: #ea4c89;
}

.author-profile .author-links ul li.linkedin a {
  background-color: #0266a0;
}

.author-profile .author-links ul li.youtube a {
  background-color: #cc181e;
}

.author-profile .author-links ul li.pinterest a {
  background-color: #cb2027;
}

.author-profile .author-links ul li.github a {
  background-color: #666;
}

.author-profile .author-links ul li.site a {
  background-color: #666;
}

.better-gallery {
  margin: 10px 0 25px;
}

.better-gallery .gallery-title {
  color: #fff;
  padding: 10px 0 0;
  text-align: center;
  user-select: none;
}

.better-gallery p {
  margin: 0;
}

.better-gallery br {
  display: none;
}

.better-gallery .gallery-title .next, .better-gallery .gallery-title .prev {
  cursor: pointer;
  color: #373737;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.17);
  font-family: "Open Sans";
  font-size: 11px !important;
  padding: 0 11px;
  display: inline-block;
  line-height: 20px !important;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-transform: uppercase;
}

.better-gallery .gallery-title .next:hover, .better-gallery .gallery-title .prev:hover {
  color: #fff;
}

.better-gallery .gallery-title .next .fa {
  margin-left: 3px;
}

.better-gallery .gallery-title .prev .fa {
  margin-right: 3px;
}

.better-gallery .count {
  font-size: 12px;
  margin-right: 20px;
  margin-left: 20px;
  font-style: normal;
  line-height: 24px;
  display: inline-block;
  color: grey;
  vertical-align: middle;
  min-width: 46px;
}

.better-gallery .count i {
  font-style: normal;
}

.better-gallery .main-title {
  width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #373737;
  font-weight: bolder;
  text-align: center;
  font-size: 26px;
  margin-bottom: 12px;
}

.better-gallery .slide-title-wrap {
  display: block;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.better-gallery .slide-title-wrap .slide-copy, .better-gallery .slide-title-wrap .slide-title {
  padding: 4px 11px;
  background-color: rgba(20, 20, 20, 0.55);
  display: inline-block;
  font-size: 12px;
}

.better-gallery .slide-title-wrap .slide-copy {
  margin: 4px;
  font-style: italic;
}

.better-gallery .fotorama__thumb:before {
  width: 85px;
  height: 62px;
  position: relative;
  display: block;
  content: "";
  border: 2px solid rgba(255, 255, 255, 0.33);
  z-index: 8;
}

.better-gallery .fotorama__nav:before, .better-gallery .fotorama__stage:before {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.6) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.7), transparent);
  background-position: 0 0, 0 0;
  opacity: 0.4;
}

.better-gallery .fotorama__nav:after, .better-gallery .fotorama__stage:after {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.6) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.7), transparent);
  background-position: 100% 0, 100% 0;
  opacity: 0.4;
}

.better-gallery .fotorama__wrap {
  padding-top: 15px;
}

.better-gallery .count {
  font-size: 12px;
  margin-right: 20px;
  margin-left: 20px;
  font-style: normal;
  line-height: 24px;
  display: inline-block;
  color: grey;
  vertical-align: middle;
  text-transform: lowercase;
}

.better-gallery .fotorama__nav-wrap {
  margin-top: 8px;
  padding: 0 10px;
}

.better-gallery .fotorama__wrap .fotorama__html {
  height: 100%;
  text-align: center;
}

.better-gallery .fotorama__wrap .fotorama__html img {
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-box-shadow: 0 0 22px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0 0 22px rgba(0, 0, 0, 0.17);
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.17);
}

.better-gallery .fotorama__wrap .fotorama__html > div {
  height: 100%;
}

.better-gallery.better-gallery-img-full .fotorama__nav__frame {
  text-align: center;
}

.better-gallery.better-gallery-img-full .fotorama__nav__frame img {
  max-width: 100% !important;
  max-height: 100% !important;
  height: auto !important;
  width: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0;
}

.no-results {
  text-align: center;
}

.no-results > .title {
  text-align: center;
  font-size: 28px;
  text-transform: uppercase;
  position: relative;
  margin: 20px 0;
}

.no-results > .title:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  height: 2px;
  width: 100%;
  background-color: #f0f0f0;
}

.no-results > .title span {
  display: inline-block;
  padding: 0 10px;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.no-results .search-form {
  margin: 20px 0 30px;
}

.no-results .search-form input[type=submit] {
  float: none;
}

.no-results .search-form input[type=search] {
  float: none;
  width: 240px;
}

.widget > .bs-shortcode:last-child {
  margin-bottom: 0;
}

.bs-shortcode.bs-heading-shortcode {
  margin-bottom: 21px;
}

@-webkit-keyframes bs-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bs-fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes bs-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bs-fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes bs-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bs-fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes bs-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes bs-fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes bs-spin {
  0% {
    -webkit-transform: rotate(0);
    tranform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    tranform: rotate(360deg);
  }
}
@keyframes bs-spin {
  0% {
    -webkit-transform: rotate(0);
    tranform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    tranform: rotate(360deg);
  }
}
.bs-animate {
  -webkit-animation-duration: 0.55s;
  animation-duration: 0.55s;
  -webkit-animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
  animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bs-fadeInLeft {
  -webkit-animation-name: bs-fadeInLeft;
  animation-name: bs-fadeInLeft;
}

.bs-fadeInRight {
  -webkit-animation-name: bs-fadeInRight;
  animation-name: bs-fadeInRight;
}

.bs-fadeInDown {
  -webkit-animation-name: bs-fadeInDown;
  animation-name: bs-fadeInDown;
}

.bs-fadeInUp {
  -webkit-animation-name: bs-fadeInUp;
  animation-name: bs-fadeInUp;
}

.bs-spin {
  -webkit-animation-name: bs-spin;
  animation-name: bs-spin;
}

.pagination-animate > .bs-animate {
  -webkit-animation-duration: 0.55s;
  animation-duration: 0.55s;
  -webkit-animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
  animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
}

.bs-tab-anim.bs-tab-animated.active {
  -webkit-animation-duration: 0.55s;
  animation-duration: 0.55s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bs-fadeInDown;
  animation-name: bs-fadeInDown;
  -webkit-animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
  animation-timing-function: cubic-bezier(0.55, -0.01, 0.19, 0.98);
}

.bs-loading, .bs-loading > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.bs-loading {
  display: block;
  font-size: 0;
  color: #333;
}

.bs-loading > div {
  display: inline-block;
  float: none;
}

.bs-loading {
  width: 54px;
  height: 54px;
}

.bs-loading > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  -webkit-animation-name: bs-loading-keyframe;
  -moz-animation-name: bs-loading-keyframe;
  -o-animation-name: bs-loading-keyframe;
  animation-name: bs-loading-keyframe;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.bs-loading > div:nth-child(1) {
  -webkit-animation-duration: 0.65s;
  -moz-animation-duration: 0.65s;
  -o-animation-duration: 0.65s;
  animation-duration: 0.65s;
  -webkit-animation-delay: 0.03s;
  -moz-animation-delay: 0.03s;
  -o-animation-delay: 0.03s;
  animation-delay: 0.03s;
}

.bs-loading > div:nth-child(2) {
  -webkit-animation-duration: 1.02s;
  -moz-animation-duration: 1.02s;
  -o-animation-duration: 1.02s;
  animation-duration: 1.02s;
  -webkit-animation-delay: 0.09s;
  -moz-animation-delay: 0.09s;
  -o-animation-delay: 0.09s;
  animation-delay: 0.09s;
}

.bs-loading > div:nth-child(3) {
  -webkit-animation-duration: 1.06s;
  -moz-animation-duration: 1.06s;
  -o-animation-duration: 1.06s;
  animation-duration: 1.06s;
  -webkit-animation-delay: -0.69s;
  -moz-animation-delay: -0.69s;
  -o-animation-delay: -0.69s;
  animation-delay: -0.69s;
}

.bs-loading > div:nth-child(4) {
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  -o-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: -0.41s;
  -moz-animation-delay: -0.41s;
  -o-animation-delay: -0.41s;
  animation-delay: -0.41s;
}

.bs-loading > div:nth-child(5) {
  -webkit-animation-duration: 1.6s;
  -moz-animation-duration: 1.6s;
  -o-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-delay: 0.04s;
  -moz-animation-delay: 0.04s;
  -o-animation-delay: 0.04s;
  animation-delay: 0.04s;
}

.bs-loading > div:nth-child(6) {
  -webkit-animation-duration: 0.84s;
  -moz-animation-duration: 0.84s;
  -o-animation-duration: 0.84s;
  animation-duration: 0.84s;
  -webkit-animation-delay: 0.07s;
  -moz-animation-delay: 0.07s;
  -o-animation-delay: 0.07s;
  animation-delay: 0.07s;
}

.bs-loading > div:nth-child(7) {
  -webkit-animation-duration: 0.68s;
  -moz-animation-duration: 0.68s;
  -o-animation-duration: 0.68s;
  animation-duration: 0.68s;
  -webkit-animation-delay: -0.66s;
  -moz-animation-delay: -0.66s;
  -o-animation-delay: -0.66s;
  animation-delay: -0.66s;
}

.bs-loading > div:nth-child(8) {
  -webkit-animation-duration: 0.93s;
  -moz-animation-duration: 0.93s;
  -o-animation-duration: 0.93s;
  animation-duration: 0.93s;
  -webkit-animation-delay: -0.76s;
  -moz-animation-delay: -0.76s;
  -o-animation-delay: -0.76s;
  animation-delay: -0.76s;
}

.bs-loading > div:nth-child(9) {
  -webkit-animation-duration: 1.24s;
  -moz-animation-duration: 1.24s;
  -o-animation-duration: 1.24s;
  animation-duration: 1.24s;
  -webkit-animation-delay: -0.76s;
  -moz-animation-delay: -0.76s;
  -o-animation-delay: -0.76s;
  animation-delay: -0.76s;
}

@-webkit-keyframes bs-loading-keyframe {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -webkit-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes bs-loading-keyframe {
  0% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -moz-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes bs-loading-keyframe {
  0% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -o-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bs-loading-keyframe {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -webkit-transform: scale(0.45);
    -moz-transform: scale(0.45);
    -o-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.deferred-loading-container {
  position: relative;
}

.bs-pagination-wrapper:focus {
  outline: 0 !important;
}

.bs-pagin-loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.bs-pagin-loading-wrapper .bs-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -27px;
  margin-left: -27px;
}

.bs-pagination-wrapper .bs-pagination-in-loading {
  opacity: 0.15;
  pointer-events: none;
}

.bs-pagination {
  margin: 15px 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.post-related .bs-pagination {
  margin-top: 0;
}

.bs-pagination-label {
  color: #9c9c9c;
  color: rgba(138, 138, 138, 0.83);
  font-size: 11px;
  margin-left: 4px;
}

.btn-bs-pagination {
  border: 1px solid #d4d4d4;
  border: 1px solid rgba(138, 138, 138, 0.33);
  color: #9c9c9c !important;
  color: rgba(138, 138, 138, 0.83) !important;
  font-size: 11px !important;
  padding: 0 6px;
  display: inline-block;
  line-height: 22px !important;
  min-width: 26px;
  text-align: center !important;
  cursor: pointer;
  text-transform: uppercase !important;
}

.btn-bs-pagination.bs-pagination-in-loading, .btn-bs-pagination.hover, .btn-bs-pagination:hover {
  color: #fff !important;
}

body .bs-pagination a.btn-bs-pagination.disabled:hover {
  border: 1px solid #d4d4d4 !important;
  border: 1px solid rgba(138, 138, 138, 0.33) !important;
  color: #9c9c9c !important;
  color: rgba(138, 138, 138, 0.83) !important;
  background-color: transparent !important;
  cursor: default;
}

.btn-bs-pagination.disabled, .btn-bs-pagination.disabled:hover {
  opacity: 0.5;
}

.btn-bs-pagination .fa {
  font-weight: 700;
}

.btn-bs-pagination.next .fa {
  margin-left: 2px;
}

.btn-bs-pagination.prev .fa {
  margin-right: 2px;
}

.bs-pagination-wrapper {
  position: relative;
}

.btn-bs-pagination .loading .fa {
  margin-right: 2px;
}

.btn-bs-pagination .loaded .fa {
  margin-left: 2px;
}

.bs-pagination.bs-ajax-pagination.infinity, .bs-pagination.bs-ajax-pagination.more_btn, .bs-pagination.bs-ajax-pagination.more_btn_infinity {
  text-align: center;
}

.bs-pagination.bs-ajax-pagination.infinity .btn-bs-pagination, .bs-pagination.bs-ajax-pagination.more_btn .btn-bs-pagination, .bs-pagination.bs-ajax-pagination.more_btn_infinity .btn-bs-pagination {
  line-height: 26px !important;
  padding: 0 7px;
}

.bs-pagination.bs-simple-pagination .btn-bs-pagination.prev .fa {
  margin: 0 0 0 2px;
}

.bs-pagination.bs-simple-pagination .btn-bs-pagination.next .fa {
  margin: 0 2px 0 0;
}

.bs-listing-listing-classic-2 .bs-pagination, .bs-listing-listing-classic-2 .bs-pagination .pagination, .bs-listing-listing-classic-3 .bs-pagination, .bs-listing-listing-classic-3 .bs-pagination .pagination, .bs-listing-listing-grid-1 .bs-pagination, .bs-listing-listing-grid-1 .bs-pagination .pagination, .bs-listing-listing-grid-2 .bs-pagination, .bs-listing-listing-grid-2 .bs-pagination .pagination, .bs-listing-listing-mix-3-2 .bs-pagination, .bs-listing-listing-mix-3-2 .bs-pagination .pagination, .bs-listing-listing-mix-4-1 .bs-pagination, .bs-listing-listing-mix-4-1 .bs-pagination .pagination, .bs-listing-listing-mix-4-2 .bs-pagination, .bs-listing-listing-mix-4-2 .bs-pagination .pagination, .bs-listing-listing-mix-4-3 .bs-pagination, .bs-listing-listing-mix-4-3 .bs-pagination .pagination, .bs-listing-listing-mix-4-4 .bs-pagination, .bs-listing-listing-mix-4-4 .bs-pagination .pagination, .bs-listing-listing-mix-4-5 .bs-pagination, .bs-listing-listing-mix-4-5 .bs-pagination .pagination, .bs-listing-listing-mix-4-6 .bs-pagination, .bs-listing-listing-mix-4-6 .bs-pagination .pagination, .bs-listing-listing-tall-1 .bs-pagination, .bs-listing-listing-tall-1 .bs-pagination .pagination, .bs-listing-listing-tall-2 .bs-pagination, .bs-listing-listing-tall-2 .bs-pagination .pagination, .bs-listing-listing-text-1 .bs-pagination, .bs-listing-listing-text-1 .bs-pagination .pagination, .bs-listing-listing-thumbnail-1 .bs-pagination, .bs-listing-listing-thumbnail-1 .bs-pagination .pagination, .bs-listing-listing-thumbnail-2 .bs-pagination, .bs-listing-listing-thumbnail-2 .bs-pagination .pagination, .bs-listing-listing-thumbnail-3 .bs-pagination, .bs-listing-listing-thumbnail-3 .bs-pagination .pagination, .bs-listing-modern-grid-listing-3 .bs-pagination, .bs-listing-modern-grid-listing-3 .bs-pagination .pagination {
  margin-top: 0;
}

.bs-slider-controls .btn-bs-pagination.next {
  margin-left: 5px;
}

.bs-slider-items-container > .bs-slider-item {
  display: none;
}

.bs-slider-items-container .bs-slider-item.bs-slider-first-item {
  display: block;
}

.bs-slider-items-container .bs-control-nav-style-2 .btn-bs-pagination.next {
  float: right;
}

.bs-slider-items-container .bs-control-nav-style-3 {
  text-align: center;
}

.bs-listing.bs-listing-single-tab .bs-slider-items-container .bs-control-nav-style-4 .btn-bs-pagination {
  line-height: 22px;
  min-width: 24px;
  padding: 0 6px;
  border-radius: 50%;
}

.bs-slider-dots {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.bs-slider-dots li {
  display: inline-block;
}

.bs-slider-dots .bts-bs-dots-btn {
  cursor: pointer;
}

.bs-slider-dots-style-1, .bs-slider-dots-style-2, .bs-slider-dots-style-3, .bs-slider-dots-style-4 {
  text-align: center;
  line-height: 27px;
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
}

.bs-control-nav + .bs-slider-dots-style-1, .bs-control-nav + .bs-slider-dots-style-2, .bs-control-nav + .bs-slider-dots-style-3, .bs-control-nav + .bs-slider-dots-style-4 {
  bottom: 0;
}

.bs-control-nav-style-4 + .bs-slider-dots-style-1, .bs-control-nav-style-4 + .bs-slider-dots-style-2, .bs-control-nav-style-4 + .bs-slider-dots-style-3, .bs-control-nav-style-4 + .bs-slider-dots-style-4 {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.bs-slider-dots-style-1 .bts-bs-dots-btn, .bs-slider-dots-style-2 .bts-bs-dots-btn, .bs-slider-dots-style-3 .bts-bs-dots-btn, .bs-slider-dots-style-4 .bts-bs-dots-btn {
  display: inline-block;
  height: 8px;
  width: 8px;
  text-indent: -9999px;
  overflow: hidden;
  background: #eee;
  background: rgba(0, 0, 0, 0.2);
  margin: 0 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  pointer-events: all;
}

.bs-slider-dots-style-1 .bts-bs-dots-btn {
  border-radius: 50%;
}

.bs-slider-dots-style-3 .bts-bs-dots-btn {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bs-slider-dots-style-4 .bts-bs-dots-btn {
  width: 12px;
  height: 4px;
}

.bs-simple-pagination.simple_next_prev .btn-bs-pagination.prev {
  float: right;
}

.bs-listing-listing-blog-1 .bs-slider-controls, .bs-listing-listing-blog-2 .bs-slider-controls, .bs-listing-listing-blog-3 .bs-slider-controls, .bs-listing-listing-blog-4 .bs-slider-controls, .bs-listing-listing-classic-1 .bs-slider-controls, .bs-listing-listing-classic-3 .bs-slider-controls, .bs-listing-listing-mix-1-1 .bs-slider-controls, .bs-listing-listing-mix-1-3 .bs-slider-controls, .bs-listing-listing-mix-1-4 .bs-slider-controls, .bs-listing-listing-mix-3-1 .bs-slider-controls, .bs-listing-listing-mix-3-3 .bs-slider-controls, .bs-listing-listing-mix-4-7 .bs-slider-controls, .bs-listing-listing-mix-4-8 .bs-slider-controls, .bs-listing-listing-text-1 .bs-slider-controls, .bs-listing-listing-text-2 .bs-slider-controls, .bs-listing-listing-text-3 .bs-slider-controls, .bs-listing-listing-text-4 .bs-slider-controls, .bs-listing-modern-grid-listing-1 .bs-slider-controls, .bs-listing-modern-grid-listing-2 .bs-slider-controls, .bs-listing-modern-grid-listing-5 .bs-slider-controls {
  height: 27px;
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-mix-1-4 .bs-slider-controls, .bs-listing-modern-grid-listing-5 .bs-slider-controls {
  margin-top: 0;
}

.bs-listing-listing-text-1 .bs-slider-controls, .bs-listing-listing-text-3 .bs-slider-controls, .bs-listing-listing-text-4 .bs-slider-controls {
  margin-top: 0;
}

.bs-listing-modern-grid-listing-1 .bs-slider-controls, .bs-listing-modern-grid-listing-2 .bs-slider-controls {
  margin-top: 10px;
}

.bs-listing-modern-grid-listing-6 .bs-slider-controls {
  height: 27px;
  margin-bottom: -10px;
  margin-top: 5px;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-mix-2-2 .bs-slider-controls {
  height: 27px;
  margin-top: 15px;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-thumbnail-1 .bs-slider-controls {
  height: 27px;
  margin-top: -5px;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-thumbnail-2 .bs-slider-controls {
  height: 27px;
  margin-top: 0;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-classic-2 .bs-slider-controls {
  height: 27px;
  margin-top: -5px;
  position: relative;
  z-index: 1;
}

.bs-listing-modern-grid-listing-4 .bs-slider-controls {
  height: 27px;
}

.bs-listing-listing-tall-1 .bs-slider-controls, .bs-listing-listing-tall-2 .bs-slider-controls {
  height: 27px;
  margin-top: 5px;
  position: relative;
  z-index: 1;
}

.bs-listing-modern-grid-listing-3 .bs-slider-controls {
  height: 27px;
  margin-top: -10px;
  position: relative;
  z-index: 1;
}

.bs-listing-listing-grid-1 .bs-slider-controls, .bs-listing-listing-grid-2 .bs-slider-controls {
  height: 27px;
  margin-top: -7px;
  position: relative;
  z-index: 1;
}

.listing-mix-4-7 + .bs-pagination-response, .listing-mix-4-7 + .bs-pagination-response ~ .bs-pagination-response, .listing-mix-4-8 + .bs-pagination-response, .listing-mix-4-8 + .bs-pagination-response ~ .bs-pagination-response {
  margin-top: 20px;
}

.dropcap.dropcap {
  display: inline-block;
  float: left;
  margin: 0 8px -10px 0;
  font-size: 74px;
  line-height: 74px;
  height: 74px;
  text-transform: uppercase;
}

.dropcap.dropcap-square {
  color: #fff;
  padding: 0 11px;
}

.dropcap.dropcap-square-outline {
  border: 2px solid transparent;
  padding: 0 11px;
  line-height: 70px;
}

.dropcap.dropcap-circle {
  color: #fff;
  padding: 0 11px;
  border-radius: 50%;
  min-width: 74px;
  font-size: 52px;
  text-align: center;
}

.dropcap.dropcap-circle-outline {
  border: 2px solid transparent;
  padding: 0 11px;
  border-radius: 50%;
  min-width: 74px;
  font-size: 52px;
  text-align: center;
  line-height: 72px;
}

.bs-highlight {
  background-color: #ff9;
  padding: 0 3px;
}

.bs-highlight.bs-highlight-red {
  background-color: #ffb6b6;
}

.bs-tab-shortcode {
  margin-bottom: 20px;
}

.bs-tab-shortcode .nav.nav-tabs {
  border: none;
  padding: 0;
  margin: 0;
}

.bs-tab-shortcode .nav-tabs > li > a {
  background-color: #f3f4f6;
  border: 1px solid #d6d6d6 !important;
  padding: 9px 22px;
  margin-right: -1px;
  border-radius: 0;
  color: #4a4a4a !important;
  font-weight: bolder;
  position: relative;
}

.bs-tab-shortcode .nav-tabs > li > a:after {
  display: inline-block;
  content: "";
  height: 1px;
  left: 0;
  right: 0;
  bottom: -1px;
  background: 0 0;
  position: absolute;
}

.bs-tab-shortcode .nav-tabs > li.active > a, .bs-tab-shortcode .nav-tabs > li > a:focus, .bs-tab-shortcode .nav-tabs > li > a:hover {
  text-decoration: none !important;
  background-color: #fff;
}

.bs-tab-shortcode .nav-tabs > li.active > a:after {
  background-color: #fff;
}

.bs-tab-shortcode .tab-content .tab-pane {
  padding: 20px;
  border: 1px solid #d6d6d6;
}

.bs-row-shortcode {
  margin-bottom: 20px;
}

.bs-accordion-shortcode .panel {
  box-shadow: none;
  border: 1px solid #d6d6d6;
  -webkit-transition: all 0.07s ease;
  -moz-transition: all 0.07s ease;
  -o-transition: all 0.07s ease;
  transition: all 0.07s ease;
  border-radius: 0 !important;
}

.bs-accordion-shortcode .panel-heading {
  padding: 0 0 0 60px;
  position: relative;
}

.bs-accordion-shortcode .panel-heading a {
  color: #585858;
}

.bs-accordion-shortcode .panel-heading a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f067";
  margin: 0 10px 0 0;
  font-size: 14px;
  position: absolute;
  left: 0;
  width: 44px;
  line-height: 44px;
  top: 0;
  bottom: 0;
  text-align: center;
  border-right: 1px solid #d6d6d6;
}

.bs-accordion-shortcode .panel-heading.active a:before {
  content: "\f068";
}

.bs-accordion-shortcode .panel-heading *, .bs-accordion-shortcode .panel-heading .panel-title br {
  display: none;
}

.bs-accordion-shortcode .panel-heading .panel-title, .bs-accordion-shortcode .panel-heading .panel-title a {
  display: block;
}

.bs-accordion-shortcode .panel + .panel {
  margin-top: 12px !important;
}

.bs-accordion-shortcode .panel-heading .panel-title a {
  padding: 12px 0;
  font-size: 14px;
}

.bs-accordion-shortcode .panel-heading + .panel-collapse > .panel-body {
  -webkit-transition: all 0.07s ease;
  -moz-transition: all 0.07s ease;
  -o-transition: all 0.07s ease;
  transition: all 0.07s ease;
}

.bs-accordion-shortcode .panel .panel-heading a {
  color: #444 !important;
}

.bs-accordion-shortcode .panel .panel-heading a, .bs-accordion-shortcode .panel .panel-heading a:hover {
  text-decoration: none;
}

ul.bs-shortcode-list, ul.bs-shortcode-list ul {
  list-style: none !important;
  padding-left: 20px;
  overflow: hidden;
}

ul.bs-shortcode-list ul {
  padding-left: 13px;
}

ul.bs-shortcode-list li {
  position: relative;
  margin-bottom: 7px;
  padding-left: 25px;
}

ul.bs-shortcode-list li:before {
  width: 25px;
  content: "\f00c";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 0;
  top: 6px;
}

ul.bs-shortcode-list li:empty {
  display: none;
}

ul.bs-shortcode-list.list-style-check li:before {
  content: "\f00c";
}

ul.bs-shortcode-list.list-style-star li:before {
  content: "\f005";
}

ul.bs-shortcode-list.list-style-edit li:before {
  content: "\f044";
}

ul.bs-shortcode-list.list-style-folder li:before {
  content: "\f07b";
}

ul.bs-shortcode-list.list-style-file li:before {
  content: "\f15b";
}

ul.bs-shortcode-list.list-style-heart li:before {
  content: "\f004";
}

ul.bs-shortcode-list.list-style-asterisk li:before {
  content: "\f069";
}

hr.bs-divider {
  height: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: #dbdbdb;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  margin: 30px auto 28px;
  position: relative;
  width: 90%;
}

hr.bs-divider.dashed-line {
  border-style: dashed;
  border-width: 1px;
}

hr.bs-divider.full {
  width: 100%;
}

hr.bs-divider.large {
  width: 90%;
}

hr.bs-divider.small {
  width: 70%;
}

hr.bs-divider.tiny {
  width: 50%;
}

hr.bs-divider + h1, hr.bs-divider + .h1, hr.bs-divider + h2, hr.bs-divider + .h2, hr.bs-divider + h3, hr.bs-divider + .h3 {
  margin-top: -10px;
}

hr.bs-divider + h5, hr.bs-divider + .h5 {
  margin-top: -5px;
}

h1 + hr.bs-divider, .h1 + hr.bs-divider, h2 + hr.bs-divider, .h2 + hr.bs-divider, h3 + hr.bs-divider, .h3 + hr.bs-divider, h4 + hr.bs-divider, .h4 + hr.bs-divider, h5 + hr.bs-divider, .h5 + hr.bs-divider {
  margin-top: 28px;
}

.bs-shortcode-alert.alert {
  border-radius: 0;
}

.bs-shortcode-alert.alert-simple {
  background-color: #f3f3f3;
  border-color: #e6e6e6;
}

.listing-item-blog.sticky, .listing-item-blog.sticky:first-child {
  background-color: #fffbdf;
  padding: 18px;
  border: 1px solid rgba(0, 0, 0, 0.07);
}

.vc_images_carousel:hover .vc_carousel-control .icon-next, .vc_images_carousel:hover .vc_carousel-control .icon-prev {
  background: #fff;
}

.vc_images_carousel .vc_carousel-inner .vc_carousel-slideline .vc_carousel-slideline-inner > .vc_item {
  height: 0;
}

.vc_images_carousel .vc_carousel-control .icon-next.icon-next:before, .vc_images_carousel .vc_carousel-control .icon-prev.icon-prev:before {
  top: -11px;
  line-height: 30px;
  color: #333;
  text-shadow: none;
}

.vc_images_carousel .vc_carousel-control .icon-next.icon-next:before {
  right: -1px;
}

.vc_images_carousel .vc_carousel-control .icon-prev.icon-prev:before {
  left: -1px;
}

.wpb_gallery > .wpb_wrapper > .wpb_flexslider {
  padding: 0;
  margin: 0 0 30px;
  background: 0 0;
  border-radius: 0;
  box-shadow: none;
  zoom: 1;
  height: auto !important;
}

.wpb_flexslider .flex-direction-nav a {
  width: 30px;
  height: 30px;
}

.wpb_flexslider .flex-direction-nav a:before {
  font-family: flexslider-icon;
  font-size: 14px;
  display: inline-block;
  content: "\f001";
  text-shadow: none;
  line-height: 30px;
  height: 30px;
}

.wpb_gallery .wpb_flexslider .flex-direction-nav a {
  border: none;
  background: #fff;
  text-align: center;
  line-height: 30px;
}

.wpb_gmaps_widget .wpb_wrapper {
  padding: 0 !important;
}

.vc_btn3 {
  height: auto !important;
}

.wpcf7-form p {
  margin-bottom: 0;
  margin-top: 17px;
}

.wpcf7-form p:first-child {
  margin-top: 0;
}

.wpcf7-form label {
  display: block;
}

.archive-title.search-title.search-bgcs-title {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.better-gcs-wrapper {
  border-top: none;
  padding-top: 30px !important;
  margin-top: -30px;
}

.better-gcs-wrapper {
  padding-top: 30px !important;
}

.bs-pretty-tabs-container {
  position: relative;
  display: inline-block;
  float: right;
  text-align: center;
  z-index: 2;
}

.bs-pretty-tabs-container .bs-pretty-tabs-more {
  z-index: 3;
  position: relative;
  cursor: pointer;
}

.bs-pretty-tabs-container .bs-pretty-tabs-elements .other-link {
  display: block;
  float: none;
  width: 100%;
}

.bs-pretty-tabs-container .bs-pretty-tabs-elements .h-text {
  border: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 3px 10px;
  text-align: right;
}

.bs-pretty-tabs-container .bs-pretty-tabs-elements {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  z-index: 2;
  white-space: nowrap;
  border: 1px solid #d8d8d8;
  display: none;
  padding: 5px;
  min-width: 160px;
}

.bs-pretty-tabs .bs-pretty-tabs-more.other-link {
  pointer-events: none;
}

.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  position: relative;
  cursor: pointer;
  padding: 3px 10px;
  border: 1px solid transparent;
}

.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text {
  transition: none;
}

.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text .fa {
  margin-left: 3px;
}

.bs-pretty-tabs.bs-pretty-tabs-initialized .bs-pretty-tabs-container:hover .bs-pretty-tabs-more.other-link .h-text:after {
  display: block;
}

.bs-pretty-tabs-container.display-more-items .bs-pretty-tabs-elements {
  display: block;
}

.bs-pretty-tabs .bs-pretty-tabs-more.other-link:hover .h-text {
  background: 0 0 !important;
  color: currentColor !important;
  border-color: rgba(0, 0, 0, 0.13) !important;
}

.section-heading.multi-tab .other-link {
  opacity: 0;
  -webkit-transition: color 0.7s ease, background 0.7s ease;
  -moz-transition: color 0.7s ease, background 0.7s ease;
  -o-transition: color 0.7s ease, background 0.7s ease;
  transition: color 0.7s ease, background 0.7s ease;
  position: absolute;
}

.section-heading.multi-tab .bs-pretty-tabs-container {
  padding-left: 10px;
}

.bs-pretty-tabs.bs-pretty-tabs-initialized .other-link {
  opacity: 1;
  position: relative;
}

.bs-slider-1-item .rating-stars, .listing-mg-1-item .rating-stars, .listing-mg-2-item .rating-stars, .listing-mg-3-item .rating-stars, .listing-mg-4-item .rating-stars, .listing-mg-6-item .rating-stars {
  color: #fff;
}

.bs-slider-1-item .post-meta .rating-bar, .listing-mg-1-item .post-meta .rating-bar, .listing-mg-2-item .post-meta .rating-bar, .listing-mg-3-item .post-meta .rating-bar, .listing-mg-4-item .post-meta .rating-bar, .listing-mg-6-item .post-meta .rating-bar {
  background-color: #fff;
}

.bs-slider-item .post-meta .rating, .listing-item-tall-2 .post-meta .rating, .listing-item-text-1 .post-meta .rating, .listing-mg-3-item .post-meta .rating, .listing-mg-4-item .post-meta .rating, .listing-mg-5-item-big .post-meta .rating {
  float: none;
  vertical-align: middle;
  margin-top: 0;
  margin-right: 10px;
}

.listing-item-tall-2 .post-meta .comments, .listing-item-text-1 .post-meta .comments {
  float: none;
  margin-left: 0;
}

.search-container {
  position: relative;
}

.search-container.open.result-results-exist .search-preview {
  display: block;
}

.search-preview {
  display: none;
  border: 1px solid #dedede;
  position: absolute;
  top: 100%;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1);
  font-weight: 400;
  color: #383838;
  line-height: 1;
  z-index: 99;
  text-align: left;
}

.search-preview [data-section-name] {
  max-height: 310px;
}

.search-preview .clean-title {
  font-size: 16px;
  margin-bottom: 15px;
}

.search-preview .listing-item-thumbnail:last-child, .search-preview ul.products {
  margin-bottom: 0;
}

.ajax-search-results {
  background: inherit;
}

.ajax-search-results .search-404 {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}

.ajax-search-results .clean-button {
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-results-exist .search-preview {
  border-top: 0;
}

.ajax-search-no-product .ajax-ajax-posts-list, .two-column-results-list {
  padding: 21px;
  position: relative;
}

.two-column-results-list > div {
  width: 50%;
  float: left;
  background: inherit;
  padding-right: 3%;
}

.two-column-results-list > div:last-child {
  padding-right: 0;
}

.two-column-results-list > .ajax-posts-columns {
  width: 45%;
}

.two-column-results-list > .ajax-products-columns {
  width: 55%;
}

.ajax-search-loading {
  display: block;
  text-align: center;
  color: #a6a6a6;
}

.ajax-ajax-posts-list {
  overflow: hidden;
  background: inherit;
  display: table-cell;
}

.ajax-ajax-posts-list .ajax-search-loading, .ajax-ajax-posts-list .align-vertical-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: 0;
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  color: #afafaf;
}

.ajax-taxonomy-list .ajax-search-loading, .ajax-taxonomy-list .align-vertical-center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: 0;
  height: 18px;
  line-height: 18px;
  font-size: 14px;
  color: #afafaf;
}

.ajax-ajax-posts-list .ajax-taxonomy-list {
  margin-top: 30px;
}

.ajax-ajax-posts-list .listing-thumbnail {
  margin-bottom: 20px;
  -webkit-transform: scale3d(0.97, 0.97, 0.97);
  transform: scale3d(0.97, 0.97, 0.97);
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bs-fadeInDown;
  animation-name: bs-fadeInDown;
}

.search-preview .bs-listing-products {
  margin-bottom: 28px;
}

.ajax-taxonomy-list {
  background: #fbfbfb;
  border-left: 1px solid #e2e2e2;
  display: table-cell;
  overflow: hidden;
  width: 30%;
  padding: 25px;
}

.ajax-products-columns .add_to_cart_button {
  display: none !important;
}

.ajax-products-columns .price {
  margin-top: 19px;
}

.ajax-products-columns .star-rating + .price {
  margin-top: 0;
}

.ajax-products-columns ul.products li.product {
  margin-bottom: 1px;
}

.search-preview .post-categories {
  margin: 13px 0 0;
  padding: 0;
  list-style: none;
  -webkit-transform: scale3d(0.97, 0.97, 0.97);
  transform: scale3d(0.97, 0.97, 0.97);
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bs-fadeInDown;
  animation-name: bs-fadeInDown;
}

.search-preview .post-categories li {
  display: inline-block;
  margin: 0 0 0 5px;
}

.ajax-search-results {
  display: table-row;
}

.ajax-search-results-wrapper {
  display: table;
  background: inherit;
  width: 100%;
  height: 350px;
}

.ajax-search-results:after {
  content: " ";
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  height: 3px;
}

.ajax-categories-columns {
  background: inherit;
  margin-bottom: 30px;
}

.ajax-tags-columns {
  background: inherit;
}

.ajax-search-no-product .ajax-taxonomy-list > div {
  width: 100%;
  float: none;
  background: inherit;
  height: 152px;
  margin-bottom: 0;
  position: relative;
}

.ajax-search-no-product .ajax-taxonomy-list {
  width: 100%;
}

.ajax-search-no-product .ajax-ajax-posts-list {
  width: 53%;
}

.ajax-search-no-product.ajax-search-fullwidth .ajax-ajax-posts-list {
  width: 69%;
  position: relative;
}

.ajax-search-no-product.ajax-search-fullwidth .two-column-results-list {
  padding: 5px;
}

.ajax-search-no-product.ajax-search-fullwidth .listing-thumbnail {
  margin-bottom: 0;
}

.ajax-posts-column {
  background: inherit;
}

.search-preview .woocommerce ul.products li.product, .search-preview .woocommerce-page ul.products li.product {
  font-size: 14px;
}

.search-preview .woocommerce ul.products li.product h3, .search-preview .woocommerce ul.products li.product .h3 {
  font-size: 1em;
}

.ajax-posts-columns {
  background: inherit;
}

.clean-title {
  position: relative;
  background: inherit;
  margin-bottom: 8px;
}

.clean-title > span {
  position: relative;
  z-index: 30;
  background: inherit;
  display: inline-block;
  padding: 0 15px;
  margin-left: -10px;
}

.clean-title::after {
  position: absolute;
  z-index: 20;
  width: 100%;
  top: 50%;
  height: 1px;
  background: #e5e5e5;
  display: block;
  content: " ";
}

.clean-button {
  border-radius: 0;
  border: 1px solid #e2e2e2;
  background: #f9f9f9;
  color: #383838;
  font-size: 13px;
  font-weight: 600;
  padding: 6px 9px;
  margin: 0 1px 5px;
  display: inline-block;
}

.clean-button-light {
  background: #fff;
}

.clean-button .fa {
  margin-right: 6px;
}

@media only screen and (max-width: 1000px) {
  .container {
    width: 100%;
  }
  .content-wrap {
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .slider-container .content-wrap {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 420px) {
  .widget {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 668px) {
  body {
    padding: 0;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .bs-visible-md {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .bs-visible-sm {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .bs-visible-lg {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .bs-hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .bs-hidden-md {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .bs-hidden-sm, .bs-hidden-xs {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .bs-hidden-lg {
    display: none !important;
  }
}
.ajax-post-content {
  margin-top: 30px;
}

.ajax-post-content .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.ajax-post-content .single-post-title {
  margin: 10px 0 5px;
}

.ajax-post-content .single-featured.single-featured {
  margin-left: 0;
  margin-right: 0;
}

.ajax-post-content .post-meta {
  margin: 0 0 10px;
}

.ajax-post-content .comments-template.comments-template, .ajax-post-content .post-related.post-related, .ajax-post-content .single-container.single-container {
  padding-right: 0;
  padding-left: 0;
}

.ajax-post-content {
  max-width: 856px;
  margin-left: auto;
  margin-right: auto;
}

.ajax-post-content .post-header-inner .single-post-excerpt {
  margin-top: -5px;
  padding-bottom: 10px;
}

.ajax-post-content .post-header-inner .single-post-excerpt:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.ajax-post-content .post-header-inner .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.ajax-post-content .post-header-inner .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

.modal.sign-in-modal {
  text-align: center;
  padding: 0 !important;
}

.modal.sign-in-modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.sign-in-modal .modal-content {
  border: none;
  border-radius: 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.31);
}

.sign-in-modal .modal-header {
  padding: 0;
  border-bottom: 1px solid #efefef;
  line-height: 48px;
  text-align: center;
}

.sign-in-modal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 440px;
  position: relative;
  -webkit-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  -moz-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  -o-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
}

.sign-in-modal .close-modal {
  position: absolute;
  top: 32px;
  right: 37px;
  font-size: 15px;
  color: #bdbdbd;
  line-height: 15px;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  -moz-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  -o-transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
  transition: 0.6s cubic-bezier(0.58, 0.99, 0.63, 1.06) 0s !important;
}

.sign-in-modal .close-modal:hover {
  color: #333;
}

.sign-in-modal .modal-body {
  padding: 25px 15px;
}

.bs-login {
  max-width: 290px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.bs-login .bs-login-panel {
  display: block;
  width: 100%;
  -webkit-transition: all 0.44s cubic-bezier(0.8, 0.2, 0.24, 0.88);
  -moz-transition: all 0.44s cubic-bezier(0.8, 0.2, 0.24, 0.88);
  -ms-transition: all 0.44s cubic-bezier(0.8, 0.2, 0.24, 0.88);
  transition: all 0.44s cubic-bezier(0.8, 0.2, 0.24, 0.88);
}

.bs-login.inmove .bs-login-panel {
  position: absolute;
  top: 0;
}

.bs-login .bs-login-panel.bs-current-login-panel.bs-login-sign-panel {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.bs-login .bs-login-panel.bs-login-sign-panel {
  left: -44px;
  opacity: 0;
}

.bs-login .bs-login-panel.bs-login-reset-panel {
  right: -44px;
  visibility: hidden;
  position: absolute;
  top: 0;
  opacity: 0;
}

.bs-login .bs-login-panel.bs-current-login-panel.bs-login-reset-panel {
  right: 0;
  opacity: 1;
  visibility: visible;
}

.bs-login .login-header {
  text-align: center;
  color: #7a7a7a;
  font-size: 14px;
  margin-bottom: 15px;
}

.bs-login .login-header:last-child {
  margin-bottom: 0;
}

.bs-login .login-header .login-icon {
  display: block;
  margin-bottom: 10px;
  font-size: 64px;
  line-height: 80px;
}

.bs-login .bs-login-reset-panel .login-header {
  margin-bottom: 27px;
}

.bs-login .bs-login-reset-panel .login-header .login-icon {
  color: #494949;
}

.bs-login .bs-login-reset-panel .login-header p {
  margin-bottom: 4px;
}

.bs-login .login-field {
  margin-bottom: 15px;
}

.bs-login .login-field:last-child {
  margin-bottom: 0;
}

.bs-login input[type=password], .bs-login input[type=text] {
  width: 100%;
  height: 36px;
  line-height: 36px;
  border-radius: 3px;
}

.bs-login .remember-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.bs-login .remember-checkbox + .remember-label {
  padding-left: 21px;
  height: 16px;
  display: inline-block;
  line-height: 16px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
  color: #a0a0a0;
  font-weight: lighter;
}

.bs-login .remember-checkbox:checked + .remember-label {
  background-position: 0 -16px;
  color: #424242;
}

.bs-login .remember-label {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAgCAYAAAAbifjMAAAABGdBTUEAALGPC/xhBQAAAVZJREFUSA3tlTFug0AQRQcwMgoFEg2uXbnlCJyF0kdIAU1a38A14hb0Ri6ho0UUpDOFEdrMR4EQgyIIRYp4pZVgdt6fgd2ZleI4ViVJeuPpNk1j0oyhKMq7EOLM83UDWNf1436/f1FVdQZOVNe1mWXZ8Xa7EV2v1/J+v7PYsgEGrIy050YepgcGrDw0/ub5KUD0/Af8D1AYfLYXnyEwYGWugDMXRrVEBL5gwEpry3n1Nv63fpCmKbmuS3mef2375XKZ1cuSJBGWZQkmxeFwEGhpYEe7MHUeENlxHCqKoo3MTZhk+RMdZhCGodA0TZxOpz6rYWREt21blGXZroOlTqCqKsGNsk0RjhDhyGK32/W2IQyFbwIw+L7fO0PEMIz+/RGeFIDR87wegshj2vDpxiiDbmEoMhW584PAhiOMBn8KbbdbiqKIgiAg0/zhylx9tXE6f9sPPgDpK2Td6sBUpAAAAABJRU5ErkJggg==);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bs-login .go-reset-panel {
  float: right;
  color: #595959;
}

.bs-login .login-submit {
  margin-bottom: 10px;
}

.bs-login .login-btn {
  border-radius: 3px;
  height: 38px;
  text-align: center;
  width: 100%;
}

.bs-login .bs-login-reset-panel .login-btn {
  background: #575757 !important;
}

.bs-login .login-signup {
  font-size: 14px;
  color: #a7a7a7;
  text-align: center;
}

.bs-login .go-login-panel {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  color: #adadad;
  transition: all 0.5s ease;
}

.sign-in-modal .bs-login .go-login-panel {
  left: -30px;
}

.bs-login .go-login-panel:hover {
  cursor: pointer;
  position: absolute;
  color: #3f3f3f;
}

.bs-login.bs-type-profile {
  max-width: 290px;
  height: 240px;
}

.bs-login .bs-login-panel.bs-current-login-panel.bs-login-user-panel {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.bs-login .bs-login-user-panel .login-header img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.bs-login .user-links {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}

.bs-login .user-links li {
  border-bottom: 1px solid #dcdcdc;
  height: 38px;
  line-height: 38px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 14px;
}

.bs-login .user-links li:last-child {
  border-bottom: none;
}

.bs-login .user-links .fa {
  margin-right: 5px;
}

.bs-login .social-login-buttons {
  text-align: center;
  padding: 0 0 17px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  margin-bottom: 25px;
}

.bs-login .social-login-buttons .or-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -13px;
  text-align: center;
}

.bs-login .social-login-buttons .or-text {
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #e4e3e3;
  border-radius: 26px;
  font-size: 12px;
  min-width: 28px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  font-weight: bolder;
  color: #9e9e9e;
  text-transform: uppercase;
}

.bs-login .social-login-buttons ul {
  list-style: none;
  padding: 0;
  margin: 0 -4px;
  font-size: 0;
}

.bs-login .social-login-buttons li {
  display: inline-block;
  width: 50%;
  padding: 0 4px 8px;
  margin: 0;
}

.bs-login .social-login-buttons li .social-login-btn.btn {
  width: 100%;
  margin: 0;
}

.bs-login .social-login-buttons ul li:first-child, .bs-login .social-login-buttons ul.items-count-2 li {
  width: 100%;
}

.bs-login .social-login-buttons ul li:first-child .social-login-btn, .bs-login .social-login-buttons ul.items-count-2 li .social-login-btn {
  text-align: center;
}

.social-login-btn.social-login-btn-facebook, .social-login-btn.social-login-btn-facebook:focus, .social-login-btn.social-login-btn-facebook:hover {
  background-color: #49639f !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-google, .social-login-btn.social-login-btn-google:focus, .social-login-btn.social-login-btn-google:hover {
  background-color: #d73d32 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-twitter, .social-login-btn.social-login-btn-twitter:focus, .social-login-btn.social-login-btn-twitter:hover {
  background-color: #59c8ff !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-instagram, .social-login-btn.social-login-btn-instagram:focus, .social-login-btn.social-login-btn-instagram:hover {
  background-color: #517fa4 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-wordpress, .social-login-btn.social-login-btn-wordpress:focus, .social-login-btn.social-login-btn-wordpress:hover {
  background-color: #1a638d !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-yahoo, .social-login-btn.social-login-btn-yahoo:focus, .social-login-btn.social-login-btn-yahoo:hover {
  background-color: #723e98 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-linkedin, .social-login-btn.social-login-btn-linkedin:focus, .social-login-btn.social-login-btn-linkedin:hover {
  background-color: #0c5d98 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-disqus, .social-login-btn.social-login-btn-disqus:focus, .social-login-btn.social-login-btn-disqus:hover {
  background-color: #2e9fff !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-reddit, .social-login-btn.social-login-btn-reddit:focus, .social-login-btn.social-login-btn-reddit:hover {
  background-color: #7ba3bc !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-foursquare, .social-login-btn.social-login-btn-foursquare:focus, .social-login-btn.social-login-btn-foursquare:hover {
  background-color: #3492ce !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-lastfm, .social-login-btn.social-login-btn-lastfm:focus, .social-login-btn.social-login-btn-lastfm:hover {
  background-color: #e02529 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-tumblr, .social-login-btn.social-login-btn-tumblr:focus, .social-login-btn.social-login-btn-tumblr:hover {
  background-color: #354a60 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-goodreads, .social-login-btn.social-login-btn-goodreads:focus, .social-login-btn.social-login-btn-goodreads:hover {
  background-color: #7c460f !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-stackoverflow, .social-login-btn.social-login-btn-stackoverflow:focus, .social-login-btn.social-login-btn-stackoverflow:hover {
  background-color: #f36f21 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-github, .social-login-btn.social-login-btn-github:focus, .social-login-btn.social-login-btn-github:hover {
  background-color: #1b1919 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-dribbble, .social-login-btn.social-login-btn-dribbble:focus, .social-login-btn.social-login-btn-dribbble:hover {
  background-color: #ea4c89 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-500px, .social-login-btn.social-login-btn-500px:focus, .social-login-btn.social-login-btn-500px:hover {
  background-color: #231f20 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-skyrock, .social-login-btn.social-login-btn-skyrock:focus, .social-login-btn.social-login-btn-skyrock:hover {
  background-color: #5597cd !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-mixi, .social-login-btn.social-login-btn-mixi:focus, .social-login-btn.social-login-btn-mixi:hover {
  background-color: #e49100 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-steam, .social-login-btn.social-login-btn-steam:focus, .social-login-btn.social-login-btn-steam:hover {
  background-color: #303030 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-twitchtv, .social-login-btn.social-login-btn-twitchtv:focus, .social-login-btn.social-login-btn-twitchtv:hover {
  background-color: #5a399c !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-vkontakte, .social-login-btn.social-login-btn-vkontakte:focus, .social-login-btn.social-login-btn-vkontakte:hover {
  background-color: #31537d !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-mailru, .social-login-btn.social-login-btn-mailru:focus, .social-login-btn.social-login-btn-mailru:hover {
  background-color: #3881c2 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-yandex, .social-login-btn.social-login-btn-yandex:focus, .social-login-btn.social-login-btn-yandex:hover {
  background-color: #ed1c24 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-odnoklassniki, .social-login-btn.social-login-btn-odnoklassniki:focus, .social-login-btn.social-login-btn-odnoklassniki:hover {
  background-color: #e87502 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-aol, .social-login-btn.social-login-btn-aol:focus, .social-login-btn.social-login-btn-aol:hover {
  background-color: #e69827 !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-live, .social-login-btn.social-login-btn-live:focus, .social-login-btn.social-login-btn-live:hover {
  background-color: #7fb0cc !important;
  color: #fff !important;
}

.social-login-btn.social-login-btn-pixelpin, .social-login-btn.social-login-btn-pixelpin:focus, .social-login-btn.social-login-btn-pixelpin:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.social-login-btn.btn {
  height: 34px;
  line-height: 34px;
  border-radius: 3px;
  text-transform: capitalize !important;
  padding: 0 14px 0 43px;
  width: auto;
  font-size: 14px;
  position: relative;
  text-align: left;
  overflow: hidden;
  margin: 0 4px 7px 0;
}

.social-login-btn.btn .bf-icon {
  vertical-align: top;
  line-height: 34px;
  margin-right: 10px;
  position: absolute;
  height: 34px;
  width: 34px;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
}

.social-login-btn.btn:hover {
  color: #fff;
}

.wp-social-login-provider-list {
  text-align: center;
}

.bs-login .g-recaptcha {
  margin-bottom: 10px;
  max-width: 100%;
}

.listing-item.better-ads-listitemad.listing-item-classic-1 {
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  border-bottom-color: rgba(0, 0, 0, 0.07);
}

.better-ads-listitemad.listing-item-text-1 {
  padding-bottom: 15px;
}

.bf-breadcrumb .bf-breadcrumb-items {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0;
}

.bf-breadcrumb .bf-breadcrumb-item {
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
}

.bf-breadcrumb .bf-breadcrumb-item:after {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  margin-left: 9px;
  margin-right: 9px;
  color: #929292;
  vertical-align: top;
}

.bf-breadcrumb .bf-breadcrumb-item.bf-breadcrumb-end:after, .bf-breadcrumb .bf-breadcrumb-item:last-child:after {
  display: none;
}

.bf-breadcrumb.hide_current .bf-breadcrumb-item.bf-breadcrumb-end {
  opacity: 0;
  visibility: hidden;
}

.bf-breadcrumb .bf-breadcrumb-item span {
  vertical-align: top;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bf-breadcrumb .bf-breadcrumb-item a, .bf-breadcrumb .bf-breadcrumb-item span {
  color: #9e9e9e;
  display: inline-block;
}

.bf-breadcrumb.bc-align-center {
  text-align: center;
}

.bf-breadcrumb.bc-light-color .bf-breadcrumb-item a, .bf-breadcrumb.bc-light-color .bf-breadcrumb-item span, .bf-breadcrumb.bc-light-color .bf-breadcrumb-item:after {
  color: #fff;
}

.bf-breadcrumb.bc-light-color .bf-breadcrumb-item.bf-breadcrumb-end {
  opacity: 0.8;
}

.bf-breadcrumb.bc-hide-last .bf-breadcrumb-item.bf-breadcrumb-end {
  display: none;
}

.bf-breadcrumb.bc-top-style {
  padding: 7px 0 0;
}

@media only screen and (max-width: 680px) {
  body.single .bf-breadcrumb .bf-breadcrumb-item:last-child {
    display: none;
  }
}
body.single .content-column > .bs-newsletter-pack:last-child {
  margin-bottom: 0 !important;
}

.bsnp-empty-note {
  margin-bottom: 30px;
}

.bs-light-scheme, .bs-light-scheme .listing-item-blog-1 > .item-inner > .title, .bs-light-scheme .listing-item-blog-2 > .item-inner > .title, .bs-light-scheme .listing-item-blog-3 > .item-inner > .title, .bs-light-scheme .listing-item-blog-5 > .item-inner > .title, .bs-light-scheme .listing-item-classic-1 .title, .bs-light-scheme .listing-item-classic-2 .title, .bs-light-scheme .listing-item-grid-1 .title, .bs-light-scheme .listing-item-grid-2 .title, .bs-light-scheme .listing-item-tb-1 .title, .bs-light-scheme .listing-item-tb-2 .title, .bs-light-scheme .listing-item-tb-3 .title, .bs-light-scheme .listing-item-text-1 .title, .bs-light-scheme .listing-item-text-2 .title, .bs-light-scheme .listing-item-text-3 .title, .bs-light-scheme .listing-item-text-4 .title, .bs-light-scheme .post-meta, .bs-light-scheme .post-meta a, .bs-light-scheme .post-summary, .bs-light-scheme .section-heading .other-link .h-text, .bs-light-scheme .title, .bs-light-scheme a {
  color: #fff;
}

.bs-light-scheme .post-meta > a, .bs-light-scheme .post-meta > span {
  opacity: 0.6;
}

.bs-light-scheme .post-summary {
  opacity: 0.9;
}

.bs-light-scheme .listing-blog-4 .listing-item-blog.has-post-thumbnail, .bs-light-scheme .listing-classic-1.columns-2 .listing-item-classic-1:last-child .listing-inner, .bs-light-scheme .listing-classic-1.columns-3 .listing-item-classic-1:last-child .listing-inner, .bs-light-scheme .listing-item-classic-1 .listing-inner, .bs-light-scheme .listing-item-grid-2 .post-summary {
  border-bottom-color: rgba(255, 255, 255, 0.129);
}

.bs-light-scheme .listing-item-classic-2 .listing-inner {
  border-color: rgba(255, 255, 255, 0.129);
}

.bs-light-scheme .pagination.bs-numbered-pagination .page-numbers, .bs-light-scheme .pagination.bs-numbered-pagination .page-numbers.dots:hover, .bs-light-scheme .pagination.bs-numbered-pagination .wp-pagenavi a, .bs-light-scheme .pagination.bs-numbered-pagination .wp-pagenavi span, .bs-light-scheme .pagination.bs-numbered-pagination span {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: rgba(255, 255, 255, 0.1);
}

.bs-light-scheme.better-social-counter.style-clean .social-item, .bs-light-scheme.better-social-counter.style-modern .social-item, .bs-light-scheme.better-social-counter.style-style-10 .social-item, .bs-light-scheme.better-social-counter.style-style-6 .social-item, .bs-light-scheme.better-social-counter.style-style-7 .social-item, .bs-light-scheme.better-social-counter.style-style-8 .social-item, .bs-light-scheme.better-social-counter.style-style-9 .social-item {
  border-color: rgba(255, 255, 255, 0.0705882);
}

.bs-light-scheme.better-social-counter.style-style-10 .item-count:before, .bs-light-scheme.better-social-counter.style-style-10 .item-join:before, .bs-light-scheme.better-social-counter.style-style-9 .item-count:before, .bs-light-scheme.better-social-counter.style-style-9 .item-join:before {
  background-color: rgba(255, 255, 255, 0.0705882);
}

.bs-light-scheme.bs-popular-categories .bs-popular-term-item .term-count, .bs-light-scheme.bs-popular-categories .bs-popular-term-item a {
  color: #fff;
}

.bs-light-scheme .section-heading.sh-t2.sh-s2 .h-text, .bs-light-scheme .section-heading.sh-t2.sh-s3 .h-text, .bs-light-scheme .section-heading.sh-t2.sh-s4 .h-text {
  color: #fff;
}

.bs-light-scheme .section-heading.sh-t1.sh-s1:after {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.bs-light-scheme .section-heading.sh-t1.sh-s2:after {
  opacity: 0.15;
}

.bs-light-scheme .section-heading.sh-t1.sh-s3:after, .bs-light-scheme .section-heading.sh-t1.sh-s6:after, .bs-light-scheme .section-heading.sh-t1.sh-s7:after, .bs-light-scheme .section-heading.sh-t4.sh-s3:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAEAQMAAACTPww9AAAABlBMVEUAAAD///+l2Z/dAAAAAXRSTlMAQObYZgAAABBJREFUCNdjEGBQYHBgaAAAAlgA8X2AAy8AAAAASUVORK5CYII=) repeat;
  opacity: 0.3;
}

.bs-light-scheme .section-heading.sh-t1 > .h-text:last-child:first-child, .bs-light-scheme .section-heading.sh-t1 > .main-link > .h-text, .bs-light-scheme .section-heading.sh-t1 > a:last-child:first-child > .h-text {
  color: #fff !important;
}

.bs-light-scheme .section-heading.sh-t1.sh-s5:after, .bs-light-scheme .section-heading.sh-t3.sh-s1:after {
  opacity: 0.15;
}

.section-heading.sh-t1.sh-s8 .h-text {
  padding: 4px 17px 4px 21px;
}

.section-heading.sh-t1.sh-s8 .other-link .h-text {
  padding: 4px 4px 4px 17px !important;
}

.section-heading.sh-t1.sh-s8:first-child:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjQ4NUVFODNGNzc1MTFFN0I4NTdCMTRBMzQ3RTIwNTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjQ4NUVFODRGNzc1MTFFN0I4NTdCMTRBMzQ3RTIwNTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNDg1RUU4MUY3NzUxMUU3Qjg1N0IxNEEzNDdFMjA1NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNDg1RUU4MkY3NzUxMUU3Qjg1N0IxNEEzNDdFMjA1NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pk86A7EAAAASSURBVHjaYmBgYLBgIAgAAgwAB88AOccVbLsAAAAASUVORK5CYII=) repeat;
  opacity: 1;
  height: 10px;
  margin-top: -6px;
  background-color: transparent !important;
}

.section-heading.sh-t1.sh-s8 .main-link .h-text:before, .section-heading.sh-t1.sh-s8 .main-link.h-text:before, .section-heading.sh-t1.sh-s8 > .h-text:first-child:last-child:before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 14px solid rgba(0, 0, 0, 0.06);
  top: 50%;
  margin-top: -9px;
}

.bs-light-scheme .section-heading.sh-t2.sh-s2 {
  background: rgba(255, 255, 255, 0.06);
}

.bs-light-scheme .section-heading.sh-t3.sh-s3:before {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .section-heading.multi-tab.sh-t4 .main-link.active .h-text, .bs-light-scheme .section-heading.multi-tab.sh-t4 .main-link:hover .h-text, .bs-light-scheme .section-heading.sh-t3.sh-s4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s4 > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s4 > a.active > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s4 > a > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s7.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s7 > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s7 > a.active > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t3.sh-s7 > a > .h-text.h-text.h-text, .bs-light-scheme .section-heading.sh-t4 .main-link.active .h-text, .bs-light-scheme .section-heading.sh-t4 .main-link:first-child:last-child .h-text, .bs-light-scheme .section-heading.sh-t4 a.active .h-text, .bs-light-scheme .section-heading.sh-t4 a:hover .h-text, .bs-light-scheme .section-heading.sh-t4 > .h-text, .bs-light-scheme .section-heading.sh-t6 > .h-text:last-child:first-child, .bs-light-scheme .section-heading.sh-t6 > .main-link > .h-text, .bs-light-scheme .section-heading.sh-t6 > a:last-child:first-child > .h-text {
  color: #fff !important;
}

.bs-light-scheme .section-heading.sh-t3.sh-s9.section-heading.sh-t3.sh-s9:before {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .section-heading.sh-t3.sh-s9.section-heading.sh-t3.sh-s9:after {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .section-heading.sh-t3.sh-s5 {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.bs-light-scheme .section-heading.sh-t3.sh-s8 > .h-text:last-child:first-child, .bs-light-scheme .section-heading.sh-t3.sh-s8 > .main-link > .h-text, .bs-light-scheme .section-heading.sh-t3.sh-s8 > a:last-child:first-child > .h-text {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.bs-light-scheme .section-heading.sh-t3.sh-s8:after {
  opacity: 0.15;
  background: #fff;
}

.bs-light-scheme .section-heading.sh-t4.sh-s1:after, .bs-light-scheme .section-heading.sh-t4.sh-s3:after, .bs-light-scheme .section-heading.sh-t6.sh-s6:before, .bs-light-scheme .section-heading.sh-t6.sh-s7:before {
  background: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .section-heading.multi-tab.sh-t4 .bs-pretty-tabs-container .bs-pretty-tabs-elements, .bs-light-scheme .section-heading.multi-tab.sh-t4 .h-text, .bs-light-scheme .section-heading.multi-tab.sh-t4 .main-link .h-text, .bs-light-scheme .section-heading.multi-tab.sh-t4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text {
  border-color: rgba(255, 255, 255, 0.15);
}

.bs-light-scheme .section-heading.sh-t5:before {
  background: rgba(255, 255, 255, 0.1);
}

.bs-light-scheme .section-heading.sh-t6.sh-s9:before {
  background: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .section-heading.sh-t6.sh-s13.section-heading.sh-t6.sh-s13 .h-text {
  color: #000 !important;
}

.bs-light-scheme .section-heading.sh-t7.sh-s1 .h-text {
  color: #fff !important;
}

.bs-light-scheme .section-heading.sh-t7:before {
  background: rgba(255, 255, 255, 0.1) !important;
}

.bs-light-scheme .listing-item-user .user-display-name {
  color: #fff;
}

.bs-light-scheme.bs-about .about-icons-list .about-icon-item a {
  color: #fff;
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18);
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.18);
}

.bs-light-scheme.bs-popular-categories .bs-popular-term-item {
  border-bottom-color: rgba(226, 226, 226, 0.15);
}

.bs-light-scheme.bs-popular-categories .bs-popular-term-item .term-count {
  background: rgba(255, 255, 255, 0.08);
}

.bs-light-scheme.bs-subscribe-newsletter .powered-by {
  color: #fff;
}

@media print {
  body {
    background: #fff none !important;
    color: #000;
  }
  .back-top, .bs-irp, .bs-pinning-wrapper, .comment-list .comment-footer, .comment-respond, .footer-instagram, .main-menu-wrapper, .more-stories, .next-prev-post, .post-related, .post-tp-4-header .move-to-content, .post-tp-6-header.wfi, .post-tp-8-header, .sidebar-column.sidebar-column-primary, .sidebar-column.sidebar-column-secoundary, .single-container > .post-author .author-links ul, .single-post-share, .single-post-share .share-handler-wrap, .site-footer .footer-social-icons, .site-footer .footer-widgets, body.single .bf-breadcrumb .bf-breadcrumb-item.bf-breadcrumb-end, body.single .bf-breadcrumb .bf-breadcrumb-item:last-child {
    display: none !important;
  }
  .single-post-share .post-share-btn.post-share-btn-comments, .single-post-share .post-share-btn.post-share-btn-views {
    float: none;
  }
  a[href]:after {
    content: none !important;
  }
  .term-badges a {
    max-width: inherit;
  }
  .bs-sks {
    display: none;
  }
  body.active-top-line {
    border-top: none;
  }
  .comment-list .comment-meta .comment-published {
    float: none;
    margin: 0 15px;
  }
  .post-template-6.wfi .post-header-inner, .post-template-7.wfi .post-header-inner, .post-tp-11-header.wfi .post-header-inner, .post-tp-2-header.wfi .post-header-inner, .post-tp-3-header.wfi .post-header-inner, .post-tp-4-header.wfi .post-header-inner, .post-tp-5-header.wfi .post-header-inner, .post-tp-7-header.wfi .post-header-inner {
    height: auto;
    padding: 0;
  }
  .post-template-6.wfi .post-header-inner, .post-tp-11-header.wfi .post-header-title, .post-tp-2-header.wfi .post-header-title, .post-tp-3-header.wfi .post-header-title, .post-tp-4-header .term-badges.floated, .post-tp-4-header.wfi .post-header-title, .post-tp-5-header .term-badges.floated, .post-tp-5-header.wfi .post-header-title, .post-tp-7-header.wfi .post-header-title {
    position: relative !important;
    top: inherit !important;
    left: inherit !important;
    right: inherit !important;
    bottom: inherit !important;
    margin-top: 20px !important;
    opacity: 1 !important;
    transform: none !important;
  }
  .post-template-6.wfi .post-header-inner, .post-template-7.wfi .post-header-inner, .post-tp-4-header .term-badges.floated {
    margin-bottom: 15px;
  }
  .post-tp-7-header.wfi .post-header-title {
    padding: 0;
  }
}
.bs-wrap-gdpr-law {
  display: block;
  color: #212121;
  font-size: 14px;
}

.bs-gdpr-law {
  padding: 8px 15px;
  color: inherit;
  font-size: inherit;
  display: block;
  text-align: center;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  background: #f5f5f5;
}

.bs-gdpr-law p {
  font-size: inherit;
  color: inherit;
  margin: 0;
  display: inline-block;
}

.bs-gdpr-law .bs-gdpr-accept, .bs-gdpr-law .bs-gdpr-more {
  display: inline-block;
  padding: 0;
  line-height: 1;
  font-size: inherit;
  color: inherit;
  margin-left: 10px;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.bs-gdpr-law .bs-gdpr-accept {
  background: #212121;
  color: #fff;
  padding: 6px 15px;
}

.bs-gdpr-law .bs-gdpr-accept:hover, .bs-gdpr-law .bs-gdpr-more:hover {
  opacity: 0.8;
}

.bs-gdpr-law, .bs-wrap-gdpr-law .bs-gdpr-show {
  position: fixed;
  z-index: 19999;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}

.bs-wrap-gdpr-law .bs-gdpr-show {
  display: inline-block;
  width: auto;
  right: 100px;
  left: auto;
  padding: 6px 15px;
  font-size: inherit;
  color: inherit;
  background: #f5f5f5;
  line-height: 1;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}

.bs-wrap-gdpr-law-close {
  z-index: 0;
}

.bs-wrap-gdpr-law .bs-gdpr-show, .bs-wrap-gdpr-law-close .bs-gdpr-law {
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
}

.bs-wrap-gdpr-law-close .bs-gdpr-show {
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

@media screen and (max-width: 768px) {
  .bs-wrap-gdpr-law .bs-gdpr-show {
    display: none !important;
  }
}
.sidebar-column-primary {
  z-index: 1000;
}

.bs-sks {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999;
  pointer-events: none;
}

.bs-sks.bs-sks-type-1 {
  bottom: 0;
}

.bs-sks.bs-sks-type-2 {
  left: calc((100vw - var(--publisher-site-width)) / 2 * -1);
  max-width: initial !important;
  display: inline-table;
  width: calc(100vw - 9px) !important;
  height: 100%;
}

.bs-sksin1, .bs-sksin3 {
  display: inline-table;
  height: 100%;
  width: 100%;
}

.bs-sks.bs-sks-s1 .bs-sksin2 {
  --sks-top: calc(1px - 1px);
  --sks-top-gap: var(--wp-adminbar-height);
  position: sticky;
  top: calc(var(--sks-top) + var(--sks-top-gap));
  transition: top 0.2s ease;
}

body.menu-pined .bs-sks.bs-sks-s1 .bs-sksin2 {
  --sks-top-gap: calc(var(--publisher-menu-height) + var(--wp-adminbar-height)) ;
}

.bs-sks .bs-sks-path {
  overflow: hidden;
}

.bs-sks .bs-sks-path-boxed {
  max-width: var(--publisher-site-width);
  margin-left: auto;
  margin-right: auto;
}

.bs-sks .bs-sksitem > * {
  pointer-events: all;
}

.bs-sks .bs-sksiteml {
  float: left;
  transform: translateX(-100%);
}

.bs-sks.bs-sks-type-2 .bs-sksiteml {
  transform: translateX(calc(-100% + 5px));
}

.bs-sks .bs-sksitemr {
  float: right;
  transform: translateX(100%);
}

.bs-sks.bs-sks-type-2 .bs-sksitemr {
  transform: translateX(calc(100% + 5px));
}

.bs-sks.bs-sks-sticky .bs-sksin2 {
  --sks-top: 0px;
  --sks-top-gap: 0px;
  position: sticky;
  top: calc(var(--sks-top) + var(--sks-top-gap));
  transition: top 0.2s ease;
}

body.admin-bar .bs-sks.bs-sks-sticky .bs-sksin2, body.full-width .bs-sks.bs-sks-sticky.bs-sks-pafter-header .bs-sksin2 {
  --sks-top: 30px ;
}

body.full-width.admin-bar .bs-sks.bs-sks-sticky.bs-sks-pafter-header .bs-sksin2 {
  --sks-top: 60px ;
}

body.full-menu-pined .bs-sks.bs-sks-sticky .bs-sksin2 {
  --sks-top-gap: 30px ;
}

.listing.scolumns-1 .listing-item {
  width: 100%;
  float: none;
}

.listing.scolumns-2 .listing-item {
  width: 50%;
  float: left;
}

.listing.scolumns-2 .listing-item:nth-child(odd) {
  padding-right: 12px;
  clear: left;
}

.listing.scolumns-2 .listing-item:nth-child(even) {
  padding-left: 12px;
}

.listing.scolumns-3 .listing-item {
  width: 33.3333%;
  float: left;
}

.listing.scolumns-3 .listing-item:nth-child(3n-1) {
  padding: 0 6px;
}

.listing.scolumns-3 .listing-item:nth-child(3n-2) {
  padding-right: 12px;
  clear: left;
}

.listing.scolumns-3 .listing-item:nth-child(3n+3) {
  padding-left: 12px;
}

.listing.scolumns-4 .listing-item {
  width: 25%;
  float: left;
}

.listing.scolumns-4 .listing-item:nth-child(4n-3) {
  padding-left: 0;
  padding-right: 14px;
  clear: left;
}

.listing.scolumns-4 .listing-item:nth-child(4n+2) {
  padding-left: 5px;
  padding-right: 9px;
}

.listing.scolumns-4 .listing-item:nth-child(4n+3) {
  padding-left: 9px;
  padding-right: 5px;
}

.listing.scolumns-4 .listing-item:nth-child(4n+4) {
  padding-right: 0;
  padding-left: 14px;
  clear: none;
}

.listing.scolumns-5 .listing-item {
  width: 20%;
  float: left;
}

.listing.scolumns-5 .listing-item:nth-child(5n+1) {
  padding-left: 0;
  padding-right: 12px;
  clear: left;
}

.listing.scolumns-5 .listing-item:nth-child(5n+2) {
  padding-left: 4px;
  padding-right: 8px;
}

.listing.scolumns-5 .listing-item:nth-child(5n+3) {
  padding-left: 6px;
  padding-right: 6px;
}

.listing.scolumns-5 .listing-item:nth-child(5n+4) {
  padding-left: 8px;
  padding-right: 4px;
}

.listing.scolumns-5 .listing-item:nth-child(5n+5) {
  padding-left: 12px;
  padding-right: 0;
}

@media only screen and (max-width: 1000px) {
  .listing.scolumns-5 .listing-item {
    width: 25%;
    float: left;
  }
  .listing.scolumns-5 .listing-item:nth-child(4n-3) {
    padding-left: 0;
    padding-right: 14px;
    clear: left;
  }
  .listing.scolumns-5 .listing-item:nth-child(4n+2) {
    padding-left: 5px;
    padding-right: 9px;
    clear: none;
  }
  .listing.scolumns-5 .listing-item:nth-child(4n+3) {
    padding-left: 9px;
    padding-right: 5px;
  }
  .listing.scolumns-5 .listing-item:nth-child(4n+4) {
    padding-right: 0;
    padding-left: 14px;
    clear: none !important;
  }
}
@media only screen and (max-width: 900px) {
  .listing.scolumns-4 .listing-item, .listing.scolumns-5 .listing-item {
    width: 33.3333%;
    float: left;
  }
  .listing.scolumns-4 .listing-item:nth-child(3n-1), .listing.scolumns-5 .listing-item:nth-child(3n-1) {
    padding: 0 6px;
    clear: none;
  }
  .listing.scolumns-4 .listing-item:nth-child(3n-2), .listing.scolumns-5 .listing-item:nth-child(3n-2) {
    padding-right: 12px;
    padding-left: 0;
    clear: left;
  }
  .listing.scolumns-4 .listing-item:nth-child(3n+3), .listing.scolumns-5 .listing-item:nth-child(3n+3) {
    padding-left: 12px;
    padding-right: 0;
    clear: none;
  }
}
@media only screen and (max-width: 600px) {
  .listing.scolumns-3 .listing-item, .listing.scolumns-4 .listing-item, .listing.scolumns-5 .listing-item {
    width: 50%;
    float: left;
  }
  .listing.scolumns-3 .listing-item:nth-child(odd), .listing.scolumns-4 .listing-item:nth-child(odd), .listing.scolumns-5 .listing-item:nth-child(odd) {
    padding-left: 0;
    padding-right: 12px;
    clear: left;
  }
  .listing.scolumns-3 .listing-item:nth-child(even), .listing.scolumns-4 .listing-item:nth-child(even), .listing.scolumns-5 .listing-item:nth-child(even) {
    padding-right: 0;
    padding-left: 12px;
    clear: none;
  }
}
@media only screen and (max-width: 320px) {
  .listing.scolumns-2 .listing-item, .listing.scolumns-3 .listing-item, .listing.scolumns-4 .listing-item, .listing.scolumns-5 .listing-item {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    clear: none !important;
  }
}
.post-count-badge {
  pointer-events: none;
  display: inline-block;
}

.post-count-badge.pcb-t1 {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px;
  font-size: 12px;
  text-align: center;
  font-weight: bolder;
  box-shadow: 0 0 0 2px #fff;
}

.post-count-badge.pcb-t1.pcb-s1 {
  color: #fff;
  background: #2a2a2a;
}

.post-count-badge.pcb-t1.pcb-s2 {
  color: #000;
  background: rgba(0, 0, 0, 0.06);
  box-shadow: none;
}

.post-count-badge.pcb-t2 {
  line-height: 1;
  font-size: 90px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.045);
  font-family: arial;
}

.post-count-badge.pcb-t2.pcb-s2 {
  font-size: 42px;
}

.listing-item-classic .post-subtitle {
  margin-bottom: 7px;
}

.listing-item-classic-1 {
  margin-bottom: 22px;
}

.listing-classic-1.columns-2 .listing-item-classic-1:last-child .listing-inner, .listing-classic-1.columns-3 .listing-item-classic-1:last-child .listing-inner, .listing-item-classic-1 .listing-inner {
  padding-bottom: 22px;
  border-bottom: 1px solid #f1f1f1;
}

.listing-item-classic-1.has-not-post-thumbnail .listing-inner {
  padding-bottom: 28px;
}

.listing-item-classic-1:last-child {
  margin-bottom: 0;
}

.listing-item-classic-1:last-child .listing-inner {
  padding-bottom: 0;
  border-bottom: none;
}

.listing-item-classic-1 .featured {
  position: relative;
  line-height: 0;
  margin-bottom: 17px;
}

.listing-item-classic-1 .featured iframe {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.listing-item-classic-1 .featured .img-holder {
  width: 100%;
  margin: 0;
  float: none;
}

.listing-item-classic-1 .featured .img-holder:before {
  padding-top: 50%;
}

.listing-item-classic-1 .title {
  margin: 0 0 6px;
}

.listing-item-classic-1 .title a {
  color: inherit;
}

.listing-item-classic-1 .post-meta {
  margin: 0 0 9px;
}

.listing-item-classic.bsw-1 .title, .listing-item-classic.bsw-2 .title, .listing-item-classic.bsw-3 .title, .listing-item-classic.bsw-4 .title {
  line-height: 1.3;
}

.listing-item-classic.bsw-1 .title .post-title, .listing-item-classic.bsw-2 .title .post-title, .listing-item-classic.bsw-3 .title .post-title, .listing-item-classic.bsw-4 .title .post-title {
  font-size: 88%;
}

@media only screen and (max-width: 400px) {
  .listing-item-classic .title {
    line-height: 1.3 !important;
  }
  .listing-item-classic .title .post-title {
    font-size: 88% !important;
  }
}
.listing.columns-1 > .listing-item-classic-2:last-child, .listing.columns-3 > .listing-item-classic-2:last-child {
  margin-bottom: 25px;
}

.listing-item-classic-2, .listing.columns-2 > .listing-item-classic-2:last-child {
  margin-bottom: 30px;
}

.listing .listing-classic-2 > .listing-item-classic-2:last-child, .listing-item-classic-2:last-child {
  margin-bottom: 0;
}

.listing-item-classic-2 .listing-inner {
  padding: 0 20px 20px 20px;
  border: 1px solid #e6e6e6;
}

.listing-item-classic-2.has-not-post-thumbnail .listing-inner {
  padding-top: 20px;
}

.listing-item-classic-2 .featured {
  position: relative;
  line-height: 0;
  margin: -1px -21px 17px;
}

.listing-item-classic-2 .featured iframe {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.listing-item-classic-2 .featured .img-holder {
  width: 100%;
  margin: 0;
  float: none;
}

.listing-item-classic-2 .featured .img-holder:before {
  padding-top: 56%;
}

.listing-item-classic-2 .title {
  margin: 0 0 6px;
}

.listing-item-classic-2 .title a {
  color: inherit;
}

.listing-item-classic-2 .post-meta {
  margin: 0 0 9px;
}

.listing-classic-3.columns-2 .listing-item-classic-3:last-child, .listing-classic-3.columns-3 .listing-item-classic-3:last-child, .listing-item-classic-3 {
  margin-bottom: 25px;
}

.listing-item-classic-3 .listing-inner {
  padding: 0 25px 25px;
  border-bottom: 1px solid #f1f1f1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-left: 4%;
  margin-right: 4%;
  position: relative;
  z-index: 1;
  margin-top: -15px;
}

.listing-item-classic-3:last-child, .listing-item-classic-3:last-child .listing-inner {
  margin-bottom: 0;
  border-bottom: none;
}

.listing-classic-3.columns-2 .listing-item-classic-3:last-child .listing-inner, .listing-classic-3.columns-3 .listing-item-classic-3:last-child .listing-inner {
  border-bottom: 1px solid #f1f1f1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.listing-item-classic-3 .featured {
  position: relative;
  line-height: 0;
}

.listing-item-classic-3 .featured iframe {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.listing-item-classic-3 .featured .img-holder {
  width: 100%;
  margin: 0;
  float: none;
}

.listing-item-classic-3 .featured .img-holder:before {
  padding-top: 55%;
}

.listing-item-classic-3 .title {
  margin: 40px 0 6px;
}

.listing-item-classic-3 .featured .title {
  margin: 0 4%;
  background: #fff;
  position: absolute;
  bottom: -2px;
  padding: 26px 25px 25px;
  width: 92%;
}

.listing-item-classic-3 .title a {
  color: inherit;
}

.listing-item-classic-3 .title h2, .listing-item-classic-3 .title .h2 {
  margin: 0;
}

.listing-item-classic-3 .post-meta {
  margin: 0 0 9px;
}

.listing-item-classic-3.has-not-post-thumbnail .listing-inner {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.listing-item-classic-3.has-not-post-thumbnail .title {
  margin-top: 20px;
}

.listing-item-classic-3.bsw-1 .featured .img-holder:before, .listing-item-classic-3.bsw-2 .featured .img-holder:before, .listing-item-classic-3.bsw-3 .featured .img-holder:before, .listing-item-classic-3.bsw-4 .featured .img-holder:before {
  padding-top: 65%;
}

.listing-item-classic-3.bsw-1 .featured .title, .listing-item-classic-3.bsw-2 .featured .title, .listing-item-classic-3.bsw-3 .featured .title {
  margin: 15px 0 10px;
  padding: 0;
  background: #fff;
  position: relative;
}

.listing-item-classic-3.bsw-1 .listing-inner, .listing-item-classic-3.bsw-2 .listing-inner, .listing-item-classic-3.bsw-3 .listing-inner {
  padding: 0 0 15px;
  margin: 0;
}

@media only screen and (max-width: 380px) {
  .listing-item-classic-3 .featured .img-holder:before {
    padding-top: 65% !important;
  }
  .listing-item-classic-3 .featured .title {
    margin: 15px 0 10px !important;
    padding: 0 !important;
    background: #fff !important;
    position: relative !important;
    bottom: inherit !important;
  }
  .listing-item-classic-3 .listing-inner {
    padding: 0 0 15px !important;
    margin: 0 !important;
  }
}
.listing-item-grid-1 {
  margin-bottom: 22px;
}

.listing-item-grid-1 .featured {
  position: relative;
  line-height: 0;
  margin-bottom: 13px;
}

.listing-item-grid-1 .featured iframe {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.listing-item-grid-1 .featured .img-holder {
  width: 100%;
  margin: 0;
  float: none;
  display: block;
}

.listing-item-grid-1 .featured .img-holder:before {
  padding-top: 58%;
}

.listing-item-grid-1:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.listing-item-grid-1 .title {
  margin: 0 0 6px;
}

.listing-item-grid-1 .title a {
  color: inherit;
  text-transform: inherit;
}

.listing-item-grid-1 .post-meta {
  margin: 0 0 7px;
}

.listing-item-grid-1 .post-summary {
  padding-bottom: 22px;
  border-bottom: 1px solid #f1f1f1;
  border-bottom-color: rgba(0, 0, 0, 0.07);
}

.listing-item-grid-1 .post-subtitle {
  margin-bottom: 6px;
}

.listing-grid.columns-1 .listing-item-grid-1:last-child .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing-item-grid.simple-grid .featured {
  margin-bottom: 7px;
}

.listing-item-grid.simple-grid .featured .img-holder:before {
  padding-top: 66%;
}

.listing-item-grid-2 {
  margin-bottom: 22px;
}

.listing-item-grid-2 .featured {
  position: relative;
  line-height: 0;
  margin-bottom: 13px;
}

.listing-item-grid-2 .featured iframe {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.listing-item-grid-2 .featured .img-holder {
  width: 100%;
  margin: 0;
  float: none;
  display: block;
}

.listing-item-grid-2 .featured .img-holder:before {
  padding-top: 58%;
}

.listing-item-grid-2:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.listing-item-grid-2 .title {
  margin: 0 0 6px;
}

.listing-item-grid-2 .title a {
  color: inherit;
  text-transform: inherit;
}

.listing-item-grid-2 .post-meta {
  margin: 0 0 15px;
}

.listing-item-grid-2 .post-summary {
  padding-bottom: 22px;
  border-bottom: 1px solid #f1f1f1;
}

.listing-item-grid-2 .post-subtitle {
  margin-bottom: 6px;
}

.listing-item-blog .format-icon {
  right: 15px;
  top: 15px;
  width: 28px;
  height: 28px;
  line-height: 25px;
  font-size: 13px;
}

.listing-item-blog .format-icon.format-audio {
  padding-right: 3px;
}

.listing-item-blog .format-icon.format-video {
  padding-left: 3px;
}

.listing-item-blog .format-icon .fa {
  line-height: 25px;
}

.listing-item-blog .featured:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.listing-item-blog-1, .listing-item-blog-5 {
  margin-bottom: 25px;
}

.listing-item-blog-1:last-child, .listing-item-blog-5:last-child {
  margin-bottom: 0;
}

.listing-item-blog-1 .featured, .listing-item-blog-5 .featured {
  position: relative;
  float: left;
  margin-right: 26px;
  line-height: 0;
}

.listing-item-blog-1 .featured .img-holder, .listing-item-blog-5 .featured .img-holder {
  width: 210px;
  margin: 0;
}

.listing-item-blog-1 .featured .img-holder.img-holder:before, .listing-item-blog-5 .featured .img-holder.img-holder:before {
  padding-top: 64%;
}

.listing-item-blog-1 > .item-inner > .title, .listing-item-blog-5 > .item-inner > .title {
  margin: 0 0 6px;
}

.listing-item-blog-1 > .item-inner > .title a, .listing-item-blog-5 > .item-inner > .title a {
  color: inherit;
}

.listing-item-blog-1 .post-summary, .listing-item-blog-5 .post-summary {
  margin-top: 6px;
}

.listing-item-blog-1.has-post-thumbnail .post-summary {
  padding-left: 237px;
}

.listing-item-blog-5.has-post-thumbnail .post-summary {
  padding-left: 326px;
}

.listing-item-blog-5 .post-summary p + .read-more {
  margin-top: 0;
}

.listing-item-blog-5 .featured .img-holder {
  width: 300px;
  margin: 0;
}

.listing-item-blog.has-not-post-thumbnail:first-child {
  padding-top: 0;
  border-top: none;
}

.listing-item-blog + .listing-item-blog.has-not-post-thumbnail, .listing-item-blog.has-not-post-thumbnail + .listing-item-blog {
  padding-top: 18px;
  border-top: 1px solid #f1f3f5;
}

.listing-item-blog > .item-inner > .post-subtitle {
  margin-bottom: 5px;
}

.listing-item-blog > .item-inner > .post-meta + .post-subtitle {
  margin-top: 5px;
}

.listing-item-blog-5.bsw-1 .img-holder, .listing-item-blog-5.bsw-2 .img-holder, .listing-item-blog-5.bsw-3 .img-holder, .listing-item-blog-5.bsw-4 .img-holder, .listing-item-blog-5.bsw-5 .img-holder {
  width: 220px;
}

.listing-item-blog-5.has-post-thumbnail.bsw-1 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-2 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-3 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-4 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-5 .post-summary {
  padding-left: 243px;
}

.listing-item-blog-1.bsw-1 .featured, .listing-item-blog-1.bsw-2 .featured, .listing-item-blog-1.bsw-3 .featured, .listing-item-blog-1.bsw-4 .featured, .listing-item-blog-2.bsw-1 .featured, .listing-item-blog-2.bsw-2 .featured, .listing-item-blog-2.bsw-3 .featured, .listing-item-blog-2.bsw-4 .featured, .listing-item-blog-5.bsw-1 .featured, .listing-item-blog-5.bsw-2 .featured, .listing-item-blog-5.bsw-3 .featured, .listing-item-blog-5.bsw-4 .featured {
  float: none;
  text-align: center;
  margin: 0 0 10px 0;
  display: inline-block;
}

.listing-item-blog-5.bsw-1 .featured, .listing-item-blog-5.bsw-1 .img-holder, .listing-item-blog-5.bsw-2 .featured, .listing-item-blog-5.bsw-2 .img-holder, .listing-item-blog-5.bsw-3 .featured, .listing-item-blog-5.bsw-3 .img-holder, .listing-item-blog-5.bsw-4 .featured, .listing-item-blog-5.bsw-4 .img-holder {
  width: 100%;
}

.listing-item-blog-2.has-post-thumbnail.bsw-1 .post-summary, .listing-item-blog-2.has-post-thumbnail.bsw-2 .post-summary, .listing-item-blog-2.has-post-thumbnail.bsw-3 .post-summary, .listing-item-blog-2.has-post-thumbnail.bsw-4 .post-summary {
  padding-right: 0;
}

.listing-item-blog-1.has-post-thumbnail.bsw-1 .post-summary, .listing-item-blog-1.has-post-thumbnail.bsw-2 .post-summary, .listing-item-blog-1.has-post-thumbnail.bsw-3 .post-summary, .listing-item-blog-1.has-post-thumbnail.bsw-4 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-1 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-2 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-3 .post-summary, .listing-item-blog-5.has-post-thumbnail.bsw-4 .post-summary {
  padding-left: 0;
}

@media only screen and (max-width: 830px) {
  .listing-item-blog-1.has-post-thumbnail .post-summary, .listing-item-blog-5.has-post-thumbnail .post-summary {
    padding-left: 0 !important;
  }
  .listing-item-blog-1 .featured, .listing-item-blog-2 .featured, .listing-item-blog-5 .featured {
    float: none !important;
    text-align: center !important;
    margin: 0 0 10px 0 !important;
    display: inline-block !important;
  }
  .listing-item-blog-5 .featured, .listing-item-blog-5 .img-holder {
    width: 100% !important;
  }
}
.listing-item-blog-2 {
  margin-bottom: 25px;
}

.listing-item-blog-2:last-child {
  margin-bottom: 0;
}

.listing-item-blog-2 .featured {
  position: relative;
  float: right;
  margin-left: 23px;
  line-height: 0;
}

.listing-item-blog-2 .featured .img-holder {
  width: 210px;
  margin: 0;
}

.listing-item-blog-2 .featured .img-holder.img-holder:before {
  padding-top: 64%;
}

.listing-item-blog-2 > .item-inner > .title {
  margin: 0 0 6px;
}

.listing-item-blog-2 > .item-inner > .title a {
  color: inherit;
}

.listing-item-blog-2 .post-summary {
  margin-top: 6px;
}

.listing-item-blog-2.has-post-thumbnail .post-summary {
  padding-right: 233px;
}

@media only screen and (max-width: 420px) {
  .listing-item-blog-2.has-post-thumbnail .post-summary {
    padding-right: 0 !important;
  }
  .listing-item-blog-2 .featured {
    float: none;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
.listing-item-blog-3 {
  margin-bottom: 25px;
}

.listing-item-blog-3:last-child {
  margin-bottom: 0;
}

.listing-item-blog-3 .featured {
  position: relative;
  float: left;
  margin-right: 23px;
  line-height: 0;
}

.listing-item-blog-3 .featured .img-holder {
  width: 210px;
  margin: 0;
}

.listing-item-blog-3.has-post-thumbnail .post-summary {
  padding-left: 233px;
}

.listing-item-blog-3 .featured .img-holder.img-holder:before {
  padding-top: 64%;
}

.listing-item-blog-3 > .item-inner > .title {
  margin: 7px 0 3px;
}

.listing-item-blog-3 > .item-inner > .post-meta {
  margin-bottom: 10px;
}

.listing-item-blog-3 > .item-inner > .title a {
  color: inherit;
}

.listing-item-blog-3.bsw-1 .featured, .listing-item-blog-3.bsw-2 .featured, .listing-item-blog-3.bsw-3 .featured, .listing-item-blog-3.bsw-4 .featured {
  float: none;
  text-align: center;
  margin: 0 0 10px 0;
}

.listing-item-blog-3.bsw-1 .featured, .listing-item-blog-3.bsw-2 .featured, .listing-item-blog-3.bsw-3 .featured, .listing-item-blog-3.bsw-4 .featured {
  float: none;
  text-align: center;
  margin: 0 0 10px 0;
  display: inline-block;
}

.listing-item-blog-3.has-post-thumbnail.bsw-1 .post-summary, .listing-item-blog-3.has-post-thumbnail.bsw-2 .post-summary, .listing-item-blog-3.has-post-thumbnail.bsw-3 .post-summary, .listing-item-blog-3.has-post-thumbnail.bsw-4 .post-summary {
  padding-left: 0;
}

@media only screen and (max-width: 420px) {
  .listing-item-blog-3 .featured {
    float: none !important;
    text-align: center !important;
    margin: 0 0 10px 0 !important;
  }
  .listing-item-blog-3 .featured {
    float: none !important;
    text-align: center !important;
    margin: 0 0 10px 0 !important;
    display: inline-block !important;
  }
  .listing-item-blog-3.has-post-thumbnail .post-summary {
    padding-left: 0 !important;
  }
}
.listing-blog-4 .listing-item-blog.has-post-thumbnail {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}

.listing-blog-4 .listing-item-blog.has-post-thumbnail:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.listing-item-blog-5 .post-subtitle {
  margin-bottom: 7px;
}

.listing-item-tall .format-icon {
  right: 15px;
  top: 15px;
  width: 28px;
  height: 28px;
  line-height: 25px;
  font-size: 12px;
}

.listing-item-tall .format-icon .fa {
  line-height: 25px;
}

.listing-item-tall:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.listing-item-tall .post-subtitle {
  margin-bottom: 5px;
}

.listing-item-tall .featured + .post-subtitle {
  margin: 8px 0 -8px;
}

.listing-item-tall .post-meta + .post-subtitle {
  margin-top: 8px;
}

.listing-item-tall-1 {
  margin-bottom: 23px;
}

.listing-item-tall-1:last-child {
  margin-bottom: 0;
}

.listing-item-tall-1 .featured {
  position: relative;
}

.bs-ll-a .listing-item-tall-1 .featured .img-holder.b-loaded, .bs-ll-d .listing-item-tall-1 .featured .img-holder, .listing-item-tall-1 .featured .img-holder {
  width: 100%;
  margin: 0;
  background-position: top center;
}

.bs-ll-a .listing-item-tall-1 .featured .img-holder {
  background-position: center center;
}

.listing-item-tall-1 .featured .img-holder.img-holder:before {
  padding-top: 134%;
}

.listing-item-tall-1 > .title {
  margin: 15px 0 9px;
}

.listing-item-tall-1 > .title a {
  color: inherit;
}

.listing-item-tall-1 .post-summary {
  margin-top: 10px;
}

.listing-item-tall-2 {
  margin-bottom: 23px;
}

.bs-slider-item .listing-item-tall-2 {
  margin-bottom: 0 !important;
}

.listing-item-tall-2:last-child {
  margin-bottom: 0;
}

.listing-item-tall-2 .featured {
  position: relative;
}

.bs-ll-a .listing-item-tall-2 .featured .img-holder.b-loaded, .bs-ll-d .listing-item-tall-2 .featured .img-holder, .listing-item-tall-2 .featured .img-holder {
  width: 100%;
  margin: 0;
  background-position: top center;
}

.bs-ll-a .listing-item-tall-2 .featured .img-holder {
  background-position: center center;
}

.listing-item-tall-2 .featured .img-holder.img-holder:before {
  padding-top: 134%;
}

.listing-item-tall-2 .term-badges.floated {
  left: 0;
  right: 0;
  text-align: center;
  pointer-events: none;
}

.listing-item-tall-2 .term-badges.floated a {
  pointer-events: all;
}

.listing-item-tall-2 > .title {
  margin: 15px 0 9px;
}

.listing-item-tall-2 > .title a {
  color: inherit;
}

.listing-item-tall-2 .post-meta {
  text-align: center;
}

.listing-item-tall-2 .post-summary {
  text-align: center;
  margin-top: 10px;
}

.listing-item-tall-2 .post-subtitle {
  text-align: center !important;
}

.listing-item-tb-1 {
  margin-bottom: 22px;
  position: relative;
}

.listing-item-tb-1:last-child, .listing-mix-3-1 .listing-tb-1 > .listing-item-tb-1:last-child {
  margin-bottom: 0;
}

.listing-item-tb-1 .title {
  margin: 0;
}

.listing-item-tb-1 .title a {
  color: inherit;
}

.listing-item-tb-1 .featured {
  margin-right: 13px;
  float: left;
  position: relative;
  line-height: 0;
}

.listing-item-tb-1 .img-holder {
  width: 86px;
  margin-right: 0;
  float: none;
}

.listing-item-tb-1 .img-holder:before {
  padding-top: 74%;
}

.listing-item-tb-1 .featured.featured-type-author-avatar .img-holder:before {
  padding-top: 100%;
}

.listing-item-tb-1 .post-subtitle {
  margin-top: 4px;
}

.listing-item-tb-1 .featured + .post-subtitle {
  margin: 0;
}

.bs-listing.bs-listing-listing-thumbnail-1 .bs-pagination {
  margin-top: 15px;
}

.listing-item-tb-1.has-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 1;
}

.listing-item-tb-1.has-not-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  margin-bottom: 5px;
}

.listing-item-tb-2 {
  margin-bottom: 20px;
  position: relative;
}

.listing-item-tb-2 .term-badges.floated {
  left: 0;
}

.listing-item-tb-2 .item-inner {
  position: relative;
}

.listing-item-tb-2 .title {
  margin: 0;
}

.listing-item-tb-2 .title a {
  color: inherit;
}

.listing-item-tb-2 .post-meta {
  margin-top: 5px;
}

.listing-item-tb-2 .post-summary {
  margin-top: 5px;
}

.listing-item-tb-2 .featured {
  margin-bottom: 10px;
  line-height: 0;
  position: relative;
}

.listing-item-tb-2 .format-icon {
  right: 15px;
  top: 15px;
  width: 28px;
  height: 28px;
  line-height: 24px;
  font-size: 12px;
}

.listing-item-tb-2 .format-icon .fa {
  line-height: 24px;
}

.listing-item-tb-2:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform: scale(1.5);
}

.listing-item-tb-2 .img-holder {
  width: 100%;
  margin-right: 0;
  float: none;
  display: block;
}

.listing-item-tb-2 .img-holder:before {
  padding-top: 70%;
}

.listing-item-tb-2 .featured.featured-type-author-avatar .img-holder:before {
  padding-top: 100%;
}

.listing-item-tb-2 .post-subtitle {
  margin-bottom: 5px;
}

.listing-item-tb-2 .title + .post-subtitle {
  margin: 5px 0 0;
}

.listing-item-tb-2.has-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  position: absolute;
  bottom: -14px;
  left: 6px;
  z-index: 1;
}

.listing-item-tb-2.has-post-thumbnail.has-counter-badge .featured {
  margin-bottom: 22px;
}

.listing-item-tb-2.has-not-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  margin-bottom: 10px;
}

.listing-item-tb-3 {
  margin-bottom: 20px;
  position: relative;
}

.listing-item-tb-3:last-child {
  margin-bottom: 0;
}

.listing-tb-3 > .listing-item-tb-3:last-child {
  margin-bottom: 20px;
}

.listing-item-tb-3 .title {
  margin: 0;
}

.listing-item-tb-3 .title a {
  color: inherit;
}

.listing-item-tb-3 .featured {
  margin-left: 15px;
  float: right;
  position: relative;
  line-height: 0;
}

.listing-item-tb-3 .img-holder {
  width: 86px;
  margin-right: 0;
  float: none;
}

.listing-item-tb-3 .img-holder:before {
  padding-top: 74%;
}

.listing-item-tb-3 .featured.featured-type-author-avatar .img-holder:before {
  padding-top: 100%;
}

.listing-item-tb-3 .post-subtitle {
  margin-top: 4px;
}

.listing-item-tb-3 .featured + .post-subtitle {
  margin: 0;
}

.listing-item-tb-3.has-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}

.listing-item-tb-3.has-not-post-thumbnail .post-count-badge.pcb-t1.pcb-s1 {
  margin-bottom: 5px;
}

.listing-item-text-1 {
  position: relative;
  margin-bottom: 15px;
}

.listing-item-text-1:last-child {
  margin-bottom: 0;
}

.listing-item-text-1 .item-inner {
  padding-bottom: 15px;
  border-bottom: 1px dashed #eaeaea;
  border-bottom-color: rgba(0, 0, 0, 0.08);
}

.listing-item-text-1 .term-badges.floated {
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-align: center;
  margin-bottom: 12px;
}

.listing-item-text-1 .term-badges.floated .term-badge a {
  color: #fff !important;
  margin: 0 2px;
}

.listing-item-text-1 .title {
  margin: 0;
}

.listing-item-text-1.no-meta .title {
  margin-bottom: 10px;
}

.listing-item-text-1 .title a {
  color: inherit;
}

.listing-item-text-1 .post-meta {
  padding-top: 6px;
  text-align: center;
}

.listing-item-text-1 .post-summary {
  padding-top: 4px;
  text-align: center;
}

.listing-text-1.listing.columns-1 .listing-item:last-child {
  margin-bottom: 0;
}

.listing-text-1.listing.columns-1 .listing-item:last-child .item-inner {
  border-bottom: none;
}

.listing-item-text-1 .post-subtitle {
  text-align: center !important;
  margin: 5px 0 0;
}

.listing-item-text-1 .post-subtitle + .post-meta {
  padding-top: 5px;
}

.listing-item-text-1 .post-subtitle + .title {
  margin-top: 6px;
}

.listing-item-text-1 .post-subtitle:first-child {
  margin-top: 0;
}

.listing-item-text-1 .post-count-badge.pcb-t2.pcb-s1 {
  position: absolute;
  top: 0;
  left: 0;
}

.listing-item-text-2 {
  position: relative;
  margin-bottom: 15px;
}

.listing-item-text-2:last-child {
  margin-bottom: 0;
}

.listing-item-text-2 .item-inner {
  border-left: 3px solid rgba(0, 0, 0, 0.11);
  -webkit-transition: 0.7s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -moz-transition: 0.7s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  -o-transition: 0.7s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  transition: 0.3s cubic-bezier(0.55, -0.01, 0.19, 0.98) 0s;
  padding: 6px 0 6px 14px;
}

.listing-item-text-2 .term-badges.floated {
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  text-align: center;
  margin-bottom: 10px;
}

.listing-item-text-2 .term-badges.floated .term-badge a {
  background: #434343 !important;
  color: #fff !important;
  margin: 0;
}

.listing-item-text-2 .title {
  margin: 0;
}

.listing-item-text-2 .title a {
  color: inherit;
}

.listing-item-text-2 .post-meta, .listing-item-text-2 .post-meta .comments, .listing-item-text-2 .post-meta .post-author {
  font-size: 11px;
}

.listing-text-2.listing.columns-1 .listing-item:last-child {
  margin-bottom: 0;
}

.listing-text-2.listing.columns-1 .listing-item:last-child .item-inner {
  border-bottom: none;
}

.listing-item-text-2 .post-subtitle {
  margin-top: 4px;
}

.listing-item-text-2 .post-subtitle:first-child {
  margin-top: 0;
}

.listing-item-text-2 .post-subtitle + .title {
  margin-top: 4px;
}

.listing-item-text-2 .post-summary {
  padding-top: 6px;
}

.listing-item-text-2 .post-count-badge.pcb-t2.pcb-s2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.listing-item-text-3 {
  margin-bottom: 13px;
}

.listing-item-text-3 .item-inner {
  padding-bottom: 13px !important;
  border-bottom: 1px solid #f0f0f0;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  position: relative;
}

.listing-item-text-3:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.listing-item-text-3 .title {
  margin: 0;
}

.listing-item-text-3 .title a {
  color: inherit;
}

.listing-item-text-3 .post-meta {
  margin-top: 2px;
}

.listing-item-text-3 .post-summary {
  margin-top: 4px;
}

.content-column > .listing-text-3 .listing-item-text-3 {
  margin-bottom: 17px;
  padding-bottom: 17px !important;
}

.listing-item-text-3 .post-subtitle {
  margin-top: 4px;
}

.listing-item-text-3 .post-subtitle:first-child {
  margin-top: 0;
}

.listing-item-text-3 .post-subtitle + .title {
  margin-top: 4px;
}

.listing-item-text-3.has-counter-badge .item-inner {
  padding-left: 43px;
}

.listing-item-text-3.has-counter-badge .post-count-badge.pcb-t1.pcb-s2 {
  position: absolute;
  top: 3px;
  left: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
}

.listing-item-text-4 {
  margin-bottom: 13px;
}

.listing-item-text-4 .item-inner {
  padding-bottom: 13px !important;
  border-bottom: 1px solid #f0f0f0;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  position: relative;
}

.listing-item-text-4:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.listing-text-4.columns-1 .listing-item-text-4:last-child {
  margin-bottom: 0;
}

.listing-text-4.columns-1 .listing-item-text-4:last-child .item-inner {
  border-bottom: none;
  padding-bottom: 0;
}

.listing-item-text-4 .title {
  margin: 0;
}

.listing-item-text-4 .title a {
  color: inherit;
}

.listing-item-text-4 .post-meta {
  margin-top: 2px;
}

.listing-item-text-4 .post-summary {
  margin-top: 4px;
}

.listing-item-text-4 .term-badges.text-badges {
  margin-bottom: 6px;
}

.content-column > .listing-text-4 .listing-item-text-4 {
  margin-bottom: 17px;
  padding-bottom: 17px !important;
}

.listing-item-text-4 .post-subtitle {
  margin-top: 4px;
}

.listing-item-text-4 .post-subtitle:first-child {
  margin-top: 0;
}

.listing-item-text-4 .post-subtitle + .title {
  margin-top: 4px;
}

.listing-item-text-4.has-counter-badge .item-inner {
  padding-left: 43px;
}

.listing-item-text-4.has-counter-badge .post-count-badge.pcb-t1.pcb-s2 {
  position: absolute;
  top: 5px;
  left: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
}

.bs-listing p:empty {
  display: none;
}

.listing-mix-1-1 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing-mix-1-1 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing-mix-1-1 .listing-item-tb-1 {
  margin-bottom: 27px;
}

.listing-mix-1-1 .listing-item-tb-1:last-child {
  margin-bottom: 0;
}

.listing-mix-1-1 .column-1 {
  width: 50%;
  float: left;
  padding-right: 18px;
}

.listing-mix-1-1 .column-2 {
  width: 50%;
  float: left;
  padding-left: 18px;
}

.listing.listing-mix-1-1.l-1-col .column-1, .listing.listing-mix-1-1.l-1-col .column-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing.listing-mix-1-1.l-1-col .column-1 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 450px) {
  .listing.listing-mix-1-1 .column-1, .listing.listing-mix-1-1 .column-2 {
    width: 100%;
    padding: 0;
    float: none;
  }
  .listing.listing-mix-1-1 .column-1 {
    margin-bottom: 24px;
  }
}
.listing.listing-mix-1-2 .listing-item-grid-1 {
  margin-bottom: 30px;
}

.listing.listing-mix-1-2 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing.listing-mix-1-2 .listing-item-tb-1 {
  margin-bottom: 7px;
}

.listing.listing-mix-1-2 .column-1 {
  width: 50%;
  float: left;
  padding-right: 18px;
}

.listing.listing-mix-1-2 .column-2 {
  width: 50%;
  float: left;
  padding-left: 18px;
}

.bs-listing-listing-mix-1-2 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.listing.listing-mix-1-2.l-1-col .column-1, .listing.listing-mix-1-2.l-1-col .column-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing.listing-mix-1-2.l-1-col .column-1 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 450px) {
  .listing.listing-mix-1-2 .column-1, .listing.listing-mix-1-2 .column-2 {
    width: 100% !important;
    padding: 0 !important;
    float: none !important;
  }
  .listing.listing-mix-1-2 .column-1 {
    margin-bottom: 20px;
  }
}
.listing.listing-mix-1-4 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing.listing-mix-1-4 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing.listing-mix-1-4 .column-1 {
  width: 70%;
  float: left;
  padding-right: 18px;
}

.listing.listing-mix-1-4 .column-2 {
  width: 30%;
  float: left;
  padding-left: 18px;
}

.listing.listing-mix-1-4.l-1-col .column-1, .listing.listing-mix-1-4.l-1-col .column-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing.listing-mix-1-4.l-1-col .listing-item-grid-1 {
  margin-bottom: 20px;
}

.listing.listing-mix-1-4.l-1-col .listing-item-tb-2 {
  width: 50%;
  float: left;
}

.listing.listing-mix-1-4.l-1-col .listing-item-tb-2:nth-child(odd) {
  padding-right: 13px;
  clear: left;
}

.listing.listing-mix-1-4.l-1-col .listing-item-tb-2:nth-child(even) {
  padding-left: 13px;
}

@media only screen and (max-width: 450px) {
  .listing.listing-mix-1-4 .column-1, .listing.listing-mix-1-4 .column-2 {
    width: 100% !important;
    padding: 0 !important;
    float: none !important;
  }
  .listing.listing-mix-1-4 .listing-item-grid-1 {
    margin-bottom: 20px;
  }
  .listing.listing-mix-1-4 .listing-item-tb-2 {
    width: 50%;
    float: left;
  }
  .listing.listing-mix-1-4 .listing-item-tb-2:nth-child(odd) {
    padding-right: 13px;
    clear: left;
  }
  .listing.listing-mix-1-4 .listing-item-tb-2:nth-child(even) {
    padding-left: 13px;
  }
}
.listing-mix-2-1 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.bs-listing-listing-mix-2-1 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.listing-mix-2-1.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  float: none;
  padding: 0 !important;
}

@media only screen and (max-width: 450px) {
  .listing-mix-2-1 .listing.columns-2 .listing-item {
    width: 100%;
    float: none;
    padding: 0 !important;
  }
}
.listing-mix-2-2 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing-mix-2-2.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  float: none;
  padding: 0 !important;
}

@media only screen and (max-width: 450px) {
  .listing-mix-2-2 .listing.columns-2 .listing-item {
    width: 100%;
    float: none;
    padding: 0 !important;
  }
}
.listing.listing-mix-3-1 .row-1 + .row-2 {
  margin-top: 23px;
}

.listing.listing-mix-3-1 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing.listing-mix-3-2 .row-1:last-child {
  margin-bottom: 20px;
}

.listing.listing-mix-3-2 .row-1 + .row-2 {
  margin-top: 23px;
}

.listing.listing-mix-3-2 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing.listing-mix-3-2 .listing-item-tb-2 {
  margin-bottom: 25px;
}

.infinity > .listing-mix-3-2 + .listing-mix-3-2, .more_btn > .listing-mix-3-2 + .listing-mix-3-2 {
  margin-top: 0;
}

.listing.listing-mix-3-3 .row-1 + .row-2 {
  margin-top: 23px;
}

.listing.listing-mix-3-3 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing.listing-mix-3-3 .listing-item-text-2 {
  margin-bottom: 25px;
}

.listing-mix-3-3.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  float: none;
  padding: 0 !important;
}

@media only screen and (max-width: 450px) {
  .listing-mix-3-3 .listing.columns-2 .listing-item {
    width: 100%;
    float: none;
    padding: 0 !important;
  }
}
.listing.listing-mix-3-4 .row-1 + .row-2 {
  margin-top: 23px;
}

.listing.listing-mix-3-4 .listing-item-tb-1:last-child, .listing.listing-mix-3-4 .listing-mg-3-item {
  margin-bottom: 0;
}

.listing.listing-mix-3-4 .listing-item-tb-1 {
  margin-bottom: 25px;
}

.listing-mix-6 .listing-item-grid-1 {
  margin-bottom: 0;
}

.listing-mix-6 .listing-item-grid-1 .post-summary {
  padding-bottom: 0;
  border-bottom: none;
}

.listing-mix-6 .listing-item-text:last-child {
  margin-bottom: 0;
}

.listing-mix-6 .column-1 {
  width: 50%;
  float: left;
  padding-right: 18px;
}

.listing-mix-6 .column-2 {
  width: 50%;
  float: left;
  padding-left: 18px;
}

.listing.listing-mix-6.l-1-col .column-1, .listing.listing-mix-6.l-1-col .column-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing.listing-mix-6.l-1-col .column-1 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 450px) {
  .listing.listing-mix-6 .column-1, .listing.listing-mix-6 .column-2 {
    width: 100%;
    padding: 0;
    float: none;
  }
  .listing.listing-mix-6 .column-1 {
    margin-bottom: 20px;
  }
}
.listing-mix-4-1 .listing-classic-3 {
  margin-bottom: 15px;
}

.bs-pagination-wrapper .listing-mix-4-1 + .listing-mix-4-1 {
  margin-top: 0;
}

.listing.listing-mix-4-1.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-1 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-2 .listing-classic-3 {
  margin-bottom: 15px;
}

.bs-listing-listing-mix-4-2 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.listing.listing-mix-4-2.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-2 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-3 .listing-classic-2 {
  margin-bottom: 25px;
}

.bs-pagination-wrapper .listing-mix-4-3 + .listing-mix-4-3 {
  margin-top: 0;
}

.listing.listing-mix-4-3.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-3 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-4 .listing-classic-2 {
  margin-bottom: 25px;
  margin-top: 0 !important;
}

.bs-pagination-wrapper .listing-mix-4-4 + .listing-mix-4-4 {
  margin-top: 0;
}

.listing.listing-mix-4-4.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-4 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-5 .listing-classic-2.columns-2 .listing-item-classic-2 {
  margin-bottom: 30px !important;
}

.listing-mix-4-5 .listing-classic-2 {
  margin-bottom: 30px;
}

.listing-mix-4-5 .listing-classic-2:last-child {
  margin-bottom: 0;
}

.bs-listing-listing-mix-4-5 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.listing.listing-mix-4-5.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-5 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-6 .listing-classic-2.columns-2 .listing-item-classic-2 {
  margin-bottom: 30px !important;
}

.listing-mix-4-6 .listing-classic-2 {
  margin-bottom: 30px;
}

.listing-mix-4-6 .listing-classic-2.columns-2 {
  margin-bottom: 0;
}

.bs-listing-listing-mix-4-6 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.listing.listing-mix-4-6.l-1-col .listing.columns-2 .listing-item {
  width: 100%;
  padding: 0;
  float: none;
}

@media only screen and (max-width: 500px) {
  .listing.listing-mix-4-6 .listing.columns-2 .listing-item {
    width: 100%;
    padding: 0;
    float: none;
  }
}
.listing-mix-4-7 .listing-classic-3 {
  margin-bottom: 0;
}

.bs-listing-listing-mix-4-7 .bs-pagination-wrapper .listing + .listing {
  margin-top: 0;
}

.bs-listing-listing-mix-4-7 .bs-pagination-wrapper .listing-mix-4-7 + .listing-mix-4-7.listing-mix-4-7 {
  margin-top: 20px;
}

.listing-mix-4-8 .listing-classic-2 {
  margin-bottom: 25px;
}

.listing-mg-item {
  position: relative;
  overflow: hidden;
}

.listing-mg-item.has-not-post-thumbnail .img-cont {
  background-color: #e4e4e4;
}

.listing-mg-1-item .item-content, .listing-mg-item .content-container, .listing-mg-item .term-badges.floated, .listing-modern-grid .bs-slider-item .item-content {
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

.listing-mg-1-item .img-cont {
  -webkit-backface-visibility: hidden;
}

.listing-mg-item .post-meta .views.views.views {
  color: #fff !important;
}

.listing-mg-item.listing-mg-5-item .post-meta.post-meta .views.views.views {
  padding: 0;
  background: 0 0;
}

.listing-mg-item .post-meta .views {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 1px 6px;
  vertical-align: middle;
}

.listing-modern-grid.slider-overlay-simple .listing-mg-item .content-container {
  padding: 22px 20px 20px;
  background: rgba(0, 0, 0, 0.42);
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.listing-modern-grid.slider-overlay-simple-gr .listing-mg-item .img-cont:after {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.65)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  top: auto;
  height: 75%;
}

.listing-modern-grid.slider-overlay-colored .listing-item-1 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-17 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-9 .img-cont:after {
  background: #dc4225;
  background: -moz-linear-gradient(45deg, #292484 0, #dc4225 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #292484), color-stop(100%, #dc4225));
  background: -webkit-linear-gradient(45deg, #292484 0, #dc4225 100%);
  background: -o-linear-gradient(45deg, #292484 0, #dc4225 100%);
  background: -ms-linear-gradient(45deg, #292484 0, #dc4225 100%);
  background: linear-gradient(45deg, #292484 0, #dc4225 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-10 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-18 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-2 .img-cont:after {
  background: #26d0ce;
  background: -moz-linear-gradient(45deg, #1a2980 0, #26d0ce 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #1a2980), color-stop(100%, #26d0ce));
  background: -webkit-linear-gradient(45deg, #1a2980 0, #26d0ce 100%);
  background: -o-linear-gradient(45deg, #1a2980 0, #26d0ce 100%);
  background: -ms-linear-gradient(45deg, #1a2980 0, #26d0ce 100%);
  background: linear-gradient(45deg, #1a2980 0, #26d0ce 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-11 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-19 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-3 .img-cont:after {
  background: #1d976c;
  background: -moz-linear-gradient(45deg, #1d976c 0, #3cf93d 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #1d976c), color-stop(100%, #3cf93d));
  background: -webkit-linear-gradient(45deg, #1d976c 0, #3cf93d 100%);
  background: -o-linear-gradient(45deg, #1d976c 0, #3cf93d 100%);
  background: -ms-linear-gradient(45deg, #1d976c 0, #3cf93d 100%);
  background: linear-gradient(45deg, #1d976c 0, #3cf93d 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-12 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-20 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-4 .img-cont:after {
  background: #dc4225;
  background: -moz-linear-gradient(45deg, #e0be00 0, #dc4225 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #e0be00), color-stop(100%, #dc4225));
  background: -webkit-linear-gradient(45deg, #e0be00 0, #dc4225 100%);
  background: -o-linear-gradient(45deg, #e0be00 0, #dc4225 100%);
  background: -ms-linear-gradient(45deg, #e0be00 0, #dc4225 100%);
  background: linear-gradient(45deg, #e0be00 0, #dc4225 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-13 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-21 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-5 .img-cont:after {
  background: #ff4f39;
  background: -moz-linear-gradient(45deg, #ff4f39 0, #dd0098 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #ff4f39), color-stop(100%, #dd0098));
  background: -webkit-linear-gradient(45deg, #ff4f39 0, #dd0098 100%);
  background: -o-linear-gradient(45deg, #ff4f39 0, #dd0098 100%);
  background: -ms-linear-gradient(45deg, #ff4f39 0, #dd0098 100%);
  background: linear-gradient(45deg, #ff4f39 0, #dd0098 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-14 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-22 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-6 .img-cont:after {
  background: #3895ff;
  background: -moz-linear-gradient(45deg, #3895ff 0, #2add28 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #3895ff), color-stop(100%, #2add28));
  background: -webkit-linear-gradient(45deg, #3895ff 0, #2add28 100%);
  background: -o-linear-gradient(45deg, #3895ff 0, #2add28 100%);
  background: -ms-linear-gradient(45deg, #3895ff 0, #2add28 100%);
  background: linear-gradient(45deg, #3895ff 0, #2add28 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-15 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-23 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-7 .img-cont:after {
  background: #7741d5;
  background: -moz-linear-gradient(45deg, #7741d5 0, #c56200 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #7741d5), color-stop(100%, #c56200));
  background: -webkit-linear-gradient(45deg, #7741d5 0, #c56200 100%);
  background: -o-linear-gradient(45deg, #7741d5 0, #c56200 100%);
  background: -ms-linear-gradient(45deg, #7741d5 0, #c56200 100%);
  background: linear-gradient(45deg, #7741d5 0, #c56200 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-item-16 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-24 .img-cont:after, .listing-modern-grid.slider-overlay-colored .listing-item-8 .img-cont:after {
  background: #06c67b;
  background: -moz-linear-gradient(45deg, #06c67b 0, #076fc5 100%);
  background: -webkit-gradient(left bottom, right top, color-stop(0, #06c67b), color-stop(100%, #076fc5));
  background: -webkit-linear-gradient(45deg, #06c67b 0, #076fc5 100%);
  background: -o-linear-gradient(45deg, #06c67b 0, #076fc5 100%);
  background: -ms-linear-gradient(45deg, #06c67b 0, #076fc5 100%);
  background: linear-gradient(45deg, #06c67b 0, #076fc5 100%);
}

.listing-modern-grid.slider-overlay-colored .listing-mg-item .img-cont:after {
  opacity: 0.7;
}

.listing-modern-grid.slider-overlay-colored .listing-mg-item:hover .img-cont:after {
  opacity: 0.53;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-mg-item .img-cont:after {
  background-size: 300% 300%;
  -webkit-animation: mg-gr-anim 5.5s ease-in infinite;
  -moz-animation: mg-gr-anim 5.5s ease-in infinite;
  -o-animation: mg-gr-anim 5.5s ease-in infinite;
  animation: mg-gr-anim 5.5s ease-in infinite;
  opacity: 0.5;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-mg-item:hover .img-cont:after {
  opacity: 0.7;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-1 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-17 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-9 .img-cont:after {
  background: linear-gradient(135deg, #292484, #dc4225);
  background-size: 500% 500%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-10 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-18 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-2 .img-cont:after {
  background: linear-gradient(135deg, #1a2980, #26d0ce);
  background-size: 500% 500%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-11 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-19 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-3 .img-cont:after {
  background: linear-gradient(135deg, #1d976c, #3cf93d);
  background-size: 500% 500%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-12 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-20 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-4 .img-cont:after {
  background: linear-gradient(135deg, #e0be00, #dc4225);
  background-size: 500% 500%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-13 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-21 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-5 .img-cont:after {
  background: linear-gradient(135deg, #ff4f39, #dd0098);
  background-size: 1000% 1000%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-14 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-22 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-6 .img-cont:after {
  background: linear-gradient(135deg, #06c67b, #076fc5);
  background-size: 1000% 1000%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-15 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-23 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-7 .img-cont:after {
  background: linear-gradient(135deg, #7741d5, #c56200);
  background-size: 1000% 1000%;
}

.listing-modern-grid.slider-overlay-colored-anim .listing-item-16 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-24 .img-cont:after, .listing-modern-grid.slider-overlay-colored-anim .listing-item-8 .img-cont:after {
  background: linear-gradient(135deg, #3895ff, #2add28);
  background-size: 1000% 1000%;
}

@-webkit-keyframes mg-gr-anim {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@-moz-keyframes mg-gr-anim {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@-o-keyframes mg-gr-anim {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes mg-gr-anim {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}
.listing-mg-item.listing-mg-1-item.listing-mg-1-item:hover .title a, .listing-mg-item.listing-mg-2-item.listing-mg-2-item:hover .title a, .listing-mg-item.listing-mg-3-item.listing-mg-3-item:hover .title a, .listing-mg-item.listing-mg-4-item.listing-mg-4-item:hover .title a, .listing-mg-item.listing-mg-6-item.listing-mg-6-item:hover .title a {
  color: #fff !important;
}

.listing-modern-grid-1.l-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-1.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-1.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-1.l-5 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-5 .listing-mg-item:hover .format-icon, .listing-modern-grid-3.l-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-3.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-3.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-3.l-5 .listing-mg-item:hover .format-icon, .listing-modern-grid-4.l-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-4.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-4.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-4.l-5 .listing-mg-item:hover .format-icon, .listing-modern-grid-6.l-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-6.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-6.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-6.l-5 .listing-mg-item:hover .format-icon {
  right: 20px;
  top: 20px;
  margin-top: 0;
  margin-right: 0;
  transform: none;
}

.listing-mg-item:hover .format-icon {
  right: 50%;
  top: 50%;
  margin-top: -16px;
  margin-right: -16px;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  transform: scale(1.4);
}

.listing-item-3.listing-mg-1-item:hover .format-icon, .listing-item-4.listing-mg-1-item:hover .format-icon {
  margin-top: -30px;
}

.listing-item-2.listing-mg-2-item:hover .format-icon, .listing-item-3.listing-mg-2-item:hover .format-icon, .listing-item-4.listing-mg-2-item:hover .format-icon, .listing-item-5.listing-mg-2-item:hover .format-icon {
  margin-top: -25px;
}

.listing-mg-3-item:hover .format-icon {
  top: 27px;
  margin-top: 0;
}

.infinity > .listing-modern-grid-3 + .listing-modern-grid-3, .more_btn > .listing-modern-grid-3 + .listing-modern-grid-3 {
  margin-top: 0;
}

body.bs-ll-a .listing-mg-type-1 .img-cont.img-cont {
  background-size: inherit !important;
  transition: none;
  transition: all 0.35s;
}

body.bs-ll-a .listing-mg-type-1 .img-cont.img-cont.b-error, body.bs-ll-a .listing-mg-type-1 .img-cont.img-cont.b-loaded, body.bs-ll-d .listing-mg-type-1 .img-cont.img-cont {
  background-size: cover !important;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-animation: none;
  animation: none;
}

.listing-mg-item .post-subtitle {
  -webkit-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  -moz-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.listing-mg-item .post-subtitle, .listing-mg-item .post-subtitle a {
  color: #fff !important;
}

.listing-mg-item .post-subtitle {
  margin-top: 10px;
}

.listing-mg-item .post-subtitle + .post-meta {
  margin-top: 5px;
}

.listing-mg-item .post-subtitle:first-child {
  margin: 0 0 7px;
}

@media only screen and (max-width: 480px) {
  .listing-modern-grid-1 .listing-mg-item .format-icon, .listing-modern-grid-1 .listing-mg-item:hover .format-icon, .listing-modern-grid-2 .listing-mg-item .format-icon, .listing-modern-grid-2 .listing-mg-item:hover .format-icon, .listing-modern-grid-3 .listing-mg-item .format-icon, .listing-modern-grid-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-4 .listing-mg-item .format-icon, .listing-modern-grid-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-6 .listing-mg-item .format-icon, .listing-modern-grid-6 .listing-mg-item:hover .format-icon {
    right: 20px;
    top: 20px;
    margin-top: 0;
    margin-right: 0;
    transform: none;
  }
}
.listing-mg-type-1:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 68.17%;
}

.listing-mg-type-1 .item-content, .listing-modern-grid .bs-slider-item .listing-mg-type-1 .item-content {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.listing-mg-type-1 .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: -1px;
  left: -2px;
  right: -2px;
  bottom: -1px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  -moz-transform-origin: 0 0;
}

.listing-mg-type-1 .img-cont.b-error, .listing-mg-type-1 .img-cont.b-loaded, body.bs-ll-d .listing-mg-type-1 .img-cont {
  background-size: cover !important;
}

.listing-mg-type-1 .img-cont:after {
  -webkit-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-mg-type-1:hover .img-cont {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.listing-mg-type-1 .content-container {
  position: absolute;
  bottom: 15px;
  left: 20px;
  right: 20px;
  padding: 0;
  max-height: 75%;
  overflow: hidden;
  pointer-events: none;
}

.listing-mg-type-1 .content-container a {
  pointer-events: all;
}

.listing-mg-type-1 .title {
  margin: 0;
  -webkit-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  -moz-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
}

.listing-item.listing-mg-type-1 .title.title a.post-url.post-url, .listing-item.listing-mg-type-1 .title.title a.post-url.post-url:hover {
  color: inherit !important;
}

.listing-mg-type-1 .post-meta {
  margin-top: 3px;
}

.listing-mg-type-1 .post-meta .post-author {
  color: #fff;
}

.listing-mg-type-1 .post-meta .comments {
  color: #fff;
  float: right;
  margin-right: 0;
  margin-left: 15px;
}

.listing-mg-type-1 .post-meta .rating + .comments {
  float: none;
  margin-left: 0;
  vertical-align: middle;
}

.listing-mg-type-1 .post-meta .post-author:after, .listing-mg-type-1 .post-meta .time {
  color: rgba(255, 255, 255, 0.57);
}

.listing-mg-1-item.listing-item-2 .title, .listing-mg-1-item.listing-item-3 .title, .listing-mg-1-item.listing-item-4 .title, .listing-mg-1-item.listing-item-5 .title {
  font-size: 72%;
}

.listing-mg-1-item.listing-item-2 .title {
  font-size: 80%;
}

.listing-modern-grid-1 .mg-col-1 {
  width: 56%;
  float: left;
  padding-right: 1px;
  overflow: hidden;
}

.listing-modern-grid-1 .mg-col-2 {
  width: 44%;
  float: left;
  padding-left: 1px;
  overflow: hidden;
}

.listing-modern-grid-1 .mg-row-1 {
  margin: 0 0 2px;
  overflow: hidden;
}

.listing-mg-1-item.listing-item-2:before {
  padding-top: 47.29%;
}

.listing-modern-grid-1 .item-3-cont, .listing-modern-grid-1 .item-4-cont {
  width: 50%;
  float: left;
  overflow: hidden;
}

.listing-modern-grid-1 .item-3-cont {
  padding-right: 1px;
}

.listing-modern-grid-1 .item-4-cont {
  padding-left: 1px;
}

.listing-mg-1-item.listing-item-3:before, .listing-mg-1-item.listing-item-4:before {
  padding-top: 78.54%;
}

.listing-modern-grid-1.l-1 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1.l-1 .listing-mg-1-item.listing-item-4:before {
  padding-top: 78.43%;
}

.listing-modern-grid-1.l-2 .listing-mg-1-item:before {
  padding-top: 89%;
}

.listing-modern-grid-1.l-2 .listing-mg-1-item.listing-item-2:before {
  padding-top: 63.74%;
}

.listing-modern-grid-1.l-2 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1.l-2 .listing-mg-1-item.listing-item-4:before {
  padding-top: 98.58%;
}

.listing-modern-grid-1.l-2 .listing-item-1 .title, .listing-modern-grid-1.l-3 .listing-item-1 .title, .listing-modern-grid-1.l-4 .listing-item-1 .title, .listing-modern-grid-1.l-5 .listing-item-1 .title {
  font-size: 80%;
}

.listing-modern-grid-1.l-2 .listing-item-2 .title {
  font-size: 72%;
}

.listing-modern-grid-1.l-2 .listing-item-3 .title, .listing-modern-grid-1.l-2 .listing-item-4 .title, .listing-modern-grid-1.l-3 .listing-item-3 .title, .listing-modern-grid-1.l-3 .listing-item-4 .title, .listing-modern-grid-1.l-4 .listing-item-3 .title, .listing-modern-grid-1.l-4 .listing-item-4 .title, .listing-modern-grid-1.l-5 .listing-item-3 .title, .listing-modern-grid-1.l-5 .listing-item-4 .title {
  font-size: 62%;
}

.listing-modern-grid-1.l-2 .listing-item-3 .term-badges, .listing-modern-grid-1.l-2 .listing-item-4 .term-badges, .listing-modern-grid-1.l-3 .listing-item-4 .term-badges, .listing-modern-grid-1.l-4 .listing-item-4 .term-badges, .listing-modern-grid-1.l-5 .listing-item-4 .term-badges {
  display: none;
}

.listing-modern-grid-1.l-2 .listing-mg-item .content-container, .listing-modern-grid-1.l-3 .listing-mg-item .content-container, .listing-modern-grid-1.l-4 .listing-mg-item .content-container, .listing-modern-grid-1.l-5 .listing-mg-item .content-container {
  left: 15px;
  right: 15px;
  bottom: 10px;
  max-height: 50%;
}

.listing-modern-grid-1.l-3 .listing-mg-item .format-icon, .listing-modern-grid-1.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-1.l-4 .listing-mg-item .format-icon, .listing-modern-grid-1.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-1.l-5 .listing-mg-item .format-icon, .listing-modern-grid-1.l-5 .listing-mg-item:hover .format-icon {
  right: 12px;
  top: 12px;
}

.listing-modern-grid-1.l-3 .mg-col-1, .listing-modern-grid-1.l-5 .mg-col-1 {
  width: 100%;
  padding: 0;
}

.listing-modern-grid-1.l-3 .mg-col-2, .listing-modern-grid-1.l-5 .mg-col-2 {
  width: 100%;
  padding: 2px 0 0 0;
}

.listing-modern-grid-1.l-3 .mg-row-1, .listing-modern-grid-1.l-5 .mg-row-1 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing-modern-grid-1.l-3 .mg-row-2, .listing-modern-grid-1.l-5 .mg-row-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing-modern-grid-1.l-3 .listing-item-1:before, .listing-modern-grid-1.l-3 .listing-mg-1-item.listing-item-2:before, .listing-modern-grid-1.l-4 .listing-item-1:before, .listing-modern-grid-1.l-4 .listing-mg-1-item.listing-item-2:before, .listing-modern-grid-1.l-5 .listing-item-1:before, .listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-2:before {
  padding-top: 46%;
}

.listing-modern-grid-1.l-3 .listing-item-2 .title, .listing-modern-grid-1.l-4 .listing-item-2 .title, .listing-modern-grid-1.l-5 .listing-item-2 .title {
  font-size: 72%;
}

.listing-modern-grid-1.l-3 .listing-item-2 .post-meta, .listing-modern-grid-1.l-3 .listing-item-3 .term-badges.floated, .listing-modern-grid-1.l-3 .listing-item-4 .term-badges.floated {
  display: none;
}

.listing-modern-grid-1.l-4 .listing-mg-1-item.listing-item-1:before {
  padding-top: 50%;
}

.listing-modern-grid-1.l-4 .listing-mg-1-item.listing-item-2 .term-badges.floated {
  display: none;
}

.listing-modern-grid-1.l-4 .listing-mg-1-item.listing-item-2:before {
  padding-top: 38%;
}

.listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-1:before {
  padding-top: 57%;
}

.listing-modern-grid-1.l-5 .listing-item-2 .post-meta, .listing-modern-grid-1.l-5 .listing-item-3 .term-badges.floated, .listing-modern-grid-1.l-5 .listing-item-4 .term-badges.floated, .listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-1 .post-meta, .listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-2 .term-badges.floated {
  display: none;
}

.listing-modern-grid-1.l-5 .listing-item-2 .title {
  font-size: 64%;
}

.listing-modern-grid-1.l-5 .listing-mg-item .content-container {
  left: 12px;
  right: 12px;
  bottom: 12px;
}

.listing-modern-grid-1.l-5 .listing-mg-item.listing-item-2 .content-container {
  max-height: 40%;
}

.slider-overlay-simple.listing-modern-grid-1.l-5 .listing-mg-item.listing-item-2 .content-container {
  max-height: 60%;
  padding: 12px 12px 10px;
}

.listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-4:before {
  padding-top: 88%;
}

.listing-modern-grid-1.l-5 .listing-mg-1-item.listing-item-2:before {
  padding-top: 50.5%;
}

@media only screen and (max-width: 980px) {
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1 .listing-mg-1-item.listing-item-4:before {
    padding-top: 78.43%;
  }
}
@media only screen and (max-width: 780px) {
  .listing-modern-grid-1 .listing-mg-1-item:before {
    padding-top: 89%;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-2:before {
    padding-top: 63.74%;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1 .listing-mg-1-item.listing-item-4:before {
    padding-top: 98.58%;
  }
  .listing-modern-grid-1 .listing-item-1 .title {
    font-size: 80%;
  }
  .listing-modern-grid-1 .listing-item-2 .title {
    font-size: 72%;
  }
  .listing-modern-grid-1 .listing-item-3 .title, .listing-modern-grid-1 .listing-item-4 .title {
    font-size: 62%;
  }
  .listing-modern-grid-1 .listing-item-3 .term-badges, .listing-modern-grid-1 .listing-item-4 .term-badges {
    display: none;
  }
  .listing-modern-grid-1 .listing-mg-item .content-container {
    left: 15px;
    right: 15px;
    bottom: 10px;
    max-height: 50%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-1 .listing-mg-item .format-icon, .listing-modern-grid-1 .listing-mg-item:hover .format-icon {
    right: 12px;
    top: 12px;
  }
  .listing-modern-grid-1 .mg-col-1 {
    width: 100%;
    padding: 0;
  }
  .listing-modern-grid-1 .mg-col-2 {
    width: 100%;
    padding: 2px 0 0 0;
  }
  .listing-modern-grid-1 .mg-row-1 {
    width: 100%;
    padding: 0;
    float: none;
  }
  .listing-modern-grid-1 .mg-row-2 {
    width: 100%;
    padding: 0;
    float: none;
  }
  .listing-modern-grid-1 .listing-item-1:before, .listing-modern-grid-1 .listing-mg-1-item.listing-item-2:before {
    padding-top: 46%;
  }
  .listing-modern-grid-1 .listing-item-2 .title {
    font-size: 72%;
  }
  .listing-modern-grid-1 .listing-item-2 .post-meta, .listing-modern-grid-1 .listing-item-3 .term-badges.floated, .listing-modern-grid-1 .listing-item-4 .term-badges.floated {
    display: none;
  }
}
@media only screen and (max-width: 560px) {
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-1:before {
    padding-top: 50%;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-2 .term-badges.floated {
    display: none;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-2:before {
    padding-top: 38%;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-2:before {
    padding-top: 50.5%;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-1:before {
    padding-top: 57%;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-1 .post-meta {
    display: none;
  }
  .listing-modern-grid-1 .listing-item-2 .title {
    font-size: 64%;
  }
  .listing-modern-grid-1 .listing-mg-item .content-container {
    left: 12px;
    right: 12px;
    bottom: 12px;
  }
  .listing-modern-grid-1 .listing-mg-item.listing-item-2 .content-container {
    max-height: 40%;
  }
  .slider-overlay-simple.listing-modern-grid-1 .listing-mg-item.listing-item-2 .content-container {
    max-height: 60%;
    padding: 12px 12px 10px;
  }
  .listing-modern-grid-1 .listing-mg-1-item.listing-item-3:before, .listing-modern-grid-1 .listing-mg-1-item.listing-item-4:before {
    padding-top: 88%;
  }
}
.listing-mg-2-item.listing-item-2 .title, .listing-mg-2-item.listing-item-3 .title, .listing-mg-2-item.listing-item-4 .title, .listing-mg-2-item.listing-item-5 .title {
  font-size: 72%;
}

.listing-mg-2-item:before {
  padding-top: 77.8%;
}

.listing-mg-2-item.listing-item-1:before {
  padding-top: 69.05%;
}

.listing-modern-grid-2 .mg-col-1 {
  width: 53%;
  float: left;
  padding-right: 1px;
}

.listing-modern-grid-2 .mg-col-2 {
  width: 47%;
  float: left;
  padding-left: 1px;
}

.listing-modern-grid-2 .item-2-cont, .listing-modern-grid-2 .item-3-cont, .listing-modern-grid-2 .item-4-cont, .listing-modern-grid-2 .item-5-cont {
  width: 50%;
  float: left;
}

.listing-modern-grid-2 .item-2-cont, .listing-modern-grid-2 .item-4-cont {
  padding-right: 1px;
}

.listing-modern-grid-2 .item-3-cont, .listing-modern-grid-2 .item-5-cont {
  padding-left: 1px;
}

.listing-modern-grid-2 .mg-row-1 {
  margin-bottom: 2px;
}

.listing-modern-grid-2.l-1 .listing-mg-item .content-container, .listing-modern-grid-2.l-2 .listing-mg-item .content-container, .listing-modern-grid-2.l-3 .listing-mg-item .content-container, .listing-modern-grid-2.l-4 .listing-mg-item .content-container, .listing-modern-grid-2.l-5 .listing-mg-item .content-container {
  left: 15px;
  right: 15px;
  bottom: 12px;
  max-height: 50%;
}

.listing-modern-grid-2.l-2 .mg-col-1, .listing-modern-grid-2.l-2 .mg-col-2, .listing-modern-grid-2.l-3 .mg-col-1, .listing-modern-grid-2.l-3 .mg-col-2, .listing-modern-grid-2.l-4 .mg-col-1, .listing-modern-grid-2.l-4 .mg-col-2, .listing-modern-grid-2.l-5 .mg-col-1, .listing-modern-grid-2.l-5 .mg-col-2 {
  width: 50%;
}

.listing-modern-grid-2.l-2 .listing-mg-item:before, .listing-modern-grid-2.l-3 .listing-mg-item:before, .listing-modern-grid-2.l-4 .listing-mg-item:before, .listing-modern-grid-2.l-5 .listing-mg-item:before {
  padding-top: 92.97%;
}

.listing-modern-grid-2.l-2 .listing-item-1:before, .listing-modern-grid-2.l-3 .listing-item-1:before, .listing-modern-grid-2.l-4 .listing-item-1:before, .listing-modern-grid-2.l-5 .listing-item-1:before {
  padding-top: 93%;
}

.listing-modern-grid-2.l-2 .listing-mg-item .title, .listing-modern-grid-2.l-3 .listing-mg-item .title, .listing-modern-grid-2.l-4 .listing-mg-item .title, .listing-modern-grid-2.l-5 .listing-mg-item .title {
  font-size: 64%;
}

.listing-modern-grid-2.l-2 .listing-item-1 .title, .listing-modern-grid-2.l-3 .listing-item-1 .title, .listing-modern-grid-2.l-4 .listing-item-1 .title, .listing-modern-grid-2.l-5 .listing-item-1 .title {
  font-size: 86%;
}

.listing-modern-grid-2.l-2 .listing-item-2 .term-badges, .listing-modern-grid-2.l-2 .listing-item-3 .term-badges, .listing-modern-grid-2.l-2 .listing-item-4 .term-badges, .listing-modern-grid-2.l-2 .listing-item-5 .term-badges, .listing-modern-grid-2.l-3 .listing-item-2 .term-badges, .listing-modern-grid-2.l-3 .listing-item-3 .term-badges, .listing-modern-grid-2.l-3 .listing-item-4 .term-badges, .listing-modern-grid-2.l-3 .listing-item-5 .term-badges, .listing-modern-grid-2.l-4 .listing-item-2 .term-badges, .listing-modern-grid-2.l-4 .listing-item-3 .term-badges, .listing-modern-grid-2.l-4 .listing-item-4 .term-badges, .listing-modern-grid-2.l-4 .listing-item-5 .term-badges, .listing-modern-grid-2.l-5 .listing-item-2 .term-badges, .listing-modern-grid-2.l-5 .listing-item-3 .term-badges, .listing-modern-grid-2.l-5 .listing-item-4 .term-badges, .listing-modern-grid-2.l-5 .listing-item-5 .term-badges {
  display: none;
}

.listing-modern-grid-2.l-3 .listing-mg-item .post-meta, .listing-modern-grid-2.l-4 .listing-mg-item .post-meta, .listing-modern-grid-2.l-5 .listing-mg-item .post-meta {
  display: none !important;
}

.listing-modern-grid-2.l-3 .listing-mg-item.listing-item-1 .post-meta, .listing-modern-grid-2.l-4 .listing-mg-item.listing-item-1 .post-meta, .listing-modern-grid-2.l-5 .listing-mg-item.listing-item-1 .post-meta {
  display: block;
}

.listing-modern-grid-2.l-3 .listing-mg-item .format-icon, .listing-modern-grid-2.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-4 .listing-mg-item .format-icon, .listing-modern-grid-2.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-2.l-5 .listing-mg-item .format-icon, .listing-modern-grid-2.l-5 .listing-mg-item:hover .format-icon {
  right: 12px;
  top: 12px;
}

.listing-modern-grid-2.l-3 .listing-mg-item:before, .listing-modern-grid-2.l-4 .listing-mg-item:before, .listing-modern-grid-2.l-5 .listing-mg-item:before {
  padding-top: 60%;
}

.listing-modern-grid-2.l-3 .listing-item-1:before, .listing-modern-grid-2.l-4 .listing-item-1:before, .listing-modern-grid-2.l-5 .listing-item-1:before {
  padding-top: 45%;
}

.listing-modern-grid-2.l-3 .mg-col-1, .listing-modern-grid-2.l-4 .mg-col-1, .listing-modern-grid-2.l-5 .mg-col-1 {
  width: 100%;
  padding: 0;
  margin-bottom: 2px;
}

.listing-modern-grid-2.l-3 .mg-col-2, .listing-modern-grid-2.l-4 .mg-col-2, .listing-modern-grid-2.l-5 .mg-col-2 {
  width: 100%;
  padding: 0;
}

.listing-modern-grid-2.l-4 .listing-mg-item .format-icon, .listing-modern-grid-2.l-5 .listing-mg-item .format-icon {
  display: none;
}

.listing-modern-grid-2.l-4 .listing-mg-item-1 .format-icon, .listing-modern-grid-2.l-5 .listing-mg-item-1 .format-icon {
  display: block;
}

.listing-modern-grid-2.l-4 .listing-mg-item:before, .listing-modern-grid-2.l-5 .listing-mg-item:before {
  padding-top: 80%;
}

.listing-modern-grid-2.l-4 .listing-item-1:before, .listing-modern-grid-2.l-5 .listing-item-1:before {
  padding-top: 60%;
}

.slider-overlay-simple.listing-modern-grid-2.l-5 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-2.l-5 .listing-mg-item.listing-item-3 .content-container, .slider-overlay-simple.listing-modern-grid-2.l-5 .listing-mg-item.listing-item-4 .content-container, .slider-overlay-simple.listing-modern-grid-2.l-5 .listing-mg-item.listing-item-5 .content-container {
  max-height: 64%;
  padding: 12px;
}

@media only screen and (max-width: 900px) {
  .listing-modern-grid-2 .listing-mg-item .content-container {
    left: 15px;
    right: 15px;
    bottom: 12px;
    max-height: 50%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-2 .mg-col-1, .listing-modern-grid-2 .mg-col-2 {
    width: 50%;
  }
  .listing-modern-grid-2 .listing-mg-item:before {
    padding-top: 92.97%;
  }
  .listing-modern-grid-2 .listing-item-1:before {
    padding-top: 93%;
  }
  .listing-modern-grid-2 .listing-mg-item .title {
    font-size: 66%;
  }
  .listing-modern-grid-2 .listing-item-1 .title {
    font-size: 86%;
  }
  .listing-modern-grid-2 .listing-item-2 .term-badges, .listing-modern-grid-2 .listing-item-3 .term-badges, .listing-modern-grid-2 .listing-item-4 .term-badges, .listing-modern-grid-2 .listing-item-5 .term-badges {
    display: none;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-2 .listing-mg-item .post-meta {
    display: none;
  }
  .listing-modern-grid-2 .listing-mg-item.listing-item-1 .post-meta {
    display: block;
  }
  .listing-modern-grid-2 .listing-mg-item .format-icon, .listing-modern-grid-2 .listing-mg-item:hover .format-icon {
    right: 12px;
    top: 12px;
  }
  .listing-modern-grid-2 .listing-mg-item:before {
    padding-top: 60%;
  }
  .listing-modern-grid-2 .listing-item-1:before {
    padding-top: 45%;
  }
  .listing-modern-grid-2 .mg-col-1 {
    width: 100%;
    padding: 0;
    margin-bottom: 2px;
  }
  .listing-modern-grid-2 .mg-col-2 {
    width: 100%;
    padding: 0;
  }
}
@media only screen and (max-width: 560px) {
  .listing-modern-grid-2 .listing-mg-item:before {
    padding-top: 80%;
  }
  .listing-modern-grid-2 .listing-item-1:before {
    padding-top: 60%;
  }
}
@media only screen and (max-width: 480px) {
  .slider-overlay-simple.listing-modern-grid-2 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-2 .listing-mg-item.listing-item-3 .content-container, .slider-overlay-simple.listing-modern-grid-2 .listing-mg-item.listing-item-4 .content-container, .slider-overlay-simple.listing-modern-grid-2 .listing-mg-item.listing-item-5 .content-container {
    max-height: 64%;
    padding: 12px;
  }
}
.bs-shortcode.bs-modern-grid-listing-3 {
  margin-bottom: 10px;
}

.listing-mg-3-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.listing-mg-3-item .item-content {
  position: relative;
  overflow: hidden;
}

.listing-mg-3-item .item-content:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 65%;
}

.listing-mg-3-item .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: -1px;
  left: -2px;
  right: -2px;
  bottom: -1px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  -moz-transform-origin: 0 0;
}

.listing-mg-3-item .img-cont.b-error, .listing-mg-3-item .img-cont.b-loaded, body.bs-ll-d .listing-mg-3-item .img-cont {
  background-size: cover !important;
  animation: none;
}

.listing-mg-3-item .img-cont:after {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-mg-3-item:hover .img-cont {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
}

.listing-mg-3-item .content-container {
  position: absolute !important;
  bottom: 20px;
  left: 20px;
  right: 20px;
  padding: 0;
  overflow: hidden;
  pointer-events: none;
}

.listing-mg-3-item .content-container a {
  pointer-events: all;
}

.listing-mg-3-item .title {
  margin: 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  text-align: center !important;
}

.listing-mg-3-item .title a {
  color: #fff;
}

.listing-mg-3-item .post-meta {
  margin-top: 12px;
  text-align: center !important;
  font-size: 11px;
}

.listing-mg-3-item .post-meta .comments, .listing-mg-3-item .post-meta .post-author {
  color: #fff;
  font-size: 11px;
}

.listing-mg-3-item .post-meta .comments {
  float: none;
  margin-left: 0;
}

.listing-mg-3-item .post-meta .post-author:after, .listing-mg-3-item .post-meta .time {
  color: rgba(255, 255, 255, 0.57);
}

.listing-mg-3-item .term-badges.floated {
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
  text-align: center;
  margin-bottom: 15px;
}

.listing-mg-3-item .term-badges.floated .term-badge a {
  margin: 0 2px;
}

.listing-mg-3-item.bsw-1 .title, .listing-mg-3-item.bsw-2 .title {
  font-size: 82%;
}

.listing-mg-3-item.bsw-1 .post-meta, .listing-mg-3-item.bsw-2 .post-meta {
  display: none;
}

.listing-mg-3-item.bsw-1 .item-content:before, .listing-mg-3-item.bsw-2 .item-content:before {
  padding-top: 65% !important;
}

.listing-mg-3-item.bsw-1 .format-icon, .listing-mg-3-item.bsw-2 .format-icon {
  display: none !important;
}

.listing-mg-3-item.bsw-1 .title a, .listing-mg-3-item.bsw-2 .title a {
  display: inline-block;
}

.listing-mg-3-item.bsw-100 .title, .listing-mg-3-item.bsw-150 .title, .listing-mg-3-item.bsw-200 .title, .listing-mg-3-item.bsw-250 .title, .listing-mg-3-item.bsw-300 .title {
  font-size: 82%;
}

.listing-mg-3-item.bsw-100 .post-meta, .listing-mg-3-item.bsw-150 .post-meta, .listing-mg-3-item.bsw-200 .post-meta, .listing-mg-3-item.bsw-250 .post-meta, .listing-mg-3-item.bsw-300 .post-meta {
  display: none;
}

.listing-mg-3-item.bsw-400 .item-content:before {
  padding-top: 88% !important;
}

.listing-mg-3-item.bsw-100 .item-content:before, .listing-mg-3-item.bsw-150 .item-content:before, .listing-mg-3-item.bsw-200 .item-content:before, .listing-mg-3-item.bsw-250 .item-content:before, .listing-mg-3-item.bsw-300 .item-content:before {
  padding-top: 68% !important;
}

.listing-mg-3-item.bsw-100 .format-icon, .listing-mg-3-item.bsw-150 .format-icon, .listing-mg-3-item.bsw-200 .format-icon, .listing-mg-3-item.bsw-250 .format-icon {
  display: none !important;
}

.listing-mg-3-item.bsw-100 .title a, .listing-mg-3-item.bsw-150 .title a, .listing-mg-3-item.bsw-200 .title a, .listing-mg-3-item.bsw-250 .title a, .listing-mg-3-item.bsw-300 .title a {
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .listing-mg-3-item .title {
    font-size: 82%;
  }
  .listing-mg-3-item .post-meta {
    display: none;
  }
  .listing-mg-3-item .item-content:before {
    padding-top: 65% !important;
  }
  .listing-mg-3-item .format-icon {
    display: none !important;
  }
  .listing-mg-3-item .title a {
    display: inline-block;
  }
}
.bs-shortcode.bs-modern-grid-listing-4 {
  margin-bottom: 10px;
}

.listing-mg-4-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 6px;
}

.listing-mg-4-item .item-content {
  position: relative;
  overflow: hidden;
}

.listing-mg-4-item .item-content:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 135%;
}

.listing-mg-4-item .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  -moz-transform-origin: 0 0;
}

.listing-mg-4-item .img-cont.b-error, .listing-mg-4-item .img-cont.b-loaded, body.bs-ll-d .listing-mg-4-item .img-cont {
  background-size: cover !important;
  animation: none;
}

.listing-mg-4-item .img-cont:after {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-mg-4-item:hover .img-cont {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.listing-mg-4-item .content-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px 20px;
  overflow: hidden;
  pointer-events: none;
}

.listing-mg-4-item .content-container a {
  pointer-events: all;
}

.listing-mg-4-item .title {
  margin: 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  text-align: center !important;
}

.listing-mg-4-item .title a {
  color: #fff;
}

.listing-mg-4-item .post-subtitle {
  text-align: center !important;
}

.listing-mg-4-item .post-meta {
  margin-top: 12px;
  text-align: center !important;
  font-size: 11px;
}

.listing-mg-4-item .post-meta .comments, .listing-mg-4-item .post-meta .post-author {
  color: #fff;
}

.listing-mg-4-item .post-meta .comments {
  float: none;
  margin-left: 0;
}

.listing-mg-4-item .post-meta .post-author:after, .listing-mg-4-item .post-meta .time {
  color: rgba(255, 255, 255, 0.57);
}

.listing-mg-4-item .term-badges.floated {
  position: absolute;
  right: auto;
  left: 0;
  top: 26px;
  bottom: auto;
  text-align: center;
  margin-bottom: 0;
  width: 100%;
  pointer-events: none;
}

.listing-mg-4-item .term-badges.floated .term-badge a {
  margin: 0 2px;
  pointer-events: all;
}

.listing-modern-grid-4.listing.columns-2 .listing-item {
  width: 50%;
}

.listing-modern-grid-4.listing.columns-2 .listing-item:nth-child(odd) {
  padding-left: 0;
  padding-right: 4px;
  clear: left;
}

.listing-modern-grid-4.listing.columns-2 .listing-item:nth-child(even) {
  padding-left: 4px;
  padding-right: 0;
  clear: none;
}

.listing-modern-grid-4.listing.columns-3 .listing-item:nth-child(3n-1) {
  padding: 0 2px;
}

.listing-modern-grid-4.listing.columns-3 .listing-item:nth-child(3n-2) {
  padding-right: 4px;
  clear: left;
}

.listing-modern-grid-4.listing.columns-3 .listing-item:nth-child(3n+3) {
  padding-left: 4px;
}

.listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(4n-3) {
  padding-left: 0;
  padding-right: 3px;
  clear: left;
}

.listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(4n+2) {
  padding-left: 1px;
  padding-right: 2px;
}

.listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(4n+3) {
  padding-left: 2px;
  padding-right: 1px;
}

.listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(4n+4) {
  padding-right: 0;
  padding-left: 3px;
}

.listing-modern-grid-4.bsw-6 .listing-mg-4-item .item-content:before {
  padding-top: 115%;
}

.listing-mg-4-item.bsw-100 .post-meta, .listing-mg-4-item.bsw-150 .post-meta, .listing-mg-4-item.bsw-200 .post-meta, .listing-mg-4-item.bsw-250 .post-meta {
  display: none;
}

.listing-mg-4-item.bsw-100 .item-content:before, .listing-mg-4-item.bsw-150 .item-content:before, .listing-mg-4-item.bsw-200 .item-content:before, .listing-mg-4-item.bsw-250 .item-content:before {
  padding-top: 125% !important;
}

.listing-mg-4-item.bsw-100 .format-icon, .listing-mg-4-item.bsw-150 .format-icon, .listing-mg-4-item.bsw-200 .format-icon, .listing-mg-4-item.bsw-250 .format-icon {
  display: none !important;
}

.listing-mg-4-item.bsw-100 .title a, .listing-mg-4-item.bsw-150 .title a, .listing-mg-4-item.bsw-200 .title a, .listing-mg-4-item.bsw-250 .title a {
  font-size: 82%;
  display: inline-block;
}

@media only screen and (max-width: 900px) {
  .listing-modern-grid-4.listing.columns-4 .listing-item {
    width: 33.3333%;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n-1) {
    padding: 0 2px;
    clear: none !important;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n-2) {
    padding-left: 0;
    padding-right: 4px;
    clear: left !important;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n+3) {
    padding-left: 4px;
    padding-right: 0;
    clear: none !important;
  }
}
@media only screen and (max-width: 680px) {
  .listing-modern-grid-4.listing.columns-4 .listing-item {
    width: 33.333%;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n-1) {
    padding: 0 2px;
    clear: none !important;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n-2) {
    padding-right: 4px;
    padding-left: 0;
    clear: left !important;
  }
  .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(3n+3) {
    padding-left: 4px;
    padding-right: 0;
    clear: none !important;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-4.listing.columns-3 .listing-item, .listing-modern-grid-4.listing.columns-4 .listing-item {
    width: 50%;
  }
  .listing-modern-grid-4.listing.columns-3 .listing-item:nth-child(odd), .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(odd) {
    padding-left: 0;
    padding-right: 4px;
    clear: left !important;
  }
  .listing-modern-grid-4.listing.columns-3 .listing-item:nth-child(even), .listing-modern-grid-4.listing.columns-4 .listing-item:nth-child(even) {
    padding-left: 4px;
    padding-right: 0;
    clear: none !important;
  }
}
@media only screen and (max-width: 300px) {
  .listing-modern-grid-4 .listing-mg-4-item .post-meta {
    display: none;
  }
}
@media only screen and (max-width: 380px) {
  .listing-mg-4-item .item-content:before {
    padding-top: 115% !important;
  }
  .listing-mg-4-item .format-icon {
    display: none !important;
  }
  .listing-mg-4-item .title a {
    font-size: 82%;
    display: inline-block;
  }
}
.listing-modern-grid-5 .mg-col {
  position: relative;
  float: left;
}

.listing-modern-grid-5 .mg-col-1 {
  width: 55%;
  left: 22.5%;
  padding: 0 10px;
}

.listing-modern-grid-5 .mg-col-2 {
  width: 22.5%;
  left: -55%;
  padding: 0 20px 0 0;
}

.listing-modern-grid-5 .mg-col-3 {
  width: 22.5%;
  padding: 0 0 0 20px;
}

.listing-modern-grid-5.l-2 .mg-col-1 {
  width: 100%;
  left: auto;
  margin: 0 0 20px;
  padding: 0;
}

.listing-modern-grid-5.l-2 .mg-col-2, .listing-modern-grid-5.l-2 .mg-col-3 {
  width: 50%;
  left: auto;
  padding: 0 40px;
}

.listing-modern-grid-5.bsw-2 .mg-col-1, .listing-modern-grid-5.bsw-3 .mg-col-1, .listing-modern-grid-5.bsw-4 .mg-col-1, .listing-modern-grid-5.bsw-5 .mg-col-1, .listing-modern-grid-5.bsw-6 .mg-col-1 {
  margin-bottom: 10px;
}

.listing-modern-grid-5.bsw-2 .mg-col-2, .listing-modern-grid-5.bsw-2 .mg-col-3, .listing-modern-grid-5.bsw-3 .mg-col-2, .listing-modern-grid-5.bsw-3 .mg-col-3, .listing-modern-grid-5.bsw-4 .mg-col-2, .listing-modern-grid-5.bsw-4 .mg-col-3, .listing-modern-grid-5.bsw-5 .mg-col-2, .listing-modern-grid-5.bsw-5 .mg-col-3, .listing-modern-grid-5.bsw-6 .mg-col-2, .listing-modern-grid-5.bsw-6 .mg-col-3 {
  padding: 0 20px;
}

.listing-modern-grid-5.bsw-2 .listing-mg-5-item-small .title, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-small .title, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-small .title, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-small .title, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-small .title {
  padding: 0;
  line-height: 1.3;
}

.listing-modern-grid-5.bsw-2 .listing-mg-5-item-small .title a, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-small .title a, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-small .title a, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-small .title a, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-small .title a {
  font-size: 90%;
}

.listing-modern-grid-5.bsw-1 .mg-col-3, .listing-modern-grid-5.bsw-2 .mg-col-3, .listing-modern-grid-5.bsw-3 .mg-col-3, .listing-modern-grid-5.bsw-4 .mg-col-3, .listing-modern-grid-5.bsw-5 .mg-col-3 {
  padding: 0 0 0 10px;
}

.listing-modern-grid-5.bsw-1 .mg-col-2, .listing-modern-grid-5.bsw-2 .mg-col-2, .listing-modern-grid-5.bsw-3 .mg-col-2, .listing-modern-grid-5.bsw-4 .mg-col-2, .listing-modern-grid-5.bsw-5 .mg-col-2 {
  padding: 0 10px 0 0;
}

.listing-modern-grid-5 .term-badges.floated {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  pointer-events: none;
  z-index: 1;
  line-height: 0;
  text-align: center;
}

.listing-modern-grid-5 .term-badges.floated a {
  pointer-events: all;
}

.listing-mg-5-item .item-content {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.listing-mg-5-item .item-content:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 70.29%;
}

.listing-mg-5-item .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  -moz-transform-origin: 0 0;
}

.listing-mg-5-item .img-cont.b-error, .listing-mg-5-item .img-cont.b-loaded, body.bs-ll-d .listing-mg-5-item .img-cont {
  background-size: cover !important;
  animation: none;
}

.listing-mg-5-item .title a {
  color: inherit;
}

.listing-mg-5-item .title {
  line-height: 1.4;
}

.listing-mg-5-item .content-container {
  padding: 0 !important;
  background: 0 0 !important;
}

.listing-mg-5-item-big .img-cont {
  top: -1px;
  left: -2px;
  right: -2px;
  bottom: -1px;
}

.listing-mg-5-item-big .item-content:before {
  padding-top: 66.29%;
}

.listing-mg-5-item-big:hover .img-cont {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1) rotate(0.02deg);
}

.listing-mg-5-item-big .content-container {
  position: relative;
  padding: 20px !important;
  overflow: hidden;
  pointer-events: none;
  margin: -80px 35px 0;
  z-index: 1;
  background: #fff !important;
  min-height: 100px;
}

.listing-mg-5-item-big .title {
  margin: 0 0 6px;
}

.listing-mg-5-item-big .post-subtitle {
  color: #2d2d2d !important;
  text-shadow: none;
}

.listing-mg-5-item-big .post-meta {
  text-align: center;
}

.listing-mg-5-item-big .post-meta .comments {
  float: none;
  margin-left: 0;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-big .content-container, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-big .content-container, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-big .content-container, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-big .content-container, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-big .content-container {
  padding: 20px 15px !important;
  overflow: hidden;
  pointer-events: none;
  margin: -80px 18px 0;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-7 .listing-mg-5-item-big .title, .listing-modern-grid-5.bsw-8 .listing-mg-5-item-big .title {
  line-height: 1;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-7 .listing-mg-5-item-big .title a, .listing-modern-grid-5.bsw-8 .listing-mg-5-item-big .title a {
  font-size: 71%;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-7 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-8 .listing-mg-5-item-big .item-content:before {
  padding-top: 54%;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-big .item-content:before, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-big .item-content:before {
  padding-top: 62%;
}

.listing-mg-5-item-small .item-content:before {
  padding-top: 68%;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-1 .listing-mg-5-item-small .term-badges.floated, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-small .term-badges.floated, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-small .term-badges.floated, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-small .term-badges.floated, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-small .term-badges.floated, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-small .format-icon, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-small .term-badges.floated {
  display: none;
}

.listing-modern-grid-5.bsw-1 .listing-mg-5-item-small .item-content:before, .listing-modern-grid-5.bsw-2 .listing-mg-5-item-small .item-content:before, .listing-modern-grid-5.bsw-3 .listing-mg-5-item-small .item-content:before, .listing-modern-grid-5.bsw-4 .listing-mg-5-item-small .item-content:before, .listing-modern-grid-5.bsw-5 .listing-mg-5-item-small .item-content:before, .listing-modern-grid-5.bsw-6 .listing-mg-5-item-small .item-content:before {
  padding-top: 70%;
}

.listing-mg-5-item-small {
  margin-bottom: 16px;
}

.mg-col .listing-mg-5-item-small:last-child {
  margin-bottom: 0;
}

.listing-mg-5-item-small .title {
  padding: 0 13px;
  margin: 10px 0 0;
  min-height: 38px;
}

@media only screen and (max-width: 780px) {
  .listing-modern-grid-5 .mg-col-1 {
    width: 100%;
    left: auto;
    margin: 0 0 20px;
    padding: 0;
  }
  .listing-modern-grid-5 .mg-col-2, .listing-modern-grid-5 .mg-col-3 {
    width: 50%;
    left: auto;
    padding: 0 40px;
  }
  .listing-modern-grid-5 .listing-mg-5-item-big .title {
    line-height: 1;
  }
  .listing-modern-grid-5 .listing-mg-5-item-big .title a {
    font-size: 71%;
  }
  .listing-modern-grid-5 .listing-mg-5-item-big .item-content:before {
    padding-top: 54%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-5 .listing-mg-5-item-small .format-icon {
    display: none;
  }
  .listing-modern-grid-5 .mg-col-1 {
    margin-bottom: 10px;
  }
  .listing-modern-grid-5 .mg-col-2, .listing-modern-grid-5 .mg-col-3 {
    padding: 0 20px;
  }
  .listing-modern-grid-5 .listing-mg-5-item-small .title {
    padding: 0;
    line-height: 1.3;
  }
  .listing-modern-grid-5 .listing-mg-5-item-small .title a {
    font-size: 90%;
  }
}
@media only screen and (max-width: 560px) {
  .listing-modern-grid-5 .listing-mg-5-item-big .item-content:before {
    padding-top: 62%;
  }
  .listing-modern-grid-5 .listing-mg-5-item-small .term-badges.floated {
    display: none;
  }
  .listing-modern-grid-5 .listing-mg-5-item-small .item-content:before {
    padding-top: 70%;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-5 .mg-col-3 {
    padding: 0 0 0 10px !important;
  }
  .listing-modern-grid-5 .mg-col-2 {
    padding: 0 10px 0 0 !important;
  }
}
@media only screen and (max-width: 450px) {
  .listing-modern-grid-5 .listing-mg-5-item-big .content-container {
    padding: 20px 15px !important;
    overflow: hidden;
    pointer-events: none;
    margin: -80px 18px 0;
  }
}
.listing-mg-6-item {
  position: relative;
  overflow: hidden;
}

.listing-mg-6-item + .listing-mg-6-item {
  margin-top: 2px;
}

.listing-mg-6-item:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 64%;
}

.listing-mg-6-item .item-content, .listing-modern-grid .bs-slider-item .item-content {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-mg-6-item .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  image-rendering: optimizeQuality;
  -moz-transform-origin: 0 0;
}

.listing-mg-6-item .img-cont.b-error, .listing-mg-6-item .img-cont.b-loaded, body.bs-ll-d .listing-mg-6-item .img-cont {
  background-size: cover !important;
}

.listing-mg-6-item .img-cont:after {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-mg-6-item:hover .img-cont {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.listing-mg-6-item .content-container {
  position: absolute;
  bottom: 15px;
  left: 20px;
  right: 20px;
  padding: 0;
  max-height: 75%;
  overflow: hidden;
  pointer-events: none;
}

.listing-mg-6-item .content-container a {
  pointer-events: all;
}

.listing-mg-6-item .title {
  margin: 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
}

.listing-mg-6-item .title a {
  color: inherit;
}

.listing-mg-6-item .post-meta {
  margin-top: 3px;
}

.listing-mg-6-item .post-meta .post-author {
  color: #fff;
}

.listing-mg-6-item .post-meta .comments {
  color: #fff;
  float: right;
  margin-right: 0;
  margin-left: 15px;
}

.listing-mg-6-item .post-meta .rating + .comments {
  float: none;
  margin-left: 0;
}

.listing-mg-6-item .post-meta .post-author:after, .listing-mg-6-item .post-meta .time {
  color: rgba(255, 255, 255, 0.57);
}

.listing-modern-grid-6 .mg-col-1 {
  width: 50%;
  float: left;
  padding-right: 1px;
}

.listing-modern-grid-6 .mg-col-2 {
  width: 50%;
  float: left;
  padding-left: 1px;
}

.infinity > .listing-modern-grid-6 + .listing-modern-grid-6, .more_btn > .listing-modern-grid-6 + .listing-modern-grid-6 {
  margin-top: 4px;
}

.listing-modern-grid-6.bsw-1 .content-container, .listing-modern-grid-6.bsw-2 .content-container, .listing-modern-grid-6.bsw-3 .content-container, .listing-modern-grid-6.bsw-4 .content-container, .listing-modern-grid-6.bsw-5 .content-container, .listing-modern-grid-6.bsw-6 .content-container, .listing-modern-grid-6.bsw-7 .content-container, .listing-modern-grid-6.bsw-8 .content-container {
  max-height: 50%;
}

.listing-modern-grid-6.bsw-1 .listing-item .title, .listing-modern-grid-6.bsw-2 .listing-item .title, .listing-modern-grid-6.bsw-3 .listing-item .title, .listing-modern-grid-6.bsw-4 .listing-item .title, .listing-modern-grid-6.bsw-5 .listing-item .title, .listing-modern-grid-6.bsw-6 .listing-item .title, .listing-modern-grid-6.bsw-7 .listing-item .title, .listing-modern-grid-6.bsw-8 .listing-item .title {
  line-height: 1;
}

.listing-modern-grid-6.bsw-1 .listing-item .title a, .listing-modern-grid-6.bsw-2 .listing-item .title a, .listing-modern-grid-6.bsw-3 .listing-item .title a, .listing-modern-grid-6.bsw-4 .listing-item .title a, .listing-modern-grid-6.bsw-5 .listing-item .title a, .listing-modern-grid-6.bsw-6 .listing-item .title a, .listing-modern-grid-6.bsw-7 .listing-item .title a, .listing-modern-grid-6.bsw-8 .listing-item .title a {
  font-size: 80%;
}

.listing-modern-grid-6.bsw-1 .mg-col-1, .listing-modern-grid-6.bsw-2 .mg-col-1, .listing-modern-grid-6.bsw-3 .mg-col-1, .listing-modern-grid-6.bsw-4 .mg-col-1, .listing-modern-grid-6.bsw-5 .mg-col-1 {
  width: 100%;
  padding: 0;
}

.listing-modern-grid-6.bsw-1 .mg-col-2, .listing-modern-grid-6.bsw-2 .mg-col-2, .listing-modern-grid-6.bsw-3 .mg-col-2, .listing-modern-grid-6.bsw-4 .mg-col-2, .listing-modern-grid-6.bsw-5 .mg-col-2 {
  width: 100%;
  padding: 4px 0 0 0;
}

.listing-modern-grid-6.bsw-1 .format-icon, .listing-modern-grid-6.bsw-2 .format-icon, .listing-modern-grid-6.bsw-3 .format-icon, .listing-modern-grid-6.bsw-4 .format-icon, .listing-modern-grid-6.bsw-5 .format-icon {
  display: none;
}

.listing-modern-grid-6.bsw-1 .listing-mg-6-item:before, .listing-modern-grid-6.bsw-2 .listing-mg-6-item:before, .listing-modern-grid-6.bsw-3 .listing-mg-6-item:before, .listing-modern-grid-6.bsw-4 .listing-mg-6-item:before, .listing-modern-grid-6.bsw-5 .listing-mg-6-item:before {
  padding-top: 60%;
}

.slider-overlay-simple.listing-modern-grid-6.bsw-1 .listing-mg-item .content-container, .slider-overlay-simple.listing-modern-grid-6.bsw-2 .listing-mg-item .content-container, .slider-overlay-simple.listing-modern-grid-6.bsw-3 .listing-mg-item .content-container, .slider-overlay-simple.listing-modern-grid-6.bsw-4 .listing-mg-item .content-container, .slider-overlay-simple.listing-modern-grid-6.bsw-5 .listing-mg-item .content-container {
  max-height: 60%;
  padding: 18px 15px 18px;
}

@media only screen and (max-width: 780px) {
  .listing-modern-grid-6 .content-container {
    max-height: 50%;
  }
  .listing-modern-grid-6 .listing-item .title {
    line-height: 1;
  }
  .listing-modern-grid-6 .listing-item .title a {
    font-size: 80%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-6 .listing-mg-6-item .post-meta {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-6 .format-icon {
    display: none;
  }
  .listing-modern-grid-6 .mg-col-1 {
    width: 100%;
    padding: 0;
  }
  .listing-modern-grid-6 .mg-col-2 {
    width: 100%;
    padding: 4px 0 0 0;
  }
  .listing-modern-grid-6 .listing-mg-6-item:before {
    padding-top: 60%;
  }
  .slider-overlay-simple.listing-modern-grid-6 .listing-mg-item .content-container {
    max-height: 60%;
    padding: 18px 15px 18px;
  }
}
.listing-mg-7-item:before {
  padding-top: 60%;
}

.listing-modern-grid-7 .mg-row-1 {
  padding-bottom: 2px;
}

.listing-modern-grid-7 .mg-row-1 .mg-col-1 {
  width: 50%;
  float: left;
  padding-right: 1px;
}

.listing-modern-grid-7 .mg-row-1 .mg-col-2 {
  width: 50%;
  float: left;
  padding-left: 1px;
}

.listing-modern-grid-7 .mg-row-2 .mg-col {
  width: 33.333%;
  float: left;
}

.listing-modern-grid-7 .mg-row-2 .mg-col-1 {
  padding-right: 1.5px;
}

.listing-modern-grid-7 .mg-row-2 .mg-col-2 {
  padding-left: 0.75px;
  padding-right: 0.75px;
}

.listing-modern-grid-7 .mg-row-2 .mg-col-3 {
  padding-left: 1.5px;
}

.listing-modern-grid-7 .mg-row-2 .listing-mg-7-item {
  width: 100%;
}

.listing-modern-grid-7 .mg-row-2 .listing-mg-7-item:before {
  padding-top: 60%;
}

.listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .post-subtitle, .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .title {
  font-size: 79%;
}

.listing-modern-grid-7.bsw-10 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-2 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-3 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-4 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-5 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-6 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-7 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-8 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-9 .mg-row-1 .listing-mg-7-item .title {
  font-size: 92%;
}

.listing-modern-grid-7.bsw-10 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-2 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-3 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-4 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-5 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-6 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-7 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-8 .mg-row-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-9 .mg-row-2 .listing-mg-7-item .title {
  font-size: 72%;
}

.listing-modern-grid-7.bsw-2 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-2 .listing-mg-7-item.listing-mg-item:hover .format-icon, .listing-modern-grid-7.bsw-3 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-3 .listing-mg-7-item.listing-mg-item:hover .format-icon, .listing-modern-grid-7.bsw-4 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-4 .listing-mg-7-item.listing-mg-item:hover .format-icon, .listing-modern-grid-7.bsw-5 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-5 .listing-mg-7-item.listing-mg-item:hover .format-icon, .listing-modern-grid-7.bsw-6 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-6 .listing-mg-7-item.listing-mg-item:hover .format-icon, .listing-modern-grid-7.bsw-7 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7.bsw-7 .listing-mg-7-item.listing-mg-item:hover .format-icon {
  right: 20px !important;
  top: 20px !important;
  transform: none;
  margin: 0;
}

.listing-modern-grid-7.bsw-1 .mg-col, .listing-modern-grid-7.bsw-1 .mg-row, .listing-modern-grid-7.bsw-2 .mg-col, .listing-modern-grid-7.bsw-2 .mg-row, .listing-modern-grid-7.bsw-3 .mg-col, .listing-modern-grid-7.bsw-3 .mg-row, .listing-modern-grid-7.bsw-4 .mg-col, .listing-modern-grid-7.bsw-4 .mg-row, .listing-modern-grid-7.bsw-5 .mg-col, .listing-modern-grid-7.bsw-5 .mg-row, .listing-modern-grid-7.bsw-6 .mg-col, .listing-modern-grid-7.bsw-6 .mg-row, .listing-modern-grid-7.bsw-7 .mg-col, .listing-modern-grid-7.bsw-7 .mg-row {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  float: none !important;
}

.listing-modern-grid-7.bsw-1 .listing-item, .listing-modern-grid-7.bsw-2 .listing-item, .listing-modern-grid-7.bsw-3 .listing-item, .listing-modern-grid-7.bsw-4 .listing-item, .listing-modern-grid-7.bsw-5 .listing-item, .listing-modern-grid-7.bsw-6 .listing-item, .listing-modern-grid-7.bsw-7 .listing-item {
  margin-bottom: 2px;
}

.listing-modern-grid-7.bsw-4 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-5 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-6 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-7 .listing-mg-7-item:before {
  padding-top: 50% !important;
}

.listing-modern-grid-7.bsw-1 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-2 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-3 .listing-mg-7-item:before {
  padding-top: 72% !important;
}

.listing-modern-grid-7.bsw-1 .listing-mg-7-item .format-icon, .listing-modern-grid-7.bsw-2 .listing-mg-7-item .format-icon, .listing-modern-grid-7.bsw-3 .listing-mg-7-item .format-icon {
  display: none;
}

.listing-modern-grid-7.bsw-1 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-2 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-3 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-4 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-5 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-6 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-7 .listing-mg-7-item:before {
  padding-top: 24% !important;
}

.listing-modern-grid-7.bsw-1 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-2 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-3 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-4 .listing-mg-7-item:before, .listing-modern-grid-7.bsw-5 .listing-mg-7-item:before {
  padding-top: 40% !important;
}

.listing-modern-grid-7.bsw-2 .listing-mg-7-item .post-meta, .listing-modern-grid-7.bsw-3 .listing-mg-7-item .post-meta, .listing-modern-grid-7.bsw-4 .listing-mg-7-item .post-meta, .listing-modern-grid-7.bsw-5 .listing-mg-7-item .post-meta {
  display: none;
}

.listing-modern-grid-7.bsw-2 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-3 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-4 .listing-mg-7-item .title, .listing-modern-grid-7.bsw-5 .listing-mg-7-item .title {
  font-size: 62% !important;
}

.listing-modern-grid-7.bsw-2 .listing-mg-7-item .post-subtitle, .listing-modern-grid-7.bsw-3 .listing-mg-7-item .post-subtitle, .listing-modern-grid-7.bsw-4 .listing-mg-7-item .post-subtitle, .listing-modern-grid-7.bsw-5 .listing-mg-7-item .post-subtitle {
  font-size: 60% !important;
}

.listing-modern-grid-7.bsw-2 .listing-mg-7-item .content-container, .listing-modern-grid-7.bsw-3 .listing-mg-7-item .content-container, .listing-modern-grid-7.bsw-4 .listing-mg-7-item .content-container, .listing-modern-grid-7.bsw-5 .listing-mg-7-item .content-container {
  max-height: 43% !important;
}

@media only screen and (max-width: 980px) {
  .listing-modern-grid-7 .mg-row-1 .listing-mg-7-item .title {
    font-size: 92%;
  }
  .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .title {
    font-size: 72%;
  }
}
@media only screen and (max-width: 780px) {
  .listing-modern-grid-7 .listing-mg-7-item.listing-mg-item .format-icon, .listing-modern-grid-7 .listing-mg-7-item.listing-mg-item:hover .format-icon {
    right: 20px !important;
    top: 20px !important;
    transform: none;
    margin: 0;
  }
  .listing-modern-grid-7 .listing-mg-7-item.listing-mg-7-item:before {
    padding-top: 70% !important;
  }
  .listing-modern-grid-7 .mg-row-1 .listing-mg-7-item .title, .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .title {
    font-size: 78% !important;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-7 .listing-item {
    margin-bottom: 2px;
  }
  .listing-modern-grid-7 .mg-col {
    width: 100% !important;
    float: none !important;
    padding: 0 !important;
  }
  .listing-modern-grid-7 .mg-row {
    margin: 0 !important;
    padding: 0 !important;
  }
  .listing-modern-grid-7 .post-meta {
    display: none;
  }
  .listing-modern-grid-7 .term-badges.floated {
    display: block;
  }
  .listing-modern-grid-7 .listing-mg-7-item.listing-mg-7-item:before {
    padding-top: 40% !important;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-7 .mg-row-1 .listing-mg-7-item .title {
    font-size: 82%;
  }
  .listing-modern-grid-7 .mg-row-1 .listing-mg-7-item.listing-item-2 .title, .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .title {
    font-size: 62%;
  }
  .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .post-subtitle {
    font-size: 60%;
  }
  .listing-modern-grid-7 .mg-row-2 .listing-mg-7-item .content-container {
    max-height: 43%;
  }
}
.listing-mg-8-item.listing-item-2 .title, .listing-mg-8-item.listing-item-3 .title, .listing-mg-8-item.listing-item-4 .title, .listing-mg-8-item.listing-item-5 .post-subtitle, .listing-mg-8-item.listing-item-5 .title {
  font-size: 72%;
}

.listing-mg-8-item.listing-item-2 .title {
  font-size: 80%;
}

.listing-modern-grid-8 .mg-col-1 {
  width: 46%;
  float: left;
  padding-right: 1px;
  overflow: hidden;
}

.listing-modern-grid-8 .mg-col-2 {
  width: 32%;
  float: left;
  padding-left: 1px;
  padding-right: 1px;
  overflow: hidden;
}

.listing-modern-grid-8 .mg-col-3 {
  width: 22%;
  float: left;
  padding-left: 1px;
  overflow: hidden;
}

.listing-modern-grid-8 .mg-row-1 {
  margin: 0 0 2px;
  overflow: hidden;
}

.listing-modern-grid-8 .item-3-cont, .listing-modern-grid-8 .item-4-cont {
  width: 50%;
  float: left;
  overflow: hidden;
}

.listing-modern-grid-8 .item-3-cont {
  padding-right: 1px;
}

.listing-modern-grid-8 .item-4-cont {
  padding-left: 1px;
}

.listing-mg-8-item.listing-item-1:before {
  padding-top: 71.779%;
}

.listing-mg-8-item.listing-item-2:before {
  padding-top: 53.005%;
}

.listing-mg-8-item.listing-item-3:before, .listing-mg-8-item.listing-item-4:before {
  padding-top: 100.55%;
}

.listing-mg-8-item.listing-item-5:before {
  padding-top: 150.394%;
}

.listing-mg-8-item.listing-mg-item .format-icon, .listing-mg-8-item.listing-mg-item:hover .format-icon {
  right: 20px !important;
  top: 20px !important;
  transform: none;
  margin: 0;
}

.listing-modern-grid-8.l-1 .mg-col-3, .listing-modern-grid-8.l-2 .mg-col-3, .listing-modern-grid-8.l-3 .mg-col-3, .listing-modern-grid-8.l-4 .mg-col-3 {
  width: 100%;
  padding: 2px 0 0;
}

.listing-modern-grid-8.l-1 .mg-col-1, .listing-modern-grid-8.l-2 .mg-col-1, .listing-modern-grid-8.l-3 .mg-col-1, .listing-modern-grid-8.l-4 .mg-col-1 {
  width: 56%;
}

.listing-modern-grid-8.l-1 .mg-col-2, .listing-modern-grid-8.l-2 .mg-col-2, .listing-modern-grid-8.l-3 .mg-col-2, .listing-modern-grid-8.l-4 .mg-col-2 {
  width: 44%;
  padding: 0 0 0 1px;
}

.listing-modern-grid-8.l-1 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2:before {
  padding-top: 47.29%;
}

.listing-modern-grid-8.l-1 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-1 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-4:before {
  padding-top: 87.68%;
}

.listing-modern-grid-8.l-1 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-5:before {
  padding-top: 18%;
}

.listing-modern-grid-8.l-2 .listing-mg-8-item:before, .listing-modern-grid-8.l-3 .listing-mg-8-item:before, .listing-modern-grid-8.l-4 .listing-mg-8-item:before {
  padding-top: 89%;
}

.listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-5:before {
  padding-top: 18%;
}

.listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2:before {
  padding-top: 65.5%;
}

.listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-2 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-4:before {
  padding-top: 95.055%;
}

.listing-modern-grid-8.l-2 .listing-item-1 .title, .listing-modern-grid-8.l-3 .listing-item-1 .title, .listing-modern-grid-8.l-4 .listing-item-1 .title {
  font-size: 80%;
}

.listing-modern-grid-8.l-2 .listing-item-2 .title, .listing-modern-grid-8.l-2 .listing-item-3 .title, .listing-modern-grid-8.l-2 .listing-item-4 .title, .listing-modern-grid-8.l-3 .listing-item-2 .title, .listing-modern-grid-8.l-3 .listing-item-3 .title, .listing-modern-grid-8.l-3 .listing-item-4 .title, .listing-modern-grid-8.l-4 .listing-item-2 .title, .listing-modern-grid-8.l-4 .listing-item-3 .title, .listing-modern-grid-8.l-4 .listing-item-4 .title {
  font-size: 62%;
}

.listing-modern-grid-8.l-2 .listing-item-3 .term-badges, .listing-modern-grid-8.l-2 .listing-item-4 .term-badges, .listing-modern-grid-8.l-3 .listing-item-3 .term-badges, .listing-modern-grid-8.l-3 .listing-item-4 .term-badges, .listing-modern-grid-8.l-4 .listing-item-3 .term-badges, .listing-modern-grid-8.l-4 .listing-item-4 .term-badges {
  display: none;
}

.listing-modern-grid-8.l-2 .listing-mg-item .content-container, .listing-modern-grid-8.l-3 .listing-mg-item .content-container, .listing-modern-grid-8.l-4 .listing-mg-item .content-container {
  left: 15px;
  right: 15px;
  bottom: 10px;
  max-height: 50%;
}

.listing-modern-grid-8.l-3 .mg-col-1, .listing-modern-grid-8.l-4 .mg-col-1 {
  width: 100%;
  padding: 0;
}

.listing-modern-grid-8.l-3 .mg-col-2, .listing-modern-grid-8.l-4 .mg-col-2 {
  width: 100%;
  padding: 2px 0 0 0;
}

.listing-modern-grid-8.l-3 .mg-row-2, .listing-modern-grid-8.l-4 .mg-row-2 {
  width: 100%;
  padding: 0;
  float: none;
}

.listing-modern-grid-8.l-3 .listing-item-1:before, .listing-modern-grid-8.l-4 .listing-item-1:before {
  padding-top: 46%;
}

.listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-3:before {
  padding-top: 65.5%;
}

.listing-modern-grid-8.l-3 .listing-item-2 .title, .listing-modern-grid-8.l-4 .listing-item-2 .title {
  font-size: 72%;
}

.listing-modern-grid-8.l-3 .listing-item-2 .post-meta, .listing-modern-grid-8.l-3 .mg-col-2 .post-meta, .listing-modern-grid-8.l-3 .mg-col-2 .term-badges.floated, .listing-modern-grid-8.l-3 .mg-col-3 .post-meta, .listing-modern-grid-8.l-3 .mg-col-3 .term-badges.floated, .listing-modern-grid-8.l-4 .listing-item-2 .post-meta, .listing-modern-grid-8.l-4 .mg-col-2 .post-meta, .listing-modern-grid-8.l-4 .mg-col-2 .term-badges.floated, .listing-modern-grid-8.l-4 .mg-col-3 .post-meta, .listing-modern-grid-8.l-4 .mg-col-3 .term-badges.floated {
  display: none;
}

.listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-1:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-1:before {
  padding-top: 50%;
}

.listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-2 .term-badges.floated, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2 .term-badges.floated {
  display: none;
}

.listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-5:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-5:before {
  padding-top: 36%;
}

.listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-3 .listing-mg-8-item.listing-item-4:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-4:before {
  padding-top: 79.5%;
}

.listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-2:before {
  padding-top: 50.5%;
}

.listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-1:before {
  padding-top: 55%;
}

.listing-modern-grid-8.l-4 .listing-mg-8-item.listing-item-1 .post-meta {
  display: none;
}

.listing-modern-grid-8.l-4 .listing-mg-item .content-container {
  left: 12px;
  right: 12px;
  bottom: 12px;
}

.listing-modern-grid-8.l-4 .listing-mg-item.listing-item-2 .content-container, .listing-modern-grid-8.l-4 .listing-mg-item.listing-item-3 .content-container {
  max-height: 40%;
}

.slider-overlay-simple.listing-modern-grid-8.l-4 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-8.l-4 .listing-mg-item.listing-item-3 .content-container {
  max-height: 60%;
  padding: 12px 12px 10px;
}

.listing-mg-8-item.listing-mg-item.l-4 .format-icon, .listing-mg-8-item.listing-mg-item.l-4:hover .format-icon {
  right: 10px !important;
  top: 10px !important;
}

@media only screen and (max-width: 980px) {
  .listing-modern-grid-8 .mg-col-3 {
    width: 100%;
    padding: 2px 0 0;
  }
  .listing-modern-grid-8 .mg-col-1 {
    width: 56%;
  }
  .listing-modern-grid-8 .mg-col-2 {
    width: 44%;
    padding: 0 0 0 1px;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2:before {
    padding-top: 47.29%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8 .listing-mg-8-item.listing-item-4:before {
    padding-top: 87.68%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-5:before {
    padding-top: 18%;
  }
}
@media only screen and (max-width: 780px) {
  .listing-modern-grid-8 .listing-mg-8-item:before {
    padding-top: 89%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-5:before {
    padding-top: 18%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2:before {
    padding-top: 65.5%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8 .listing-mg-8-item.listing-item-4:before {
    padding-top: 95.055%;
  }
  .listing-modern-grid-8 .listing-item-1 .title {
    font-size: 80%;
  }
  .listing-modern-grid-8 .listing-item-2 .title, .listing-modern-grid-8 .listing-item-3 .title {
    font-size: 72%;
  }
  .listing-modern-grid-8 .listing-item-3 .term-badges, .listing-modern-grid-8 .listing-item-4 .term-badges {
    display: none;
  }
  .listing-modern-grid-8 .listing-mg-item .content-container {
    left: 15px;
    right: 15px;
    bottom: 10px;
    max-height: 50%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-8 .mg-col-1 {
    width: 100% !important;
    padding: 0 !important;
  }
  .listing-modern-grid-8 .mg-col-2 {
    width: 100% !important;
    padding: 2px 0 0 0 !important;
  }
  .listing-modern-grid-8 .mg-row-2 {
    width: 100% !important;
    padding: 0 !important;
    float: none !important;
  }
  .listing-modern-grid-8 .listing-item-1:before {
    padding-top: 46% !important;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8 .listing-mg-8-item.listing-item-3:before {
    padding-top: 65.5% !important;
  }
  .listing-modern-grid-8 .listing-item-2 .title, .listing-modern-grid-8 .listing-item-3 .title {
    font-size: 72% !important;
  }
  .listing-modern-grid-8 .listing-item-2 .post-meta, .listing-modern-grid-8 .mg-col-2 .post-meta, .listing-modern-grid-8 .mg-col-2 .term-badges.floated, .listing-modern-grid-8 .mg-col-3 .post-meta, .listing-modern-grid-8 .mg-col-3 .term-badges.floated {
    display: none !important;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-1:before {
    padding-top: 50% !important;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2 .term-badges.floated {
    display: none !important;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2:before, .listing-modern-grid-8 .listing-mg-8-item.listing-item-5:before {
    padding-top: 36% !important;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-3:before, .listing-modern-grid-8 .listing-mg-8-item.listing-item-4:before {
    padding-top: 79.5% !important;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-2:before {
    padding-top: 50.5%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-1:before {
    padding-top: 55%;
  }
  .listing-modern-grid-8 .listing-mg-8-item.listing-item-1 .post-meta {
    display: none;
  }
  .listing-modern-grid-8 .listing-item-2 .title, .listing-modern-grid-8 .listing-item-3 .title {
    font-size: 64%;
  }
  .listing-modern-grid-8 .listing-mg-item .content-container {
    left: 12px;
    right: 12px;
    bottom: 12px;
  }
  .listing-modern-grid-8 .listing-mg-item.listing-item-2 .content-container, .listing-modern-grid-8 .listing-mg-item.listing-item-3 .content-container {
    max-height: 40%;
  }
  .slider-overlay-simple.listing-modern-grid-8 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-8 .listing-mg-item.listing-item-3 .content-container {
    max-height: 60%;
    padding: 12px 12px 10px;
  }
  .listing-mg-8-item.listing-mg-item .format-icon, .listing-mg-8-item.listing-mg-item:hover .format-icon {
    right: 10px !important;
    top: 10px !important;
  }
}
.listing-mg-9-item.listing-item-2 .title, .listing-mg-9-item.listing-item-3 .title, .listing-mg-9-item.listing-item-4 .title, .listing-mg-9-item.listing-item-5 .title, .listing-mg-9-item.listing-item-6 .title, .listing-mg-9-item.listing-item-7 .title {
  font-size: 72%;
}

.listing-mg-9-item.listing-mg-item .format-icon, .listing-mg-9-item.listing-mg-item:hover .format-icon {
  right: 20px !important;
  top: 20px !important;
  transform: none;
  margin: 0;
}

.listing-mg-9-item:before {
  padding-top: 93.76%;
}

.listing-modern-grid-9 .mg-col-3 .listing-mg-9-item:before {
  padding-top: 93.97%;
}

.listing-mg-9-item.listing-item-1:before {
  padding-top: 74.545%;
}

.listing-modern-grid-9 .mg-col-1 {
  width: 45.6%;
  float: left;
  padding-right: 1px;
}

.listing-modern-grid-9 .mg-col-2 {
  width: 36.35%;
  float: left;
  padding-left: 1px;
  padding-right: 1px;
}

.listing-modern-grid-9 .mg-col-3 {
  width: 18.05%;
  float: left;
  padding-left: 1px;
}

.listing-modern-grid-9 .item-2-cont, .listing-modern-grid-9 .item-3-cont, .listing-modern-grid-9 .item-4-cont, .listing-modern-grid-9 .item-5-cont {
  width: 50%;
  float: left;
}

.listing-modern-grid-9 .item-2-cont, .listing-modern-grid-9 .item-4-cont {
  padding-right: 1px;
}

.listing-modern-grid-9 .item-3-cont, .listing-modern-grid-9 .item-5-cont {
  padding-left: 1px;
}

.listing-modern-grid-9 .mg-row-1 {
  margin-bottom: 2px;
}

.listing-modern-grid-9.l-1 .mg-col-1, .listing-modern-grid-9.l-2 .mg-col-1, .listing-modern-grid-9.l-3 .mg-col-1, .listing-modern-grid-9.l-4 .mg-col-1, .listing-modern-grid-9.l-5 .mg-col-1 {
  width: 53%;
}

.listing-modern-grid-9.l-1 .mg-col-2, .listing-modern-grid-9.l-2 .mg-col-2, .listing-modern-grid-9.l-3 .mg-col-2, .listing-modern-grid-9.l-4 .mg-col-2, .listing-modern-grid-9.l-5 .mg-col-2 {
  width: 47%;
}

.listing-modern-grid-9.l-1 .listing-mg-9-item:before, .listing-modern-grid-9.l-2 .listing-mg-9-item:before, .listing-modern-grid-9.l-3 .listing-mg-9-item:before, .listing-modern-grid-9.l-4 .listing-mg-9-item:before, .listing-modern-grid-9.l-5 .listing-mg-9-item:before {
  padding-top: 86.97%;
}

.listing-modern-grid-9.l-1 .listing-item-1:before, .listing-modern-grid-9.l-2 .listing-item-1:before, .listing-modern-grid-9.l-3 .listing-item-1:before, .listing-modern-grid-9.l-4 .listing-item-1:before, .listing-modern-grid-9.l-5 .listing-item-1:before {
  padding-top: 77%;
}

.listing-modern-grid-9.l-1 .listing-mg-item .content-container, .listing-modern-grid-9.l-2 .listing-mg-item .content-container, .listing-modern-grid-9.l-3 .listing-mg-item .content-container, .listing-modern-grid-9.l-4 .listing-mg-item .content-container, .listing-modern-grid-9.l-5 .listing-mg-item .content-container {
  left: 15px;
  right: 15px;
  bottom: 12px;
  max-height: 50%;
}

.listing-modern-grid-9.l-1 .mg-col-3 .mg-row, .listing-modern-grid-9.l-2 .mg-col-3 .mg-row, .listing-modern-grid-9.l-3 .mg-col-3 .mg-row, .listing-modern-grid-9.l-4 .mg-col-3 .mg-row, .listing-modern-grid-9.l-5 .mg-col-3 .mg-row {
  width: 50%;
  float: left;
}

.listing-modern-grid-9.l-1 .mg-col-3, .listing-modern-grid-9.l-2 .mg-col-3, .listing-modern-grid-9.l-3 .mg-col-3, .listing-modern-grid-9.l-4 .mg-col-3, .listing-modern-grid-9.l-5 .mg-col-3 {
  width: 100%;
  padding: 2px 0 0;
}

.listing-modern-grid-9.l-1 .mg-col-3 .mg-row.mg-row-1, .listing-modern-grid-9.l-2 .mg-col-3 .mg-row.mg-row-1, .listing-modern-grid-9.l-3 .mg-col-3 .mg-row.mg-row-1, .listing-modern-grid-9.l-4 .mg-col-3 .mg-row.mg-row-1, .listing-modern-grid-9.l-5 .mg-col-3 .mg-row.mg-row-1 {
  padding-right: 1px;
}

.listing-modern-grid-9.l-1 .mg-col-3 .mg-row.mg-row-2, .listing-modern-grid-9.l-2 .mg-col-3 .mg-row.mg-row-2, .listing-modern-grid-9.l-3 .mg-col-3 .mg-row.mg-row-2, .listing-modern-grid-9.l-4 .mg-col-3 .mg-row.mg-row-2, .listing-modern-grid-9.l-5 .mg-col-3 .mg-row.mg-row-2 {
  padding-left: 1px;
}

.listing-modern-grid-9.l-2 .mg-col-1, .listing-modern-grid-9.l-2 .mg-col-2, .listing-modern-grid-9.l-3 .mg-col-1, .listing-modern-grid-9.l-3 .mg-col-2, .listing-modern-grid-9.l-4 .mg-col-1, .listing-modern-grid-9.l-4 .mg-col-2, .listing-modern-grid-9.l-5 .mg-col-1, .listing-modern-grid-9.l-5 .mg-col-2 {
  width: 50%;
}

.listing-modern-grid-9.l-2 .listing-mg-item:before, .listing-modern-grid-9.l-3 .listing-mg-item:before, .listing-modern-grid-9.l-4 .listing-mg-item:before, .listing-modern-grid-9.l-5 .listing-mg-item:before {
  padding-top: 93%;
}

.listing-modern-grid-9.l-2 .listing-item-1:before, .listing-modern-grid-9.l-3 .listing-item-1:before, .listing-modern-grid-9.l-4 .listing-item-1:before, .listing-modern-grid-9.l-5 .listing-item-1:before {
  padding-top: 92.876%;
}

.listing-modern-grid-9.l-2 .mg-col-3 .listing-item:before {
  padding-top: 37%;
}

.listing-modern-grid-9.l-2 .listing-mg-item .title, .listing-modern-grid-9.l-3 .listing-mg-item .title, .listing-modern-grid-9.l-4 .listing-mg-item .title, .listing-modern-grid-9.l-5 .listing-mg-item .title {
  font-size: 66%;
}

.listing-modern-grid-9.l-2 .listing-item-1 .title, .listing-modern-grid-9.l-3 .listing-item-1 .title, .listing-modern-grid-9.l-4 .listing-item-1 .title, .listing-modern-grid-9.l-5 .listing-item-1 .title {
  font-size: 86%;
}

.listing-modern-grid-9.l-2 .mg-col-2 .term-badges, .listing-modern-grid-9.l-2 .mg-col-3 .term-badges, .listing-modern-grid-9.l-3 .mg-col-2 .term-badges, .listing-modern-grid-9.l-3 .mg-col-3 .term-badges, .listing-modern-grid-9.l-4 .mg-col-2 .term-badges, .listing-modern-grid-9.l-4 .mg-col-3 .term-badges, .listing-modern-grid-9.l-5 .mg-col-2 .term-badges, .listing-modern-grid-9.l-5 .mg-col-3 .term-badges {
  display: none;
}

.listing-modern-grid-9.l-3 .listing-mg-item .post-meta, .listing-modern-grid-9.l-4 .listing-mg-item .post-meta, .listing-modern-grid-9.l-5 .listing-mg-item .post-meta {
  display: none !important;
}

.listing-modern-grid-9.l-3 .listing-mg-item .format-icon, .listing-modern-grid-9.l-3 .listing-mg-item:hover .format-icon, .listing-modern-grid-9.l-4 .listing-mg-item .format-icon, .listing-modern-grid-9.l-4 .listing-mg-item:hover .format-icon, .listing-modern-grid-9.l-5 .listing-mg-item .format-icon, .listing-modern-grid-9.l-5 .listing-mg-item:hover .format-icon {
  right: 12px !important;
  top: 12px !important;
}

.listing-modern-grid-9.l-3.l-3 .listing-mg-item:before, .listing-modern-grid-9.l-4 .listing-mg-item:before, .listing-modern-grid-9.l-5 .listing-mg-item:before {
  padding-top: 60%;
}

.listing-modern-grid-9.l-3 .listing-item-1:before, .listing-modern-grid-9.l-4 .listing-item-1:before, .listing-modern-grid-9.l-5 .listing-item-1:before {
  padding-top: 45%;
}

.listing-modern-grid-9.l-3 .mg-col-1, .listing-modern-grid-9.l-4 .mg-col-1, .listing-modern-grid-9.l-5 .mg-col-1 {
  width: 100% !important;
  padding: 0 !important;
  margin-bottom: 2px !important;
}

.listing-modern-grid-9.l-3 .mg-col-2, .listing-modern-grid-9.l-4 .mg-col-2, .listing-modern-grid-9.l-5 .mg-col-2 {
  width: 100% !important;
  padding: 0 !important;
}

.listing-modern-grid-9.l-4 .listing-mg-item:before, .listing-modern-grid-9.l-4 .mg-col-3 .listing-item:before, .listing-modern-grid-9.l-5 .listing-mg-item:before, .listing-modern-grid-9.l-5 .mg-col-3 .listing-item:before {
  padding-top: 80%;
}

.listing-modern-grid-9.l-4 .listing-item-1:before, .listing-modern-grid-9.l-5 .listing-item-1:before {
  padding-top: 55%;
}

.slider-overlay-simple.listing-modern-grid-9.l-5 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-9.l-5 .listing-mg-item.listing-item-3 .content-container, .slider-overlay-simple.listing-modern-grid-9.l-5 .listing-mg-item.listing-item-4 .content-container, .slider-overlay-simple.listing-modern-grid-9.l-5 .listing-mg-item.listing-item-5 .content-container {
  max-height: 64%;
  padding: 12px !important;
}

@media only screen and (max-width: 980px) {
  .listing-modern-grid-9 .mg-col-1 {
    width: 53%;
  }
  .listing-modern-grid-9 .mg-col-2 {
    width: 47%;
  }
  .listing-modern-grid-9 .listing-mg-9-item:before {
    padding-top: 86.97%;
  }
  .listing-modern-grid-9 .listing-item-1:before {
    padding-top: 77%;
  }
  .listing-modern-grid-9 .listing-mg-item .content-container {
    left: 15px;
    right: 15px;
    bottom: 12px;
    max-height: 50%;
  }
  .listing-modern-grid-9 .mg-col-3 .mg-row {
    width: 50% !important;
    float: left !important;
  }
  .listing-modern-grid-9 .mg-col-3 {
    width: 100%;
    padding: 2px 0 0;
  }
  .listing-modern-grid-9 .mg-col-3 .mg-row.mg-row-1 {
    padding-right: 1px;
  }
  .listing-modern-grid-9 .mg-col-3 .mg-row.mg-row-2 {
    padding-left: 1px;
  }
  .listing-modern-grid-9 .mg-col-3 .listing-item:before {
    padding-top: 37%;
  }
}
@media only screen and (max-width: 780px) {
  .listing-modern-grid-9 .mg-col-1, .listing-modern-grid-9 .mg-col-2 {
    width: 50% !important;
  }
  .listing-modern-grid-9 .listing-mg-item:before {
    padding-top: 93%;
  }
  .listing-modern-grid-9 .listing-item-1:before {
    padding-top: 92.876%;
  }
  .listing-modern-grid-9 .listing-mg-item .title {
    font-size: 66%;
  }
  .listing-modern-grid-9 .listing-item-1 .title {
    font-size: 86%;
  }
  .listing-modern-grid-9 .mg-col-2 .term-badges, .listing-modern-grid-9 .mg-col-3 .term-badges {
    display: none;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-9 .listing-mg-item .post-meta {
    display: none;
  }
  .listing-modern-grid-9 .listing-mg-item .format-icon, .listing-modern-grid-9 .listing-mg-item:hover .format-icon {
    right: 12px;
    top: 12px;
  }
  .listing-modern-grid-9 .listing-mg-item:before, .listing-modern-grid-9 .mg-col-3 .listing-item:before {
    padding-top: 60%;
  }
  .listing-modern-grid-9 .listing-item-1:before {
    padding-top: 45%;
  }
  .listing-modern-grid-9 .mg-col-1 {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 2px !important;
  }
  .listing-modern-grid-9 .mg-col-2 {
    width: 100% !important;
    padding: 0 !important;
  }
}
@media only screen and (max-width: 560px) {
  .listing-modern-grid-9 .listing-mg-item:before, .listing-modern-grid-9 .mg-col-3 .listing-item:before {
    padding-top: 80% !important;
  }
  .listing-modern-grid-9 .listing-item-1:before {
    padding-top: 55% !important;
  }
}
@media only screen and (max-width: 480px) {
  .slider-overlay-simple.listing-modern-grid-9 .listing-mg-item.listing-item-2 .content-container, .slider-overlay-simple.listing-modern-grid-9 .listing-mg-item.listing-item-3 .content-container, .slider-overlay-simple.listing-modern-grid-9 .listing-mg-item.listing-item-4 .content-container, .slider-overlay-simple.listing-modern-grid-9 .listing-mg-item.listing-item-5 .content-container {
    max-height: 64%;
    padding: 12px;
  }
}
.listing-mg-10-item:before {
  padding-top: 60%;
}

.listing-modern-grid-10 .mg-row-1 {
  padding-bottom: 4px;
}

.listing-modern-grid-10 .mg-row-1 .mg-col-1 {
  width: 50%;
  float: left;
  padding-right: 2px;
}

.listing-modern-grid-10 .mg-row-1 .mg-col-2 {
  width: 50%;
  float: left;
  padding-left: 2px;
}

.listing-modern-grid-10 .mg-row-2 .mg-col {
  width: 25%;
  float: left;
}

.listing-modern-grid-10 .mg-row-2 .mg-col-1 {
  padding-right: 3px;
}

.listing-modern-grid-10 .mg-row-2 .mg-col-2 {
  padding-left: 1px;
  padding-right: 2px;
}

.listing-modern-grid-10 .mg-row-2 .mg-col-3 {
  padding-left: 2px;
  padding-right: 1px;
}

.listing-modern-grid-10 .mg-row-2 .mg-col-4 {
  padding-left: 3px;
}

.listing-modern-grid-10 .mg-row-2 .listing-mg-10-item {
  width: 100%;
}

.listing-modern-grid-10 .mg-row-2 .listing-mg-10-item:before {
  padding-top: 64%;
}

.listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .post-subtitle, .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .title {
  font-size: 75%;
}

.listing-modern-grid-10 .mg-row-2 .listing-mg-item:hover .format-icon {
  margin-top: -46px;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.listing-modern-grid-10.l-1 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10.l-1 .listing-mg-10-item.listing-mg-item:hover .format-icon {
  right: 20px !important;
  top: 20px !important;
  transform: none;
  margin: 0;
}

.listing-modern-grid-10.l-1 .mg-row-2 .mg-col, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col {
  width: 50%;
}

.listing-modern-grid-10.l-1 .mg-row-2 .mg-col-1, .listing-modern-grid-10.l-1 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-1, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-1, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-1, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-3 {
  padding-right: 2px;
  padding-left: 0;
}

.listing-modern-grid-10.l-1 .mg-row-2 .mg-col-2, .listing-modern-grid-10.l-1 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-2, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-2, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-2, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-4 {
  padding-left: 2px;
  padding-right: 0;
}

.listing-modern-grid-10.l-1 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-1 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-2 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-3 .mg-row-2 .mg-col-4, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-3, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-4 {
  padding-top: 4px;
}

.listing-modern-grid-10.l-1 .mg-row-1 .listing-mg-10-item .title, .listing-modern-grid-10.l-2 .mg-row-1 .listing-mg-10-item .title, .listing-modern-grid-10.l-3 .mg-row-1 .listing-mg-10-item .title, .listing-modern-grid-10.l-4 .mg-row-1 .listing-mg-10-item .title {
  font-size: 92%;
}

.listing-modern-grid-10.l-1 .mg-row-2 .listing-mg-10-item .title, .listing-modern-grid-10.l-2 .mg-row-2 .listing-mg-10-item .title, .listing-modern-grid-10.l-3 .mg-row-2 .listing-mg-10-item .title, .listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item .title {
  font-size: 72%;
}

.listing-modern-grid-10.l-2 .mg-row-1 .mg-col, .listing-modern-grid-10.l-3 .mg-row-1 .mg-col, .listing-modern-grid-10.l-4 .mg-row-1 .mg-col {
  width: 100%;
  padding: 0;
  float: none;
}

.listing-modern-grid-10.l-2 .mg-row-1 .mg-col-1, .listing-modern-grid-10.l-3 .mg-row-1 .mg-col-1, .listing-modern-grid-10.l-4 .mg-row-1 .mg-col-1 {
  padding-bottom: 4px;
}

.listing-modern-grid-10.l-2 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10.l-2 .listing-mg-10-item.listing-mg-item:hover .format-icon, .listing-modern-grid-10.l-3 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10.l-3 .listing-mg-10-item.listing-mg-item:hover .format-icon, .listing-modern-grid-10.l-4 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10.l-4 .listing-mg-10-item.listing-mg-item:hover .format-icon {
  right: 20px !important;
  top: 20px !important;
  transform: none;
  margin: 0;
}

.listing-modern-grid-10.l-2 .mg-row-1 .listing-mg-10-item:before, .listing-modern-grid-10.l-3 .mg-row-1 .listing-mg-10-item:before, .listing-modern-grid-10.l-4 .mg-row-1 .listing-mg-10-item:before {
  padding-top: 36%;
}

.listing-modern-grid-10.l-2 .mg-row-2 .listing-mg-10-item:before, .listing-modern-grid-10.l-3 .mg-row-2 .listing-mg-10-item:before, .listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item:before {
  padding-top: 70%;
}

.listing-modern-grid-10.l-3 .listing-item .post-meta, .listing-modern-grid-10.l-4 .listing-item .post-meta {
  display: none;
}

.listing-modern-grid-10.l-3 .mg-row-2 .mg-col, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col {
  width: 50%;
}

.listing-modern-grid-10.l-3 .mg-row-2 .mg-col-1, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-1 {
  padding-right: 2px;
}

.listing-modern-grid-10.l-3 .mg-row-2 .mg-col-2, .listing-modern-grid-10.l-4 .mg-row-2 .mg-col-2 {
  padding-left: 2px;
  padding-right: 0;
}

.listing-modern-grid-10.l-3 .mg-row-2 .listing-mg-10-item .format-icon, .listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item .format-icon {
  display: none;
}

.listing-modern-grid-10.l-4 .mg-row-1 .listing-mg-10-item:before {
  padding-top: 52%;
}

.listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item:before {
  padding-top: 76%;
}

.listing-modern-grid-10.l-4 .mg-row-1 .listing-mg-10-item .title {
  font-size: 82%;
}

.listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item .title {
  font-size: 62%;
}

.listing-modern-grid-10.l-4 .mg-row-2 .listing-mg-10-item .content-container {
  max-height: 43%;
}

@media only screen and (max-width: 980px) {
  .listing-modern-grid-10 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10 .listing-mg-10-item.listing-mg-item:hover .format-icon {
    right: 20px !important;
    top: 20px !important;
    transform: none;
    margin: 0;
  }
  .listing-modern-grid-10 .mg-row-1 .listing-mg-10-item .title {
    font-size: 92%;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .title {
    font-size: 72%;
  }
}
@media only screen and (max-width: 780px) {
  .listing-modern-grid-10 .mg-row-2 .mg-col {
    width: 50% !important;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col-1, .listing-modern-grid-10 .mg-row-2 .mg-col-3 {
    padding-right: 2px !important;
    padding-left: 0 !important;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col-2, .listing-modern-grid-10 .mg-row-2 .mg-col-4 {
    padding-left: 2px !important;
    padding-right: 0 !important;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col-3, .listing-modern-grid-10 .mg-row-2 .mg-col-4 {
    padding-top: 4px !important;
  }
  .listing-modern-grid-10 .mg-row-1 .mg-col {
    width: 100% !important;
    padding: 0 !important;
    float: none !important;
  }
  .listing-modern-grid-10 .mg-row-1 .mg-col-1 {
    padding-bottom: 4px !important;
  }
  .listing-modern-grid-10 .mg-row-1 .listing-mg-10-item:before {
    padding-top: 30%;
  }
  .listing-modern-grid-10 .listing-mg-10-item.listing-mg-item .format-icon, .listing-modern-grid-10 .listing-mg-10-item.listing-mg-item:hover .format-icon {
    right: 20px !important;
    top: 20px !important;
    transform: none;
    margin: 0;
  }
  .listing-modern-grid-10 .listing-mg-10-item:before {
    padding-top: 70%;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item:before {
    padding-top: 50%;
  }
}
@media only screen and (max-width: 620px) {
  .listing-modern-grid-10 .listing-item .post-meta {
    display: none;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col {
    width: 50% !important;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col-1 {
    padding-right: 2px !important;
  }
  .listing-modern-grid-10 .mg-row-2 .mg-col-2 {
    padding-left: 2px !important;
    padding-right: 0 !important;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .format-icon {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .listing-modern-grid-10 .mg-row-1 .listing-mg-10-item:before {
    padding-top: 52% !important;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item:before {
    padding-top: 76% !important;
  }
  .listing-modern-grid-10 .mg-row-1 .listing-mg-10-item .title {
    font-size: 82%;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .title {
    font-size: 62%;
  }
  .listing-modern-grid-10 .mg-row-2 .listing-mg-10-item .content-container {
    max-height: 43%;
  }
}
.listing-item-user {
  position: relative;
}

.listing-item-user .user-avatar img {
  border-radius: 50%;
  display: inline-block;
}

.listing-item-user .btn-light {
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #3c3c3c !important;
  vertical-align: top;
  line-height: 24px;
  font-size: 10px;
  padding: 0 8px;
  margin: 0 10px 0 0;
}

.listing-item-user .btn-light.btn-light:focus, .listing-item-user .btn-light.btn-light:hover {
  color: #3c3c3c !important;
  text-decoration: none !important;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.listing-item-user .user-social-icons {
  display: inline;
  padding: 0;
  margin: 0;
  vertical-align: top;
}

.listing-item-user .user-social-icons .social-item {
  display: inline-block;
  padding: 0;
  margin: 0 5px 7px 0;
}

.listing-item-user .user-social-icons .social-item:last-child {
  margin-right: 0;
}

.listing-item-user .user-social-icons a {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  padding: 0 4px;
  text-align: center;
  display: inline-block;
  min-width: 27px;
  font-size: 12px;
  line-height: 24px;
  color: #444;
  vertical-align: top;
}

.listing-item-user .user-social-icons .social-item a, .listing-item-user .user-social-icons .social-item a .fa {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.listing-item-user .user-social-icons .github:hover a:hover {
  color: #666;
}

.listing-item-user .user-social-icons .pinterest a:hover {
  color: #cb2027;
}

.listing-item-user .user-social-icons .youtube a:hover {
  color: #cc181e;
}

.listing-item-user .user-social-icons .linkedin a:hover {
  color: #0266a0;
}

.listing-item-user .user-social-icons .dribbble a:hover {
  color: #ea4c89;
}

.listing-item-user .user-social-icons .vimeo a:hover {
  color: #46a3e9;
}

.listing-item-user .user-social-icons .delicious a:hover {
  color: #4a79ff;
}

.listing-item-user .user-social-icons .soundcloud a:hover {
  color: #f50;
}

.listing-item-user .user-social-icons .behance a:hover {
  color: #3b63fc;
}

.listing-item-user .user-social-icons .flickr a:hover {
  color: #2f44db;
}

.listing-item-user .user-social-icons .instagram a:hover {
  color: #517fa4;
}

.listing-item-user .user-social-icons .google-plus a:hover {
  color: #d73d32;
}

.listing-item-user .user-social-icons .google-plus a:hover {
  color: #d73d32;
}

.listing-item-user .user-social-icons .google-plus a:hover {
  color: #d73d32;
}

.listing-item-user .user-social-icons .google-plus a:hover {
  color: #d73d32;
}

.listing-item-user .user-social-icons .twitter a:hover {
  color: #59c8ff;
}

.listing-item-user .user-social-icons .facebook a:hover {
  color: #4a639f;
}

.listing-item-user .user-avatar {
  float: left;
  margin-right: 15px;
  margin-bottom: 5px;
  position: relative;
}

.listing-item-user .user-display-name {
  padding: 0;
  color: #444;
  font-size: 18px;
  margin: 0 0 8px;
}

.listing-item-user .biography {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
}

.listing-item-user .biography p {
  margin: 0 0 10px 0;
}

.listing-item-user .user-badge {
  position: absolute;
  left: -11px;
  top: 35px;
  background: #0077d5;
  color: #fff;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  line-height: 23px;
  overflow: hidden;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  border: 3px solid #fff;
}

.listing-item-user .user-badge {
  left: -12px;
  top: 50%;
  width: 26px;
  height: 26px;
  line-height: 24px;
  font-size: 12px;
  border-width: 2px;
  margin-top: -13px;
}

.listing-user.columns-1 .listing-item-user {
  padding-bottom: 0 !important;
}

.listing-user .listing-item-user {
  padding-bottom: 0 !important;
}

.bs-listing.bs-listing-user-listing-1 .bs-slider-dots:first-child:last-child, .bs-listing.bs-listing-user-listing-2 .bs-slider-dots:first-child:last-child, .bs-listing.bs-listing-user-listing-3 .bs-slider-dots:first-child:last-child, .bs-listing.bs-listing-user-listing-4 .bs-slider-dots:first-child:last-child {
  position: relative;
  left: auto;
  right: auto;
}

.listing-item-user.type-1 .user-social-icons .social-item a:hover {
  background: currentColor;
  border-color: currentColor;
}

.listing-item-user.type-1 .user-social-icons .social-item a:hover .fa {
  color: #fff;
}

.listing-item-user.type-1 {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 13px;
  position: relative;
}

.listing.columns-1 .listing-item-user.type-1:last-child {
  border-width: 0;
}

.listing-item-user.type-1.style-2 .user-avatar {
  float: left;
  margin: 0;
}

.listing-item-user.type-1.style-2 .user-meta {
  padding-left: 120px;
}

.listing-item-user.type-1.style-2 .user-avatar {
  margin-right: 20px;
  margin-bottom: 10px;
}

.listing-item-user.type-1.style-2 .user-display-name {
  font-size: 20px;
}

.listing-item-user.type-2 .bs-user-item {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.069);
  margin-right: 25px;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  position: relative;
  margin-bottom: 16px;
}

.listing-item-user.type-2 .bs-user-item:hover {
  -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.1);
}

.listing-item-user.type-2 .bs-user-item:last-child {
  margin-right: 0;
}

.listing-item-user.type-2 .user-avatar {
  float: none;
  margin: 0;
}

.listing-item-user.type-2 .user-social-icons {
  overflow: hidden;
  max-height: 22px;
  display: block;
  margin: 0 0 14px 0;
}

.listing-item-user.type-2 .btn-light {
  border-color: rgba(0, 0, 0, 0.18);
  color: #7d7d80 !important;
  line-height: 22px;
  padding: 0 12px;
  margin-bottom: 0;
}

.listing-item-user.type-2 .user-display-name {
  margin: 12px 0 8px;
}

.listing-item-user.type-2 .biography {
  font-size: 12px;
  line-height: 18px;
}

.listing-item-user.type-2 .biography p:last-child {
  margin: 0;
}

.listing-item-user.type-2 .user-badge {
  top: 8px;
  left: -5px;
}

.listing-item-user.type-2 {
  padding-bottom: 20px;
}

.listing-item-user.type-2 .user-social-icons a {
  border: transparent;
}

.listing-item-user.type-2 .user-social-icons .social-item {
  margin: 0 0 5px;
}

.listing-item-user.type-2 .user-social-icons a {
  min-width: 24px;
}

.listing-item-user.type-2.style-1 {
  text-align: center;
}

.listing-item-user.type-2.style-1 .btn-light {
  border-radius: 12px;
}

.listing-item-user.type-2.style-2 .user-badge {
  left: -12px;
  top: 8px;
}

.more-stories {
  display: none;
  position: fixed;
  right: -330px;
  bottom: 70px;
  z-index: 9999;
  border: 1px solid #ddd;
  -webkit-box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  background: #fff;
  width: 325px;
  max-width: 60%;
}

.more-stories:before {
  content: "";
  display: inline-block;
  height: 4px;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -4px;
}

.more-stories.right {
  border-right-width: 0;
}

.more-stories.left {
  -webkit-box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-left-width: 0;
  right: auto;
  left: -330px;
}

.more-stories.more-stories-thumbnail-2 {
  width: 355px;
  right: -360px;
}

.more-stories.more-stories-thumbnail-2.left {
  width: 355px;
  right: auto;
  left: -360px;
}

.more-stories-title {
  text-align: center;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  color: #313131;
  border-bottom: 1px solid #f0f0f0;
}

.more-stories .listing {
  padding-top: 15px;
}

.more-stories .bs-pagination:last-child {
  margin-bottom: 10px;
}

.more-stories-close {
  float: left;
  color: #cecece;
}

.more-stories.left .more-stories-close {
  float: right;
}

.more-stories .listing-item {
  margin-bottom: 8px;
}

.more-stories .listing-item:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 680px) {
  .more-stories {
    display: none !important;
  }
}
.bs-irp {
  position: relative;
  width: 320px;
  margin: 10px 0 15px 0;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  padding-bottom: 20px;
}

.bs-irp .bs-irp-heading {
  font-size: 15px;
  margin-bottom: 16px;
  position: relative;
  padding: 8px 0 0;
  text-transform: uppercase;
}

.bs-irp .bs-irp-heading:before {
  content: "";
  display: inline-block;
  width: 70px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

.bs-irp br {
  display: none;
}

.bs-irp p:empty {
  display: none;
}

.bs-irp p {
  margin-bottom: 0 !important;
}

.bs-irp a:hover {
  text-decoration: none !important;
}

.bs-irp.right {
  float: right;
  margin-left: 30px;
}

.bs-irp.left {
  float: left;
  margin-right: 30px;
}

.bs-irp.center {
  float: none;
  margin-right: auto;
  margin-left: auto;
}

.bs-irp .listing-text .listing-item:last-child .item-inner {
  border-bottom-width: 0;
  padding-bottom: 0;
}

.bs-irp .listing .listing-item:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.bs-irp.bs-irp-thumbnail-2 {
  padding-bottom: 0;
}

.bs-irp.bs-irp-text-1-full, .bs-irp.bs-irp-text-2-full, .bs-irp.bs-irp-text-3-full, .bs-irp.bs-irp-text-4-full, .bs-irp.bs-irp-thumbnail-1-full, .bs-irp.bs-irp-thumbnail-2-full, .bs-irp.bs-irp-thumbnail-3-full {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  float: none;
  padding-bottom: 0;
  clear: both;
  border-top-width: 1px;
  padding-top: 3px;
}

.bs-irp.bs-irp-text-1-full .bs-irp-heading:before, .bs-irp.bs-irp-text-2-full .bs-irp-heading:before, .bs-irp.bs-irp-text-3-full .bs-irp-heading:before, .bs-irp.bs-irp-text-4-full .bs-irp-heading:before, .bs-irp.bs-irp-thumbnail-1-full .bs-irp-heading:before, .bs-irp.bs-irp-thumbnail-2-full .bs-irp-heading:before, .bs-irp.bs-irp-thumbnail-3-full .bs-irp-heading:before {
  display: none;
}

.bs-irp.bs-irp-text-1-full .listing-item-text-1, .bs-irp.bs-irp-text-1-full .listing-item-text-1:last-child, .bs-irp.bs-irp-text-2-full .listing-item-text-2, .bs-irp.bs-irp-text-2-full .listing-item-text-2:last-child, .bs-irp.bs-irp-text-3-full .listing-item-text-3, .bs-irp.bs-irp-text-3-full .listing-item-text-3:last-child, .bs-irp.bs-irp-text-4-full .listing-item-text-4, .bs-irp.bs-irp-text-4-full .listing-item-text-4:last-child {
  border-bottom: 0;
  margin-bottom: 17px !important;
  padding-bottom: 0 !important;
}

.bs-irp.bs-irp-text-1-full .listing-item-text-1 .item-inner {
  padding-bottom: 0 !important;
  border: none;
}

.bs-irp.bs-irp-thumbnail-2 .bs-pagination {
  padding-bottom: 18px;
}

.bs-irp.bs-irp-text-1-full .bs-pagination, .bs-irp.bs-irp-text-2-full .bs-pagination, .bs-irp.bs-irp-text-3-full .bs-pagination, .bs-irp.bs-irp-text-4-full .bs-pagination, .bs-irp.bs-irp-thumbnail-1-full .bs-pagination, .bs-irp.bs-irp-thumbnail-2-full .bs-pagination, .bs-irp.bs-irp-thumbnail-3-full .bs-pagination {
  padding-bottom: 18px;
  padding-top: 0;
  margin-top: 0;
}

.bs-irp .post-meta p {
  display: inline;
}

@media only screen and (max-width: 530px) {
  .bs-irp.left, .bs-irp.right {
    float: none;
    width: 100%;
    max-width: 100%;
    margin: 21px 0 21px 0;
  }
}
.bs-listing-slider-1 > .bs-slider, .bs-listing-slider-2 > .bs-slider, .bs-listing-slider-3 > .bs-slider {
  margin-bottom: 0;
}

.bs-listing-slider-1, .bs-listing-slider-2, .bs-listing-slider-3 {
  margin-bottom: 22px;
}

.bs-shortcode.bs-slider .better-direction-nav a {
  text-indent: -9999px;
}

.bs-shortcode.bs-slider .better-direction-nav a:before {
  line-height: 38px;
  font-size: 14px;
  display: inline-block;
  content: "\f053";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-indent: 0;
  width: auto;
  height: auto;
}

.bs-shortcode.bs-slider .better-direction-nav a.better-next:before {
  content: "\f054";
  right: -3px;
}

.bs-slider-item {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.bs-slider-item > .item-content {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.bs-slider-item > .item-content:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 45%;
}

.bs-slider-item .img-cont {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bs-slider-item .img-cont.b-error, .bs-slider-item .img-cont.b-loaded, body.bs-ll-d .bs-slider-item .img-cont {
  background-size: cover !important;
  animation: none;
}

.bs-slider .bs-slider-item:nth-child(1) {
  float: left;
  display: block;
  width: 100%;
}

.bs-slider-item.has-not-post-thumbnail .img-cont {
  background-color: #e2e2e2;
}

.bs-slider-item .post-subtitle {
  text-align: center !important;
}

.bs-slider-1-item > .item-content:before {
  padding-top: 45%;
}

.bs-slider-1-item .img-cont:after {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.55)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 160px;
}

.bs-slider-1-item:hover .img-cont {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
}

.bs-slider-1-item .content-container {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  position: absolute;
  bottom: -80px;
  left: 30px;
  right: 30px;
  padding: 0 30px 30px;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
}

.bs-slider-1-item.slider-content-shown .content-container {
  bottom: 0;
  opacity: 1;
}

.bs-slider-1-item .content-container a {
  pointer-events: all;
}

.bs-slider-1-item .title {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  text-align: center !important;
  max-width: 70%;
  margin: 0 auto;
}

.bs-slider-1-item .title a {
  color: #fff;
}

.bs-slider-1-item .post-meta {
  margin-top: 12px;
  text-align: center !important;
  font-size: 11px;
}

.bs-slider-1-item .post-meta .comments, .bs-slider-1-item .post-meta .post-author {
  color: #fff;
  font-size: 11px;
}

.bs-slider-1-item .post-meta .comments {
  float: none;
  margin-left: 0;
}

.bs-slider-1-item .post-meta .post-author:after, .bs-slider-1-item .post-meta .time {
  color: rgba(255, 255, 255, 0.57);
}

.bs-slider-1-item .term-badges.floated {
  position: absolute;
  right: auto;
  left: 0;
  top: 30px;
  bottom: auto;
  width: 100%;
  text-align: center;
  pointer-events: none;
}

.bs-slider-1 .better-direction-nav .better-next, .bs-slider-1 .better-direction-nav .better-prev {
  opacity: 1;
  transform: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: 0 0;
  border: 2px solid #fff;
  text-align: center;
  line-height: 40px;
  color: #fff;
  text-shadow: none;
  margin: -36px 0 0;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.bs-slider-1 .better-direction-nav .better-prev {
  left: 20px;
}

.bs-slider-1 .better-direction-nav .better-next {
  right: 20px;
}

.bs-slider-1-item:hover .format-icon {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.bs-slider-1-item .post-subtitle {
  margin-top: 7px;
  color: #fff !important;
}

.bs-slider-1-item .post-subtitle:first-child {
  margin: 0 0 7px;
}

.bs-slider-1.l-1 .bs-slider-1-item > .item-content:before, .bs-slider-1.l-2 .bs-slider-1-item > .item-content:before {
  padding-top: 60%;
}

.bs-slider-1.l-2 .bs-slider-1-item > .item-content:before {
  padding-top: 110%;
}

.bs-slider-1.l-2 .bs-slider-1-item .post-meta {
  display: none;
}

.bs-slider-1.l-2 .bs-slider-1-item .title a {
  font-size: 75%;
  line-height: 170%;
  display: inline-block;
}

.bs-slider-1.l-2 .bs-slider-1-item .content-container {
  left: 0;
  right: 0;
  padding: 0 20px 20px;
}

.bs-slider-1.l-2 .better-direction-nav .better-prev {
  left: -20px;
  opacity: 0;
}

.bs-slider-1.l-2:hover .better-direction-nav .better-prev {
  left: 10px;
  opacity: 1;
}

.bs-slider-1.l-2 .better-direction-nav .better-next {
  right: -20px;
  opacity: 0;
}

.bs-slider-1.l-2:hover .better-direction-nav .better-next {
  right: 10px;
  opacity: 1;
}

@media only screen and (max-width: 780px) {
  .bs-slider-1 .bs-slider-1-item > .item-content:before {
    padding-top: 60%;
  }
}
@media only screen and (max-width: 370px) {
  .bs-slider-1 .bs-slider-1-item .post-meta {
    display: none;
  }
  .bs-slider-1 .bs-slider-1-item > .item-content:before {
    padding-top: 110% !important;
  }
  .bs-slider-1 .bs-slider-1-item .title a {
    font-size: 75%;
    line-height: 170%;
    display: inline-block;
  }
  .bs-slider-1 .bs-slider-1-item .content-container {
    left: 0;
    right: 0;
    padding: 0 20px 20px;
  }
  .bs-slider-1 .better-direction-nav .better-prev {
    left: -20px;
    opacity: 0;
  }
  .bs-slider-1:hover .better-direction-nav .better-prev {
    left: 10px;
    opacity: 1;
  }
  .bs-slider-1 .better-direction-nav .better-next {
    right: -20px;
    opacity: 0;
  }
  .bs-slider-1:hover .better-direction-nav .better-next {
    right: 10px;
    opacity: 1;
  }
}
.bs-slider-2-item > .item-content:before {
  padding-top: 45%;
}

.bs-slider-2-item:hover .img-cont {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
}

.bs-slider-2-item .content-container {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  position: absolute;
  left: 50%;
  bottom: 10px;
  padding: 30px;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  width: 460px;
  margin: 0 0 0 -230px;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.bs-slider-2-item.slider-content-shown .content-container {
  bottom: 40px;
  opacity: 1;
}

.bs-slider-2-item .content-container a {
  pointer-events: all;
}

.bs-slider-2-item .title {
  margin: 10px 0 0;
  line-height: 1.4;
  text-align: center !important;
}

.bs-slider-2-item .title a {
  color: #212121;
}

.bs-slider-2-item .post-meta {
  margin-top: 8px;
  text-align: center !important;
  font-size: 12px;
}

.bs-slider-2-item .post-meta .comments, .bs-slider-2-item .post-meta .post-author {
  color: #212121;
  font-size: 12px;
}

.bs-slider-2-item .post-meta .comments {
  float: none;
  margin-left: 0;
}

.bs-slider-2-item .post-meta .post-author:after, .bs-slider-2-item .post-meta .time {
  color: #212121;
}

.bs-slider-2-item .content-container a.read-more {
  border: 1px solid transparent;
  background: 0 0;
  color: #fff;
  font-size: 12px;
  padding: 6px 20px;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  line-height: 20px;
  margin-top: 15px;
}

.bs-slider-2-item .content-container a.read-more:hover {
  color: #fff;
}

.bs-slider-2-item .term-badges.floated {
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.bs-slider-2-item .term-badges.floated .term-badge a {
  background: #212121 !important;
  color: #fff !important;
  margin: 0;
}

.bs-slider-2-item:hover .format-icon {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.bs-slider-2 .better-direction-nav .better-next, .bs-slider-2 .better-direction-nav .better-prev {
  transform: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: 0 0;
  border: 2px solid #fff;
  text-align: center;
  line-height: 40px;
  color: #fff;
  text-shadow: none;
  margin: -36px 0 0;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.bs-slider-2-item .post-subtitle {
  margin-top: 7px;
}

.bs-slider-2-item .post-subtitle:first-child, .bs-slider-2-item .term-badges + .post-subtitle {
  margin: 0 0 -4px;
}

.bs-slider-2.l-1 .bs-slider-2-item > .item-content:before, .bs-slider-2.l-2 .bs-slider-2-item > .item-content:before, .bs-slider-2.l-3 .bs-slider-2-item > .item-content:before {
  padding-top: 70%;
}

.bs-slider-2.l-1 .bs-slider-2-item .title a, .bs-slider-2.l-2 .bs-slider-2-item .title a, .bs-slider-2.l-3 .bs-slider-2-item .title a {
  font-size: 85%;
}

.bs-slider-2.l-2 .bs-slider-2-item > .item-content:before, .bs-slider-2.l-3 .bs-slider-2-item > .item-content:before {
  padding-top: 120%;
}

.bs-slider-2.l-2 .bs-slider-2-item .post-meta, .bs-slider-2.l-3 .bs-slider-2-item .post-meta {
  display: none;
}

.bs-slider-2.l-2 .bs-slider-2-item .title a, .bs-slider-2.l-3 .bs-slider-2-item .title a {
  font-size: 75%;
  line-height: 170%;
  display: inline-block;
}

.bs-slider-2.l-2 .bs-slider-2-item .content-container, .bs-slider-2.l-3 .bs-slider-2-item .content-container {
  left: 7.5%;
  bottom: 7.5%;
  right: 7.5%;
  padding: 20px;
  width: 85%;
  transform: none;
  margin: 0;
}

.bs-slider-2.l-3 .better-direction-nav .better-next, .bs-slider-2.l-3 .better-direction-nav .better-prev {
  display: none;
}

@media only screen and (max-width: 780px) {
  .bs-slider-2-item > .item-content:before {
    padding-top: 70%;
  }
  .bs-slider-2-item .title a {
    font-size: 85%;
  }
}
@media only screen and (max-width: 520px) {
  .bs-slider-2-item > .item-content:before {
    padding-top: 120% !important;
  }
  .bs-slider-2-item .post-meta {
    display: none;
  }
  .bs-slider-2-item .title a {
    font-size: 75%;
    line-height: 170%;
    display: inline-block;
  }
  .bs-slider-2-item .content-container {
    left: 7.5%;
    bottom: 7.5%;
    right: 7.5%;
    padding: 20px;
    width: 85%;
    transform: none;
    margin: 0;
  }
}
@media only screen and (max-width: 370px) {
  .bs-slider-2 .better-direction-nav .better-next, .bs-slider-2 .better-direction-nav .better-prev {
    display: none;
  }
}
.bs-slider-3-item > .item-content:before {
  padding-top: 45%;
}

.bs-slider-3-item:hover .img-cont {
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
}

.bs-slider-3-item .content-container {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  position: absolute;
  left: 50%;
  top: 45%;
  padding: 30px;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  width: 460px;
  margin: -120px 0 0 -230px;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.bs-slider-3-item .content-container a.read-more {
  border: 1px solid transparent;
  background: 0 0;
  color: #fff;
  font-size: 12px;
  padding: 6px 20px;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  line-height: 20px;
  margin-top: 15px;
}

.bs-slider-3-item .content-container a.read-more:hover {
  color: #fff;
}

.bs-slider-3-item.slider-content-shown .content-container {
  opacity: 1;
  top: 50%;
}

.bs-slider-3-item .content-container a {
  pointer-events: all;
}

.bs-slider-3-item .title {
  margin: 10px 0 0;
  line-height: 1.4;
  text-align: center !important;
}

.bs-slider-3-item .title a {
  color: #212121;
}

.bs-slider-3-item .post-meta {
  margin-top: 8px;
  text-align: center !important;
  font-size: 12px;
}

.bs-slider-3-item .post-meta .comments, .bs-slider-3-item .post-meta .post-author {
  color: #212121;
  font-size: 12px;
}

.bs-slider-3-item .post-meta .comments {
  float: none;
  margin-left: 0;
}

.bs-slider-3-item .post-meta .post-author:after, .bs-slider-3-item .post-meta .time {
  color: #212121;
}

.bs-slider-3-item .term-badges.floated {
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.bs-slider-3-item .term-badges.floated .term-badge a {
  background: #212121 !important;
  color: #fff !important;
  margin: 0;
}

.bs-slider-3 .better-direction-nav .better-next, .bs-slider-3 .better-direction-nav .better-prev {
  transform: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: 0 0;
  border: 2px solid #fff;
  text-align: center;
  line-height: 40px;
  color: #fff;
  text-shadow: none;
  margin: -36px 0 0;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.bs-slider-3 .better-direction-nav .better-prev {
  left: 20px;
}

.bs-slider-3 .better-direction-nav .better-next {
  right: 20px;
}

.bs-slider-3-item:hover .format-icon {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

.bs-slider-3-item .post-subtitle {
  margin-top: 7px;
}

.bs-slider-3-item .post-subtitle:first-child, .bs-slider-3-item .term-badges + .post-subtitle {
  margin: 0 0 -4px;
}

.bs-slider-3.l-1 .bs-slider-3-item > .item-content:before, .bs-slider-3.l-2 .bs-slider-3-item > .item-content:before, .bs-slider-3.l-3 .bs-slider-3-item > .item-content:before {
  padding-top: 60%;
}

.bs-slider-3.l-1 .bs-slider-3-item .title a, .bs-slider-3.l-2 .bs-slider-3-item .title a, .bs-slider-3.l-3 .bs-slider-3-item .title a {
  font-size: 90%;
}

.bs-slider-3.l-1 .bs-slider-3-item > .item-content:before, .bs-slider-3.l-2 .bs-slider-3-item > .item-content:before, .bs-slider-3.l-3 .bs-slider-3-item > .item-content:before {
  padding-top: 60%;
}

.bs-slider-3.l-2 .bs-slider-3-item > .item-content:before, .bs-slider-3.l-3 .bs-slider-3-item > .item-content:before {
  padding-top: 110% !important;
}

.bs-slider-3.l-2 .bs-slider-3-item .post-meta, .bs-slider-3.l-3 .bs-slider-3-item .post-meta {
  display: none;
}

.bs-slider-3.l-2 .bs-slider-3-item .title a, .bs-slider-3.l-3 .bs-slider-3-item .title a {
  font-size: 75%;
  line-height: 150%;
  display: inline-block;
}

.bs-slider-3.l-3 .bs-slider-3-item .content-container {
  left: 7.5%;
  bottom: 7.5%;
  right: 7.5%;
  top: auto;
  padding: 20px;
  width: 85%;
  transform: none;
  margin: 0;
}

.bs-slider-3.l-3 .bs-slider-3-item.slider-content-shown .content-container {
  bottom: 25px;
}

.bs-slider-3.l-3 .better-direction-nav .better-next, .bs-slider-3.l-3 .better-direction-nav .better-prev {
  display: none;
}

@media only screen and (max-width: 780px) {
  .bs-slider-3 .bs-slider-3-item > .item-content:before {
    padding-top: 60%;
  }
  .bs-slider-3 .bs-slider-3-item .title a {
    font-size: 90%;
  }
  .bs-slider-3 .bs-slider-3-item > .item-content:before {
    padding-top: 60%;
  }
}
@media only screen and (max-width: 520px) {
  .bs-slider-3 .bs-slider-3-item > .item-content:before {
    padding-top: 110% !important;
  }
  .bs-slider-3 .bs-slider-3-item .post-meta {
    display: none;
  }
  .bs-slider-3 .bs-slider-3-item .title a {
    font-size: 75%;
    line-height: 150%;
    display: inline-block;
  }
  .bs-slider-3 .bs-slider-3-item .content-container {
    left: 7.5%;
    bottom: 7.5%;
    right: 7.5%;
    top: auto;
    padding: 20px;
    width: 85%;
    transform: none;
    margin: 0;
  }
}
@media only screen and (max-width: 380px) {
  .bs-slider-3 .bs-slider-3-item.slider-content-shown .content-container {
    bottom: 25px;
  }
  .bs-slider-3 .better-direction-nav .better-next, .bs-slider-3 .better-direction-nav .better-prev {
    display: none;
  }
}
.bs-push-noti {
  position: relative;
  padding: 10px;
  font-weight: 700;
  overflow: hidden;
  text-align: center;
}

.bs-push-noti-bg {
  display: none;
}

.bs-push-noti .bs-push-noti-button {
  text-transform: capitalize !important;
  line-height: 30px;
  font-weight: bolder;
}

.bs-push-noti .bs-push-noti-wrapper-icon {
  color: #fff;
}

.bs-push-noti.bspn-t1.bspn-t1 {
  text-align: left;
}

.bs-push-noti.bspn-t1 .bs-push-noti-inner {
  padding: 15px 10px 20px 124px;
  border: 1px solid #e8e8e8;
  position: relative;
  overflow: hidden;
}

.bs-push-noti.bspn-t1 p {
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 15px;
}

.bs-push-noti.bspn-t1 .bs-push-noti-wrapper-icon {
  padding: 10px;
  background: #bbb;
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  width: 111px;
  text-align: center;
}

.bs-push-noti.bspn-t1 .bs-push-noti-wrapper-icon:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 37px;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 5px 5px 5px 0;
  top: 50%;
  left: 50%;
  margin-left: -26px;
  margin-top: -23px;
}

.bs-push-noti.bspn-t1 .bs-push-noti-wrapper-icon:before {
  content: "";
  position: absolute;
  border-top: 0 solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 0 solid transparent;
  border-left: 14px solid rgba(255, 255, 255, 0.25);
  z-index: 2;
  left: 50%;
  margin-left: -26px;
  bottom: 50%;
  margin-bottom: -24px;
}

.bs-push-noti.bspn-t1 .bs-push-noti-wrapper-icon .notification-icon {
  position: absolute;
  top: 50%;
  font-size: 17px;
  margin-top: -13px;
  margin-left: -8.5px;
  color: #fff;
  z-index: 1;
}

.bs-push-noti.bspn-t1 .bs-push-noti-bg {
  display: block;
  position: absolute;
  top: -50%;
  right: -3%;
  font-size: 7em;
  transform: rotate(30deg);
  opacity: 0.09;
}

.bs-push-noti.post-bottom {
  margin-bottom: 0 !important;
}

.widget .bs-push-noti.bspn-t1 .bs-push-noti-wrapper-icon {
  position: absolute;
  left: -16px;
  right: -16px;
  top: -1px;
  display: block;
  height: 140px;
  width: auto;
}

.widget .bs-push-noti.bspn-t1 .bs-push-noti-inner {
  padding-left: 15px;
  padding-top: 150px;
}

.bs-push-noti.bspn-t2 {
  padding: 0;
}

.bs-push-noti.bspn-t2 .bs-push-noti-inner {
  background: #fdfdfd;
  text-align: center;
  margin-top: 35px;
  padding: 10px 20px 20px;
  border: 1px solid #e8e8e8;
}

.bs-push-noti.bspn-t2 .bs-push-noti-wrapper-icon {
  background: #ccc;
  border-radius: 50%;
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: -40px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  z-index: 9;
}

.bs-push-noti.bspn-t2 .notification-icon {
  line-height: 60px;
}

.bs-push-noti.bspn-t2 .bs-push-noti-message {
  margin-top: -25px;
  margin-bottom: 20px;
}

.bs-push-noti.bspn-t2 p {
  font-size: 15px;
  font-weight: 400;
  margin: 0;
}

.bs-push-noti.bspn-t2 .bs-push-noti-button:before {
  font: normal normal normal 14px/1 bs-icons;
  content: "끡";
  margin-right: 5px;
  vertical-align: middle;
}

.bs-push-noti.bspn-t2 .bs-push-noti-button {
  text-transform: capitalize !important;
  line-height: 20px;
  font-weight: bolder;
}

.bs-box-inner {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.bs-box-inner:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 70%;
}

.bs-box .box-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.bs-box .box-image {
  background-repeat: no-repeat !important;
  background-position: top center !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.bs-box-1 .bs-box-inner {
  background: #ccc;
}

.bs-box-1 .box-text {
  position: absolute;
  background: #fff;
  color: #333;
  font-size: 16px;
  text-align: center;
  padding: 20px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  opacity: 1;
  pointer-events: none;
}

.bs-box-1 .box-image:before {
  border: 1px solid #fff;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1, 1);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.bs-box-1:hover .box-image:before {
  bottom: 15px;
  left: 15px;
  opacity: 1;
  right: 15px;
  top: 15px;
}

.bs-box-1 .box-pre-title {
  font-size: 13px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.bs-box-1 .box-title {
  font-size: 22px;
  margin: 0;
}

.bs-box-2 .box-image:after {
  border: 1px solid #fff;
  content: "";
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  bottom: 15px;
  left: 15px;
  opacity: 1;
  right: 15px;
  top: 15px;
}

.bs-box-2 .box-text {
  position: absolute;
  top: 50%;
  pointer-events: none;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  -webkit-backface-visibility: hidden;
  text-align: center;
}

.bs-box-2 .box-image:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.bs-box-2:hover .box-image:before {
  background: rgba(0, 0, 0, 0.3);
}

.bs-box-2 .box-title {
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
  padding: 5px 10px;
  line-height: 1.3;
  background: #fff;
  color: #353535;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: inline-block;
}

.bs-box-3 .box-text {
  position: absolute;
  font-size: 16px;
  padding: 0;
  bottom: 25px;
  left: 25px;
  right: 25px;
  opacity: 1;
  pointer-events: none;
}

.bs-box-3 .box-image:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.bs-box-3:hover .box-image:before {
  background: rgba(0, 0, 0, 0.35);
}

.bs-box-3 .box-sub-title {
  font-size: 13px;
  margin-bottom: 5px;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
}

.bs-box-3 .box-title {
  font-size: 17px;
  margin: 0 0 10px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
}

.bs-box-3 .bf-icon {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
  font-size: 24px;
  display: block;
  margin-bottom: 15px;
}

.bs-box-3.box-no-bg .bf-icon, .bs-box-3.box-no-bg .box-sub-title, .bs-box-3.box-no-bg .box-title {
  color: #333;
  text-shadow: none;
}

.bs-box-3.box-no-bg {
  background: #eee;
}

.bs-box-3.box-no-bg .box-image:before {
  display: none;
}

.bs-box-3.box-text-left .bf-icon, .bs-box-3.box-text-left .box-sub-title, .bs-box-3.box-text-left .box-title {
  text-align: left !important;
}

.bs-box-3.box-text-right .bf-icon, .bs-box-3.box-text-right .box-sub-title, .bs-box-3.box-text-right .box-title {
  text-align: right !important;
}

.bs-box-3.box-text-center .bf-icon, .bs-box-3.box-text-center .box-sub-title, .bs-box-3.box-text-center .box-title {
  text-align: center !important;
}

.bs-box-4 .bs-box-inner:before {
  padding-top: 120%;
}

.bs-box-4 .box-text {
  position: absolute;
  font-size: 16px;
  padding: 0;
  bottom: 25px;
  left: 25px;
  right: 25px;
  opacity: 1;
  pointer-events: none;
}

.bs-box-4 .box-image:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.bs-box-4:hover .box-image:before {
  background: rgba(0, 0, 0, 0.35);
}

.bs-box-4 .box-sub-title {
  font-size: 13px;
  margin-bottom: 5px;
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
}

.bs-box-4 .box-title {
  font-size: 17px;
  margin: 0 0 10px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
}

.bs-box-4 .bf-icon {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.09);
  font-size: 24px;
  display: block;
  margin-bottom: 15px;
}

.bs-box-4.box-no-bg .bf-icon, .bs-box-4.box-no-bg .box-sub-title, .bs-box-4.box-no-bg .box-title {
  color: #333;
  text-shadow: none;
}

.bs-box-4.box-no-bg {
  background: #eee;
}

.bs-box-4.box-no-bg .box-image:before {
  display: none;
}

.bs-box-4.box-text-left .bf-icon, .bs-box-4.box-text-left .box-sub-title, .bs-box-4.box-text-left .box-title {
  text-align: left !important;
}

.bs-box-4.box-text-right .bf-icon, .bs-box-4.box-text-right .box-sub-title, .bs-box-4.box-text-right .box-title {
  text-align: right !important;
}

.bs-box-4.box-text-center .bf-icon, .bs-box-4.box-text-center .box-sub-title, .bs-box-4.box-text-center .box-title {
  text-align: center !important;
}

.listing-widget, .widget .listing-widget {
  padding: 0;
  margin: 0;
  list-style: none;
}

.listing-widget .listing-item img.wp-post-image {
  max-width: 80px;
  float: left;
  margin-right: 15px;
}

.listing-widget .listing-item {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.listing-widget .listing-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.listing-widget .img-holder {
  width: 80px;
}

.listing-widget .img-holder:before {
  padding-top: 70%;
}

.listing-widget .listing-item .post-meta {
  margin-top: 2px;
}

@media only screen and (max-width: 678px) {
  .footer-widgets > .content-wrap > .container > .row > :last-child {
    margin-bottom: 0 !important;
  }
}
.listing-widget.listing-widget-simple .listing-item .title {
  font-size: 14px;
  margin: 0;
}

.listing-widget.listing-widget-simple .listing-item .title a {
  line-height: 1.4;
}

.listing-widget.listing-widget-simple .listing-item .post-meta {
  margin-top: 5px;
  margin-bottom: 8px;
}

.listing-widget.listing-widget-thumbnail .listing-item .title {
  font-size: 14px;
  margin: 0;
  line-height: 1.2;
}

.separator-line {
  margin: 15px auto;
  width: 70%;
  overflow: hidden;
  text-align: center;
  position: relative;
  height: 20px;
  color: #949494;
}

.separator-line .sep-icon {
  padding: 0 25px;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  position: relative;
  z-index: 1;
  color: rgba(0, 0, 0, 0.35);
}

.separator-line .sep-icon:after {
  content: "";
  height: 2px;
  width: 440%;
  top: 50%;
  left: -440%;
  position: absolute;
  margin-top: -1px;
  background-color: #dadada;
  background-color: rgba(0, 0, 0, 0.15);
  display: inline-block;
}

.separator-line .sep-icon:before {
  content: "";
  height: 2px;
  width: 440%;
  top: 50%;
  right: -440%;
  position: absolute;
  margin-top: -1px;
  background-color: #dadada;
  background-color: rgba(0, 0, 0, 0.15);
  display: inline-block;
}

.sidebar > .widget:last-child, .widget > .bs-listing {
  margin-bottom: 0;
}

.widget ol, .widget ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.widget li {
  margin-bottom: 7px;
}

.widget li:last-child {
  margin-bottom: 0;
}

.widget li > ul {
  margin-top: 10px;
}

.widget img {
  max-width: 100%;
  height: auto;
}

.widget.widget_text img {
  margin: 10px 0 15px 0;
}

.widget select {
  width: 100%;
}

.sidebar-column .widget > .bs-listing:last-child {
  margin-bottom: 0;
}

.widget > .better-weather {
  margin-bottom: 0;
}

.widget.widget_archive ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.widget.widget_archive ul li a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f187";
  margin-right: 10px;
}

.widget.widget_archive ul li a {
  font-size: 14px;
}

.widget.widget_archive ul li {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.widget.widget_archive ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.widget.widget_archive .post-count {
  float: right;
  color: #444;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  background: #eee;
  text-align: center;
}

.widget.widget_calendar {
  font-size: 13px;
}

.widget.widget_calendar table {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.widget.widget_calendar table td, .widget.widget_calendar table th {
  text-align: center;
  padding: 5px 0;
}

.widget.widget_calendar table td.active-day {
  background-color: #444;
  background-color: rgba(0, 0, 0, 0.71);
}

.widget.widget_calendar table td.active-day:hover {
  background-color: #444;
  background-color: rgba(0, 0, 0, 0.91);
}

.widget.widget_calendar table td, .widget.widget_calendar table td:hover {
  background-color: #dcdcdc;
  background-color: rgba(0, 0, 0, 0.08);
  color: #888;
  color: rgba(0, 0, 0, 0.51);
}

.widget.widget_calendar table td.pad, .widget.widget_calendar table td.pad:hover {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.03);
}

.widget.widget_calendar table td a {
  color: #fff;
}

.widget.widget_calendar table caption {
  padding: 20px 5px;
  text-align: center;
  background-color: #444;
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 18px;
  color: #fff;
  margin-bottom: 2px;
}

.widget.widget_calendar table tfoot td, .widget.widget_calendar table th {
  background-color: #444;
  background-color: rgba(0, 0, 0, 0.71);
  color: #fff;
}

.widget.widget_calendar table tfoot td:hover {
  background-color: #dedede;
}

.widget.widget_categories ul {
  list-style: none;
  padding-left: 0;
}

.widget.widget_categories ul:last-child {
  margin-bottom: 0;
}

.widget.widget_categories ul li {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.widget.widget_categories ul li a {
  text-transform: capitalize;
  font-size: 14px;
}

.widget.widget_categories ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget.widget_categories ul li a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f07c";
  margin-right: 10px;
}

.widget.widget_categories .children a:before {
  content: "\f115";
}

.widget.widget_categories .post-count {
  float: right;
  color: #444;
  min-width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  padding: 0 4px;
  background: #eee;
  background: rgba(0, 0, 0, 0.08);
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.widget.widget_categories ul.children {
  border-top: 1px solid #f5f5f5;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 5px;
  padding-top: 5px;
  padding-left: 25px;
}

.widget.widget_categories ul.children li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget.widget_nav_menu .bf-icon {
  margin-left: 5px;
  margin-right: 7px;
}

.widget.widget_nav_menu ul.menu li.menu-have-icon.menu-title-hide > a > .bf-icon {
  margin-right: 5px;
}

.widget.widget_nav_menu ul.menu {
  font-size: 16px;
}

.widget.widget_nav_menu ul, .widget.widget_nav_menu ul ul {
  list-style: none;
  margin-top: 0;
  padding: 0;
}

.widget.widget_nav_menu ul.menu > li .sub-menu {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: auto;
  height: auto;
  background: 0 0;
}

.widget.widget_nav_menu ul.menu > li .sub-menu .sub-menu {
  left: 0;
}

.widget.widget_nav_menu ul.menu li {
  display: block;
  float: none;
  margin-bottom: 2px;
  padding: 0;
}

.widget.widget_nav_menu ul.menu li:before {
  content: "";
  display: none;
}

.widget.widget_nav_menu ul.menu li.menu-title-hide span.hidden {
  display: inline !important;
  visibility: visible !important;
}

.widget.widget_nav_menu ul.menu li a {
  line-height: 24px;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #dedede;
  display: block;
  position: relative;
  width: 100%;
}

.widget.widget_nav_menu ul.menu li a .description {
  display: none;
}

.widget.widget_nav_menu ul.menu li > a:hover {
  color: #fff !important;
  border-color: transparent !important;
}

.widget.widget_nav_menu ul.menu li a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0da";
  margin-left: 7px;
  margin-right: 7px;
}

.widget.widget_nav_menu ul.menu ul li a:before {
  content: "\f105";
}

.widget.widget_nav_menu ul.menu li .sub-menu li.menu-have-icon > a:before, .widget.widget_nav_menu ul.menu li.menu-have-icon a:before {
  display: none;
}

.widget.widget_nav_menu ul.menu ul li:first-child {
  margin-top: 2px;
}

.widget.widget_nav_menu ul.menu ul li {
  margin-left: 15px;
}

.widget.widget_nav_menu ul.menu ul ul li {
  margin-left: 15px;
}

.widget.widget_nav_menu ul.menu .sub-menu > li:last-child a {
  border-bottom: 1px solid #dedede !important;
}

.widget.widget_nav_menu ul.menu .sub-menu > li:last-child a:hover {
  border-bottom-color: transparent !important;
}

.widget.widget_nav_menu ul.menu li .sub-menu > li > a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0da";
}

.widget.widget_nav_menu .menu .better-custom-badge {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  color: #fff !important;
  font-family: "Open Sans";
  font-size: 10px;
  padding: 0 5px;
  margin-left: 13px;
  font-weight: 700;
  z-index: 1;
  text-transform: uppercase;
  text-align: center;
  min-width: 19px;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 8px;
}

.widget.widget_nav_menu .menu .better-custom-badge:after {
  border: solid 4px transparent;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: -8px;
  margin-top: -4px;
}

.ltr .widget.widget_nav_menu .menu .better-custom-badge:after {
  border-left-color: transparent !important;
}

.widget.widget_nav_menu ul.menu li.current-menu-item > a {
  border-color: transparent;
  color: #fff;
}

.widget.widget_nav_menu ul.menu > li > .mega-menu {
  display: none;
}

.widget.widget_nav_menu ul.menu li.menu-item-has-children > a:after {
  display: none;
}

.widget.widget_nav_menu ul.menu .mega-menu, .widget.widget_nav_menu ul.menu .sub-menu {
  display: block;
  visibility: visible;
  opacity: 1 !important;
  transform: none !important;
  transition: none !important;
  animation-name: none !important;
  position: relative;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  background: 0 0 !important;
  width: 100%;
}

.widget.widget_pages ul {
  list-style: none;
  padding-left: 0;
}

.widget.widget_pages ul li {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.widget.widget_pages ul li a {
  font-size: 13px;
}

.widget.widget_pages ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget.widget_pages ul li a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f15b";
  margin-right: 10px;
}

.widget.widget_recent_comments a {
  border-bottom: 1px solid #e7e7e7;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.widget.widget_recent_comments ul {
  list-style: none;
  padding-left: 0;
}

.widget.widget_recent_comments ul li {
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.widget.widget_recent_comments ul li a {
  font-size: 13px;
}

.widget.widget_recent_comments ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget.widget_recent_comments ul li:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f075";
  margin-right: 10px;
}

.widget.widget_recent_entries ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.widget.widget_recent_entries ul li {
  border-bottom: 1px solid #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.widget.widget_recent_entries ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget.widget_recent_entries li a {
  font-size: 14px;
}

.widget.widget_recent_entries li .post-date {
  font-style: italic;
  display: block;
  font-size: 12px;
}

.widget.widget_rss li .rsswidget {
  font-size: 14px;
}

.widget.widget_rss li .rss-date {
  display: block;
  font-style: italic;
}

.widget.widget_rss li .rssSummary {
  font-size: 13px;
}

.search-header .search-form, .widget.widget_display_search form[role=search], .widget.widget_product_search .woocommerce-product-search, .widget.widget_search .search-form {
  position: relative;
}

.search-header .search-form .search-field, .widget.widget_display_search form[role=search] input#bbp_search, .widget.widget_product_search .search-field, .widget.widget_search .search-form input[type=search] {
  width: 100%;
  padding-right: 20%;
  margin: 0;
  line-height: 36px;
  height: 36px;
}

.search-header .search-form .search-submit, .widget.widget_display_search form[role=search] input#bbp_search_submit, .widget.widget_product_search input[type=submit], .widget.widget_search .search-form .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 36px;
  height: 36px;
  padding: 0 16px;
}

.widget.widget_tag_cloud .tagcloud a {
  display: inline-block;
  line-height: 16px;
  color: #444;
  background-color: #fff;
  border: 1px solid #dedede;
  margin: 0 6px 6px 0;
  padding: 5px 7px;
  font-size: 12px !important;
  font-weight: 400;
}

.widget.widget_tag_cloud .tagcloud a:hover {
  color: #fff;
  border-color: transparent;
}

.bs-social-share .social-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bs-social-share span.social-item {
  float: left;
  margin-right: 6px;
  padding: 0;
}

.bs-social-share span.social-item a {
  font-family: "Open Sans", sans-serif;
  border-radius: 2px;
}

.bs-social-share span.social-item.bbm, .bs-social-share span.social-item.line, .bs-social-share span.social-item.whatsapp {
  display: none;
}

.widget_bs-theme-social-share .bs-social-share span.social-item {
  float: none;
  display: inline-block;
}

@media only screen and (max-width: 780px) {
  .bs-social-share span.social-item.bbm, .bs-social-share span.social-item.line, .bs-social-share span.social-item.whatsapp {
    display: block;
  }
}
.bs-social-share.style-button span.social-item a {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  padding: 0 13px;
  background-color: #8d8d8d;
  display: block;
  color: #fff !important;
  margin-bottom: 7px;
}

.bs-social-share.style-button span.social-item a:hover {
  background-color: #7c7c7c;
}

.bs-social-share.style-button span.social-item a span {
  margin-left: 7px;
}

.bs-social-share.style-button span.social-item a span:last-child:first-child {
  margin-right: 0;
  margin-left: 0;
}

.bs-social-share.style-button.no-title-style span.social-item a {
  width: 40px;
  text-align: center;
  padding: 0;
}

.bs-social-share.style-button.no-title-style span.social-item a .fa {
  margin-right: 0;
}

.bs-social-share.style-button.colored span.social-item.facebook a {
  background-color: #4a639f;
}

.bs-social-share.style-button.colored span.social-item.facebook a:hover {
  background-color: #5271b3;
}

.bs-social-share.style-button.colored span.social-item.twitter a {
  background-color: #59c8ff;
}

.bs-social-share.style-button.colored span.social-item.twitter a:hover {
  background-color: #58d1ff;
}

.bs-social-share.style-button.colored span.social-item.google_plus a {
  background-color: #d73d32;
}

.bs-social-share.style-button.colored span.social-item.google_plus a:hover {
  background-color: #e64035;
}

.bs-social-share.style-button.colored span.social-item.pinterest a {
  background-color: #cb2027;
}

.bs-social-share.style-button.colored span.social-item.pinterest a:hover {
  background-color: #e32229;
}

.bs-social-share.style-button.colored span.social-item.linkedin a {
  background-color: #0266a0;
}

.bs-social-share.style-button.colored span.social-item.linkedin a:hover {
  background-color: #027ec1;
}

.bs-social-share.style-button.colored span.social-item.tumblr a {
  background-color: #3e5a70;
}

.bs-social-share.style-button.colored span.social-item.tumblr a:hover {
  background-color: #426283;
}

.bs-social-share.style-button.colored span.social-item.email a {
  background-color: #0c151c;
}

.bs-social-share.style-button.colored span.social-item.email a:hover {
  background-color: #1a2a39;
}

.bs-social-share.style-button.colored span.social-item.telegram a {
  background-color: #179cde;
}

.bs-social-share.style-button.colored span.social-item.telegram a:hover {
  background-color: #0f8dde;
}

.bs-social-share.style-button.colored span.social-item.whatsapp a {
  background-color: #00e676;
}

.bs-social-share.style-button.colored span.social-item.whatsapp a:hover {
  background-color: #00ca65;
}

.bs-social-share.style-button.colored span.social-item.reddit a {
  background-color: #ff4500;
}

.bs-social-share.style-button.colored span.social-item.reddit a:hover {
  background-color: #dc3c01;
}

.bs-social-share.style-button.colored span.social-item.stumbleupon a {
  background-color: #ee4813;
}

.bs-social-share.style-button.colored span.social-item.stumbleupon a:hover {
  background-color: #ce3d0f;
}

.bs-social-share.style-button.colored span.social-item.vk a {
  background-color: #4c75a3;
}

.bs-social-share.style-button.colored span.social-item.vk a:hover {
  background-color: #3e5f84;
}

.bs-social-share.style-button.colored span.social-item.digg a {
  background-color: #000;
}

.bs-social-share.style-button.colored span.social-item.digg a:hover {
  background-color: #2f2f2f;
}

.bs-social-share.style-button.colored span.social-item.line a {
  background-color: #00b900;
}

.bs-social-share.style-button.colored span.social-item.line a:hover {
  background-color: #00b900;
}

.bs-social-share.style-button.colored span.social-item.line .fa.fa-line-it {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAxlBMVEX///8AuwAAtwAAvQD9//1O0kx23Xaa5Zly3HEryij7/vvw+/Ds+uzZ9tnR9NGM4YyK4IpJ0Ug6zDgdxhn3/ffl+OTX9te/77657bix67Gp6amk6KOC34Fr2mph1mBd1VxCzkBB0D8DwQD0/fTo+eff9t/K8srD8MO77rqu6q6d5ZyR45GH4IZ+331u2m1U1FI1yzIwyi0vyiwiyB4NxQMAvwDt+u3e9t7M8sy37LaT4pKP4Y963Xk+zTwmyCIYxBITwwsAtABqI5GFAAABAklEQVQY022RV5KDMBBEm5FENsE4kW3WOYf15nj/S60KKFym9v3paWY+uqH8S62poda1/Rbq+1uSn1j1gVJ2188osV4zRpUmtn1CAx+dpIe0Ee7ofJICUjSg5QMCiR7arOWRFXg8RC9aagPEQy3dd2B94acD09BhT/THSV/sg8vBATIcbZjHHSxfNVgq1LNQB0ACow+zq5pTX5fz1+QsPqRWcX0B7xa+J1JjsWG7i5E74D7IBZbudrSYh3YvdELXm8EpQMEMLXhGUOjw0NIaSa1QPr2zsQwLZYCr2wLXGNV5kzL2hn0Oia3L160GYkEEYD5uaqigXxeWVzSlNWxG9ajkD+0mEhtxMt1EAAAAAElFTkSuQmCC);
  display: inline-block;
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-top: -6px;
  margin-left: -5px;
}

.bs-social-share.style-button.colored span.social-item.bbm a {
  background-color: #1f1f1f;
}

.bs-social-share.style-button.colored span.social-item.bbm a:hover {
  background-color: #3c3c3c;
}

.bs-social-share.style-button.colored span.social-item.bbm .fa.fa-bbm {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAkFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+WABnwAAAAL3RSTlMABezkG/XZhyYiCgOqO/Hb0sO+t7SejnluXVdAMh0SDuHe1UtHLSncyK6Zk4JyadjoMwwAAADLSURBVBjTtZBZrsIwDEVvmjRDWzrTuWXmjcD+d0cSWUjwicT5OfJV7MjGR0k45/kV6K2z34XSDb9ZZIz1zXGkeOWrGputc3qiuP+SMv/TOOdS7pMjWKeU6hEzxmLAm1mL0HVhEUJEACLr5TEUVRAE+wNwsd62DK1LQ6ROBVD6VxOGpmn+R/gqwexHBgaE7d2tNUZrXo+AnqZTBJy1Fu5HY8xsPe/CMP1e4YWBhhJtVdXKgE7QPV2mZFBFUf507OkyWQTamThwSzbgPe52ABZlYOfWsgAAAABJRU5ErkJggg==);
  display: inline-block;
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-top: -6px;
  margin-left: -3px;
}

.bs-social-share.style-button.colored span.social-item.viber a {
  background-color: #5d54a4;
}

.bs-social-share.style-button.colored span.social-item.viber .fa.fa-viber {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAAtFBMVEVmXaxlW6taTqVeVKhjWadcUKb///9iWKZhVqnu7fZWS6P8/P5pX65iWKphWKSQisR9dLlrYq9YTaO2sdhyabNTR6Hw7/fm5fKsp9Kjnc6gmsyclsqIgb+BeLt5cLZ1bLNuZLD5+fzq6fTAu927t9uoo9CWkMf39vvRzufOy+Wwq9SalMlwZrFcUaP08/nf3e7IxOHFweCxrNSNhsKEfb3i4fDLx+O4s9mzrtZOQp/W1OnV0+nSxjEiAAABXUlEQVQY0z2Qh5LjIAxABQoEAz737tiOW3rPtrv//6/D8e6+GTTMk0bSCGYEo5RR85DAL8i8Mvvs+2BwamQ/CRbuk/XZMpzX8e2J9mxXCX8f3bABr3oEscpf9cJ7U478kghuSCU97njJjKYFz78gW9EwS4sG/WbzTxAgeLWA9fzS4GPYp0dBO1Uh2JC8S6/l/C7LILvntn/gDgNxtAIfA74pmFd8pEMoS1VQwDpOKdabFgRq55C5crXOvzXQT+5EgJKYbV5ahNbeRNjGz+joaEpYqQ4UbJ10FIB5F/Wx5dvKpuM0kuDuzbYBsOm5YYey5y4C0Ny0JcazcqfWRaTjCzFlomkt17c1ERKfbqRvfGTzqSwVeEJorb3V3uLpdCoChFbX1o2GJGnPavP3gGKSBoZD9dh2t+4+VoIuCJn1ArOgq6OT7/un5WICXvE0Xmt/sZzV0gDLiT8mM3+++Q/56h8tpySg5AAAAABJRU5ErkJggg==);
  display: inline-block;
  height: 22px;
  width: 22px;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-top: -4px;
  margin-left: -5px;
}

.bs-social-share.style-outline-button span.social-item a {
  height: 32px;
  line-height: 28px;
  font-size: 13px;
  padding: 0 13px;
  display: block;
  color: #5e5e5e;
  border: 2px solid #a1a1a1;
  margin-bottom: 7px;
}

.bs-social-share.style-outline-button span.social-item a .fa {
  margin-right: 5px;
}

.bs-social-share.style-outline-button.no-title-style span.social-item a {
  width: 40px;
  text-align: center;
}

.bs-social-share.style-outline-button.no-title-style span.social-item a .fa {
  margin-right: 0;
}

.bs-social-share.style-outline-button.colored span.social-item.facebook a {
  border-color: #4a639f;
  color: #4a639f;
}

.bs-social-share.style-outline-button.colored span.social-item.facebook a:hover {
  border-color: #5271b3;
  color: #5271b3;
}

.bs-social-share.style-outline-button.colored span.social-item.twitter a {
  border-color: #59c8ff;
  color: #59c8ff;
}

.bs-social-share.style-outline-button.colored span.social-item.twitter a:hover {
  border-color: #58d1ff;
  color: #58d1ff;
}

.bs-social-share.style-outline-button.colored span.social-item.google_plus a {
  border-color: #d73d32;
  color: #d73d32;
}

.bs-social-share.style-outline-button.colored span.social-item.google_plus a:hover {
  border-color: #e64035;
  color: #e64035;
}

.bs-social-share.style-outline-button.colored span.social-item.pinterest a {
  border-color: #cb2027;
  color: #cb2027;
}

.bs-social-share.style-outline-button.colored span.social-item.pinterest a:hover {
  border-color: #e32229;
  color: #e32229;
}

.bs-social-share.style-outline-button.colored span.social-item.linkedin a {
  border-color: #0266a0;
  color: #0266a0;
}

.bs-social-share.style-outline-button.colored span.social-item.linkedin a:hover {
  border-color: #027ec1;
  color: #027ec1;
}

.bs-social-share.style-outline-button.colored span.social-item.tumblr a {
  border-color: #3e5a70;
  color: #3e5a70;
}

.bs-social-share.style-outline-button.colored span.social-item.tumblr a:hover {
  border-color: #426283;
  color: #426283;
}

.bs-social-share.style-outline-button.colored span.social-item.email a {
  border-color: #0c151c;
  color: #0c151c;
}

.bs-social-share.style-outline-button.colored span.social-item.email a:hover {
  border-color: #1a2a39;
  color: #1a2a39;
}

.bs-social-share.style-outline-button.colored span.social-item.telegram a {
  border-color: #179cde;
  color: #179cde;
}

.bs-social-share.style-outline-button.colored span.social-item.telegram a:hover {
  border-color: #179cde;
  color: #179cde;
}

.bs-social-share.style-outline-button.colored span.social-item.whatsapp a {
  border-color: #00e676;
  color: #00e676;
}

.bs-social-share.style-outline-button.colored span.social-item.whatsapp a:hover {
  border-color: #00ca65;
  color: #00ca65;
}

.bs-about .about-title {
  text-align: center;
  margin: 0 0 15px;
}

.bs-about .about-text {
  text-align: center;
}

.bs-about .about-link {
  text-align: center;
  margin-top: 10px;
}

.bs-about .about-link a {
  font-weight: 700;
  font-size: 14px;
}

.bs-about .about-icons-list {
  text-align: center;
  margin: 17px 0 0;
  padding: 0;
}

.bs-about .about-icons-list .about-icon-item {
  display: inline-block;
  margin: 0 5px 5px 0;
}

.bs-about .about-icons-list .about-icon-item a {
  width: 28px;
  height: 28px;
  display: inline-block;
  color: #333;
  -webkit-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.22);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.22);
  text-align: center;
  line-height: 28px;
  font-size: 16px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.bs-about .about-icons-list .about-icon-item a:hover {
  color: #fff;
  box-shadow: none;
}

.bs-about .about-icons-list .about-icon-item a .bsfi, .bs-about .about-icons-list .about-icon-item a .fa {
  vertical-align: top;
  line-height: 28px;
}

.bs-about .about-icons-list .about-icon-item.facebook a:hover {
  background-color: #4a639f;
}

.bs-about .about-icons-list .about-icon-item.twitter a:hover {
  background-color: #59c8ff;
}

.bs-about .about-icons-list .about-icon-item.google-plus a:hover {
  background-color: #d73d32;
}

.bs-about .about-icons-list .about-icon-item.instagram a:hover {
  background: #f77638;
  background: -webkit-radial-gradient(circle farthest-corner at 35% 90%, #fec564, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(circle farthest-corner at 0 140%, #fec564, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, rgba(0, 0, 0, 0) 50%), -webkit-radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, rgba(0, 0, 0, 0)), -webkit-linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, rgba(0, 0, 0, 0) 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, rgba(0, 0, 0, 0)), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.bs-about .about-icons-list .about-icon-item.youtube a:hover {
  background-color: #cc181e;
}

.bs-about .about-icons-list .about-icon-item.youtube a:hover {
  background-color: #cc181e;
}

.bs-about .about-icons-list .about-icon-item.dribbble a:hover {
  background-color: #ea4c89;
}

.bs-about .about-icons-list .about-icon-item.vimeo a:hover {
  background-color: #46a3e9;
}

.bs-about .about-icons-list .about-icon-item.behance a:hover {
  background-color: #3b63fc;
}

.bs-about .about-icons-list .about-icon-item.pinterest a:hover {
  background-color: #cb2027;
}

.bs-about .about-icons-list .about-icon-item.github a:hover {
  background-color: #4c4c4c;
}

.bs-about .about-icons-list .about-icon-item.email a:hover {
  background-color: #333;
}

.bs-about .about-icons-list .about-icon-item.telegram a:hover {
  background-color: #179cde;
}

.bs-about .about-icons-list .about-icon-item.vk a:hover {
  background-color: #4e729a;
}

.bs-popular-categories .bs-popular-terms-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-column-secondary .bs-popular-categories .bs-popular-terms-list, .vc_col-sm-2 .bs-popular-categories .bs-popular-terms-list {
  padding: 0;
}

.bs-popular-categories .bs-popular-term-item {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  font-size: 13px;
  line-height: 24px;
}

.bs-popular-categories .bs-popular-term-item .term-count, .bs-popular-categories .bs-popular-term-item a {
  color: #333;
}

.bs-popular-categories .bs-popular-term-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.bs-popular-categories .bs-popular-term-item .term-count {
  float: right;
  display: inline-block;
  background: rgba(0, 0, 0, 0.08);
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 0 6px;
}

.bs-popular-categories .bs-popular-term-item:hover .term-count {
  color: #fff;
}

.bs-popular-categories .bs-popular-term-item:hover a {
  color: inherit;
}

.widget.widget_bs-subscribe-newsletter {
  background: #efefef;
  padding: 27px 23px !important;
}

.bs-subscribe-newsletter .subscribe-image {
  margin-bottom: 15px;
  text-align: center;
  max-width: 312px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
}

.bs-subscribe-newsletter .subscribe-message {
  text-align: center;
  max-width: 312px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.bs-subscribe-newsletter .subscribe-message p:last-child {
  margin-bottom: 0;
}

.bs-subscribe-newsletter form {
  width: 100%;
  position: relative;
  max-width: 312px;
  margin-left: auto;
  margin-right: auto;
}

.bs-subscribe-newsletter .newsletter-email {
  color: #5f6569;
  width: 100%;
  border-color: #cbd1d8;
  height: 34px;
  line-height: 34px;
  border-radius: 33px;
  padding: 0 12px;
  font-size: 12px;
}

.bs-subscribe-newsletter .newsletter-subscribe {
  margin-top: 0;
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
  height: 34px;
  line-height: 34px;
  border-radius: 0 33px 33px 0;
  text-transform: capitalize !important;
  cursor: pointer;
  font-size: 13px;
  padding: 0 13px;
}

.bs-subscribe-newsletter .newsletter-subscribe .fa {
  margin-right: 3px;
}

.bs-subscribe-newsletter .powered-by {
  font-size: 12px;
  margin: 5px auto 0;
  max-width: 311px;
  color: #b7b7b7;
  text-align: center;
  opacity: 0.6;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  vertical-align: middle;
}

.bs-subscribe-newsletter .powered-by:hover {
  opacity: 1;
}

.bs-flickr .bs-flickr-photo-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.bs-flickr .bs-flickr-photo-list.columns-2 {
  margin: 0;
}

.bs-flickr .bs-flickr-photo-list.columns-2 li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
  text-align: center;
}

.bs-flickr .bs-flickr-photo-list.columns-2 li img {
  width: 100%;
}

.bs-flickr .bs-flickr-photo-list.columns-2 li:nth-child(odd) {
  padding-right: 5px;
}

.bs-flickr .bs-flickr-photo-list.columns-2 li:nth-child(even) {
  padding-left: 5px;
}

.bs-flickr .bs-flickr-photo-list.columns-2 li:nth-child(n+3) {
  clear: right;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li {
  float: left;
  width: 33.333%;
  margin-bottom: 8px;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(1) {
  width: 66.66666%;
  padding-right: 3px !important;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(1) img {
  width: 100%;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(2) {
  padding: 0 0 0 6px !important;
  margin-bottom: 10px;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(3) {
  padding: 0 0 0 6px !important;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li img {
  max-width: 100%;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(3n-1) {
  padding: 0 3px;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(3n-2) {
  padding-right: 6px;
  clear: left;
}

.bs-flickr .bs-flickr-photo-list.columns-3 li:nth-child(3n+3) {
  padding-left: 6px;
}

.bs-flickr .bs-flickr-photo-list.list-photos .bs-flickr-photo {
  float: left;
  width: 12.5%;
}

.bs-flickr .bs-flickr-photo-list.list-photos .bs-flickr-photo img {
  max-width: 100%;
}

.bs-flickr .bs-flickr-photo-list.list-photos {
  margin: 0;
  padding: 0;
}

.bs-flickr-photo .img-holder {
  width: 100%;
}

.bs-flickr-photo .img-holder:before {
  padding-top: 100%;
}

.bs-dribbble .bs-dribbble-shot-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.bs-dribbble .bs-dribbble-shot-list li:before {
  display: none;
}

.bs-dribbble .bs-dribbble-shot-list li {
  padding: 0;
  margin-bottom: 10px;
  text-align: center;
}

.bs-dribbble .bs-dribbble-shot-list.columns-2 li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
}

.bs-dribbble .bs-dribbble-shot-list.columns-2 li:nth-child(odd) {
  padding-right: 5px;
}

.bs-dribbble .bs-dribbble-shot-list.columns-2 li:nth-child(even) {
  padding-left: 5px;
}

.bs-dribbble .bs-dribbble-shot-list.columns-2 li:nth-child(n+3) {
  clear: right;
}

.bs-dribbble .bs-dribbble-shot-list.columns-3 li {
  float: left;
  width: 33.333%;
}

.bs-dribbble .bs-dribbble-shot-list.columns-3 li:nth-child(3n-1) {
  padding: 0 3px;
}

.bs-dribbble .bs-dribbble-shot-list.columns-3 li:nth-child(3n-2) {
  padding-right: 6px;
  clear: left;
}

.bs-dribbble .bs-dribbble-shot-list.columns-3 li:nth-child(3n+3) {
  padding-left: 6px;
}

.dribbble-shot .img-holder {
  width: 100%;
}

.bs-google-plus > div {
  margin-right: auto !important;
  margin-left: auto !important;
  display: block !important;
}

.bs-embed .bs-embed-item iframe {
  max-width: 100%;
}

.bs-embed .bs-embed-item {
  margin-bottom: 15px;
}

.bs-embed .bs-embed-item:last-child {
  margin-bottom: 0;
}

.bs-instagram .bs-instagram-photo-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.bs-instagram-photo {
  position: relative;
  z-index: 1;
  width: 100%;
}

.bs-instagram-photo a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  float: none;
  margin: 0;
}

.bs-instagram-photo a, .bs-instagram-photo a:hover {
  color: #fff;
}

.bs-instagram-photo a:before {
  padding-top: 100%;
}

.bs-instagram-photo .img-holder {
  background-color: #e6e6e6;
}

.bs-instagram-photo .img-holder.b-loaded, body.bs-ll-d .bs-instagram-photo .img-holder {
  background-size: cover;
  background-color: transparent;
}

.bs-instagram-photo a .the-hover:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.35);
  display: block;
  -webkit-transition: all 0.45s ease;
  -moz-transition: all 0.45s ease;
  -o-transition: all 0.45s ease;
  transition: all 0.45s ease;
  opacity: 0;
}

.bs-instagram-photo a .the-hover:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f16d";
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  left: 50%;
  margin-left: -20px;
  font-size: 30px;
  opacity: 0;
  -webkit-transition: all 0.45s ease;
  -moz-transition: all 0.45s ease;
  -o-transition: all 0.45s ease;
  transition: all 0.45s ease;
}

.bs-instagram-photo:hover a .the-hover:after, .bs-instagram-photo:hover a .the-hover:before {
  opacity: 1;
}

.bs-instagram .bs-instagram-photo-list li:before {
  display: none;
}

.bs-dribbble .bs-instagram-photo-list li {
  padding: 0;
  margin-bottom: 8px;
  text-align: center;
}

.bs-dribbble .bs-instagram-photo-list li br {
  display: none;
}

.bs-instagram .bs-instagram-photo-list .bs-instagram-photo img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.bs-instagram .bs-instagram-photo-list.columns-2 li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
  max-height: 155px;
  overflow: hidden;
}

.bs-instagram .bs-instagram-photo-list.columns-2 li:nth-child(odd) {
  padding-right: 5px;
}

.bs-instagram .bs-instagram-photo-list.columns-2 li:nth-child(even) {
  padding-left: 5px;
}

.bs-instagram .bs-instagram-photo-list.columns-2 li:nth-child(n+3) {
  clear: right;
}

.bs-instagram .bs-instagram-photo-list.columns-2-1 li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
  max-height: 155px;
  overflow: hidden;
}

.bs-instagram .bs-instagram-photo-list.columns-2-1 li:first-child {
  width: 100%;
  max-height: inherit;
  float: none;
  padding: 0 !important;
}

.bs-instagram .bs-instagram-photo-list.columns-2-1 li:nth-child(even) {
  padding-right: 5px;
}

.bs-instagram .bs-instagram-photo-list.columns-2-1 li:nth-child(odd) {
  padding-left: 5px;
}

.bs-instagram .bs-instagram-photo-list.columns-2-1 li:nth-child(n+3) {
  clear: right;
}

.bs-instagram .bs-instagram-photo-list.columns-3 li {
  float: left;
  width: 33.333%;
  overflow: hidden;
  margin-bottom: 10px;
}

.bs-instagram .bs-instagram-photo-list.columns-3 li:nth-child(3n-1) {
  padding: 0 3px;
}

.bs-instagram .bs-instagram-photo-list.columns-3 li:nth-child(3n-2) {
  padding-right: 6px;
  clear: left;
}

.bs-instagram .bs-instagram-photo-list.columns-3 li:nth-child(3n+3) {
  padding-left: 6px;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li {
  float: left;
  width: 33.333%;
  margin-bottom: 8px;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(1) {
  width: 66.66666%;
  padding-right: 3px !important;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(1) img {
  width: 100%;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(2) {
  padding: 0 0 0 6px !important;
  margin-bottom: 10px;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(3) {
  padding: 0 0 0 6px !important;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li img {
  max-width: 100%;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(3n-1) {
  padding: 0 3px;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(3n-2) {
  padding-right: 6px;
  clear: left;
}

.bs-instagram .bs-instagram-photo-list.columns-3-1 li:nth-child(3n+3) {
  padding-left: 6px;
}

.bs-instagram .bs-instagram-photo-list.list-photos .bs-instagram-photo {
  float: left;
  width: 12.5%;
}

.bs-instagram .bs-instagram-photo-list.list-photos .bs-instagram-photo img {
  max-width: 100%;
}

.bs-instagram .bs-instagram-photo-list.list-photos .bs-instagram-photo-list {
  margin: 0;
  padding: 0;
}

.bs-instagram .better-direction-nav .better-next, .bs-instagram .better-direction-nav .better-prev {
  margin-top: -26px;
}

.better-newsticker {
  min-height: 24px;
  max-height: 24px;
  overflow: hidden;
  position: relative;
  margin: 0 0 20px 0;
}

.better-newsticker .heading {
  display: inline-block;
  width: 90px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 24px;
  font-size: 12px;
  padding: 0;
  text-transform: uppercase !important;
  margin: 0;
  color: #fff;
  text-align: center;
  border-radius: 3px;
}

.better-newsticker .heading:after {
  content: "";
  display: inline-block;
  border-top: 6px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 6px solid transparent;
  position: absolute;
  right: -7px;
  top: 50%;
  margin-top: -6px;
}

.better-newsticker ul.news-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.better-newsticker ul.news-list li {
  margin: 0;
  padding: 0 15px 0 0;
  height: 24px;
  line-height: 24px;
  font-size: 13px;
  width: 65%;
  -webkit-transition: all 0.45s ease;
  -moz-transition: all 0.45s ease;
  -o-transition: all 0.45s ease;
  transition: all 0.45s ease;
  z-index: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.better-newsticker ul.news-list li.active {
  margin: 0;
  z-index: 1;
  opacity: 1;
  left: 110px;
}

.better-newsticker ul.news-list li a {
  color: #696969;
}

.better-newsticker ul.news-list li a:hover {
  color: #0080ce;
}

.better-newsticker .control-nav {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  line-height: 24px;
  direction: ltr;
}

.better-newsticker .control-nav span {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border: 1px solid #eaeaea;
  color: #ccc;
  background: #fff;
  -webkit-transition: all 0.45s ease;
  -moz-transition: all 0.45s ease;
  -o-transition: all 0.45s ease;
  transition: all 0.45s ease;
  cursor: pointer;
  vertical-align: top;
  border-radius: 3px;
}

.better-newsticker .control-nav span:hover {
  border-color: transparent;
  color: #fff;
}

.better-newsticker .control-nav span.prev {
  margin-right: 6px;
}

.newsticker-container {
  margin: 0 0 20px;
}

.layout-1-col .newsticker-container {
  padding-right: 0;
  padding-left: 0;
}

.better-newsticker.header-ticker {
  margin: 0;
}

.wpb_wrapper .better-newsticker {
  margin-left: 20px;
  margin-right: 20px;
}

.entry-content > .vc_row:first-child > .wpb_column > .vc_column-inner > .wpb_wrapper > .better-newsticker {
  margin-top: -5px;
}

.better-newsticker .control-nav span .fa {
  line-height: 22px;
  position: relative;
}

.better-social-counter.style-big-button .social-item, .better-social-counter.style-box .item-icon, .better-social-counter.style-button .item-icon, .better-social-counter.style-clean .item-icon, .better-social-counter.style-modern .item-icon, .better-social-counter.style-style-6 .item-icon {
  background-color: #434343;
}

.better-social-counter.style-big-button .item-icon, .better-social-counter.style-box .item-count, .better-social-counter.style-box .item-title {
  background-color: #313131;
}

.widget_better-social-counter .better-social-counter.style-button .social-list {
  text-align: center;
}

.widget_better-social-counter .better-social-counter.style-button .social-item {
  display: inline-block;
  float: none;
}

.widget_better-social-counter .better-social-counter.style-style-6 .social-item {
  border-bottom: none;
}

.site-footer .better-studio-shortcode, .widget .better-studio-shortcode {
  margin-bottom: 0;
}

.section-heading {
  margin: 0 0 15px;
  font-size: 16px;
  line-height: 20px;
  position: relative;
}

.section-heading p {
  margin-bottom: 0 !important;
  display: inline-block;
}

.section-heading .h-text .bf-icon {
  margin-right: 3px;
  vertical-align: top;
  line-height: inherit;
}

.section-heading .h-text, .section-heading:after {
  display: inline-block;
  -webkit-transition: 0.35s cubic-bezier(0.39, 0.58, 0.57, 1) 0s;
  -moz-transition: 0.35s cubic-bezier(0.39, 0.58, 0.57, 1) 0s;
  -o-transition: 0.35s cubic-bezier(0.39, 0.58, 0.57, 1) 0s;
  transition: 0.35s cubic-bezier(0.39, 0.58, 0.57, 1) 0s;
}

.section-heading:after {
  background: rgba(0, 0, 0, 0.08);
  content: "";
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.section-heading .h-text {
  position: relative;
  z-index: 1;
}

.section-heading .other-link {
  float: right;
  outline: 0;
}

.section-heading .other-link .h-text {
  margin-left: 0;
  color: #484848;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 82%;
  padding: 4px 0 4px 14px;
}

.section-heading.sh-t1:after {
  top: 50%;
  left: 0;
  height: 5px;
  margin-top: -2.5px;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
}

.section-heading.sh-t1 .h-text {
  display: inline-block;
  padding: 4px 17px 4px 0;
  color: #444;
  background: #fff;
}

.section-heading.sh-t1 .other-link .h-text {
  padding: 4px 4px 4px 17px !important;
  background: #fff;
}

.section-heading.sh-t1 .bs-pretty-tabs-container {
  background: #fff;
}

.section-heading.multi-tab.sh-t1 .bs-pretty-tabs-container {
  padding-left: 0;
}

.section-heading.sh-t1 .bs-pretty-tabs .bs-pretty-tabs-container:hover .bs-pretty-tabs-more.other-link .h-text {
  border: 1px solid #d8d8d8;
  border-bottom: none;
}

.section-heading.sh-t1 .bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;
  display: none;
}

.section-heading.sh-t1.sh-s2:after {
  opacity: 0.15;
}

.section-heading.sh-t1.sh-s3:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFNzg0NTFBNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFNzg0NTFCNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUU3ODQ1MTg2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUU3ODQ1MTk2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz461u9NAAAAEUlEQVR42mJgQAX/yeAABBgAgcID/UDOgL8AAAAASUVORK5CYII=) repeat;
  opacity: 0.2;
  height: 8px;
  margin-top: -4px;
  background-color: transparent !important;
}

.section-heading.sh-t1.sh-s4:after {
  display: none;
}

.section-heading.sh-t1.sh-s5 .h-text {
  line-height: 22px;
}

.section-heading.sh-t1.sh-s5 {
  padding-bottom: 7px;
}

.section-heading.sh-t1.sh-s5:after {
  top: inherit;
  left: 0;
  height: 1px;
  margin-top: -0.5px;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  bottom: 0;
}

.section-heading.sh-t1.sh-s5 > .h-text:last-child:first-child:after, .section-heading.sh-t1.sh-s5 > .main-link > .h-text:after, .section-heading.sh-t1.sh-s5 > a:last-child:first-child > .h-text:after {
  display: inline-block;
  margin-left: 16px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  font-weight: 900;
}

.section-heading.sh-t1.sh-s5 > .h-text:last-child:first-child, .section-heading.sh-t1.sh-s5 > .main-link:last-child:first-child > .h-text, .section-heading.sh-t1.sh-s5 > a:last-child:first-child > .h-text {
  padding-right: 15px;
  color: #444 !important;
}

.section-heading.sh-t1.sh-s6:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFNzg0NTFBNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFNzg0NTFCNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUU3ODQ1MTg2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUU3ODQ1MTk2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz461u9NAAAAEUlEQVR42mJgQAX/yeAABBgAgcID/UDOgL8AAAAASUVORK5CYII=) repeat;
  opacity: 0.2;
  height: 8px;
  background-color: transparent !important;
}

.section-heading.sh-t1.sh-s6 {
  text-align: center;
}

.section-heading.sh-t1.sh-s6.multi-tab {
  text-align: inherit;
}

.section-heading.sh-t1.sh-s6 > .h-text:last-child:first-child, .section-heading.sh-t1.sh-s6 > .main-link:last-child:first-child > .h-text, .section-heading.sh-t1.sh-s6 > a:last-child:first-child > .h-text {
  padding: 4px 17px;
}

.section-heading.sh-t1.sh-s7:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFNzg0NTFBNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFNzg0NTFCNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUU3ODQ1MTg2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUU3ODQ1MTk2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz461u9NAAAAEUlEQVR42mJgQAX/yeAABBgAgcID/UDOgL8AAAAASUVORK5CYII=) repeat;
  opacity: 0.2;
  height: 8px;
  background-color: transparent !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: initial;
  margin-top: 0;
}

.section-heading.sh-t1.sh-s7 {
  text-align: center;
  padding-bottom: 26px;
}

.section-heading.sh-t1.sh-s7.multi-tab {
  text-align: inherit;
}

.section-heading.sh-t1.sh-s7 > .h-text:last-child:first-child, .section-heading.sh-t1.sh-s7 > .main-link:last-child:first-child > .h-text, .section-heading.sh-t1.sh-s7 > a:last-child:first-child > .h-text {
  padding: 4px 0;
}

.section-heading.sh-t2 {
  line-height: 20px;
  padding: 4px 0 0;
}

.section-heading.sh-t2:after {
  top: 0;
  height: 4px;
}

.section-heading.sh-t2:before {
  content: "";
  display: inline-block;
  height: 1px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #ededed;
  background: rgba(0, 0, 0, 0.07);
}

.section-heading.sh-t2 .h-text {
  padding: 4px 0;
  line-height: 34px;
}

.section-heading.sh-t2 .other-link .h-text {
  padding: 4px 0 4px 12px;
  text-transform: capitalize;
}

.section-heading.sh-t2 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  border-color: #e6e6e6;
}

.section-heading.sh-t2.sh-t2 .bs-pretty-tabs-elements .h-text {
  line-height: 24px;
  padding: 2px 3px;
}

.section-heading.sh-t2.sh-s2 {
  background: #f5f5f5;
  background: rgba(0, 0, 0, 0.04);
  padding: 4px 15px 0;
}

.section-heading.sh-t2.sh-s2 .bs-pretty-tabs-container {
  margin-right: -15px;
}

.section-heading.sh-t2.sh-s2 .h-text {
  line-height: 38px;
  color: #444;
}

.section-heading.sh-t2.sh-s3 {
  background: #f5f5f5;
  background: rgba(0, 0, 0, 0.04);
  padding: 0 15px 0 20px;
}

.section-heading.sh-t2.sh-s4 {
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0 15px 0 20px;
}

.section-heading.sh-t2.sh-s3 .bs-pretty-tabs-container, .section-heading.sh-t2.sh-s4 .bs-pretty-tabs-container {
  margin-right: -15px;
}

.section-heading.sh-t2.sh-s3 .h-text, .section-heading.sh-t2.sh-s4 .h-text {
  line-height: 34px;
  color: #444;
}

.section-heading.sh-t2.sh-s4 .h-text {
  line-height: 32px;
}

.section-heading.sh-t2.sh-s3:after {
  top: 0;
  width: 5px;
  bottom: 0;
  height: 100%;
}

.section-heading.sh-t2.sh-s4:after {
  top: -1px;
  bottom: -1px;
  left: -1px;
  height: auto;
  width: 5px;
}

.section-heading.sh-t2.sh-s3:before, .section-heading.sh-t2.sh-s4:before {
  display: none;
}

.section-heading.sh-t3 {
  line-height: 20px;
  padding: 0 0 4px;
}

.section-heading.sh-t3:after {
  top: inherit;
  bottom: 0;
  height: 4px;
  background: #2d2d2d;
}

.section-heading.sh-t3:before {
  display: none;
}

.section-heading.sh-t3.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t3 > .h-text, .section-heading.sh-t3 > a > .h-text {
  padding: 4px 0;
  line-height: 26px;
  border: none;
}

.bs-light-scheme .section-heading.sh-t3 > .h-text {
  color: #fff;
}

.bs-light-scheme .section-heading.sh-t3:after {
  background-color: #fff !important;
}

.section-heading.sh-t3 > .other-link .h-text {
  padding: 4px 0 4px 17px;
}

.section-heading.sh-t3 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  border-color: rgba(0, 0, 0, 0.09);
}

.section-heading.sh-t3.sh-s2 {
  padding: 0 15px;
}

.section-heading.sh-t3.sh-s2:after {
  height: 100%;
  opacity: 0.08;
}

.section-heading.sh-t3.sh-s2.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t3.sh-s2 > .h-text, .section-heading.sh-t3.sh-s2 > a > .h-text {
  line-height: 36px;
}

.section-heading.sh-t3.sh-s3 {
  padding: 0 15px;
}

.section-heading.sh-t3.sh-s3:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #444;
}

.section-heading.sh-t3.sh-s3.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text, .section-heading.sh-t3.sh-s3 > .h-text.h-text, .section-heading.sh-t3.sh-s3 > a.active > .h-text.h-text, .section-heading.sh-t3.sh-s3 > a > .h-text.h-text {
  line-height: 34px;
  color: #fff !important;
}

.section-heading.sh-t3.sh-s3:after {
  height: 3px;
  background: #444;
}

.section-heading.sh-t3.sh-s3 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  background: #444;
  right: -15px;
}

.section-heading.sh-t3.sh-s3 .other-link .h-text {
  color: #fff;
}

.section-heading.sh-t3.sh-s3 > .h-text:last-child:first-child, .section-heading.sh-t3.sh-s3 > .main-link > .h-text, .section-heading.sh-t3.sh-s3 > a:last-child:first-child > .h-text {
  text-transform: uppercase;
}

.section-heading.sh-t3.sh-s4 {
  padding: 0 15px;
}

.section-heading.sh-t3.sh-s4:after {
  height: 100%;
}

.section-heading.sh-t3.sh-s4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text, .section-heading.sh-t3.sh-s4 > .h-text.h-text.h-text, .section-heading.sh-t3.sh-s4 > a.active > .h-text.h-text.h-text, .section-heading.sh-t3.sh-s4 > a > .h-text.h-text.h-text {
  line-height: 34px;
  border: none;
}

.section-heading.sh-t3.sh-s4.sh-t3.sh-s4 .h-text, .section-heading.sh-t3.sh-s4.sh-t3.sh-s4 .main-link:first-child:last-child .main-term-1171.h-text, .section-heading.sh-t3.sh-s4.sh-t3.sh-s4 a.active, .section-heading.sh-t3.sh-s4.sh-t3.sh-s4 > .other-link .h-text {
  color: #fff !important;
}

.section-heading.sh-t3.sh-s4 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  right: -15px;
}

.section-heading.sh-t3.sh-s5 {
  border: 1px solid rgba(0, 0, 1, 0.07);
  padding: 2px 15px 0 15px;
}

.section-heading.sh-t3.sh-s5:before {
  display: none;
}

.section-heading.sh-t3.sh-s5:after {
  top: -1px;
  height: 3px;
  left: -1px;
  right: -1px;
  width: auto;
}

.section-heading.sh-t3.sh-s5.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text {
  padding-right: 0;
}

.section-heading.sh-t3.sh-s5.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t3.sh-s5 > .h-text, .section-heading.sh-t3.sh-s5 > a > .h-text {
  line-height: 36px;
  border: none;
}

.section-heading.sh-t3.sh-s6 {
  padding: 0 15px 0 15px;
}

.section-heading.sh-t3.sh-s6:before {
  display: none;
}

.section-heading.sh-t3.sh-s6:after {
  height: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFNzg0NTFBNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFNzg0NTFCNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUU3ODQ1MTg2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUU3ODQ1MTk2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz461u9NAAAAEUlEQVR42mJgQAX/yeAABBgAgcID/UDOgL8AAAAASUVORK5CYII=) !important;
  opacity: 0.13;
}

.section-heading.sh-t3.sh-s6.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t3.sh-s6 > .h-text, .section-heading.sh-t3.sh-s6 > a > .h-text {
  line-height: 36px;
  border: none;
}

.section-heading.sh-t3.sh-s7 {
  padding: 0 15px;
  text-align: center;
}

.section-heading.sh-t3.sh-s7.multi-tab {
  text-align: inherit;
}

.section-heading.sh-t3.sh-s7:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 50%;
  height: 0;
  width: 0;
  margin-left: -10.5px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #444;
}

.section-heading.sh-t3.sh-s7.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text.h-text, .section-heading.sh-t3.sh-s7 > .h-text.h-text.h-text, .section-heading.sh-t3.sh-s7 > a.active > .h-text.h-text.h-text, .section-heading.sh-t3.sh-s7 > a > .h-text.h-text.h-text {
  line-height: 32px;
  color: #fff !important;
}

.section-heading.sh-t3.sh-s7:after {
  height: 100%;
  background: #444;
}

.section-heading.sh-t3.sh-s7 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  background: #444;
  right: -15px;
}

.section-heading.sh-t3.sh-s7 .other-link .h-text {
  color: #fff;
}

.section-heading.sh-t3.sh-s8 {
  padding: 0;
  text-align: center;
}

.section-heading.sh-t3.sh-s8.multi-tab {
  text-align: inherit;
}

.section-heading.sh-t3.sh-s8 > .h-text:last-child:first-child, .section-heading.sh-t3.sh-s8 > .main-link > .h-text, .section-heading.sh-t3.sh-s8 > a:last-child:first-child > .h-text {
  text-transform: uppercase;
  border: 2px solid #444;
  padding: 0 15px;
}

.section-heading.sh-t3.sh-s8 > .h-text:last-child:first-child:after, .section-heading.sh-t3.sh-s8 > .h-text:last-child:first-child:before, .section-heading.sh-t3.sh-s8 > .main-link > .h-text:after, .section-heading.sh-t3.sh-s8 > .main-link > .h-text:before, .section-heading.sh-t3.sh-s8 > a:last-child:first-child > .h-text:after, .section-heading.sh-t3.sh-s8 > a:last-child:first-child > .h-text:before {
  display: inline-block;
  content: "";
  height: 100%;
  width: 17px;
  position: absolute;
  left: -19px;
  top: 0;
  background: #fff;
}

.section-heading.sh-t3.sh-s8 > .h-text:last-child:first-child:after, .section-heading.sh-t3.sh-s8 > .main-link > .h-text:after, .section-heading.sh-t3.sh-s8 > a:last-child:first-child > .h-text:after {
  left: inherit;
  right: -19px;
}

.section-heading.sh-t3.sh-s8.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t3.sh-s8 > .h-text, .section-heading.sh-t3.sh-s8 > a > .h-text {
  line-height: 34px;
  border: none;
  background: #fff;
}

.section-heading.sh-t3.sh-s8:after {
  opacity: 0.13;
  height: 2px;
  margin-top: -1px;
  top: 50%;
  display: block;
}

.section-heading.sh-t3.sh-s8.multi-tab .bs-pretty-tabs-container {
  background: #fff;
}

.section-heading.sh-t3.sh-s9 {
  text-align: center;
  padding: 0 16px 4px;
}

.section-heading.sh-t3.sh-s9:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
}

.section-heading.sh-t3.sh-s9:after {
  height: 3px;
}

.section-heading.sh-t3.sh-s9.section-heading.sh-t3.sh-s9 .h-text {
  color: #fff !important;
  padding: 5px 0;
}

.section-heading.sh-t3.sh-s9.bs-pretty-tabs {
  text-align: inherit !important;
}

.section-heading.multi-tab.sh-t4 .main-link.active .h-text, .section-heading.sh-t4 .main-link:first-child:last-child .h-text, .section-heading.sh-t4 > .h-text {
  color: #fff;
}

.section-heading.sh-t4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t4 > .h-text, .section-heading.sh-t4 > a > .h-text {
  line-height: 32px;
}

.section-heading.multi-tab.sh-t4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t4 .h-text {
  padding: 0 12px;
}

.section-heading.multi-tab.sh-t4 .h-text, .section-heading.multi-tab.sh-t4 .main-link .h-text {
  border: 1px solid #e2e2e2;
  border-color: rgba(0, 0, 0, 0.15);
  margin-right: 12px;
}

.section-heading.multi-tab.sh-t4 > .h-text, .section-heading.multi-tab.sh-t4 > a > .h-text {
  background: #fff;
}

.section-heading.multi-tab.sh-t4 .other-link .h-text {
  margin-right: 0;
  margin-left: 12px;
}

.section-heading.multi-tab.sh-t4 .main-link:hover .h-text, .section-heading.sh-t4 .main-link.active .h-text, .section-heading.sh-t4 a.active .h-text, .section-heading.sh-t4 a:hover .h-text {
  border-color: transparent !important;
  color: #fff;
}

.section-heading.multi-tab.sh-t4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text {
  border-color: #e2e2e2;
  border-color: rgba(0, 0, 0, 0.15);
  background: #fff;
}

.section-heading.sh-t4 .bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text:after {
  height: 50%;
  right: 0;
  left: initial;
  top: 90%;
  pointer-events: all;
}

.section-heading.sh-t4 .bs-pretty-tabs-container .bs-pretty-tabs-elements .other-link .h-text {
  margin: 0 0 1px;
  border: none;
  padding: 1px 10px;
}

.section-heading.sh-t4 .bs-pretty-tabs-container .bs-pretty-tabs-elements .other-link .h-text:after {
  display: none;
}

.section-heading.multi-tab.sh-t4 .bs-pretty-tabs-container {
  padding-left: 0;
}

.section-heading.multi-tab.sh-t4 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  top: 120%;
  border-color: #e2e2e2;
  border-color: rgba(0, 0, 0, 0.15);
}

.section-heading.multi-tab.sh-t4.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text:before {
  content: "";
  position: absolute;
  height: 21%;
  display: inline-block;
  background: 0 0;
  right: -1px;
  left: -1px;
  top: 103%;
  top: calc(100% + 1px);
  pointer-events: visible;
}

.section-heading.multi-tab.sh-t4.bs-pretty-tabs .bs-pretty-tabs-more.other-link:hover .h-text {
  background: #fff !important;
}

.section-heading.sh-t4.sh-s1:after, .section-heading.sh-t4.sh-s3:after {
  height: 100%;
  top: 0;
  margin-top: 0;
  background: #f3f3f3;
  background: rgba(0, 0, 0, 0.04);
}

.section-heading.sh-t4.sh-s1 .h-text:after, .section-heading.sh-t4.sh-s3 .h-text:after {
  content: "";
  position: absolute;
  height: 110%;
  width: 12px;
  display: inline-block;
  background: #fff;
  right: -13px;
  top: -1px;
}

.section-heading.sh-t4.sh-s1 .other-link .h-text:after, .section-heading.sh-t4.sh-s3 .other-link .h-text:after {
  right: initial;
  left: -13px;
}

.section-heading.sh-t4.sh-s2:after {
  display: none;
}

.section-heading.sh-t4.sh-s3:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjVFNzg0NTFBNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjVFNzg0NTFCNkM0MDExRTdCNkE5ODk3MjRCNzlCNkRFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NUU3ODQ1MTg2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NUU3ODQ1MTk2QzQwMTFFN0I2QTk4OTcyNEI3OUI2REUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz461u9NAAAAEUlEQVR42mJgQAX/yeAABBgAgcID/UDOgL8AAAAASUVORK5CYII=);
  opacity: 0.13;
}

.section-heading.sh-t4.sh-s4:after {
  height: 13px;
  top: 9px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjQ4NUVFODNGNzc1MTFFN0I4NTdCMTRBMzQ3RTIwNTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjQ4NUVFODRGNzc1MTFFN0I4NTdCMTRBMzQ3RTIwNTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCNDg1RUU4MUY3NzUxMUU3Qjg1N0IxNEEzNDdFMjA1NCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCNDg1RUU4MkY3NzUxMUU3Qjg1N0IxNEEzNDdFMjA1NCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pk86A7EAAAASSURBVHjaYmBgYLBgIAgAAgwAB88AOccVbLsAAAAASUVORK5CYII=) repeat;
  opacity: 1;
}

.section-heading.sh-t4.sh-s4 .h-text:before {
  content: "";
  position: absolute;
  display: inline-block;
  right: -11px;
  top: 0;
  width: 25px;
  height: 35px;
  transform: skew(29deg);
  z-index: -1;
}

.section-heading.sh-t4.sh-s4 .h-text:after {
  content: "";
  position: absolute;
  height: 110%;
  display: inline-block;
  background: #fff;
  top: -1px;
  width: 42px;
  right: -42px;
  z-index: -2;
}

.site-footer .light-text .section-heading.sh-t4.sh-s4 .h-text:after, .site-footer .light-text .section-heading.sh-t4.sh-s4:after {
  display: none;
}

.section-heading.sh-t4.sh-s4 .h-text {
  line-height: 34px;
  height: 35px;
  padding-top: 2px;
}

.section-heading.sh-t4.sh-s4 .main-link .h-text {
  border: none !important;
}

.section-heading.sh-t4.sh-s4 .other-link.other-link .h-text {
  background-color: #fff !important;
  border: none !important;
  margin-top: -3px;
  color: #000 !important;
  margin-left: 0;
}

.section-heading.sh-t4.sh-s4 .other-link.other-link .h-text:after, .section-heading.sh-t4.sh-s4 .other-link.other-link .h-text:before {
  display: none;
}

.section-heading.sh-t4.sh-s4 .main-link:hover .h-text, .section-heading.sh-t4.sh-s4.section-heading.sh-t4.sh-s4 .main-link .h-text {
  color: #fff !important;
}

.section-heading.sh-t4.sh-s5:after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjkyNTA0MDQyNDg2MTExRThBMUYzQkY4RTIxQ0VCMDZDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjkyNTA0MDQzNDg2MTExRThBMUYzQkY4RTIxQ0VCMDZDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTI1MDQwNDA0ODYxMTFFOEExRjNCRjhFMjFDRUIwNkMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTI1MDQwNDE0ODYxMTFFOEExRjNCRjhFMjFDRUIwNkMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6f3F9TAAAAhklEQVR42myPSw7DIAxEJyp36BoQWy7ASRAC5WAIDpVVJBb87kEbFhVtY8kL+4014y2lJDnn+K2cM7TW2MYY8g4aYxBCAC7B2m8olVLyPM85k/Wy1gprLWKMEELM3UfQWoNzbsI1E1nh5ckY+8pDeu/Y931CSunfN4/jOJ7e+1tYSsFLgAEAJ2RFZ6uSCs8AAAAASUVORK5CYII=) repeat;
  opacity: 0.7;
}

.have_bg.bs-light-scheme .section-heading.sh-t4.sh-s5:after {
  filter: invert(100%) brightness(12.7);
  opacity: 0.6;
  background-color: transparent;
}

.bs-light-scheme .section-heading.sh-t4.sh-s5 > .h-text {
  color: #000 !important;
}

.section-heading.sh-t4.sh-s5:after {
  height: 100%;
}

.section-heading.sh-t4.sh-s5 .h-text:before {
  content: "";
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  z-index: -1;
  border-left: 0 solid transparent;
  border-right: 17px solid transparent;
  border-top: 16px solid #fff;
}

.section-heading.sh-t4.sh-s5 .h-text:after {
  content: "";
  position: absolute;
  height: 100%;
  display: inline-block;
  background: #fff;
  top: 0;
  width: 14px;
  right: -14px;
  z-index: -2;
}

.site-footer .light-text .section-heading.sh-t4.sh-s5 .h-text:after, .site-footer .light-text .section-heading.sh-t4.sh-s5:after {
  display: none;
}

.section-heading.sh-t4.sh-s5 .h-text {
  line-height: 40px;
  height: 42px;
  padding-top: 2px;
  padding-right: 17px;
  padding-left: 17px;
}

.section-heading.sh-t4.sh-s5 .main-link .h-text {
  border: none !important;
}

.section-heading.sh-t4.sh-s5 .other-link.other-link .h-text {
  background-color: #fff !important;
  border: none !important;
  color: #000 !important;
  margin-left: 0;
}

.section-heading.sh-t4.sh-s5 .other-link.other-link .h-text:after, .section-heading.sh-t4.sh-s5 .other-link.other-link .h-text:before {
  display: none;
}

.section-heading.sh-t4.sh-s5 .main-link:hover .h-text, .section-heading.sh-t4.sh-s5.section-heading.sh-t4.sh-s5 .main-link .h-text {
  color: #fff !important;
}

.section-heading.sh-t4.sh-s6 {
  background-color: #2d2f38;
}

.bs-light-scheme .section-heading.sh-t4.sh-s6 {
  background-color: rgba(255, 255, 255, 0.07);
}

.section-heading.sh-t4.sh-s6 .h-text {
  line-height: 38px;
  color: #fff !important;
  border: none !important;
}

.section-heading.sh-t4.sh-s6 .h-text:before {
  content: "";
  position: absolute;
  right: -20px;
  top: 0;
  z-index: -1;
  border-left: 0 solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 38px solid transparent;
}

.section-heading.sh-t4.sh-s6 .other-link span {
  background-color: transparent !important;
  border: none;
  color: #fff;
  font-size: 11px;
}

.section-heading.sh-t4.sh-s6 .other-link span:before {
  display: none;
}

.section-heading.sh-t4.sh-s6 .other-link.active .h-text {
  background-color: transparent !important;
  border: none;
  color: #fff;
}

.section-heading.multi-tab.sh-t4.sh-s6 .main-link .h-text:hover {
  color: #fff !important;
}

.section-heading.sh-t4.sh-s6 .h-text:after, .section-heading.sh-t4.sh-s6:after {
  display: none;
}

.section-heading.sh-t5 {
  padding: 0 0 3px;
}

.section-heading.sh-t5:after {
  display: none;
}

.section-heading.sh-t5:before {
  content: "";
  display: inline-block;
  height: 3px;
  position: absolute;
  top: inherit;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ededed;
  background: rgba(0, 0, 0, 0.07);
}

.section-heading.sh-t5 > .h-text:last-child:first-child, .section-heading.sh-t5 > .main-link > .h-text, .section-heading.sh-t5 > a:last-child:first-child > .h-text {
  position: relative;
}

.section-heading.sh-t5 > .h-text:last-child:first-child:before, .section-heading.sh-t5 > .main-link > .h-text:before, .section-heading.sh-t5 > a:last-child:first-child > .h-text:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  bottom: -3px;
  background: #000;
}

.section-heading.sh-t5 .h-text {
  padding: 0;
  line-height: 36px;
}

.section-heading.sh-t5 .other-link .h-text, .section-heading.sh-t5.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  padding: 0 0 0 17px;
  text-transform: capitalize;
}

.section-heading.sh-t5 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  border-color: #e6e6e6;
  padding: 8px 12px;
}

.section-heading.sh-t5 .bs-pretty-tabs-elements .other-link .h-text {
  line-height: 30px;
  padding: 0;
}

.section-heading.sh-t5.sh-s2 {
  padding: 3px 0 0;
}

.section-heading.sh-t5.sh-s2 > .h-text:last-child:first-child:before, .section-heading.sh-t5.sh-s2 > .main-link > .h-text:before, .section-heading.sh-t5.sh-s2 > a:last-child:first-child > .h-text:before {
  bottom: inherit;
  top: -3px;
}

.section-heading.sh-t5.sh-s2:before {
  top: 0;
  bottom: inherit;
}

.section-heading.sh-t5.sh-2 .h-text {
  line-height: 40px;
}

.section-heading.sh-t6 {
  padding: 0;
}

.section-heading.sh-t6:after {
  display: none;
}

.section-heading.sh-t6:before {
  content: "";
  display: inline-block;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.section-heading.sh-t6 > .h-text:last-child:first-child, .section-heading.sh-t6 > .main-link > .h-text, .section-heading.sh-t6 > a:last-child:first-child > .h-text {
  position: relative;
  padding: 0 11px;
  color: #fff;
}

.section-heading.sh-t6.sh-s13:before {
  top: auto;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.07) !important;
}

.section-heading.sh-t6 .h-text {
  padding: 0;
  line-height: 32px;
}

.section-heading.sh-t6 .other-link .h-text, .section-heading.sh-t6.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  padding: 2px 0 0 17px;
  text-transform: capitalize;
}

.section-heading.sh-t6 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  border-color: #e6e6e6;
  padding: 8px 12px;
}

.section-heading.sh-t6 .bs-pretty-tabs-elements .other-link .h-text {
  line-height: 30px;
  padding: 0;
}

.section-heading.sh-t6 > .other-link {
  margin-top: 2px;
}

.section-heading.sh-t6 .bs-pretty-tabs-elements .other-link {
  margin-top: 0;
}

.section-heading.multi-tab.sh-t6 .bs-pretty-tabs-container {
  padding-left: 0;
}

.section-heading.sh-t6.sh-s2:before {
  top: 50%;
  height: 3px;
  margin-top: -1.5px;
}

.section-heading.sh-t6.sh-s2 > .other-link {
  margin-top: 0;
}

.section-heading.sh-t6.sh-s2 .other-link .h-text, .section-heading.sh-t6.sh-s2.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  padding: 0 0 0 17px;
  background: #fff;
}

.section-heading.sh-t6.sh-s3:before {
  top: inherit;
  bottom: 0;
  height: 3px;
  margin-top: 0;
}

.section-heading.sh-t6.sh-s3 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s3 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s3 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s3 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s3 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s3 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 30px solid #000;
  border-right: 20px solid transparent;
  z-index: 1;
  right: -20px;
  background-color: transparent !important;
}

.section-heading.sh-t6.sh-s3 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s3 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s3 > a:last-child:first-child > .h-text:before {
  background: #fff;
}

.section-heading.sh-t6.sh-s3 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s3 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s3 > a:last-child:first-child > .h-text:after {
  border-bottom-color: rgba(255, 255, 255, 0.43);
  z-index: 2;
  -webkir-box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1);
}

.section-heading.sh-t6.sh-s3 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s3 > .main-link > .h-text, .section-heading.sh-t6.sh-s3 > a:last-child:first-child > .h-text {
  padding: 0 15px;
}

.section-heading.sh-t6.sh-s3 > .other-link {
  margin-top: 0;
}

.section-heading.sh-t6.bs-pretty-tabs.sh-s3 .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t6.sh-s3 .other-link .h-text {
  padding: 0 0 0 17px;
}

.section-heading.sh-t6.sh-s3 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  top: 96%;
  border-top-color: transparent;
}

.section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s4 > .main-link > .h-text, .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text {
  position: relative;
  padding: 0 24px 0 12px;
}

.section-heading.sh-t6.sh-s4:before {
  display: none;
}

.section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s4 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 100%;
  border-left: 2px solid #fff;
  border-right: 3px solid rgba(255, 255, 255, 0.7);
}

.section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after {
  right: 5px;
  width: 2px;
  border-left: 3px solid #fff;
  border-right: 5px solid rgba(255, 255, 255, 0.45);
}

.section-heading.sh-t6.sh-s5:before {
  display: none;
}

.section-heading.sh-t6.sh-s5 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s5 > .main-link > .h-text, .section-heading.sh-t6.sh-s5 > a:last-child:first-child > .h-text {
  padding: 0 22px 0 25px;
  border-radius: 0 0 12px;
}

.section-heading.sh-t6.sh-s5 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s5 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s5 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s5 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s5 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s5 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 20px solid #fff;
  border-right: 15px solid transparent;
}

.section-heading.sh-t6.sh-s5 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s5 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s5 > a:last-child:first-child > .h-text:after {
  border-right: none;
  border-top: none;
  border-bottom: 20px solid rgba(0, 0, 0, 0.3);
  border-left: 15px solid transparent;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
}

.section-heading.sh-t6.sh-s6, .section-heading.sh-t6.sh-s7 {
  padding: 0 25px 0 0;
}

.section-heading.sh-t6.sh-s6:before, .section-heading.sh-t6.sh-s7:before {
  height: 100%;
  width: auto;
  left: 5px;
  right: 5px;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  background: rgba(0, 0, 0, 0.05) !important;
}

.section-heading.sh-t6.sh-s7:before {
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
}

.section-heading.sh-t6.sh-s6 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s6 > .main-link > .h-text, .section-heading.sh-t6.sh-s6 > a:last-child:first-child > .h-text, .section-heading.sh-t6.sh-s7 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s7 > .main-link > .h-text, .section-heading.sh-t6.sh-s7 > a:last-child:first-child > .h-text {
  padding: 0 26px 0 10px;
}

.section-heading.sh-t6.sh-s6 .h-text, .section-heading.sh-t6.sh-s7 .h-text {
  line-height: 34px;
}

.section-heading.sh-t6.bs-pretty-tabs.sh-s6 .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t6.bs-pretty-tabs.sh-s7 .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t6.sh-s6 .other-link .h-text, .section-heading.sh-t6.sh-s7 .other-link .h-text {
  padding: 0 0 0 17px;
  border: none;
}

.section-heading.sh-t6.sh-s6 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s6 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s6 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s7 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s7 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s7 > a:last-child:first-child > .h-text:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: -8px;
  width: 16px;
  height: 100%;
  z-index: 1;
  background: #fff;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
}

.section-heading.sh-t6.sh-s7 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s7 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s7 > a:last-child:first-child > .h-text:after {
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
}

.section-heading.sh-t6.sh-s7 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s7 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s7 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 44px solid #fff;
  border-right: 16px solid transparent;
}

.section-heading.sh-t6.sh-s6 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s6 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s6 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -7px;
  width: 15px;
  height: 100%;
  z-index: 1;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  border: none;
}

.section-heading.sh-t6.sh-s6 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s6 > .main-link > .h-text, .section-heading.sh-t6.sh-s6 > a:last-child:first-child > .h-text {
  margin-left: 11px;
}

.section-heading.sh-t6.sh-s7 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s7 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s7 > a:last-child:first-child > .h-text:before {
  border-top: none;
  border-bottom: 44px solid #fff;
}

.section-heading.sh-t6.sh-s6 > .other-link, .section-heading.sh-t6.sh-s7 > .other-link {
  margin-top: 0;
}

.section-heading.sh-t6.sh-s6 .bs-pretty-tabs-container .bs-pretty-tabs-elements, .section-heading.sh-t6.sh-s7 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  right: -13px;
}

.section-heading.sh-t6.sh-s8:before {
  display: none;
}

.section-heading.sh-t6.sh-s8 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s8 > .main-link > .h-text, .section-heading.sh-t6.sh-s8 > a:last-child:first-child > .h-text {
  padding: 0 25px 0 12px;
}

.section-heading.sh-t6.sh-s8 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s8 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s8 > a:last-child:first-child > .h-text:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 16px solid transparent;
  border-right: 16px solid #fff;
  border-bottom: 16px solid transparent;
}

.section-heading.sh-t6.sh-s8 .other-link .h-text, .section-heading.sh-t6.sh-s8.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  padding-top: 0;
}

.section-heading.sh-t6.sh-s8 > .other-link {
  margin-top: 0;
}

.section-heading.sh-t6.sh-s10, .section-heading.sh-t6.sh-s12, .section-heading.sh-t6.sh-s9 {
  padding: 0 15px 7px 0;
}

.section-heading.sh-t6.sh-s10 .h-text, .section-heading.sh-t6.sh-s12 .h-text, .section-heading.sh-t6.sh-s9 .h-text {
  line-height: 34px;
}

.section-heading.sh-t6.sh-s10:before, .section-heading.sh-t6.sh-s9:before {
  height: auto;
  top: 7px;
  border-radius: 3px;
  bottom: 0;
}

.section-heading.sh-t6.sh-s12:before {
  display: none;
}

.section-heading.sh-t6.sh-s9:before {
  background: rgba(0, 0, 0, 0.05) !important;
}

.section-heading.sh-t6.sh-s10:before {
  opacity: 0.13;
  background: #a2a2a2;
}

.section-heading.sh-t6.sh-s10 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s10 > .main-link > .h-text, .section-heading.sh-t6.sh-s10 > a:last-child:first-child > .h-text, .section-heading.sh-t6.sh-s9 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s9 > .main-link > .h-text, .section-heading.sh-t6.sh-s9 > a:last-child:first-child > .h-text {
  border-radius: 3px 3px 3px 0;
}

.section-heading.sh-t6.sh-s10 > .other-link, .section-heading.sh-t6.sh-s9 > .other-link {
  margin-top: 6px;
}

.section-heading.sh-t6.sh-s10.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text, .section-heading.sh-t6.sh-s9.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  border: none;
  padding-top: 0;
  line-height: 33px;
  margin-top: 8px;
}

.section-heading.sh-t6.sh-s10.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  border: none;
  padding-top: 0;
  line-height: 33px;
}

.section-heading.sh-t6.sh-s10 > .other-link, .section-heading.sh-t6.sh-s9 > .other-link {
  margin-top: 6px;
}

.section-heading.sh-t6.sh-s10 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s10 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s10 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s10 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s10 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s10 > a:last-child:first-child > .h-text:before, .section-heading.sh-t6.sh-s12 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s12 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s12 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s12 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s12 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s12 > a:last-child:first-child > .h-text:before, .section-heading.sh-t6.sh-s9 > .h-text:last-child:first-child:after, .section-heading.sh-t6.sh-s9 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s9 > .main-link > .h-text:after, .section-heading.sh-t6.sh-s9 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s9 > a:last-child:first-child > .h-text:after, .section-heading.sh-t6.sh-s9 > a:last-child:first-child > .h-text:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 10px solid rgba(0, 0, 0, 0.18);
  border-right: 10px solid transparent;
}

.section-heading.sh-t6.sh-s10 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s10 > .main-link > .h-text, .section-heading.sh-t6.sh-s10 > a:last-child:first-child > .h-text, .section-heading.sh-t6.sh-s12 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s12 > .main-link > .h-text, .section-heading.sh-t6.sh-s12 > a:last-child:first-child > .h-text, .section-heading.sh-t6.sh-s9 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s9 > .main-link > .h-text, .section-heading.sh-t6.sh-s9 > a:last-child:first-child > .h-text {
  padding: 0 19px;
}

.section-heading.sh-t6.sh-s12 .other-link .h-text {
  padding-top: 0;
}

.section-heading.sh-t6.sh-s12 > .other-link {
  margin-top: 0;
}

.section-heading.sh-t6.sh-s11 {
  text-align: center;
}

.section-heading.sh-t6.sh-s11.multi-tab {
  text-align: inherit;
}

.section-heading.sh-t6.sh-s11:before {
  display: none;
}

.section-heading.sh-t6.sh-s11 > .h-text:last-child:first-child, .section-heading.sh-t6.sh-s11 > .main-link > .h-text, .section-heading.sh-t6.sh-s11 > a:last-child:first-child > .h-text {
  background: 0 0 !important;
  padding: 0 62px;
  text-align: center;
  line-height: 31px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  text-transform: inherit;
}

.section-heading.sh-t6.sh-s11 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s11 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s11 > a:last-child:first-child > .h-text:before {
  left: 47px;
  right: 47px;
  height: 100%;
  position: absolute;
  content: "";
  display: inline-block;
  z-index: -1;
  border-radius: 10px;
}

.section-heading.sh-t6.sh-s11 .sh-svg-l {
  position: absolute;
  left: 0;
  z-index: -1;
  top: -1px;
}

.section-heading.sh-t6.sh-s11 .sh-svg-r {
  position: absolute;
  right: 0;
  top: -1px;
}

.section-heading.sh-t6.sh-s12 > .h-text:last-child:first-child:before, .section-heading.sh-t6.sh-s12 > .main-link > .h-text:before, .section-heading.sh-t6.sh-s12 > a:last-child:first-child > .h-text:before {
  background-color: transparent !important;
}

.section-heading.sh-t7 {
  padding: 0;
}

.section-heading.sh-t7:after {
  display: none;
}

.section-heading.sh-t7:before {
  content: "";
  display: inline-block;
  height: 3px;
  position: absolute;
  top: 50%;
  margin-top: -1.5px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ededed;
  background: rgba(0, 0, 0, 0.07);
}

.section-heading.sh-t7 > .h-text:last-child:first-child, .section-heading.sh-t7 > .main-link > .h-text, .section-heading.sh-t7 > a:last-child:first-child > .h-text {
  position: relative;
  padding: 0 15px 0 24px;
  background: #fff;
}

.section-heading.sh-t7 > .h-text:last-child:first-child:before, .section-heading.sh-t7 > .main-link > .h-text:before, .section-heading.sh-t7 > a:last-child:first-child > .h-text:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 15px;
  width: 15px;
  background: #000;
  top: 50%;
  margin-top: -7.5px;
}

.section-heading.sh-t7 .h-text {
  padding: 0;
}

.section-heading.sh-t7 .other-link .h-text, .section-heading.sh-t7.bs-pretty-tabs .bs-pretty-tabs-more.other-link .h-text {
  padding: 0 0 0 17px;
  text-transform: capitalize;
  background: #fff;
}

.section-heading.sh-t7 .bs-pretty-tabs-container .bs-pretty-tabs-elements {
  border-color: #e6e6e6;
  padding: 8px 12px;
}

.section-heading.sh-t7 .bs-pretty-tabs-elements .other-link .h-text {
  line-height: 30px;
  padding: 0;
}

.footer-widgets.light-text .section-heading.sh-t1 .main-link:first-child:last-child .h-text, .footer-widgets.light-text .section-heading.sh-t1 a.active .h-text, .footer-widgets.light-text .section-heading.sh-t1 a:hover .h-text, .footer-widgets.light-text .section-heading.sh-t1 > .h-text {
  color: #fff;
}

.footer-widgets.light-text .section-heading.sh-t1:after {
  background: rgba(255, 255, 255, 0.1);
}

.footer-widgets.light-text .section-heading.sh-t1.sh-s2:after {
  opacity: 0.45;
}

.footer-widgets.light-text .section-heading.sh-t1.sh-s5 > .h-text:first-child:last-child:after, .footer-widgets.light-text .section-heading.sh-t1.sh-s5 > .h-text:last-child:first-child, .footer-widgets.light-text .section-heading.sh-t1.sh-s5 > .main-link > .h-text, .footer-widgets.light-text .section-heading.sh-t1.sh-s5 > .main-link > .h-text:after, .footer-widgets.light-text .section-heading.sh-t1.sh-s5 > a:first-child:last-child > .h-text:after, .footer-widgets.light-text .section-heading.sh-t1.sh-s5 > a:last-child:first-child > .h-text {
  color: #fff !important;
}

.footer-widgets.light-text .section-heading.sh-t2:before {
  background: rgba(255, 255, 255, 0.14);
}

.footer-widgets.light-text .section-heading.sh-t2:after {
  background-color: #f9f9f9;
}

.footer-widgets.light-text .section-heading.sh-t2 .main-link:first-child:last-child .h-text, .footer-widgets.light-text .section-heading.sh-t2 a.active, .footer-widgets.light-text .section-heading.sh-t2 > .h-text {
  color: #fff !important;
}

.footer-widgets.light-text .listing-item-text-2 .item-inner {
  border-color: rgba(255, 255, 255, 0.11);
}

.footer-widgets.light-text .section-heading.sh-t2.sh-s2 {
  background: rgba(255, 255, 255, 0.04);
}

.footer-widgets.light-text .section-heading.sh-t2.sh-s3 {
  background: rgba(255, 255, 255, 0.04);
}

.footer-widgets.light-text .section-heading.sh-t2.sh-s4 {
  border: 1px solid rgba(255, 255, 255, 0.06);
}

.footer-widgets.light-text .section-heading.sh-t3:after {
  background: #fff;
}

.footer-widgets.light-text .section-heading.sh-t3 {
  color: #fff;
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s4.bs-pretty-tabs .bs-pretty-tabs-container .bs-pretty-tabs-more.other-link .h-text.h-text, .footer-widgets.light-text .section-heading.sh-t3.sh-s4 > .h-text.h-text.h-text, .footer-widgets.light-text .section-heading.sh-t3.sh-s4 > a.active > .h-text.h-text.h-text, .footer-widgets.light-text .section-heading.sh-t3.sh-s4 > a > .h-text.h-text.h-text {
  color: #000 !important;
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s5 {
  border: 1px solid rgba(255, 255, 255, 0.07);
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s6 {
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s7:after {
  background: #444;
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s7:before {
  border-top: 10px solid #444;
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s9:before {
  background-color: rgba(255, 255, 255, 0.07) !important;
}

.footer-widgets.light-text .section-heading.sh-t3.sh-s9:after {
  background-color: rgba(255, 255, 255, 0.07) !important;
}

.footer-widgets.light-text .section-heading.sh-t4.sh-s1:after, .footer-widgets.light-text .section-heading.sh-t4.sh-s3:after {
  background: rgba(255, 255, 255, 0.05);
}

.footer-widgets.light-text .section-heading.sh-t4.sh-s6 {
  background-color: rgba(255, 255, 255, 0.07);
}

.footer-widgets.light-text .section-heading.sh-t4.sh-s5:after {
  display: block;
  filter: invert(100%) brightness(12.7);
  opacity: 0.6;
  background-color: transparent;
}

.footer-widgets.light-text .section-heading.sh-t5:before {
  background: rgba(255, 255, 255, 0.06);
}

.footer-widgets.light-text .section-heading.sh-t5 > .h-text:last-child:first-child:before, .footer-widgets.light-text .section-heading.sh-t5 > .main-link > .h-text:before, .footer-widgets.light-text .section-heading.sh-t5 > a:last-child:first-child > .h-text:before {
  background: #fff;
}

.footer-widgets.light-text .section-heading.sh-t5 {
  color: #fff;
}

.footer-widgets .section-heading.sh-t4.sh-s5 .h-text:after {
  display: block !important;
}

.ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after {
  border-right-color: rgba(0, 0, 0, 0.2);
}

.rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after {
  border-left-color: rgba(0, 0, 0, 0.2);
}

.ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:before, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:before, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after, .ltr .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:before {
  border-right-color: rgba(0, 0, 0, 0.3);
}

.rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:after, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .h-text:last-child:first-child:before, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:after, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > .main-link > .h-text:before, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:after, .rtl .footer-widgets.light-text .section-heading.sh-t6.sh-s4 > a:last-child:first-child > .h-text:before {
  border-left-color: rgba(0, 0, 0, 0.3);
}

.footer-widgets.light-text .section-heading.sh-t6.sh-s9:before {
  background: rgba(255, 255, 255, 0.05) !important;
}

.site-footer .footer-widgets.light-text .section-heading.sh-t7.sh-s1 .h-text {
  color: #fff !important;
}

.footer-widgets.light-text .section-heading.sh-t7:before {
  background: rgba(255, 255, 255, 0.1);
}

.single-featured-video {
  max-width: 100% !important;
}

.post-meta.single-post-meta, .post-meta.single-post-meta a {
  color: #3a3a3a;
}

.post-meta.single-post-meta .comments, .post-meta.single-post-meta .post-author-avatar, .post-meta.single-post-meta .time, .post-meta.single-post-meta .views {
  float: none;
  margin: 0 12px 0 0;
  white-space: nowrap;
  line-height: 26px;
  display: inline-block;
}

.post-meta.single-post-meta > a + span:before, .post-meta.single-post-meta > span + a:before, .post-meta.single-post-meta > span + span:before {
  content: " ";
  display: inline-block;
  width: 9px;
  margin-right: 12px;
  height: 1px;
  background: rgba(0, 0, 0, 0.23);
  vertical-align: middle;
}

.post-meta.single-post-meta b {
  color: #4d4d4d;
  margin-left: 2px;
}

.post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  border-radius: 50%;
  margin: 0;
}

.post-meta.single-post-meta .post-author-a.post-author-avatar .avatar + .post-author-name {
  margin-left: 7px;
}

.post-meta.single-post-meta .post-author-name {
  text-transform: capitalize !important;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.post-meta.single-post-meta .post-author-a, .post-meta.single-post-meta .time {
  color: rgba(0, 0, 0, 0.44);
}

.post-meta.single-post-meta .post-author-a:hover {
  color: #b7b7b7 !important;
}

.single-post-title {
  line-height: 1.3;
}

.single-featured {
  text-align: center;
  display: block;
  margin: 0 0 20px;
  line-height: 0;
  position: relative;
}

.single-featured img {
  max-width: 100%;
  height: auto !important;
}

.single-featured figure {
  line-height: 2;
  text-align: center;
}

.single-featured iframe {
  max-width: 100%;
}

.image-credit a, .image-credit a:hover {
  color: #fff !important;
}

.single-featured .image-credit {
  position: absolute;
  right: 0;
  font-size: 10px;
  display: inline-block;
  padding: 0 8px;
  bottom: -18px;
  color: #adadad;
  background: 0 0;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
}

.post.format-standard .single-featured .image-credit {
  line-height: 20px;
  background: rgba(0, 0, 0, 0.26);
  bottom: 0;
  color: #fff;
}

.single-post-excerpt {
  color: #6d6d6d;
}

.single-post-excerpt p:last-child {
  margin-bottom: 0;
}

.single-post-excerpt.post-excerpt-bc {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e2e2e2;
}

.single-post-share {
  list-style: none;
  padding: 0;
  font-size: 0;
}

.single-post-share.top-share {
  margin-bottom: 10px;
}

.single-post-share .number {
  font-size: 13px;
  font-weight: 700;
  margin: 0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  display: inline-block;
}

.single-post-share .post-share-btn {
  background: #fff;
  border: 1px solid #e2e2e2;
  line-height: 30px;
  display: inline-block;
  padding: 0 5px;
  border-radius: 30px;
  font-size: 12px;
  color: #4d4d4d;
  margin-right: 8px;
  margin-bottom: 9px;
  vertical-align: top;
}

.single-post-share .post-share-btn .bf-icon, .single-post-share .post-share-btn .number, .single-post-share .post-share-btn .text {
  line-height: 30px;
  vertical-align: top;
}

.single-post-share .post-share-btn .text {
  font-size: 12px;
}

.single-post-share .post-share-btn .bf-icon {
  font-size: 15px;
  margin: 0 3px;
}

.single-post-share .share-handler {
  float: left;
  position: relative;
}

.single-post-share .post-share-btn.post-share-btn-comments, .single-post-share .post-share-btn.post-share-btn-views {
  float: right;
  border-radius: 0;
}

.single-post-share .post-share-btn.post-share-btn-views {
  font-weight: 700;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.single-post-share .post-share-btn.post-share-btn-views.rank-0, .single-post-share .post-share-btn.post-share-btn-views.rank-default {
  font-weight: 400;
}

.share-handler-wrap {
  overflow: hidden;
  height: 33px;
}

.post-share.style-2 .share-handler-wrap {
  height: 38px;
}

.post-share.style-5 .share-handler-wrap, .post-share.style-8 .share-handler-wrap {
  height: 47px;
}

.post-share.style-6 .share-handler-wrap, .post-share.style-7 .share-handler-wrap {
  height: 70px;
}

.share-handler-wrap.bs-pretty-tabs-initialized {
  height: auto !important;
}

.post-share .post-share-btn-group {
  overflow: hidden;
  float: right;
  margin-bottom: 10px;
}

.post-share .post-share-btn-group .post-share-btn {
  border-radius: 0;
  margin: 0;
  padding: 0 10px;
  border-left-width: 0;
  border-right-width: 0;
  position: relative;
}

.post-share .post-share-btn-group .post-share-btn:before {
  display: inline-block;
  content: " ";
  width: 1px;
  height: 66%;
  background: #e2e2e2;
  position: absolute;
  left: 0;
  top: 17%;
}

.post-share .post-share-btn-group .post-share-btn:first-child {
  border-radius: 0 3px 3px 0;
  border-right-width: 1px;
}

.post-share .post-share-btn-group .post-share-btn:last-child {
  border-radius: 3px 0 0 3px;
  border-left-width: 1px;
}

.post-share .post-share-btn-group .post-share-btn:last-child:before {
  display: none;
}

.post-share .bs-pretty-tabs-container {
  float: left;
}

.post-share .bs-pretty-tabs-elements {
  display: none !important;
}

.single-post-share ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.single-post-share li {
  margin: 0 6px 6px;
  display: inline-block;
  text-align: center;
  list-style: none;
}

.single-post-share li li {
  display: none;
}

.single-post-share .social-item {
  margin: 0 6px 4px 0;
  float: left;
}

.single-post-share .social-item a {
  line-height: 30px;
  min-width: 30px;
  display: inline-block;
  padding: 0 0;
  border-radius: 30px;
  font-size: 14px;
  color: #fff;
  overflow: hidden;
  text-align: center;
}

.single-post-share .social-item.has-count a {
  padding: 0 10px;
}

.single-post-share .social-item a .bf-icon, .single-post-share .social-item a .item-title, .single-post-share .social-item a .number {
  line-height: 30px;
  vertical-align: top;
  color: #fff;
  display: inline-block;
}

.single-post-share .social-item a .item-title, .single-post-share .social-item a .number {
  margin-left: 6px;
}

.single-post-share .social-item a .bf-icon {
  font-size: 16px;
}

.single-post-share .social-item.whatsapp .bf-icon {
  font-size: 16px;
  font-weight: 700;
}

.single-post-share .number:empty {
  display: none;
}

.single-post-share .social-item.facebook a {
  background-color: #2d5f9a;
  color: #133d6d;
}

.single-post-share .social-item.facebook a:hover {
  background-color: #1b4a82;
}

.single-post-share .social-item.twitter a {
  background-color: #53c7ff;
  color: #2e86b1;
}

.single-post-share .social-item.twitter a:hover {
  background-color: #369ed0;
}

.single-post-share .social-item.google_plus a {
  background-color: #d93b2b;
  color: #9a2317;
}

.single-post-share .social-item.google_plus a:hover {
  background-color: #b7291a;
}

.single-post-share .social-item.pinterest a {
  background-color: #a41719;
  color: #670d0e;
}

.single-post-share .social-item.pinterest a:hover {
  background-color: #8c0a0c;
}

.single-post-share .social-item.linkedin a {
  background-color: #005182;
  color: #013c61;
}

.single-post-share .social-item.linkedin a:hover {
  background-color: #003b5f;
}

.single-post-share .social-item.email a, .single-post-share .social-item.print a {
  background-color: #444;
  color: #313131;
}

.single-post-share .social-item.email a:hover, .single-post-share .social-item.print a:hover {
  background-color: #2f2f2f;
}

.single-post-share .social-item.tumblr a {
  background-color: #3e5a70;
  color: #24384a;
}

.single-post-share .social-item.tumblr a:hover {
  background-color: #426283;
}

.single-post-share .social-item.telegram a {
  background-color: #179cde;
  color: #0a629a;
}

.single-post-share .social-item.telegram a:hover {
  background-color: #0f8dde;
}

.single-post-share .social-item.reddit a {
  background-color: #ff4500;
  color: #bb3200;
}

.single-post-share .social-item.reddit a:hover {
  background-color: #dc3c01;
}

.single-post-share .social-item.stumbleupon a {
  background-color: #ee4813;
  color: #982d0b;
}

.single-post-share .social-item.stumbleupon a:hover {
  background-color: #ce3d0f;
}

.single-post-share .social-item.vk a {
  background-color: #4c75a3;
  color: #223448;
}

.single-post-share .social-item.vk a:hover {
  background-color: #3e5f84;
}

.single-post-share .social-item.digg a {
  background-color: #000;
  color: #525252;
}

.single-post-share .social-item.digg a:hover {
  background-color: #2f2f2f;
}

.single-post-share .social-item.bs-custom-link a {
  background-color: #000;
  color: #525252;
}

.single-post-share .social-item.bs-custom-link a:hover {
  background-color: #2f2f2f;
}

.single-post-share .social-item.ok-ru a {
  background-color: #ee8208;
  color: #cb6e04;
}

.single-post-share .social-item.ok-ru a:hover {
  background-color: #d36c08;
}

.single-post-share .social-item.whatsapp a {
  background-color: #00e676;
  color: #02a252;
}

.single-post-share .social-item.whatsapp a:hover {
  background-color: #00c462;
}

.single-post-share .social-item.line a {
  background-color: #00b900;
  color: #018c01;
}

.single-post-share .social-item.line a:hover {
  background-color: #009a00;
}

.single-post-share .social-item.line .bf-icon {
  font-size: 20px;
}

.single-post-share .social-item.bbm .bf-icon:before, .single-post-share .social-item.line .bf-icon:before, .single-post-share .social-item.viber .bf-icon:before {
  vertical-align: top;
}

.single-post-share .social-item.viber a {
  background-color: #5d54a4;
  color: #1f1d3c;
}

.single-post-share .social-item.viber:hover a {
  background-color: #494589;
}

.single-post-share .social-item.viber .bf-icon {
  font-size: 18px;
}

.single-post-share .social-item.bbm a {
  background-color: #1f1f1f;
  color: #505050;
}

.single-post-share .social-item.bbm a:hover {
  background-color: #3c3c3c;
}

.single-post-share .social-item.facebook-messenger a {
  background-color: #448aff;
  color: #2464ff;
}

.single-post-share .social-item.facebook-messenger a:hover {
  background-color: #448aff;
}

.single-post-share .social-item.bbm .bf-icon {
  font-size: 18px;
}

.single-post-share .social-item.telegram .bf-icon {
  margin-left: -1px;
}

.single-post-share .social-item.bbm .bsfi-bbm, .single-post-share .social-item.digg .bf-icon, .single-post-share .social-item.line .bsfi-line, .single-post-share .social-item.viber .bsfi-viber {
  font-size: 18px;
}

.single-post-share .social-item .bf-icon {
  vertical-align: middle;
}

.single-post-share .social-item.bbm, .single-post-share .social-item.line, .single-post-share .social-item.viber, .single-post-share .social-item.whatsapp {
  display: none;
}

@media only screen and (max-width: 780px) {
  .single-post-share .social-item.bbm, .single-post-share .social-item.line, .single-post-share .social-item.viber, .single-post-share .social-item.whatsapp {
    display: inline-block;
  }
}
@media only screen and (max-width: 540px) {
  .post-share .post-share-btn-group {
    float: left;
  }
  .share-handler-wrap {
    clear: both;
  }
}
@media only screen and (max-width: 420px) {
  .single-post-share .social-share-list + .post-share-btn {
    float: left;
    clear: left;
    margin-left: 0;
    margin-top: 5px;
  }
  .single-post-share .post-share-btn {
    margin-bottom: 4px !important;
  }
}
.post-share.style-3 .share-handler, .post-share.style-4 .share-handler, .post-share.style-5 .share-handler, .post-share.style-8 .share-handler {
  margin: 0 12px 12px 0;
}

.post-share .share-handler:after, .post-share .share-handler:before {
  border: solid 6px transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: -12px;
  border-left-color: #d7d7d7;
  margin-top: -6px;
}

.post-share .share-handler:after {
  border: solid 5px transparent;
  border-left-color: #fff;
  right: -10px;
  margin-top: -5px;
}

.post-share.style-1 .share-handler:after, .post-share.style-1 .share-handler:before, .post-share.style-2 .share-handler:after, .post-share.style-2 .share-handler:before {
  display: none;
}

.post-share .post-share-btn, .post-share .social-item a {
  border-radius: 3px;
  padding: 0 5px;
}

.post-share.style-3 .social-item a, .post-share.style-6 .social-item a, .post-share.style-7 .social-item a {
  -moz-box-shadow: 0 3px 0;
  -webkit-box-shadow: 0 3px 0;
  box-shadow: 0 3px 0;
}

.post-share.style-6 .bf-icon, .post-share.style-6 .item-title, .post-share.style-6 .number, .post-share.style-6 .text, .post-share.style-7 .bf-icon, .post-share.style-7 .item-title, .post-share.style-7 .number, .post-share.style-7 .text {
  font-size: 15px;
  display: block;
  font-weight: 400;
}

.post-share.style-5 .post-share-btn .bf-icon, .post-share.style-5 .post-share-btn .number, .post-share.style-5 .post-share-btn .text, .post-share.style-8 .post-share-btn .bf-icon, .post-share.style-8 .post-share-btn .number, .post-share.style-8 .post-share-btn .text {
  line-height: 43px;
}

.post-share.style-5 .bf-icon {
  font-size: 22px !important;
}

.post-share.style-5 .number {
  font-size: 18px;
}

.post-share.style-5 .social-item .item-title, .post-share.style-8 .social-item .item-title {
  font-size: 16px;
}

.post-share.style-5 .social-item a .bf-icon, .post-share.style-5 .social-item a .number, .post-share.style-8 .social-item a .bf-icon, .post-share.style-8 .social-item a .number {
  line-height: 44px;
  font-weight: 400;
}

.post-share.style-5 .social-item, .post-share.style-8 .social-item {
  margin: 0 6px 6px 0;
}

.post-share.style-5 .bs-pretty-tabs-more, .post-share.style-8 .bs-pretty-tabs-more {
  padding: 0 6px !important;
  transform: initial;
  top: 6px;
  min-width: 30px !important;
}

.post-share.style-5 .bs-pretty-tabs-more .bf-icon {
  line-height: 30px;
  font-size: 18px !important;
}

.post-share.style-10 .social-item, .post-share.style-9 .social-item {
  border: 1px solid #e2e2e2;
  border-radius: 3px;
}

.post-share.style-10 .post-share-btn, .post-share.style-11 .post-share-btn, .post-share.style-9 .post-share-btn {
  line-height: 31px;
}

.post-share.style-10 .share-handler, .post-share.style-9 .share-handler {
  padding: 0 10px;
  margin-right: 15px;
}

.post-share.style-10 .social-item a .item-title, .post-share.style-10 .social-item a .number {
  color: inherit;
}

.post-share.style-10 .social-item.has-count.has-title .number {
  border-left: none;
  padding-left: 0;
}

.post-share.style-10 .social-item.has-count.has-title .item-title {
  border-right: none;
}

.post-share.style-10 .post-share-btn, .post-share.style-11 .post-share-btn, .post-share.style-9 .post-share-btn {
  padding: 0 6px;
  line-height: 32px;
  border-radius: 3px;
  min-width: 32px;
  height: 32px;
}

.post-share.style-10 .post-share-btn-group .post-share-btn:first-child, .post-share.style-11 .post-share-btn-group .post-share-btn:first-child, .post-share.style-9 .post-share-btn-group .post-share-btn:first-child {
  border-radius: 0 3px 3px 0;
}

.post-share.style-10 .post-share-btn-group .post-share-btn:last-child, .post-share.style-11 .post-share-btn-group .post-share-btn:last-child, .post-share.style-9 .post-share-btn-group .post-share-btn:last-child {
  border-radius: 3px 0 0 3px;
}

.post-share.style-10 .social-item a, .post-share.style-9 .social-item a {
  background: #fff !important;
  color: #4d4d4d;
}

.post-share.style-10 .social-item.facebook a, .post-share.style-11 .social-item.facebook a, .post-share.style-9 .social-item.facebook a {
  color: #2d5f9a;
}

.post-share.style-10 .social-item.facebook .icon, .post-share.style-11 .social-item.facebook .icon {
  background-color: #2d5f9a;
  border-color: #2d5f9a;
}

.post-share.style-10 .social-item.facebook a:hover, .post-share.style-11 .social-item.facebook a:hover, .post-share.style-9 .social-item.facebook a:hover {
  color: #1b4a82;
}

.post-share.style-10 .social-item.facebook a:hover .icon, .post-share.style-11 .social-item.facebook a:hover .icon {
  background-color: #1b4a82;
  border-color: #1b4a82;
}

.post-share.style-10 .social-item.twitter a, .post-share.style-11 .social-item.twitter a, .post-share.style-9 .social-item.twitter a {
  color: #53c7ff;
}

.post-share.style-10 .social-item.twitter .icon, .post-share.style-11 .social-item.twitter .icon {
  background-color: #53c7ff;
  border-color: #53c7ff;
}

.post-share.style-10 .social-item.twitter a:hover, .post-share.style-11 .social-item.twitter a:hover, .post-share.style-9 .social-item.twitter a:hover {
  color: #369ed0;
}

.post-share.style-10 .social-item.google_plus a, .post-share.style-11 .social-item.google_plus a, .post-share.style-9 .social-item.google_plus a {
  color: #d93b2b;
}

.post-share.style-10 .social-item.google_plus .icon, .post-share.style-11 .social-item.google_plus .icon {
  background-color: #d93b2b;
  border-color: #d93b2b;
}

.post-share.style-10 .social-item.google_plus a:hover, .post-share.style-11 .social-item.google_plus a:hover, .post-share.style-9 .social-item.google_plus a:hover {
  color: #b7291a;
}

.post-share.style-10 .social-item.pinterest a, .post-share.style-11 .social-item.pinterest a, .post-share.style-9 .social-item.pinterest a {
  color: #a41719;
}

.post-share.style-10 .social-item.pinterest .icon, .post-share.style-11 .social-item.pinterest .icon {
  background-color: #a41719;
  border-color: #a41719;
}

.post-share.style-10 .social-item.pinterest a:hover, .post-share.style-11 .social-item.pinterest a:hover, .post-share.style-9 .social-item.pinterest a:hover {
  color: #8c0a0c;
}

.post-share.style-10 .social-item.linkedin a, .post-share.style-11 .social-item.linkedin a, .post-share.style-9 .social-item.linkedin a {
  color: #005182;
}

.post-share.style-10 .social-item.linkedin .icon, .post-share.style-11 .social-item.linkedin .icon {
  background-color: #005182;
  border-color: #005182;
}

.post-share.style-10 .social-item.linkedin a:hover, .post-share.style-11 .social-item.linkedin a:hover, .post-share.style-9 .social-item.linkedin a:hover {
  color: #003b5f;
}

.post-share.style-10 .social-item.email a, .post-share.style-10 .social-item.print a, .post-share.style-11 .social-item.email a, .post-share.style-11 .social-item.print a, .post-share.style-9 .social-item.email a, .post-share.style-9 .social-item.print a {
  color: #444;
}

.post-share.style-10 .social-item.email .icon, .post-share.style-10 .social-item.print .icon, .post-share.style-11 .social-item.email .icon, .post-share.style-11 .social-item.print .icon {
  background-color: #444;
  border-color: #444;
}

.post-share.style-10 .social-item.email a:hover, .post-share.style-10 .social-item.print a:hover, .post-share.style-11 .social-item.email a:hover, .post-share.style-11 .social-item.print a:hover, .post-share.style-9 .social-item.email a:hover, .post-share.style-9 .social-item.print a:hover {
  color: #2f2f2f;
}

.post-share.style-10 .social-item.tumblr a, .post-share.style-11 .social-item.tumblr a, .post-share.style-9 .social-item.tumblr a {
  color: #3e5a70;
}

.post-share.style-10 .social-item.tumblr .icon, .post-share.style-11 .social-item.tumblr .icon {
  background-color: #3e5a70;
  border-color: #3e5a70;
}

.post-share.style-10 .social-item.tumblr a:hover, .post-share.style-11 .social-item.tumblr a:hover, .post-share.style-9 .social-item.tumblr a:hover {
  color: #426283;
}

.post-share.style-10 .social-item.telegram a, .post-share.style-11 .social-item.telegram a, .post-share.style-9 .social-item.telegram a {
  color: #179cde;
}

.post-share.style-10 .social-item.telegram .icon, .post-share.style-11 .social-item.telegram .icon {
  background-color: #179cde;
  border-color: #179cde;
}

.post-share.style-10 .social-item.telegram a:hover, .post-share.style-11 .social-item.telegram a:hover, .post-share.style-9 .social-item.telegram a:hover {
  color: #0f8dde;
}

.post-share.style-10 .social-item.reddit a, .post-share.style-11 .social-item.reddit a, .post-share.style-9 .social-item.reddit a {
  color: #ff4500;
}

.post-share.style-10 .social-item.reddit .icon, .post-share.style-11 .social-item.reddit .icon {
  background-color: #ff4500;
  border-color: #ff4500;
}

.post-share.style-10 .social-item.reddit a:hover, .post-share.style-11 .social-item.reddit a:hover, .post-share.style-9 .social-item.reddit a:hover {
  color: #dc3c01;
}

.post-share.style-10 .social-item.stumbleupon a, .post-share.style-11 .social-item.stumbleupon a, .post-share.style-9 .social-item.stumbleupon a {
  color: #ee4813;
}

.post-share.style-10 .social-item.stumbleupon .icon, .post-share.style-11 .social-item.stumbleupon .icon {
  background-color: #ee4813;
  border-color: #ee4813;
}

.post-share.style-10 .social-item.stumbleupon a:hover, .post-share.style-11 .social-item.stumbleupon a:hover, .post-share.style-9 .social-item.stumbleupon a:hover {
  color: #ce3d0f;
}

.post-share.style-10 .social-item.vk a, .post-share.style-11 .social-item.vk a, .post-share.style-9 .social-item.vk a {
  color: #4c75a3;
}

.post-share.style-10 .social-item.vk .icon, .post-share.style-11 .social-item.vk .icon {
  background-color: #4c75a3;
  border-color: #4c75a3;
}

.post-share.style-10 .social-item.vk a:hover, .post-share.style-11 .social-item.vk a:hover, .post-share.style-9 .social-item.vk a:hover {
  color: #3e5f84;
}

.post-share.style-10 .social-item.digg a, .post-share.style-11 .social-item.digg a, .post-share.style-9 .social-item.digg a {
  color: #000;
}

.post-share.style-10 .social-item.digg .icon, .post-share.style-11 .social-item.digg .icon {
  background-color: #000;
  border-color: #000;
}

.post-share.style-10 .social-item.bs-custom-link a, .post-share.style-11 .social-item.bs-custom-link a, .post-share.style-9 .social-item.bs-custom-link a {
  color: #000;
}

.post-share.style-10 .social-item.bs-custom-link .icon, .post-share.style-11 .social-item.bs-custom-link .icon {
  background-color: #000;
  border-color: #000;
}

.post-share.style-10 .social-item.ok-ru a, .post-share.style-11 .social-item.ok-ru a, .post-share.style-9 .social-item.ok-ru a {
  color: #ee8208;
}

.post-share.style-10 .social-item.ok-ru .icon, .post-share.style-11 .social-item.ok-ru .icon {
  background-color: #ee8208;
  border-color: #ee8208;
}

.post-share.style-10 .social-item.digg a:hover, .post-share.style-11 .social-item.digg a:hover, .post-share.style-9 .social-item.digg a:hover {
  color: #2f2f2f;
}

.post-share.style-10 .social-item.bs-custom-link a:hover, .post-share.style-11 .social-item.bs-custom-link a:hover, .post-share.style-9 .social-item.bs-custom-link a:hover {
  color: #2f2f2f;
}

.post-share.style-10 .social-item.whatsapp a, .post-share.style-11 .social-item.whatsapp a, .post-share.style-9 .social-item.whatsapp a {
  color: #00e676;
}

.post-share.style-10 .social-item.whatsapp .icon, .post-share.style-11 .social-item.whatsapp .icon {
  background-color: #00e676;
  border-color: #00e676;
}

.post-share.style-10 .social-item.whatsapp a:hover, .post-share.style-11 .social-item.whatsapp a:hover, .post-share.style-9 .social-item.whatsapp a:hover {
  color: #00c462;
}

.post-share.style-10 .social-item.line a, .post-share.style-11 .social-item.line a, .post-share.style-9 .social-item.line a {
  color: #00b900;
}

.post-share.style-10 .social-item.line .icon, .post-share.style-11 .social-item.line .icon {
  background-color: #00b900;
  border-color: #00b900;
}

.post-share.style-10 .social-item.line a:hover, .post-share.style-11 .social-item.line a:hover, .post-share.style-9 .social-item.line a:hover {
  color: #009a00;
}

.post-share.style-10 .social-item.print a, .post-share.style-11 .social-item.print a, .post-share.style-9 .social-item.print a {
  color: #444;
}

.post-share.style-10 .social-item.viber a .icon, .post-share.style-11 .social-item.viber a .icon {
  background-color: #5d54a4 !important;
  border-color: #5d54a4 !important;
}

.post-share.style-10 .social-item.viber:hover a .icon, .post-share.style-11 .social-item.viber:hover a .icon {
  background-color: #494589 !important;
  border-color: #494589 !important;
}

.post-share.style-10 .social-item.viber .icon, .post-share.style-11 .social-item.viber .icon {
  font-size: 18px;
}

.post-share.style-10 .social-item.bbm a, .post-share.style-11 .social-item.bbm a, .post-share.style-9 .social-item.bbm a {
  color: #1f1f1f;
}

.post-share.style-10 .social-item.bbm .icon, .post-share.style-11 .social-item.bbm .icon {
  background-color: #1f1f1f;
  border-color: #1f1f1f;
}

.post-share.style-10 .social-item.bbm a:hover, .post-share.style-11 .social-item.bbm a:hover, .post-share.style-9 .social-item.bbm a:hover {
  color: #3c3c3c;
}

.post-share.style-12 .post-share-btn, .post-share.style-12 a, .post-share.style-12 span, .post-share.style-13 .post-share-btn, .post-share.style-13 a, .post-share.style-13 span, .style-23.entry-terms .terms-label, .style-23.entry-terms a, .style-24.entry-terms .terms-label, .style-24.entry-terms a, .style-25.entry-terms .terms-label, .style-25.entry-terms a, .style-26.entry-terms .terms-label, .style-26.entry-terms a {
  border-radius: 0 !important;
}

.post-share.style-13 .post-share-btn, .post-share.style-13 .social-item a {
  padding: 0 10px;
  min-width: 44px;
  min-height: 44px;
  line-height: 44px !important;
}

.post-share.style-13 .post-share-btn > *, .post-share.style-13 .social-item .item-title, .post-share.style-13 .social-item i {
  line-height: 44px !important;
}

.post-share.style-13 .social-item .icon i {
  font-size: 22px;
}

.post-share.style-13 .social-item .item-title {
  font-size: 19px;
}

.post-share.style-13 .bs-pretty-tabs-more, .post-share.style-13 .post-share-btn {
  min-height: 42px;
  line-height: 42px !important;
}

.post-share.style-13 .bs-pretty-tabs-more i, .post-share.style-13 .post-share-btn > * {
  line-height: 42px !important;
}

.post-share .item-title {
  font-weight: 400;
  padding: 0;
}

.post-share .item-title, .post-share .number {
  font-size: 12px;
}

.post-share .post-share-btn-group .item-title, .post-share .post-share-btn-group .number {
  padding: 0;
}

.post-share.style-1 .social-item.has-title a {
  padding: 0 11px;
}

.post-share.style-1 .post-share-btn-group .post-share-btn:first-child, .post-share.style-2 .post-share-btn-group .post-share-btn:first-child {
  border-radius: 0 30px 30px 0;
}

.post-share.style-1 .post-share-btn-group .post-share-btn:last-child, .post-share.style-2 .post-share-btn-group .post-share-btn:last-child {
  border-radius: 30px 0 0 30px;
}

.post-share.style-1 .post-share-btn-group .post-share-btn:last-child:first-child, .post-share.style-2 .post-share-btn-group .post-share-btn:last-child:first-child {
  border-radius: 30px;
}

.post-share.style-1 .bs-pretty-tabs-more, .post-share.style-1 .share-handler, .post-share.style-1 .social-item a, .post-share.style-2 .bs-pretty-tabs-more, .post-share.style-2 .share-handler, .post-share.style-2 .social-item a {
  border-radius: 30px;
}

.post-share.style-1 .bs-pretty-tabs-more {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
}

.post-share.style-2 .bs-pretty-tabs-more {
  min-width: 38px;
  height: 38px;
  line-height: 38px;
}

.post-share .bs-pretty-tabs-more {
  color: #afafaf;
}

.post-share .bs-pretty-tabs-more:hover {
  color: #383838;
}

.single-post-share.top-share.style-1 {
  margin-bottom: 7px;
}

.single-post-share.bottom-share.style-1 {
  margin-bottom: -8px;
}

.single-post-share.top-share.style-2 {
  margin-bottom: 9px;
}

.single-post-share.bottom-share.style-2 {
  margin-bottom: -6px;
}

.post-share.style-1 .share-handler, .post-share.style-2 .share-handler {
  padding: 0 10px;
}

.post-share.style-2 .post-share-btn .bf-icon {
  font-size: 20px;
}

.post-share.style-2 .item-title, .post-share.style-2 .number, .post-share.style-2 .text {
  font-size: 14px;
}

.post-share.style-2 .number {
  margin: 0;
}

.post-share.style-2 .bf-icon, .post-share.style-2 .item-title, .post-share.style-2 .number, .post-share.style-2 .text {
  line-height: 38px !important;
  height: 38px;
}

.post-share.style-2 .item-title {
  margin-left: 10px;
  margin-right: 5px;
  font-weight: 400;
}

.post-share.style-2 .social-item a {
  padding: 0 8px;
  min-width: 38px;
  line-height: 38px;
}

.post-share.style-2 .social-item.has-count a, .post-share.style-2 .social-item.has-title a {
  padding: 0 15px;
}

.post-share.style-2 .social-item {
  margin: 0 6px 6px 0;
}

.post-share.style-2 .bs-pretty-tabs-more .bf-icon, .post-share.style-2 .post-share-btn-group .bf-icon {
  font-size: 15px;
}

.post-share.style-2 .social-item a .item-title + .number {
  margin-left: 0;
}

.single-post-share.top-share.style-3 {
  margin-bottom: 7px;
}

.single-post-share.bottom-share.style-3 {
  margin-bottom: -8px;
}

.post-share.style-3 .bf-icon, .post-share.style-3 .item-title, .post-share.style-3 .number, .post-share.style-3 .text {
  line-height: 32px !important;
}

.post-share.style-3 .post-share-btn, .post-share.style-3 .social-item a {
  line-height: 32px;
  height: 32px;
  min-width: 40px;
}

.post-share.style-3 .post-share-btn {
  line-height: 35px;
  height: 35px;
  padding: 0 8px;
}

.post-share.style-3 .post-share-btn .bf-icon, .post-share.style-3 .post-share-btn .number, .post-share.style-3 .post-share-btn .text {
  line-height: 34px !important;
}

.post-share.style-3 .social-item a {
  padding: 0 8px;
}

.post-share.style-3 .social-item.has-count a, .post-share.style-3 .social-item.has-title a {
  padding: 0 12px;
}

.single-post-share.style-3 .social-item.line .bsfi-line {
  font-size: 20px;
}

.single-post-share.style-3 .social-item.viber .bsfi-viber {
  font-size: 17px;
}

.single-post-share .social-item {
  margin-bottom: 8px;
}

.single-post-share.top-share.style-4 {
  margin-bottom: 7px;
}

.single-post-share.bottom-share.style-4 {
  margin-bottom: -8px;
}

.post-share.style-4 .share-handler.post-share-btn {
  padding: 0 8px 0 5px;
}

.post-share.style-4 .social-item a {
  padding: 0;
}

.post-share.style-4 .social-item .number {
  padding-right: 10px;
  margin-left: 10px;
}

.post-share.style-4 .social-item .item-title {
  padding-right: 9px;
  margin-left: 8px;
}

.post-share.style-4 .social-item .item-title + .number {
  margin-left: -2px;
}

.post-share.style-4 .social-item .bf-icon {
  display: inline-block;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  padding: 0 6px;
  min-width: 30px;
}

.post-share.style-4 .social-item .icon:last-child:first-child .bf-icon {
  background: 0 0;
}

.post-share.style-4 .share-handler .text {
  margin-left: 2px;
  font-size: 13px;
}

.post-share.style-4 .bs-pretty-tabs-more.post-share-btn {
  height: 30px;
}

.single-post-share.top-share.style-5 {
  margin-bottom: 9px;
}

.single-post-share.bottom-share.style-5 {
  margin-bottom: -6px;
}

.post-share.style-5 .post-share-btn, .post-share.style-5 .social-item a {
  padding: 0 10px;
  min-width: 44px;
}

.post-share.style-5 .social-item.has-count a, .post-share.style-5 .social-item.has-title a {
  padding: 0 12px;
}

.post-share.style-5 .social-item a .item-title, .post-share.style-5 .social-item a .number {
  margin: 0 5px 0 10px;
  line-height: 44px;
}

.post-share.style-5 .social-item a .item-title + .number {
  margin: 0 0 0 2px;
}

.post-share.style-5 .share-handler .text {
  font-size: 16px;
}

.post-share.style-5 .post-share-btn-group .bf-icon, .post-share.style-5 .post-share-btn-group .number {
  font-size: 15px !important;
}

.single-post-share.top-share.style-6, .single-post-share.top-share.style-7 {
  margin-bottom: 10px;
}

.single-post-share.bottom-share.style-6, .single-post-share.bottom-share.style-7 {
  margin-bottom: -5px;
}

.post-share.style-6 .social-item, .post-share.style-7 .social-item {
  text-align: center;
  margin: 0 10px 12px 0;
}

.post-share.style-6 .social-item a, .post-share.style-7 .social-item a {
  padding: 0 12px 0;
  line-height: 32px;
  height: 61px;
  min-width: 76px;
}

.post-share.style-6 .post-share-btn .bf-icon, .post-share.style-6 .post-share-btn .item-title, .post-share.style-6 .post-share-btn .number, .post-share.style-6 .social-item .item-title, .post-share.style-6 .social-item .number, .post-share.style-7 .post-share-btn .bf-icon, .post-share.style-7 .post-share-btn .item-title, .post-share.style-7 .post-share-btn .number, .post-share.style-7 .social-item .item-title, .post-share.style-7 .social-item .number {
  line-height: 20px;
  margin: 0 3px;
  font-size: 13px;
}

.post-share.style-6 .post-share-btn .bf-icon, .post-share.style-7 .post-share-btn .bf-icon {
  line-height: 26px;
  margin-top: 7px;
}

.post-share.style-6 .post-share-btn.bs-pretty-tabs-more .bf-icon, .post-share.style-7 .post-share-btn.bs-pretty-tabs-more .bf-icon {
  margin: 0;
}

.post-share.style-6 .social-item .bf-icon, .post-share.style-7 .social-item .bf-icon {
  font-size: 20px;
  display: block;
  line-height: 32px;
  margin-top: 6px;
}

.post-share.style-6 .social-item .icon:first-child:last-child .bf-icon, .post-share.style-7 .social-item .icon:first-child:last-child .bf-icon {
  line-height: 64px;
  margin: 0;
}

.post-share.style-6 .share-handler .bf-icon, .post-share.style-7 .share-handler .bf-icon {
  font-size: 20px;
  line-height: 28px;
  margin-top: 6px;
}

.post-share.style-6 .share-handler .number, .post-share.style-7 .share-handler .number {
  line-height: 26px;
  font-size: 15px;
}

.post-share.style-6 .bs-pretty-tabs-more, .post-share.style-7 .bs-pretty-tabs-more {
  margin-top: 18px;
  min-width: 28px;
}

.post-share.style-6 .share-handler, .post-share.style-7 .share-handler {
  text-align: center;
  padding: 0 14px;
  margin: 0 15px 11px 0;
  min-width: 84px;
  height: 64px;
}

.post-share.style-6 .share-handler .text, .post-share.style-7 .share-handler .text {
  font-weight: 700;
  line-height: 20px;
}

.post-share.style-6 .post-share-btn-group .post-share-btn, .post-share.style-7 .post-share-btn-group .post-share-btn {
  padding: 0 16px;
  text-align: center;
  height: 64px;
}

.post-share.style-7 .social-item a {
  border-radius: 0;
}

.post-share.style-7 .social-item.first a {
  border-radius: 3px 0 0 3px;
}

.post-share.style-7 .social-item.last a, .post-share.style-7 .social-item:last-child a {
  border-radius: 0 3px 3px 0;
}

.post-share.style-7 .social-item {
  margin: 0 0 12px 0;
}

.post-share.style-7 .bs-pretty-tabs-container {
  margin-left: 10px;
}

.single-post-share.top-share.style-8 {
  margin-bottom: 9px;
}

.single-post-share.bottom-share.style-8 {
  margin-bottom: -6px;
}

.post-share.style-8 .social-item .bf-icon {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 90px;
  margin-top: 8px;
  line-height: 30px !important;
  font-size: 16px;
  width: 30px;
  height: 30px;
}

.post-share.style-8 .social-item a {
  padding: 0 10px;
  line-height: 45px;
  min-width: 56px;
}

.post-share.style-8 .share-handler .text, .post-share.style-8 .social-item a .item-title, .post-share.style-8 .social-item a .number {
  margin: 0 6px 0;
  font-size: 14px;
  padding-right: 0;
  line-height: 45px;
}

.post-share.style-8 .social-item .item-title + .number {
  margin: 0 2px 0 -2px;
}

.post-share.style-8 .share-handler .text {
  padding-left: 3px;
  margin: 0;
  line-height: 44px;
  display: inline-block;
  vertical-align: top;
}

.post-share.style-8 .social-item .number {
  text-indent: 2px;
}

.post-share.style-8 .post-share-btn {
  padding: 0 10px;
}

.post-share.style-8 .share-handler {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 12px;
  height: 45px;
}

.post-share.style-8 .number {
  font-size: 14px;
}

.post-share.style-8 .bs-pretty-tabs-more .bf-icon {
  line-height: 30px;
  font-size: 16px !important;
}

.post-share.style-9 .social-item.has-count a, .post-share.style-9 .social-item.has-title a {
  padding: 0 10px;
}

.post-share.style-9 .social-item a {
  padding: 0 5px;
}

.post-share.style-9 .social-item .number {
  margin-left: 5px !important;
}

.post-share.style-9 .share-handler {
  margin-right: 12px;
  border-radius: 3px;
}

.post-share.style-9 .social-item a .bf-icon, .post-share.style-9 .social-item a .item-title, .post-share.style-9 .social-item a .number {
  color: inherit !important;
}

.post-share.style-9 .bs-pretty-tabs-more.post-share-btn {
  padding: 0 6px;
  width: 32px;
  line-height: 32px;
  height: 32px;
  border-radius: 3px;
}

.post-share.style-9 .social-item a .item-title, .post-share.style-9 .social-item a .number {
  margin-left: 8px;
}

.single-post-share.top-share.style-10, .single-post-share.top-share.style-11 {
  margin-bottom: 7px;
}

.single-post-share.bottom-share.style-10, .single-post-share.bottom-share.style-11 {
  margin-bottom: -8px;
}

.post-share.style-10 .social-item a, .post-share.style-11 .social-item a {
  padding: 0 !important;
  height: 32px;
}

.post-share.style-10 .social-item .icon, .post-share.style-10 .social-item a > span, .post-share.style-11 .social-item .icon, .post-share.style-11 .social-item a > span {
  display: inline-block;
  border: 1px solid #e2e2e2;
  border-left-width: 0;
  margin: 0 !important;
  padding: 0 8px;
  text-align: center;
}

.post-share.style-10 .social-item {
  border: none;
  padding: 0 !important;
}

.post-share.style-10 .social-item .icon, .post-share.style-11 .social-item .icon {
  color: #fff;
  display: inline-block;
  border-color: transparent;
  position: relative;
  height: inherit;
  min-width: 32px;
}

.post-share.style-10 .social-item .icon:after, .post-share.style-10 .social-item .icon:before {
  width: 0;
  height: 50%;
  border: 7px solid transparent;
  border-top-width: 0;
  border-right: 7px solid #fff;
  content: " ";
  position: absolute;
  top: 0;
  right: -1px;
  box-shadow: 7px -15px 0 #e2e2e2;
  display: none;
}

.post-share.style-10 .social-item .icon:before {
  bottom: 0;
  top: auto;
  border-top-width: 7px;
  border-bottom-width: 0;
  border-right: 7px solid #fff;
  box-shadow: 7px 15px 0 #e2e2e2;
}

.post-share.style-10 .social-item.has-count .icon:after, .post-share.style-10 .social-item.has-count .icon:before, .post-share.style-10 .social-item.has-title .icon:after, .post-share.style-10 .social-item.has-title .icon:before {
  display: inline-block;
}

.post-share.style-10 .social-item.has-count .icon, .post-share.style-10 .social-item.has-title .icon {
  padding: 0 16px 0 11px;
  min-width: 33px;
}

.post-share.style-10 .social-item .item-title, .post-share.style-10 .social-item .number {
  height: 32px;
}

.post-share.style-11 .social-item .item-title, .post-share.style-11 .social-item .number {
  background: #fff;
  margin: 1px !important;
  height: 30px;
  border-radius: 0 3px 3px 0;
  color: inherit;
  border: none;
  line-height: 30px;
  min-width: 30px;
}

.post-share.style-11 .social-item a .bf-icon {
  line-height: 30px;
}

.post-share.style-11 .social-item .icon, .post-share.style-11 .social-item a > span {
  padding: 0 8px;
}

.post-share.style-11 .post-share-btn {
  padding: 0 6px;
}

.post-share.style-11 .share-handler.post-share-btn {
  margin-right: 14px;
}

.post-share.style-11 .social-item.has-count.has-title .item-title {
  margin: 1px -1px 0 0 !important;
  border-radius: 0 !important;
}

.post-share.style-11 .social-item.has-count.has-title .number {
  padding-left: 0;
  margin-left: -4px !important;
}

.next-prev-post {
  padding: 25px 0 0;
  margin: 30px 0 0;
  position: relative;
  border-top: 1px solid #e8e8e8;
}

.bs-newsletter-pack + .next-prev-post, .post-author + .next-prev-post {
  border-top: none;
  padding: 0 20px;
}

.next-prev-post .prev-post {
  width: 50%;
  float: left;
  padding-right: 15px;
  text-align: left;
}

.next-prev-post .prev-post .pre-title .fa {
  margin-right: 10px;
}

.next-prev-post .next-post {
  width: 50%;
  float: right;
  padding-left: 15px;
  text-align: right;
}

.next-prev-post .next-post .pre-title .fa {
  margin-left: 10px;
}

.next-prev-post .pre-title {
  text-transform: uppercase;
  font-size: 13px;
  color: #8a8a8a;
  margin-bottom: 6px;
}

.next-prev-post .title {
  font-size: 15px;
  margin: 0;
  line-height: 1.4;
}

.post-tp-1-header .post-meta-wrap {
  margin: 15px 0 20px;
  padding: 0;
}

.post-tp-1-header .post-meta-wrap .post-meta {
  padding: 0;
}

.post-tp-1-header .post-meta-wrap .term-badges + .post-meta .post-author-a:last-child .post-author:after {
  display: none;
}

.post-tp-1-header .term-badges {
  float: right;
  margin: 0;
  display: inline;
  line-height: 18px;
}

.post-tp-1-header .term-badges:first-child:last-child {
  float: none;
}

.post-tp-1-header .term-badges a {
  background: #444;
  padding: 0 6px;
  display: inline-block;
  line-height: 18px;
  font-size: 10px;
  color: #fff;
  margin: 0 0 0 4px;
}

.post-tp-1-header .term-badges .term-badge:first-child a {
  margin: 0;
}

.post-tp-1-header .single-post-title {
  margin: 0 0 15px;
}

.post-tp-1-header .single-post-title + .post-subtitle {
  margin-top: -5px;
}

.post-tp-1-header .single-post-excerpt {
  margin-top: -8px;
  padding-bottom: 10px;
}

.post-tp-1-header .post-subtitle + .single-post-excerpt {
  padding: 10px 0;
  margin: 0;
}

.post-tp-1-header .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

@media only screen and (max-width: 420px) {
  .post-tp-1-header .term-badges {
    float: none;
    margin-bottom: 10px;
    display: block;
  }
  .post-tp-1-header .post-meta-wrap {
    margin: 10px 0 12px;
    padding: 0;
  }
}
.post-tp-2-header {
  position: relative;
  margin: 0 0 25px;
}

.post-tp-2-header.wfi {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
}

.post-tp-2-header.wfi:before {
  background: rgba(0, 0, 0, 0.35);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.post-tp-2-header .post-header-inner {
  padding: 30px 0 0;
  position: relative;
}

.post-tp-2-header.wfi .post-header-inner {
  padding: 30px 30px;
  height: 560px;
  overflow: hidden;
}

.post-tp-2-header.wfi .post-header-title {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}

.post-tp-2-header.nfi .post-header-title {
  margin-top: 20px;
}

.post-tp-2-header.wfi .single-post-title {
  color: #fff;
}

.post-tp-2-header.nfi .single-post-title {
  margin: 10px 0;
}

.post-tp-2-header .single-post-title + .post-subtitle {
  margin: 0 0 15px;
}

.post-tp-2-header.wfi .single-post-title + .post-subtitle {
  color: #fff;
}

.post-tp-2-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 10px;
}

.post-tp-2-header.wfi .post-meta, .post-tp-2-header.wfi .post-meta .post-author-a:hover, .post-tp-2-header.wfi .post-meta .views, .post-tp-2-header.wfi .post-meta a, .post-tp-2-header.wfi .post-meta a:hover, .post-tp-2-header.wfi .post-meta b {
  color: #fff !important;
}

.post-tp-2-header.wfi .post-meta.single-post-meta .post-author-a, .post-tp-2-header.wfi .post-meta.single-post-meta .post-author-a:hover, .post-tp-2-header.wfi .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-2-header.wfi .post-meta.single-post-meta > a + span:before, .post-tp-2-header.wfi .post-meta.single-post-meta > span + a:before, .post-tp-2-header.wfi .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-2-header.wfi .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-2-header .image-credit {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 9px;
}

.post-tp-2-header.wfi .image-credit {
  color: rgba(255, 255, 255, 0.7);
}

.post-tp-2-header.wfi .image-credit:hover {
  color: #fff;
}

.post-template-2 .post-embed {
  margin-bottom: 20px;
}

.post-tp-2-header .bf-breadcrumb {
  position: absolute;
  left: 0;
  top: 15px;
}

.post-tp-2-header .single-post-excerpt {
  padding-bottom: 12px;
}

.post-tp-2-header.wfi .single-post-excerpt {
  color: #fff;
}

.post-tp-2-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

.post-tp-2-header.nfi .bf-breadcrumb .bf-breadcrumb-item.bf-breadcrumb-end {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .post-tp-2-header.wfi .post-header-inner {
    height: 450px;
  }
}
@media only screen and (max-width: 680px) {
  .post-tp-2-header.wfi .post-header-inner {
    height: 420px;
  }
}
.post-tp-3-header {
  position: relative;
  margin-bottom: 25px;
}

.post-tp-3-header.wfi {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
}

.post-tp-3-header.wfi:before {
  background: rgba(0, 0, 0, 0.35);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.post-tp-3-header.wfi .post-header-inner {
  padding: 30px 30px;
  height: 560px;
  position: relative;
  overflow: hidden;
}

.post-tp-3-header.wfi .post-header-title {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
}

.post-tp-3-header.wfi .single-post-title {
  color: #fff;
}

.post-tp-3-header.nfi .single-post-title {
  margin: 10px 0 12px;
}

.post-tp-3-header .single-post-title + .post-subtitle {
  margin: 0 0 15px;
}

.post-tp-3-header.wfi .single-post-title + .post-subtitle {
  color: #fff;
}

.post-tp-3-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 10px;
}

.post-tp-3-header.wfi .post-meta, .post-tp-3-header.wfi .post-meta .post-author-a:hover, .post-tp-3-header.wfi .post-meta .views, .post-tp-3-header.wfi .post-meta a, .post-tp-3-header.wfi .post-meta a:hover, .post-tp-3-header.wfi .post-meta b {
  color: #fff !important;
}

.post-tp-3-header.wfi .post-meta.single-post-meta .post-author-a, .post-tp-3-header.wfi .post-meta.single-post-meta .post-author-a:hover, .post-tp-3-header.wfi .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-3-header.wfi .post-meta.single-post-meta > a + span:before, .post-tp-3-header.wfi .post-meta.single-post-meta > span + a:before, .post-tp-3-header.wfi .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-3-header.wfi .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-3-header .image-credit {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 9px;
}

.post-tp-3-header.wfi .image-credit {
  color: rgba(255, 255, 255, 0.7);
}

.post-tp-3-header.wfi .image-credit:hover {
  color: #fff;
}

.post-template-3 .post-embed {
  margin-bottom: 20px;
}

.post-tp-3-header .single-post-excerpt {
  color: #fff;
  padding-bottom: 12px;
}

.post-tp-3-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

.post-tp-3-header.nfi .bf-breadcrumb .bf-breadcrumb-item.bf-breadcrumb-end {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .post-tp-3-header.wfi .post-header-inner {
    height: 450px;
  }
}
@media only screen and (max-width: 780px) {
  .post-tp-3-header.wfi .post-header-inner {
    padding: 20px;
  }
  .post-tp-3-header.wfi .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  .post-tp-3-header .image-credit {
    bottom: 5px;
  }
  .post-tp-3-header .bf-breadcrumb {
    margin-top: 0;
  }
}
@media only screen and (max-width: 680px) {
  .post-tp-3-header.wfi .post-header-inner {
    height: 420px;
  }
}
.post-tp-4-header {
  position: relative;
  margin: 0 0 25px;
}

.post-tp-4-header.wfi {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
}

.post-tp-4-header.wfi:before {
  background: rgba(0, 0, 0, 0.25);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.post-tp-4-header.nfi .post-header-inner {
  padding: 18px 10px 10px;
  position: relative;
}

.post-tp-4-header.wfi .post-header-inner {
  padding: 18px 30px 30px;
  height: 580px;
  position: relative;
  overflow: hidden;
}

.post-tp-4-header.wfi .post-header-title {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.39);
}

.post-tp-4-header.nfi .post-header-title {
  margin-top: 60px;
  position: relative;
  opacity: 1 !important;
}

.post-tp-4-header .single-post-title {
  text-align: center;
}

.post-tp-4-header.wfi .single-post-title {
  color: #fff;
}

.post-tp-4-header .single-post-title + .post-subtitle {
  margin: 0 0 15px;
  text-align: center;
}

.post-tp-4-header.wfi .single-post-title + .post-subtitle {
  color: #fff;
}

.post-tp-4-header .term-badges.floated {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: auto;
  text-align: center;
}

.post-tp-4-header.wfi .term-badges.floated .term-badge a, .post-tp-4-header.wfi .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-4-header .post-meta a, .post-tp-4-header.wfi .post-meta, .post-tp-4-header.wfi .post-meta .post-author-a:hover, .post-tp-4-header.wfi .post-meta .views, .post-tp-4-header.wfi .post-meta a:hover, .post-tp-4-header.wfi .post-meta b {
  color: #fff !important;
}

.post-tp-4-header.wfi .post-meta.single-post-meta .post-author-a, .post-tp-4-header.wfi .post-meta.single-post-meta .post-author-a:hover, .post-tp-4-header.wfi .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-4-header.wfi .post-meta.single-post-meta > a + span:before, .post-tp-4-header.wfi .post-meta.single-post-meta > span + a:before, .post-tp-4-header.wfi .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-4-header.wfi .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-4-header .post-meta {
  text-align: center;
}

.post-tp-4-header .image-credit {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  padding: 0 8px;
}

.post-tp-4-header .move-to-content {
  display: block;
  text-align: center;
  font-size: 36px;
}

.post-tp-4-header.wfi .move-to-content {
  color: #fff;
}

.post-tp-4-header .move-to-content .fa {
  cursor: pointer;
}

.post-template-4 .post-embed {
  margin-bottom: 20px;
}

.post-tp-4-header.show-cat-badges.show-breadcrumb .term-badges.floated {
  top: 55px;
}

.post-tp-4-header .single-post-excerpt {
  color: #fff;
  padding-bottom: 12px;
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.post-tp-4-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

.post-tp-4-header .bf-breadcrumb.bc-light-color .bf-breadcrumb-item.bf-breadcrumb-end {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .post-tp-4-header.wfi .post-header-inner {
    height: 500px;
  }
}
@media only screen and (max-width: 680px) {
  .post-tp-4-header.wfi .post-header-inner {
    height: 430px;
  }
}
.post-tp-5-header {
  position: relative;
  margin-bottom: 25px;
}

.post-tp-5-header.wfi {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
}

.post-tp-5-header.wfi:before {
  background: rgba(0, 0, 0, 0.25);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.post-tp-5-header.nfi .post-header-inner {
  padding-top: 30px;
  position: relative;
  opacity: 1 !important;
  margin-top: 5px;
}

.post-tp-5-header.wfi .post-header-inner {
  padding: 20px 30px 30px;
  height: 580px;
  position: relative;
  overflow: hidden;
}

.post-tp-5-header.wfi .post-header-title {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.39);
}

.post-tp-5-header.nfi .post-header-title {
  opacity: 1 !important;
  position: relative;
}

.post-tp-5-header .single-post-title {
  text-align: center;
  padding: 0 25px;
}

.post-tp-5-header.nfi .single-post-title {
  margin: 12px 0;
}

.post-tp-5-header.wfi .single-post-title {
  color: #fff;
  text-align: center;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.39);
  padding: 0 25px;
}

.post-tp-5-header .single-post-title + .post-subtitle {
  margin: 0 0 15px;
}

.post-tp-5-header.wfi .single-post-title + .post-subtitle {
  color: #fff;
  text-align: center;
}

.post-tp-5-header .term-badges.floated {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  text-align: center;
}

.post-tp-5-header.nfi .term-badges.floated {
  top: 0;
}

.post-tp-5-header.wfi .term-badges.floated .term-badge a, .post-tp-5-header.wfi .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-5-header.wfi .post-meta, .post-tp-5-header.wfi .post-meta .post-author-a:hover, .post-tp-5-header.wfi .post-meta .views, .post-tp-5-header.wfi .post-meta a, .post-tp-5-header.wfi .post-meta a:hover, .post-tp-5-header.wfi .post-meta b {
  color: #fff !important;
}

.post-tp-5-header.wfi .post-meta.single-post-meta .post-author-a, .post-tp-5-header.wfi .post-meta.single-post-meta .post-author-a:hover, .post-tp-5-header.wfi .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-5-header.wfi .post-meta.single-post-meta > a + span:before, .post-tp-5-header.wfi .post-meta.single-post-meta > span + a:before, .post-tp-5-header.wfi .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-5-header.wfi .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-5-header .post-meta {
  text-align: center;
}

.post-tp-5-header .image-credit {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  padding: 0 8px;
}

.post-tp-5-header .move-to-content {
  display: block;
  text-align: center;
  font-size: 36px;
}

.post-tp-5-header.wfi .move-to-content {
  color: #fff;
}

.post-tp-5-header .move-to-content .fa {
  cursor: pointer;
}

.post-template-5 .post-embed {
  margin-bottom: 20px;
}

.post-tp-5-header .single-post-excerpt {
  padding-bottom: 12px;
  text-align: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.post-tp-5-header.wfi .single-post-excerpt {
  color: #fff;
}

.post-tp-5-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

@media only screen and (max-width: 1080px) {
  .post-tp-5-header.wfi .post-header-inner {
    height: 450px;
  }
}
@media only screen and (max-width: 780px) {
  .post-tp-5-header.wfi .post-header-inner {
    padding: 20px 20px;
  }
  .post-tp-5-header.wfi .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}
.post-tp-6-header {
  position: relative;
  margin-bottom: 25px;
}

.post-tp-6-header.wfi {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
  height: 550px;
}

.post-template-6 .post-header-inner {
  position: relative;
  overflow: hidden;
  padding: 10px 0 20px;
}

.post-template-6.wfi .post-header-inner {
  padding: 20px 20px 20px 0;
  background: #fff;
  margin-top: -150px;
}

.layout-2-col.layout-left-sidebar.post-template-6.wfi .post-header-inner, .layout-3-col.layout-3-col-3.post-template-6.wfi .post-header-inner, .layout-3-col.layout-3-col-4.post-template-6.wfi .post-header-inner, .layout-3-col.layout-3-col-5.post-template-6.wfi .post-header-inner, .layout-3-col.layout-3-col-6.post-template-6.wfi .post-header-inner {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.post-template-6.post-template-6-no-term.wfi .post-header-inner {
  padding-top: 33px;
  padding-bottom: 30px;
}

.post-template-6.post-template-6-no-meta.wfi .post-header-inner {
  padding-top: 25px;
  padding-bottom: 20px;
}

.post-template-6.post-template-6-no-meta.post-template-6-no-term.wfi .post-header-inner {
  padding-top: 35px;
  padding-bottom: 20px;
  margin-top: -120px;
}

.post-template-6 .single-post-title {
  margin: 0 0 15px;
}

.post-template-6 .single-post-title + .post-subtitle {
  margin: -5px 0 15px;
}

.post-template-6 .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 11px;
}

.post-tp-6-header.wfi .term-badges.floated .term-badge a, .post-tp-6-header.wfi .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-6-header.wfi .post-meta, .post-tp-6-header.wfi .post-meta .post-author, .post-tp-6-header.wfi .post-meta a {
  color: #fff;
}

.post-tp-6-header .post-meta {
  text-align: center;
}

.post-tp-6-header .image-credit {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  padding: 0 8px;
}

.post-tp-6-header.wfi .image-credit:hover {
  background: rgba(0, 0, 0, 0.35);
}

.post-template-6 .post-embed {
  margin-bottom: 20px;
}

.post-template-6 .post-header-inner .single-post-excerpt {
  margin-top: -8px;
  padding-bottom: 10px;
}

.post-template-6 .post-header-inner .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-template-6 .post-header-inner .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

@media only screen and (max-width: 1080px) {
  .post-tp-6-header.wfi {
    height: 450px;
  }
}
@media only screen and (max-width: 780px) {
  .post-tp-6-header.wfi {
    height: 350px;
  }
  .post-template-6.wfi .post-header-inner {
    margin-top: 0;
    padding-top: 0;
  }
  .post-tp-6-header.wfi .post-header-inner {
    padding: 20px 20px;
  }
  .post-tp-6-header.wfi .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}
.post-tp-7-header {
  position: relative;
  margin: 0;
}

.post-tp-7-header.wfi {
  background-color: #565656;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.post-tp-7-header.wfi:before {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.55)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 380px;
}

.post-tp-7-header.wfi .post-header-inner {
  padding: 30px 30px 0;
  height: 580px;
  position: relative;
}

.post-tp-7-header.nfi .post-header-inner {
  margin: 5px 0 0;
  position: relative;
}

.post-tp-7-header.wfi .post-header-title {
  position: absolute;
  bottom: 0;
  left: -25px;
  right: -25px;
  background: #fff;
  padding: 25px 25px 0;
}

.post-tp-7-header .post-header-title .single-post-title {
  margin-top: 0;
}

.post-tp-7-header .single-post-title + .post-subtitle {
  margin: -2px 0 13px;
}

.post-tp-7-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 15px;
}

.post-tp-7-header .image-credit {
  position: absolute;
  right: 0;
  top: -21px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  padding: 0 8px;
  overflow: hidden;
  white-space: nowrap;
}

.post-tp-7-header.wfi .image-credit {
  color: #fff;
}

.post-tp-7-header.nfi .image-credit {
  display: none;
}

.post-template-7 .post-embed {
  margin-bottom: 20px;
}

.post-tp-7-header .bf-breadcrumb {
  margin-bottom: 10px;
}

.post-tp-7-header .single-post-excerpt {
  padding-bottom: 10px;
}

.post-tp-7-header .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-tp-7-header .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

@media only screen and (max-width: 1366px) {
  .post-tp-7-header.wfi .post-header-inner {
    height: 500px;
  }
}
@media only screen and (max-width: 1080px) {
  .post-tp-7-header.wfi .post-header-inner {
    height: 400px;
  }
}
@media only screen and (max-width: 980px) {
  .post-tp-7-header .post-header-title {
    left: 0;
    right: 0;
  }
}
.post-tp-8-header {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  background-color: #565656;
  height: 350px;
  float: left;
  width: 40%;
  margin: 0 30px 25px 0;
}

.post-template-8 .post-header-inner {
  padding: 0;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
}

.post-template-8 .single-post-title {
  margin: 0 0 13px;
}

.post-template-8 .single-post-title + .post-subtitle {
  margin: -7px 0 15px;
}

.post-template-8 .single-post-title:last-child {
  margin-bottom: 0;
}

.post-template-8 .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 15px;
}

.post-tp-8-header .term-badges.floated .term-badge a, .post-tp-8-header .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-8-header .post-meta, .post-tp-8-header .post-meta .post-author, .post-tp-8-header .post-meta a {
  color: #fff;
}

.post-tp-8-header .post-meta {
  text-align: center;
}

.post-tp-8-header .image-credit {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 8px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  font-size: 10px;
  color: #fff;
}

.post-template-8 .single-post-excerpt {
  margin-top: -5px;
  padding-bottom: 10px;
}

.post-template-8 .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-template-8 .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

@media only screen and (max-width: 780px) {
  .post-tp-8-header {
    width: 100%;
    margin-right: 0;
  }
  .post-template-8 .post-header-inner {
    margin-top: 0;
    padding-top: 0;
  }
  .post-tp-8-header .post-header-inner {
    padding: 20px 20px;
  }
  .post-tp-8-header .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
}
.post-template-9 .post-header-inner {
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.post-template-9 .single-post-title {
  margin: 0 0 12px;
}

.post-template-9 .single-post-title + .post-subtitle {
  margin: -7px 0 15px;
}

.post-template-9 .single-post-title:last-child {
  margin-bottom: 0;
}

.post-template-9 .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 15px;
}

.post-tp-9-header .term-badges.floated .term-badge a, .post-tp-9-header .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-9-header .post-meta, .post-tp-9-header .post-meta .post-author, .post-tp-9-header .post-meta a {
  color: #fff;
}

.post-template-9 .post-header-inner .single-post-excerpt {
  margin-top: -5px;
  padding-bottom: 10px;
}

.post-template-9 .post-header-inner .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-template-9 .post-header-inner .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

.post-tp-10-header {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  background-color: #565656;
  height: 350px;
  float: left;
  width: 40%;
  margin: 0 30px 25px 0;
}

.post-template-10 .post-header-inner {
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.post-template-10 .single-featured:empty {
  display: none;
}

.post-template-10 .single-post-title {
  margin: 0 0 13px;
}

.post-template-10 .single-post-title + .post-subtitle {
  margin: -5px 0 13px;
}

.post-template-10 .single-post-title:last-child {
  margin-bottom: 0;
}

.post-template-10 .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 15px;
}

.post-tp-10-header .term-badges.floated .term-badge a, .post-tp-10-header .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-10-header .post-meta, .post-tp-10-header .post-meta .post-author, .post-tp-10-header .post-meta a {
  color: #fff;
}

.post-tp-10-header .post-meta {
  text-align: center;
}

.post-template-10 .post-header-inner .single-post-excerpt {
  margin-top: -5px;
  padding-bottom: 10px;
}

.post-template-10 .post-header-inner .single-post-excerpt:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.post-template-10 .post-header-inner .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-template-10 .post-header-inner .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

@media only screen and (max-width: 780px) {
  .post-tp-10-header {
    width: 100%;
    margin-right: 0;
  }
  .post-template-10 .post-header-inner {
    margin-top: 0;
    padding-top: 0;
  }
  .post-tp-10-header .post-header-inner {
    padding: 20px;
  }
  .post-tp-10-header .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  .post-tp-10-header .image-credit {
    bottom: 5px;
  }
}
.post-tp-11-header {
  position: relative;
  margin-bottom: 15px;
}

.post-tp-11-header.wfi {
  margin-bottom: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #565656;
}

.post-tp-11-header.wfi:before {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.55)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.55) 100%);
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 380px;
}

.post-tp-11-header.wfi .post-header-inner {
  height: 470px;
  position: relative;
  overflow: hidden;
}

.post-tp-11-header.wfi .post-header-title {
  position: absolute;
  bottom: 25px;
  left: 25px;
  right: 25px;
}

.post-tp-11-header .single-post-title {
  margin: 0 0 10px;
}

.post-tp-11-header.wfi .single-post-title {
  color: #fff;
}

.post-tp-11-header .single-post-title + .post-subtitle {
  margin: 0 0 13px;
}

.post-tp-11-header.wfi .single-post-title + .post-subtitle {
  color: #fff;
}

.post-tp-11-header .single-post-title:last-child {
  margin-bottom: 0;
}

.post-tp-11-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 15px;
}

.post-tp-11-header.wfi .post-meta, .post-tp-11-header.wfi .post-meta .post-author-a:hover, .post-tp-11-header.wfi .post-meta .views, .post-tp-11-header.wfi .post-meta a, .post-tp-11-header.wfi .post-meta a:hover, .post-tp-11-header.wfi .post-meta b {
  color: #fff !important;
}

.post-tp-11-header.wfi .post-meta.single-post-meta .post-author-a, .post-tp-11-header.wfi .post-meta.single-post-meta .post-author-a:hover, .post-tp-11-header.wfi .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-11-header.wfi .post-meta.single-post-meta > a + span:before, .post-tp-11-header.wfi .post-meta.single-post-meta > span + a:before, .post-tp-11-header.wfi .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-11-header.wfi .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-11-header .image-credit {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 9px;
  overflow: hidden;
  white-space: nowrap;
}

.post-tp-11-header.wfi .image-credit {
  color: rgba(255, 255, 255, 0.7);
}

.post-tp-11-header.wfi .image-credit:hover {
  color: #fff;
}

.post-template-11 .post-embed {
  margin-bottom: 20px;
}

.post-template-11 .bf-breadcrumb {
  margin: -7px 0 9px;
}

.post-tp-11-header .single-post-excerpt {
  color: #fff;
  padding-bottom: 12px;
}

.post-tp-11-header .single-post-excerpt:last-child {
  padding-bottom: 0 !important;
}

.post-tp-11-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

@media only screen and (max-width: 1080px) {
  .post-tp-11-header.wfi .post-header-inner {
    height: 450px;
  }
}
@media only screen and (max-width: 780px) {
  .post-tp-11-header.wfi .post-header-inner {
    height: 380px;
  }
  .post-tp-11-header.wfi .post-header-inner {
    padding: 20px 20px;
  }
  .post-tp-11-header.wfi .post-header-title {
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  .post-tp-11-header .image-credit {
    bottom: 5px;
  }
}
.post-tp-12-header {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  background-color: #3c3c3c;
  margin: 0 0 25px;
}

.post-tp-12-header .single-featured {
  margin: 20px auto 0;
  max-width: 900px;
  text-align: center;
}

.post-tp-12-header .single-featured > * {
  margin-left: auto;
  margin-right: auto;
}

.post-tp-12-header .post-header-inner {
  padding: 40px 25px 60px;
  position: relative;
}

.post-tp-12-header .single-post-title {
  color: #fff;
  text-align: center;
  margin: 0 0 10px;
}

.post-tp-12-header .single-post-title + .post-subtitle {
  margin: 0 0 13px;
  color: #c7c7c7;
  text-align: center;
}

.post-tp-12-header .single-post-title:last-child {
  margin-bottom: 30px;
}

.post-tp-12-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  text-align: center;
  margin-bottom: 20px;
}

.post-tp-12-header .term-badges.floated .term-badge a, .post-tp-12-header .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-12-header .post-meta, .post-tp-12-header .post-meta .post-author-a:hover, .post-tp-12-header .post-meta .views, .post-tp-12-header .post-meta a, .post-tp-12-header .post-meta a:hover, .post-tp-12-header .post-meta b {
  color: #fff !important;
}

.post-tp-12-header .post-meta.single-post-meta .post-author-a, .post-tp-12-header .post-meta.single-post-meta .post-author-a:hover, .post-tp-12-header .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-12-header .post-meta.single-post-meta > a + span:before, .post-tp-12-header .post-meta.single-post-meta > span + a:before, .post-tp-12-header .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-12-header .single-post-share {
  margin: 25px 0 -20px !important;
  text-align: center;
}

.post-tp-12-header .single-post-share li {
  margin: 0 3px;
}

.post-tp-12-header .single-post-share .share-handler-wrap {
  max-width: 260px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.post-tp-12-header .single-post-share .post-share-btn {
  background: #3c3c3c;
  border: 1px solid #5d5d5d;
  color: #e8e8e8;
}

.post-tp-12-header .single-post-share .social-item.email a {
  background-color: #6b6b6b;
}

.post-tp-12-header .post-share .share-handler:after, .post-tp-12-header .post-share .share-handler:before {
  border-left-color: #5d5d5d;
}

.post-tp-12-header .post-share .share-handler:after {
  border-left-color: #3c3c3c;
}

.post-tp-12-header .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-12-header .post-meta {
  text-align: center;
}

.post-tp-12-header .image-credit {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 10px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 9px;
  text-align: center;
}

.post-tp-12-header .image-credit:hover {
  color: #fff;
}

.post-tp-12-header .bf-breadcrumb {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 10px;
  opacity: 0.6;
}

.post-tp-12-header .bf-breadcrumb .bf-breadcrumb-item {
  font-size: 11px;
}

.post-tp-12-header .single-post-excerpt {
  color: #fff;
  text-align: center;
}

@media only screen and (max-width: 780px) {
  .post-tp-12-header .post-header-inner {
    padding-left: 0;
    padding-right: 0;
  }
}
.post-template-13 .single-container {
  padding-right: 40px;
  padding-left: 40px;
}

.post-tp-13-header {
  padding: 0;
}

.post-tp-13-header .post-meta-wrap {
  margin: 15px 0;
  padding: 0;
  text-align: center;
}

.post-tp-13-header .post-meta-wrap .post-meta {
  padding: 0;
}

.post-tp-13-header .single-featured {
  margin: 0 -40px 20px;
}

.post-tp-13-header .term-badges {
  position: relative;
  text-align: center;
  top: auto;
  left: auto;
  right: auto;
  margin-bottom: 15px;
}

.post-tp-13-header .single-post-title {
  margin: 0 0 15px;
  text-align: center;
}

.post-tp-13-header .single-post-title + .post-subtitle {
  margin: -1px 0 15px;
  text-align: center;
}

.post-tp-13-header .single-post-title:first-child {
  margin-top: 15px;
}

.post-tp-13-header .single-post-title + .post-share, .post-tp-13-header .single-post-title + .single-featured {
  margin-top: 25px;
}

.post-tp-13-header .single-post-share {
  margin: 15px 0 10px;
  text-align: center;
}

.post-tp-13-header .single-post-share .share-handler-wrap {
  display: inline-block;
}

.post-template-13 .comments-template, body.single .post-template-13 .content-column > .bs-newsletter-pack {
  margin-right: 40px;
  margin-left: 40px;
}

.post-template-13 .ajax-post-content .comments-template.comments-template {
  margin-right: 0;
  margin-left: 0;
}

.post-template-13 .post-related {
  margin-right: 40px;
  margin-left: 40px;
}

.post-template-13 .post-embed {
  margin-bottom: 20px;
}

.post-tp-13-header .bf-breadcrumb {
  margin: -5px 0 12px;
}

.post-tp-13-header .single-post-excerpt {
  text-align: center;
  padding: 0 0 20px;
}

.post-tp-13-header .post-subtitle + .single-post-excerpt {
  margin-top: -5px;
}

.post-tp-13-header .single-post-excerpt + .post-meta-wrap {
  margin-top: -7px;
}

@media only screen and (max-width: 780px) {
  .post-template-13 .single-container {
    padding-right: 0;
    padding-left: 0;
  }
  .post-tp-13-header .single-featured {
    margin: 0 0 20px;
  }
  .post-template-13 .comments-template, .post-template-13 .post-related {
    margin-right: 0;
    margin-left: 0;
  }
}
.post-tp-14-header {
  position: relative;
  margin-bottom: 25px;
}

.post-template-14 .post-header-inner {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0 0 25px;
}

.post-template-14 .single-post-title {
  margin: 0 0 15px;
}

.post-template-14 .single-post-title + .post-subtitle {
  margin: -5px 0 15px;
}

.post-template-14 .post-header-inner .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin-bottom: 11px;
}

.post-tp-14-header .image-credit {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 10px;
  line-height: 20px;
  background: rgba(0, 0, 0, 0.24);
  padding: 0 8px;
}

.post-tp-14-header.wfi .image-credit:hover {
  background: rgba(0, 0, 0, 0.35);
}

.post-template-14 .post-embed {
  margin-bottom: 20px;
}

.post-template-14 .post-header-inner .single-post-excerpt {
  margin-top: -8px;
  padding-bottom: 10px;
}

.post-template-14 .post-header-inner .post-subtitle + .single-post-excerpt {
  padding: 0 0 10px;
  margin: -5px 0 0;
}

.post-template-14 .post-header-inner .single-post-excerpt + .post-meta-wrap {
  margin-top: 2px;
}

.post-tp-15-header {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  background-color: #3c3c3c;
  margin: 0 0 25px;
}

.post-tp-15-header .single-post-share {
  margin: 25px 0 -20px !important;
  text-align: center;
}

.post-tp-15-header .single-post-share li {
  margin: 0 3px;
  float: none;
  display: inline-block;
}

.post-tp-15-header .single-featured {
  margin: 20px auto 0;
  max-width: 900px;
  text-align: center;
}

.post-tp-15-header .single-featured > * {
  margin-left: auto;
  margin-right: auto;
}

.post-tp-15-header .post-header-inner {
  padding: 40px 25px 50px;
  position: relative;
}

.post-tp-15-header .single-post-title {
  color: #fff;
  text-align: center;
  margin: 0 0 10px;
}

.post-tp-15-header .single-post-title + .post-subtitle {
  margin: 0 0 13px;
  color: #c7c7c7;
  text-align: center;
}

.post-tp-15-header .single-post-title:last-child {
  margin-bottom: 30px;
}

.post-tp-15-header .term-badges.floated {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  text-align: center;
  margin-bottom: 20px;
}

.post-tp-15-header .term-badges.floated .term-badge a, .post-tp-15-header .term-badges.floated a {
  background: #fff !important;
  color: #444 !important;
}

.post-tp-15-header .post-meta, .post-tp-15-header .post-meta .post-author-a:hover, .post-tp-15-header .post-meta .views, .post-tp-15-header .post-meta a, .post-tp-15-header .post-meta a:hover, .post-tp-15-header .post-meta b {
  color: #fff !important;
}

.post-tp-15-header .post-meta.single-post-meta .post-author-a, .post-tp-15-header .post-meta.single-post-meta .post-author-a:hover, .post-tp-15-header .post-meta.single-post-meta .time {
  color: rgba(255, 255, 255, 0.55) !important;
}

.post-tp-15-header .post-meta.single-post-meta > a + span:before, .post-tp-15-header .post-meta.single-post-meta > span + a:before, .post-tp-15-header .post-meta.single-post-meta > span + span:before {
  background: rgba(255, 255, 255, 0.43);
}

.post-tp-15-header .single-post-share .share-handler-wrap {
  display: inline-block;
}

.post-tp-15-header .single-post-share .post-share-btn {
  background: #3c3c3c;
  border: 1px solid #5d5d5d;
  color: #e8e8e8;
}

.post-tp-15-header .single-post-share .social-item.email a {
  background-color: #6b6b6b;
}

.post-tp-15-header .post-meta.single-post-meta .post-author-a.post-author-avatar .avatar {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
}

.post-tp-15-header .post-meta {
  text-align: center;
}

.post-tp-15-header .image-credit {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 10px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 9px;
  text-align: center;
}

.post-tp-15-header .image-credit:hover {
  color: #fff;
}

.post-tp-15-header .bf-breadcrumb {
  margin: -15px 0 15px;
}

.post-tp-15-header .single-post-excerpt {
  color: #fff;
  text-align: center;
}

.post-tp-15-header .post-share .share-handler:after, .post-tp-15-header .post-share .share-handler:before {
  border-left-color: #5d5d5d;
}

.post-tp-15-header .post-share .share-handler:after {
  border-left-color: #3c3c3c;
}

.site-footer {
  background-color: #434343;
}

.site-footer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
}

.footer-instagram.boxed, .site-footer.boxed {
  margin-left: auto;
  margin-right: auto;
}

.footer-instagram.full-width.stretched .content-wrap, .site-footer.full-width.stretched .container, .site-footer.full-width.stretched .content-wrap {
  max-width: 100% !important;
}

.footer-widgets {
  padding: 45px 0 35px;
}

.footer-widgets .widget .btn-bs-pagination:hover {
  color: #fff !important;
}

.footer-widgets.light-text .widget.widget_nav_menu ul.menu li a {
  background-color: rgba(0, 0, 0, 0.08);
}

.footer-widgets.light-text .bs-popular-categories .bs-popular-term-item .term-count, .footer-widgets.light-text .widget.widget_archive .post-count, .footer-widgets.light-text .widget.widget_calendar table caption, .footer-widgets.light-text .widget.widget_calendar table td.active-day, .footer-widgets.light-text .widget.widget_calendar table tfoot td, .footer-widgets.light-text .widget.widget_calendar table th, .footer-widgets.light-text .widget.widget_categories .post-count, .footer-widgets.light-text .widget.widget_tag_cloud .tagcloud a {
  background: rgba(255, 255, 255, 0.26);
  color: #fff;
}

.footer-widgets.light-text .bs-popular-categories .bs-popular-term-item, .footer-widgets.light-text .widget.widget_categories ul li, .footer-widgets.light-text .widget.widget_categories ul.children, .footer-widgets.light-text .widget.widget_nav_menu ul.menu .sub-menu > li:last-child a, .footer-widgets.light-text .widget.widget_nav_menu ul.menu li a, .footer-widgets.light-text .widget.widget_pages ul li, .footer-widgets.light-text .widget.widget_tag_cloud .tagcloud a {
  border-color: rgba(255, 255, 255, 0.04);
}

.footer-widgets.light-text .widget.widget_nav_menu ul.menu .sub-menu > li:last-child a {
  border-color: rgba(255, 255, 255, 0.06) !important;
}

.footer-widgets.light-text .widget.widget_tag_cloud .tagcloud a:hover {
  color: rgba(255, 255, 255, 0.71) !important;
}

.footer-widgets.light-text .widget, .footer-widgets.light-text .widget.widget_calendar table td, .footer-widgets.light-text .widget.widget_calendar table td:hover {
  color: rgba(255, 255, 255, 0.71);
}

.footer-widgets.light-text .post-meta .post-author, .footer-widgets.light-text .widget a, .footer-widgets.light-text .widget.widget_categories .post-count {
  color: #fff;
}

.footer-widgets.light-text .widget .bs-about .about-icons-list .about-icon-item a {
  -webkit-box-shadow: inset 0 0 0 1px rgba(228, 228, 228, 0.21);
  -moz-box-shadow: inset 0 0 0 1px rgba(228, 228, 228, 0.21);
  box-shadow: inset 0 0 0 1px rgba(228, 228, 228, 0.21);
  color: #fff;
}

.footer-widgets.light-text .widget .better-social-counter.style-clean .item-count, .footer-widgets.light-text .widget .bs-about .about-icons-list .about-icon-item a:hover {
  color: #fff !important;
}

.footer-widgets.light-text .better-control-nav li a, .footer-widgets.light-text .widget.widget_bs-subscribe-newsletter {
  background: rgba(0, 0, 0, 0.15);
}

.footer-widgets.dark-text .widget.widget_nav_menu ul.menu li a {
  background-color: rgba(0, 0, 0, 0.08);
}

.footer-widgets.dark-text .bs-popular-categories .bs-popular-term-item .term-count, .footer-widgets.dark-text .widget.widget_calendar table caption, .footer-widgets.dark-text .widget.widget_calendar table td.active-day, .footer-widgets.dark-text .widget.widget_calendar table tfoot td, .footer-widgets.dark-text .widget.widget_calendar table th, .footer-widgets.dark-text .widget.widget_categories .post-count {
  background: rgba(0, 0, 0, 0.15);
}

.footer-widgets.dark-text .bs-popular-categories .bs-popular-term-item, .footer-widgets.dark-text .widget.widget_categories ul li, .footer-widgets.dark-text .widget.widget_categories ul.children, .footer-widgets.dark-text .widget.widget_pages ul li {
  border-color: rgba(0, 0, 0, 0.11);
}

.footer-widgets.dark-text .widget, .footer-widgets.dark-text .widget.widget_calendar table td, .footer-widgets.dark-text .widget.widget_calendar table td:hover {
  color: rgba(0, 0, 0, 0.69);
}

.footer-widgets.dark-text .post-meta .post-author {
  color: rgba(0, 0, 0, 0.6);
}

.footer-widgets.dark-text .widget .bs-about .about-icons-list .about-icon-item a {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.46);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.46);
  color: rgba(0, 0, 0, 0.41);
}

.footer-widgets.dark-text .widget .bs-about .about-icons-list .about-icon-item a:hover, .footer-widgets.dark-text .widget.widget_categories .post-count {
  color: #fff !important;
}

.footer-widgets.dark-text .better-control-nav li a, .footer-widgets.dark-text .widget.widget_bs-subscribe-newsletter {
  background: rgba(0, 0, 0, 0.15);
}

.footer-widgets.dark-text .listing-item-text-1 .item-inner {
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

.site-footer .footer-social-icons {
  padding: 20px 0;
  background: #292929;
}

.site-footer .footer-social-icons .social-item {
  margin-bottom: 0;
}

.footer-social-icons .better-social-counter.style-big-button .social-list {
  text-align: center;
  font-size: 0;
}

.footer-social-icons .better-social-counter.style-big-button .social-item {
  display: inline-block;
  float: none !important;
  text-align: left;
}

.site-footer .copy-footer {
  padding: 22px 0 22px;
  background: #353535;
}

.site-footer .copy-footer .container {
  font-size: 12px;
}

.site-footer .copy-1, .site-footer .copy-2 {
  color: #b6b6b6;
  color: rgba(255, 255, 255, 0.57);
}

.site-footer .copy-2 {
  text-align: right;
}

.site-footer .copy-1 a, .site-footer .copy-2 a {
  color: #f3f3f3;
}

@media only screen and (max-width: 780px) {
  .site-footer .copy-1, .site-footer .copy-2 {
    text-align: center !important;
  }
  .site-footer .copy-1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .site-footer ul.menu.footer-menu {
    text-align: center;
  }
  .site-footer ul.menu.footer-menu > li {
    float: none;
    margin: 0 7px;
    display: inline-block;
  }
}
.copy-footer .content-wrap {
  overflow-x: hidden;
}

.footer-menu-container {
  border-bottom: 2px solid #646464;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding: 10px 0;
  margin-bottom: 10px;
  position: relative;
}

ul.menu.footer-menu > li {
  margin-right: 20px;
}

ul.menu.footer-menu li > a {
  color: inherit;
  text-transform: inherit;
}

ul.menu.footer-menu > li.menu-item-has-children > a:after {
  display: none;
}

.site-footer .copy-footer .footer-menu-wrapper .footer-menu-container:before {
  content: "";
  position: absolute;
  width: 200vw;
  height: 100%;
  right: -100vw;
  padding-left: 200px;
  box-sizing: unset;
  top: 0;
}

.site-footer.full-width .copy-footer {
  max-width: 100%;
  overflow: hidden;
}

.site-footer.full-width .copy-footer .content-wrap {
  overflow-x: visible;
}

.site-footer.full-width.stretched .copy-footer .content-wrap {
  overflow-x: hidden;
  overflow-y: hidden;
}

.main-wrap .site-footer.full-width .copy-footer .content-wrap {
  overflow-x: hidden;
  overflow-y: hidden;
}

.footer-instagram .footer-instagram-label {
  margin: 25px 0;
  text-align: center;
  font-size: 18px;
}

.footer-instagram .bs-instagram-photo img {
  max-width: 100%;
}

.footer-instagram-1 {
  padding: 0 2px 2px;
}

.footer-instagram-1 .bs-instagram-col {
  float: left;
  width: 16.666666667%;
  overflow: hidden;
}

.footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-1 .bs-instagram-photo:nth-child(1) {
  max-width: 100%;
}

.footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-1 .bs-instagram-photo:nth-child(2), .footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-1 .bs-instagram-photo:nth-child(3) {
  max-width: 50%;
  float: left;
}

.footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-2 .bs-instagram-photo:nth-child(3) {
  max-width: 100%;
  clear: left;
}

.footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-2 .bs-instagram-photo:nth-child(1), .footer-instagram-1 .bs-instagram-col.bs-instagram-col-type-2 .bs-instagram-photo:nth-child(2) {
  max-width: 50%;
  float: left;
}

.footer-instagram-1 .bs-instagram-col .bs-instagram-photo {
  border: 1px solid transparent;
}

@media only screen and (max-width: 1280px) {
  .footer-instagram-1 .bs-instagram-col {
    width: 20%;
  }
  .footer-instagram-1 .bs-instagram-col.bs-instagram-col-6 {
    display: none;
  }
}
@media only screen and (max-width: 980px) {
  .footer-instagram-1 .bs-instagram-col {
    width: 25%;
  }
  .footer-instagram-1 .bs-instagram-col.bs-instagram-col-5 {
    display: none;
  }
}
@media only screen and (max-width: 780px) {
  .footer-instagram-1 .bs-instagram-col {
    width: 33.33333%;
  }
  .footer-instagram-1 .bs-instagram-col.bs-instagram-col-4 {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .footer-instagram-1 .bs-instagram-col {
    width: 50%;
  }
  .footer-instagram-1 .bs-instagram-col.bs-instagram-col-3 {
    display: none;
  }
}
.footer-instagram-2 {
  margin: 0;
}

.footer-instagram-2 .bs-instagram-photo {
  width: 12.5%;
  float: left;
}

@media only screen and (max-width: 1280px) {
  .footer-instagram-2 .bs-instagram-photo {
    width: 14.285714286%;
  }
  .footer-instagram-2 .bs-instagram-photo-7 {
    display: none;
  }
}
@media only screen and (max-width: 1100px) {
  .footer-instagram-2 .bs-instagram-photo {
    width: 16.666666667%;
  }
  .footer-instagram-2 .bs-instagram-photo-6 {
    display: none;
  }
}
@media only screen and (max-width: 980px) {
  .footer-instagram-2 .bs-instagram-photo {
    width: 20%;
  }
  .footer-instagram-2 .bs-instagram-photo-5 {
    display: none;
  }
}
@media only screen and (max-width: 780px) {
  .footer-instagram-2 .bs-instagram-photo {
    width: 25%;
  }
  .footer-instagram-2 .bs-instagram-photo-4 {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .footer-instagram-2 .bs-instagram-photo {
    width: 33.333%;
  }
  .footer-instagram-2 .bs-instagram-photo-3 {
    display: none;
  }
}
.footer-instagram-3 {
  margin: 0 0 30px;
}

.footer-instagram-3 .bs-instagram-photo {
  width: 200px;
  margin: 0 20px;
  float: left;
}

.footer-instagram-3 .simply-scroll-container {
  position: relative;
}

.footer-instagram-3 .simply-scroll-clip {
  position: relative;
  overflow: hidden;
}

.footer-instagram-3 .simply-scroll-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-instagram-3 .simply-scroll-list li {
  padding: 0;
  margin: 0 15px;
  list-style: none;
}

.footer-instagram-3 .custom .simply-scroll-clip {
  width: 100%;
  height: 210px;
}

@media (min-width: 768px) {
  .active-sticky-sidebar .row.main-section, .active-sticky-sidebar .row > .bs-vc-wrapper {
    display: flex;
  }
}
:root {
  --publisher-spacing: 20;
  --publisher-spacing-half: calc(var(--publisher-spacing) / 2);
  --publisher-spacing-80-percent: calc(var(--publisher-spacing) * 0.83);
  --publisher-spacing-60-percent: calc(var(--publisher-spacing) * 0.66) ;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .container, .vc_column_container.vc_column_container, .vc_row .vc_column_container > .vc_column-inner, .vc_row.vc_column-gap-1, .vc_row.vc_column-gap-10, .vc_row.vc_column-gap-15, .vc_row.vc_column-gap-2, .vc_row.vc_column-gap-20, .vc_row.vc_column-gap-25, .vc_row.vc_column-gap-3, .vc_row.vc_column-gap-30, .vc_row.vc_column-gap-35, .vc_row.vc_column-gap-4, .vc_row.vc_column-gap-5 {
  padding-left: calc(var(--publisher-spacing-half) * 1px);
  padding-right: calc(var(--publisher-spacing-half) * 1px);
}

.bs-vc-content .vc_row.vc_row-no-padding[data-vc-stretch-content=true], .row, .vc_row.wpb_row {
  margin-left: calc(var(--publisher-spacing-half) * -1px);
  margin-right: calc(var(--publisher-spacing-half) * -1px);
}

.vc_row.vc_inner {
  margin-left: calc(var(--publisher-spacing-half) * -1px) !important;
  margin-right: calc(var(--publisher-spacing-half) * -1px) !important;
}

.bs-vc-wrapper > :not(.bs-vc-column), .sidebar > * {
  margin-bottom: calc(var(--publisher-spacing) * 1px);
}

.better-studio-shortcode, .bs-listing, .bs-shortcode, .bsac, .bsp-wrapper, .content-column > div:last-child, .entry-content .better-studio-shortcode, .order-customer-detail, .order-detail-wrap, .single-container, .slider-style-16-container, .slider-style-18-container, .slider-style-2-container, .slider-style-23-container, .slider-style-4-container, .slider-style-8-container, .vc_row .vc_column-inner .wpb_content_element, .wc-account-content-wrap, .widget {
  margin-bottom: calc(var(--publisher-spacing) * 1px);
}

.archive-title {
  margin-bottom: calc(var(--publisher-spacing-60-percent) * 1px);
}

.layout-1-col, .layout-2-col, .layout-3-col {
  margin-top: calc(var(--publisher-spacing-80-percent) * 1px);
}

.layout-1-col.layout-bc-before, .layout-2-col.layout-bc-before, .layout-3-col.layout-bc-before {
  margin-top: calc(var(--publisher-spacing-half) * 1px);
}

.bs-listing.bs-listing-products .bs-pagination, .bs-listing.bs-listing-products .bs-slider-controls, .bs-vc-content > .vc_row.vc_row-fluid.vc_row-has-fill:first-child {
  margin-top: calc(var(--publisher-spacing-80-percent) * -1px) !important;
}

.vc_col-has-fill > .bs-vc-wrapper, .vc_row-has-fill + .vc_row-full-width + .vc_row > .bs-vc-wrapper > .bs-vc-column > .bs-vc-wrapper, .vc_row-has-fill + .vc_row > .bs-vc-wrapper > .bs-vc-column > .bs-vc-wrapper, .vc_row-has-fill + .vc_row > .wpb_column > .bs-vc-wrapper, .vc_row-has-fill > .bs-vc-wrapper > .vc_column_container > .bs-vc-wrapper, .vc_row-has-fill > .wpb_column > .bs-vc-wrapper {
  padding-top: calc(var(--publisher-spacing-80-percent) * 1px) !important;
}

#bbpress-forums #bbp-search-form, .main-section, .vc_col-has-fill .wpb_wrapper > .bs-listing:last-child, .vc_col-has-fill .wpb_wrapper > .bsp-wrapper:last-child, .vc_col-has-fill .wpb_wrapper > :last-child, .vc_row-has-fill .wpb_wrapper > .bs-listing:last-child, .vc_row-has-fill .wpb_wrapper > .bsp-wrapper:last-child, .vc_row-has-fill .wpb_wrapper > :last-child {
  margin-bottom: calc(var(--publisher-spacing-half) * 1px);
}

.bs-listing-modern-grid-listing-3.bs-listing {
  margin-bottom: calc(var(--publisher-spacing-half) * 1px) !important;
}

.vc_row-has-fill .wpb_wrapper > .bs-listing-modern-grid-listing-3.bs-listing:last-child {
  margin-bottom: calc(var(--publisher-spacing-60-percent) * 1px) !important;
}

.bsac.bsac-post-before-author, .comment-respond.comments-template, .comments-template, .post-related, .post-related + .ajax-post-content, .post-related + .comments-template, .post-related + .single-container, .single-container > .bs-newsletter-pack, .single-container > .post-author, .up-sells.products, .woocommerce .cart-collaterals .cart_totals, .woocommerce .cart-collaterals .cross-sells, .woocommerce + .bs-shortcode, .woocommerce + .woocommerce, .woocommerce-checkout-review-order-wrap, .woocommerce-page div.product .related.products, .woocommerce-page div.product .woocommerce-tabs, body.single .content-column > .bs-newsletter-pack {
  margin-top: calc(var(--publisher-spacing) * 1px);
}

.better-gcs-wrapper {
  margin-top: calc(var(--publisher-spacing) * -1px);
}

.slider-container + .bs-sks, .slider-style-1-container, .slider-style-11-container, .slider-style-13-container, .slider-style-15-container, .slider-style-17-container, .slider-style-19-container, .slider-style-2-container.slider-container-1col, .slider-style-20-container, .slider-style-21-container, .slider-style-22-container, .slider-style-3-container, .slider-style-4-container.slider-container-1col, .slider-style-5-container, .slider-style-7-container, .slider-style-9-container {
  padding-top: calc(var(--publisher-spacing-80-percent) * 1px);
  padding-bottom: calc(var(--publisher-spacing) * 1px);
  margin-bottom: calc(var(--publisher-spacing-80-percent) * -1px);
}

.slider-container.slider-bc-before + .bs-sks, .slider-style-1-container.slider-bc-before, .slider-style-11-container.slider-bc-before, .slider-style-13-container.slider-bc-before, .slider-style-15-container.slider-bc-before, .slider-style-17-container.slider-bc-before, .slider-style-19-container.slider-bc-before, .slider-style-20-container.slider-bc-before, .slider-style-21-container.slider-bc-before, .slider-style-22-container.slider-bc-before, .slider-style-23-container.slider-bc-before, .slider-style-3-container.slider-bc-before, .slider-style-5-container.slider-bc-before, .slider-style-7-container.slider-bc-before, .slider-style-9-container.slider-bc-before {
  padding-top: calc(var(--publisher-spacing-half) * 1px);
  padding-bottom: calc(var(--publisher-spacing-half) * 1px);
  margin-bottom: calc(var(--publisher-spacing-half) * 1px);
}

.section-heading {
  margin-bottom: calc(var(--publisher-spacing-60-percent) * 1px);
}

@media only screen and (max-width: 678px) {
  .footer-widgets > .content-wrap > .container > .row > * {
    margin-bottom: calc(var(--publisher-spacing-80-percent) * 1px);
  }
}
.bs-injection.bs-injection-before_footer .bs-injection.bs-injection-after_footer {
  margin-top: calc(var(--publisher-spacing-80-percent) * 1px);
}

.bs-injection.bs-injection-before_header .bs-injection.bs-injection-after_header {
  padding-top: calc(var(--publisher-spacing-80-percent) * 1px);
}

.single-container > .bs-push-noti.post-bottom, .single-container > .bs-push-noti.post-bottom + .post-author {
  margin-top: calc(var(--publisher-spacing) * 1px);
}

.bs-push-noti.post-top {
  margin-bottom: calc(var(--publisher-spacing) * 1px) !important;
}

/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*!
 * Bootstrap Icons v1.11.2 (https://icons.getbootstrap.com/)
 * Copyright 2019-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/icons/blob/main/LICENSE)
 */
@font-face {
  font-display: block;
  font-family: "bootstrap-icons";
  src: url("./fonts/bootstrap-icons.woff2?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff2"), url("./fonts/bootstrap-icons.woff?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff");
}
.bi::before,
[class^=bi-]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: "bootstrap-icons" !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-123::before {
  content: "\f67f";
}

.bi-alarm-fill::before {
  content: "\f101";
}

.bi-alarm::before {
  content: "\f102";
}

.bi-align-bottom::before {
  content: "\f103";
}

.bi-align-center::before {
  content: "\f104";
}

.bi-align-end::before {
  content: "\f105";
}

.bi-align-middle::before {
  content: "\f106";
}

.bi-align-start::before {
  content: "\f107";
}

.bi-align-top::before {
  content: "\f108";
}

.bi-alt::before {
  content: "\f109";
}

.bi-app-indicator::before {
  content: "\f10a";
}

.bi-app::before {
  content: "\f10b";
}

.bi-archive-fill::before {
  content: "\f10c";
}

.bi-archive::before {
  content: "\f10d";
}

.bi-arrow-90deg-down::before {
  content: "\f10e";
}

.bi-arrow-90deg-left::before {
  content: "\f10f";
}

.bi-arrow-90deg-right::before {
  content: "\f110";
}

.bi-arrow-90deg-up::before {
  content: "\f111";
}

.bi-arrow-bar-down::before {
  content: "\f112";
}

.bi-arrow-bar-left::before {
  content: "\f113";
}

.bi-arrow-bar-right::before {
  content: "\f114";
}

.bi-arrow-bar-up::before {
  content: "\f115";
}

.bi-arrow-clockwise::before {
  content: "\f116";
}

.bi-arrow-counterclockwise::before {
  content: "\f117";
}

.bi-arrow-down-circle-fill::before {
  content: "\f118";
}

.bi-arrow-down-circle::before {
  content: "\f119";
}

.bi-arrow-down-left-circle-fill::before {
  content: "\f11a";
}

.bi-arrow-down-left-circle::before {
  content: "\f11b";
}

.bi-arrow-down-left-square-fill::before {
  content: "\f11c";
}

.bi-arrow-down-left-square::before {
  content: "\f11d";
}

.bi-arrow-down-left::before {
  content: "\f11e";
}

.bi-arrow-down-right-circle-fill::before {
  content: "\f11f";
}

.bi-arrow-down-right-circle::before {
  content: "\f120";
}

.bi-arrow-down-right-square-fill::before {
  content: "\f121";
}

.bi-arrow-down-right-square::before {
  content: "\f122";
}

.bi-arrow-down-right::before {
  content: "\f123";
}

.bi-arrow-down-short::before {
  content: "\f124";
}

.bi-arrow-down-square-fill::before {
  content: "\f125";
}

.bi-arrow-down-square::before {
  content: "\f126";
}

.bi-arrow-down-up::before {
  content: "\f127";
}

.bi-arrow-down::before {
  content: "\f128";
}

.bi-arrow-left-circle-fill::before {
  content: "\f129";
}

.bi-arrow-left-circle::before {
  content: "\f12a";
}

.bi-arrow-left-right::before {
  content: "\f12b";
}

.bi-arrow-left-short::before {
  content: "\f12c";
}

.bi-arrow-left-square-fill::before {
  content: "\f12d";
}

.bi-arrow-left-square::before {
  content: "\f12e";
}

.bi-arrow-left::before {
  content: "\f12f";
}

.bi-arrow-repeat::before {
  content: "\f130";
}

.bi-arrow-return-left::before {
  content: "\f131";
}

.bi-arrow-return-right::before {
  content: "\f132";
}

.bi-arrow-right-circle-fill::before {
  content: "\f133";
}

.bi-arrow-right-circle::before {
  content: "\f134";
}

.bi-arrow-right-short::before {
  content: "\f135";
}

.bi-arrow-right-square-fill::before {
  content: "\f136";
}

.bi-arrow-right-square::before {
  content: "\f137";
}

.bi-arrow-right::before {
  content: "\f138";
}

.bi-arrow-up-circle-fill::before {
  content: "\f139";
}

.bi-arrow-up-circle::before {
  content: "\f13a";
}

.bi-arrow-up-left-circle-fill::before {
  content: "\f13b";
}

.bi-arrow-up-left-circle::before {
  content: "\f13c";
}

.bi-arrow-up-left-square-fill::before {
  content: "\f13d";
}

.bi-arrow-up-left-square::before {
  content: "\f13e";
}

.bi-arrow-up-left::before {
  content: "\f13f";
}

.bi-arrow-up-right-circle-fill::before {
  content: "\f140";
}

.bi-arrow-up-right-circle::before {
  content: "\f141";
}

.bi-arrow-up-right-square-fill::before {
  content: "\f142";
}

.bi-arrow-up-right-square::before {
  content: "\f143";
}

.bi-arrow-up-right::before {
  content: "\f144";
}

.bi-arrow-up-short::before {
  content: "\f145";
}

.bi-arrow-up-square-fill::before {
  content: "\f146";
}

.bi-arrow-up-square::before {
  content: "\f147";
}

.bi-arrow-up::before {
  content: "\f148";
}

.bi-arrows-angle-contract::before {
  content: "\f149";
}

.bi-arrows-angle-expand::before {
  content: "\f14a";
}

.bi-arrows-collapse::before {
  content: "\f14b";
}

.bi-arrows-expand::before {
  content: "\f14c";
}

.bi-arrows-fullscreen::before {
  content: "\f14d";
}

.bi-arrows-move::before {
  content: "\f14e";
}

.bi-aspect-ratio-fill::before {
  content: "\f14f";
}

.bi-aspect-ratio::before {
  content: "\f150";
}

.bi-asterisk::before {
  content: "\f151";
}

.bi-at::before {
  content: "\f152";
}

.bi-award-fill::before {
  content: "\f153";
}

.bi-award::before {
  content: "\f154";
}

.bi-back::before {
  content: "\f155";
}

.bi-backspace-fill::before {
  content: "\f156";
}

.bi-backspace-reverse-fill::before {
  content: "\f157";
}

.bi-backspace-reverse::before {
  content: "\f158";
}

.bi-backspace::before {
  content: "\f159";
}

.bi-badge-3d-fill::before {
  content: "\f15a";
}

.bi-badge-3d::before {
  content: "\f15b";
}

.bi-badge-4k-fill::before {
  content: "\f15c";
}

.bi-badge-4k::before {
  content: "\f15d";
}

.bi-badge-8k-fill::before {
  content: "\f15e";
}

.bi-badge-8k::before {
  content: "\f15f";
}

.bi-badge-ad-fill::before {
  content: "\f160";
}

.bi-badge-ad::before {
  content: "\f161";
}

.bi-badge-ar-fill::before {
  content: "\f162";
}

.bi-badge-ar::before {
  content: "\f163";
}

.bi-badge-cc-fill::before {
  content: "\f164";
}

.bi-badge-cc::before {
  content: "\f165";
}

.bi-badge-hd-fill::before {
  content: "\f166";
}

.bi-badge-hd::before {
  content: "\f167";
}

.bi-badge-tm-fill::before {
  content: "\f168";
}

.bi-badge-tm::before {
  content: "\f169";
}

.bi-badge-vo-fill::before {
  content: "\f16a";
}

.bi-badge-vo::before {
  content: "\f16b";
}

.bi-badge-vr-fill::before {
  content: "\f16c";
}

.bi-badge-vr::before {
  content: "\f16d";
}

.bi-badge-wc-fill::before {
  content: "\f16e";
}

.bi-badge-wc::before {
  content: "\f16f";
}

.bi-bag-check-fill::before {
  content: "\f170";
}

.bi-bag-check::before {
  content: "\f171";
}

.bi-bag-dash-fill::before {
  content: "\f172";
}

.bi-bag-dash::before {
  content: "\f173";
}

.bi-bag-fill::before {
  content: "\f174";
}

.bi-bag-plus-fill::before {
  content: "\f175";
}

.bi-bag-plus::before {
  content: "\f176";
}

.bi-bag-x-fill::before {
  content: "\f177";
}

.bi-bag-x::before {
  content: "\f178";
}

.bi-bag::before {
  content: "\f179";
}

.bi-bar-chart-fill::before {
  content: "\f17a";
}

.bi-bar-chart-line-fill::before {
  content: "\f17b";
}

.bi-bar-chart-line::before {
  content: "\f17c";
}

.bi-bar-chart-steps::before {
  content: "\f17d";
}

.bi-bar-chart::before {
  content: "\f17e";
}

.bi-basket-fill::before {
  content: "\f17f";
}

.bi-basket::before {
  content: "\f180";
}

.bi-basket2-fill::before {
  content: "\f181";
}

.bi-basket2::before {
  content: "\f182";
}

.bi-basket3-fill::before {
  content: "\f183";
}

.bi-basket3::before {
  content: "\f184";
}

.bi-battery-charging::before {
  content: "\f185";
}

.bi-battery-full::before {
  content: "\f186";
}

.bi-battery-half::before {
  content: "\f187";
}

.bi-battery::before {
  content: "\f188";
}

.bi-bell-fill::before {
  content: "\f189";
}

.bi-bell::before {
  content: "\f18a";
}

.bi-bezier::before {
  content: "\f18b";
}

.bi-bezier2::before {
  content: "\f18c";
}

.bi-bicycle::before {
  content: "\f18d";
}

.bi-binoculars-fill::before {
  content: "\f18e";
}

.bi-binoculars::before {
  content: "\f18f";
}

.bi-blockquote-left::before {
  content: "\f190";
}

.bi-blockquote-right::before {
  content: "\f191";
}

.bi-book-fill::before {
  content: "\f192";
}

.bi-book-half::before {
  content: "\f193";
}

.bi-book::before {
  content: "\f194";
}

.bi-bookmark-check-fill::before {
  content: "\f195";
}

.bi-bookmark-check::before {
  content: "\f196";
}

.bi-bookmark-dash-fill::before {
  content: "\f197";
}

.bi-bookmark-dash::before {
  content: "\f198";
}

.bi-bookmark-fill::before {
  content: "\f199";
}

.bi-bookmark-heart-fill::before {
  content: "\f19a";
}

.bi-bookmark-heart::before {
  content: "\f19b";
}

.bi-bookmark-plus-fill::before {
  content: "\f19c";
}

.bi-bookmark-plus::before {
  content: "\f19d";
}

.bi-bookmark-star-fill::before {
  content: "\f19e";
}

.bi-bookmark-star::before {
  content: "\f19f";
}

.bi-bookmark-x-fill::before {
  content: "\f1a0";
}

.bi-bookmark-x::before {
  content: "\f1a1";
}

.bi-bookmark::before {
  content: "\f1a2";
}

.bi-bookmarks-fill::before {
  content: "\f1a3";
}

.bi-bookmarks::before {
  content: "\f1a4";
}

.bi-bookshelf::before {
  content: "\f1a5";
}

.bi-bootstrap-fill::before {
  content: "\f1a6";
}

.bi-bootstrap-reboot::before {
  content: "\f1a7";
}

.bi-bootstrap::before {
  content: "\f1a8";
}

.bi-border-all::before {
  content: "\f1a9";
}

.bi-border-bottom::before {
  content: "\f1aa";
}

.bi-border-center::before {
  content: "\f1ab";
}

.bi-border-inner::before {
  content: "\f1ac";
}

.bi-border-left::before {
  content: "\f1ad";
}

.bi-border-middle::before {
  content: "\f1ae";
}

.bi-border-outer::before {
  content: "\f1af";
}

.bi-border-right::before {
  content: "\f1b0";
}

.bi-border-style::before {
  content: "\f1b1";
}

.bi-border-top::before {
  content: "\f1b2";
}

.bi-border-width::before {
  content: "\f1b3";
}

.bi-border::before {
  content: "\f1b4";
}

.bi-bounding-box-circles::before {
  content: "\f1b5";
}

.bi-bounding-box::before {
  content: "\f1b6";
}

.bi-box-arrow-down-left::before {
  content: "\f1b7";
}

.bi-box-arrow-down-right::before {
  content: "\f1b8";
}

.bi-box-arrow-down::before {
  content: "\f1b9";
}

.bi-box-arrow-in-down-left::before {
  content: "\f1ba";
}

.bi-box-arrow-in-down-right::before {
  content: "\f1bb";
}

.bi-box-arrow-in-down::before {
  content: "\f1bc";
}

.bi-box-arrow-in-left::before {
  content: "\f1bd";
}

.bi-box-arrow-in-right::before {
  content: "\f1be";
}

.bi-box-arrow-in-up-left::before {
  content: "\f1bf";
}

.bi-box-arrow-in-up-right::before {
  content: "\f1c0";
}

.bi-box-arrow-in-up::before {
  content: "\f1c1";
}

.bi-box-arrow-left::before {
  content: "\f1c2";
}

.bi-box-arrow-right::before {
  content: "\f1c3";
}

.bi-box-arrow-up-left::before {
  content: "\f1c4";
}

.bi-box-arrow-up-right::before {
  content: "\f1c5";
}

.bi-box-arrow-up::before {
  content: "\f1c6";
}

.bi-box-seam::before {
  content: "\f1c7";
}

.bi-box::before {
  content: "\f1c8";
}

.bi-braces::before {
  content: "\f1c9";
}

.bi-bricks::before {
  content: "\f1ca";
}

.bi-briefcase-fill::before {
  content: "\f1cb";
}

.bi-briefcase::before {
  content: "\f1cc";
}

.bi-brightness-alt-high-fill::before {
  content: "\f1cd";
}

.bi-brightness-alt-high::before {
  content: "\f1ce";
}

.bi-brightness-alt-low-fill::before {
  content: "\f1cf";
}

.bi-brightness-alt-low::before {
  content: "\f1d0";
}

.bi-brightness-high-fill::before {
  content: "\f1d1";
}

.bi-brightness-high::before {
  content: "\f1d2";
}

.bi-brightness-low-fill::before {
  content: "\f1d3";
}

.bi-brightness-low::before {
  content: "\f1d4";
}

.bi-broadcast-pin::before {
  content: "\f1d5";
}

.bi-broadcast::before {
  content: "\f1d6";
}

.bi-brush-fill::before {
  content: "\f1d7";
}

.bi-brush::before {
  content: "\f1d8";
}

.bi-bucket-fill::before {
  content: "\f1d9";
}

.bi-bucket::before {
  content: "\f1da";
}

.bi-bug-fill::before {
  content: "\f1db";
}

.bi-bug::before {
  content: "\f1dc";
}

.bi-building::before {
  content: "\f1dd";
}

.bi-bullseye::before {
  content: "\f1de";
}

.bi-calculator-fill::before {
  content: "\f1df";
}

.bi-calculator::before {
  content: "\f1e0";
}

.bi-calendar-check-fill::before {
  content: "\f1e1";
}

.bi-calendar-check::before {
  content: "\f1e2";
}

.bi-calendar-date-fill::before {
  content: "\f1e3";
}

.bi-calendar-date::before {
  content: "\f1e4";
}

.bi-calendar-day-fill::before {
  content: "\f1e5";
}

.bi-calendar-day::before {
  content: "\f1e6";
}

.bi-calendar-event-fill::before {
  content: "\f1e7";
}

.bi-calendar-event::before {
  content: "\f1e8";
}

.bi-calendar-fill::before {
  content: "\f1e9";
}

.bi-calendar-minus-fill::before {
  content: "\f1ea";
}

.bi-calendar-minus::before {
  content: "\f1eb";
}

.bi-calendar-month-fill::before {
  content: "\f1ec";
}

.bi-calendar-month::before {
  content: "\f1ed";
}

.bi-calendar-plus-fill::before {
  content: "\f1ee";
}

.bi-calendar-plus::before {
  content: "\f1ef";
}

.bi-calendar-range-fill::before {
  content: "\f1f0";
}

.bi-calendar-range::before {
  content: "\f1f1";
}

.bi-calendar-week-fill::before {
  content: "\f1f2";
}

.bi-calendar-week::before {
  content: "\f1f3";
}

.bi-calendar-x-fill::before {
  content: "\f1f4";
}

.bi-calendar-x::before {
  content: "\f1f5";
}

.bi-calendar::before {
  content: "\f1f6";
}

.bi-calendar2-check-fill::before {
  content: "\f1f7";
}

.bi-calendar2-check::before {
  content: "\f1f8";
}

.bi-calendar2-date-fill::before {
  content: "\f1f9";
}

.bi-calendar2-date::before {
  content: "\f1fa";
}

.bi-calendar2-day-fill::before {
  content: "\f1fb";
}

.bi-calendar2-day::before {
  content: "\f1fc";
}

.bi-calendar2-event-fill::before {
  content: "\f1fd";
}

.bi-calendar2-event::before {
  content: "\f1fe";
}

.bi-calendar2-fill::before {
  content: "\f1ff";
}

.bi-calendar2-minus-fill::before {
  content: "\f200";
}

.bi-calendar2-minus::before {
  content: "\f201";
}

.bi-calendar2-month-fill::before {
  content: "\f202";
}

.bi-calendar2-month::before {
  content: "\f203";
}

.bi-calendar2-plus-fill::before {
  content: "\f204";
}

.bi-calendar2-plus::before {
  content: "\f205";
}

.bi-calendar2-range-fill::before {
  content: "\f206";
}

.bi-calendar2-range::before {
  content: "\f207";
}

.bi-calendar2-week-fill::before {
  content: "\f208";
}

.bi-calendar2-week::before {
  content: "\f209";
}

.bi-calendar2-x-fill::before {
  content: "\f20a";
}

.bi-calendar2-x::before {
  content: "\f20b";
}

.bi-calendar2::before {
  content: "\f20c";
}

.bi-calendar3-event-fill::before {
  content: "\f20d";
}

.bi-calendar3-event::before {
  content: "\f20e";
}

.bi-calendar3-fill::before {
  content: "\f20f";
}

.bi-calendar3-range-fill::before {
  content: "\f210";
}

.bi-calendar3-range::before {
  content: "\f211";
}

.bi-calendar3-week-fill::before {
  content: "\f212";
}

.bi-calendar3-week::before {
  content: "\f213";
}

.bi-calendar3::before {
  content: "\f214";
}

.bi-calendar4-event::before {
  content: "\f215";
}

.bi-calendar4-range::before {
  content: "\f216";
}

.bi-calendar4-week::before {
  content: "\f217";
}

.bi-calendar4::before {
  content: "\f218";
}

.bi-camera-fill::before {
  content: "\f219";
}

.bi-camera-reels-fill::before {
  content: "\f21a";
}

.bi-camera-reels::before {
  content: "\f21b";
}

.bi-camera-video-fill::before {
  content: "\f21c";
}

.bi-camera-video-off-fill::before {
  content: "\f21d";
}

.bi-camera-video-off::before {
  content: "\f21e";
}

.bi-camera-video::before {
  content: "\f21f";
}

.bi-camera::before {
  content: "\f220";
}

.bi-camera2::before {
  content: "\f221";
}

.bi-capslock-fill::before {
  content: "\f222";
}

.bi-capslock::before {
  content: "\f223";
}

.bi-card-checklist::before {
  content: "\f224";
}

.bi-card-heading::before {
  content: "\f225";
}

.bi-card-image::before {
  content: "\f226";
}

.bi-card-list::before {
  content: "\f227";
}

.bi-card-text::before {
  content: "\f228";
}

.bi-caret-down-fill::before {
  content: "\f229";
}

.bi-caret-down-square-fill::before {
  content: "\f22a";
}

.bi-caret-down-square::before {
  content: "\f22b";
}

.bi-caret-down::before {
  content: "\f22c";
}

.bi-caret-left-fill::before {
  content: "\f22d";
}

.bi-caret-left-square-fill::before {
  content: "\f22e";
}

.bi-caret-left-square::before {
  content: "\f22f";
}

.bi-caret-left::before {
  content: "\f230";
}

.bi-caret-right-fill::before {
  content: "\f231";
}

.bi-caret-right-square-fill::before {
  content: "\f232";
}

.bi-caret-right-square::before {
  content: "\f233";
}

.bi-caret-right::before {
  content: "\f234";
}

.bi-caret-up-fill::before {
  content: "\f235";
}

.bi-caret-up-square-fill::before {
  content: "\f236";
}

.bi-caret-up-square::before {
  content: "\f237";
}

.bi-caret-up::before {
  content: "\f238";
}

.bi-cart-check-fill::before {
  content: "\f239";
}

.bi-cart-check::before {
  content: "\f23a";
}

.bi-cart-dash-fill::before {
  content: "\f23b";
}

.bi-cart-dash::before {
  content: "\f23c";
}

.bi-cart-fill::before {
  content: "\f23d";
}

.bi-cart-plus-fill::before {
  content: "\f23e";
}

.bi-cart-plus::before {
  content: "\f23f";
}

.bi-cart-x-fill::before {
  content: "\f240";
}

.bi-cart-x::before {
  content: "\f241";
}

.bi-cart::before {
  content: "\f242";
}

.bi-cart2::before {
  content: "\f243";
}

.bi-cart3::before {
  content: "\f244";
}

.bi-cart4::before {
  content: "\f245";
}

.bi-cash-stack::before {
  content: "\f246";
}

.bi-cash::before {
  content: "\f247";
}

.bi-cast::before {
  content: "\f248";
}

.bi-chat-dots-fill::before {
  content: "\f249";
}

.bi-chat-dots::before {
  content: "\f24a";
}

.bi-chat-fill::before {
  content: "\f24b";
}

.bi-chat-left-dots-fill::before {
  content: "\f24c";
}

.bi-chat-left-dots::before {
  content: "\f24d";
}

.bi-chat-left-fill::before {
  content: "\f24e";
}

.bi-chat-left-quote-fill::before {
  content: "\f24f";
}

.bi-chat-left-quote::before {
  content: "\f250";
}

.bi-chat-left-text-fill::before {
  content: "\f251";
}

.bi-chat-left-text::before {
  content: "\f252";
}

.bi-chat-left::before {
  content: "\f253";
}

.bi-chat-quote-fill::before {
  content: "\f254";
}

.bi-chat-quote::before {
  content: "\f255";
}

.bi-chat-right-dots-fill::before {
  content: "\f256";
}

.bi-chat-right-dots::before {
  content: "\f257";
}

.bi-chat-right-fill::before {
  content: "\f258";
}

.bi-chat-right-quote-fill::before {
  content: "\f259";
}

.bi-chat-right-quote::before {
  content: "\f25a";
}

.bi-chat-right-text-fill::before {
  content: "\f25b";
}

.bi-chat-right-text::before {
  content: "\f25c";
}

.bi-chat-right::before {
  content: "\f25d";
}

.bi-chat-square-dots-fill::before {
  content: "\f25e";
}

.bi-chat-square-dots::before {
  content: "\f25f";
}

.bi-chat-square-fill::before {
  content: "\f260";
}

.bi-chat-square-quote-fill::before {
  content: "\f261";
}

.bi-chat-square-quote::before {
  content: "\f262";
}

.bi-chat-square-text-fill::before {
  content: "\f263";
}

.bi-chat-square-text::before {
  content: "\f264";
}

.bi-chat-square::before {
  content: "\f265";
}

.bi-chat-text-fill::before {
  content: "\f266";
}

.bi-chat-text::before {
  content: "\f267";
}

.bi-chat::before {
  content: "\f268";
}

.bi-check-all::before {
  content: "\f269";
}

.bi-check-circle-fill::before {
  content: "\f26a";
}

.bi-check-circle::before {
  content: "\f26b";
}

.bi-check-square-fill::before {
  content: "\f26c";
}

.bi-check-square::before {
  content: "\f26d";
}

.bi-check::before {
  content: "\f26e";
}

.bi-check2-all::before {
  content: "\f26f";
}

.bi-check2-circle::before {
  content: "\f270";
}

.bi-check2-square::before {
  content: "\f271";
}

.bi-check2::before {
  content: "\f272";
}

.bi-chevron-bar-contract::before {
  content: "\f273";
}

.bi-chevron-bar-down::before {
  content: "\f274";
}

.bi-chevron-bar-expand::before {
  content: "\f275";
}

.bi-chevron-bar-left::before {
  content: "\f276";
}

.bi-chevron-bar-right::before {
  content: "\f277";
}

.bi-chevron-bar-up::before {
  content: "\f278";
}

.bi-chevron-compact-down::before {
  content: "\f279";
}

.bi-chevron-compact-left::before {
  content: "\f27a";
}

.bi-chevron-compact-right::before {
  content: "\f27b";
}

.bi-chevron-compact-up::before {
  content: "\f27c";
}

.bi-chevron-contract::before {
  content: "\f27d";
}

.bi-chevron-double-down::before {
  content: "\f27e";
}

.bi-chevron-double-left::before {
  content: "\f27f";
}

.bi-chevron-double-right::before {
  content: "\f280";
}

.bi-chevron-double-up::before {
  content: "\f281";
}

.bi-chevron-down::before {
  content: "\f282";
}

.bi-chevron-expand::before {
  content: "\f283";
}

.bi-chevron-left::before {
  content: "\f284";
}

.bi-chevron-right::before {
  content: "\f285";
}

.bi-chevron-up::before {
  content: "\f286";
}

.bi-circle-fill::before {
  content: "\f287";
}

.bi-circle-half::before {
  content: "\f288";
}

.bi-circle-square::before {
  content: "\f289";
}

.bi-circle::before {
  content: "\f28a";
}

.bi-clipboard-check::before {
  content: "\f28b";
}

.bi-clipboard-data::before {
  content: "\f28c";
}

.bi-clipboard-minus::before {
  content: "\f28d";
}

.bi-clipboard-plus::before {
  content: "\f28e";
}

.bi-clipboard-x::before {
  content: "\f28f";
}

.bi-clipboard::before {
  content: "\f290";
}

.bi-clock-fill::before {
  content: "\f291";
}

.bi-clock-history::before {
  content: "\f292";
}

.bi-clock::before {
  content: "\f293";
}

.bi-cloud-arrow-down-fill::before {
  content: "\f294";
}

.bi-cloud-arrow-down::before {
  content: "\f295";
}

.bi-cloud-arrow-up-fill::before {
  content: "\f296";
}

.bi-cloud-arrow-up::before {
  content: "\f297";
}

.bi-cloud-check-fill::before {
  content: "\f298";
}

.bi-cloud-check::before {
  content: "\f299";
}

.bi-cloud-download-fill::before {
  content: "\f29a";
}

.bi-cloud-download::before {
  content: "\f29b";
}

.bi-cloud-drizzle-fill::before {
  content: "\f29c";
}

.bi-cloud-drizzle::before {
  content: "\f29d";
}

.bi-cloud-fill::before {
  content: "\f29e";
}

.bi-cloud-fog-fill::before {
  content: "\f29f";
}

.bi-cloud-fog::before {
  content: "\f2a0";
}

.bi-cloud-fog2-fill::before {
  content: "\f2a1";
}

.bi-cloud-fog2::before {
  content: "\f2a2";
}

.bi-cloud-hail-fill::before {
  content: "\f2a3";
}

.bi-cloud-hail::before {
  content: "\f2a4";
}

.bi-cloud-haze-fill::before {
  content: "\f2a6";
}

.bi-cloud-haze::before {
  content: "\f2a7";
}

.bi-cloud-haze2-fill::before {
  content: "\f2a8";
}

.bi-cloud-lightning-fill::before {
  content: "\f2a9";
}

.bi-cloud-lightning-rain-fill::before {
  content: "\f2aa";
}

.bi-cloud-lightning-rain::before {
  content: "\f2ab";
}

.bi-cloud-lightning::before {
  content: "\f2ac";
}

.bi-cloud-minus-fill::before {
  content: "\f2ad";
}

.bi-cloud-minus::before {
  content: "\f2ae";
}

.bi-cloud-moon-fill::before {
  content: "\f2af";
}

.bi-cloud-moon::before {
  content: "\f2b0";
}

.bi-cloud-plus-fill::before {
  content: "\f2b1";
}

.bi-cloud-plus::before {
  content: "\f2b2";
}

.bi-cloud-rain-fill::before {
  content: "\f2b3";
}

.bi-cloud-rain-heavy-fill::before {
  content: "\f2b4";
}

.bi-cloud-rain-heavy::before {
  content: "\f2b5";
}

.bi-cloud-rain::before {
  content: "\f2b6";
}

.bi-cloud-slash-fill::before {
  content: "\f2b7";
}

.bi-cloud-slash::before {
  content: "\f2b8";
}

.bi-cloud-sleet-fill::before {
  content: "\f2b9";
}

.bi-cloud-sleet::before {
  content: "\f2ba";
}

.bi-cloud-snow-fill::before {
  content: "\f2bb";
}

.bi-cloud-snow::before {
  content: "\f2bc";
}

.bi-cloud-sun-fill::before {
  content: "\f2bd";
}

.bi-cloud-sun::before {
  content: "\f2be";
}

.bi-cloud-upload-fill::before {
  content: "\f2bf";
}

.bi-cloud-upload::before {
  content: "\f2c0";
}

.bi-cloud::before {
  content: "\f2c1";
}

.bi-clouds-fill::before {
  content: "\f2c2";
}

.bi-clouds::before {
  content: "\f2c3";
}

.bi-cloudy-fill::before {
  content: "\f2c4";
}

.bi-cloudy::before {
  content: "\f2c5";
}

.bi-code-slash::before {
  content: "\f2c6";
}

.bi-code-square::before {
  content: "\f2c7";
}

.bi-code::before {
  content: "\f2c8";
}

.bi-collection-fill::before {
  content: "\f2c9";
}

.bi-collection-play-fill::before {
  content: "\f2ca";
}

.bi-collection-play::before {
  content: "\f2cb";
}

.bi-collection::before {
  content: "\f2cc";
}

.bi-columns-gap::before {
  content: "\f2cd";
}

.bi-columns::before {
  content: "\f2ce";
}

.bi-command::before {
  content: "\f2cf";
}

.bi-compass-fill::before {
  content: "\f2d0";
}

.bi-compass::before {
  content: "\f2d1";
}

.bi-cone-striped::before {
  content: "\f2d2";
}

.bi-cone::before {
  content: "\f2d3";
}

.bi-controller::before {
  content: "\f2d4";
}

.bi-cpu-fill::before {
  content: "\f2d5";
}

.bi-cpu::before {
  content: "\f2d6";
}

.bi-credit-card-2-back-fill::before {
  content: "\f2d7";
}

.bi-credit-card-2-back::before {
  content: "\f2d8";
}

.bi-credit-card-2-front-fill::before {
  content: "\f2d9";
}

.bi-credit-card-2-front::before {
  content: "\f2da";
}

.bi-credit-card-fill::before {
  content: "\f2db";
}

.bi-credit-card::before {
  content: "\f2dc";
}

.bi-crop::before {
  content: "\f2dd";
}

.bi-cup-fill::before {
  content: "\f2de";
}

.bi-cup-straw::before {
  content: "\f2df";
}

.bi-cup::before {
  content: "\f2e0";
}

.bi-cursor-fill::before {
  content: "\f2e1";
}

.bi-cursor-text::before {
  content: "\f2e2";
}

.bi-cursor::before {
  content: "\f2e3";
}

.bi-dash-circle-dotted::before {
  content: "\f2e4";
}

.bi-dash-circle-fill::before {
  content: "\f2e5";
}

.bi-dash-circle::before {
  content: "\f2e6";
}

.bi-dash-square-dotted::before {
  content: "\f2e7";
}

.bi-dash-square-fill::before {
  content: "\f2e8";
}

.bi-dash-square::before {
  content: "\f2e9";
}

.bi-dash::before {
  content: "\f2ea";
}

.bi-diagram-2-fill::before {
  content: "\f2eb";
}

.bi-diagram-2::before {
  content: "\f2ec";
}

.bi-diagram-3-fill::before {
  content: "\f2ed";
}

.bi-diagram-3::before {
  content: "\f2ee";
}

.bi-diamond-fill::before {
  content: "\f2ef";
}

.bi-diamond-half::before {
  content: "\f2f0";
}

.bi-diamond::before {
  content: "\f2f1";
}

.bi-dice-1-fill::before {
  content: "\f2f2";
}

.bi-dice-1::before {
  content: "\f2f3";
}

.bi-dice-2-fill::before {
  content: "\f2f4";
}

.bi-dice-2::before {
  content: "\f2f5";
}

.bi-dice-3-fill::before {
  content: "\f2f6";
}

.bi-dice-3::before {
  content: "\f2f7";
}

.bi-dice-4-fill::before {
  content: "\f2f8";
}

.bi-dice-4::before {
  content: "\f2f9";
}

.bi-dice-5-fill::before {
  content: "\f2fa";
}

.bi-dice-5::before {
  content: "\f2fb";
}

.bi-dice-6-fill::before {
  content: "\f2fc";
}

.bi-dice-6::before {
  content: "\f2fd";
}

.bi-disc-fill::before {
  content: "\f2fe";
}

.bi-disc::before {
  content: "\f2ff";
}

.bi-discord::before {
  content: "\f300";
}

.bi-display-fill::before {
  content: "\f301";
}

.bi-display::before {
  content: "\f302";
}

.bi-distribute-horizontal::before {
  content: "\f303";
}

.bi-distribute-vertical::before {
  content: "\f304";
}

.bi-door-closed-fill::before {
  content: "\f305";
}

.bi-door-closed::before {
  content: "\f306";
}

.bi-door-open-fill::before {
  content: "\f307";
}

.bi-door-open::before {
  content: "\f308";
}

.bi-dot::before {
  content: "\f309";
}

.bi-download::before {
  content: "\f30a";
}

.bi-droplet-fill::before {
  content: "\f30b";
}

.bi-droplet-half::before {
  content: "\f30c";
}

.bi-droplet::before {
  content: "\f30d";
}

.bi-earbuds::before {
  content: "\f30e";
}

.bi-easel-fill::before {
  content: "\f30f";
}

.bi-easel::before {
  content: "\f310";
}

.bi-egg-fill::before {
  content: "\f311";
}

.bi-egg-fried::before {
  content: "\f312";
}

.bi-egg::before {
  content: "\f313";
}

.bi-eject-fill::before {
  content: "\f314";
}

.bi-eject::before {
  content: "\f315";
}

.bi-emoji-angry-fill::before {
  content: "\f316";
}

.bi-emoji-angry::before {
  content: "\f317";
}

.bi-emoji-dizzy-fill::before {
  content: "\f318";
}

.bi-emoji-dizzy::before {
  content: "\f319";
}

.bi-emoji-expressionless-fill::before {
  content: "\f31a";
}

.bi-emoji-expressionless::before {
  content: "\f31b";
}

.bi-emoji-frown-fill::before {
  content: "\f31c";
}

.bi-emoji-frown::before {
  content: "\f31d";
}

.bi-emoji-heart-eyes-fill::before {
  content: "\f31e";
}

.bi-emoji-heart-eyes::before {
  content: "\f31f";
}

.bi-emoji-laughing-fill::before {
  content: "\f320";
}

.bi-emoji-laughing::before {
  content: "\f321";
}

.bi-emoji-neutral-fill::before {
  content: "\f322";
}

.bi-emoji-neutral::before {
  content: "\f323";
}

.bi-emoji-smile-fill::before {
  content: "\f324";
}

.bi-emoji-smile-upside-down-fill::before {
  content: "\f325";
}

.bi-emoji-smile-upside-down::before {
  content: "\f326";
}

.bi-emoji-smile::before {
  content: "\f327";
}

.bi-emoji-sunglasses-fill::before {
  content: "\f328";
}

.bi-emoji-sunglasses::before {
  content: "\f329";
}

.bi-emoji-wink-fill::before {
  content: "\f32a";
}

.bi-emoji-wink::before {
  content: "\f32b";
}

.bi-envelope-fill::before {
  content: "\f32c";
}

.bi-envelope-open-fill::before {
  content: "\f32d";
}

.bi-envelope-open::before {
  content: "\f32e";
}

.bi-envelope::before {
  content: "\f32f";
}

.bi-eraser-fill::before {
  content: "\f330";
}

.bi-eraser::before {
  content: "\f331";
}

.bi-exclamation-circle-fill::before {
  content: "\f332";
}

.bi-exclamation-circle::before {
  content: "\f333";
}

.bi-exclamation-diamond-fill::before {
  content: "\f334";
}

.bi-exclamation-diamond::before {
  content: "\f335";
}

.bi-exclamation-octagon-fill::before {
  content: "\f336";
}

.bi-exclamation-octagon::before {
  content: "\f337";
}

.bi-exclamation-square-fill::before {
  content: "\f338";
}

.bi-exclamation-square::before {
  content: "\f339";
}

.bi-exclamation-triangle-fill::before {
  content: "\f33a";
}

.bi-exclamation-triangle::before {
  content: "\f33b";
}

.bi-exclamation::before {
  content: "\f33c";
}

.bi-exclude::before {
  content: "\f33d";
}

.bi-eye-fill::before {
  content: "\f33e";
}

.bi-eye-slash-fill::before {
  content: "\f33f";
}

.bi-eye-slash::before {
  content: "\f340";
}

.bi-eye::before {
  content: "\f341";
}

.bi-eyedropper::before {
  content: "\f342";
}

.bi-eyeglasses::before {
  content: "\f343";
}

.bi-facebook::before {
  content: "\f344";
}

.bi-file-arrow-down-fill::before {
  content: "\f345";
}

.bi-file-arrow-down::before {
  content: "\f346";
}

.bi-file-arrow-up-fill::before {
  content: "\f347";
}

.bi-file-arrow-up::before {
  content: "\f348";
}

.bi-file-bar-graph-fill::before {
  content: "\f349";
}

.bi-file-bar-graph::before {
  content: "\f34a";
}

.bi-file-binary-fill::before {
  content: "\f34b";
}

.bi-file-binary::before {
  content: "\f34c";
}

.bi-file-break-fill::before {
  content: "\f34d";
}

.bi-file-break::before {
  content: "\f34e";
}

.bi-file-check-fill::before {
  content: "\f34f";
}

.bi-file-check::before {
  content: "\f350";
}

.bi-file-code-fill::before {
  content: "\f351";
}

.bi-file-code::before {
  content: "\f352";
}

.bi-file-diff-fill::before {
  content: "\f353";
}

.bi-file-diff::before {
  content: "\f354";
}

.bi-file-earmark-arrow-down-fill::before {
  content: "\f355";
}

.bi-file-earmark-arrow-down::before {
  content: "\f356";
}

.bi-file-earmark-arrow-up-fill::before {
  content: "\f357";
}

.bi-file-earmark-arrow-up::before {
  content: "\f358";
}

.bi-file-earmark-bar-graph-fill::before {
  content: "\f359";
}

.bi-file-earmark-bar-graph::before {
  content: "\f35a";
}

.bi-file-earmark-binary-fill::before {
  content: "\f35b";
}

.bi-file-earmark-binary::before {
  content: "\f35c";
}

.bi-file-earmark-break-fill::before {
  content: "\f35d";
}

.bi-file-earmark-break::before {
  content: "\f35e";
}

.bi-file-earmark-check-fill::before {
  content: "\f35f";
}

.bi-file-earmark-check::before {
  content: "\f360";
}

.bi-file-earmark-code-fill::before {
  content: "\f361";
}

.bi-file-earmark-code::before {
  content: "\f362";
}

.bi-file-earmark-diff-fill::before {
  content: "\f363";
}

.bi-file-earmark-diff::before {
  content: "\f364";
}

.bi-file-earmark-easel-fill::before {
  content: "\f365";
}

.bi-file-earmark-easel::before {
  content: "\f366";
}

.bi-file-earmark-excel-fill::before {
  content: "\f367";
}

.bi-file-earmark-excel::before {
  content: "\f368";
}

.bi-file-earmark-fill::before {
  content: "\f369";
}

.bi-file-earmark-font-fill::before {
  content: "\f36a";
}

.bi-file-earmark-font::before {
  content: "\f36b";
}

.bi-file-earmark-image-fill::before {
  content: "\f36c";
}

.bi-file-earmark-image::before {
  content: "\f36d";
}

.bi-file-earmark-lock-fill::before {
  content: "\f36e";
}

.bi-file-earmark-lock::before {
  content: "\f36f";
}

.bi-file-earmark-lock2-fill::before {
  content: "\f370";
}

.bi-file-earmark-lock2::before {
  content: "\f371";
}

.bi-file-earmark-medical-fill::before {
  content: "\f372";
}

.bi-file-earmark-medical::before {
  content: "\f373";
}

.bi-file-earmark-minus-fill::before {
  content: "\f374";
}

.bi-file-earmark-minus::before {
  content: "\f375";
}

.bi-file-earmark-music-fill::before {
  content: "\f376";
}

.bi-file-earmark-music::before {
  content: "\f377";
}

.bi-file-earmark-person-fill::before {
  content: "\f378";
}

.bi-file-earmark-person::before {
  content: "\f379";
}

.bi-file-earmark-play-fill::before {
  content: "\f37a";
}

.bi-file-earmark-play::before {
  content: "\f37b";
}

.bi-file-earmark-plus-fill::before {
  content: "\f37c";
}

.bi-file-earmark-plus::before {
  content: "\f37d";
}

.bi-file-earmark-post-fill::before {
  content: "\f37e";
}

.bi-file-earmark-post::before {
  content: "\f37f";
}

.bi-file-earmark-ppt-fill::before {
  content: "\f380";
}

.bi-file-earmark-ppt::before {
  content: "\f381";
}

.bi-file-earmark-richtext-fill::before {
  content: "\f382";
}

.bi-file-earmark-richtext::before {
  content: "\f383";
}

.bi-file-earmark-ruled-fill::before {
  content: "\f384";
}

.bi-file-earmark-ruled::before {
  content: "\f385";
}

.bi-file-earmark-slides-fill::before {
  content: "\f386";
}

.bi-file-earmark-slides::before {
  content: "\f387";
}

.bi-file-earmark-spreadsheet-fill::before {
  content: "\f388";
}

.bi-file-earmark-spreadsheet::before {
  content: "\f389";
}

.bi-file-earmark-text-fill::before {
  content: "\f38a";
}

.bi-file-earmark-text::before {
  content: "\f38b";
}

.bi-file-earmark-word-fill::before {
  content: "\f38c";
}

.bi-file-earmark-word::before {
  content: "\f38d";
}

.bi-file-earmark-x-fill::before {
  content: "\f38e";
}

.bi-file-earmark-x::before {
  content: "\f38f";
}

.bi-file-earmark-zip-fill::before {
  content: "\f390";
}

.bi-file-earmark-zip::before {
  content: "\f391";
}

.bi-file-earmark::before {
  content: "\f392";
}

.bi-file-easel-fill::before {
  content: "\f393";
}

.bi-file-easel::before {
  content: "\f394";
}

.bi-file-excel-fill::before {
  content: "\f395";
}

.bi-file-excel::before {
  content: "\f396";
}

.bi-file-fill::before {
  content: "\f397";
}

.bi-file-font-fill::before {
  content: "\f398";
}

.bi-file-font::before {
  content: "\f399";
}

.bi-file-image-fill::before {
  content: "\f39a";
}

.bi-file-image::before {
  content: "\f39b";
}

.bi-file-lock-fill::before {
  content: "\f39c";
}

.bi-file-lock::before {
  content: "\f39d";
}

.bi-file-lock2-fill::before {
  content: "\f39e";
}

.bi-file-lock2::before {
  content: "\f39f";
}

.bi-file-medical-fill::before {
  content: "\f3a0";
}

.bi-file-medical::before {
  content: "\f3a1";
}

.bi-file-minus-fill::before {
  content: "\f3a2";
}

.bi-file-minus::before {
  content: "\f3a3";
}

.bi-file-music-fill::before {
  content: "\f3a4";
}

.bi-file-music::before {
  content: "\f3a5";
}

.bi-file-person-fill::before {
  content: "\f3a6";
}

.bi-file-person::before {
  content: "\f3a7";
}

.bi-file-play-fill::before {
  content: "\f3a8";
}

.bi-file-play::before {
  content: "\f3a9";
}

.bi-file-plus-fill::before {
  content: "\f3aa";
}

.bi-file-plus::before {
  content: "\f3ab";
}

.bi-file-post-fill::before {
  content: "\f3ac";
}

.bi-file-post::before {
  content: "\f3ad";
}

.bi-file-ppt-fill::before {
  content: "\f3ae";
}

.bi-file-ppt::before {
  content: "\f3af";
}

.bi-file-richtext-fill::before {
  content: "\f3b0";
}

.bi-file-richtext::before {
  content: "\f3b1";
}

.bi-file-ruled-fill::before {
  content: "\f3b2";
}

.bi-file-ruled::before {
  content: "\f3b3";
}

.bi-file-slides-fill::before {
  content: "\f3b4";
}

.bi-file-slides::before {
  content: "\f3b5";
}

.bi-file-spreadsheet-fill::before {
  content: "\f3b6";
}

.bi-file-spreadsheet::before {
  content: "\f3b7";
}

.bi-file-text-fill::before {
  content: "\f3b8";
}

.bi-file-text::before {
  content: "\f3b9";
}

.bi-file-word-fill::before {
  content: "\f3ba";
}

.bi-file-word::before {
  content: "\f3bb";
}

.bi-file-x-fill::before {
  content: "\f3bc";
}

.bi-file-x::before {
  content: "\f3bd";
}

.bi-file-zip-fill::before {
  content: "\f3be";
}

.bi-file-zip::before {
  content: "\f3bf";
}

.bi-file::before {
  content: "\f3c0";
}

.bi-files-alt::before {
  content: "\f3c1";
}

.bi-files::before {
  content: "\f3c2";
}

.bi-film::before {
  content: "\f3c3";
}

.bi-filter-circle-fill::before {
  content: "\f3c4";
}

.bi-filter-circle::before {
  content: "\f3c5";
}

.bi-filter-left::before {
  content: "\f3c6";
}

.bi-filter-right::before {
  content: "\f3c7";
}

.bi-filter-square-fill::before {
  content: "\f3c8";
}

.bi-filter-square::before {
  content: "\f3c9";
}

.bi-filter::before {
  content: "\f3ca";
}

.bi-flag-fill::before {
  content: "\f3cb";
}

.bi-flag::before {
  content: "\f3cc";
}

.bi-flower1::before {
  content: "\f3cd";
}

.bi-flower2::before {
  content: "\f3ce";
}

.bi-flower3::before {
  content: "\f3cf";
}

.bi-folder-check::before {
  content: "\f3d0";
}

.bi-folder-fill::before {
  content: "\f3d1";
}

.bi-folder-minus::before {
  content: "\f3d2";
}

.bi-folder-plus::before {
  content: "\f3d3";
}

.bi-folder-symlink-fill::before {
  content: "\f3d4";
}

.bi-folder-symlink::before {
  content: "\f3d5";
}

.bi-folder-x::before {
  content: "\f3d6";
}

.bi-folder::before {
  content: "\f3d7";
}

.bi-folder2-open::before {
  content: "\f3d8";
}

.bi-folder2::before {
  content: "\f3d9";
}

.bi-fonts::before {
  content: "\f3da";
}

.bi-forward-fill::before {
  content: "\f3db";
}

.bi-forward::before {
  content: "\f3dc";
}

.bi-front::before {
  content: "\f3dd";
}

.bi-fullscreen-exit::before {
  content: "\f3de";
}

.bi-fullscreen::before {
  content: "\f3df";
}

.bi-funnel-fill::before {
  content: "\f3e0";
}

.bi-funnel::before {
  content: "\f3e1";
}

.bi-gear-fill::before {
  content: "\f3e2";
}

.bi-gear-wide-connected::before {
  content: "\f3e3";
}

.bi-gear-wide::before {
  content: "\f3e4";
}

.bi-gear::before {
  content: "\f3e5";
}

.bi-gem::before {
  content: "\f3e6";
}

.bi-geo-alt-fill::before {
  content: "\f3e7";
}

.bi-geo-alt::before {
  content: "\f3e8";
}

.bi-geo-fill::before {
  content: "\f3e9";
}

.bi-geo::before {
  content: "\f3ea";
}

.bi-gift-fill::before {
  content: "\f3eb";
}

.bi-gift::before {
  content: "\f3ec";
}

.bi-github::before {
  content: "\f3ed";
}

.bi-globe::before {
  content: "\f3ee";
}

.bi-globe2::before {
  content: "\f3ef";
}

.bi-google::before {
  content: "\f3f0";
}

.bi-graph-down::before {
  content: "\f3f1";
}

.bi-graph-up::before {
  content: "\f3f2";
}

.bi-grid-1x2-fill::before {
  content: "\f3f3";
}

.bi-grid-1x2::before {
  content: "\f3f4";
}

.bi-grid-3x2-gap-fill::before {
  content: "\f3f5";
}

.bi-grid-3x2-gap::before {
  content: "\f3f6";
}

.bi-grid-3x2::before {
  content: "\f3f7";
}

.bi-grid-3x3-gap-fill::before {
  content: "\f3f8";
}

.bi-grid-3x3-gap::before {
  content: "\f3f9";
}

.bi-grid-3x3::before {
  content: "\f3fa";
}

.bi-grid-fill::before {
  content: "\f3fb";
}

.bi-grid::before {
  content: "\f3fc";
}

.bi-grip-horizontal::before {
  content: "\f3fd";
}

.bi-grip-vertical::before {
  content: "\f3fe";
}

.bi-hammer::before {
  content: "\f3ff";
}

.bi-hand-index-fill::before {
  content: "\f400";
}

.bi-hand-index-thumb-fill::before {
  content: "\f401";
}

.bi-hand-index-thumb::before {
  content: "\f402";
}

.bi-hand-index::before {
  content: "\f403";
}

.bi-hand-thumbs-down-fill::before {
  content: "\f404";
}

.bi-hand-thumbs-down::before {
  content: "\f405";
}

.bi-hand-thumbs-up-fill::before {
  content: "\f406";
}

.bi-hand-thumbs-up::before {
  content: "\f407";
}

.bi-handbag-fill::before {
  content: "\f408";
}

.bi-handbag::before {
  content: "\f409";
}

.bi-hash::before {
  content: "\f40a";
}

.bi-hdd-fill::before {
  content: "\f40b";
}

.bi-hdd-network-fill::before {
  content: "\f40c";
}

.bi-hdd-network::before {
  content: "\f40d";
}

.bi-hdd-rack-fill::before {
  content: "\f40e";
}

.bi-hdd-rack::before {
  content: "\f40f";
}

.bi-hdd-stack-fill::before {
  content: "\f410";
}

.bi-hdd-stack::before {
  content: "\f411";
}

.bi-hdd::before {
  content: "\f412";
}

.bi-headphones::before {
  content: "\f413";
}

.bi-headset::before {
  content: "\f414";
}

.bi-heart-fill::before {
  content: "\f415";
}

.bi-heart-half::before {
  content: "\f416";
}

.bi-heart::before {
  content: "\f417";
}

.bi-heptagon-fill::before {
  content: "\f418";
}

.bi-heptagon-half::before {
  content: "\f419";
}

.bi-heptagon::before {
  content: "\f41a";
}

.bi-hexagon-fill::before {
  content: "\f41b";
}

.bi-hexagon-half::before {
  content: "\f41c";
}

.bi-hexagon::before {
  content: "\f41d";
}

.bi-hourglass-bottom::before {
  content: "\f41e";
}

.bi-hourglass-split::before {
  content: "\f41f";
}

.bi-hourglass-top::before {
  content: "\f420";
}

.bi-hourglass::before {
  content: "\f421";
}

.bi-house-door-fill::before {
  content: "\f422";
}

.bi-house-door::before {
  content: "\f423";
}

.bi-house-fill::before {
  content: "\f424";
}

.bi-house::before {
  content: "\f425";
}

.bi-hr::before {
  content: "\f426";
}

.bi-hurricane::before {
  content: "\f427";
}

.bi-image-alt::before {
  content: "\f428";
}

.bi-image-fill::before {
  content: "\f429";
}

.bi-image::before {
  content: "\f42a";
}

.bi-images::before {
  content: "\f42b";
}

.bi-inbox-fill::before {
  content: "\f42c";
}

.bi-inbox::before {
  content: "\f42d";
}

.bi-inboxes-fill::before {
  content: "\f42e";
}

.bi-inboxes::before {
  content: "\f42f";
}

.bi-info-circle-fill::before {
  content: "\f430";
}

.bi-info-circle::before {
  content: "\f431";
}

.bi-info-square-fill::before {
  content: "\f432";
}

.bi-info-square::before {
  content: "\f433";
}

.bi-info::before {
  content: "\f434";
}

.bi-input-cursor-text::before {
  content: "\f435";
}

.bi-input-cursor::before {
  content: "\f436";
}

.bi-instagram::before {
  content: "\f437";
}

.bi-intersect::before {
  content: "\f438";
}

.bi-journal-album::before {
  content: "\f439";
}

.bi-journal-arrow-down::before {
  content: "\f43a";
}

.bi-journal-arrow-up::before {
  content: "\f43b";
}

.bi-journal-bookmark-fill::before {
  content: "\f43c";
}

.bi-journal-bookmark::before {
  content: "\f43d";
}

.bi-journal-check::before {
  content: "\f43e";
}

.bi-journal-code::before {
  content: "\f43f";
}

.bi-journal-medical::before {
  content: "\f440";
}

.bi-journal-minus::before {
  content: "\f441";
}

.bi-journal-plus::before {
  content: "\f442";
}

.bi-journal-richtext::before {
  content: "\f443";
}

.bi-journal-text::before {
  content: "\f444";
}

.bi-journal-x::before {
  content: "\f445";
}

.bi-journal::before {
  content: "\f446";
}

.bi-journals::before {
  content: "\f447";
}

.bi-joystick::before {
  content: "\f448";
}

.bi-justify-left::before {
  content: "\f449";
}

.bi-justify-right::before {
  content: "\f44a";
}

.bi-justify::before {
  content: "\f44b";
}

.bi-kanban-fill::before {
  content: "\f44c";
}

.bi-kanban::before {
  content: "\f44d";
}

.bi-key-fill::before {
  content: "\f44e";
}

.bi-key::before {
  content: "\f44f";
}

.bi-keyboard-fill::before {
  content: "\f450";
}

.bi-keyboard::before {
  content: "\f451";
}

.bi-ladder::before {
  content: "\f452";
}

.bi-lamp-fill::before {
  content: "\f453";
}

.bi-lamp::before {
  content: "\f454";
}

.bi-laptop-fill::before {
  content: "\f455";
}

.bi-laptop::before {
  content: "\f456";
}

.bi-layer-backward::before {
  content: "\f457";
}

.bi-layer-forward::before {
  content: "\f458";
}

.bi-layers-fill::before {
  content: "\f459";
}

.bi-layers-half::before {
  content: "\f45a";
}

.bi-layers::before {
  content: "\f45b";
}

.bi-layout-sidebar-inset-reverse::before {
  content: "\f45c";
}

.bi-layout-sidebar-inset::before {
  content: "\f45d";
}

.bi-layout-sidebar-reverse::before {
  content: "\f45e";
}

.bi-layout-sidebar::before {
  content: "\f45f";
}

.bi-layout-split::before {
  content: "\f460";
}

.bi-layout-text-sidebar-reverse::before {
  content: "\f461";
}

.bi-layout-text-sidebar::before {
  content: "\f462";
}

.bi-layout-text-window-reverse::before {
  content: "\f463";
}

.bi-layout-text-window::before {
  content: "\f464";
}

.bi-layout-three-columns::before {
  content: "\f465";
}

.bi-layout-wtf::before {
  content: "\f466";
}

.bi-life-preserver::before {
  content: "\f467";
}

.bi-lightbulb-fill::before {
  content: "\f468";
}

.bi-lightbulb-off-fill::before {
  content: "\f469";
}

.bi-lightbulb-off::before {
  content: "\f46a";
}

.bi-lightbulb::before {
  content: "\f46b";
}

.bi-lightning-charge-fill::before {
  content: "\f46c";
}

.bi-lightning-charge::before {
  content: "\f46d";
}

.bi-lightning-fill::before {
  content: "\f46e";
}

.bi-lightning::before {
  content: "\f46f";
}

.bi-link-45deg::before {
  content: "\f470";
}

.bi-link::before {
  content: "\f471";
}

.bi-linkedin::before {
  content: "\f472";
}

.bi-list-check::before {
  content: "\f473";
}

.bi-list-nested::before {
  content: "\f474";
}

.bi-list-ol::before {
  content: "\f475";
}

.bi-list-stars::before {
  content: "\f476";
}

.bi-list-task::before {
  content: "\f477";
}

.bi-list-ul::before {
  content: "\f478";
}

.bi-list::before {
  content: "\f479";
}

.bi-lock-fill::before {
  content: "\f47a";
}

.bi-lock::before {
  content: "\f47b";
}

.bi-mailbox::before {
  content: "\f47c";
}

.bi-mailbox2::before {
  content: "\f47d";
}

.bi-map-fill::before {
  content: "\f47e";
}

.bi-map::before {
  content: "\f47f";
}

.bi-markdown-fill::before {
  content: "\f480";
}

.bi-markdown::before {
  content: "\f481";
}

.bi-mask::before {
  content: "\f482";
}

.bi-megaphone-fill::before {
  content: "\f483";
}

.bi-megaphone::before {
  content: "\f484";
}

.bi-menu-app-fill::before {
  content: "\f485";
}

.bi-menu-app::before {
  content: "\f486";
}

.bi-menu-button-fill::before {
  content: "\f487";
}

.bi-menu-button-wide-fill::before {
  content: "\f488";
}

.bi-menu-button-wide::before {
  content: "\f489";
}

.bi-menu-button::before {
  content: "\f48a";
}

.bi-menu-down::before {
  content: "\f48b";
}

.bi-menu-up::before {
  content: "\f48c";
}

.bi-mic-fill::before {
  content: "\f48d";
}

.bi-mic-mute-fill::before {
  content: "\f48e";
}

.bi-mic-mute::before {
  content: "\f48f";
}

.bi-mic::before {
  content: "\f490";
}

.bi-minecart-loaded::before {
  content: "\f491";
}

.bi-minecart::before {
  content: "\f492";
}

.bi-moisture::before {
  content: "\f493";
}

.bi-moon-fill::before {
  content: "\f494";
}

.bi-moon-stars-fill::before {
  content: "\f495";
}

.bi-moon-stars::before {
  content: "\f496";
}

.bi-moon::before {
  content: "\f497";
}

.bi-mouse-fill::before {
  content: "\f498";
}

.bi-mouse::before {
  content: "\f499";
}

.bi-mouse2-fill::before {
  content: "\f49a";
}

.bi-mouse2::before {
  content: "\f49b";
}

.bi-mouse3-fill::before {
  content: "\f49c";
}

.bi-mouse3::before {
  content: "\f49d";
}

.bi-music-note-beamed::before {
  content: "\f49e";
}

.bi-music-note-list::before {
  content: "\f49f";
}

.bi-music-note::before {
  content: "\f4a0";
}

.bi-music-player-fill::before {
  content: "\f4a1";
}

.bi-music-player::before {
  content: "\f4a2";
}

.bi-newspaper::before {
  content: "\f4a3";
}

.bi-node-minus-fill::before {
  content: "\f4a4";
}

.bi-node-minus::before {
  content: "\f4a5";
}

.bi-node-plus-fill::before {
  content: "\f4a6";
}

.bi-node-plus::before {
  content: "\f4a7";
}

.bi-nut-fill::before {
  content: "\f4a8";
}

.bi-nut::before {
  content: "\f4a9";
}

.bi-octagon-fill::before {
  content: "\f4aa";
}

.bi-octagon-half::before {
  content: "\f4ab";
}

.bi-octagon::before {
  content: "\f4ac";
}

.bi-option::before {
  content: "\f4ad";
}

.bi-outlet::before {
  content: "\f4ae";
}

.bi-paint-bucket::before {
  content: "\f4af";
}

.bi-palette-fill::before {
  content: "\f4b0";
}

.bi-palette::before {
  content: "\f4b1";
}

.bi-palette2::before {
  content: "\f4b2";
}

.bi-paperclip::before {
  content: "\f4b3";
}

.bi-paragraph::before {
  content: "\f4b4";
}

.bi-patch-check-fill::before {
  content: "\f4b5";
}

.bi-patch-check::before {
  content: "\f4b6";
}

.bi-patch-exclamation-fill::before {
  content: "\f4b7";
}

.bi-patch-exclamation::before {
  content: "\f4b8";
}

.bi-patch-minus-fill::before {
  content: "\f4b9";
}

.bi-patch-minus::before {
  content: "\f4ba";
}

.bi-patch-plus-fill::before {
  content: "\f4bb";
}

.bi-patch-plus::before {
  content: "\f4bc";
}

.bi-patch-question-fill::before {
  content: "\f4bd";
}

.bi-patch-question::before {
  content: "\f4be";
}

.bi-pause-btn-fill::before {
  content: "\f4bf";
}

.bi-pause-btn::before {
  content: "\f4c0";
}

.bi-pause-circle-fill::before {
  content: "\f4c1";
}

.bi-pause-circle::before {
  content: "\f4c2";
}

.bi-pause-fill::before {
  content: "\f4c3";
}

.bi-pause::before {
  content: "\f4c4";
}

.bi-peace-fill::before {
  content: "\f4c5";
}

.bi-peace::before {
  content: "\f4c6";
}

.bi-pen-fill::before {
  content: "\f4c7";
}

.bi-pen::before {
  content: "\f4c8";
}

.bi-pencil-fill::before {
  content: "\f4c9";
}

.bi-pencil-square::before {
  content: "\f4ca";
}

.bi-pencil::before {
  content: "\f4cb";
}

.bi-pentagon-fill::before {
  content: "\f4cc";
}

.bi-pentagon-half::before {
  content: "\f4cd";
}

.bi-pentagon::before {
  content: "\f4ce";
}

.bi-people-fill::before {
  content: "\f4cf";
}

.bi-people::before {
  content: "\f4d0";
}

.bi-percent::before {
  content: "\f4d1";
}

.bi-person-badge-fill::before {
  content: "\f4d2";
}

.bi-person-badge::before {
  content: "\f4d3";
}

.bi-person-bounding-box::before {
  content: "\f4d4";
}

.bi-person-check-fill::before {
  content: "\f4d5";
}

.bi-person-check::before {
  content: "\f4d6";
}

.bi-person-circle::before {
  content: "\f4d7";
}

.bi-person-dash-fill::before {
  content: "\f4d8";
}

.bi-person-dash::before {
  content: "\f4d9";
}

.bi-person-fill::before {
  content: "\f4da";
}

.bi-person-lines-fill::before {
  content: "\f4db";
}

.bi-person-plus-fill::before {
  content: "\f4dc";
}

.bi-person-plus::before {
  content: "\f4dd";
}

.bi-person-square::before {
  content: "\f4de";
}

.bi-person-x-fill::before {
  content: "\f4df";
}

.bi-person-x::before {
  content: "\f4e0";
}

.bi-person::before {
  content: "\f4e1";
}

.bi-phone-fill::before {
  content: "\f4e2";
}

.bi-phone-landscape-fill::before {
  content: "\f4e3";
}

.bi-phone-landscape::before {
  content: "\f4e4";
}

.bi-phone-vibrate-fill::before {
  content: "\f4e5";
}

.bi-phone-vibrate::before {
  content: "\f4e6";
}

.bi-phone::before {
  content: "\f4e7";
}

.bi-pie-chart-fill::before {
  content: "\f4e8";
}

.bi-pie-chart::before {
  content: "\f4e9";
}

.bi-pin-angle-fill::before {
  content: "\f4ea";
}

.bi-pin-angle::before {
  content: "\f4eb";
}

.bi-pin-fill::before {
  content: "\f4ec";
}

.bi-pin::before {
  content: "\f4ed";
}

.bi-pip-fill::before {
  content: "\f4ee";
}

.bi-pip::before {
  content: "\f4ef";
}

.bi-play-btn-fill::before {
  content: "\f4f0";
}

.bi-play-btn::before {
  content: "\f4f1";
}

.bi-play-circle-fill::before {
  content: "\f4f2";
}

.bi-play-circle::before {
  content: "\f4f3";
}

.bi-play-fill::before {
  content: "\f4f4";
}

.bi-play::before {
  content: "\f4f5";
}

.bi-plug-fill::before {
  content: "\f4f6";
}

.bi-plug::before {
  content: "\f4f7";
}

.bi-plus-circle-dotted::before {
  content: "\f4f8";
}

.bi-plus-circle-fill::before {
  content: "\f4f9";
}

.bi-plus-circle::before {
  content: "\f4fa";
}

.bi-plus-square-dotted::before {
  content: "\f4fb";
}

.bi-plus-square-fill::before {
  content: "\f4fc";
}

.bi-plus-square::before {
  content: "\f4fd";
}

.bi-plus::before {
  content: "\f4fe";
}

.bi-power::before {
  content: "\f4ff";
}

.bi-printer-fill::before {
  content: "\f500";
}

.bi-printer::before {
  content: "\f501";
}

.bi-puzzle-fill::before {
  content: "\f502";
}

.bi-puzzle::before {
  content: "\f503";
}

.bi-question-circle-fill::before {
  content: "\f504";
}

.bi-question-circle::before {
  content: "\f505";
}

.bi-question-diamond-fill::before {
  content: "\f506";
}

.bi-question-diamond::before {
  content: "\f507";
}

.bi-question-octagon-fill::before {
  content: "\f508";
}

.bi-question-octagon::before {
  content: "\f509";
}

.bi-question-square-fill::before {
  content: "\f50a";
}

.bi-question-square::before {
  content: "\f50b";
}

.bi-question::before {
  content: "\f50c";
}

.bi-rainbow::before {
  content: "\f50d";
}

.bi-receipt-cutoff::before {
  content: "\f50e";
}

.bi-receipt::before {
  content: "\f50f";
}

.bi-reception-0::before {
  content: "\f510";
}

.bi-reception-1::before {
  content: "\f511";
}

.bi-reception-2::before {
  content: "\f512";
}

.bi-reception-3::before {
  content: "\f513";
}

.bi-reception-4::before {
  content: "\f514";
}

.bi-record-btn-fill::before {
  content: "\f515";
}

.bi-record-btn::before {
  content: "\f516";
}

.bi-record-circle-fill::before {
  content: "\f517";
}

.bi-record-circle::before {
  content: "\f518";
}

.bi-record-fill::before {
  content: "\f519";
}

.bi-record::before {
  content: "\f51a";
}

.bi-record2-fill::before {
  content: "\f51b";
}

.bi-record2::before {
  content: "\f51c";
}

.bi-reply-all-fill::before {
  content: "\f51d";
}

.bi-reply-all::before {
  content: "\f51e";
}

.bi-reply-fill::before {
  content: "\f51f";
}

.bi-reply::before {
  content: "\f520";
}

.bi-rss-fill::before {
  content: "\f521";
}

.bi-rss::before {
  content: "\f522";
}

.bi-rulers::before {
  content: "\f523";
}

.bi-save-fill::before {
  content: "\f524";
}

.bi-save::before {
  content: "\f525";
}

.bi-save2-fill::before {
  content: "\f526";
}

.bi-save2::before {
  content: "\f527";
}

.bi-scissors::before {
  content: "\f528";
}

.bi-screwdriver::before {
  content: "\f529";
}

.bi-search::before {
  content: "\f52a";
}

.bi-segmented-nav::before {
  content: "\f52b";
}

.bi-server::before {
  content: "\f52c";
}

.bi-share-fill::before {
  content: "\f52d";
}

.bi-share::before {
  content: "\f52e";
}

.bi-shield-check::before {
  content: "\f52f";
}

.bi-shield-exclamation::before {
  content: "\f530";
}

.bi-shield-fill-check::before {
  content: "\f531";
}

.bi-shield-fill-exclamation::before {
  content: "\f532";
}

.bi-shield-fill-minus::before {
  content: "\f533";
}

.bi-shield-fill-plus::before {
  content: "\f534";
}

.bi-shield-fill-x::before {
  content: "\f535";
}

.bi-shield-fill::before {
  content: "\f536";
}

.bi-shield-lock-fill::before {
  content: "\f537";
}

.bi-shield-lock::before {
  content: "\f538";
}

.bi-shield-minus::before {
  content: "\f539";
}

.bi-shield-plus::before {
  content: "\f53a";
}

.bi-shield-shaded::before {
  content: "\f53b";
}

.bi-shield-slash-fill::before {
  content: "\f53c";
}

.bi-shield-slash::before {
  content: "\f53d";
}

.bi-shield-x::before {
  content: "\f53e";
}

.bi-shield::before {
  content: "\f53f";
}

.bi-shift-fill::before {
  content: "\f540";
}

.bi-shift::before {
  content: "\f541";
}

.bi-shop-window::before {
  content: "\f542";
}

.bi-shop::before {
  content: "\f543";
}

.bi-shuffle::before {
  content: "\f544";
}

.bi-signpost-2-fill::before {
  content: "\f545";
}

.bi-signpost-2::before {
  content: "\f546";
}

.bi-signpost-fill::before {
  content: "\f547";
}

.bi-signpost-split-fill::before {
  content: "\f548";
}

.bi-signpost-split::before {
  content: "\f549";
}

.bi-signpost::before {
  content: "\f54a";
}

.bi-sim-fill::before {
  content: "\f54b";
}

.bi-sim::before {
  content: "\f54c";
}

.bi-skip-backward-btn-fill::before {
  content: "\f54d";
}

.bi-skip-backward-btn::before {
  content: "\f54e";
}

.bi-skip-backward-circle-fill::before {
  content: "\f54f";
}

.bi-skip-backward-circle::before {
  content: "\f550";
}

.bi-skip-backward-fill::before {
  content: "\f551";
}

.bi-skip-backward::before {
  content: "\f552";
}

.bi-skip-end-btn-fill::before {
  content: "\f553";
}

.bi-skip-end-btn::before {
  content: "\f554";
}

.bi-skip-end-circle-fill::before {
  content: "\f555";
}

.bi-skip-end-circle::before {
  content: "\f556";
}

.bi-skip-end-fill::before {
  content: "\f557";
}

.bi-skip-end::before {
  content: "\f558";
}

.bi-skip-forward-btn-fill::before {
  content: "\f559";
}

.bi-skip-forward-btn::before {
  content: "\f55a";
}

.bi-skip-forward-circle-fill::before {
  content: "\f55b";
}

.bi-skip-forward-circle::before {
  content: "\f55c";
}

.bi-skip-forward-fill::before {
  content: "\f55d";
}

.bi-skip-forward::before {
  content: "\f55e";
}

.bi-skip-start-btn-fill::before {
  content: "\f55f";
}

.bi-skip-start-btn::before {
  content: "\f560";
}

.bi-skip-start-circle-fill::before {
  content: "\f561";
}

.bi-skip-start-circle::before {
  content: "\f562";
}

.bi-skip-start-fill::before {
  content: "\f563";
}

.bi-skip-start::before {
  content: "\f564";
}

.bi-slack::before {
  content: "\f565";
}

.bi-slash-circle-fill::before {
  content: "\f566";
}

.bi-slash-circle::before {
  content: "\f567";
}

.bi-slash-square-fill::before {
  content: "\f568";
}

.bi-slash-square::before {
  content: "\f569";
}

.bi-slash::before {
  content: "\f56a";
}

.bi-sliders::before {
  content: "\f56b";
}

.bi-smartwatch::before {
  content: "\f56c";
}

.bi-snow::before {
  content: "\f56d";
}

.bi-snow2::before {
  content: "\f56e";
}

.bi-snow3::before {
  content: "\f56f";
}

.bi-sort-alpha-down-alt::before {
  content: "\f570";
}

.bi-sort-alpha-down::before {
  content: "\f571";
}

.bi-sort-alpha-up-alt::before {
  content: "\f572";
}

.bi-sort-alpha-up::before {
  content: "\f573";
}

.bi-sort-down-alt::before {
  content: "\f574";
}

.bi-sort-down::before {
  content: "\f575";
}

.bi-sort-numeric-down-alt::before {
  content: "\f576";
}

.bi-sort-numeric-down::before {
  content: "\f577";
}

.bi-sort-numeric-up-alt::before {
  content: "\f578";
}

.bi-sort-numeric-up::before {
  content: "\f579";
}

.bi-sort-up-alt::before {
  content: "\f57a";
}

.bi-sort-up::before {
  content: "\f57b";
}

.bi-soundwave::before {
  content: "\f57c";
}

.bi-speaker-fill::before {
  content: "\f57d";
}

.bi-speaker::before {
  content: "\f57e";
}

.bi-speedometer::before {
  content: "\f57f";
}

.bi-speedometer2::before {
  content: "\f580";
}

.bi-spellcheck::before {
  content: "\f581";
}

.bi-square-fill::before {
  content: "\f582";
}

.bi-square-half::before {
  content: "\f583";
}

.bi-square::before {
  content: "\f584";
}

.bi-stack::before {
  content: "\f585";
}

.bi-star-fill::before {
  content: "\f586";
}

.bi-star-half::before {
  content: "\f587";
}

.bi-star::before {
  content: "\f588";
}

.bi-stars::before {
  content: "\f589";
}

.bi-stickies-fill::before {
  content: "\f58a";
}

.bi-stickies::before {
  content: "\f58b";
}

.bi-sticky-fill::before {
  content: "\f58c";
}

.bi-sticky::before {
  content: "\f58d";
}

.bi-stop-btn-fill::before {
  content: "\f58e";
}

.bi-stop-btn::before {
  content: "\f58f";
}

.bi-stop-circle-fill::before {
  content: "\f590";
}

.bi-stop-circle::before {
  content: "\f591";
}

.bi-stop-fill::before {
  content: "\f592";
}

.bi-stop::before {
  content: "\f593";
}

.bi-stoplights-fill::before {
  content: "\f594";
}

.bi-stoplights::before {
  content: "\f595";
}

.bi-stopwatch-fill::before {
  content: "\f596";
}

.bi-stopwatch::before {
  content: "\f597";
}

.bi-subtract::before {
  content: "\f598";
}

.bi-suit-club-fill::before {
  content: "\f599";
}

.bi-suit-club::before {
  content: "\f59a";
}

.bi-suit-diamond-fill::before {
  content: "\f59b";
}

.bi-suit-diamond::before {
  content: "\f59c";
}

.bi-suit-heart-fill::before {
  content: "\f59d";
}

.bi-suit-heart::before {
  content: "\f59e";
}

.bi-suit-spade-fill::before {
  content: "\f59f";
}

.bi-suit-spade::before {
  content: "\f5a0";
}

.bi-sun-fill::before {
  content: "\f5a1";
}

.bi-sun::before {
  content: "\f5a2";
}

.bi-sunglasses::before {
  content: "\f5a3";
}

.bi-sunrise-fill::before {
  content: "\f5a4";
}

.bi-sunrise::before {
  content: "\f5a5";
}

.bi-sunset-fill::before {
  content: "\f5a6";
}

.bi-sunset::before {
  content: "\f5a7";
}

.bi-symmetry-horizontal::before {
  content: "\f5a8";
}

.bi-symmetry-vertical::before {
  content: "\f5a9";
}

.bi-table::before {
  content: "\f5aa";
}

.bi-tablet-fill::before {
  content: "\f5ab";
}

.bi-tablet-landscape-fill::before {
  content: "\f5ac";
}

.bi-tablet-landscape::before {
  content: "\f5ad";
}

.bi-tablet::before {
  content: "\f5ae";
}

.bi-tag-fill::before {
  content: "\f5af";
}

.bi-tag::before {
  content: "\f5b0";
}

.bi-tags-fill::before {
  content: "\f5b1";
}

.bi-tags::before {
  content: "\f5b2";
}

.bi-telegram::before {
  content: "\f5b3";
}

.bi-telephone-fill::before {
  content: "\f5b4";
}

.bi-telephone-forward-fill::before {
  content: "\f5b5";
}

.bi-telephone-forward::before {
  content: "\f5b6";
}

.bi-telephone-inbound-fill::before {
  content: "\f5b7";
}

.bi-telephone-inbound::before {
  content: "\f5b8";
}

.bi-telephone-minus-fill::before {
  content: "\f5b9";
}

.bi-telephone-minus::before {
  content: "\f5ba";
}

.bi-telephone-outbound-fill::before {
  content: "\f5bb";
}

.bi-telephone-outbound::before {
  content: "\f5bc";
}

.bi-telephone-plus-fill::before {
  content: "\f5bd";
}

.bi-telephone-plus::before {
  content: "\f5be";
}

.bi-telephone-x-fill::before {
  content: "\f5bf";
}

.bi-telephone-x::before {
  content: "\f5c0";
}

.bi-telephone::before {
  content: "\f5c1";
}

.bi-terminal-fill::before {
  content: "\f5c2";
}

.bi-terminal::before {
  content: "\f5c3";
}

.bi-text-center::before {
  content: "\f5c4";
}

.bi-text-indent-left::before {
  content: "\f5c5";
}

.bi-text-indent-right::before {
  content: "\f5c6";
}

.bi-text-left::before {
  content: "\f5c7";
}

.bi-text-paragraph::before {
  content: "\f5c8";
}

.bi-text-right::before {
  content: "\f5c9";
}

.bi-textarea-resize::before {
  content: "\f5ca";
}

.bi-textarea-t::before {
  content: "\f5cb";
}

.bi-textarea::before {
  content: "\f5cc";
}

.bi-thermometer-half::before {
  content: "\f5cd";
}

.bi-thermometer-high::before {
  content: "\f5ce";
}

.bi-thermometer-low::before {
  content: "\f5cf";
}

.bi-thermometer-snow::before {
  content: "\f5d0";
}

.bi-thermometer-sun::before {
  content: "\f5d1";
}

.bi-thermometer::before {
  content: "\f5d2";
}

.bi-three-dots-vertical::before {
  content: "\f5d3";
}

.bi-three-dots::before {
  content: "\f5d4";
}

.bi-toggle-off::before {
  content: "\f5d5";
}

.bi-toggle-on::before {
  content: "\f5d6";
}

.bi-toggle2-off::before {
  content: "\f5d7";
}

.bi-toggle2-on::before {
  content: "\f5d8";
}

.bi-toggles::before {
  content: "\f5d9";
}

.bi-toggles2::before {
  content: "\f5da";
}

.bi-tools::before {
  content: "\f5db";
}

.bi-tornado::before {
  content: "\f5dc";
}

.bi-trash-fill::before {
  content: "\f5dd";
}

.bi-trash::before {
  content: "\f5de";
}

.bi-trash2-fill::before {
  content: "\f5df";
}

.bi-trash2::before {
  content: "\f5e0";
}

.bi-tree-fill::before {
  content: "\f5e1";
}

.bi-tree::before {
  content: "\f5e2";
}

.bi-triangle-fill::before {
  content: "\f5e3";
}

.bi-triangle-half::before {
  content: "\f5e4";
}

.bi-triangle::before {
  content: "\f5e5";
}

.bi-trophy-fill::before {
  content: "\f5e6";
}

.bi-trophy::before {
  content: "\f5e7";
}

.bi-tropical-storm::before {
  content: "\f5e8";
}

.bi-truck-flatbed::before {
  content: "\f5e9";
}

.bi-truck::before {
  content: "\f5ea";
}

.bi-tsunami::before {
  content: "\f5eb";
}

.bi-tv-fill::before {
  content: "\f5ec";
}

.bi-tv::before {
  content: "\f5ed";
}

.bi-twitch::before {
  content: "\f5ee";
}

.bi-twitter::before {
  content: "\f5ef";
}

.bi-type-bold::before {
  content: "\f5f0";
}

.bi-type-h1::before {
  content: "\f5f1";
}

.bi-type-h2::before {
  content: "\f5f2";
}

.bi-type-h3::before {
  content: "\f5f3";
}

.bi-type-italic::before {
  content: "\f5f4";
}

.bi-type-strikethrough::before {
  content: "\f5f5";
}

.bi-type-underline::before {
  content: "\f5f6";
}

.bi-type::before {
  content: "\f5f7";
}

.bi-ui-checks-grid::before {
  content: "\f5f8";
}

.bi-ui-checks::before {
  content: "\f5f9";
}

.bi-ui-radios-grid::before {
  content: "\f5fa";
}

.bi-ui-radios::before {
  content: "\f5fb";
}

.bi-umbrella-fill::before {
  content: "\f5fc";
}

.bi-umbrella::before {
  content: "\f5fd";
}

.bi-union::before {
  content: "\f5fe";
}

.bi-unlock-fill::before {
  content: "\f5ff";
}

.bi-unlock::before {
  content: "\f600";
}

.bi-upc-scan::before {
  content: "\f601";
}

.bi-upc::before {
  content: "\f602";
}

.bi-upload::before {
  content: "\f603";
}

.bi-vector-pen::before {
  content: "\f604";
}

.bi-view-list::before {
  content: "\f605";
}

.bi-view-stacked::before {
  content: "\f606";
}

.bi-vinyl-fill::before {
  content: "\f607";
}

.bi-vinyl::before {
  content: "\f608";
}

.bi-voicemail::before {
  content: "\f609";
}

.bi-volume-down-fill::before {
  content: "\f60a";
}

.bi-volume-down::before {
  content: "\f60b";
}

.bi-volume-mute-fill::before {
  content: "\f60c";
}

.bi-volume-mute::before {
  content: "\f60d";
}

.bi-volume-off-fill::before {
  content: "\f60e";
}

.bi-volume-off::before {
  content: "\f60f";
}

.bi-volume-up-fill::before {
  content: "\f610";
}

.bi-volume-up::before {
  content: "\f611";
}

.bi-vr::before {
  content: "\f612";
}

.bi-wallet-fill::before {
  content: "\f613";
}

.bi-wallet::before {
  content: "\f614";
}

.bi-wallet2::before {
  content: "\f615";
}

.bi-watch::before {
  content: "\f616";
}

.bi-water::before {
  content: "\f617";
}

.bi-whatsapp::before {
  content: "\f618";
}

.bi-wifi-1::before {
  content: "\f619";
}

.bi-wifi-2::before {
  content: "\f61a";
}

.bi-wifi-off::before {
  content: "\f61b";
}

.bi-wifi::before {
  content: "\f61c";
}

.bi-wind::before {
  content: "\f61d";
}

.bi-window-dock::before {
  content: "\f61e";
}

.bi-window-sidebar::before {
  content: "\f61f";
}

.bi-window::before {
  content: "\f620";
}

.bi-wrench::before {
  content: "\f621";
}

.bi-x-circle-fill::before {
  content: "\f622";
}

.bi-x-circle::before {
  content: "\f623";
}

.bi-x-diamond-fill::before {
  content: "\f624";
}

.bi-x-diamond::before {
  content: "\f625";
}

.bi-x-octagon-fill::before {
  content: "\f626";
}

.bi-x-octagon::before {
  content: "\f627";
}

.bi-x-square-fill::before {
  content: "\f628";
}

.bi-x-square::before {
  content: "\f629";
}

.bi-x::before {
  content: "\f62a";
}

.bi-youtube::before {
  content: "\f62b";
}

.bi-zoom-in::before {
  content: "\f62c";
}

.bi-zoom-out::before {
  content: "\f62d";
}

.bi-bank::before {
  content: "\f62e";
}

.bi-bank2::before {
  content: "\f62f";
}

.bi-bell-slash-fill::before {
  content: "\f630";
}

.bi-bell-slash::before {
  content: "\f631";
}

.bi-cash-coin::before {
  content: "\f632";
}

.bi-check-lg::before {
  content: "\f633";
}

.bi-coin::before {
  content: "\f634";
}

.bi-currency-bitcoin::before {
  content: "\f635";
}

.bi-currency-dollar::before {
  content: "\f636";
}

.bi-currency-euro::before {
  content: "\f637";
}

.bi-currency-exchange::before {
  content: "\f638";
}

.bi-currency-pound::before {
  content: "\f639";
}

.bi-currency-yen::before {
  content: "\f63a";
}

.bi-dash-lg::before {
  content: "\f63b";
}

.bi-exclamation-lg::before {
  content: "\f63c";
}

.bi-file-earmark-pdf-fill::before {
  content: "\f63d";
}

.bi-file-earmark-pdf::before {
  content: "\f63e";
}

.bi-file-pdf-fill::before {
  content: "\f63f";
}

.bi-file-pdf::before {
  content: "\f640";
}

.bi-gender-ambiguous::before {
  content: "\f641";
}

.bi-gender-female::before {
  content: "\f642";
}

.bi-gender-male::before {
  content: "\f643";
}

.bi-gender-trans::before {
  content: "\f644";
}

.bi-headset-vr::before {
  content: "\f645";
}

.bi-info-lg::before {
  content: "\f646";
}

.bi-mastodon::before {
  content: "\f647";
}

.bi-messenger::before {
  content: "\f648";
}

.bi-piggy-bank-fill::before {
  content: "\f649";
}

.bi-piggy-bank::before {
  content: "\f64a";
}

.bi-pin-map-fill::before {
  content: "\f64b";
}

.bi-pin-map::before {
  content: "\f64c";
}

.bi-plus-lg::before {
  content: "\f64d";
}

.bi-question-lg::before {
  content: "\f64e";
}

.bi-recycle::before {
  content: "\f64f";
}

.bi-reddit::before {
  content: "\f650";
}

.bi-safe-fill::before {
  content: "\f651";
}

.bi-safe2-fill::before {
  content: "\f652";
}

.bi-safe2::before {
  content: "\f653";
}

.bi-sd-card-fill::before {
  content: "\f654";
}

.bi-sd-card::before {
  content: "\f655";
}

.bi-skype::before {
  content: "\f656";
}

.bi-slash-lg::before {
  content: "\f657";
}

.bi-translate::before {
  content: "\f658";
}

.bi-x-lg::before {
  content: "\f659";
}

.bi-safe::before {
  content: "\f65a";
}

.bi-apple::before {
  content: "\f65b";
}

.bi-microsoft::before {
  content: "\f65d";
}

.bi-windows::before {
  content: "\f65e";
}

.bi-behance::before {
  content: "\f65c";
}

.bi-dribbble::before {
  content: "\f65f";
}

.bi-line::before {
  content: "\f660";
}

.bi-medium::before {
  content: "\f661";
}

.bi-paypal::before {
  content: "\f662";
}

.bi-pinterest::before {
  content: "\f663";
}

.bi-signal::before {
  content: "\f664";
}

.bi-snapchat::before {
  content: "\f665";
}

.bi-spotify::before {
  content: "\f666";
}

.bi-stack-overflow::before {
  content: "\f667";
}

.bi-strava::before {
  content: "\f668";
}

.bi-wordpress::before {
  content: "\f669";
}

.bi-vimeo::before {
  content: "\f66a";
}

.bi-activity::before {
  content: "\f66b";
}

.bi-easel2-fill::before {
  content: "\f66c";
}

.bi-easel2::before {
  content: "\f66d";
}

.bi-easel3-fill::before {
  content: "\f66e";
}

.bi-easel3::before {
  content: "\f66f";
}

.bi-fan::before {
  content: "\f670";
}

.bi-fingerprint::before {
  content: "\f671";
}

.bi-graph-down-arrow::before {
  content: "\f672";
}

.bi-graph-up-arrow::before {
  content: "\f673";
}

.bi-hypnotize::before {
  content: "\f674";
}

.bi-magic::before {
  content: "\f675";
}

.bi-person-rolodex::before {
  content: "\f676";
}

.bi-person-video::before {
  content: "\f677";
}

.bi-person-video2::before {
  content: "\f678";
}

.bi-person-video3::before {
  content: "\f679";
}

.bi-person-workspace::before {
  content: "\f67a";
}

.bi-radioactive::before {
  content: "\f67b";
}

.bi-webcam-fill::before {
  content: "\f67c";
}

.bi-webcam::before {
  content: "\f67d";
}

.bi-yin-yang::before {
  content: "\f67e";
}

.bi-bandaid-fill::before {
  content: "\f680";
}

.bi-bandaid::before {
  content: "\f681";
}

.bi-bluetooth::before {
  content: "\f682";
}

.bi-body-text::before {
  content: "\f683";
}

.bi-boombox::before {
  content: "\f684";
}

.bi-boxes::before {
  content: "\f685";
}

.bi-dpad-fill::before {
  content: "\f686";
}

.bi-dpad::before {
  content: "\f687";
}

.bi-ear-fill::before {
  content: "\f688";
}

.bi-ear::before {
  content: "\f689";
}

.bi-envelope-check-fill::before {
  content: "\f68b";
}

.bi-envelope-check::before {
  content: "\f68c";
}

.bi-envelope-dash-fill::before {
  content: "\f68e";
}

.bi-envelope-dash::before {
  content: "\f68f";
}

.bi-envelope-exclamation-fill::before {
  content: "\f691";
}

.bi-envelope-exclamation::before {
  content: "\f692";
}

.bi-envelope-plus-fill::before {
  content: "\f693";
}

.bi-envelope-plus::before {
  content: "\f694";
}

.bi-envelope-slash-fill::before {
  content: "\f696";
}

.bi-envelope-slash::before {
  content: "\f697";
}

.bi-envelope-x-fill::before {
  content: "\f699";
}

.bi-envelope-x::before {
  content: "\f69a";
}

.bi-explicit-fill::before {
  content: "\f69b";
}

.bi-explicit::before {
  content: "\f69c";
}

.bi-git::before {
  content: "\f69d";
}

.bi-infinity::before {
  content: "\f69e";
}

.bi-list-columns-reverse::before {
  content: "\f69f";
}

.bi-list-columns::before {
  content: "\f6a0";
}

.bi-meta::before {
  content: "\f6a1";
}

.bi-nintendo-switch::before {
  content: "\f6a4";
}

.bi-pc-display-horizontal::before {
  content: "\f6a5";
}

.bi-pc-display::before {
  content: "\f6a6";
}

.bi-pc-horizontal::before {
  content: "\f6a7";
}

.bi-pc::before {
  content: "\f6a8";
}

.bi-playstation::before {
  content: "\f6a9";
}

.bi-plus-slash-minus::before {
  content: "\f6aa";
}

.bi-projector-fill::before {
  content: "\f6ab";
}

.bi-projector::before {
  content: "\f6ac";
}

.bi-qr-code-scan::before {
  content: "\f6ad";
}

.bi-qr-code::before {
  content: "\f6ae";
}

.bi-quora::before {
  content: "\f6af";
}

.bi-quote::before {
  content: "\f6b0";
}

.bi-robot::before {
  content: "\f6b1";
}

.bi-send-check-fill::before {
  content: "\f6b2";
}

.bi-send-check::before {
  content: "\f6b3";
}

.bi-send-dash-fill::before {
  content: "\f6b4";
}

.bi-send-dash::before {
  content: "\f6b5";
}

.bi-send-exclamation-fill::before {
  content: "\f6b7";
}

.bi-send-exclamation::before {
  content: "\f6b8";
}

.bi-send-fill::before {
  content: "\f6b9";
}

.bi-send-plus-fill::before {
  content: "\f6ba";
}

.bi-send-plus::before {
  content: "\f6bb";
}

.bi-send-slash-fill::before {
  content: "\f6bc";
}

.bi-send-slash::before {
  content: "\f6bd";
}

.bi-send-x-fill::before {
  content: "\f6be";
}

.bi-send-x::before {
  content: "\f6bf";
}

.bi-send::before {
  content: "\f6c0";
}

.bi-steam::before {
  content: "\f6c1";
}

.bi-terminal-dash::before {
  content: "\f6c3";
}

.bi-terminal-plus::before {
  content: "\f6c4";
}

.bi-terminal-split::before {
  content: "\f6c5";
}

.bi-ticket-detailed-fill::before {
  content: "\f6c6";
}

.bi-ticket-detailed::before {
  content: "\f6c7";
}

.bi-ticket-fill::before {
  content: "\f6c8";
}

.bi-ticket-perforated-fill::before {
  content: "\f6c9";
}

.bi-ticket-perforated::before {
  content: "\f6ca";
}

.bi-ticket::before {
  content: "\f6cb";
}

.bi-tiktok::before {
  content: "\f6cc";
}

.bi-window-dash::before {
  content: "\f6cd";
}

.bi-window-desktop::before {
  content: "\f6ce";
}

.bi-window-fullscreen::before {
  content: "\f6cf";
}

.bi-window-plus::before {
  content: "\f6d0";
}

.bi-window-split::before {
  content: "\f6d1";
}

.bi-window-stack::before {
  content: "\f6d2";
}

.bi-window-x::before {
  content: "\f6d3";
}

.bi-xbox::before {
  content: "\f6d4";
}

.bi-ethernet::before {
  content: "\f6d5";
}

.bi-hdmi-fill::before {
  content: "\f6d6";
}

.bi-hdmi::before {
  content: "\f6d7";
}

.bi-usb-c-fill::before {
  content: "\f6d8";
}

.bi-usb-c::before {
  content: "\f6d9";
}

.bi-usb-fill::before {
  content: "\f6da";
}

.bi-usb-plug-fill::before {
  content: "\f6db";
}

.bi-usb-plug::before {
  content: "\f6dc";
}

.bi-usb-symbol::before {
  content: "\f6dd";
}

.bi-usb::before {
  content: "\f6de";
}

.bi-boombox-fill::before {
  content: "\f6df";
}

.bi-displayport::before {
  content: "\f6e1";
}

.bi-gpu-card::before {
  content: "\f6e2";
}

.bi-memory::before {
  content: "\f6e3";
}

.bi-modem-fill::before {
  content: "\f6e4";
}

.bi-modem::before {
  content: "\f6e5";
}

.bi-motherboard-fill::before {
  content: "\f6e6";
}

.bi-motherboard::before {
  content: "\f6e7";
}

.bi-optical-audio-fill::before {
  content: "\f6e8";
}

.bi-optical-audio::before {
  content: "\f6e9";
}

.bi-pci-card::before {
  content: "\f6ea";
}

.bi-router-fill::before {
  content: "\f6eb";
}

.bi-router::before {
  content: "\f6ec";
}

.bi-thunderbolt-fill::before {
  content: "\f6ef";
}

.bi-thunderbolt::before {
  content: "\f6f0";
}

.bi-usb-drive-fill::before {
  content: "\f6f1";
}

.bi-usb-drive::before {
  content: "\f6f2";
}

.bi-usb-micro-fill::before {
  content: "\f6f3";
}

.bi-usb-micro::before {
  content: "\f6f4";
}

.bi-usb-mini-fill::before {
  content: "\f6f5";
}

.bi-usb-mini::before {
  content: "\f6f6";
}

.bi-cloud-haze2::before {
  content: "\f6f7";
}

.bi-device-hdd-fill::before {
  content: "\f6f8";
}

.bi-device-hdd::before {
  content: "\f6f9";
}

.bi-device-ssd-fill::before {
  content: "\f6fa";
}

.bi-device-ssd::before {
  content: "\f6fb";
}

.bi-displayport-fill::before {
  content: "\f6fc";
}

.bi-mortarboard-fill::before {
  content: "\f6fd";
}

.bi-mortarboard::before {
  content: "\f6fe";
}

.bi-terminal-x::before {
  content: "\f6ff";
}

.bi-arrow-through-heart-fill::before {
  content: "\f700";
}

.bi-arrow-through-heart::before {
  content: "\f701";
}

.bi-badge-sd-fill::before {
  content: "\f702";
}

.bi-badge-sd::before {
  content: "\f703";
}

.bi-bag-heart-fill::before {
  content: "\f704";
}

.bi-bag-heart::before {
  content: "\f705";
}

.bi-balloon-fill::before {
  content: "\f706";
}

.bi-balloon-heart-fill::before {
  content: "\f707";
}

.bi-balloon-heart::before {
  content: "\f708";
}

.bi-balloon::before {
  content: "\f709";
}

.bi-box2-fill::before {
  content: "\f70a";
}

.bi-box2-heart-fill::before {
  content: "\f70b";
}

.bi-box2-heart::before {
  content: "\f70c";
}

.bi-box2::before {
  content: "\f70d";
}

.bi-braces-asterisk::before {
  content: "\f70e";
}

.bi-calendar-heart-fill::before {
  content: "\f70f";
}

.bi-calendar-heart::before {
  content: "\f710";
}

.bi-calendar2-heart-fill::before {
  content: "\f711";
}

.bi-calendar2-heart::before {
  content: "\f712";
}

.bi-chat-heart-fill::before {
  content: "\f713";
}

.bi-chat-heart::before {
  content: "\f714";
}

.bi-chat-left-heart-fill::before {
  content: "\f715";
}

.bi-chat-left-heart::before {
  content: "\f716";
}

.bi-chat-right-heart-fill::before {
  content: "\f717";
}

.bi-chat-right-heart::before {
  content: "\f718";
}

.bi-chat-square-heart-fill::before {
  content: "\f719";
}

.bi-chat-square-heart::before {
  content: "\f71a";
}

.bi-clipboard-check-fill::before {
  content: "\f71b";
}

.bi-clipboard-data-fill::before {
  content: "\f71c";
}

.bi-clipboard-fill::before {
  content: "\f71d";
}

.bi-clipboard-heart-fill::before {
  content: "\f71e";
}

.bi-clipboard-heart::before {
  content: "\f71f";
}

.bi-clipboard-minus-fill::before {
  content: "\f720";
}

.bi-clipboard-plus-fill::before {
  content: "\f721";
}

.bi-clipboard-pulse::before {
  content: "\f722";
}

.bi-clipboard-x-fill::before {
  content: "\f723";
}

.bi-clipboard2-check-fill::before {
  content: "\f724";
}

.bi-clipboard2-check::before {
  content: "\f725";
}

.bi-clipboard2-data-fill::before {
  content: "\f726";
}

.bi-clipboard2-data::before {
  content: "\f727";
}

.bi-clipboard2-fill::before {
  content: "\f728";
}

.bi-clipboard2-heart-fill::before {
  content: "\f729";
}

.bi-clipboard2-heart::before {
  content: "\f72a";
}

.bi-clipboard2-minus-fill::before {
  content: "\f72b";
}

.bi-clipboard2-minus::before {
  content: "\f72c";
}

.bi-clipboard2-plus-fill::before {
  content: "\f72d";
}

.bi-clipboard2-plus::before {
  content: "\f72e";
}

.bi-clipboard2-pulse-fill::before {
  content: "\f72f";
}

.bi-clipboard2-pulse::before {
  content: "\f730";
}

.bi-clipboard2-x-fill::before {
  content: "\f731";
}

.bi-clipboard2-x::before {
  content: "\f732";
}

.bi-clipboard2::before {
  content: "\f733";
}

.bi-emoji-kiss-fill::before {
  content: "\f734";
}

.bi-emoji-kiss::before {
  content: "\f735";
}

.bi-envelope-heart-fill::before {
  content: "\f736";
}

.bi-envelope-heart::before {
  content: "\f737";
}

.bi-envelope-open-heart-fill::before {
  content: "\f738";
}

.bi-envelope-open-heart::before {
  content: "\f739";
}

.bi-envelope-paper-fill::before {
  content: "\f73a";
}

.bi-envelope-paper-heart-fill::before {
  content: "\f73b";
}

.bi-envelope-paper-heart::before {
  content: "\f73c";
}

.bi-envelope-paper::before {
  content: "\f73d";
}

.bi-filetype-aac::before {
  content: "\f73e";
}

.bi-filetype-ai::before {
  content: "\f73f";
}

.bi-filetype-bmp::before {
  content: "\f740";
}

.bi-filetype-cs::before {
  content: "\f741";
}

.bi-filetype-css::before {
  content: "\f742";
}

.bi-filetype-csv::before {
  content: "\f743";
}

.bi-filetype-doc::before {
  content: "\f744";
}

.bi-filetype-docx::before {
  content: "\f745";
}

.bi-filetype-exe::before {
  content: "\f746";
}

.bi-filetype-gif::before {
  content: "\f747";
}

.bi-filetype-heic::before {
  content: "\f748";
}

.bi-filetype-html::before {
  content: "\f749";
}

.bi-filetype-java::before {
  content: "\f74a";
}

.bi-filetype-jpg::before {
  content: "\f74b";
}

.bi-filetype-js::before {
  content: "\f74c";
}

.bi-filetype-jsx::before {
  content: "\f74d";
}

.bi-filetype-key::before {
  content: "\f74e";
}

.bi-filetype-m4p::before {
  content: "\f74f";
}

.bi-filetype-md::before {
  content: "\f750";
}

.bi-filetype-mdx::before {
  content: "\f751";
}

.bi-filetype-mov::before {
  content: "\f752";
}

.bi-filetype-mp3::before {
  content: "\f753";
}

.bi-filetype-mp4::before {
  content: "\f754";
}

.bi-filetype-otf::before {
  content: "\f755";
}

.bi-filetype-pdf::before {
  content: "\f756";
}

.bi-filetype-php::before {
  content: "\f757";
}

.bi-filetype-png::before {
  content: "\f758";
}

.bi-filetype-ppt::before {
  content: "\f75a";
}

.bi-filetype-psd::before {
  content: "\f75b";
}

.bi-filetype-py::before {
  content: "\f75c";
}

.bi-filetype-raw::before {
  content: "\f75d";
}

.bi-filetype-rb::before {
  content: "\f75e";
}

.bi-filetype-sass::before {
  content: "\f75f";
}

.bi-filetype-scss::before {
  content: "\f760";
}

.bi-filetype-sh::before {
  content: "\f761";
}

.bi-filetype-svg::before {
  content: "\f762";
}

.bi-filetype-tiff::before {
  content: "\f763";
}

.bi-filetype-tsx::before {
  content: "\f764";
}

.bi-filetype-ttf::before {
  content: "\f765";
}

.bi-filetype-txt::before {
  content: "\f766";
}

.bi-filetype-wav::before {
  content: "\f767";
}

.bi-filetype-woff::before {
  content: "\f768";
}

.bi-filetype-xls::before {
  content: "\f76a";
}

.bi-filetype-xml::before {
  content: "\f76b";
}

.bi-filetype-yml::before {
  content: "\f76c";
}

.bi-heart-arrow::before {
  content: "\f76d";
}

.bi-heart-pulse-fill::before {
  content: "\f76e";
}

.bi-heart-pulse::before {
  content: "\f76f";
}

.bi-heartbreak-fill::before {
  content: "\f770";
}

.bi-heartbreak::before {
  content: "\f771";
}

.bi-hearts::before {
  content: "\f772";
}

.bi-hospital-fill::before {
  content: "\f773";
}

.bi-hospital::before {
  content: "\f774";
}

.bi-house-heart-fill::before {
  content: "\f775";
}

.bi-house-heart::before {
  content: "\f776";
}

.bi-incognito::before {
  content: "\f777";
}

.bi-magnet-fill::before {
  content: "\f778";
}

.bi-magnet::before {
  content: "\f779";
}

.bi-person-heart::before {
  content: "\f77a";
}

.bi-person-hearts::before {
  content: "\f77b";
}

.bi-phone-flip::before {
  content: "\f77c";
}

.bi-plugin::before {
  content: "\f77d";
}

.bi-postage-fill::before {
  content: "\f77e";
}

.bi-postage-heart-fill::before {
  content: "\f77f";
}

.bi-postage-heart::before {
  content: "\f780";
}

.bi-postage::before {
  content: "\f781";
}

.bi-postcard-fill::before {
  content: "\f782";
}

.bi-postcard-heart-fill::before {
  content: "\f783";
}

.bi-postcard-heart::before {
  content: "\f784";
}

.bi-postcard::before {
  content: "\f785";
}

.bi-search-heart-fill::before {
  content: "\f786";
}

.bi-search-heart::before {
  content: "\f787";
}

.bi-sliders2-vertical::before {
  content: "\f788";
}

.bi-sliders2::before {
  content: "\f789";
}

.bi-trash3-fill::before {
  content: "\f78a";
}

.bi-trash3::before {
  content: "\f78b";
}

.bi-valentine::before {
  content: "\f78c";
}

.bi-valentine2::before {
  content: "\f78d";
}

.bi-wrench-adjustable-circle-fill::before {
  content: "\f78e";
}

.bi-wrench-adjustable-circle::before {
  content: "\f78f";
}

.bi-wrench-adjustable::before {
  content: "\f790";
}

.bi-filetype-json::before {
  content: "\f791";
}

.bi-filetype-pptx::before {
  content: "\f792";
}

.bi-filetype-xlsx::before {
  content: "\f793";
}

.bi-1-circle-fill::before {
  content: "\f796";
}

.bi-1-circle::before {
  content: "\f797";
}

.bi-1-square-fill::before {
  content: "\f798";
}

.bi-1-square::before {
  content: "\f799";
}

.bi-2-circle-fill::before {
  content: "\f79c";
}

.bi-2-circle::before {
  content: "\f79d";
}

.bi-2-square-fill::before {
  content: "\f79e";
}

.bi-2-square::before {
  content: "\f79f";
}

.bi-3-circle-fill::before {
  content: "\f7a2";
}

.bi-3-circle::before {
  content: "\f7a3";
}

.bi-3-square-fill::before {
  content: "\f7a4";
}

.bi-3-square::before {
  content: "\f7a5";
}

.bi-4-circle-fill::before {
  content: "\f7a8";
}

.bi-4-circle::before {
  content: "\f7a9";
}

.bi-4-square-fill::before {
  content: "\f7aa";
}

.bi-4-square::before {
  content: "\f7ab";
}

.bi-5-circle-fill::before {
  content: "\f7ae";
}

.bi-5-circle::before {
  content: "\f7af";
}

.bi-5-square-fill::before {
  content: "\f7b0";
}

.bi-5-square::before {
  content: "\f7b1";
}

.bi-6-circle-fill::before {
  content: "\f7b4";
}

.bi-6-circle::before {
  content: "\f7b5";
}

.bi-6-square-fill::before {
  content: "\f7b6";
}

.bi-6-square::before {
  content: "\f7b7";
}

.bi-7-circle-fill::before {
  content: "\f7ba";
}

.bi-7-circle::before {
  content: "\f7bb";
}

.bi-7-square-fill::before {
  content: "\f7bc";
}

.bi-7-square::before {
  content: "\f7bd";
}

.bi-8-circle-fill::before {
  content: "\f7c0";
}

.bi-8-circle::before {
  content: "\f7c1";
}

.bi-8-square-fill::before {
  content: "\f7c2";
}

.bi-8-square::before {
  content: "\f7c3";
}

.bi-9-circle-fill::before {
  content: "\f7c6";
}

.bi-9-circle::before {
  content: "\f7c7";
}

.bi-9-square-fill::before {
  content: "\f7c8";
}

.bi-9-square::before {
  content: "\f7c9";
}

.bi-airplane-engines-fill::before {
  content: "\f7ca";
}

.bi-airplane-engines::before {
  content: "\f7cb";
}

.bi-airplane-fill::before {
  content: "\f7cc";
}

.bi-airplane::before {
  content: "\f7cd";
}

.bi-alexa::before {
  content: "\f7ce";
}

.bi-alipay::before {
  content: "\f7cf";
}

.bi-android::before {
  content: "\f7d0";
}

.bi-android2::before {
  content: "\f7d1";
}

.bi-box-fill::before {
  content: "\f7d2";
}

.bi-box-seam-fill::before {
  content: "\f7d3";
}

.bi-browser-chrome::before {
  content: "\f7d4";
}

.bi-browser-edge::before {
  content: "\f7d5";
}

.bi-browser-firefox::before {
  content: "\f7d6";
}

.bi-browser-safari::before {
  content: "\f7d7";
}

.bi-c-circle-fill::before {
  content: "\f7da";
}

.bi-c-circle::before {
  content: "\f7db";
}

.bi-c-square-fill::before {
  content: "\f7dc";
}

.bi-c-square::before {
  content: "\f7dd";
}

.bi-capsule-pill::before {
  content: "\f7de";
}

.bi-capsule::before {
  content: "\f7df";
}

.bi-car-front-fill::before {
  content: "\f7e0";
}

.bi-car-front::before {
  content: "\f7e1";
}

.bi-cassette-fill::before {
  content: "\f7e2";
}

.bi-cassette::before {
  content: "\f7e3";
}

.bi-cc-circle-fill::before {
  content: "\f7e6";
}

.bi-cc-circle::before {
  content: "\f7e7";
}

.bi-cc-square-fill::before {
  content: "\f7e8";
}

.bi-cc-square::before {
  content: "\f7e9";
}

.bi-cup-hot-fill::before {
  content: "\f7ea";
}

.bi-cup-hot::before {
  content: "\f7eb";
}

.bi-currency-rupee::before {
  content: "\f7ec";
}

.bi-dropbox::before {
  content: "\f7ed";
}

.bi-escape::before {
  content: "\f7ee";
}

.bi-fast-forward-btn-fill::before {
  content: "\f7ef";
}

.bi-fast-forward-btn::before {
  content: "\f7f0";
}

.bi-fast-forward-circle-fill::before {
  content: "\f7f1";
}

.bi-fast-forward-circle::before {
  content: "\f7f2";
}

.bi-fast-forward-fill::before {
  content: "\f7f3";
}

.bi-fast-forward::before {
  content: "\f7f4";
}

.bi-filetype-sql::before {
  content: "\f7f5";
}

.bi-fire::before {
  content: "\f7f6";
}

.bi-google-play::before {
  content: "\f7f7";
}

.bi-h-circle-fill::before {
  content: "\f7fa";
}

.bi-h-circle::before {
  content: "\f7fb";
}

.bi-h-square-fill::before {
  content: "\f7fc";
}

.bi-h-square::before {
  content: "\f7fd";
}

.bi-indent::before {
  content: "\f7fe";
}

.bi-lungs-fill::before {
  content: "\f7ff";
}

.bi-lungs::before {
  content: "\f800";
}

.bi-microsoft-teams::before {
  content: "\f801";
}

.bi-p-circle-fill::before {
  content: "\f804";
}

.bi-p-circle::before {
  content: "\f805";
}

.bi-p-square-fill::before {
  content: "\f806";
}

.bi-p-square::before {
  content: "\f807";
}

.bi-pass-fill::before {
  content: "\f808";
}

.bi-pass::before {
  content: "\f809";
}

.bi-prescription::before {
  content: "\f80a";
}

.bi-prescription2::before {
  content: "\f80b";
}

.bi-r-circle-fill::before {
  content: "\f80e";
}

.bi-r-circle::before {
  content: "\f80f";
}

.bi-r-square-fill::before {
  content: "\f810";
}

.bi-r-square::before {
  content: "\f811";
}

.bi-repeat-1::before {
  content: "\f812";
}

.bi-repeat::before {
  content: "\f813";
}

.bi-rewind-btn-fill::before {
  content: "\f814";
}

.bi-rewind-btn::before {
  content: "\f815";
}

.bi-rewind-circle-fill::before {
  content: "\f816";
}

.bi-rewind-circle::before {
  content: "\f817";
}

.bi-rewind-fill::before {
  content: "\f818";
}

.bi-rewind::before {
  content: "\f819";
}

.bi-train-freight-front-fill::before {
  content: "\f81a";
}

.bi-train-freight-front::before {
  content: "\f81b";
}

.bi-train-front-fill::before {
  content: "\f81c";
}

.bi-train-front::before {
  content: "\f81d";
}

.bi-train-lightrail-front-fill::before {
  content: "\f81e";
}

.bi-train-lightrail-front::before {
  content: "\f81f";
}

.bi-truck-front-fill::before {
  content: "\f820";
}

.bi-truck-front::before {
  content: "\f821";
}

.bi-ubuntu::before {
  content: "\f822";
}

.bi-unindent::before {
  content: "\f823";
}

.bi-unity::before {
  content: "\f824";
}

.bi-universal-access-circle::before {
  content: "\f825";
}

.bi-universal-access::before {
  content: "\f826";
}

.bi-virus::before {
  content: "\f827";
}

.bi-virus2::before {
  content: "\f828";
}

.bi-wechat::before {
  content: "\f829";
}

.bi-yelp::before {
  content: "\f82a";
}

.bi-sign-stop-fill::before {
  content: "\f82b";
}

.bi-sign-stop-lights-fill::before {
  content: "\f82c";
}

.bi-sign-stop-lights::before {
  content: "\f82d";
}

.bi-sign-stop::before {
  content: "\f82e";
}

.bi-sign-turn-left-fill::before {
  content: "\f82f";
}

.bi-sign-turn-left::before {
  content: "\f830";
}

.bi-sign-turn-right-fill::before {
  content: "\f831";
}

.bi-sign-turn-right::before {
  content: "\f832";
}

.bi-sign-turn-slight-left-fill::before {
  content: "\f833";
}

.bi-sign-turn-slight-left::before {
  content: "\f834";
}

.bi-sign-turn-slight-right-fill::before {
  content: "\f835";
}

.bi-sign-turn-slight-right::before {
  content: "\f836";
}

.bi-sign-yield-fill::before {
  content: "\f837";
}

.bi-sign-yield::before {
  content: "\f838";
}

.bi-ev-station-fill::before {
  content: "\f839";
}

.bi-ev-station::before {
  content: "\f83a";
}

.bi-fuel-pump-diesel-fill::before {
  content: "\f83b";
}

.bi-fuel-pump-diesel::before {
  content: "\f83c";
}

.bi-fuel-pump-fill::before {
  content: "\f83d";
}

.bi-fuel-pump::before {
  content: "\f83e";
}

.bi-0-circle-fill::before {
  content: "\f83f";
}

.bi-0-circle::before {
  content: "\f840";
}

.bi-0-square-fill::before {
  content: "\f841";
}

.bi-0-square::before {
  content: "\f842";
}

.bi-rocket-fill::before {
  content: "\f843";
}

.bi-rocket-takeoff-fill::before {
  content: "\f844";
}

.bi-rocket-takeoff::before {
  content: "\f845";
}

.bi-rocket::before {
  content: "\f846";
}

.bi-stripe::before {
  content: "\f847";
}

.bi-subscript::before {
  content: "\f848";
}

.bi-superscript::before {
  content: "\f849";
}

.bi-trello::before {
  content: "\f84a";
}

.bi-envelope-at-fill::before {
  content: "\f84b";
}

.bi-envelope-at::before {
  content: "\f84c";
}

.bi-regex::before {
  content: "\f84d";
}

.bi-text-wrap::before {
  content: "\f84e";
}

.bi-sign-dead-end-fill::before {
  content: "\f84f";
}

.bi-sign-dead-end::before {
  content: "\f850";
}

.bi-sign-do-not-enter-fill::before {
  content: "\f851";
}

.bi-sign-do-not-enter::before {
  content: "\f852";
}

.bi-sign-intersection-fill::before {
  content: "\f853";
}

.bi-sign-intersection-side-fill::before {
  content: "\f854";
}

.bi-sign-intersection-side::before {
  content: "\f855";
}

.bi-sign-intersection-t-fill::before {
  content: "\f856";
}

.bi-sign-intersection-t::before {
  content: "\f857";
}

.bi-sign-intersection-y-fill::before {
  content: "\f858";
}

.bi-sign-intersection-y::before {
  content: "\f859";
}

.bi-sign-intersection::before {
  content: "\f85a";
}

.bi-sign-merge-left-fill::before {
  content: "\f85b";
}

.bi-sign-merge-left::before {
  content: "\f85c";
}

.bi-sign-merge-right-fill::before {
  content: "\f85d";
}

.bi-sign-merge-right::before {
  content: "\f85e";
}

.bi-sign-no-left-turn-fill::before {
  content: "\f85f";
}

.bi-sign-no-left-turn::before {
  content: "\f860";
}

.bi-sign-no-parking-fill::before {
  content: "\f861";
}

.bi-sign-no-parking::before {
  content: "\f862";
}

.bi-sign-no-right-turn-fill::before {
  content: "\f863";
}

.bi-sign-no-right-turn::before {
  content: "\f864";
}

.bi-sign-railroad-fill::before {
  content: "\f865";
}

.bi-sign-railroad::before {
  content: "\f866";
}

.bi-building-add::before {
  content: "\f867";
}

.bi-building-check::before {
  content: "\f868";
}

.bi-building-dash::before {
  content: "\f869";
}

.bi-building-down::before {
  content: "\f86a";
}

.bi-building-exclamation::before {
  content: "\f86b";
}

.bi-building-fill-add::before {
  content: "\f86c";
}

.bi-building-fill-check::before {
  content: "\f86d";
}

.bi-building-fill-dash::before {
  content: "\f86e";
}

.bi-building-fill-down::before {
  content: "\f86f";
}

.bi-building-fill-exclamation::before {
  content: "\f870";
}

.bi-building-fill-gear::before {
  content: "\f871";
}

.bi-building-fill-lock::before {
  content: "\f872";
}

.bi-building-fill-slash::before {
  content: "\f873";
}

.bi-building-fill-up::before {
  content: "\f874";
}

.bi-building-fill-x::before {
  content: "\f875";
}

.bi-building-fill::before {
  content: "\f876";
}

.bi-building-gear::before {
  content: "\f877";
}

.bi-building-lock::before {
  content: "\f878";
}

.bi-building-slash::before {
  content: "\f879";
}

.bi-building-up::before {
  content: "\f87a";
}

.bi-building-x::before {
  content: "\f87b";
}

.bi-buildings-fill::before {
  content: "\f87c";
}

.bi-buildings::before {
  content: "\f87d";
}

.bi-bus-front-fill::before {
  content: "\f87e";
}

.bi-bus-front::before {
  content: "\f87f";
}

.bi-ev-front-fill::before {
  content: "\f880";
}

.bi-ev-front::before {
  content: "\f881";
}

.bi-globe-americas::before {
  content: "\f882";
}

.bi-globe-asia-australia::before {
  content: "\f883";
}

.bi-globe-central-south-asia::before {
  content: "\f884";
}

.bi-globe-europe-africa::before {
  content: "\f885";
}

.bi-house-add-fill::before {
  content: "\f886";
}

.bi-house-add::before {
  content: "\f887";
}

.bi-house-check-fill::before {
  content: "\f888";
}

.bi-house-check::before {
  content: "\f889";
}

.bi-house-dash-fill::before {
  content: "\f88a";
}

.bi-house-dash::before {
  content: "\f88b";
}

.bi-house-down-fill::before {
  content: "\f88c";
}

.bi-house-down::before {
  content: "\f88d";
}

.bi-house-exclamation-fill::before {
  content: "\f88e";
}

.bi-house-exclamation::before {
  content: "\f88f";
}

.bi-house-gear-fill::before {
  content: "\f890";
}

.bi-house-gear::before {
  content: "\f891";
}

.bi-house-lock-fill::before {
  content: "\f892";
}

.bi-house-lock::before {
  content: "\f893";
}

.bi-house-slash-fill::before {
  content: "\f894";
}

.bi-house-slash::before {
  content: "\f895";
}

.bi-house-up-fill::before {
  content: "\f896";
}

.bi-house-up::before {
  content: "\f897";
}

.bi-house-x-fill::before {
  content: "\f898";
}

.bi-house-x::before {
  content: "\f899";
}

.bi-person-add::before {
  content: "\f89a";
}

.bi-person-down::before {
  content: "\f89b";
}

.bi-person-exclamation::before {
  content: "\f89c";
}

.bi-person-fill-add::before {
  content: "\f89d";
}

.bi-person-fill-check::before {
  content: "\f89e";
}

.bi-person-fill-dash::before {
  content: "\f89f";
}

.bi-person-fill-down::before {
  content: "\f8a0";
}

.bi-person-fill-exclamation::before {
  content: "\f8a1";
}

.bi-person-fill-gear::before {
  content: "\f8a2";
}

.bi-person-fill-lock::before {
  content: "\f8a3";
}

.bi-person-fill-slash::before {
  content: "\f8a4";
}

.bi-person-fill-up::before {
  content: "\f8a5";
}

.bi-person-fill-x::before {
  content: "\f8a6";
}

.bi-person-gear::before {
  content: "\f8a7";
}

.bi-person-lock::before {
  content: "\f8a8";
}

.bi-person-slash::before {
  content: "\f8a9";
}

.bi-person-up::before {
  content: "\f8aa";
}

.bi-scooter::before {
  content: "\f8ab";
}

.bi-taxi-front-fill::before {
  content: "\f8ac";
}

.bi-taxi-front::before {
  content: "\f8ad";
}

.bi-amd::before {
  content: "\f8ae";
}

.bi-database-add::before {
  content: "\f8af";
}

.bi-database-check::before {
  content: "\f8b0";
}

.bi-database-dash::before {
  content: "\f8b1";
}

.bi-database-down::before {
  content: "\f8b2";
}

.bi-database-exclamation::before {
  content: "\f8b3";
}

.bi-database-fill-add::before {
  content: "\f8b4";
}

.bi-database-fill-check::before {
  content: "\f8b5";
}

.bi-database-fill-dash::before {
  content: "\f8b6";
}

.bi-database-fill-down::before {
  content: "\f8b7";
}

.bi-database-fill-exclamation::before {
  content: "\f8b8";
}

.bi-database-fill-gear::before {
  content: "\f8b9";
}

.bi-database-fill-lock::before {
  content: "\f8ba";
}

.bi-database-fill-slash::before {
  content: "\f8bb";
}

.bi-database-fill-up::before {
  content: "\f8bc";
}

.bi-database-fill-x::before {
  content: "\f8bd";
}

.bi-database-fill::before {
  content: "\f8be";
}

.bi-database-gear::before {
  content: "\f8bf";
}

.bi-database-lock::before {
  content: "\f8c0";
}

.bi-database-slash::before {
  content: "\f8c1";
}

.bi-database-up::before {
  content: "\f8c2";
}

.bi-database-x::before {
  content: "\f8c3";
}

.bi-database::before {
  content: "\f8c4";
}

.bi-houses-fill::before {
  content: "\f8c5";
}

.bi-houses::before {
  content: "\f8c6";
}

.bi-nvidia::before {
  content: "\f8c7";
}

.bi-person-vcard-fill::before {
  content: "\f8c8";
}

.bi-person-vcard::before {
  content: "\f8c9";
}

.bi-sina-weibo::before {
  content: "\f8ca";
}

.bi-tencent-qq::before {
  content: "\f8cb";
}

.bi-wikipedia::before {
  content: "\f8cc";
}

.bi-alphabet-uppercase::before {
  content: "\f2a5";
}

.bi-alphabet::before {
  content: "\f68a";
}

.bi-amazon::before {
  content: "\f68d";
}

.bi-arrows-collapse-vertical::before {
  content: "\f690";
}

.bi-arrows-expand-vertical::before {
  content: "\f695";
}

.bi-arrows-vertical::before {
  content: "\f698";
}

.bi-arrows::before {
  content: "\f6a2";
}

.bi-ban-fill::before {
  content: "\f6a3";
}

.bi-ban::before {
  content: "\f6b6";
}

.bi-bing::before {
  content: "\f6c2";
}

.bi-cake::before {
  content: "\f6e0";
}

.bi-cake2::before {
  content: "\f6ed";
}

.bi-cookie::before {
  content: "\f6ee";
}

.bi-copy::before {
  content: "\f759";
}

.bi-crosshair::before {
  content: "\f769";
}

.bi-crosshair2::before {
  content: "\f794";
}

.bi-emoji-astonished-fill::before {
  content: "\f795";
}

.bi-emoji-astonished::before {
  content: "\f79a";
}

.bi-emoji-grimace-fill::before {
  content: "\f79b";
}

.bi-emoji-grimace::before {
  content: "\f7a0";
}

.bi-emoji-grin-fill::before {
  content: "\f7a1";
}

.bi-emoji-grin::before {
  content: "\f7a6";
}

.bi-emoji-surprise-fill::before {
  content: "\f7a7";
}

.bi-emoji-surprise::before {
  content: "\f7ac";
}

.bi-emoji-tear-fill::before {
  content: "\f7ad";
}

.bi-emoji-tear::before {
  content: "\f7b2";
}

.bi-envelope-arrow-down-fill::before {
  content: "\f7b3";
}

.bi-envelope-arrow-down::before {
  content: "\f7b8";
}

.bi-envelope-arrow-up-fill::before {
  content: "\f7b9";
}

.bi-envelope-arrow-up::before {
  content: "\f7be";
}

.bi-feather::before {
  content: "\f7bf";
}

.bi-feather2::before {
  content: "\f7c4";
}

.bi-floppy-fill::before {
  content: "\f7c5";
}

.bi-floppy::before {
  content: "\f7d8";
}

.bi-floppy2-fill::before {
  content: "\f7d9";
}

.bi-floppy2::before {
  content: "\f7e4";
}

.bi-gitlab::before {
  content: "\f7e5";
}

.bi-highlighter::before {
  content: "\f7f8";
}

.bi-marker-tip::before {
  content: "\f802";
}

.bi-nvme-fill::before {
  content: "\f803";
}

.bi-nvme::before {
  content: "\f80c";
}

.bi-opencollective::before {
  content: "\f80d";
}

.bi-pci-card-network::before {
  content: "\f8cd";
}

.bi-pci-card-sound::before {
  content: "\f8ce";
}

.bi-radar::before {
  content: "\f8cf";
}

.bi-send-arrow-down-fill::before {
  content: "\f8d0";
}

.bi-send-arrow-down::before {
  content: "\f8d1";
}

.bi-send-arrow-up-fill::before {
  content: "\f8d2";
}

.bi-send-arrow-up::before {
  content: "\f8d3";
}

.bi-sim-slash-fill::before {
  content: "\f8d4";
}

.bi-sim-slash::before {
  content: "\f8d5";
}

.bi-sourceforge::before {
  content: "\f8d6";
}

.bi-substack::before {
  content: "\f8d7";
}

.bi-threads-fill::before {
  content: "\f8d8";
}

.bi-threads::before {
  content: "\f8d9";
}

.bi-transparency::before {
  content: "\f8da";
}

.bi-twitter-x::before {
  content: "\f8db";
}

.bi-type-h4::before {
  content: "\f8dc";
}

.bi-type-h5::before {
  content: "\f8dd";
}

.bi-type-h6::before {
  content: "\f8de";
}

.bi-backpack-fill::before {
  content: "\f8df";
}

.bi-backpack::before {
  content: "\f8e0";
}

.bi-backpack2-fill::before {
  content: "\f8e1";
}

.bi-backpack2::before {
  content: "\f8e2";
}

.bi-backpack3-fill::before {
  content: "\f8e3";
}

.bi-backpack3::before {
  content: "\f8e4";
}

.bi-backpack4-fill::before {
  content: "\f8e5";
}

.bi-backpack4::before {
  content: "\f8e6";
}

.bi-brilliance::before {
  content: "\f8e7";
}

.bi-cake-fill::before {
  content: "\f8e8";
}

.bi-cake2-fill::before {
  content: "\f8e9";
}

.bi-duffle-fill::before {
  content: "\f8ea";
}

.bi-duffle::before {
  content: "\f8eb";
}

.bi-exposure::before {
  content: "\f8ec";
}

.bi-gender-neuter::before {
  content: "\f8ed";
}

.bi-highlights::before {
  content: "\f8ee";
}

.bi-luggage-fill::before {
  content: "\f8ef";
}

.bi-luggage::before {
  content: "\f8f0";
}

.bi-mailbox-flag::before {
  content: "\f8f1";
}

.bi-mailbox2-flag::before {
  content: "\f8f2";
}

.bi-noise-reduction::before {
  content: "\f8f3";
}

.bi-passport-fill::before {
  content: "\f8f4";
}

.bi-passport::before {
  content: "\f8f5";
}

.bi-person-arms-up::before {
  content: "\f8f6";
}

.bi-person-raised-hand::before {
  content: "\f8f7";
}

.bi-person-standing-dress::before {
  content: "\f8f8";
}

.bi-person-standing::before {
  content: "\f8f9";
}

.bi-person-walking::before {
  content: "\f8fa";
}

.bi-person-wheelchair::before {
  content: "\f8fb";
}

.bi-shadows::before {
  content: "\f8fc";
}

.bi-suitcase-fill::before {
  content: "\f8fd";
}

.bi-suitcase-lg-fill::before {
  content: "\f8fe";
}

.bi-suitcase-lg::before {
  content: "\f8ff";
}

.bi-suitcase::before {
  content: "豈";
}

.bi-suitcase2-fill::before {
  content: "更";
}

.bi-suitcase2::before {
  content: "車";
}

.bi-vignette::before {
  content: "賈";
}
